import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { of, Subject, Subscription, from } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { BuildingDocumentsInputData } from './../../../core/components/building/building-documents-input-data';
import { IntegrityBoxComponent } from './../../dashboard-boxes/integrity-box/integrity-box.component';
import { MissingDocumentState } from './../missing-document-upload/missing-document-state.service';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../core/odata/odataclient';

@Component({
  selector: 'app-buildings-missing-documents-only',
  templateUrl: './buildings-missing-documents-only.component.html',
  styleUrls: ['./buildings-missing-documents-only.component.scss']
})
export class BuildingsMissingDocumentsOnlyComponent implements OnInit, OnDestroy {

  buildingDocumentsInputData: BuildingDocumentsInputData = new BuildingDocumentsInputData();

  _searchFilter: string = '';
  searchFilterSubject = new Subject<string>();

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: MissingDocumentState,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.route.queryParams.pipe(filter(params => params.search)).subscribe(params => { this.searchFilter = params['search'] || ''; }));

    this.buildingDocumentsInputData.navigateToFirstListItem = false;
    this.buildingDocumentsInputData.buildingDocument$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(v => {
        return from(this.odataCoreService.Building.Query()
          .Expand(x => {
            x.Expand("DocumentTypeMarkers",
              y => {
                y.Expand("DocumentType")
                y.Filter(z => z
                  .EqualsField("IsRequired", true).And
                  .EqualsField("IsMissing", true))
              })
            x.Expand("Documents",
              y => {
                y.Expand("DocumentType")
              })
          })
          .Filter(x => x
            .Equals(ODataPath.For("KpiMarker", "HasDocumentation"), false))
          .Exec().then(x => x.value));
      }));

    this.buildingDocumentsInputData.searchFilter$ = this.searchFilterSubject.asObservable().pipe(startWith(this.searchFilter));
    this.buildingDocumentsInputData.showBuildingData = true;

    this.buildingDocumentsInputData.currentBuildingUT$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(action => {
        if (action === MissingDocumentState.ACTION_UPLOAD_DOCUMENT) {
          this.buildingDocumentsInputData.navigateToFirstListItem = true;
          return of(<string>null);
        }
        return this.route.parent.children[1].paramMap.pipe(map( (params: ParamMap) => params.get('id')));
      }));
    this.buildingDocumentsInputData.currentDocumentTypeId$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(action => {
        if (action === MissingDocumentState.ACTION_UPLOAD_DOCUMENT) {
          this.buildingDocumentsInputData.navigateToFirstListItem = true;
          return of(<number>null);
        }
        return this.route.parent.children[1].paramMap.pipe(map( (params: ParamMap) => +params.get('documentTypeId')));
      }));

    this.buildingDocumentsInputData.showsAsCallToAction = false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get searchFilter(): string {
    return this._searchFilter;
  }

  set searchFilter(searchFilter: string) {
    this._searchFilter = searchFilter;
    this.searchFilterSubject.next(searchFilter);

    this.router.navigate([], { relativeTo: this.route, queryParams: { search: this.searchFilter }, queryParamsHandling: 'merge' });
  }

  documentWizard(buildingUT: string, documentType: number) {
    this.router.navigate([
      '/dashboard',
      {
        outlets: {
          left: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_BUILDINGS_MISSING_DOCUMENTS_ONLY],
          right: ['building', buildingUT, 'document-upload', documentType]
        }
      }
    ], { queryParams: { search: this.searchFilter }, queryParamsHandling: 'merge' });
  }

}
