import { Directive, forwardRef, } from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';

export const modelGroupProvider: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => NgModelGroup)
};

/**
 * see https://github.com/angular/angular/issues/9600
 */
@Directive({
  selector: '[provide-parent-form-group]',
  providers: [
    modelGroupProvider
  ]
})
export class ProvideParentFormGroupDirective{ }
