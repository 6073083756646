import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, from } from 'rxjs';

import { PropertyCore, Operations } from '../../core/odata/odata.coreapi';
import { AuthService } from './../../core/auth/auth.service';
import { Selectable } from './../../core/model/common';
import { ConfirmationModal } from './../../core/popup/confirmation.modal';
import { GlobalService } from './../../core/shared/global.service';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../core/tools/utils';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-range-of-services',
  templateUrl: './range-of-services.component.html',
  styleUrls: ['./range-of-services.component.scss']
})
export class RangeOfServicesComponent implements OnInit, OnDestroy {

  @ViewChild('helpPopupContent', { static: true })
  helpPopupContent: any;

  modalTitle: string;
  isOther: boolean;
  formSubmitted: boolean;
  invalid: boolean;

  buildings: Selectable<PropertyCore.Building>[];

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  help1() {
    this.isOther = false;
    this.help(1);
  }

  help2() {
    this.isOther = false;
    this.help(2);
  }

  help3() {
    this.isOther = true;
    this.help(3);
  }

  private help(idx: number) {
    if (!this.isOther) {
      if (!this.buildings) {
        this.subscriptions.add(from(this.odataCoreService.Building.Query()
          .OrderBy("Name", "asc")
          .Exec().then(x => x.value))
          .pipe(map(res => res.map(i => {
            return Utils.mapAllJSONDatesToDates(i);
          })))
          .subscribe(res => this.buildings = res));
      } else {
        this.selectAll(false);
      }
    }

    this.modalTitle = this.translateService.instant('RangeOfServices._modal_title' + idx);
    this.modalService.open(this.helpPopupContent, { windowClass: 'help-modal' }).result
      .then(val => {
        const buildingIds = !this.isOther ? this.buildings.filter(b => b.selected).map(b => b.Identity) : null;
        let service;
        switch (idx) {
          case 1:
            service = this.odataCoreService.Account.Get().Key(Guid.parse(localStorage.getItem('mandator'))).Actions().SendServiceRequestInOperationsOnAccountInOperations().Parameters(Operations.ServiceRequestTypes.InstallEquipmentDocumentation, buildingIds).Execute();
            break;
          case 2:
            service = this.odataCoreService.Account.Get().Key(Guid.parse(localStorage.getItem('mandator'))).Actions().SendServiceRequestInOperationsOnAccountInOperations().Parameters(Operations.ServiceRequestTypes.InspectionDutyAnalysis, buildingIds).Execute();
            break;
          case 3:
            service = this.odataCoreService.Account.Get().Key(Guid.parse(localStorage.getItem('mandator'))).Actions().SendServiceRequestInOperationsOnAccountInOperations().Parameters(Operations.ServiceRequestTypes.Training).Execute();
            break;
        }
        this.subscriptions.add(service.subscribe(res => {
          const modalRef = this.modalService.open(ConfirmationModal);
          modalRef.componentInstance.title = this.translateService.instant('RangeOfServices._modal2_title');
          modalRef.componentInstance.message = this.translateService.instant('RangeOfServices._modal2_message');
          modalRef.componentInstance.yesButton = this.translateService.instant('RangeOfServices._modal2_yes');
          modalRef.componentInstance.hideCancelButton = true;
        }));
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  selectAll(all: boolean): void {
    this.buildings.forEach(b => {
      b.selected = all;
    });
  }

  validate(closeModal: () => void): void {
    this.invalid = !this.isOther ? !this.buildings.filter(b => b.selected).length : false;
    if (this.invalid) {
      this.formSubmitted = true;
      return;
    }
    closeModal();
  }
}
