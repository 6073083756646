import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { of, Subscription, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { PropertyCore } from "../../core/odata/odata.coreapi";
import { SearchNavigationPaths } from "./search-navigation-paths.enum";
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnDestroy {
  searchFilter: string = "";

  buildings: PropertyCore.Building[] = [];
  installEquipments: PropertyCore.Equipment[] = [];
  installEquipmentFeatures: PropertyCore.Equipment[] = [];
  installModules: PropertyCore.Module[] = [];
  installModuleFeatures: PropertyCore.Module[] = [];

  currentPage: SearchNavigationPaths;

  PAGE_BUILDING = SearchNavigationPaths.BUILDING;
  PAGE_INSTALL_EQUIPMENT = SearchNavigationPaths.INSTALL_EQUIPMENT;
  PAGE_INSTALL_EQUIPMENT_FEATURE =
    SearchNavigationPaths.INSTALL_EQUIPMENT_FEATURE;
  PAGE_INSTALL_MODULE = SearchNavigationPaths.INSTALL_MODULE;
  PAGE_INSTALL_MODULE_FEATURE = SearchNavigationPaths.INSTALL_MODULE_FEATURE;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.currentPage = this.PAGE_BUILDING;

    this.subscriptions.add(
      this.route.paramMap.subscribe(
        (params) => (this.searchFilter = params.get("id"))
      )
    );

    this.subscriptions.add(
      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            params.get("id") != null
              ? from(
                  this.odataCoreService.Building.Functions()
                    .SearchInObjektBriefOnBuildingInPropertyCoreOnEntitySet()
                    .Parameters(params.get("id"))
                    .Execute()
                    .then((x) => x.value)
                )
              : of(<PropertyCore.Building[]>[])
          )
        )
        .subscribe((res) => (this.buildings = res))
    );
    this.subscriptions.add(
      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            params.get("id") != null
              ? from(
                  this.odataCoreService.Equipment.Functions()
                    .SearchInObjektBriefOnEquipmentInPropertyCoreOnEntitySet()
                    .Parameters(params.get("id"))
                    .Query()
                    .Expand((x) => {
                      x.Expand("Room", (y) => y.Expand("Floor"));
                      x.Expand("Building");
                      x.Expand("EquipmentType");
                      x.Expand("QuickResponseCodes");
                    })
                    .Exec()
                    .then((x) => x.value)
                )
              : of(<PropertyCore.Equipment[]>[])
          )
        )
        .subscribe((res) => (this.installEquipments = res))
    );
    this.subscriptions.add(
      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            params.get("id") != null
              ? from(
                  this.odataCoreService.Equipment.Functions()
                    .SearchInObjektBriefOnEquipmentInPropertyCoreOnEntitySet()
                    .Parameters(params.get("id"))
                    .Query()
                    .Expand((x) => {
                      x.Expand("Room", (y) => y.Expand("Floor"));
                      x.Expand("Room");
                      x.Expand("Building");
                      x.Expand("EquipmentType");
                      //x.Expand("QuickResponseCodes");
                    })
                    .Exec()
                    .then((x) => x.value)
                )
              : of(<PropertyCore.Equipment[]>[])
          )
        )
        .subscribe((res) => (this.installEquipmentFeatures = res))
    );
    this.subscriptions.add(
      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            params.get("id") != null
              ? from(
                  this.odataCoreService.Module.Functions()
                    .SearchInObjektBriefOnModuleInPropertyCoreOnEntitySet()
                    .Parameters(params.get("id"))
                    .Query()
                    .Expand((x) => {
                      x.Expand("Equipment", (y) => y.Expand("Building"));
                      x.Expand("Equipment", (y) => y.Expand("EquipmentType"));
                      x.Expand("ModuleType");
                      x.Expand("Room", (y) => y.Expand("Floor"));
                    })
                    .Exec()
                    .then((x) => x.value)
                )
              : of(<PropertyCore.Module[]>[])
          )
        )
        .subscribe((res) => (this.installModules = res))
    );
    this.subscriptions.add(
      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            params.get("id") != null
              ? from(
                  this.odataCoreService.Module.Functions()
                    .SearchInObjektBriefOnModuleInPropertyCoreOnEntitySet()
                    .Parameters(params.get("id"))
                    .Query()
                    .Expand((x) => {
                      x.Expand("Equipment", (y) => y.Expand("Building"));
                      x.Expand("Equipment", (y) => y.Expand("EquipmentType"));
                      x.Expand("ModuleType");
                      x.Expand("Room", (y) => y.Expand("Floor"));
                    })
                    .Exec()
                    .then((x) => x.value)
                )
              : of(<PropertyCore.Module[]>[])
          )
        )
        .subscribe((res) => (this.installModuleFeatures = res))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  search() {
    this.router.navigate([
      "/search",
      this.searchFilter ? this.searchFilter : "",
    ]);
  }

  navToBuilding(building: PropertyCore.Building) {
    this.router.navigate([
      "/building",
      building.Identity,
      { outlets: { left: ["list"], right: ["building"] } },
    ]);
  }
  navToInstallEquipment(
    installEquipment: PropertyCore.Equipment,
    toFeatures: boolean = false
  ) {
    this.router.navigate([
      "/building",
      installEquipment.Building.Identity,
      {
        outlets: {
          left: ["building", "view", { outlets: { tab: ["equipment"] } }],
          right: [
            "equipment",
            installEquipment.Identity,
            "view",
            { outlets: { tab: [toFeatures ? "features" : "details"] } },
          ],
        },
      },
    ]);
  }
  navToInstallModule(
    installModule: PropertyCore.Module,
    toFeatures: boolean = false
  ) {
    this.router.navigate([
      "/building",
      installModule.Equipment.Building.Identity,
      {
        outlets: {
          left: [
            "equipment",
            installModule.Equipment.Identity,
            "view",
            { outlets: { tab: ["modules"] } },
          ],
          right: [
            "equipment",
            "module",
            installModule.Identity,
            { outlets: { tab: [toFeatures ? "features" : "details"] } },
          ],
        },
      },
    ]);
  }
}
