import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable, from } from 'rxjs';

import { PropertyCore } from '../../../../core/odata/odata.coreapi';
import { ContractWizardNavigationPaths } from '../contract-wizard-navigation-paths.enum';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { ContractWizardModel } from './../contract-wizard-model';
import { ContractWizardService } from './../contract-wizard.service';
import { ODataPath } from '../../../../core/odata/odataclient';
import { Utils } from '../../../../core/tools/utils';
import { map } from 'rxjs/operators';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';



@Component({
  selector: 'app-contract-wizard-service-contact',
  templateUrl: './contract-wizard-service-contact.component.html',
  styleUrls: ['./contract-wizard-service-contact.component.scss']
})
export class ContractWizardServiceContactComponent implements OnInit, OnDestroy {

  model: ContractWizardModel;

  serviceContacts: PropertyCore.ServiceProvider[];
  serviceContactFilter: string;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<ContractWizardNavigationPaths>();

  @Output()
  saveContractRequest = new EventEmitter<ContractWizardModel>();

  constructor(
    private service: ContractWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.model = this.service.getCurrentContract();
    this.subscriptions.add(
      from(this.odataCoreService.ServiceProvider.Query()
        .Filter(x => x
          .Equals(ODataPath.For("Account", "Identity"), Guid.parse(localStorage.getItem('mandator'))))
        .Exec().then(x => x.value))
        .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
        .subscribe(res => (this.serviceContacts = res)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  next(serviceContact: PropertyCore.ServiceProvider): void {
    if (!serviceContact) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(ContractWizardNavigationPaths.NEXT);
  }

  previous(): void {
    this.pageChangeRequest.emit(ContractWizardNavigationPaths.PREVIOUS);
  }

  selectServiceContact(serviceContact: PropertyCore.ServiceProvider): void {
    this.model.contract.ServiceProvider = serviceContact;
  }

  saveButtonDisabled(): boolean {
    return !(!this.model.installEquipments || !this.model.installEquipments.length || this.model.installEquipments.every(ie => !!ie.Schedule && !!ie.Schedule.Cycle && !!ie.Schedule.CycleUnit && !!ie.Schedule.CycleStart));
  }

  save(serviceContact: PropertyCore.ServiceProvider): void {
    if (!serviceContact) {
      this.formSubmitted = true;
      return;
    }
    this.saveContractRequest.emit(this.model);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('ContractWizzard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('ContractWizzard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('ContractWizzard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ContractWizzard._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentContract();
            this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
    }
  }

  serviceContactWizard(): void {
    this.pageChangeRequest.emit(ContractWizardNavigationPaths.ADD_SERVICE_CONTACT);
  }

}
