import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-preview-modal',
  templateUrl: './image-preview.modal.html'
})

export class ImagePreviewModal {

  _imageDownloadToken: string;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  @Input()
  set imageDownloadToken(imageDownloadToken: string) {
    // replace width url attribute in download token url to max 3200px for the ImageResizer to work
    imageDownloadToken = imageDownloadToken.replace(/w=\d+&*/, 'w=3200&').replace(/h=\d+&*/, 'h=3200&');
    this._imageDownloadToken = imageDownloadToken;
  }

  get imageDownloadToken() {
    return this._imageDownloadToken;
  }
}
