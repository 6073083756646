import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IncidentWizardNavigationPaths } from '../incident-wizard-navigation-paths.enum';
import { IncidentWizardModel } from './../incident-wizard-model';
import { IncidentWizardService } from './../incident-wizard.service';

@Component({
  selector: 'app-incident-wizard-progress',
  templateUrl: './incident-wizard-progress.component.html',
  styleUrls: ['./incident-wizard-progress.component.scss']
})
export class IncidentWizardProgressComponent implements OnInit {

  PAGE_DATA = IncidentWizardNavigationPaths.DATA;
  PAGE_EQUIPMENT = IncidentWizardNavigationPaths.EQUIPMENT;
  PAGE_CONTACT = IncidentWizardNavigationPaths.CONTACT;
  PAGE_ADD_SERVICE_CONTACT = IncidentWizardNavigationPaths.ADD_SERVICE_CONTACT;

  model: IncidentWizardModel;

  @Output()
  pageChangeRequest = new EventEmitter<IncidentWizardNavigationPaths>();

  @Input()
  currentPage: IncidentWizardNavigationPaths;

  constructor(private service: IncidentWizardService) { }

  ngOnInit() {
    this.model = this.service.getCurrentIncident();
  }

  navigate(pageChangeEvent: IncidentWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }

}
