import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { BuildingModule } from "./../building/building.module";
import { AuthGuard } from "./../core/auth/auth-guard.service";
import { CoreModule } from "./../core/core.module";
import { ConformityDetailComponent } from "./conformity/conformity-detail/conformity-detail.component";
import { ConformityListComponent } from "./conformity/conformity-list/conformity-list.component";
import { ConformityBoxComponent } from "./dashboard-boxes/conformity-box/conformity-box.component";
import { DashboardBoxesComponent } from "./dashboard-boxes/dashboard-boxes.component";
import { EconomyBoxComponent } from "./dashboard-boxes/economy-box/economy-box.component";
import { IntegrityBoxComponent } from "./dashboard-boxes/integrity-box/integrity-box.component";
import { DashboardComponent } from "./dashboard.component";
import { BuildingsMissingDocumentsComponent } from "./integrity/buildings-missing-documents/buildings-missing-documents.component";
import { BuildingsMissingDocumentsOnlyComponent } from "./integrity/buildings-missing-documents-only/buildings-missing-documents-only.component";
import { BuildingsNoEquipmentComponent } from "./integrity/buildings-no-equipment/buildings-no-equipment.component";
import { BuildingEditUsagesComponent } from "./integrity/buildings-no-usage/building-edit-usages/building-edit-usages.component";
import { BuildingNoUsageState } from "./integrity/buildings-no-usage/building-no-usage-state.service";
import { BuildingsNoUsageComponent } from "./integrity/buildings-no-usage/buildings-no-usage.component";
import { EquipmentsContractNotScheduledComponent } from "./integrity/equipments-contract-not-scheduled/equipments-contract-not-scheduled.component";
import { EquipmentsContractsSetTerminationComponent } from "./integrity/equipments-contract-not-scheduled/equipments-contracts-set-termination/equipments-contracts-set-termination.component";
import { EquipmentsMissingDocumentsComponent } from "./integrity/equipments-missing-documents/equipments-missing-documents.component";
import { EquipmentChooseContractActionComponent } from "./integrity/equipments-no-contract/equipment-choose-contract-action/equipment-choose-contract-action.component";
import { EquipmentSetContractComponent } from "./integrity/equipments-no-contract/equipment-set-contract/equipment-set-contract.component";
import { EquipmentsNoContractState } from "./integrity/equipments-no-contract/equipments-no-contract-state.service";
import { EquipmentsNoContractComponent } from "./integrity/equipments-no-contract/equipments-no-contract.component";
import { EquipmentNoInspectionDutiesState } from "./integrity/equipments-no-inspection-duties/equipment-no-inspection-duties-state.service";
import { EquipmentSetInspectionDutiesComponent } from "./integrity/equipments-no-inspection-duties/equipment-set-inspection-duties/equipment-set-inspection-duties.component";
import { EquipmentsNoInspectionDutiesComponent } from "./integrity/equipments-no-inspection-duties/equipments-no-inspection-duties.component";
import { EquipmentNotScheduledInspectionDutiesState } from "./integrity/equipments-not-scheduled-inspection-duties/equipment-not-scheduled-inspection-duties-state.service";
import { EquipmentTerminateInspectionDutiesComponent } from "./integrity/equipments-not-scheduled-inspection-duties/equipment-terminate-inspection-duties/equipment-terminate-inspection-duties.component";
import { EquipmentsNotScheduledInspectionDutiesComponent } from "./integrity/equipments-not-scheduled-inspection-duties/equipments-not-scheduled-inspection-duties.component";
import { MissingDocumentState } from "./integrity/missing-document-upload/missing-document-state.service";
import { MissingDocumentUploadComponent } from "./integrity/missing-document-upload/missing-document-upload.component";
import { EquipmentsContractHandlingComponent } from "./integrity/shared/equipments-contract-handling/equipments-contract-handling.component";
import { RangeOfServicesComponent } from "./range-of-services/range-of-services.component";

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      // 1st level
      {
        outlet: "left",
        path: "",
        pathMatch: "full",
        component: DashboardBoxesComponent,
        data: {
          animation: "1-dashboard",
        },
      },
      {
        outlet: "left",
        path: "board",
        component: DashboardBoxesComponent,
        data: {
          animation: "1-dashboard",
        },
      },
      {
        outlet: "right",
        path: "rangeOfServices",
        component: RangeOfServicesComponent,
      },
      {
        outlet: "right",
        path: "",
        pathMatch: "full",
        component: RangeOfServicesComponent,
      },
      {
        outlet: "right",
        path: "conformity/list",
        component: ConformityListComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/buildings-no-equipment",
        component: BuildingsNoEquipmentComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/buildings-no-usage",
        component: BuildingsNoUsageComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/equipments-no-contract",
        component: EquipmentsNoContractComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/equipments-contract-not-scheduled",
        component: EquipmentsContractNotScheduledComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/equipments-no-inspection-duties",
        component: EquipmentsNoInspectionDutiesComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/equipments-not-scheduled-inspection-duties",
        component: EquipmentsNotScheduledInspectionDutiesComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/buildings-missing-documents",
        component: BuildingsMissingDocumentsComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/buildings-missing-documents-only",
        component: BuildingsMissingDocumentsOnlyComponent,
        data: {
          animation: "1-level-list",
        },
      },
      {
        outlet: "right",
        path: "integrity/equipments-missing-documents",
        component: EquipmentsMissingDocumentsComponent,
        data: {
          animation: "1-level-list",
        },
      },

      // 2nd level
      //
      // gebäude ohne anlagen

      {
        outlet: "left",
        path: "conformity/list",
        component: ConformityListComponent,
        data: {
          animation: "2-level-list",
        },
      },
      {
        outlet: "right",
        path: "conformity/detail/:incidentId",
        component: ConformityDetailComponent,
        data: {
          animation: "2-level-detail",
        },
      },
      {
        outlet: "right",
        path: "incident/:incidentId/document-upload",
        component: MissingDocumentUploadComponent,
        data: {
          animation: "2-level-detail",
        },
      },
      // gebäude ohne nutzungsarten
      {
        outlet: "left",
        path: "integrity/buildings-no-usage",
        component: BuildingsNoUsageComponent,
        data: {
          animation: "2-level-list",
        },
      },
      {
        outlet: "right",
        path: "building/:id/edit/usages",
        component: BuildingEditUsagesComponent,
        data: {
          animation: "2-level-detail",
        },
      },
      // anlagen ohne vertrag
      {
        outlet: "left",
        path: "integrity/equipments-no-contract",
        component: EquipmentsNoContractComponent,
        data: {
          animation: "2-level-list",
        },
      },
      {
        outlet: "right",
        path: "equipment/:id/contract/choose",
        component: EquipmentChooseContractActionComponent,
        data: {
          animation: "2-level-detail",
        },
      },
      {
        outlet: "right",
        path: "set-contract/:id",
        component: EquipmentSetContractComponent,
        data: {
          animation: "2-level-detail",
        },
      },

      // Wartungstermine zu Anlage festlegen
      {
        outlet: "left",
        path: "integrity/equipments-contract-not-scheduled",
        component: EquipmentsContractNotScheduledComponent,
        data: {
          animation: "2-level-list",
        },
      },
      {
        outlet: "right",
        path: "equipment/:id/contract/terminate",
        component: EquipmentsContractsSetTerminationComponent,
        data: {
          animation: "2-level-detail",
        },
      },
      // Prüfpflichten zu Anlagen aktivieren
      {
        outlet: "left",
        path: "integrity/equipments-no-inspection-duties",
        component: EquipmentsNoInspectionDutiesComponent,
        data: {
          animation: "2-level-list",
        },
      },
      {
        outlet: "right",
        path: "equipment/:id/inspection-duties/set",
        component: EquipmentSetInspectionDutiesComponent,
        data: {
          animation: "2-level-detail",
        },
      },

      // Nicht terminierte Prüfpflichten von Anlagen
      {
        outlet: "left",
        path: "integrity/equipments-not-scheduled-inspection-duties",
        component: EquipmentsNotScheduledInspectionDutiesComponent,
        data: {
          animation: "2-level-list",
        },
      },
      {
        outlet: "right",
        path: "equipment/:id/inspection-duties/terminate",
        component: EquipmentTerminateInspectionDutiesComponent,
        data: {
          animation: "2-level-detail",
        },
      },

      // Fehlende Gebäudedokumentation
      {
        outlet: "left",
        path: "integrity/buildings-missing-documents",
        component: BuildingsMissingDocumentsComponent,
        data: {
          animation: "2-level-list",
        },
      },

      // Fehlende Gebäudedokumentation
      {
        outlet: "left",
        path: "integrity/buildings-missing-documents-only",
        component: BuildingsMissingDocumentsOnlyComponent,
        data: {
          animation: "2-level-list",
        },
      },

      {
        outlet: "right",
        path: "building/:id/document-upload/:documentTypeId",
        component: MissingDocumentUploadComponent,
        data: {
          animation: "2-level-detail",
        },
      },

      // Fehlende Anlagendokumentation
      {
        outlet: "left",
        path: "integrity/equipments-missing-documents",
        component: EquipmentsMissingDocumentsComponent,
        data: {
          animation: "2-level-list",
        },
      },
      {
        outlet: "right",
        path: "equipment/:id/document-upload/:documentTypeId",
        component: MissingDocumentUploadComponent,
        data: {
          animation: "2-level-detail",
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    CoreModule,
    RouterModule.forChild(routes),
    BuildingModule,
    FormsModule,
  ],
  exports: [RouterModule],
  declarations: [
    DashboardComponent,
    DashboardBoxesComponent,
    ConformityBoxComponent,
    EconomyBoxComponent,
    IntegrityBoxComponent,
    ConformityListComponent,
    ConformityDetailComponent,
    BuildingsNoEquipmentComponent,
    EquipmentsNoContractComponent,
    EquipmentsContractNotScheduledComponent,
    EquipmentsNoInspectionDutiesComponent,
    EquipmentsNotScheduledInspectionDutiesComponent,
    BuildingsNoUsageComponent,
    EquipmentChooseContractActionComponent,
    BuildingEditUsagesComponent,
    EquipmentSetContractComponent,
    EquipmentsContractHandlingComponent,
    EquipmentsContractsSetTerminationComponent,
    EquipmentSetInspectionDutiesComponent,
    EquipmentTerminateInspectionDutiesComponent,
    BuildingsMissingDocumentsComponent,
    BuildingsMissingDocumentsOnlyComponent,
    EquipmentsMissingDocumentsComponent,
    MissingDocumentUploadComponent,
    RangeOfServicesComponent,
  ],
  providers: [
    EquipmentsNoContractState,
    EquipmentNoInspectionDutiesState,
    EquipmentNotScheduledInspectionDutiesState,
    BuildingNoUsageState,
    MissingDocumentState,
  ],
})
export class DashboardModule {}
