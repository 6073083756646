import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription, from } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { BuildingListInputData } from './../../../core/components/building/building-list-input-data';
import { IntegrityBoxComponent } from './../../dashboard-boxes/integrity-box/integrity-box.component';
import { BuildingNoUsageState } from './building-no-usage-state.service';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../core/odata/odataclient';

@Component({
  selector: 'app-buildings-no-usage',
  templateUrl: './buildings-no-usage.component.html',
  styleUrls: ['./buildings-no-usage.component.scss']
})
export class BuildingsNoUsageComponent implements OnInit, OnDestroy {

  inputData: BuildingListInputData = new BuildingListInputData();

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: BuildingNoUsageState,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.inputData.navigateToFirstListItem = false;

    this.inputData.translatedListTitle = 'DashboardIntegrityBuildingsNoUsage._h1_buildingOverview';
    this.inputData.currentBuildingUT$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(action => {
        if (action === BuildingNoUsageState.ACTION_SET_USAGE) {
          this.inputData.navigateToFirstListItem = true;
          return of(<string>null);
        }
        return this.route.root.firstChild.children[1].paramMap.pipe(map(p => p.get('id')));
      }));

    this.inputData.showGroupingWizardRow = false;
    this.inputData.showMap = false;
    this.inputData.scrollStyle = { 'max-height': 'calc(100vh - 240px)' };

    this.inputData.buildings$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(() => {
        return this.inputData.buildings$ =
          from(this.odataCoreService.Building.Query()
          .Filter(x => x
            .Equals(ODataPath.For("KpiMarker", "HasUsageTypes"), false))
          .Exec()
          .then(x => {
              return x.value
            }));
      }));
     
    this.subscriptions.add(this.route.queryParams.pipe(filter(params => params.search)).subscribe(params => { this.inputData.searchFilter = params['search'] || ''; }));
    this.inputData.onSearchFilterChange = (newValue) => {
      this.inputData.searchFilter = newValue;
      this.router.navigate([], { relativeTo: this.route, queryParams: { search: this.inputData.searchFilter }, queryParamsHandling: 'merge' });
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleDetailPageRequest(building: PropertyCore.Building): void {
    this.router.navigate([
      '/dashboard',
      {
        outlets: {
          left: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_BUILDINGS_NO_USAGE],
          right: ['building', building.Identity, 'edit', 'usages']
        }
      }
    ], { queryParams: { search: this.inputData.searchFilter }, queryParamsHandling: 'merge' });
  }

  handleListEmpty(): void {
    this.router.navigate(['/dashboard']);
  }
}
