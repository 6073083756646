import {Injectable} from '@angular/core';

import {Mandator} from './../../../core/model/mandator';
import {Common, Library} from './../../../core/odata/odata.coreapi';
import {Utils} from './../../../core/tools/utils';
import {MandatorWizardModel} from './mandator-wizard-model';

@Injectable()
export class MandatorWizardService {

  private model: MandatorWizardModel;

  constructor() {
  }

  getCurrentMandator(): MandatorWizardModel {
    return this.model;
  }

  startWizard(): void {
    this.resetCurrentMandator();
  }

  resetCurrentMandator(): void {
    this.model = new MandatorWizardModel();
    this.model.identity = Utils.guid();
    this.model.address = <Common.Address>{};
    this.model.address.CountryInfo = <Library.Country>{ Id: 1, Name: 'Deutschland' };
  }

  editWizard(mandator: Mandator, country: Library.Country, history: boolean = false): void {
    this.model.identity = mandator.Identity;
    this.model.name = mandator.Name;
    if (history && mandator?.AccountDataProcessingAgreement) {
      this.model.mandatorName = mandator?.AccountDataProcessingAgreement.Name;
      this.model.address.Street = mandator?.AccountDataProcessingAgreement.Street;
      this.model.address.No = mandator?.AccountDataProcessingAgreement.HouseNo;
      this.model.address.PostCode = mandator?.AccountDataProcessingAgreement.ZipCode;
      this.model.address.City = mandator?.AccountDataProcessingAgreement.City;
      this.model.address.CountryInfo = mandator?.AccountDataProcessingAgreement.Country;
    } else {
      this.model.mandatorName = mandator.Profile.Name;
      this.model.address.Street = mandator.Profile.Address.Street;
      this.model.address.No = mandator.Profile.Address.No;
      this.model.address.PostCode = mandator.Profile.Address.PostCode;
      this.model.address.City = mandator.Profile.Address.City;
      this.model.address.CountryInfo = mandator.Profile.Address.CountryInfo;
    }
    this.model.contact = mandator.Profile.Contact;
    this.model.contactPhone = mandator.Profile.ContactPhone;
    this.model.contactMail = mandator.Profile.ContactMail;
    this.model.agreed = !!(mandator?.AccountDataProcessingAgreement);
    this.model.oldAgreed = !!(mandator?.AccountDataProcessingAgreement);
    this.model.editMode = true;
  }

}
