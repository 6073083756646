import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

import {ConfirmationModal} from './../../../../core/popup/confirmation.modal';
import {MandatorWizardModel} from './../mandator-wizard-model';
import {MandatorWizardNavigationPaths} from './../mandator-wizard-navigation-paths.enum';
import {MandatorWizardService} from './../mandator-wizard.service';

@Component({
  selector: 'app-mandator-wizard-done',
  templateUrl: './mandator-wizard-done.component.html',
  styleUrls: ['./mandator-wizard-done.component.scss']
})
export class MandatorWizardDoneComponent implements OnInit, OnDestroy {

  model: MandatorWizardModel;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<MandatorWizardNavigationPaths>();

  constructor(
    private service: MandatorWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentMandator();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  previous(): void {
    this.pageChangeRequest.emit(MandatorWizardNavigationPaths.PREVIOUS);
  }

  next(): void {
    this.pageChangeRequest.emit(MandatorWizardNavigationPaths.NEXT);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('CreateMandator._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('CreateMandator._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('CreateMandator._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('CreateMandator._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentMandator();
            this.pageChangeRequest.emit(MandatorWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(MandatorWizardNavigationPaths.EXIT);
    }
  }

}
