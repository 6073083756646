import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import * as html2pdf from 'html2pdf.js';
import * as $ from 'jquery';
import {Subscription} from 'rxjs';
import {ConfirmationModal} from './../../../../core/popup/confirmation.modal';
import {MandatorWizardModel} from './../mandator-wizard-model';
import {MandatorWizardNavigationPaths} from './../mandator-wizard-navigation-paths.enum';
import {MandatorWizardService} from './../mandator-wizard.service';

@Component({
  selector: 'app-mandator-wizard-agreement',
  templateUrl: './mandator-wizard-agreement.component.html',
  styleUrls: ['./mandator-wizard-agreement.component.scss']
})
export class MandatorWizardAgreementComponent implements OnInit, OnDestroy {

  model: MandatorWizardModel;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<MandatorWizardNavigationPaths>();

  constructor(
    private service: MandatorWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentMandator();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  previous(): void {
    this.pageChangeRequest.emit(MandatorWizardNavigationPaths.PREVIOUS);
  }

  next(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(MandatorWizardNavigationPaths.NEXT);
  }

  agreeLater(validForm: boolean): void {
    this.model.agreed = false;
    this.next(validForm);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('CreateMandator._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('CreateMandator._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('CreateMandator._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('CreateMandator._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentMandator();
            this.pageChangeRequest.emit(MandatorWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(MandatorWizardNavigationPaths.EXIT);
    }
  }

  download(): void {
    const elem = $('#app-contract-data-processing-article > article')[0];
    const opt = {
      margin: 10,
      filename: 'Auftragsdatenverarbeitung.pdf',
      image: {type: 'jpeg', quality: 0.98},
      html2canvas: {scale: 1},
      jsPDF: {unit: 'mm', format: 'a4', orientation: 'p'}
    };
    html2pdf().from(elem).set(opt).save();
  }
}
