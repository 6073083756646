import { Directive } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

export function formFactory(form: NgForm) {
  return form;
}

/**
 * see https://github.com/angular/angular/issues/9600
 */
@Directive({
  selector: '[provide-parent-form]',
  providers: [
    {
      provide: ControlContainer,
      useFactory: formFactory,
      deps: [NgForm]
    }
  ]
})
export class ProvideParentFormDirective { }
