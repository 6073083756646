import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, from } from 'rxjs';
import { PropertyCore } from '../../../../core/odata/odata.coreapi';
import { IncidentWizardNavigationPaths } from '../incident-wizard-navigation-paths.enum';
import { ConfirmationModal } from '../../../../core/popup/confirmation.modal';
import { Utils } from '../../../../core/tools/utils';
import { IncidentWizardModel } from '../incident-wizard-model';
import { IncidentWizardService } from '../incident-wizard.service';
import { Guid } from 'guid-typescript';
import { ODataPath, ODataBinding } from '../../../../core/odata/odataclient';
import { map } from 'rxjs/operators';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';

@Component({
  selector: 'app-incident-wizard-contact',
  templateUrl: './incident-wizard-contact.component.html',
  styleUrls: ['./incident-wizard-contact.component.scss']
})
export class IncidentWizardContactComponent implements OnInit, OnDestroy {

  model: IncidentWizardModel;

  contacts: PropertyCore.ServiceProvider[];

  contactFilter: string;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<IncidentWizardNavigationPaths>();

  constructor(
    private service: IncidentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentIncident();
    this.subscriptions.add(from(this.odataCoreService.ServiceProvider.Query()
      .Filter(x =>
        x.Equals(ODataPath.For("Account", "Identity"), Guid.parse(localStorage.getItem('mandator'))))
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.contacts = res));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  previous(): void {
    this.pageChangeRequest.emit(IncidentWizardNavigationPaths.PREVIOUS);
  }

  selectContact(contact: PropertyCore.ServiceProvider): void {
    if (!this.model.contact || this.model.contact.Identity !== contact.Identity) {
      this.model.contact = contact;
    } else {
      // deselect
      this.model.contact = null;
    }
  }

  save(): void {

    this.model.Identity = Utils.guid();

    var equipmentBind;
    if (this.model.equipment) {
      equipmentBind = this.odataCoreService.Equipment.Get().Key(Guid.parse(this.model.equipment.Identity)).Bind();
    } else {
      equipmentBind = "";
    }

      this.odataCoreService.Event.Post()
        .ValueType(this.odataCoreService.ODataTypes().Event())
        .ValueProperty("Identity", this.model.Identity)
        .ValueProperty("Type", this.model.type)
        .ValueProperty("DueDate", this.model.dueDate)
        .ValueProperty("IsDefectHandler", this.model.isDefectHandler)
        .ValueProperty("Guidelines", this.model.guidelines)
        .ValueProperty("Qualification", this.model.qualification)
        .ValuePropertyBinding("Equipment", equipmentBind)
        .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.model.buildingUT)).Bind())
        .Exec()
        .then(res => {
            this.model.id = res.Id;
            this.odataCoreService.Event.Get()
            .Key(Guid.parse(this.model.Identity))
            .Actions()
            .MakeSeriesInOperationsOnEventInPropertyCore()
            .Execute()
            .then(res => {
                this.odataCoreService.Schedule.Patch()
                .Key(Guid.parse(res.Identity))
                .ValueType(this.odataCoreService.ODataTypes().Schedule())
                .ValueProperty("Cycle", this.model.cycle)
                .ValueProperty("CycleUnit", this.model.cycleUnit)
                .ValueProperty("CycleStart", this.model.dueDate)
                .Exec()
                .then(res1 => {
                    if (this.model.contact) {
                        this.odataCoreService.Event.Link()
                          .Key(Guid.parse(this.model.Identity))
                          .Value("ServiceProvider", this.odataCoreService.ServiceProvider.Get().Key(Guid.parse(this.model.contact.Identity)).Bind())
                          .Post()
                          .then(res2 => {
                            this.pageChangeRequest.emit(IncidentWizardNavigationPaths.EXIT);
                          });
                    } else {
                      this.pageChangeRequest.emit(IncidentWizardNavigationPaths.EXIT);
                    }
                  })
              });
            });
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('IncidentWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('IncidentWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('IncidentWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('IncidentWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentIncident();
            this.pageChangeRequest.emit(IncidentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      this.pageChangeRequest.emit(IncidentWizardNavigationPaths.EXIT);
    }
  }

  serviceContactWizard(): void {
    this.pageChangeRequest.emit(IncidentWizardNavigationPaths.ADD_SERVICE_CONTACT);
  }

}
