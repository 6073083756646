import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ModuleWizardNavigationPaths } from '../module-wizard-navigation-paths.enum';
import { ModuleWizardModel } from './../module-wizard-model';
import { ModuleWizardService } from './../module-wizard.service';
import { ObjektBrief } from 'app/core/odata/odata.coreapi';

@Component({
  selector: 'app-module-wizard-progress',
  templateUrl: './module-wizard-progress.component.html',
  styleUrls: ['./module-wizard-progress.component.scss']
})
export class ModuleWizardProgressComponent implements OnInit {

  PAGE_DATA = ModuleWizardNavigationPaths.DATA;
  PAGE_TYPE = ModuleWizardNavigationPaths.TYPE;
  PAGE_TECHNICAL = ModuleWizardNavigationPaths.TECHNICAL;

  model: ModuleWizardModel;
  AttributeType: any;


  @Output()
  pageChangeRequest = new EventEmitter<ModuleWizardNavigationPaths>();

  @Input()
  currentPage: ModuleWizardNavigationPaths;

  constructor(private service: ModuleWizardService) { }

  ngOnInit() {
    this.model = this.service.getCurrentModule();
    this.AttributeType = ObjektBrief.AttributeValueTypes;
  }

  navigate(pageChangeEvent: ModuleWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }

}
