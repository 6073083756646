import {PropertyCore, Common} from '../odata/odata.coreapi';

export class Incident implements PropertyCore.Event {

  // from interface
  Identity: string;
  Id: number;
  Type: PropertyCore.EventTypes;
  Guidelines: string;
  Qualification: string;
  Name: string;
  DueDate: Date;
  RefDate: Date;
  ClosedDate: Date;
  IsSkipped: boolean;
  Created: Date;
  Response?: PropertyCore.EventResponse;
  Parent?: PropertyCore.Event;
  Schedule?: PropertyCore.Schedule;
  ServiceProvider?: PropertyCore.ServiceProvider;
  Building?: PropertyCore.Building;
  Documents?: PropertyCore.Document[];
  Equipment?: PropertyCore.Equipment;
  Module?: PropertyCore.Module;
  IsDefectHandler: boolean;
  KpiMarker?: PropertyCore.EventKpiMarker;
  DefectChecker: boolean;
  TypeShort: string;
  CycleShort: string;
  GuidelinesShort: string;

  // own data
  selected?: boolean;

  get getTypeShort(): string {
    switch (this.Type) {
      case PropertyCore.EventTypes.Inspection:
      case PropertyCore.EventTypes.FollowUpInspection:
        return this.TypeShort = 'P';
        break;
      case PropertyCore.EventTypes.Maintenance:
        return this.TypeShort = 'W';
        break;
      case PropertyCore.EventTypes.Repair:
        return this.TypeShort = 'I';
        break;
      case PropertyCore.EventTypes.Custom:
        return this.TypeShort = 'S';
        break;
      default:
        return this.TypeShort = '';
        break;
    }  
 
  }

  get getCycleUnitShort(): string {
    switch (this.Schedule?.CycleUnit) {
      case Common.IntervalUnits.Undefined:
        return this.CycleShort = this.Schedule.Cycle + 'X';
        break;
      case Common.IntervalUnits.Day:
        return this.CycleShort = this.Schedule.Cycle + 'T';
        break;
      case Common.IntervalUnits.Week:
        return this.CycleShort = this.Schedule.Cycle + 'W';
        break;
      case Common.IntervalUnits.Month:
        return this.CycleShort = this.Schedule.Cycle + 'M';
        break;
      case Common.IntervalUnits.Year:
        return this.CycleShort = this.Schedule.Cycle + 'J';
        break;
      default:
        return this.CycleShort = 1 + 'X';
        break;
    }    
  }

  get getGuidelinesShort(): string {
      if(this.Type == PropertyCore.EventTypes.Maintenance && this.ServiceProvider != null && this.Guidelines == '' && this.Schedule?.MaintenanceContract?.StartDate != null){
        let StartDate = this.Schedule.MaintenanceContract.StartDate;
        let GetMonth = ('0'+(this.Schedule.MaintenanceContract.StartDate.getMonth()+1)).slice(-2);
        return this.GuidelinesShort = 'Wartungsvertrag Fa. ' + this.ServiceProvider.Name + ' vom ' + StartDate.getDate() + '.' + GetMonth + '.' + StartDate.getFullYear()
      }
      if(this.Type == PropertyCore.EventTypes.Maintenance && this.ServiceProvider == null && this.Guidelines == '' && this.Schedule?.MaintenanceContract?.StartDate != null && this.Schedule?.MaintenanceContract?.ServiceProvider != null){
        let StartDate = this.Schedule.MaintenanceContract.StartDate;
        let GetMonth = ('0'+(this.Schedule.MaintenanceContract.StartDate.getMonth()+1)).slice(-2);
        return this.GuidelinesShort = 'Wartungsvertrag Fa. ' + this.Schedule.MaintenanceContract.ServiceProvider.Name + ' vom ' + StartDate.getDate() + '.' + GetMonth + '.' + StartDate.getFullYear()
      }
      if(this.Guidelines != '' || this.Guidelines == '' && this.Schedule == null || this.Guidelines == '' && this.Schedule.InspectionDuty == null){
        return this.GuidelinesShort = this.Guidelines
      }
      if(this.Schedule != null && this.Schedule.InspectionDuty != null){
        return this.GuidelinesShort = this.Schedule.InspectionDuty.OperatorTask.Guidelines
      }
      return ''
  }

  get Status() {
    if (this.IsSkipped) {
      return IncidentStatus.IsSkipped;
    }
    else if (this.KpiMarker != null && this.KpiMarker.IsDone) {
      return IncidentStatus.Executed;
    }
    else if (this.KpiMarker != null && this.KpiMarker.IsOverdue) {
      return IncidentStatus.Due;
    }
    else if (this.RefDate) {
      return IncidentStatus.Closed;
    }
    return IncidentStatus.Open;
  }

  get documentCommissionStatus(): DocumentStatus {
    return this.getDocumentStatus(InvoiceDocumentTypeName.Commission);
  }

  get documentInvoiceStatus(): DocumentStatus {
    return this.getDocumentStatus(InvoiceDocumentTypeName.Invoice);
  }

  get documentOfferStatus(): DocumentStatus {
    return this.getDocumentStatus(InvoiceDocumentTypeName.Offer);
  }

  get documentProtocolStatus(): DocumentStatus {
    return this.getDocumentStatus(InvoiceDocumentTypeName.Protocol);
  }

  get documentReportStatus(): DocumentStatus {
    return this.getDocumentStatus(InvoiceDocumentTypeName.Report);
  }

   getDocumentStatus(invoiceDocumentTypeName: InvoiceDocumentTypeName) {
     const doc = this.Documents.find(d => d.DocumentType.Name === invoiceDocumentTypeName);
    
    if (doc) {
      return DocumentStatus.Exists;
    }
    switch (invoiceDocumentTypeName) {
      case InvoiceDocumentTypeName.Offer:
        // not required by any event type
        break;
      case InvoiceDocumentTypeName.Commission:
        // not required by any event type
        break;
      case InvoiceDocumentTypeName.Protocol:
        switch (this.Type) {
          case PropertyCore.EventTypes.Inspection:
          case PropertyCore.EventTypes.FollowUpInspection:
          case PropertyCore.EventTypes.Maintenance:
            return DocumentStatus.MissingRequired;
        }
      case InvoiceDocumentTypeName.Report:
        switch (this.Type) {
          case PropertyCore.EventTypes.Repair:
            return DocumentStatus.MissingRequired;
        }
      case InvoiceDocumentTypeName.Invoice:
        // not required by any event type
        break;
    }
    return DocumentStatus.Missing;
  }
}

export enum IncidentStatus {
  IsSkipped = 'IsSkipped',
  Closed = 'Closed',
  Executed = 'Executed',
  Due = 'Due',
  Open = 'Open'
}

export enum InvoiceDocumentTypeName {
  Offer = 'Angebot',
  Commission = 'Beauftragung',
  Protocol = 'Protokoll',
  Report = 'Regiebericht',
  Invoice = 'Rechnung'
}

export enum DocumentStatus {
  Missing = 1,
  Exists = 2,
  MissingRequired = 3
}
