import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { Selectable } from './../../../core/model/common';
import { ConfirmationModal } from './../../../core/popup/confirmation.modal';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../core/odata/odataclient';



@Component({
  selector: 'app-building-detail-edit-floors',
  templateUrl: './building-detail-edit-floors.component.html',
  styleUrls: ['./building-detail-edit-floors.component.scss']
})
export class BuildingDetailEditFloorsComponent implements OnInit, OnDestroy {

  building: PropertyCore.Building;
  buildingFloor: PropertyCore.Building;
  floors: Selectable<PropertyCore.Floor>[];

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();
  buildingIdentity: string;
  constructor(
    private translateService: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.route.root.firstChild.paramMap.pipe(switchMap((params: ParamMap) => {
      this.buildingIdentity = params.get('id');

      this.subscriptions.add(from(this.odataCoreService.Building.Get()
        .Key(Guid.parse(this.buildingIdentity))
        .Expand(x => {
          x.Expand("Floors");
        })        
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
        .subscribe(res => {
          this.buildingFloor = res;
        }));

      return combineLatest(
         from(this.odataCoreService.Building.Get()
          .Key(Guid.parse(this.buildingIdentity))
          .Expand(x => {
            x.Expand("UsageTypes");
            x.Expand("Statistics");
          })
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
        ,
        from(this.odataCoreService.Floor.Query()
          .OrderBy("Order", "asc")
          .Exec().then(x => x.value))
          .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
        ,
        from(this.odataCoreService.Building.Get()
          .Key(Guid.parse(this.buildingIdentity))
          .Expand(x => {
            x.Expand("Floors");
          })
          .Exec().then(x => x.value))
          .pipe(map(res => res[0].Floors.map(f => Utils.mapAllJSONDatesToDates(f)).sort((a, b) => a.Order - b.Order))));
    })).subscribe(([building, allFloors, buildingFloors]) => {
      this.building = building;
      this.floors = allFloors;
      this.floors.forEach(f => {
        buildingFloors.forEach(bf => (f.Id === bf.Id ? (f.selected = true) : null));
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('BuildingDetailEditFloor._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('BuildingDetailEditFloor._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('BuildingDetailEditFloor._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('BuildingDetailEditFloor._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['list'], right: ['building'] } }]);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['list'], right: ['building'] } }]);
    }
  }

  async save(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    let fIds = this.floors.filter(f => f.selected).map(f => f.Id);
    let linkFloor = fIds.filter(x => !this.getFloorIds(this.buildingFloor.Floors).includes(x));
    let unlinkFloor = this.getFloorIds(this.buildingFloor.Floors).filter(x => !fIds.includes(x));


    let x: number = 0;
    while (x < linkFloor.length) {
        await this.odataCoreService.Building.Link()
            .Key(Guid.parse(this.building.Identity))
            .Value("Floors", this.odataCoreService.Floor.Get().Key(linkFloor[x]).Bind())
            .Post()
      x++;
      }

    let y: number = 0;
    while (y < unlinkFloor.length) {
      await this.odataCoreService.Building.Link()
          .Key(Guid.parse(this.building.Identity))
          .Value("Floors", this.odataCoreService.Floor.Get().Key(unlinkFloor[y]).Bind())
          .Delete()
            
      y++;
    }
    this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['list'], right: ['building'] } }]);
}

  getFloorIds(floors): number[] {
    let floorIds: number[] = [];
    floors.forEach(ut => {
      floorIds.push(ut.Id);
    });
    return floorIds;
  }
}
