import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { Subscription } from "rxjs";

import { ConfirmationModal } from "./../../../../core/popup/confirmation.modal";
import { BuildingWizardModel } from "./../building-wizard-model";
import { BuildingWizardNavigationPaths } from "./../building-wizard-navigation-paths";
import { BuildingWizardService } from "./../building-wizard.service";
import * as L from "leaflet";

@Component({
  selector: "app-building-wizard-location",
  templateUrl: "./building-wizard-location.component.html",
  styleUrls: ["./building-wizard-location.component.scss"],
})
export class BuildingWizardLocationComponent implements OnInit, OnDestroy, AfterViewInit {
  model: BuildingWizardModel;
  leafletmap: L.Map;
  mapMarker: L.Marker;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<BuildingWizardNavigationPaths>();

  @Input()
  editMode: boolean;

  constructor(
    private modalService: NgbModal,
    private service: BuildingWizardService,
    private translateService: TranslateService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.model = this.service.getCurrentBuilding();
  }

  ngAfterViewInit(): void {
    this.loadMap();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadMap(): void {
    try {
      this.leafletmap = L.map("leafletmap", { attributionControl: false }).setView(
        [this.model.latitude, this.model.longitude],
        17
      );

      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
      }).addTo(this.leafletmap);

      const icon = L.icon({
        iconUrl: "assets/marker.png",
        iconAnchor: [0, 0],
      });

      this.mapMarker = L.marker([this.model.latitude, this.model.longitude], {
        icon,
        draggable: true,
      }).addTo(this.leafletmap);

      this.mapMarker.on("dragend", () => {
        this.model.latitude = this.mapMarker.getLatLng().lat;
        this.model.longitude = this.mapMarker.getLatLng().lng;
      });
    } catch (e) {
      // ignore: leaflet container not visible
    }
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant("BuildingWiz._modal_title");
      modalRef.componentInstance.message = this.translateService.instant("BuildingWiz._modal_message");
      modalRef.componentInstance.yesButton = this.translateService.instant("BuildingWiz._modal_yes");
      modalRef.componentInstance.cancelButton = this.translateService.instant("BuildingWiz._modal_cancel");

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentBuilding();
            this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
    }
  }

  previous(): void {
    this.pageChangeRequest.emit(BuildingWizardNavigationPaths.PREVIOUS);
  }

  // gettest() {
  //   console.log('get test');
  //   return this._test;
  // }
  // _test = '1111';

  // set test(v) {
  //   console.log('set test');
  //   this._test = v;
  // }

  // get markerPosition() {
  //   console.log('called getter markerPosition: ');
  //   //return this.markerPositionProp;

  //   return {
  //     'lat': 0,
  //     'lng': 0
  //   };
  // }

  // markerPositionProp = {
  //   'lat': 0,
  //   'lng': 0
  // };

  // mapPositionProp = {
  //   'lat': 0,
  //   'lng': 0
  // };

  // get mapPosition() {
  //   console.log('called getter mapPosition: ');
  //   return this.mapPositionProp;
  // }

  // getMarkerPosition(param: string) {
  //   console.log('called getMarkerPosition: ', param);
  //   return {
  //     'lat': 0,
  //     'lng': 0
  //   };
  // }

  // ngDoCheck() {
  //   console.log('Change detection triggered');
  // }

  async next(): Promise<void> {
    if (this.editMode) {
      // save building and then save install equipments if any
      try {
        await this.service.editBuilding();
        this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
      } catch (err) {
        this.toasterService.pop(
          "error",
          "Fehler!",
          "Ein unerwarteter Fehler ist aufgetreten und das Gebäude konnte nicht geändert werden!"
        );
      }
    } else {
      this.pageChangeRequest.emit(BuildingWizardNavigationPaths.NEXT);
    }
  }
}
