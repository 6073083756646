import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { from, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { Mandator } from "../core/model/mandator";
import { ODataCoreService } from "../core/odata-services/odata.coreapi.service";
import { Utils } from "../core/tools/utils";
import { AuthService } from "./../core/auth/auth.service";
import { GlobalService } from "./../core/shared/global.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginError: string;
  registerError: string;

  username: string;
  password: string;
  email: string;

  accessCode: string;
  invitation: string;

  subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private globalService: GlobalService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParamMap.subscribe((params) => {
        this.accessCode = params.get("AccessCode");
        this.invitation = params.get("Invitation");
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  login(username: string, password: string): void {
    this.subscriptions.add(
      this.authService.login(username, password).subscribe((loggedIn) => {
        this.loginError = "";
        if (loggedIn && loggedIn.length && loggedIn[0]) {
          loggedIn = loggedIn[0];
          if (loggedIn.error) {
            this.loginError = loggedIn.error_description;
            switch (loggedIn.error) {
              case "invalid_grant":
                // console.log('loggedIn.error invalid_grant');
                this.loginError = this.translateService.instant("Login._div_coupleInvalid");
                break;
              case "account_expired":
                // console.log('loggedIn.error account_expired');
                break;
              case "password_expired":
                // console.log('loggedIn.error password_expired');
                this.router.navigate(["/global/change-password"], {
                  queryParams: {
                    username: username,
                    password: password,
                    accessCode: this.accessCode,
                    invitation: this.invitation,
                  },
                });
                break;
              case "missing_permission":
                // console.log('loggedIn.error missing_permission');
                break;
              case "impersonation_notfound":
                // console.log('loggedIn.error impersonation_notfound');
                break;
              default:
                // console.log('loggedIn.error other');
                break;
            }
          } else {
            this.globalService.isUserValid(username, false).subscribe((isPwUnchanged) => {
              // first login
              if (isPwUnchanged) {
                this.toasterService.pop(
                  "warning",
                  "",
                  this.translateService.instant("AppComponent._user_status_error")
                );
                this.router.navigate(["/global/change-password"], {
                  queryParams: {
                    username: username,
                    password: password,
                    accessCode: this.accessCode,
                    invitation: this.invitation,
                  },
                });
              } else {
                if (this.accessCode && this.invitation) {
                  this.router.navigate(["/global/service-provider"], {
                    queryParams: {
                      accessCode: this.accessCode,
                      invitation: this.invitation,
                    },
                  });
                } else {
                  this.subscriptions.add(
                    from(
                      this.odataCoreService.Account.Query()
                        .OrderBy("Name")
                        .Exec()
                        .then((x) => x.value)
                    )
                      .pipe(
                        map((res) =>
                          res.map((i) => {
                            Utils.mapAllJSONDatesToDates(i);
                            return Object.assign(new Mandator(), i);
                          })
                        )
                      )
                      .subscribe((mandators) => {
                        if (mandators && mandators.length) {
                          this.subscriptions.add(
                            from(
                              this.odataCoreService.Building.Query()
                                .Count()
                                .then((count) => {
                                  if (count) {
                                    this.subscriptions.add(
                                      this.route.queryParamMap.subscribe((params) => {
                                        const returnUrl = params.get("returnUrl");
                                        if (returnUrl) {
                                          this.router.navigateByUrl(returnUrl);
                                        } else {
                                          this.router.navigate(["/dashboard"]);
                                        }
                                      })
                                    );
                                  } else {
                                    this.router.navigate(["/building", "wizard"]);
                                  }
                                })
                            ).subscribe()
                          );
                        } else {
                          this.toasterService.pop(
                            "info",
                            "",
                            this.translateService.instant("AppComponent._no_mandator")
                          );
                          this.router.navigate(["/global/mandator/wizard"]);
                        }
                      })
                  );
                }
              }
            });
          }
        }
      })
    );
  }

  register(email: string): void {
    this.registerError = "";
    this.subscriptions.add(
      this.globalService.register(email).subscribe((pwd) => {
        if (!pwd) {
          this.registerError = "E-Mail ist bereits registriert, bitte loggen Sie sich ein.";
        } else {
          console.log("login.component: " + email);
          this.login(email, pwd);
        }
      })
    );
  }
}
