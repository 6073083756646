import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ToasterService } from "angular2-toaster";
import * as $ from "jquery";
import { PDFDocumentProxy } from "ng2-pdf-viewer";
import { Subscription, from } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { Document } from "../../../../core/model/document";
import { Incident } from "../../../../core/model/incident";
import { Utils } from "../../../../core/tools/utils";
import { ODataCoreService } from "../../../../core/odata-services/odata.coreapi.service";
import { GlobalService } from "app/core/shared/global.service";
import { PopupService } from "app/core/popup/popup.service";
import { Guid } from "guid-typescript";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationModal } from "app/core/popup/confirmation.modal";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-defect-wizard-document",
  templateUrl: "./defect-wizard-document.component.html",
  styleUrls: ["./defect-wizard-document.component.scss"],
})
export class DefectWizardDocumentComponent implements OnInit, OnDestroy {
  parentIncident: Incident;
  documents: Document[];
  previewDocument: Document;

  pageCount: number;

  subscriptions: Subscription = new Subscription();

  constructor(
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService,
    private globalService: GlobalService,
    private popupService: PopupService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.route.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            return from(
              this.odataCoreService.Event.Query()
                .Expand((x) => {
                  x.Expand("Documents", (a) => {
                    a.Expand("DocumentType");
                  });
                  x.Expand("Response"),
                    x.Expand("Schedule", (xa) => {
                      xa.Expand("InspectionDuty", (xaa) => {
                        xaa.Select("Id"),
                          xaa.Expand("OperatorTask", (xaaa) => {
                            xaaa.Select("Guidelines"),
                              xaaa.Select("Qualification");
                          });
                      }),
                        xa.Expand("MaintenanceContract", (xab) => {
                          xab.Select("StartDate"),
                            xab.Expand("ServiceProvider", (xabb) => {
                              xabb.Select("Name");
                            });
                        });
                    }),
                    x.Expand("Building", (xb) => {
                      xb.Select("Name"), xb.Select("Id"), xb.Select("Identity");
                    }),
                    x.Expand("Equipment", (xc) => {
                      xc.Select("Name"),
                        xc.Select("Id"),
                        xc.Select("Identity"),
                        xc.Select("CustomId"),
                        xc.Expand("Room", (xcc) => {
                          xcc.Select("Name"),
                            xcc.Select("Id"),
                            xcc.Select("Identity");
                          xcc.Expand("Floor", (xccc) => {
                            xccc.Select("Id"), xccc.Select("Name");
                          });
                        });
                    }),
                    x.Expand("ServiceProvider", (xd) => {
                      xd.Select("Name"), xd.Select("Id"), xd.Select("Identity");
                    }),
                    x.Expand("Module", (xe) => {
                      xe.Select("Name"),
                        xe.Select("Id"),
                        xe.Select("Identity"),
                        xe.Expand("ModuleType", (xee) => {
                          xee.Select("Code"),
                            xee.Select("Id"),
                            xee.Select("Name");
                        });
                    });
                  x.Expand("KpiMarker");
                })
                .Filter((x) => x.EqualsField("Id", +params.get("id")))
                .Exec()
                .then((x) => x.value)
            ).pipe(
              map((res) => {
                Utils.mapAllJSONDatesToDates(res[0]);
                return Object.assign(new Incident(), res[0]);
              })
            );
          })
        )
        .subscribe((res) => {
          this.parentIncident = res;
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showDocument(doc: Document) {
    if (!doc.IsVirtual) {
      if (doc.Name.endsWith(".pdf")) {
        this.popupService.openPdfModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false),
          doc.Name
        );
      } else if (
        doc.Name.endsWith(".jpg") ||
        doc.Name.endsWith(".jpeg") ||
        doc.Name.endsWith(".png")
      ) {
        this.popupService.openImagePreviewModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false)
        );
      } else if (
        doc.Name.endsWith(".doc") ||
        doc.Name.endsWith(".docx") ||
        doc.Name.endsWith(".xls") ||
        doc.Name.endsWith(".xlsx") ||
        doc.Name.endsWith(".ppt") ||
        doc.Name.endsWith(".pptx")
      ) {
        this.popupService.openOfficePreviewModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false),
          doc.Name
        );
      } else {
        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = this.translateService.instant(
          "DocumentList._preview_title"
        );
        modalRef.componentInstance.message = this.translateService.instant(
          "DocumentList._preview_message"
        );
        modalRef.componentInstance.yesButton = this.translateService.instant(
          "DocumentList._preview_download"
        );
        modalRef.componentInstance.cancelButton = this.translateService.instant(
          "DocumentList._preview_cancel"
        );

        modalRef.result
          .then((val) => {
            if (val === ConfirmationModal.YES_VALUE) {
              this.subscriptions.add(
                this.globalService
                  .downloadDocument(
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(doc.Identity))
                      .Raw()
                      .ToUrlString(false)
                  )
                  .subscribe((data) => {
                    const blob = new Blob([data], {
                      type: "application/octet-stream",
                    });
                    // if (navigator.msSaveBlob) {
                    //   // IE 10+
                    //   navigator.msSaveBlob(blob, doc.Name);
                    // } else {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = doc.Name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    //}
                  })
              );
            }
          })
          .catch(() => {
            // do nothing, just stay on page
          });
      }
    }
  }

  viewDocument(document: Document): void {
    this.previewDocument = document;
    $("#loadingoverlay").show();
  }

  closeDocument(): void {
    this.previewDocument = null;
  }

  nextDocument(next: boolean = true): void {
    let i = this.documents.indexOf(this.previewDocument);
    next ? i++ : i--;
    if (i < 0) {
      i = this.documents.length - 1;
    } else if (i >= this.documents.length) {
      i = 0;
    }
    this.viewDocument(this.documents[i]);
  }

  onPdfAfterLoadComplete(pdf: PDFDocumentProxy) {
    // temp save pdf page count for the on complete function
    this.pageCount = pdf.numPages;
  }

  onPdfPageRendered(e: any) {
    $("#loadingoverlay").show(); // in case the overlay is cancelled by other function somewhere else
    // after all pages are rendered, call onComplete
    this.pageCount--;
    if (this.pageCount === 0) {
      $("#loadingoverlay").hide();
    }
  }

  onPdfError(error: any) {
    this.toasterService.pop(
      "error",
      "",
      "Fehler beim Laden des PDF Dokuments. " + error
    );
  }
}
