import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, from } from 'rxjs';

import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../core/tools/utils';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.subscriptions.add(from(this.odataCoreService.Building.Query()
      .OrderBy("Name", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        return Utils.mapAllJSONDatesToDates(i);
      })))
      .subscribe(res => {
      if (res.length >= 1) {
        this.router.navigate(['/building', res[0].Identity, { outlets: { left: ['list'], right: ['building'] } }]);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
