import { Observable } from 'rxjs';

import { PropertyCore } from '../../odata/odata.coreapi';

export class BuildingListInputData {

  searchFilter: string = '';
  onSearchFilterChange(newValue: string) {
    this.searchFilter = newValue;
  }

  buildings$: Observable<PropertyCore.Building[]>;
  currentBuildingUT$: Observable<string>;
  lastIdx: number = -1;
  showMap: boolean = true;
  showGroupingWizardRow: boolean = true;
  showItemLinkButton: boolean = false;
  navigateToFirstListItem: boolean = false;
  translatedListTitle: string;
  scrollStyle = { 'max-height': 'calc(100vh - 245px)' };
}
