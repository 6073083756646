import {PropertyCore, Common, Operations} from '../odata/odata.coreapi';

export class Building implements PropertyCore.Building {

    // from interface
    Identity: string;    
    Id: number;
    Name: string;
    Address: Common.Address;
    Location: Common.Location;
    Size: number;
    YearOfConstruction: number;
    Created: Date;
    DataTimestamp: Date;
    DocumentTypeMarkers?: PropertyCore.DocumentTypeMarker[];
    Roles?: Operations.BuildingRole[];
    Account?: Operations.Account;
    User?: Operations.User;
    Equipments?: PropertyCore.Equipment[];
    Images?: PropertyCore.Image[];
    Events?: PropertyCore.Event[];
    Rooms?: PropertyCore.Room[];
    UsageTypes?: PropertyCore.UsageType[];
    Floors?: PropertyCore.Floor[];
    Statistics?: PropertyCore.BuildingStatistics;
    Users?: Operations.User[];
    Documents?: PropertyCore.Document[];
    KpiMarker?: PropertyCore.BuildingKpiMarker;
    AttributeTypeSets?: PropertyCore.AttributeTypeSet[];


   Status(x: number, y?: number, equipment: boolean = false) {
     if(equipment == false){
        if (this.Events[x]?.IsSkipped) {
          return IncidentStatus.IsSkipped;
        }
        else if (this.Events[x]?.KpiMarker != null && this.Events[x].KpiMarker.IsDone) {
          return IncidentStatus.Executed;
        }
        else if (this.Events[x]?.KpiMarker != null && this.Events[x].KpiMarker.IsOverdue) {
          return IncidentStatus.Due;
        }
        else if (this.Events[x]?.RefDate) {
            return IncidentStatus.Closed;
        }
        return IncidentStatus.Open;
      } else if(equipment == true){
        if (this.Equipments[x]?.Events[y]?.IsSkipped) {
          return IncidentStatus.IsSkipped;
        }
        else if (this.Equipments[x]?.Events[y]?.KpiMarker != null && this.Equipments[x]?.Events[y]?.KpiMarker.IsDone) {
          return IncidentStatus.Executed;
        }
        else if (this.Equipments[x]?.Events[y]?.KpiMarker != null && this.Equipments[x]?.Events[y]?.KpiMarker.IsOverdue) {
          return IncidentStatus.Due;
        }
        else if (this.Equipments[x]?.Events[y]?.RefDate) {
            return IncidentStatus.Closed;
        }
        return IncidentStatus.Open;
      }
    } 

    get documentCommissionStatus(): DocumentStatus {
      return this.getDocumentStatus(InvoiceDocumentTypeName.Commission);
    }
  
    get documentInvoiceStatus(): DocumentStatus {
      return this.getDocumentStatus(InvoiceDocumentTypeName.Invoice);
    }
  
    get documentOfferStatus(): DocumentStatus {
      return this.getDocumentStatus(InvoiceDocumentTypeName.Offer);
    }
  
    get documentProtocolStatus(): DocumentStatus {
      return this.getDocumentStatus(InvoiceDocumentTypeName.Protocol);
    }
  
    get documentReportStatus(): DocumentStatus {
      return this.getDocumentStatus(InvoiceDocumentTypeName.Report);
    }
  
      getDocumentStatus(invoiceDocumentTypeName: InvoiceDocumentTypeName) {
        const doc = this.Documents.find(d => d.DocumentType.Name === invoiceDocumentTypeName);
        
        if (doc) {
          return DocumentStatus.Exists;
        }
        switch (invoiceDocumentTypeName) {
          case InvoiceDocumentTypeName.Offer:
            // not required by any event type
            break;
          case InvoiceDocumentTypeName.Commission:
            // not required by any event type
            break;
          case InvoiceDocumentTypeName.Protocol:
            switch (this.Events[0].Type) {
              case PropertyCore.EventTypes.Inspection:
              case PropertyCore.EventTypes.FollowUpInspection:
              case PropertyCore.EventTypes.Maintenance:
                return DocumentStatus.MissingRequired;
            }
          case InvoiceDocumentTypeName.Report:
            switch (this.Events[0].Type) {
              case PropertyCore.EventTypes.Repair:
                return DocumentStatus.MissingRequired;
            }
          case InvoiceDocumentTypeName.Invoice:
            // not required by any event type
            break;
        }
        return DocumentStatus.Missing;
      }
}
export enum IncidentStatus {
    IsSkipped = 'IsSkipped',
    Closed = 'Closed',
    Executed = 'Executed',
    Due = 'Due',
    Open = 'Open'
  }
  
  export enum InvoiceDocumentTypeName {
    Offer = 'Angebot',
    Commission = 'Beauftragung',
    Protocol = 'Protokoll',
    Report = 'Regiebericht',
    Invoice = 'Rechnung'
  }

export enum DocumentStatus {
    Missing = 1,
    Exists = 2,
    MissingRequired = 3
  }