import { Injectable } from '@angular/core';
import { ODataCoreContext } from '../odata//odata.coreapi';

@Injectable()
export class ODataCoreService extends ODataCoreContext {

  constructor() {
    super();
  }

}
