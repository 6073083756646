import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Observable, Subscription, from} from 'rxjs';
import {flatMap, switchMap, tap, map} from 'rxjs/operators';

import {IncidentCreateOuputData} from '../../core/components/incident/incident-create-output-data';
import {Incident} from '../../core/model/incident';
import {IncidentState} from '../building-incident/incident-state.service';
import {NavigatorService} from '../../core/shared/navigator.service';
import {DocumentType} from '../wizard/document/document-wizard-model';
import { Utils } from '../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../core/odata/odataclient';

@Component({
  selector: 'app-equipment-incident-detail',
  templateUrl: './equipment-incident-detail.component.html',
  styleUrls: ['./equipment-incident-detail.component.scss']
})
export class EquipmentIncidentDetailComponent implements OnInit, OnDestroy {
  incident$: Observable<Incident>;
  defects$: Observable<Incident[]>;

  equipmentUT: string;
  buildingUT: string;
  incidentUT: string;
  showClose: boolean;
  isIncidentView: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: IncidentState,
    private navigator: NavigatorService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.incident$ = this.route.paramMap.pipe(
      tap(params => this.incidentUT = params.get('id')),
      switchMap((params: ParamMap) => this.getIncidentUsingIdentity(+params.get('id'))));
  
    this.defects$ = this.incident$.pipe(flatMap(inc => from(this.odataCoreService.Event.Query()
      .Filter(x => x
        .Equals(ODataPath.For("Parent", "Identity"), Guid.parse(inc.Identity)))
      .Expand(x => {
        x.Expand("Parent", y => {
          y.Select("Identity")
        }),
        x.Expand("Schedule"),
        x.Expand("Equipment", y => {
          y.Select("Name"),
          y.Select("Id"),
          y.Select("Identity"),
          y.Select("CustomId")
        }),
        x.Expand("Building", y => {
          y.Select("Name"),
          y.Select("Id"),
          y.Select("Identity")
        })
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        Utils.mapAllJSONDatesToDates(i);
        return Object.assign(new Incident(), i);
      })))
    ));

    this.subscriptions.add(this.route.root.firstChild.paramMap.subscribe((params: ParamMap) => this.buildingUT = params.get('id')));
    this.subscriptions.add(this.route.parent.firstChild.paramMap.subscribe((params: ParamMap) => this.equipmentUT = params.get('id')));
    this.showClose = this.route.snapshot.data.close;

    try {
      this.isIncidentView = this.route.snapshot.parent.firstChild.firstChild.firstChild.url[0].path === 'incident';
    } finally {
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleCloseRequest(): void {
    this.router.navigate(['building', this.buildingUT,
      {
        outlets: {
          left: ['building', 'view', {outlets: {tab: ['equipment']}}],
          right: ['equipment', this.equipmentUT, 'view', {outlets: {tab: ['incident', {outlets: {'incidentstyle': ['list']}}]}}]
        }
      }], {queryParamsHandling: 'merge'});

  }


  handleCreateRequest(data: IncidentCreateOuputData): void {
    this.router.navigate(['building', 'wizard', data.buildingUT, 'defect', data.incidentId]);
  }

  handleRefreshRequest(msg: string): void {
    this.state.notify(msg);
  }

  handlePostSaveRequest(): void {
    this.navigator.provideBackUrl(this.router.routerState.snapshot.url, IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT);
    this.router.navigate(['building', 'wizard', this.buildingUT, 'document', 'building'], {
      queryParams: {
        type: DocumentType.INCIDENT,
        incidentId: this.incidentUT,
        returnUrl: true
      }, queryParamsHandling: 'merge'
    });
  }


  getIncidentUsingIdentity(identity: number): Observable<Incident> {
  return from(this.odataCoreService.Event.Query()
    .Expand(x => {
      x.Expand("Response"),
        x.Expand("Schedule", xa => {
          xa.Expand("InspectionDuty", xaa => {
            xaa.Select("Id"),
              xaa.Expand("OperatorTask", xaaa => {
                xaaa.Select("Guidelines"),
                  xaaa.Select("Qualification")
              })
          }),
            xa.Expand("MaintenanceContract", xab => {
              xab.Select("StartDate"),
                xab.Expand("ServiceProvider", xabb => {
                  xabb.Select("Name")
                })
            })
        }),
        x.Expand("Building", xb => {
          xb.Select("Name"),
            xb.Select("Id"),
            xb.Select("Identity")
        }),
        x.Expand("Equipment", xc => {
          xc.Select("Name"),
            xc.Select("Id"),
            xc.Select("Identity"),
            xc.Select("CustomId"),
            xc.Expand("Room", xcc => {
              xcc.Select("Name"),
                xcc.Select("Id"),
                xcc.Select("Identity")
              xcc.Expand("Floor", xccc => {
                xccc.Select("Id"),
                  xccc.Select("Name")
              })
            })
        }),
        x.Expand("ServiceProvider", xd => {
          xd.Select("Name"),
            xd.Select("Id"),
            xd.Select("Identity")
        }),
        x.Expand("Module", xe => {
          xe.Select("Name"),
            xe.Select("Id"),
            xe.Select("Identity"),
            xe.Expand("ModuleType", xee => {
              xee.Select("Code"),
                xee.Select("Id"),
                xee.Select("Name")
            })
        })
      x.Expand("KpiMarker")
    })
    .Filter(x => x
      .EqualsField("Id", (identity)))
    .Exec().then(x => x.value))
    .pipe(map(res => {
      Utils.mapAllJSONDatesToDates(res[0]);
      return Object.assign(new Incident(), res[0])
    }))
}

}


