import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { combineLatest, forkJoin, Subscription, from } from "rxjs";
import { filter, switchMap, map } from "rxjs/operators";

import { PropertyCore, Common } from "../../../core/odata/odata.coreapi";
import { ACTION, ExtendedMaintenanceContractMapping } from "../../../core/model/extendedMaintenanceContractMapping";
import { Utils } from "../../../core/tools/utils";
import { IntegrityBoxComponent } from "../../../dashboard/dashboard-boxes/integrity-box/integrity-box.component";
import { ContractWizardModel } from "./contract-wizard-model";
import { ContractWizardNavigationPaths } from "./contract-wizard-navigation-paths.enum";
import { ContractWizardService } from "./contract-wizard.service";
import { Guid } from "guid-typescript";
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { ODataPath } from "../../../core/odata/odataclient";

@Component({
  selector: "app-contract-wizard",
  templateUrl: "./contract-wizard.component.html",
  styleUrls: ["./contract-wizard.component.scss"],
})
export class ContractWizardComponent implements OnInit, OnDestroy {
  navigateBack: string;

  currentPage: ContractWizardNavigationPaths;

  editMode: boolean = false;

  modelReady: boolean;

  w: number = 0;
  v: number = 0;
  t: number = 0;

  schedule_cycle: number[] = [];
  schedule_cycleunit: Common.IntervalUnits[] = [];
  schedule_cyclestart: Date[] = [];

  PAGE_GENERAL_DATA = ContractWizardNavigationPaths.GENERAL_DATA;
  PAGE_SERVICE_CONTACT = ContractWizardNavigationPaths.SERVICE_CONTACT;
  PAGE_ADD_SERVICE_CONTACT = ContractWizardNavigationPaths.ADD_SERVICE_CONTACT;
  PAGE_INSTALL_EQUIPMENTS = ContractWizardNavigationPaths.INSTALL_EQUIPMENTS;
  PAGE_MAINTENANCE_DATA = ContractWizardNavigationPaths.MAINTENANCE_DATA;

  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private service: ContractWizardService,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.modelReady = false;
    this.subscriptions.add(
      this.route.data.subscribe((data: { navigateBack: string }) => (this.navigateBack = data.navigateBack))
    );
    this.editMode = this.route.snapshot.data.editMode;
    this.currentPage = this.PAGE_GENERAL_DATA;

    // preload all contract data
    if (this.editMode) {
      const contract$ = this.route.paramMap.pipe(
        switchMap((params: ParamMap) => {
          return from(
            this.odataCoreService.MaintenanceContract.Query()
              .Expand((x) => {
                x.Expand("ServiceProvider");
              })
              .Filter((xa) => xa.EqualsField("Identity", Guid.parse(params.get("id"))))
              .Exec()
              .then((x) => x.value)
          ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])));
        })
      );

      const installedEquipment$ = this.route.paramMap.pipe(
        switchMap((params: ParamMap) => {
          return from(
            this.odataCoreService.MaintenanceJob.Query()
              .Filter((x) => x.Equals(ODataPath.For("Contract", "Identity"), Guid.parse(params.get("id"))))
              .Expand((x) => {
                x.Expand("Contract"),
                  x.Expand("Schedule"),
                  x.Expand("Equipment", (y) => {
                    y.Expand("EquipmentType", (z) => {
                      z.Expand("CostGroup");
                    });
                    y.Expand("Room", (z) => {
                      z.Expand("Floor");
                    });
                    y.Expand("Building");
                  });
              })
              .Exec()
              .then((x) => x.value)
          ).pipe(
            map((res) =>
              res.map((i) => {
                Utils.mapAllJSONDatesToDates(i);
                return Object.assign(new ExtendedMaintenanceContractMapping(), i);
              })
            )
          );
        })
      );

      this.subscriptions.add(
        combineLatest(contract$, installedEquipment$).subscribe(([c, ie]) => {
          this.service.startWizard(c, ie);
          this.modelReady = true;
        })
      );
    } else {
      if (this.route.snapshot.paramMap.get("installEquipmentUT")) {
        this.subscriptions.add(
          this.route.paramMap
            .pipe(
              filter((params) => params.get("installEquipmentUT") !== null),
              switchMap((val) => {
                return from(
                  this.odataCoreService.Equipment.Query()
                    .Expand((x) => {
                      x.Expand("Building"), x.Expand("EquipmentType"), x.Expand("Room", (y) => y.Expand("Floor"));
                    })
                    .Filter((xa) => xa.EqualsField("Identity", Guid.parse(val.get("installEquipmentUT"))))
                    .Exec()
                    .then((x) => x.value)
                ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])));
              })
            )
            .subscribe((res) => {
              this.service.startWizard(null, [ExtendedMaintenanceContractMapping.createFromEquipment(res)]);
              this.modelReady = true;
            })
        );
      } else {
        this.service.startWizard();
        this.modelReady = true;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async saveOrUpdate(model: ContractWizardModel) {
    if (this.editMode) {
      // filter out only filled ie when the user clicks save in step 2
      model.installEquipments = model.installEquipments.filter((ie) => ie.Schedule != null);

      const removedIEs = model.installEquipments.filter((x) => x.action === ACTION.REMOVE);
      const addedIEs = model.installEquipments.filter((x) => x.action === ACTION.ADD);

      this.subscriptions.add(
        from(
          this.odataCoreService.MaintenanceContract.Patch()
            .ValueType(this.odataCoreService.ODataTypes().MaintenanceContract())
            .Key(Guid.parse(model.contract.Identity))
            .ValueProperty("Title", model.contract.Title)
            .ValueProperty("IsGeneralAgreement", model.contract.IsGeneralAgreement)
            .ValueProperty("StartDate", model.contract.StartDate)
            .ValueProperty("EndDate", model.contract.EndDate)
            .ValueProperty("AmountNet", model.contract.AmountNet || 0)
            .ValueProperty("CostServiceHourNetAmount", model.contract.CostServiceHourNetAmount)
            .ValueProperty("CostApproachNetAmount", model.contract.CostApproachNetAmount)
            .ValueProperty("CancellationDigit", model.contract.CancellationDigit ? model.contract.CancellationDigit : 0)
            .ValueProperty("CancellationUnit", model.contract.CancellationUnit)
            .ValueProperty("CancellationPeriod", model.contract.CancellationPeriod)
            .ValueProperty("CancellationCalendar", model.contract.CancellationCalendar)
            .ValuePropertyBinding(
              "ServiceProvider",
              this.odataCoreService.ServiceProvider.Get()
                .Key(Guid.parse(model.contract.ServiceProvider.Identity))
                .Bind()
            )
            .Exec()
        ).subscribe((res) => {
          if (!removedIEs.length && !addedIEs.length) {
            this.service.resetCurrentContract();
            this.processPageChangeRequest(ContractWizardNavigationPaths.EXIT);
          }
        })
      );

      for (let x = 0; x < removedIEs.length; x++) {
        await this.odataCoreService.MaintenanceJob.Delete().Key(Guid.parse(removedIEs[x].Identity)).Exec();
      }

      if (addedIEs.length) {
        this.subscriptions.add(
          forkJoin(
            ...addedIEs.map((addeddIE) => {
              for (let u = 0; u < addedIEs.length; u++) {
                this.schedule_cycle[u] = addedIEs[u].Schedule.Cycle;
                this.schedule_cycleunit[u] = addedIEs[u].Schedule.CycleUnit;
                this.schedule_cyclestart[u] = addedIEs[u].Schedule.CycleStart;
              }

              return from(
                this.odataCoreService.MaintenanceJob.Post()
                  .ValueType(this.odataCoreService.ODataTypes().MaintenanceJob())
                  .ValueProperty("Identity", Utils.guid())
                  .ValuePropertyBinding(
                    "Equipment",
                    this.odataCoreService.Equipment.Get().Key(Guid.parse(addeddIE.Equipment.Identity)).Bind()
                  )
                  .ValuePropertyBinding(
                    "Contract",
                    this.odataCoreService.MaintenanceContract.Get().Key(Guid.parse(model.contract.Identity)).Bind()
                  )
                  .Exec()
                  .then((x) => {
                    from(
                      this.odataCoreService.MaintenanceJob.Query()
                        .Filter((xb) =>
                          xb.Equals(ODataPath.For("Contract", "Identity"), Guid.parse(model.contract.Identity))
                        )
                        .Expand((xb) => {
                          xb.Expand("Schedule");
                        })
                        .Exec()
                        .then((x) => x.value)
                    )
                      .pipe(
                        map((res) =>
                          res.map((i) => {
                            Utils.mapAllJSONDatesToDates(i);
                            return Object.assign(new ExtendedMaintenanceContractMapping(), i);
                          })
                        )
                      )
                      .subscribe((i) => {
                        let i_filtered = i.filter((x) => x.Schedule.CycleStart == null);
                        let z = 0;
                        let u = 0;
                        if (this.v == 0) {
                          while (z < i_filtered.length) {
                            from(
                              this.odataCoreService.Schedule.Patch()
                                .ValueType(this.odataCoreService.ODataTypes().Schedule())
                                .Key(Guid.parse(i_filtered[z].Schedule.Identity))
                                .ValueProperty("Cycle", this.schedule_cycle[u])
                                .ValueProperty("CycleUnit", this.schedule_cycleunit[u])
                                .ValueProperty("CycleStart", this.schedule_cyclestart[u])
                                .Exec()
                            );
                            z++;
                            u++;
                          }
                          this.v++;
                        }
                      });
                  })
              );
            })
          ).subscribe((res) => {
            this.service.resetCurrentContract();
            this.processPageChangeRequest(ContractWizardNavigationPaths.EXIT);
          })
        );
      } else {
        this.service.resetCurrentContract();
        this.processPageChangeRequest(ContractWizardNavigationPaths.EXIT);
      }
    } else {
      // create mode
      model.contract.Identity = Utils.guid();
      this.subscriptions.add(
        from(
          this.odataCoreService.MaintenanceContract.Post()
            .ValueType(this.odataCoreService.ODataTypes().MaintenanceContract())
            .ValueProperty("Identity", model.contract.Identity)
            .ValueProperty("Title", model.contract.Title)
            .ValueProperty("IsGeneralAgreement", model.contract.IsGeneralAgreement)
            .ValueProperty("StartDate", model.contract.StartDate)
            .ValueProperty("EndDate", model.contract.EndDate)
            .ValueProperty("AmountNet", model.contract.AmountNet || 0)
            .ValueProperty("CostServiceHourNetAmount", model.contract.CostServiceHourNetAmount)
            .ValueProperty("CostApproachNetAmount", model.contract.CostApproachNetAmount)
            .ValueProperty("CancellationDigit", model.contract.CancellationDigit ? model.contract.CancellationDigit : 0)
            .ValueProperty("CancellationUnit", model.contract.CancellationUnit)
            .ValueProperty("CancellationPeriod", model.contract.CancellationPeriod)
            .ValueProperty("CancellationCalendar", model.contract.CancellationCalendar)
            .ValuePropertyBinding(
              "ServiceProvider",
              this.odataCoreService.ServiceProvider.Get()
                .Key(Guid.parse(model.contract.ServiceProvider.Identity))
                .Bind()
            )
            .ValuePropertyBinding(
              "Account",
              this.odataCoreService.Account.Get()
                .Key(Guid.parse(localStorage.getItem("mandator")))
                .Bind()
            )
            .ValuePropertyBinding(
              "User",
              this.odataCoreService.User.Get()
                .Key(Guid.parse(localStorage.getItem("infoUserIdentity")))
                .Bind()
            )
            .Exec()
        ).subscribe((res) => {
          // filter out only filled ie when the user clicks save in step 2
          model.installEquipments = model.installEquipments.filter(
            (ie) => ie.Schedule.Cycle && ie.Schedule.CycleUnit && ie.Schedule.CycleStart
          );

          const allCount = model.installEquipments ? model.installEquipments.length : 0;
          // if any install equipments assigned
          if (allCount) {
            this.subscriptions.add(
              forkJoin(
                ...model.installEquipments.map((installEquipment) => {
                  for (let u = 0; u < model.installEquipments.length; u++) {
                    this.schedule_cycle[u] = model.installEquipments[u].Schedule.Cycle;
                    this.schedule_cycleunit[u] = model.installEquipments[u].Schedule.CycleUnit;
                    this.schedule_cyclestart[u] = model.installEquipments[u].Schedule.CycleStart;
                  }

                  return from(
                    this.odataCoreService.MaintenanceJob.Post()
                      .ValueType(this.odataCoreService.ODataTypes().MaintenanceJob())
                      .ValueProperty("Identity", Utils.guid())
                      .ValuePropertyBinding(
                        "Equipment",
                        this.odataCoreService.Equipment.Get()
                          .Key(Guid.parse(installEquipment.Equipment.Identity))
                          .Bind()
                      )
                      .ValuePropertyBinding(
                        "Contract",
                        this.odataCoreService.MaintenanceContract.Get().Key(Guid.parse(model.contract.Identity)).Bind()
                      )
                      .Exec()
                      .then((x) => {
                        from(
                          this.odataCoreService.MaintenanceJob.Query()
                            .Filter((xb) =>
                              xb.Equals(ODataPath.For("Contract", "Identity"), Guid.parse(model.contract.Identity))
                            )
                            .Expand((xb) => {
                              xb.Expand("Schedule");
                            })
                            .Exec()
                            .then((x) => x.value)
                        )
                          .pipe(
                            map((res) =>
                              res.map((i) => {
                                Utils.mapAllJSONDatesToDates(i);
                                return Object.assign(new ExtendedMaintenanceContractMapping(), i);
                              })
                            )
                          )
                          .subscribe((i) => {
                            let z = 0;
                            if (this.w == 0) {
                              while (z < i.length) {
                                from(
                                  this.odataCoreService.Schedule.Patch()
                                    .ValueType(this.odataCoreService.ODataTypes().Schedule())
                                    .Key(Guid.parse(i[z].Schedule.Identity))
                                    .ValueProperty("Cycle", this.schedule_cycle[z])
                                    .ValueProperty("CycleUnit", this.schedule_cycleunit[z])
                                    .ValueProperty("CycleStart", this.schedule_cyclestart[z])
                                    .Exec()
                                );
                                z++;
                              }
                              this.w++;
                            }
                          });
                      })
                  );
                })
              ).subscribe((res2) => {
                this.service.resetCurrentContract();
                this.processPageChangeRequest(ContractWizardNavigationPaths.EXIT);
              })
            );
          } else {
            this.service.resetCurrentContract();
            this.processPageChangeRequest(ContractWizardNavigationPaths.EXIT);
          }
        })
      );
    }
  }

  processPageChangeRequest(pageChangeEvent: ContractWizardNavigationPaths): void {
    switch (pageChangeEvent) {
      case ContractWizardNavigationPaths.START: {
        this.currentPage = this.PAGE_GENERAL_DATA;
        break;
      }
      case ContractWizardNavigationPaths.EXIT: {
        if (this.navigateBack && this.navigateBack === "contract") {
          const contractUT: string = this.route.snapshot.paramMap.get("id");
          if (contractUT) {
            this.router.navigate(["/contract", contractUT, { outlets: { left: ["list"], right: ["contract"] } }]);
          } else {
            this.router.navigate(["/contract"]);
          }
        } else if (this.navigateBack && this.navigateBack === "dashboard-integrity-equipment-no-contract") {
          const installEquipmentUT: string = this.route.snapshot.paramMap.get("installEquipmentUT");
          this.router.navigate([
            "/dashboard",
            {
              outlets: {
                left: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_NO_CONTRACT],
                right: ["equipment", installEquipmentUT, "contract", "choose"],
              },
            },
          ]);
        } else {
          console.error("Could not find a navigation route for contract wizard!");
        }
        break;
      }
      case ContractWizardNavigationPaths.NEXT: {
        if (this.currentPage === this.PAGE_GENERAL_DATA) {
          this.currentPage = this.PAGE_SERVICE_CONTACT;
        } else if (this.currentPage === this.PAGE_SERVICE_CONTACT) {
          this.currentPage = this.PAGE_INSTALL_EQUIPMENTS;
        } else if (this.currentPage === this.PAGE_INSTALL_EQUIPMENTS) {
          this.currentPage = this.PAGE_MAINTENANCE_DATA;
        }
        break;
      }
      case ContractWizardNavigationPaths.PREVIOUS: {
        if (this.currentPage === this.PAGE_SERVICE_CONTACT) {
          this.currentPage = this.PAGE_GENERAL_DATA;
        } else if (this.currentPage === this.PAGE_INSTALL_EQUIPMENTS) {
          this.currentPage = this.PAGE_SERVICE_CONTACT;
        } else if (this.currentPage === this.PAGE_MAINTENANCE_DATA) {
          this.currentPage = this.PAGE_INSTALL_EQUIPMENTS;
        }
        break;
      }
      case ContractWizardNavigationPaths.GENERAL_DATA: {
        if (
          this.currentPage === this.PAGE_GENERAL_DATA ||
          this.currentPage === this.PAGE_SERVICE_CONTACT ||
          this.currentPage === this.PAGE_ADD_SERVICE_CONTACT ||
          this.currentPage === this.PAGE_INSTALL_EQUIPMENTS ||
          this.currentPage === this.PAGE_MAINTENANCE_DATA
        ) {
          this.currentPage = this.PAGE_GENERAL_DATA;
        }
        break;
      }
      case ContractWizardNavigationPaths.SERVICE_CONTACT: {
        if (
          this.currentPage === this.PAGE_SERVICE_CONTACT ||
          this.currentPage === this.PAGE_ADD_SERVICE_CONTACT ||
          this.currentPage === this.PAGE_INSTALL_EQUIPMENTS ||
          this.currentPage === this.PAGE_MAINTENANCE_DATA
        ) {
          this.currentPage = this.PAGE_SERVICE_CONTACT;
        }
        break;
      }
      case ContractWizardNavigationPaths.ADD_SERVICE_CONTACT: {
        if (
          this.currentPage === this.PAGE_SERVICE_CONTACT ||
          this.currentPage === this.PAGE_ADD_SERVICE_CONTACT ||
          this.currentPage === this.PAGE_INSTALL_EQUIPMENTS ||
          this.currentPage === this.PAGE_MAINTENANCE_DATA
        ) {
          this.currentPage = this.PAGE_ADD_SERVICE_CONTACT;
        }
        break;
      }
      case ContractWizardNavigationPaths.INSTALL_EQUIPMENTS: {
        if (this.currentPage === this.PAGE_INSTALL_EQUIPMENTS || this.currentPage === this.PAGE_MAINTENANCE_DATA) {
          this.currentPage = this.PAGE_INSTALL_EQUIPMENTS;
        }
        break;
      }
      case ContractWizardNavigationPaths.MAINTENANCE_DATA: {
        if (this.currentPage === this.PAGE_MAINTENANCE_DATA) {
          this.currentPage = this.PAGE_MAINTENANCE_DATA;
        }
        break;
      }
    }
  }

  saveNewServiceContact(serviceContact: PropertyCore.ServiceProvider) {
    this.service.getCurrentContract().contract.ServiceProvider = serviceContact;
    this.processPageChangeRequest(ContractWizardNavigationPaths.SERVICE_CONTACT);
  }
}
