import { Component, Input } from '@angular/core';

import { Incident } from './../../../../model/incident';

@Component({
  selector: 'app-incident-row',
  templateUrl: './incident-row.component.html',
  styleUrls: ['./incident-row.component.scss']
})
export class IncidentRowComponent {

  @Input()
  incident: Incident;

  @Input()
  showBuildingData: boolean;

  @Input()
  showEquipmentData: boolean;

  constructor() {}

}
