import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, Subscription, from} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {PropertyCore} from '../../../core/odata/odata.coreapi';
import {ConfirmationModal} from '../../../core/popup/confirmation.modal';
import {ILogger} from '../../../core/shared/logger.service';
import {InstallEquipmentService} from '../../installEquipment.service';
import {Utils} from "../../../core/tools/utils";
import { Guid } from 'guid-typescript';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { ODataPath } from '../../../core/odata/odataclient';


@Component({
  selector: 'app-module-detail-edit',
  templateUrl: './module-detail-edit.component.html',
  styleUrls: ['./module-detail-edit.component.scss']
})
export class ModuleDetailEditComponent implements OnInit, OnDestroy {

  origInstallModule: PropertyCore.Module;
  installModule: PropertyCore.Module;
  installModuleFloor: PropertyCore.Floor;


  floors: PropertyCore.Floor[];
  rooms: PropertyCore.Room[];

  newRoomName: string;

  installEquipmentUT: string;
  buildingUT: string;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  logger: ILogger;

  constructor(
    private installEquipmentService: InstallEquipmentService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.root.firstChild.paramMap.subscribe(params => (this.buildingUT = params.get('id'))));

    const installEquipmentUT$: Observable<string> = this.route.parent.firstChild.paramMap.pipe(map(params => params.get('id')));

    const floors$: Observable<PropertyCore.Floor[]> = installEquipmentUT$.pipe(switchMap(val =>
      from(this.odataCoreService.Equipment.Get()
        .Key(Guid.parse(val))
        .Expand(x => {
          x.Expand("Building", y => {
            y.Expand("Floors")
          })
        })
        .Exec()
        .then(x => x.value))
        .pipe(map(res => res[0].Building.Floors.map(f => Utils.mapAllJSONDatesToDates(f))))
    ));

    let module$: Observable<PropertyCore.Module> = this.route.paramMap.pipe(switchMap(params => {
      return from(this.odataCoreService.Module.Query()
        .Expand(x => {
          x.Expand("ModuleType"),
            x.Expand("Equipment"),
            x.Expand("Room", y =>
              y.Expand("Floor"))
        })
        .Filter(xa =>
          xa.EqualsField("Identity", Guid.parse(params.get('id'))))
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])));
    }))

    this.subscriptions.add(
      combineLatest(installEquipmentUT$, module$, floors$).subscribe(([_equipmentUT, _module, _floors]) => {
        this.installEquipmentUT = _equipmentUT;
        this.origInstallModule = Object.assign({}, _module);
        this.installModule = _module;
        this.installModuleFloor = this.installModule.Room.Floor;
        this.floors = _floors;
        if (this.installModuleFloor) {
          this.subscriptions.add(
            from(this.odataCoreService.Room.Query()
              .Filter(x => x
                .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.buildingUT)).And
                .Equals(ODataPath.For("Floor", "Id"), this.installModuleFloor.Id))
              .Expand(x => {
                x.Expand("Floor")
                x.Expand("Statistics")
              })
              .Exec()
              .then(x => x.value))
              .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
              .subscribe(res => (this.rooms = res))
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('ModuleDetailEdit._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('ModuleDetailEdit._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('ModuleDetailEdit._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ModuleDetailEdit._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate([
              '/building',
              this.buildingUT,
              {
                outlets: {
                  left: ['equipment', this.installEquipmentUT, 'view', {outlets: {tab: ['modules']}}],
                  right: ['equipment', 'module', this.installModule.Identity]
                }
              }
            ]);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate([
        '/building',
        this.buildingUT,
        {
          outlets: {
            left: ['equipment', this.installEquipmentUT, 'view', {outlets: {tab: ['modules']}}],
            right: ['equipment', 'module', this.installModule.Identity]
          }
        }
      ]);
    }
  }

  async save(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    if (this.installModule.Room != null) {
      await this.odataCoreService.Module.Patch()
        .ValueType(this.odataCoreService.ODataTypes().Module())
        .Key(Guid.parse(this.installModule.Identity))
        .ValueProperty("Equipment", this.installModule.Equipment)
        .ValueProperty("ModuleType", this.installModule.ModuleType)
        .ValueProperty("Room", this.installModule.Room)
        .ValueProperty("CustomId", this.installModule.CustomId)
        .ValueProperty("Quantity", this.installModule.Quantity)
        .ValueProperty("Comment", this.installModule.Comment)
        .ValuePropertyBinding("Equipment", this.odataCoreService.Equipment.Get().Key(Guid.parse(this.installModule.Equipment.Identity)).Bind())
        .ValuePropertyBinding("Room", this.odataCoreService.Room.Get().Key(Guid.parse(this.installModule.Room.Identity)).Bind())
        .ValuePropertyBinding("ModuleType", this.odataCoreService.ModuleType.Get().Key(this.installModule.ModuleType.Code).Bind())
        .Exec()

    } else {
      await this.odataCoreService.Module.Patch()
        .ValueType(this.odataCoreService.ODataTypes().Module())
        .Key(Guid.parse(this.installModule.Identity))
        .ValueProperty("Equipment", this.installModule.Equipment)
        .ValueProperty("ModuleType", this.installModule.ModuleType)
        .ValueProperty("Room", this.installModule.Room)
        .ValueProperty("CustomId", this.installModule.CustomId)
        .ValueProperty("Quantity", this.installModule.Quantity)
        .ValueProperty("Comment", this.installModule.Comment)
        .ValuePropertyBinding("Equipment", this.odataCoreService.Equipment.Get().Key(Guid.parse(this.installModule.Equipment.Identity)).Bind())
        .ValuePropertyBinding("ModuleType", this.odataCoreService.ModuleType.Get().Key(this.installModule.ModuleType.Code).Bind())
        .Exec()
    }
    // hard refresh url so that modules on the left side are reloaded
    const url = this.router.createUrlTree(['/building', this.buildingUT,
      {
        outlets: {
          left: ['equipment', this.installEquipmentUT, 'view', { outlets: { tab: ['modules'] } }],
          right: ['equipment', 'module', this.installModule.Identity]
        }
      }]).toString();
    this.router.navigateByUrl('/skipThisPage', { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
  }

  async onFloorChange(selectedFloor: PropertyCore.Floor): Promise<void> {
    this.installModuleFloor = selectedFloor;
    if (selectedFloor) {
      let res = await this.odataCoreService.Room.Query()
        .Filter(x => x
          .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.buildingUT)).And
          .Equals(ODataPath.For("Floor", "Id"), this.installModuleFloor.Id))
        .Expand(x => {
          x.Expand("Floor")
          x.Expand("Statistics")
        })
        .Exec()
        .then(x => x.value);
         this.rooms = res.map(i => Utils.mapAllJSONDatesToDates(i))
    }
  }
 
  openNewRoomPopup(content): void {
    this.modalService.open(content).result.then(async result => {
      // on save click
      const guid = Utils.guid();
      let res = await this.odataCoreService.Room.Post()
        .ValueType(this.odataCoreService.ODataTypes().Room())
        .ValueProperty("Identity", guid)
        .ValueProperty("Name", this.newRoomName)
        .ValuePropertyBinding("Floor", this.odataCoreService.Floor.Get().Key(this.installModuleFloor.Id).Bind())
        .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.buildingUT)).Bind())
        .Exec();
          this.installModule.Room = <PropertyCore.Room>{
            Id: res,
            Identity: guid,
            Name: this.newRoomName,
            Floor: this.installModuleFloor
          };
          this.rooms.push(this.installModule.Room);
          this.newRoomName = null;
        }).catch(() => {
      // do nothing, just stay on page
    });
  }

  compareId(val1: { Id: number }, val2: { Id: number }): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  openInfoDialog3() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('ModuleWizardData._modal_customName_title');
    modalRef.componentInstance.message = this.translateService.instant('ModuleWizardData._modal_customName_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('ModuleWizardData._modal_customName_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }

}
