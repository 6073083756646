import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import {map, startWith, switchMap, tap} from 'rxjs/operators';

import {IncidentState} from './../../../building/building-incident/incident-state.service';
import {IncidentDetailLinkOutputData} from './../../../core/components/incident/incident-detail-link-output-data';
import {IncidentListInputData} from './../../../core/components/incident/incident-list-input-data';
import {IncidentType} from './../../../core/components/incident/incident-type';
import {IncidentView} from './../../../core/components/incident/incident-view';
import {NavigatorService} from './../../../core/shared/navigator.service';
import {ODataCoreService} from "../../../core/odata-services/odata.coreapi.service";
import {ODataPath } from '../../../core/odata/odataclient';
import { Utils } from '../../../core/tools/utils';
import { Incident } from 'app/core/model/incident';

@Component({
  selector: 'app-conformity-list',
  templateUrl: './conformity-list.component.html',
  styleUrls: ['./conformity-list.component.scss']
})
export class ConformityListComponent implements OnInit {

  currentViewTab: IncidentView;

  currentTypeTab: IncidentType = IncidentType.NONE;

  inputData: IncidentListInputData = new IncidentListInputData();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: IncidentState,
    private navigator: NavigatorService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.inputData.navigateToFirstListItem = false;
    this.inputData.allowedViews = [IncidentView.TODOFUTURE, IncidentView.OVERDUE, IncidentView.DEFECT, IncidentView.MISSINGDOCUMENT];
    this.inputData.showBuildingData = true;

    this.inputData.allIncidents$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(action => {
        if (action === IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT) {
          this.inputData.navigateToFirstListItem = true;
        }

        return from(this.odataCoreService.Event
          .Query()
          .Filter(x => x
            .Equals(ODataPath.For("KpiMarker", "IsActive"), true).And
            .Equals(ODataPath.For("KpiMarker", "IsInFocus"), true))
          .Select("Identity", "Id", "Type", "Guidelines", "DueDate")
          .Expand(x => {
            x.Expand("Schedule", y => {
              y.Select("CycleUnit");
              y.Select("Cycle");
              y.Expand("InspectionDuty", y => {
                y.Select("OperatorTask");
                y.Expand("OperatorTask", z => {
                  z.Select("Guidelines");
                  z.Select("Qualification");
                });
              });
              y.Expand("MaintenanceContract", z => {
                z.Select("StartDate");
                z.Expand("ServiceProvider", za => {
                  za.Select("Name");
                })
              });
            });
            x.Expand("Building", y => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
            });
            x.Expand("Equipment", y => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
              y.Select("CustomId");
            });
            x.Expand("Documents", y => {
              y.Select("DocumentType"),
                y.Expand("DocumentType", z => {
                  z.Select("Id"),
                    z.Select("Name")
                })
            });
            x.Expand("ServiceProvider", y => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
            });
            x.Expand("KpiMarker");
          })
          .OrderBy("DueDate", "asc")
          .Exec()
          .then(x => 
              x.value
          ))
          .pipe(map(res => res.map(i => {
           Utils.mapAllJSONDatesToDates(i);
            return Object.assign(new Incident(), i)
          })))
          .pipe(tap(inc => {
            inc.sort((a, b) => {
              return (b.DueDate ? b.DueDate.getTime() : 0) - (a.DueDate ? a.DueDate.getTime() : 0);
            });
          }));

      }));

    this.inputData.currentView$ = this.route.paramMap
      .pipe(map(p => IncidentView.getIncidentView(p.get('tab'))), tap(val => this.currentViewTab = val));

    this.inputData.currentType$ = this.route.paramMap
      .pipe(map(p => IncidentType.getIncidentType(p.get('subtab'))), tap(val => this.currentTypeTab = val));

    this.inputData.currentIncidentId$ = this.route.parent.children[1].paramMap
      .pipe(map(params => +params.get('incidentId')));

    if (this.navigator.getNavigationAction() === IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT) {
      this.inputData.navigateToFirstListItem = true;
    }
    this.navigator.finishNavigation();
  }

  handleViewTabChangeRequest($event: string) {
    this.currentViewTab = IncidentView.getIncidentView($event);
    this.router.navigate([{tab: [this.currentViewTab]}], {relativeTo: this.route});
  }

  handleTypeTabChangeRequest($event: string) {
    this.currentTypeTab = IncidentType.getIncidentType($event);
    this.router.navigate([{tab: [this.currentViewTab], subtab: [this.currentTypeTab]}], {relativeTo: this.route});
  }

  handleDetailPageRequest($event: IncidentDetailLinkOutputData) {
    if (this.currentViewTab === IncidentView.MISSINGDOCUMENT) {
      this.router.navigate([
        'dashboard',
        {
          outlets: {
            left: ['conformity', 'list', {tab: this.currentViewTab, subtab: $event.type}],
            right: ['incident', $event.id, 'document-upload']
          }
        }
      ]);
    } else {
      const id = $event.id;
      const subTab = $event.type;
      this.router.navigate([
        'dashboard',
        {
          outlets: {
            left: ['conformity', 'list', {tab: this.currentViewTab, subtab: subTab}],
            right: ['conformity', 'detail', id]
          }
        }
      ]);
    }
  }
}
