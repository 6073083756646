import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {Subscription, from, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {InstallEquipmentService} from './../../../../building/installEquipment.service';
import {EditInspectionDutyGroup} from './../../../../core/components/equipment/edit-inspection-duties/editInspectionDutyGroup';
import {EquipmentEditInspectionDutiesInputData} from './../../../../core/components/equipment/equipment-edit-inspection-duties-input-data';
import {EquipmentEditInspectionDutiesSaveOutputData} from './../../../../core/components/equipment/equipment-edit-inspection-duties-save-output-data';
import {IntegrityBoxComponent} from './../../../dashboard-boxes/integrity-box/integrity-box.component';
import {EquipmentNoInspectionDutiesState} from './../equipment-no-inspection-duties-state.service';
import { Guid } from 'guid-typescript';
import { Utils } from '../../../../core/tools/utils';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../../core/odata/odataclient';
import { InspectionDuty } from '../../../../core/model/inspectionDuty';
import { PropertyCore, Common } from '../../../../core/odata/odata.coreapi';
import { EditOperatorTask } from 'app/core/components/equipment/edit-inspection-duties/editOperatorTask';
import { InspectiondutyWizardComponent} from 'app/building/wizard/inspectionduty/inspectionduty-wizard.component';

@Component({
  selector: 'app-equipment-set-inspection-duties',
  templateUrl: './equipment-set-inspection-duties.component.html',
  styleUrls: ['./equipment-set-inspection-duties.component.scss']
})
export class EquipmentSetInspectionDutiesComponent implements OnInit, OnDestroy {
  
  inputData = new EquipmentEditInspectionDutiesInputData();

  installEquipmentUT: string;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private installEquipmentService: InstallEquipmentService,
    private translateService: TranslateService,
    private state: EquipmentNoInspectionDutiesState,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
   
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleCloseRequest(): void {
    this.router.navigate([
      '/dashboard',
      {
        outlets: {
          left: ['board'],
          right: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_NO_INSPECTION_DUTIES]
        }
      }
    ], {queryParamsHandling: 'merge'});
  }

  handleSaveRequest(): void {
        this.state.notify(EquipmentNoInspectionDutiesState.ACTION_SET_INSPECTION_DUTY);
        this.toasterService.pop('success', '', this.translateService.instant('DashboardIntegrityEquipmentsNoInspectionDuties._toastr_setInspectionDutySuccess'));
  }

  getInspectionDuties(UT: string): Observable<InspectionDuty[]> {
    return from(this.odataCoreService.InspectionDuty.Query()
      .Filter(x => x
        .Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(UT)))
      .Exec().then(x => x.value))
      .pipe(map(idArray => idArray.map(idData => this.mapInspectionDutiesListAPIintoInspectionDuties(idData))));
  }


  private mapInspectionDutiesListAPIintoInspectionDuties(data: any): InspectionDuty {
    const id: InspectionDuty = new InspectionDuty();

    id.Identity = data.UniqToken;
    id.Id = data.Id;
    id.IsDisabled = data.IsDisabled;
    if (data.InstallModuleId) {
      id.Module = <PropertyCore.Module>{ Id: data.InstallModuleId, CustomId: data.IMCustomId, Name: data.IMLongName };
    }
    id.OperatorTask = <PropertyCore.OperatorTask>{
      Id: data.OperatorTaskId,
      Guidelines: data.OperatorTaskGuidelines,
      Deadline: data.OperatorTaskDeadline,
      Qualification: data.OperatorTaskQualification,
      Subject: data.OperatorTaskSubject,
      Type: data.OperatorTaskType,
      BuildingType: data.OperatorTaskBuildingType,
      Cycle: data.OperatorTaskCycle,
      CycleUnit: this.mapCycleUnit(data.OperatorTaskCycleUnitDescription)
    };
    if (data.InstallEquipmentId) {
      id.Equipment = <PropertyCore.Equipment>{
        Id: data.InstallEquipmentId,
        CustomId: data.IECustomId,
        Name: data.IELongName,
        HasInspectionDutiesActivated: data.IEHasInspectionDutiesActivated
      };
    }
    id.Schedule = <PropertyCore.Schedule>{
      Cycle: data.Cycle,
      CycleUnit: this.mapCycleUnit(data.CycleUnit),
      CycleStart: Utils.jsonToDate(data.SeriesStart)
    };

    return id;
  }

  private mapCycleUnit(data: number): Common.IntervalUnits {
    let cycleUnit: Common.IntervalUnits;
    switch (data) {
      case 0:
        cycleUnit = Common.IntervalUnits.Undefined;
        break;
      case 1:
        cycleUnit = Common.IntervalUnits.Year;
        break;
      case 2:
        cycleUnit = Common.IntervalUnits.Month;
        break;
      case 3:
        cycleUnit = Common.IntervalUnits.Week;
        break;
      case 4:
        cycleUnit = Common.IntervalUnits.Day;
        break;
    }
    return cycleUnit;
  }
}


