import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import { ODataPath } from '../../core/odata/odataclient';
import { map } from 'rxjs/operators';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private odataCoreService: ODataCoreService) {
  }

  ngOnInit() {
    this.subscriptions.add(from(this.odataCoreService.ServiceProvider.Query()
      .Filter(x =>
        x.Equals(ODataPath.For("Account", "Identity"), Guid.parse(localStorage.getItem('mandator'))))
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => {
      if (res.length >= 1) {
        this.router.navigate(['/service-contact', res[0].Identity, {
          outlets: {
            left: ['list'],
            right: ['service-contact']
          }
        }]);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
