import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class EquipmentNotScheduledInspectionDutiesState {

  public static readonly ACTION_SCHEDULED_INSPECTION_DUTY = 'action_scheduled_inspection_duty';

  private subject: Subject<string> = new Subject();

  constructor() { }

  notify(notificationtext: string) {
    this.subject.next(notificationtext);
  }

  registerForNotification(): Observable<string> {
    return this.subject.asObservable();
  }


}
