import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppConfig } from './../bootstrap/app.config';
import { AuthService } from './auth.service';



@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private conf: AppConfig, private router: Router) {
    console.log('C:AuthGuard: AuthService, Router');
  }

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn()
      .pipe(tap(isIn => {
        if (!isIn) {
          const url = this.conf.getLogoutUrl();
          if (!url) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          } else {
            sessionStorage.returnUrl = state.url;
            window.location.replace(url);
          }
        }
      }));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
