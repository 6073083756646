import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';

import {PropertyCore} from '../../../core/odata/odata.coreapi';
import {AuthService} from '../../../core/auth/auth.service';
import {AttributeDTO} from '../../../core/model/attributeDTO';
import {ConfirmationModal} from '../../../core/popup/confirmation.modal';
import {Utils} from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";


@Component({
  selector: 'app-module-feature-edit',
  templateUrl: './module-feature-edit.component.html',
  styleUrls: ['./module-feature-edit.component.scss']
})
export class ModuleFeatureEditComponent implements OnInit, OnDestroy {
  installModule: PropertyCore.Module;
  buildingUT: string;
  installEquipmentUT: string;
  showClose: boolean;

  installModuleId: string;
  attributeContainerId: number[] = [];
  attributesCategories?: any[] = [];
  attributesCategoryNames?: any[] = [];
  categoryArray: any[] = [];
  attributeChanged: any[] = [];
  years: string[];

  formSubmitted: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.paramMap.subscribe(params => (this.buildingUT = params.get('id'))));

    this.subscriptions.add(
      this.route.paramMap
        .pipe(switchMap((params: ParamMap) => {
          this.installModuleId = params.get('id');
          return from(this.odataCoreService.Module.Query()
          .Expand(x => {
            x.Expand("ModuleType"),
              x.Expand("Equipment"),
              x.Expand("Room", y =>
                y.Expand("Floor"))
          })
          .Filter(xa =>
            xa.EqualsField("Identity", Guid.parse( this.installModuleId)))
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])));
        }))
        .subscribe(res => (this.installModule = res))
    );

    this.subscriptions.add(this.route.parent.firstChild.paramMap.subscribe(params => (this.installEquipmentUT = params.get('id'))));


    this.subscriptions.add(
      from(this.odataCoreService.Building.Query()
      .Select("AttributeTypeSets")
      .Expand(x => {
        x.Expand("AttributeTypeSets", y => {
          y.Expand("AttributeTypes", z => {
            z.Expand("MeasurementUnit")
            z.OrderBy("Order", "asc")
          })
        })
      })
      .Filter(x => x.EqualsField("Identity", Guid.parse(this.buildingUT)))
      .Exec()
      .then(x => x.value)).pipe(map(res => res[0]))
      .subscribe(res1 => {
        this.subscriptions.add(this.route.paramMap.pipe(switchMap((params: ParamMap) => 
        from(this.odataCoreService.Module.Get()
        .Key(Guid.parse(this.installModuleId))
        .Select("Identity","Name","AttributeValues")
        .Expand(x => 
          x.Expand("AttributeValues"))
        .Exec()
          .then(x => x.value))))
          .pipe(map(res => res[0]))
        .subscribe(res => {
          
              let a: number = 0;

              while(res1.AttributeTypeSets.length > a){
                let b: number = 0;

                while(res1.AttributeTypeSets[a].AttributeTypes.length > b){
                
                let attributeItem = res.AttributeValues.find(x => x.Id == res1.AttributeTypeSets[a].AttributeTypes[b].Id);    

                  if(attributeItem){   

                    attributeItem['Description'] = res1.AttributeTypeSets[a].AttributeTypes[b].Description;
                    attributeItem['Name'] = res1.AttributeTypeSets[a].AttributeTypes[b].Name;
                    attributeItem['MeasurementUnit'] = res1.AttributeTypeSets[a].AttributeTypes[b].MeasurementUnit;
                    attributeItem['IsOptional'] = res1.AttributeTypeSets[a].AttributeTypes[b].IsOptional;
                    attributeItem['AttributeCategory'] = res1.AttributeTypeSets[a].Name;
                    attributeItem['ValueType'] = res1.AttributeTypeSets[a].AttributeTypes[b].ValueType;
                    attributeItem['AllowedValues'] = res1.AttributeTypeSets[a].AttributeTypes[b].AllowedValues;

                    if(attributeItem['ValueType'] == 'Date'){
                      attributeItem['ValueDate'] =  Utils.jsonToDate(attributeItem['Value']);
                    }
          
                  this.attributesCategories.push(attributeItem);
                  this.attributesCategoryNames.push(attributeItem['AttributeCategory']);
                  }
                  b++;
                }
                a++;
              }  
          this.categoryArray = this.attributesCategoryNames.filter((element, index, input) => {
            if (input.indexOf(element) === index) {      
              return element
            }
          })   
        })
      );

  }));

    this.showClose = this.route.snapshot.data.close;

    this.years = Utils.yearsTillToday();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean = true): void {

    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('ModuleFeatureEdit._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('ModuleFeatureEdit._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('ModuleFeatureEdit._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ModuleFeatureEdit._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate([
              '/building',
              this.buildingUT,
              {
                outlets: {
                  left: ['equipment', this.installEquipmentUT, 'view', {outlets: {tab: ['modules']}}],
                  right: ['equipment', 'module', this.installModule.Identity, {outlets: {tab: ['features']}}]
                }
              }
            ]);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate([
        '/building',
        this.buildingUT,
        {
          outlets: {
            left: ['equipment', this.installEquipmentUT, 'view', {outlets: {tab: ['modules']}}],
            right: ['equipment', 'module', this.installModule.Identity, {outlets: {tab: ['features']}}]
          }
        }
      ]);
    }
  }

  async save(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    // transform and save attributes
    const attributes: AttributeDTO[] = this.attributesCategories
      .filter(attribute => attribute.Value !== undefined)
      .map(attribute => {
        const attributeDto = new AttributeDTO();
        attributeDto.Id = attribute.Id;
        attributeDto.Value = attribute['ValueType'] == 'Date' ? Utils.dateToJson(attribute['ValueDate']) : attribute.Value;
        return attributeDto;
      });
      let b = 0;
      while(this.attributeContainerId.length > b){
        let filterAttributesChanged = attributes.find(x => x.Id == this.attributeContainerId[b])
        this.attributeChanged.push(filterAttributesChanged);
        b++;
      }

    if (this.attributeChanged.length) {
      await this.attributeChanged.forEach(async res => {
        let checkValueNull;
        if (res.Value == null) {
          checkValueNull = true;
        } else {
          checkValueNull = false;
        }

        await this.odataCoreService.Module
          .Get()
          .Key(Guid.parse(this.installModule.Identity))
          .NavigateToArray<PropertyCore.AttributeValue>("AttributeValues")
          .Patch()
          .Key(res.Id)
          .ValueType(this.odataCoreService.ODataTypes().AttributeValue())
          .ValueProperty("Value", checkValueNull ? "" : res.Value.toString())
          .Exec();
      })
      this.close(false);
      } else {
        this.close(false);
      }
    }

    onAttributeValueChange(attributeId: number) {
      if(this.attributeContainerId.indexOf(attributeId) === -1) {
        this.attributeContainerId.push(attributeId);
      }
    }
}
