import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Subscription, from, Observable} from 'rxjs';
import {PropertyCore} from '../../../../core/odata/odata.coreapi';
import {DocumentWizardNavigationPaths} from '../document-wizard-navigation-paths.enum';
import {Selectable} from '../../../../core/model/common';
import {FilterByPipe} from '../../../../core/pipes/filter.pipe';
import {ConfirmationModal} from "../../../../core/popup/confirmation.modal";
import {DocumentWizardModel} from '../document-wizard-model';
import {DocumentWizardService} from '../document-wizard.service';
import { ODataCoreService } from "../../../../core/odata-services/odata.coreapi.service";
import { Guid } from 'guid-typescript';
import { ODataPath } from '../../../../core/odata/odataclient';
import { Utils } from '../../../../core/tools/utils';
import { map } from 'rxjs/operators';
import { FileItem } from 'ng2-file-upload';
import { HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-document-wizard-equipment',
  templateUrl: './document-wizard-equipment.component.html',
  styleUrls: ['./document-wizard-equipment.component.scss']
})
export class DocumentWizardEquipmentComponent implements OnInit, OnDestroy {

  model: DocumentWizardModel;
  installEquipments: Selectable<PropertyCore.Equipment>[];

  searchFilter: string = '';
  equipments: PropertyCore.EquipmentType[];
  equipmentFilter: any = '';

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<DocumentWizardNavigationPaths>();

  constructor(
    private service: DocumentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private filterByPipe: FilterByPipe,
    private odataCoreService: ODataCoreService,
    private httpClient: HttpClient
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentDocument();

    this.subscriptions.add(from(this.odataCoreService.Equipment.Query()
      .OrderBy("Name")
      .Filter(x =>
        x.Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.building.Identity)))
      .Expand(x => {
        x.Expand("Building"),
          x.Expand("EquipmentType"),
          x.Expand("Room", y => {
            y.Expand("Floor")
          })
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => {
      // TODO odata cast?
      const res2 = <Selectable<PropertyCore.Equipment>[]>res;
      // preselect already selected items
      if (this.model.equipments && this.model.equipments.length > 0) {
        this.model.equipments.map(mie => {
          const foundIe = res2.filter(ie => ie.Identity === mie.Identity);
          foundIe.forEach(x => x.selected = true);
        });
      }
      this.installEquipments = res2;
    }));
    this.subscriptions.add(from(this.odataCoreService.EquipmentType.Query().Exec().then(x => x.value)).subscribe(res => this.equipments = res));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  previous(): void {
    this.pageChangeRequest.emit(DocumentWizardNavigationPaths.PREVIOUS);
  }

  async save(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    if (this.model.virtual) {
        await forkJoin(...this.model.equipments.map(async equipment =>
                await this.odataCoreService.Document.Post()
                    .ValueType(this.odataCoreService.ODataTypes().Document())
                    .ValuePropertyBinding("DocumentType", this.odataCoreService.DocumentType.Get().Key(this.model.DocumentType.Id).Bind())
                    .ValueProperty("Description", this.model.name)
                    .Exec()
                    .then(async res => {
                        await this.odataCoreService.Equipment.Link()
                            .Key(Guid.parse(equipment.Identity))
                            .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(res.Identity)).Bind())
                            .Post().then(res1 => {
                                this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                            });
                    }))
            )
      } else {
            await this.odataCoreService.Document.Post()
                .ValueType(this.odataCoreService.ODataTypes().Document())
                .ValuePropertyBinding("DocumentType", this.odataCoreService.DocumentType.Get().Key(this.model.DocumentType.Id).Bind())
                .ValueProperty("Name", this.model.file.file.name)
                .ValueProperty("Description", this.model.name)
                .Exec()
                .then(async res => {

                  if(this.model.file.file.type != "") {

                    var odataDocumentUrl = this.odataCoreService.Document.Get().Raw()
                    .Key(Guid.parse(res.Identity))
                    .ToUrlString(false);

                    await this.uploadODataDocument(this.model.file, odataDocumentUrl)
                    .toPromise().then(async res1 => {
                            var equipmentIdentity = this.model.equipments.map(x => x.Identity);
                            let x = 0;
                            while (equipmentIdentity.length > x) {
                                await this.odataCoreService.Equipment.Link()
                                    .Key(Guid.parse(equipmentIdentity[x]))
                                    .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(res.Identity)).Bind())
                                    .Post()
                                    .then(res2 => {
                                        this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                                    });
                                x++;
                            }
                        });
                  } else {
                    await this.uploadDocumentNoContentType(res.Identity);
                  }
              })
    }
  }

  uploadODataDocument(file: FileItem, url: string): Observable<any> {
      return this.httpClient.put(url, file._file);
    }

  uploadODataDocumentNoCT(file: Blob, url: string): Observable<any> {
    return this.httpClient.put(url, file);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('DocumentWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('DocumentWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('DocumentWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('DocumentWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentDocument();
            this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
            // do nothing, just stay on page
          }
        );
    } else {
      this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
    }
  }

  selectAll(all: boolean): void {
    let filtered = this.installEquipments;
    if (this.searchFilter) {
      filtered = this.filterByPipe.transform(filtered, this.searchFilter, ['CustomId', 'Name', 'Room.Floor.Name', 'Room.Name']);
    }
    if (this.equipmentFilter) {
      filtered = this.filterByPipe.transform(filtered, this.equipmentFilter, 'equipmentId');
    }
    filtered.map(installEquipment => (installEquipment.selected = all));

    this.saveToModel();
  }

  saveToModel(): void {
    const selectedIEs: PropertyCore.Equipment[] = this.installEquipments.filter(installEquipment => installEquipment.selected);

    if (!this.model.equipments) {
      this.model.equipments = selectedIEs;
    } else {
      // ignore already set installequipments and remove unselected ones
      this.model.equipments = this.model.equipments.filter(cie => selectedIEs.some(sie => sie.Identity === cie.Identity));
      // add new installequipments to model
      selectedIEs.forEach(sie => {
        if (!this.model.equipments.some(cie => cie.Identity === sie.Identity)) {
          this.model.equipments.push(sie);
        }
      });
    }
  }

  async uploadDocumentNoContentType(documentIdentity : string): Promise<void> {
    let fileExtensionIndex = this.model.file.file.name.lastIndexOf(".");
    let fileExtension = this.model.file.file.name.substring(fileExtensionIndex);
    let blobFile;

    switch (fileExtension) {

     case '.pdf':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/pdf'); 
      break;

     case '.png':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/png');
       break;

     case '.jpg':
     case '.jpeg':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/jpeg');
       break;

     case '.tif':
     case '.tiff':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/tiff');
       break;

     case '.doc':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/msword');
       break;

     case '.docx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
       break;

     case '.xls':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.ms-excel');
       break;

     case '.xlsx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
       break;

     case '.ppt':        
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.ms-powerpoint');
       break;

     case '.pptx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
        break;

     case '.dwg':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/vnd.dwg');
       break;

     default: 
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/octet-stream');
       break;
       
     }

    var odataDocumentUrl = this.odataCoreService.Document.Get().Raw()
          .Key(Guid.parse(documentIdentity))
          .ToUrlString(false);

    await this.uploadODataDocumentNoCT(blobFile, odataDocumentUrl);
                  var equipmentIdentity = this.model.equipments.map(x => x.Identity);
                  let x = 0;
                  while (equipmentIdentity.length > x) {
                    await this.odataCoreService.Equipment.Link()
                      .Key(Guid.parse(equipmentIdentity[x]))
                      .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(documentIdentity)).Bind())
                      .Post();
                              this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                      x++;
                  }
  }

}
