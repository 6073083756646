import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { NavigatorService } from '../../core/shared/navigator.service';
import { IncidentDetailLinkOutputData } from '../../core/components/incident/incident-detail-link-output-data';
import { IncidentListInputData } from '../../core/components/incident/incident-list-input-data';
import { IncidentType } from '../../core/components/incident/incident-type';
import { IncidentView } from '../../core/components/incident/incident-view';
import { IncidentState } from '../building-incident/incident-state.service';
import { ODataPath } from '../../core/odata/odataclient';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';
import { Incident } from 'app/core/model/incident';


@Component({
  selector: 'app-building-incident-list',
  templateUrl: './building-incident-list.component.html',
  styleUrls: ['./building-incident-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BuildingIncidentListComponent implements OnInit, OnDestroy {

  currentViewTab: IncidentView;

  currentTypeTab: IncidentType = IncidentType.NONE;

  inputData: IncidentListInputData = new IncidentListInputData();

  buildingId: string;

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: IncidentState,
    private navigator: NavigatorService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {

    this.inputData.allowedViews = [IncidentView.ALL, IncidentView.TODOFUTURE, IncidentView.DONE, IncidentView.OVERDUE];

    this.inputData.variableScrollHeight = '431';


    this.inputData.allIncidents$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(v => {
        return this.route.root.firstChild.paramMap
          .pipe(tap(params => (this.buildingId = params.get('id'))), switchMap((params: ParamMap) =>
            from(this.odataCoreService.Event
              .Query()
              .Filter(x => x
                .Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get('id'))))
              .Select("Identity", "Id", "Type", "Guidelines", "DueDate")
              .Expand(x => {
                x.Expand("Schedule", y => {
                  y.Select("CycleUnit");
                  y.Select("Cycle");
                  y.Expand("InspectionDuty", y => {
                    y.Select("OperatorTask");
                    y.Expand("OperatorTask", z => {
                      z.Select("Guidelines");
                      z.Select("Qualification");
                    });
                  });
                  y.Expand("MaintenanceContract", z => {
                    z.Select("StartDate");
                    z.Expand("ServiceProvider", za => {
                      za.Select("Name");
                    })
                  });
                });
                x.Expand("Documents", y => {
                  y.Select("DocumentType"),
                    y.Expand("DocumentType", z => {
                      z.Select("Id"),
                        z.Select("Name")
                    })
                });
                x.Expand("Building", y => {
                  y.Select("Name");
                  y.Select("Id");
                  y.Select("Identity");
                });
                x.Expand("Equipment", y => {
                  y.Select("Name");
                  y.Select("Id");
                  y.Select("Identity");
                  y.Select("CustomId");
                });
                x.Expand("ServiceProvider", y => {
                  y.Select("Name");
                  y.Select("Id");
                  y.Select("Identity");
                });
                x.Expand("KpiMarker");
              })
              .OrderBy("DueDate", "asc")
              .Exec()
              .then(x =>
                x.value
              ))
              .pipe(map(res => res.map(i => {
                Utils.mapAllJSONDatesToDates(i);
                return Object.assign(new Incident(), i)
              })))),
            tap(inc => {
              inc.sort((a, b) => {
                return (b.DueDate ? b.DueDate.getTime() : 0) - (a.DueDate ? a.DueDate.getTime() : 0);
              });
            }));
      }));

    this.inputData.currentView$ = this.route.paramMap
      .pipe(map(p => IncidentView.getIncidentView(p.get('tab'))), tap(val => (this.currentViewTab = val)));

    this.inputData.currentType$ = this.route.paramMap
      .pipe(map(p => IncidentType.getIncidentType(p.get('subtab'))), tap(val => (this.currentTypeTab = val)));

    this.inputData.currentIncidentId$ = this.route.parent.parent.parent.parent.children[1].paramMap
      .pipe(map(params => +params.get('id')));

    if (this.navigator.getNavigationAction() === IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT) {
      this.inputData.navigateToFirstListItem = true;
    }
    this.navigator.finishNavigation();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleViewTabChangeRequest($event: string) {
    this.currentViewTab = IncidentView.getIncidentView($event);
    this.router.navigate([{ tab: [this.currentViewTab] }], { relativeTo: this.route });
  }

  handleTypeTabChangeRequest($event: string) {
    this.currentTypeTab = IncidentType.getIncidentType($event);
    this.router.navigate([{ tab: [this.currentViewTab], subtab: [this.currentTypeTab] }], { relativeTo: this.route });
  }

  handleDetailPageRequest($event: IncidentDetailLinkOutputData) {
    const id = $event.id;
    const subTab = $event.type;

    if (subTab) {
      this.router.navigate([
        'building',
        this.buildingId,
        {
          outlets: {
            left: [
              'building', 'view',
              {
                outlets: {
                  tab: ['incident', {
                    outlets: {
                      'incidentstyle': ['list', {
                        tab: [this.currentViewTab],
                        subtab: [subTab]
                      }]
                    }
                  }]
                }
              }
            ],
            right: ['incident', id]
          }
        }
      ]);
    } else {
      this.router.navigate([
        'building',
        this.buildingId,
        {
          outlets: {
            left: ['building', 'view', { outlets: { tab: ['incident', { outlets: { 'incidentstyle': ['list', { tab: [this.currentViewTab] }] } }] } }],
            right: ['incident', id]
          }
        }
      ]);
    }
  }
}
