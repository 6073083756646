import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class BuildingNoUsageState {

  public static readonly ACTION_SET_USAGE = 'action_set_usage';

  private subject: Subject<string> = new Subject();

  constructor() { }

  notify(notificationtext: string) {
    this.subject.next(notificationtext);
  }

  registerForNotification(): Observable<string> {
    return this.subject.asObservable();
  }

}
