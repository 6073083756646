export enum IncidentView {
  ALL = 'all',
  TODO = 'todo',
  TODOFUTURE = 'todoFuture',
  TODOFUTUREAUTOLOAD = 'todoFutureAutoLoad',
  OVERDUE = 'overdue',
  DONE = 'done',
  DEFECT = 'defect',
  MISSINGDOCUMENT = 'missingDocument'
}
export namespace IncidentView {
  export function getIncidentView(value: string) {
    switch (value) {
      case IncidentView.ALL: return IncidentView.ALL;
      case IncidentView.TODO: return IncidentView.TODO;
      case IncidentView.TODOFUTURE: return IncidentView.TODOFUTURE;
      case IncidentView.TODOFUTUREAUTOLOAD: return IncidentView.TODOFUTUREAUTOLOAD;
      case IncidentView.OVERDUE: return IncidentView.OVERDUE;
      case IncidentView.DONE: return IncidentView.DONE;
      case IncidentView.DEFECT: return IncidentView.DEFECT;
      case IncidentView.MISSINGDOCUMENT: return IncidentView.MISSINGDOCUMENT;
      default: return null;
    }
  }
}
