import { PropertyCore } from '../../../core/odata/odata.coreapi';

export class ModuleWizardModel {

  id: string;

  equipment: PropertyCore.Equipment;

  quantity: number;

  installModuleType?: PropertyCore.ModuleType;

  customid: string;
  comment: string;
  floor?: PropertyCore.Floor;
  room?: PropertyCore.Room;

  attributesCategories: any = {};

  reset(): void {
    this.id = null;
    this.equipment = null;
    this.quantity = null;
    this.installModuleType = null;
    this.customid = null;
    this.comment = null;
    this.floor = null;
    this.room = null;
    this.attributesCategories = null;
  }
}
