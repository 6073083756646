import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { Subscription, from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PropertyCore } from '../../core/odata/odata.coreapi';
import { AuthService } from './../../core/auth/auth.service';
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from "./../../core/odata-services/odata.coreapi.service";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-contract-overview',
  templateUrl: './contract-overview.component.html',
  styleUrls: ['./contract-overview.component.scss']
})
export class ContractOverviewComponent implements OnInit, OnDestroy {

  contract: PropertyCore.MaintenanceContract;
  currentTab: string;

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {

    if (!this.route.firstChild) {
      // redirect to route with tabs outlet set to default value
      this.router.navigate([{ outlets: { tab: ['details'] } }], { relativeTo: this.route });
    } else {
      // activate correct tab
      const path = this.route.firstChild.routeConfig.path;
      this.currentTab = path;
    }

    this.subscriptions.add(this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd), map(() => this.route))
      .subscribe((event) => {
        if (!this.route.firstChild) {
          // redirect to route with tabs outlet set to default value
          this.router.navigate([{ outlets: { tab: ['details'] } }], { relativeTo: this.route });
        } else {
          // activate correct tab
          const path = this.route.firstChild.routeConfig.path;
          this.currentTab = path;
        }
      }));

    this.subscriptions.add(this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.subscriptions.add(from(this.odataCoreService.MaintenanceContract.Query()
        .Expand(x => {
          x.Expand("ServiceProvider")
        })
        .Filter(xa =>
          xa.EqualsField("Identity", Guid.parse(params.get('id'))))
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0]))).subscribe(res => this.contract = res));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (this.route.outlet === 'left') {
      this.router.navigate(['/contract', this.contract.Identity, {
        outlets: {
          left: ['list'],
          right: ['contract', { outlets: { tab: [changeEvent.nextId] } }]
        }
      }]);
    } else {
      this.router.navigate([{ outlets: { tab: [changeEvent.nextId] } }], { relativeTo: this.route });
    }
  }

  contractEditWizard(contractId: string): void {
    this.subscriptions.add(this.canUpdate(this.contract.Id).subscribe(res => {
      this.router.navigate(['contract', 'wizard', contractId]);
    }, err => {
      this.toasterService.pop('warning', 'Warnung', 'Wartungsvertrag kann nicht bearbeitet werden');
    }));
  }

  canUpdate(id: number): Observable<any> {
    return from(this.odataCoreService.ServiceProvider.Query()
      .Filter(x =>
        x.EqualsField("Id", id))
      .Exec().then(x => x.value))
  }

}
