import { ObjektBrief, Operations, PropertyCore } from '../odata/odata.coreapi';


export class Mandator implements Operations.Account {

  // from interface
  Identity: string;
  Id: number;
  Name: string;
  MaxBuildingCount: number;
  IsSubscriptionProcessed: boolean;
  SapNo: string;
  StartDate: Date;
  EndDate: Date;
  AccountType: Operations.AccountTypes;
  Created: Date;
  Profile?: ObjektBrief.AccountProfile;
  User?: Operations.User;
  Statistics?: ObjektBrief.AccountStatistics;
  AccountDataProcessingAgreement?: Operations.AccountDataProcessingAgreement;
  ActionLogs?: PropertyCore.ActionLog[];
  Buildings?: PropertyCore.Building[];
  MaintenanceContracts?: PropertyCore.MaintenanceContract[];
  ServiceProviders?: PropertyCore.ServiceProvider[];
  Roles?: Operations.AccountRole[];
  Rights?: Operations.AccountRights[];

  // our data
  // this is our own data which we populate later depending on current user
  isOwnerOwner: boolean;
  isOwner: boolean;
  doesExists: boolean;
}

export interface UserRoleBuildings {
  User?: Operations.User;
  RoleBuildings?: RoleBuilding[];
}

export interface RoleBuilding {
  Role?: Operations.BuildingRole;
  Building?: PropertyCore.Building;
}
