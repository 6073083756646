import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Observable, of, Subscription, from } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Common, PropertyCore, Library } from '../../../core/odata/odata.coreapi';
import { ConfirmationModal } from '../../../core/popup/confirmation.modal';
import { ILogger, LoggerService } from '../../../core/shared/logger.service';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { Guid } from 'guid-typescript';
import { Utils } from '../../../core/tools/utils';

@Component({
  selector: 'app-service-contact-wizard',
  templateUrl: './service-contact-wizard.component.html',
  styleUrls: ['./service-contact-wizard.component.scss']
})
export class ServiceContactWizardComponent implements OnInit, OnDestroy {

  model: PropertyCore.ServiceProvider;

  logger: ILogger;

  editMode: boolean;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    loggerService: LoggerService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
    this.logger = loggerService.getLogger('ServiceContactWizardComponent');
  }

  ngOnInit() {
    this.editMode = this.route.snapshot.data.editMode;

    let serviceContact$: Observable<PropertyCore.ServiceProvider>;

    if (this.editMode) {
      serviceContact$ = this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => {
        return from(this.odataCoreService.ServiceProvider.Query()
          .Expand(x => {
            x.Expand("Statistics")
          })
          .Filter(xa =>
            xa.EqualsField("Identity", Guid.parse(params.get('id'))))
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])));
      }));
    } else {
      const sc = <PropertyCore.ServiceProvider>{};
      sc.Address = <Common.Address>{};
      sc.Address.CountryInfo = <Library.Country>{ Id: 0, Name: '-' };
      serviceContact$ = of(sc);
    }
    this.subscriptions.add(serviceContact$.subscribe(val => {
      this.model = val;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      if (this.editMode) {
        modalRef.componentInstance.message = this.translateService.instant('ServiceContactWizzard._modal_message');
      } else {
        modalRef.componentInstance.message = this.translateService.instant('ServiceContactWizzard._modal_message2');
      }
      modalRef.componentInstance.title = this.translateService.instant('ServiceContactWizzard._modal_title');
      modalRef.componentInstance.yesButton = this.translateService.instant('ServiceContactWizzard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ServiceContactWizzard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            if (this.editMode) {
              this.backToListDetailPage(this.model.Identity);
            } else {
              this.backToStartPage();
            }
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      if (this.model.Identity) {
        this.backToListDetailPage(this.model.Identity);
      } else {
        this.backToStartPage();
      }
    }
  }

  async updateServiceProvider(): Promise<void> {
    await this.odataCoreService.ServiceProvider.Patch()
      .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())
      .Key(Guid.parse(this.model.Identity))
      .ValueProperty("Identity", this.model.Identity)
      .ValueProperty("Code", this.model.Name)
      .ValueProperty("Name", this.model.Name)
      .ValueProperty("IsMaintenance", this.model.IsMaintenance)
      .ValueProperty("IsInspection", this.model.IsInspection)
      .ValueProperty("Department", this.model.Department)
      .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
      .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
      .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
      .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
      .ValueProperty("ContactPerson", this.model.ContactPerson)
      .ValueProperty("ContactPhone1", this.model.ContactPhone1)
      .ValueProperty("ContactPhone2", this.model.ContactPhone2)
      .ValueProperty("Address", this.mapAddress(this.model.Address))
      .Exec()
      .then(res =>{
        this.backToListDetailPage(this.model.Identity)
      })
      .catch(err => {
       this.logger.error('update action error:', [err]);
      });
  }

  async createServiceProvider(): Promise<void> {
    if (this.editMode) {
      this.updateServiceProvider();
    } else {
      if (this.model.Account != null && this.model.User == null) {

        let res = await this.odataCoreService.ServiceProvider.Post()
          .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())
          .ValueProperty("Code", this.model.Name)
          .ValueProperty("Name", this.model.Name)
          .ValueProperty("IsMaintenance", this.model.IsMaintenance)
          .ValueProperty("IsInspection", this.model.IsInspection)
          .ValueProperty("Department", this.model.Department)
          .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
          .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
          .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
          .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
          .ValueProperty("ContactPerson", this.model.ContactPerson)
          .ValueProperty("ContactPhone1", this.model.ContactPhone1)
          .ValueProperty("ContactPhone2", this.model.ContactPhone2)
          .ValueProperty("Address", this.mapAddress(this.model.Address))
          .ValuePropertyBinding("Account", this.odataCoreService.Account.Get().Key(Guid.parse(this.model.Account.Identity)).Bind())
          .Exec()
          .then(res => {
            this.model.Identity = res.Identity;
            this.backToListDetailPage(this.model.Identity)
          })
          .catch(err => {
            this.logger.error('create action error:', [err]);
          });
          
      } else if (this.model.User != null && this.model.Account == null) {


        let res = await this.odataCoreService.ServiceProvider.Post()
          .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())
          .ValueProperty("Code", this.model.Name)
          .ValueProperty("Name", this.model.Name)
          .ValueProperty("IsMaintenance", this.model.IsMaintenance)
          .ValueProperty("IsInspection", this.model.IsInspection)
          .ValueProperty("Department", this.model.Department)
          .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
          .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
          .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
          .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
          .ValueProperty("ContactPerson", this.model.ContactPerson)
          .ValueProperty("ContactPhone1", this.model.ContactPhone1)
          .ValueProperty("ContactPhone2", this.model.ContactPhone2)
          .ValueProperty("Address", this.mapAddress(this.model.Address))
          .ValuePropertyBinding("User", this.odataCoreService.User.Get().Key(Guid.parse(this.model.User.Identity)).Bind())
          .Exec()
          .then(res => {
            this.model.Identity = res.Identity;
            this.backToListDetailPage(this.model.Identity)
          })
          .catch(err => {
            this.logger.error('create action error:', [err]);
          });

      } else if (this.model.User == null && this.model.Account == null) {
        let res = await this.odataCoreService.ServiceProvider.Post()
          .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())
          .ValueProperty("Code", this.model.Name)
          .ValueProperty("Name", this.model.Name)
          .ValueProperty("IsMaintenance", this.model.IsMaintenance)
          .ValueProperty("IsInspection", this.model.IsInspection)
          .ValueProperty("Department", this.model.Department)
          .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
          .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
          .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
          .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
          .ValueProperty("ContactPerson", this.model.ContactPerson)
          .ValueProperty("ContactPhone1", this.model.ContactPhone1)
          .ValueProperty("ContactPhone2", this.model.ContactPhone2)
          .ValueProperty("Address", this.mapAddress(this.model.Address))
          .Exec()
          .then(res => {
            this.model.Identity = res.Identity;
            this.backToListDetailPage(this.model.Identity)
          })
          .catch(err => {
            this.logger.error('create action error:', [err]);
          });        
      } else {

        let res = await this.odataCoreService.ServiceProvider.Post()
          .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())
          .ValueProperty("Code", this.model.Name)
          .ValueProperty("Name", this.model.Name)
          .ValueProperty("IsMaintenance", this.model.IsMaintenance)
          .ValueProperty("IsInspection", this.model.IsInspection)
          .ValueProperty("Department", this.model.Department)
          .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
          .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
          .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
          .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
          .ValueProperty("ContactPerson", this.model.ContactPerson)
          .ValueProperty("ContactPhone1", this.model.ContactPhone1)
          .ValueProperty("ContactPhone2", this.model.ContactPhone2)
          .ValueProperty("Address", this.mapAddress(this.model.Address))
          .ValuePropertyBinding("Account", this.odataCoreService.Account.Get().Key(Guid.parse(this.model.Account.Identity)).Bind())
          .ValuePropertyBinding("User", this.odataCoreService.User.Get().Key(Guid.parse(this.model.User.Identity)).Bind())
          .Exec()
          .then(res => {
            this.model.Identity = res.Identity;
            this.backToListDetailPage(this.model.Identity)
          })
          .catch(err => {
            this.logger.error('create action error:', [err]);
          });

      }
    }
  }

  //(this.model.Name, this.model.Identity)

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    if (this.editMode) {
      this.updateServiceProvider();
    } else {
      this.subscriptions.add(
        from(this.odataCoreService.ServiceProvider.Query()
          .Filter(xa =>
            xa.EqualsField("Name", this.model.Name))
          .Count().then(x => x > 0))
          .subscribe(res => {
            if (res) {
              this.toasterService.pop('warning', '', this.translateService.instant('ServiceContactWizzard._longName_exists_error'));
            } else {
              this.createServiceProvider();
            }
          }));
    }
  }

  backToListDetailPage(serviceContactId: string): void {
    // hard reload of both sides
    const url = this.router.createUrlTree(['/service-contact', serviceContactId, {
      outlets: {
        left: ['list'],
        right: ['service-contact']
      }
    }]).toString();
    this.router.navigateByUrl('/skipThisPage', { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
  }

  backToStartPage()
    :
    void {
    this.router.navigate(['/service-contact']);
  }

  delete()
    :
    void {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.message = this.translateService.instant('ServiceContactWizzard._modal_message_delete');
    modalRef.componentInstance.title = this.translateService.instant('ServiceContactWizzard._modal_title');
    modalRef.componentInstance.yesButton = this.translateService.instant('ServiceContactWizzard._button_delete');
    modalRef.componentInstance.cancelButton = this.translateService.instant('ServiceContactWizzard._button_cancel');

    modalRef.result
      .then((val) => {
        if (val === ConfirmationModal.YES_VALUE) {
          if (this.model.Statistics.EquipmentEventCount > 0 || this.model.Statistics.ModuleEventCount > 0 || this.model.Statistics.MaintenanceContractCount > 0) {
            this.toasterService.pop('warning', '', this.translateService.instant('ServiceContactWizzard._delete_error_info', {
              IEICount: this.model.Statistics.EquipmentEventCount,
              IMICount: this.model.Statistics.ModuleEventCount,
              MCCount: this.model.Statistics.MaintenanceContractCount
            }));
          } else {
            this.subscriptions.add(from(this.odataCoreService.ServiceProvider.Delete()
              .Key(Guid.parse(this.model.Identity))
              .Exec()).subscribe(res => this.backToStartPage(),
              err => {
                this.toasterService.pop('error', '', this.translateService.instant('ServiceContactWizzard._delete_error'));
              }));
          }
        }
      }
      )
      .catch(
        () => this.logger.error('delete action error in modal window'));
  }

  private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};
    if (address.RegionInfo != null && address.RegionInfo.hasOwnProperty('Code')) {
      entity.Region = address.RegionInfo['Code'];
    }
    if (address.CountryInfo != null && address.CountryInfo.hasOwnProperty('Code')) {
      entity.Country = address.CountryInfo['Code'];
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }

}
