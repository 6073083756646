import { Observable } from 'rxjs';

import { DocumentType } from './../../../building/wizard/document/document-wizard-model';
import { BuildingDocumentHierarchy } from './../../model/document';
import { PropertyCore } from '../../odata/odata.coreapi';

export class BuildingDocumentsInputData {
  buildingDocument$: Observable<PropertyCore.Building[]>;
  searchFilter$: Observable<string>;
  showBuildingData: boolean = false;
  currentBuildingUT$: Observable<string>;
  currentDocumentTypeId$: Observable<number>;
  lastIdx: number = -1;

  showsAsCallToAction: boolean = true;
  navigateToFirstListItem: boolean = false;
}

export class BuildingDocumentWizardRequest {
  buildingUT: string;
  documentType: number;
}
