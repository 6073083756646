import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, from} from 'rxjs';

import {PropertyCore} from '../../../../core/odata/odata.coreapi';
import {ContractWizardNavigationPaths} from '../contract-wizard-navigation-paths.enum';
import {ACTION, ExtendedMaintenanceContractMapping} from '../../../../core/model/extendedMaintenanceContractMapping';
import {FilterByPipe} from './../../../../core/pipes/filter.pipe';
import {ConfirmationModal} from './../../../../core/popup/confirmation.modal';
import {ContractWizardModel} from './../contract-wizard-model';
import {ContractWizardService} from './../contract-wizard.service';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../../../core/tools/utils';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';

@Component({
  selector: 'app-contract-wizard-install-equipments',
  templateUrl: './contract-wizard-install-equipments.component.html',
  styleUrls: ['./contract-wizard-install-equipments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContractWizardInstallEquipmentsComponent implements OnInit, OnDestroy {
  editMode: boolean;

  model: ContractWizardModel;

  equipmentFilter: string;
  costGroups: PropertyCore.CostGroup[];
  costGroupFilter: number;
  buildings: PropertyCore.Building[];
  buildingFilter: string;

  buildingInstallEquipments: any[]; // array with ContractInstallEquipment[] per building

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<ContractWizardNavigationPaths>();

  constructor(
    private service: ContractWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private filterByPipe: FilterByPipe,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.editMode = this.route.snapshot.data.editMode;

    this.model = this.service.getCurrentContract();

    this.subscriptions.add(from(this.odataCoreService.Building.Query()
      .OrderBy("Name", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        return Utils.mapAllJSONDatesToDates(i);
      })))
      .subscribe(res => this.buildings = res));
    this.subscriptions.add(from(this.odataCoreService.CostGroup.Query()
      .OrderBy("No")
      .Filter(x => x
        .NotEquals("Id", 30))
        .Expand(x => {
          x.Expand("EquipmentTypes")
        })
        .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => (this.costGroups = res)));

    this.subscriptions.add(
      from(this.odataCoreService.Equipment.Query()
        .Expand(x => {
            x.Expand("Building"),
            x.Expand("EquipmentType", y => {
              y.Expand("CostGroup")
            }),
            x.Expand("Room", y => {
              y.Expand("Floor")
            })
        })
        .Exec().then(x => x.value))
        .pipe(map(res => res.map(i => {
          Utils.mapAllJSONDatesToDates(i);
          return ExtendedMaintenanceContractMapping.createFromEquipment(i);
        })))
        .subscribe(res => {
        // preselect already selected items
        if (this.model.installEquipments) {
          this.model.installEquipments.forEach(mie => {
            const foundIe = res.filter(ie => ie.Equipment.Identity === mie.Equipment.Identity && mie.action !== ACTION.REMOVE);
            foundIe.forEach(x => {
              if (!x.selected) {
                // first item pre-selected, set action to model item action
                x.action = mie.action;
                x.selected = true;
              } else if (mie.action === ACTION.NONE) {
                x.action = mie.action;
              }
            });
            if (mie.action === ACTION.NONE) {
              foundIe.forEach(x => x.action = ACTION.NONE);
            }
          });
        }
        // group by building
        this.buildingInstallEquipments = res.reduce((prev, next) => {
          const b = prev.find(x => x.buildingUT === next.Equipment.Building.Identity);
          if (!b) {
            prev.push({
              buildingUT: next.Equipment.Building.Identity,
              buildingName: next.Equipment.Building.Name,
              dataList: [next]
            });
          } else {
            b.dataList.push(next);
          }
          return prev;
        }, []).sort((b1, b2) => b1.buildingName.toLowerCase().localeCompare(b2.buildingName.toLowerCase()));
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(): void {
    this.saveToModel();
    this.pageChangeRequest.emit(ContractWizardNavigationPaths.NEXT);
  }

  previous(): void {
    this.pageChangeRequest.emit(ContractWizardNavigationPaths.PREVIOUS);
  }

  selectAll(selectAll: boolean): void {
    let filtered = this.buildingInstallEquipments;
    if (this.buildingFilter) {
      filtered = this.filterByPipe.transform(filtered, this.buildingFilter, 'buildingUT');
    }
    filtered = filtered.reduce((a, b) => a.concat(b.dataList), []);
    if (this.equipmentFilter) {
      filtered = this.filterByPipe.transform(filtered, this.equipmentFilter, ['CustomId', 'Name', 'Room.Floor.Name', 'Room.Name']);
    }
    if (this.costGroupFilter) {
      filtered = this.filterByPipe.transform(filtered, this.costGroupFilter, 'costGroupId');
    }
    filtered.map(installEquipment => {
      if (selectAll || (!installEquipment.selected || installEquipment.action !== ACTION.NONE)) {
        installEquipment.selected = selectAll;
      }
    });

    this.saveToModel();
  }

  saveToModel(): void {
    const selectedIEs: ExtendedMaintenanceContractMapping[] = this.buildingInstallEquipments
      .reduce((a, b) => a.concat(b.dataList), [])
      .filter(installEquipment => installEquipment.selected);

    if (this.editMode) {
      // in editmode - mark unselected ones as removed
      this.model.installEquipments.forEach(cie => {
        if (!selectedIEs.some(sie => sie.Equipment.Identity === cie.Equipment.Identity)) {
          if (cie.action === ACTION.ADD) {
            this.model.installEquipments.splice(this.model.installEquipments.indexOf(cie), 1);
          } else {
            cie.action = ACTION.REMOVE;
          }
        }
      });
    } else {
      // in createmode - filter out unselected ones
      this.model.installEquipments = this.model.installEquipments.filter(cie => selectedIEs.some(sie => sie.Equipment.Identity === cie.Equipment.Identity));
    }

    // add new installequipments to model
    selectedIEs.forEach(sie => {
      if (!this.model.installEquipments.some(cie => cie.Equipment.Identity === sie.Equipment.Identity && cie.action !== ACTION.REMOVE)) {
        sie.action = ACTION.ADD;
        this.model.installEquipments.push(sie);
      }
    });
  }

  get selectedCount(): number {
    return new Set(this.model.installEquipments.filter(x => x.action === ACTION.NONE || x.action === ACTION.ADD).map(x => x.Equipment.Identity)).size;
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('ContractWizzard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('ContractWizzard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('ContractWizzard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ContractWizzard._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentContract();
            this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
    }
  }
}
