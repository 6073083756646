import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Subscription, from } from "rxjs";
import { switchMap, map } from "rxjs/operators";

import { PropertyCore } from "../../core/odata/odata.coreapi";
import { Utils } from "./../../core/tools/utils";
import { InstallEquipmentService } from "./../installEquipment.service";
import { Guid } from "guid-typescript";
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { ODataPath } from "app/core/odata/odataclient";

@Component({
  selector: "app-equipment-feature",
  templateUrl: "./equipment-feature.component.html",
  styleUrls: ["./equipment-feature.component.scss"],
})
export class EquipmentFeatureComponent implements OnInit, OnDestroy {
  installEquipmentId: string;
  buildingId: string;
  installEquipment: PropertyCore.Equipment;
  attributesCategories?: any[] = [];
  attributesCategoryNames?: any[] = [];
  categoryArray: any[] = [];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private equipmentService: InstallEquipmentService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.root.firstChild.paramMap.subscribe(
        (params: ParamMap) => (this.buildingId = params.get("id"))
      )
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            this.installEquipmentId = params.get("id");
            return from(
              this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.installEquipmentId))
                .Expand((x) =>
                  x
                    .Expand("Building")
                    .Expand("EquipmentType")
                    .Expand("Room", (y) => y.Expand("Floor"))
                )
                .Exec()
                .then((x) => x.value)
            ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])));
          })
        )
        .subscribe((res) => {
          this.installEquipment = res;
        })
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.installEquipmentId))
                .Select("Identity", "Name", "AttributeValues")
                .Expand((x) => x.Expand("AttributeValues"))
                .Exec()
                .then((x) => x.value)
            )
          )
        )
        .pipe(map((res) => res[0]))
        .subscribe((equipment) => {
          this.subscriptions.add(
            from(
              this.odataCoreService.AttributeType.Query()
                .Expand((x) => {
                  x.Expand("Set");
                  x.Expand("MeasurementUnit");
                })
                .OrderBy("Order", "asc")
                .Exec()
                .then((x) => x.value)
            ).subscribe((attributeTypes) => {
              let a: number = 0;

              while (attributeTypes.length > a) {
                let attributeItem = equipment.AttributeValues.find(
                  (x) => x.Id == attributeTypes[a].Id
                );
                if (attributeItem) {
                  attributeItem["Description"] = attributeTypes[a].Description;
                  attributeItem["Name"] = attributeTypes[a].Name;
                  attributeItem["MeasurementUnit"] =
                    attributeTypes[a].MeasurementUnit;
                  attributeItem["IsOptional"] = attributeTypes[a].IsOptional;
                  attributeItem["AttributeCategory"] = attributeTypes[a].Set;
                  attributeItem["ValueType"] = attributeTypes[a].ValueType;

                  this.attributesCategories.push(attributeItem);
                  if (attributeItem.Value != null || "") {
                    this.attributesCategoryNames.push(
                      attributeItem["AttributeCategory"].Name
                    );
                  }
                }
                a++;
              }

              this.categoryArray = this.attributesCategoryNames.filter(
                (element, index, input) => {
                  if (input.indexOf(element) === index) {
                    return element;
                  }
                }
              );
            })
          );
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  edit(): void {
    this.router.navigate(
      [
        "/building",
        this.buildingId,
        {
          outlets: {
            left: ["building", "view", { outlets: { tab: ["equipment"] } }],
            right: ["equipment", this.installEquipmentId, "edit", "feature"],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  private close(): void {
    this.router.navigate(
      [
        "/building",
        this.installEquipment.Identity,
        {
          outlets: {
            left: ["list"],
            right: ["building", "view", { outlets: { tab: ["equipment"] } }],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }
}
