import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { combineLatest, Subscription, from } from "rxjs";
import { AuthService } from "./../../../auth/auth.service";
import { Document } from "./../../../model/document";
import { ConfirmationModal } from "./../../../popup/confirmation.modal";
import { PopupService } from "./../../../popup/popup.service";
import {
  BuildingDocumentsInputData,
  BuildingDocumentWizardRequest,
} from "./../building-documents-input-data";
import { Guid } from "guid-typescript";
import { ODataCoreService } from "./../../../odata-services/odata.coreapi.service";
import { PropertyCore } from "../../../odata/odata.coreapi";
import { GlobalService } from "app/core/shared/global.service";

@Component({
  selector: "app-core-building-documents-missing-only",
  templateUrl: "./core-building-documents-missing-only.component.html",
  styleUrls: ["./core-building-documents-missing-only.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CoreBuildingDocumentsOnlyComponent implements OnInit, OnDestroy {
  @Input() inputData: BuildingDocumentsInputData;

  @Output() documentWizardRequest =
    new EventEmitter<BuildingDocumentWizardRequest>();
  @Output() refreshRequest = new EventEmitter<void>();

  buildingDocument: PropertyCore.Building[];
  searchFilter: string = "";
  currentBuildingUT: string;
  currentDocumentTypeId: number;

  editDocument: Document;

  subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    private popupService: PopupService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      combineLatest(
        this.inputData.buildingDocument$,
        this.inputData.searchFilter$,
        this.inputData.currentBuildingUT$,
        this.inputData.currentDocumentTypeId$
      ).subscribe(
        ([
          buildingDocument,
          searchFilter,
          currentBuildingUT,
          currentDocumentTypeId,
        ]) => {
          this.buildingDocument = buildingDocument;
          this.searchFilter = searchFilter;
          this.currentBuildingUT = currentBuildingUT;
          this.currentDocumentTypeId = currentDocumentTypeId;

          if (this.currentBuildingUT) {
            this.inputData.lastIdx = this.buildingDocument.findIndex(
              (b) => b.Identity === this.currentBuildingUT
            );
          }

          if (
            this.inputData.navigateToFirstListItem &&
            !this.currentBuildingUT &&
            !this.currentDocumentTypeId
          ) {
            const idx =
              this.inputData.lastIdx === -1
                ? 0
                : this.inputData.lastIdx >= this.buildingDocument.length
                  ? this.buildingDocument.length - 1
                  : this.inputData.lastIdx;
            const buildingDocument = this.buildingDocument[idx];
            if (buildingDocument) {
              const bDocument = buildingDocument.DocumentTypeMarkers[0];
              this.documentWizard(
                buildingDocument.Identity,
                bDocument.DocumentType.Id
              );
            }
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showDocument(doc: Document) {
    if (!doc.IsVirtual) {
      if (doc.Name.endsWith(".pdf")) {
        this.popupService.openPdfModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false),
          doc.Name
        );
      } else if (
        doc.Name.endsWith(".jpg") ||
        doc.Name.endsWith(".jpeg") ||
        doc.Name.endsWith(".png")
      ) {
        this.popupService.openImagePreviewModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false)
        );
      } else if (
        doc.Name.endsWith(".doc") ||
        doc.Name.endsWith(".docx") ||
        doc.Name.endsWith(".xls") ||
        doc.Name.endsWith(".xlsx") ||
        doc.Name.endsWith(".ppt") ||
        doc.Name.endsWith(".pptx")
      ) {
        this.popupService.openOfficePreviewModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false),
          doc.Name
        );
      } else {
        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = this.translateService.instant(
          "DocumentList._preview_title"
        );
        modalRef.componentInstance.message = this.translateService.instant(
          "DocumentList._preview_message"
        );
        modalRef.componentInstance.yesButton = this.translateService.instant(
          "DocumentList._preview_download"
        );
        modalRef.componentInstance.cancelButton = this.translateService.instant(
          "DocumentList._preview_cancel"
        );

        modalRef.result
          .then((val) => {
            if (val === ConfirmationModal.YES_VALUE) {
              this.subscriptions.add(
                this.globalService
                  .downloadDocument(
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(doc.Identity))
                      .Raw()
                      .ToUrlString(false)
                  )
                  .subscribe((data) => {
                    const blob = new Blob([data], {
                      type: "application/octet-stream",
                    });
                    // if (navigator.msSaveBlob) {
                    //   // IE 10+
                    //   navigator.msSaveBlob(blob, doc.Name);
                    // } else {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = doc.Name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    //}
                  })
              );
            }
          })
          .catch(() => {
            // do nothing, just stay on page
          });
      }
    }
  }

  documentWizard(buildingUT: string, documentType: number) {
    this.currentBuildingUT = buildingUT;
    if (this.currentBuildingUT) {
      this.inputData.lastIdx = this.buildingDocument.findIndex(
        (b) => b.Identity === this.currentBuildingUT
      );
    }
    this.currentDocumentTypeId = documentType;
    this.documentWizardRequest.emit({
      buildingUT: buildingUT,
      documentType: documentType,
    });
  }

  edit(event: MouseEvent, document: Document, content: any) {
    event.stopPropagation();
    this.editDocument = Object.assign({}, document);
    this.modalService.open(content);
  }

  delete(closeModal: () => void) {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant(
      "DocumentList._modal_title"
    );
    modalRef.componentInstance.message = this.translateService.instant(
      "DocumentList._modal_message"
    );
    modalRef.componentInstance.yesButton = this.translateService.instant(
      "DocumentList._modal_yes"
    );
    modalRef.componentInstance.cancelButton = this.translateService.instant(
      "DocumentList._modal_cancel"
    );

    modalRef.result
      .then((val) => {
        if (val === ConfirmationModal.YES_VALUE) {
          this.subscriptions.add(
            from(
              this.odataCoreService.Document.Delete()
                .Key(Guid.parse(this.editDocument.Identity))
                .Exec()
            ).subscribe((res) => {
              closeModal();
              this.toasterService.pop(
                "info",
                "",
                this.translateService.instant(
                  "DocumentList._deleteDocument_success"
                )
              );
              this.refreshRequest.emit();
            })
          );
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  save(closeModal: () => void) {
    this.subscriptions.add(
      from(
        this.odataCoreService.Document.Patch()
          .ValueType(this.odataCoreService.ODataTypes().Document())
          .Key(Guid.parse(this.editDocument.Identity))
          .ValueProperty("Description", this.editDocument.Description)
          .Exec()
      ).subscribe((res) => {
        closeModal();
        this.toasterService.pop(
          "info",
          "",
          this.translateService.instant("DocumentList._saveDocument_success")
        );
        this.refreshRequest.emit();
      })
    );
  }

  countExistingDocuments(documents: Document[]) {
    return documents.filter((d) => d.Id).length;
  }
}
