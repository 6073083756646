import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Selectable } from "app/core/model/common";
import { PropertyCore } from "app/core/odata/odata.coreapi";
import { FilterByPipe } from "app/core/pipes/filter.pipe";
import { BuildingService } from "app/core/shared/services/building.service";

@Component({
  selector: "app-export-calculation-buildings",
  templateUrl: "./export-calculation-buildings.component.html",
  styleUrls: ["./export-calculation-buildings.component.scss"],
})
export class ExportCalculationBuildingsComponent implements OnInit {
  @Output() buildingsSelected: EventEmitter<
    PropertyCore.Building[]
  > = new EventEmitter();
  public buildings: Selectable<PropertyCore.Building>[];

  constructor(private buildingService: BuildingService) { }

  ngOnInit() {
    this.buildingService.getBuildings().subscribe((res) => {
      this.buildings = res;
    });
  }

  public onBuildingsSelected(selectedBuildings: PropertyCore.Building[]): void {
    this.buildingsSelected.emit(selectedBuildings);
  }
}
