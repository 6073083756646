import {PropertyCore} from '../odata/odata.coreapi';


export enum ACTION {
  NONE = 'none',
  ADD = 'add',
  REMOVE = 'remove'
}

export class ExtendedMaintenanceContractMapping implements PropertyCore.MaintenanceJob {

  // from interface
  Identity: string;
  Id: number;
  IsDisabled: boolean;
  Created: Date;
  Equipment?: PropertyCore.Equipment;
  Schedule?: PropertyCore.Schedule;
  MaintenanceContract?: PropertyCore.MaintenanceContract;

  // own data
  action: ACTION = ACTION.NONE;
  selected: boolean;

  static createFromEquipment(ie: PropertyCore.Equipment): ExtendedMaintenanceContractMapping {
    const entity = new ExtendedMaintenanceContractMapping;
    entity.Equipment = ie;
    entity.Schedule = <PropertyCore.Schedule>{};
    return entity;
  }
}
