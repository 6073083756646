import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { of, Subscription, from } from 'rxjs';

import { Common, PropertyCore } from '../../../../core/odata/odata.coreapi';
import { ContractListInputdata } from '../../../../core/components/contract/contract-list-input-data';
import { ExtendedMaintenanceContractMapping } from '../../../../core/model/extendedMaintenanceContractMapping';
import { ConfirmationModal } from '../../../../core/popup/confirmation.modal';
import { ContractHandlingInputData } from './contract-handling-input-data';
import { map } from 'rxjs/operators';
import { Utils } from '../../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataPath } from '../../../../core/odata/odataclient';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';


@Component({
  selector: 'app-equipments-contract-handling',
  templateUrl: './equipments-contract-handling.component.html',
  styleUrls: ['./equipments-contract-handling.component.scss']
})
export class EquipmentsContractHandlingComponent implements OnInit, OnDestroy {

  @Input() contractHandlingInputData = new ContractHandlingInputData();
  @Output() saveRequest: EventEmitter<void> = new EventEmitter<void>();

  inputData = new ContractListInputdata();

  selectedContract: PropertyCore.MaintenanceContract;

  cycleUnits: string[] = Object.keys(Common.IntervalUnits).filter(x => x !== Common.IntervalUnits.Undefined);

  cycleUnit: Common.IntervalUnits;

  validFromDate: Date;

  cycle: number;

  w: number = 0;

  formSubmitted: boolean;

  installEquipment: PropertyCore.Equipment;

  private subscriptions = new Subscription();

  constructor(
    private odataCoreService: ODataCoreService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private router: Router
  ) {
  }

  ngOnInit() {

    this.inputData.contracts$ = this.contractHandlingInputData.contracts$;

    this.inputData.contractUT$ = of(<string>null);
    this.inputData.showSearch = false;
    this.inputData.showWizardButton = false;
    this.inputData.scrollStyle = { height: 'calc(100vh - 470px)' };

    this.subscriptions.add(this.contractHandlingInputData.installEquipment$.subscribe(ie => this.installEquipment = ie));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleContractListItemClick(contract: PropertyCore.MaintenanceContract) {
    this.selectedContract = contract;
  }

  compareId(val1: { Id: number }, val2: { Id: number }): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  save(form: NgForm): void {

    // if required is mandatory the form must be valid, otherwise the termination fields must only be filled when at least one of them is not empty
    if ((this.contractHandlingInputData.contractTerminationMandatory && form.form.invalid) ||
      (!this.contractHandlingInputData.contractTerminationMandatory &&
        (((form.value.cycle || form.value.cycle === 0) || form.value.cycleUnit || form.value.validFromDate) && (!(form.value.cycle && form.value.cycleUnit && form.value.validFromDate))))) {
      this.formSubmitted = true;
      return;
    }

    const contractInstallEquipment: ExtendedMaintenanceContractMapping = ExtendedMaintenanceContractMapping.createFromEquipment(this.installEquipment);
    contractInstallEquipment.Schedule.Cycle = this.cycle;
    contractInstallEquipment.Schedule.CycleUnit = this.cycleUnit;
    contractInstallEquipment.Schedule.CycleStart = this.validFromDate;

    from(this.odataCoreService.MaintenanceJob.Post()
      .ValueType(this.odataCoreService.ODataTypes().MaintenanceJob())
      .ValueProperty("Identity", Utils.guid())
      .ValuePropertyBinding("Equipment", this.odataCoreService.Equipment.Get().Key(Guid.parse(contractInstallEquipment.Equipment.Identity)).Bind())
      .ValuePropertyBinding("Contract", this.odataCoreService.MaintenanceContract.Get().Key(Guid.parse(this.selectedContract.Identity)).Bind())
      .Exec().then(x => {
        from(this.odataCoreService.MaintenanceJob.Query()
          .Filter(xb => xb.EqualsField("Identity", Guid.parse(x.Identity)))
          .Expand(xb => {
            xb.Expand("Schedule")
          })
          .Exec().then(x => x.value))
          .pipe(map(res => res.map(i => {
            Utils.mapAllJSONDatesToDates(i);
            return Object.assign(new ExtendedMaintenanceContractMapping(), i);
          }))).subscribe(i => {
                from(this.odataCoreService.Schedule.Patch()
                  .ValueType(this.odataCoreService.ODataTypes().Schedule())
                  .Key(Guid.parse(i[0].Schedule.Identity))
                  .ValueProperty("Cycle", contractInstallEquipment.Schedule.Cycle)
                  .ValueProperty("CycleUnit", contractInstallEquipment.Schedule.CycleUnit)
                  .ValueProperty("CycleStart", contractInstallEquipment.Schedule.CycleStart)
                  .Exec());

          })
      })).subscribe(res2 => {
        this.saveRequest.emit();
        this.toasterService.pop('success', '', this.translateService.instant('DashboardIntegrityEquipmentsNoContract._toastr_setContractSuccess'));
      });
  }

  close(isDirty: boolean = true): void {
    return this.cancel(isDirty);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('DashboardIntegrityEquipmentsNoContract._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('DashboardIntegrityEquipmentsNoContract._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('DashboardIntegrityEquipmentsNoContract._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('DashboardIntegrityEquipmentsNoContract._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigateByUrl(this.contractHandlingInputData.navigateBackUrlTree);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigateByUrl(this.contractHandlingInputData.navigateBackUrlTree);
    }
  }
}
