import {PropertyCore} from '../../../core/odata/odata.coreapi';
import {
  ExtendedMaintenanceContractMapping
} from '../../../core/model/extendedMaintenanceContractMapping';


export class ContractWizardModel {

  contract: PropertyCore.MaintenanceContract;
  installEquipments: ExtendedMaintenanceContractMapping[];

  reset(): void {
    this.contract = <PropertyCore.MaintenanceContract>{};
    this.installEquipments = [];
  }
}
