import { Component, Input, OnInit } from "@angular/core";

import { PropertyCore } from "app/core/odata/odata.coreapi";
import {
  InspectionDutyExtendedWithBuildingAndEquipment,
  OperatorTaskExtendedInspectionDuties,
} from "app/core/shared/services/building.service";
import { MandatorService } from "app/core/shared/services/mandator.service";
import { forkJoin } from "rxjs";
import { Mandator } from "app/core/model/mandator";
import {
  ExcelService,
  SheetInfo,
} from "app/core/shared/services/shared/excel.service";
import { TranslateService } from "@ngx-translate/core";
import { AttributeValuesService } from "app/core/shared/services/attribute-values.service";
import { AttributeValueData } from "app/core/model/attributeValueData";

@Component({
  selector: "app-export-calculation",
  templateUrl: "./export-calculation.component.html",
  styleUrls: ["./export-calculation.component.scss"],
})
export class ExportCalculationComponent implements OnInit {
  @Input() title: string;
  public selectedBuildings: PropertyCore.Building[] = [];
  public selectedOperatorTasks: OperatorTaskExtendedInspectionDuties[] = [];
  public allAttributeTypes: PropertyCore.AttributeType[] = [];

  constructor(
    private mandatorService: MandatorService,
    private excelService: ExcelService,
    private translateService: TranslateService,
    private atiiributeValuesService: AttributeValuesService
  ) {}

  ngOnInit() {}

  public onBuildingsSelected(selectedBuildings: PropertyCore.Building[]): void {
    this.selectedBuildings = selectedBuildings;
  }

  public onOperatorTasksSelected(
    selectedOperatorTasks: OperatorTaskExtendedInspectionDuties[]
  ): void {
    this.selectedOperatorTasks = selectedOperatorTasks;
  }

  public export(): void {
    var dataObservables$ = [];
    let mandator$ = this.mandatorService.getCurrentMandatorWithRights$();
    dataObservables$.push(mandator$);
    let attributeTypes$ = this.atiiributeValuesService.getAttributeTypes$();
    dataObservables$.push(attributeTypes$);

    forkJoin(dataObservables$).subscribe((data: any[]) => {
      var mandantExcelData = this.getMandatorExcelData(data[0]);
      var buildingsData = this.getBuildingsExcelData();
      var documentsData = this.getDocumentsExcelData();
      this.allAttributeTypes = data[1];

      var sheets: SheetInfo[] = [];
      sheets[0] = { title: "Firmenkonto", data: mandantExcelData };
      sheets[1] = { title: "Gebäudeliste", data: buildingsData };
      //sheets[2] = { title: "Dokumente", data: documentsData };

      let opTasksSheets = this.getOperatorTasksExcelSheets();
      opTasksSheets.sortBy((s) => s.title);

      for (let i = 0; i < opTasksSheets.length; i++) {
        sheets[i + 2] = opTasksSheets[i];
      }

      this.excelService.saveFile("Angebots-/Ausschreibungsexport", sheets);
    });
  }

  private getOperatorTasksExcelSheets(): SheetInfo[] {
    let sheets: SheetInfo[] = [];
    for (let i = 0; i < this.selectedOperatorTasks.length; i++) {
      let currOpTask = this.selectedOperatorTasks[i];
      let targetName = currOpTask.Guidelines + "-" + currOpTask.Id;
      if (targetName.length > ExcelService.MAX_ALLOWED_SHEET_LENGTH_NAME) {
        let maxLengthGuidlines =
          ExcelService.MAX_ALLOWED_SHEET_LENGTH_NAME -
          (currOpTask.Id.toString().length + 1); //1 is for -
        targetName =
          currOpTask.Guidelines.slice(0, maxLengthGuidlines) +
          "-" +
          currOpTask.Id;
      }
      let currSheet = {
        //Es gibt Prüfpflichten, die dieselbe Name haben, aber die verschieden sind (auch verschiedene Ids)
        title: targetName,
        data: this.getOperatorTaskExcelData(currOpTask),
      };

      sheets.push(currSheet);
    }

    return sheets;
  }

  private getEquipmentAttributesDict(
    equipment: PropertyCore.Equipment
  ): { [key: string]: string } {
    var attributesData = this.atiiributeValuesService.getEquipmentAttributesData(
      equipment,
      this.allAttributeTypes
    );
    let result = {};
    attributesData.forEach((attr) => {
      result[attr.Name] = attr.Value;
    });

    return result;
  }

  private getOperatorTaskExcelData(
    opTask: OperatorTaskExtendedInspectionDuties
  ): any[][] {
    let data: any[][] = [[]];

    let headers: (string | AttributeValueData)[] = [
      "Gebäude ID",
      "Gebäude",
      "Anlagen ID",
      "Anlagentyp",
      "Anlagenname",
      "Interne Bezeichnung",
      "Kommentar zur Anlage",
      "Etage",
      "Raum",
      "QR Code",
      "Equipment Nr.",
      "Regelwerk",
      "Prüfobjekt",
      "Interval",
      "Qualifikation",
      "Kommentar",
    ];

    //Add attributes to headers
    let processedQquipmentTypeIds = [];
    let attributeHeaders: AttributeValueData[] = [];
    opTask.InspectionDuties.forEach((id) => {
      if (
        processedQquipmentTypeIds.indexOf(id.Equipment.EquipmentType.Id) < 0
      ) {
        processedQquipmentTypeIds.push(id.Equipment.EquipmentType.Id);

        let attributes = this.atiiributeValuesService.getEquipmentAttributesData(
          id.Equipment,
          this.allAttributeTypes
        );
        attributes.forEach((a) => {
          // The attribute type could be associated to more than one equipment types
          let foundAttributeType = attributeHeaders.find(
            (h) => h.AttributeTypeId == a.AttributeTypeId
          );
          if (!foundAttributeType) {
            attributeHeaders.push(a);
          }
        });
      }
    });
    attributeHeaders.forEach((attributeHeader) => {
      headers.push(attributeHeader);
    });

    // let attrDictForHeaders = this.getEquipmentAttributesDict(
    //   // Take first equipment as the headers will be the same, but for the data use the specific equipment
    //   opTask.InspectionDuties[0].Equipment
    // );
    // Object.keys(attrDictForHeaders).forEach((entry) => {
    //   headers.push(entry);
    // });

    for (let i = 0; i < headers.length; i++) {
      let currHeader = headers[i];
      let headerDisplayValue = "";
      if (typeof currHeader !== "string") {
        headerDisplayValue = currHeader.Name;
      } else {
        headerDisplayValue = currHeader;
      }

      data[0][i] = headerDisplayValue;
    }

    for (let i = 0; i < opTask.InspectionDuties.length; i++) {
      // let attrDict = this.getEquipmentAttributesDict(
      //   opTask.InspectionDuties[i].Equipment
      // );
      let attributes = this.atiiributeValuesService.getEquipmentAttributesData(
        opTask.InspectionDuties[i].Equipment,
        this.allAttributeTypes
      );
      data[i + 1] = this.getInspectionDutyRowExcelData(
        opTask.InspectionDuties[i],
        opTask,
        attributes,
        headers
      );
    }

    return data;
  }

  private getInspectionDutyRowExcelData(
    inspectionDuty: InspectionDutyExtendedWithBuildingAndEquipment,
    operatorTask: OperatorTaskExtendedInspectionDuties,
    attributes: AttributeValueData[],
    headers: (string | AttributeValueData)[]
  ): any[] {
    let row = [];
    row[0] = inspectionDuty.Building.Id; //Gebäude ID
    row[1] = inspectionDuty.Building.Name; //"Gebäude"
    row[2] = inspectionDuty.Equipment.Id; // "Anlagen ID"
    row[3] = inspectionDuty.Equipment.EquipmentType?.Name; //"Anlagentyp"
    row[4] = inspectionDuty.Equipment.Name; // Anlagen Name
    row[5] = inspectionDuty.Equipment.CustomId; //"Interne Bezeichnung"
    row[6] = inspectionDuty.Equipment.Comment; //"Kommentar zur Anlage"
    row[7] = inspectionDuty.Equipment.Room?.Floor?.Name; //"Etage"
    row[8] = inspectionDuty.Equipment.Room?.Name; //"Raum"
    row[9] = inspectionDuty.Equipment.QuickResponseCodes?.map(
      (qr) => qr.Value
    ).join(";"); //"QR Code"
    row[10] = inspectionDuty.Equipment.TuevNo; //"Equipment Nr."
    row[11] = operatorTask.Guidelines; //"Regelwerk"
    row[12] = operatorTask.Subject; //"Prüfobjekt"
    row[13] =
      (inspectionDuty?.Schedule?.Cycle ? inspectionDuty?.Schedule?.Cycle : 0) +
      " " +
      (inspectionDuty?.Schedule?.CycleUnit
        ? this.translateService.instant(
            "CycleUnit." + inspectionDuty?.Schedule?.CycleUnit
          )
        : this.translateService.instant("CycleUnit.NotSpecified")); //"Interval"
    row[14] = operatorTask.Qualification; //"Qualifikation"
    row[15] = ""; //"Kommentar"

    attributes.forEach((attr) => {
      for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
        let header = headers[headerIndex];
        if (typeof header != "string") {
          if (header.AttributeTypeId == attr.AttributeTypeId) {
            row[headerIndex] = attr.Value;
          }
        }
      }
    });

    // Show !! if some attribute type is not assigned to this equipment type
    let equipmentAttributeTypeIds = attributes.map((a) => a.AttributeTypeId);
    for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
      let header = headers[headerIndex];
      if (typeof header != "string") {
        let isAttributeTypeAssignedToCurrentEquipment =
          equipmentAttributeTypeIds.indexOf(header.AttributeTypeId) > -1;
        if (!isAttributeTypeAssignedToCurrentEquipment) {
          row[headerIndex] = "!!";
        }
      }
    }

    // let attributesStartIndex = 16;
    // let attributesCountIndex = 0;
    // Object.keys(attributesDict).forEach((entry) => {
    //   row[attributesStartIndex + attributesCountIndex] = attributesDict[entry];
    //   attributesCountIndex++;
    // });

    return row;
  }

  private getDocumentsExcelData(): any[][] {
    let data: any[][] = [[]];
    let headers = [
      "Gebäude ID",
      "Gebäudebezeichnung",
      "zugeordnete Anlagen",
      "Typ",
      "Name des Dokuments",
      "Ablageort",
    ];

    for (let i = 0; i < headers.length; i++) {
      data[0][i] = headers[i];
    }

    let documentsToBuildings: PropertyCore.Document[] = [];
    this.selectedBuildings.forEach((b) => {
      b.Documents?.forEach((d) => {
        d.Buildings = [b];
        documentsToBuildings.push(d);
      });
    });

    for (let i = 0; i < documentsToBuildings.length; i++) {
      data.push([]);
      let currDocument = documentsToBuildings[i];
      data[i + 1][0] = currDocument.Buildings[0].Id;
      data[i + 1][1] = currDocument.Buildings[0].Name;
      data[i + 1][2] = "";
      data[i + 1][3] = currDocument.DocumentType.Name;
      let name = currDocument.IsVirtual
        ? ""
        : currDocument.Description
        ? currDocument.Description
        : currDocument.Name;
      data[i + 1][4] = name;
      data[i + 1][5] = currDocument.IsVirtual ? currDocument.Description : ""; //Wenn es Ablageort gibt, dann ist es Description d.h. Description ist immer da
    }

    return data;
  }

  private getBuildingsExcelData(): any[][] {
    let data: any[][] = [[]];
    let headers = [
      "Gebäude ID",
      "Gebäude",
      "Land",
      "Bundesland",
      "Zip",
      "Stadt",
      "Address",
    ];

    for (let i = 0; i < headers.length; i++) {
      data[0][i] = headers[i];
    }

    for (let i = 0; i < this.selectedBuildings.length; i++) {
      data.push([]);
      let currBuilding = this.selectedBuildings[i];
      data[i + 1][0] = currBuilding.Id;
      data[i + 1][1] = currBuilding.Name;
      data[i + 1][2] = currBuilding.Address.CountryInfo.Name;
      data[i + 1][3] = currBuilding.Address.RegionInfo.Name;
      data[i + 1][4] = currBuilding.Address.PostCode;
      data[i + 1][5] = currBuilding.Address.City;
      data[i + 1][6] = currBuilding.Address.Street.concat(" ").concat(
        currBuilding.Address.No
      );
    }

    return data;
  }

  private getMandatorExcelData(mandant: Mandator): any[][] {
    let data: any[][] = [];
    for (let i = 0; i < 12; i++) {
      data.push(new Array(2));
    }

    //row 1
    data[0][0] = "Firmenkonto";
    data[0][1] = mandant.Name;

    //row 2
    data[1][0] = "Firmenbezeichnung";
    data[1][1] = mandant.Profile?.Name;

    //row 3
    data[2][0] = "Straße";
    data[2][1] = mandant.Profile?.Address?.Street;

    //row 4
    data[3][0] = "Hausnummer";
    data[3][1] = mandant.Profile?.Address?.No;

    //row 5
    data[4][0] = "Postleitzahl";
    data[4][1] = mandant.Profile?.Address?.PostCode;

    //row 6
    data[5][0] = "Stadt";
    data[5][1] = mandant.Profile?.Address?.City;

    //row 7
    data[6][0] = "Land";
    data[6][1] = mandant.Profile?.Address?.Country;

    //row 8
    data[7][0] = "Adress Zusatz";
    data[7][1] = mandant.Profile?.AddressAddition;

    //row 9
    data[8][0] = "Ansprechpartner";
    data[8][1] = mandant.Profile?.Contact;

    //row
    data[9][0] = "Telefon";
    data[9][1] = mandant.Profile?.ContactPhone;

    //row
    data[10][0] = "E-Mail";
    data[10][1] = mandant.Profile?.ContactMail;

    return data;
  }
}
