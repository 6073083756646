import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PropertyCore } from '../../../core/odata/odata.coreapi';

@Component({
  selector: 'app-search-install-module',
  templateUrl: './search-install-module.component.html',
  styleUrls: ['./search-install-module.component.scss']
})
export class SearchInstallModuleComponent implements OnInit, OnDestroy {

  @Input()
  title: string;

  ieInstallModules: any[]; // array with InstallModule[] per install equipment

  @Output()
  navRequest = new EventEmitter<PropertyCore.Module>();

  private subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @Input()
  set installModules(installModules: PropertyCore.Module[]) {
    this.ieInstallModules = installModules.reduce((prev, next) => {
      const b = prev.find(x => x.equipmentUT === next.Equipment.Identity);
      if (!b) {
        prev.push({ buildingUT: next.Equipment.Building.Identity, buildingName: next.Equipment.Building.Name, equipmentUT: next.Equipment.Identity, equipmentName: (next.Equipment.CustomId ? next.Equipment.CustomId : next.Equipment.Name), dataList: [next] });
      } else {
        b.dataList.push(next);
      }
      return prev;
    }, []);
  }

  nav(installModule: PropertyCore.Module) {
    this.navRequest.emit(installModule);
  }

}
