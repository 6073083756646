import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service'

@Component({
  selector: 'app-equipment-history',
  templateUrl: './equipment-history.component.html',
  styleUrls: ['./equipment-history.component.scss']
})
export class EquipmentHistoryComponent implements OnInit, OnDestroy {

  histories: any[];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {

    this.subscriptions.add(this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => 
      from(this.odataCoreService.InspectionDutyUpdate.Query() 
        .Expand(x => {
            x.Expand("User", y=> {
              y.Select("Identity")
              y.Select("FirstName")
              y.Select("IsTuevEmployee")
              y.Select("LastName")
            }),
            x.Expand("Histories", 
              y => {
                y.Expand("Duty", z => {
                  z.Expand("Equipment", a => {
                    a.Select("Identity")
                  })
                  z.Expand("Schedule", a => {
                    a.Select("Identity")
                    a.Select("CycleStart")
                  })
                  z.Expand("OperatorTask", a => {
                    a.Select("Identity")
                    a.Select("Guidelines")
                  })
                })
              })
        })
        .Filter(x => x
          .Any("Histories","a:a/Duty/Equipment/Identity eq " + Guid.parse(params.get('id')) +"").And
          .Any("Histories","a:a/Duty/IsDisabled eq false"))
        .OrderBy("Timestamp", "asc")
        .Exec()
        .then(x => x.value)
      )))
      .subscribe(res => {
        this.histories = res
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
