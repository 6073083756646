import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { PropertyCore } from '../../../../core/odata/odata.coreapi';

export class ContractHandlingInputData {
  contracts$: Observable<PropertyCore.MaintenanceContract[]>;
  navigateBackUrlTree: UrlTree;
  installEquipment$: Observable<PropertyCore.Equipment>;
  contractTerminationMandatory: boolean = false;
}
