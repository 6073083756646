import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Library } from '../model/odata';


export function countryValidatorFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    const countryIdName: Library.Country = c.value;
    return countryIdName && countryIdName.Id === 0 ? { validateCountry: { valid: false } } : null;
  };
}

@Directive({
  selector: '[validateCountry][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CountryValidator, multi: true }
  ]
})
export class CountryValidator implements Validator {

  validator: ValidatorFn;

  constructor() {
    this.validator = countryValidatorFactory();
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validator(control);
  }
}
