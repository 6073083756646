import { AfterViewChecked, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '.text-truncate'
})
export class TextTruncateTitleDirective implements AfterViewChecked {

  private done = false;

  constructor(private _elementRef: ElementRef) {}

  ngAfterViewChecked() {
    if (!this._elementRef.nativeElement.title) {
      this._elementRef.nativeElement.title = this._elementRef.nativeElement.innerHTML
        // remove binding
        .replace(/<!--.*\n*.*\n*.*-->/g, '')
        // remove outer span
        .replace(/<span[^>]*>/g, '')
        .replace(/<\/span>/g, '')
        // remove outer div
        .replace(/<div[^>]*>/g, '')
        .replace(/<\/div>/g, '\u000d')
        // remove nbsp and br
        .replace('&nbsp;', ' ').replace('<br/>', '\u000d').replace('<br>', '\u000d');
    }
  }
}
