import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Subscription, from, Observable } from "rxjs";
import { DocumentWizardNavigationPaths } from "../document-wizard-navigation-paths.enum";
import { Incident } from "../../../../core/model/incident";
import { DateComparePipe } from "../../../../core/pipes/dateCompare.pipe";
import { FilterByPipe } from "../../../../core/pipes/filter.pipe";
import { ConfirmationModal } from "../../../../core/popup/confirmation.modal";
import { DocumentWizardModel } from "../document-wizard-model";
import { DocumentWizardService } from "../document-wizard.service";
import { ODataPath } from "../../../../core/odata/odataclient";
import { Guid } from "guid-typescript";
import { ODataCoreService } from "../../../../core/odata-services/odata.coreapi.service";
import { map } from "rxjs/internal/operators/map";
import { Utils } from "../../../../core/tools/utils";
import { FileItem } from "ng2-file-upload";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-document-wizard-incident",
  templateUrl: "./document-wizard-incident.component.html",
  styleUrls: ["./document-wizard-incident.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentWizardIncidentComponent implements OnInit, OnDestroy {
  model: DocumentWizardModel;

  buildingIncidents: any[]; // array with Incident[] per building
  installEquipmentIncidents: any[]; // array with Incident[] per installEquipment

  searchFilter: string = "";
  fromDateFilter: Date;
  toDateFilter: Date;
  typeshortvalue: string;
  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<DocumentWizardNavigationPaths>();

  constructor(
    private service: DocumentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private filterByPipe: FilterByPipe,
    private dateComparePipe: DateComparePipe,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    // t+3 month
    const to = new Date();
    to.setMonth(to.getMonth() + 3);
    to.setDate(to.getDate() + 1);
    to.setHours(0, 0, 0, 0);
    // prefilled filter
    this.toDateFilter = to;

    this.model = this.service.getCurrentDocument();

    this.subscriptions.add(
      from(
        this.odataCoreService.Event.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "IsActive"), true)
              //.And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
              .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.building.Identity))
          )
          .Select("Identity", "Id", "Type", "Guidelines", "Qualification", "DueDate", "RefDate")
          .Expand((x) => {
            x.Expand("Schedule", (y) => {
              y.Select("CycleUnit");
              y.Select("Cycle");
              y.Expand("InspectionDuty", (y) => {
                y.Select("OperatorTask");
                y.Expand("OperatorTask", (z) => {
                  z.Select("Guidelines");
                  z.Select("Qualification");
                });
              });
              y.Expand("MaintenanceContract", (z) => {
                z.Select("StartDate");
                z.Expand("ServiceProvider", (za) => {
                  za.Select("Name");
                });
              });
            });
            x.Expand("Building", (y) => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
            });
            x.Expand("Equipment", (y) => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
              y.Select("CustomId");
            });
            x.Expand("Documents", (y) => {
              y.Select("DocumentType"),
                y.Expand("DocumentType", (z) => {
                  z.Select("Id"), z.Select("Name");
                });
            });
            x.Expand("ServiceProvider", (y) => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
            });
            x.Expand("KpiMarker");
          })
          .OrderBy("DueDate", "asc")
          .Exec()
          .then((x) => x.value)
      )
        .pipe(
          map((res) =>
            res.map((i) => {
              return Object.assign(new Incident(), Utils.mapAllJSONDatesToDates(i));
            })
          )
        )
        .subscribe((res) => {
          // if primary incident set, then prefilter results
          this.subscriptions.add(
            this.route.queryParams.subscribe((params) => {
              const incidentId = params["incidentId"];
              if (incidentId && this.model.incidents && this.model.incidents.length) {
                switch (this.model.incidents[0].TypeShort) {
                  case "P":
                  case "W":
                    // for P and W only incidents of same type and with missing required document
                    res = res.filter(
                      (i) => TypeShort(i.Type) === this.model.incidents[0].TypeShort && i.Id === incidentId
                    );
                    break;
                  case "I":
                  case "S":
                    // for I and E only the given incident
                    res = res.filter((i) => i.Id === incidentId);
                    break;
                }
              }
            })
          );

          // preselect already selected items
          if (this.model.incidents && this.model.incidents.length > 0) {
            this.model.incidents.map((mi) => {
              const foundIe = res.filter((i) => i.Identity === mi.Identity);
              foundIe.forEach((x) => {
                x.selected = true;
              });
            });
          }

          this.buildingIncidents = res
            .filter((x) => !x.Equipment)
            .reduce((prev, next) => {
              const b = prev.find((x) => x.buildingUT === next.Building.Identity);
              if (!b) {
                prev.push({
                  buildingUT: next.Building.Identity,
                  buildingName: next.Building.Name,
                  dataList: [next],
                });
              } else {
                b.dataList.push(next);
              }
              return prev;
            }, []);
          this.installEquipmentIncidents = res
            .filter((x) => x.Equipment)
            .reduce((prev, next) => {
              const b = prev.find((x) => x.installEquipmentId === next.Equipment.Identity);
              if (!b) {
                prev.push({
                  installEquipmentId: next.Equipment.Identity,
                  installEquipmentName: next.Equipment.Name,
                  installEquipmentCustomId: next.Equipment.CustomId,
                  dataList: [next],
                });
              } else {
                b.dataList.push(next);
              }
              return prev;
            }, []);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  previous(): void {
    this.pageChangeRequest.emit(DocumentWizardNavigationPaths.PREVIOUS);
  }

  async next(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    // go to next page if missing incident data
    if (
      this.model.DocumentType.Id !== 1 &&
      this.model.DocumentType.Id !== 2 &&
      this.model.missingDataIncidents &&
      this.model.missingDataIncidents.length
    ) {
      this.pageChangeRequest.emit(DocumentWizardNavigationPaths.NEXT);
    } else {
      // save
      if (this.model.virtual) {
        if (this.model.incidents.length) {
          await forkJoin(
            ...this.model.incidents.map(
              async (incident) =>
                await this.odataCoreService.Document.Post()
                  .ValueType(this.odataCoreService.ODataTypes().Document())
                  .ValuePropertyBinding(
                    "DocumentType",
                    this.odataCoreService.DocumentType.Get().Key(this.model.DocumentType.Id).Bind()
                  )
                  .ValueProperty("Description", this.model.name)
                  .Exec()
                  .then(async (res) => {
                    await this.odataCoreService.Event.Link()
                      .Key(Guid.parse(incident.Identity))
                      .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(res.Identity)).Bind())
                      .Post()
                      .then((res1) => {
                        this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                      });
                  })
            )
          );
        }
      } else {
        await this.odataCoreService.Document.Post()
          .ValueType(this.odataCoreService.ODataTypes().Document())
          .ValuePropertyBinding(
            "DocumentType",
            this.odataCoreService.DocumentType.Get().Key(this.model.DocumentType.Id).Bind()
          )
          .ValueProperty("Name", this.model.file.file.name)
          .ValueProperty("Description", this.model.name)
          .Exec()
          .then(async (res) => {
            if (this.model.file.file.type != "") {
              var odataDocumentUrl = this.odataCoreService.Document.Get()
                .Raw()
                .Key(Guid.parse(res.Identity))
                .ToUrlString(false);

              this.uploadODataDocument(this.model.file, odataDocumentUrl)
                .toPromise()
                .then(async (res1) => {
                  var eventIdentity = this.model.incidents.map((x) => x.Identity);
                  let x = 0;
                  while (eventIdentity.length > x) {
                    await this.odataCoreService.Event.Link()
                      .Key(Guid.parse(eventIdentity[x]))
                      .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(res.Identity)).Bind())
                      .Post()
                      .then((res2) => {
                        this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                      });
                    x++;
                  }
                });
            } else {
              await this.uploadDocumentNoContentType(res.Identity);
            }
          });
      }
    }
  }

  uploadODataDocument(file: FileItem, url: string): Observable<any> {
    return this.httpClient.put(url, file._file);
  }

  uploadODataDocumentNoCT(file: Blob, url: string): Observable<any> {
    return this.httpClient.put(url, file);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant("DocumentWizard._modal_title");
      modalRef.componentInstance.message = this.translateService.instant("DocumentWizard._modal_message");
      modalRef.componentInstance.yesButton = this.translateService.instant("DocumentWizard._modal_yes");
      modalRef.componentInstance.cancelButton = this.translateService.instant("DocumentWizard._modal_cancel");

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentDocument();
            this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
    }
  }

  selectAll(all: boolean): void {
    let filtered = this.buildingIncidents.concat(this.installEquipmentIncidents).reduce((prev, next) => {
      return prev.concat(next.dataList);
    }, []);
    if (this.searchFilter) {
      filtered = this.filterByPipe.transform(filtered, this.searchFilter, [
        "Building.Name",
        "Equipment.CustomId",
        "Equipment.Name",
        "Type",
        "Guidelines",
        "ServiceProvider.Name",
        "Schedule.Cycle",
        "Schedule.CycleUnit",
        "DueDate",
      ]);
    }
    if (this.fromDateFilter) {
      filtered = this.dateComparePipe.transform(filtered, this.fromDateFilter, "DueDate", "gte");
    }
    if (this.toDateFilter) {
      filtered = this.dateComparePipe.transform(filtered, this.toDateFilter, "DueDate", "lte");
    }
    filtered.map((incident) => (incident.selected = all));

    this.saveToModel();
  }

  saveToModel(): void {
    const selectedIncidents: Incident[] = this.buildingIncidents
      .concat(this.installEquipmentIncidents)
      .reduce((prev, next) => {
        return prev.concat(next.dataList);
      }, [])
      .filter((incident) => incident.selected);

    if (!this.model.incidents) {
      this.model.incidents = selectedIncidents;
    } else {
      // ignore already set incidents and remove unselected ones
      const incidents = this.model.incidents.filter((cie) =>
        selectedIncidents.some((sie) => sie.Identity === cie.Identity)
      );
      // add new incidents to model
      selectedIncidents.forEach((sie) => {
        if (!incidents.some((cie) => cie.Identity === sie.Identity)) {
          incidents.push(sie);
        }
      });
      this.model.incidents = incidents;
    }
  }

  async uploadDocumentNoContentType(documentIdentity: string): Promise<void> {
    let fileExtensionIndex = this.model.file.file.name.lastIndexOf(".");
    let fileExtension = this.model.file.file.name.substring(fileExtensionIndex);
    let blobFile;

    switch (fileExtension) {
      case ".pdf":
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "application/pdf");
        break;

      case ".png":
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "image/png");
        break;

      case ".jpg":
      case ".jpeg":
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "image/jpeg");
        break;

      case ".tif":
      case ".tiff":
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "image/tiff");
        break;

      case ".doc":
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "application/msword");
        break;

      case ".docx":
        blobFile = this.model.file._file.slice(
          0,
          this.model.file.file.size,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
        break;

      case ".xls":
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "application/vnd.ms-excel");
        break;

      case ".xlsx":
        blobFile = this.model.file._file.slice(
          0,
          this.model.file.file.size,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        break;

      case ".ppt":
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "application/vnd.ms-powerpoint");
        break;

      case ".pptx":
        blobFile = this.model.file._file.slice(
          0,
          this.model.file.file.size,
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        );
        break;

      case ".dwg":
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "image/vnd.dwg");
        break;

      default:
        blobFile = this.model.file._file.slice(0, this.model.file.file.size, "application/octet-stream");
        break;
    }

    var odataDocumentUrl = this.odataCoreService.Document.Get()
      .Raw()
      .Key(Guid.parse(documentIdentity))
      .ToUrlString(false);

    this.uploadODataDocumentNoCT(blobFile, odataDocumentUrl)
      .toPromise()
      .then(async (res1) => {
        var eventIdentity = this.model.incidents.map((x) => x.Identity);
        let x = 0;
        while (eventIdentity.length > x) {
          await this.odataCoreService.Event.Link()
            .Key(Guid.parse(eventIdentity[x]))
            .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(documentIdentity)).Bind())
            .Post()
            .then((res2) => {
              this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
            });
          x++;
        }
      });
  }
}

function TypeShort(Type): string {
  switch (Type) {
    case Type.Inspection:
    case Type.FollowUpInspection:
      return "P";
    case Type.Maintenance:
      return "W";
    case Type.Repair:
      return "I";
    case Type.Custom:
      return "S";
  }
  return "";
}
