import {
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Selectable } from "app/core/model/common";
import { FilterByPipe } from "app/core/pipes/filter.pipe";

@Component({
  selector: "app-selectable-items",
  templateUrl: "./selectable-items.component.html",
  styleUrls: ["./selectable-items.component.scss"],
})
export class SelectableItemsComponent<T> implements OnInit {
  @Output() itemsSelected: EventEmitter<T[]> = new EventEmitter();
  public _items: Selectable<T>[] = [];
  @Input() set items(value: Selectable<T>[]) {
    this._items = value;
    this.filteredItems = value;
  }
  get items(): Selectable<T>[] {
    return this._items;
  }
  @Input() filterableProperties: string[] = [];
  @Input() labelItems: string = "";
  @Input() itemTemplateRef: TemplateRef<any>;
  @Input() placeholderSearch: string;
  public filteredItems: Selectable<T>[];
  public searchFilter: string = "";
  public allSelected: boolean = false;

  get selectedCount(): number {
    return this.filteredItems
      ? this.filteredItems.filter((x) => x.selected).length
      : 0;
  }

  constructor(private filterByPipe: FilterByPipe) { }

  ngOnInit() { }

  public select(item: Selectable<T>, checked: boolean): void {
    item.selected = checked;
    let selectedItems = this.filteredItems.filter((b) => b.selected);
    this.allSelected = selectedItems.length === this.filteredItems.length;
    this.itemsSelected.emit(selectedItems);
  }

  public onSearchFilterChange(newValue: string): void {
    this.searchFilter = newValue;
    this.filteredItems = this.filterByPipe.transform(
      this.items,
      this.searchFilter,
      this.filterableProperties
    );
  }

  selectAll(selectAll: boolean): void {
    this.filteredItems.forEach((item) => (item.selected = selectAll));

    let selectedBuildings = this.filteredItems.filter((i) => i.selected);
    this.itemsSelected.emit(selectedBuildings);
  }
}
