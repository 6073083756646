import { Observable } from 'rxjs';

import { PropertyCore } from '../../odata/odata.coreapi';

export class ContractListInputdata {

  contracts$: Observable<PropertyCore.MaintenanceContract[]>;
  contractUT$: Observable<string>;
  showSearch: boolean = true;
  showWizardButton: boolean = true;
  scrollStyle: {};
}
