import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, Observable, from} from 'rxjs';

import {ConfirmationModal} from './../../../../core/popup/confirmation.modal';
import {MandatorWizardModel} from './../mandator-wizard-model';
import {MandatorWizardNavigationPaths} from './../mandator-wizard-navigation-paths.enum';
import {MandatorWizardService} from './../mandator-wizard.service';
import { ODataCoreService } from './../../../../core/odata-services/odata.coreapi.service';
import { map } from 'rxjs/operators';
import { Utils } from '../../../../core/tools/utils';

@Component({
  selector: 'app-mandator-wizard-data',
  templateUrl: './mandator-wizard-data.component.html',
  styleUrls: ['./mandator-wizard-data.component.scss']
})
export class MandatorWizardDataComponent implements OnInit, OnDestroy {

  model: MandatorWizardModel;

  formSubmitted: boolean;

  

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<MandatorWizardNavigationPaths>();
  

  constructor(
    private service: MandatorWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentMandator();
    if (!this.model.contact) {
      this.model.contact = localStorage.getItem('infoUserName') + ' ' + localStorage.getItem('infoUserSurname');
    }
    if (!this.model.contactMail) {
      this.model.contactMail = localStorage.getItem('infoUserEmail');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  next(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(MandatorWizardNavigationPaths.NEXT);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('CreateMandator._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('CreateMandator._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('CreateMandator._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('CreateMandator._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentMandator();
            this.pageChangeRequest.emit(MandatorWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(MandatorWizardNavigationPaths.EXIT);
    }
  }

}
