import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DocumentType, DocumentWizardModel } from './../document-wizard-model';
import { DocumentWizardNavigationPaths } from './../document-wizard-navigation-paths.enum';
import { DocumentWizardService } from './../document-wizard.service';

@Component({
  selector: 'app-document-wizard-progress',
  templateUrl: './document-wizard-progress.component.html',
  styleUrls: ['./document-wizard-progress.component.scss']
})
export class DocumentWizardProgressComponent implements OnInit {

  PAGE_DATA = DocumentWizardNavigationPaths.DATA;
  PAGE_TYPE = DocumentWizardNavigationPaths.TYPE;
  PAGE_EQUIPMENT = DocumentWizardNavigationPaths.EQUIPMENT;
  PAGE_INCIDENT = DocumentWizardNavigationPaths.INCIDENT;
  PAGE_INCIDENT_DATA = DocumentWizardNavigationPaths.INCIDENT_DATA;

  DOCUMENT_TYPE_BUILDING = DocumentType.BUILDING;
  DOCUMENT_TYPE_EQUIPMENT = DocumentType.EQUIPMENT;
  DOCUMENT_TYPE_INCIDENT = DocumentType.INCIDENT;

  model: DocumentWizardModel;

  @Output()
  pageChangeRequest = new EventEmitter<DocumentWizardNavigationPaths>();

  @Input()
  currentPage: DocumentWizardNavigationPaths;

  constructor(private service: DocumentWizardService) { }

  ngOnInit() {
    this.model = this.service.getCurrentDocument();
  }

  navigate(pageChangeEvent: DocumentWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }

}
