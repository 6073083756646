import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { IEnvironmentConfig } from "./app.environmentconfig.ts";

import { tap } from "rxjs/operators";

import { ODataCoreService } from "../odata-services/odata.coreapi.service";

@Injectable()
export class AppConfig {
  public static readonly TOKEN_POSTFIX: string = "token";

  public static readonly USERINFO_POSTFIX: string = "userinfo";

  static settings: IEnvironmentConfig = null;

  constructor(
    private http: HttpClient,
    private authservice: OAuthService,
    private odataCoreApi: ODataCoreService
  ) {
    console.log("C:AppConfig, Injector(HttpClient)");
  }

  public getBaseUrl() {
    return AppConfig.settings.apiHost + "REST-OBB/";
  }

  public getLoginHostURL() {
    return AppConfig.settings.loginHost;
  }

  public getODataBaseUrl() {
    return AppConfig.settings.apiHost + "odata/";
  }

  public getLogoutUrl() {
    return AppConfig.settings.logoutRedirectUrl;
  }

  public getappInsightsKey() {
    return AppConfig.settings.appInsightsKey;
  }

  public getGoogleApiKey() {
    return AppConfig.settings.googleApiKey;
  }

  public getGaEnvName() {
    return AppConfig.settings.gaEnvName;
  }

  public getGaId() {
    return AppConfig.settings.gaId;
  }

  public getGaSection() {
    return AppConfig.settings.gaSection;
  }

  public load() {
    const environemtnFile = "/environment.json";

    var promise = this.http
      .get(environemtnFile)
      .pipe(
        tap((response) => {
          AppConfig.settings = <IEnvironmentConfig>response;
          const settings = AppConfig.settings;
          const oauthService = this.authservice;
          oauthService.oidc = false;
          oauthService.requireHttps = false;
          oauthService.tokenEndpoint =
            settings.loginHost + AppConfig.TOKEN_POSTFIX;
          oauthService.clientId = "";
          oauthService.scope = "";
          oauthService.userinfoEndpoint =
            settings.loginHost + AppConfig.USERINFO_POSTFIX;
          oauthService.setStorage(localStorage);
          // We need some reserve for token forwarding in the backend
          oauthService.timeoutFactor = 0.5;
          const odataCoreApi = this.odataCoreApi;
          odataCoreApi.ODataSettings.Url = this.getODataBaseUrl();
          odataCoreApi.ODataSettings.http = this.http;
          return response;
        }),
        tap((config) => {
          console.log("environment.json is loaded");
        })
      )
      .toPromise();

    return promise;
  }
}
