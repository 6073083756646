import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {filter, map, startWith, switchMap} from 'rxjs/operators';

import {InstallEquipmentService} from '../../../building/installEquipment.service';
import {InstallEquipmentKpiFilter} from '../../../building/kpiFilter.enum';
import {EquipmentListInputData} from '../../../core/components/equipment/equipment-list-input-data';
import {IntegrityBoxComponent} from '../../dashboard-boxes/integrity-box/integrity-box.component';
import {EquipmentsNoContractState} from '../equipments-no-contract/equipments-no-contract-state.service';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { Utils } from '../../../core/tools/utils';
import { ODataPath } from '../../../core/odata/odataclient';

@Component({
  selector: 'app-equipments-contract-not-scheduled',
  templateUrl: './equipments-contract-not-scheduled.component.html',
  styleUrls: ['./equipments-contract-not-scheduled.component.scss']
})
export class EquipmentsContractNotScheduledComponent implements OnInit, OnDestroy {

  inputData: EquipmentListInputData = new EquipmentListInputData();

  subscriptions: Subscription = new Subscription();

  constructor(
    private installEquipmentService: InstallEquipmentService,
    private route: ActivatedRoute,
    private router: Router,
    private state: EquipmentsNoContractState,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.inputData.showBuilding = true;
    this.inputData.showGroupingWizardRow = false;
    this.inputData.equipments$ = from(this.odataCoreService.EquipmentType.Query().Exec().then(x => x.value));


    this.inputData.installEquipments$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(v => {
        return from(this.odataCoreService.Equipment.Query()
          .OrderBy("Name")
          .Filter(x =>
            x.Equals(ODataPath.For("KpiMarker", "IsMaintained"), false).And
            .Equals(ODataPath.For("KpiMarker", "IsActive"), true))
          .Expand(x => {
            x.Expand("Building"),
              x.Expand("EquipmentType"),
              x.Expand("Room", y => {
                y.Expand("Floor")
              })
          })
          .Exec().then(x => x.value))
          .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))));
      }));
    this.inputData.currentInstallEquipmentUT$ = this.route.root.firstChild.children[1].paramMap.pipe(map(p => p.get('id')));
    this.inputData.scrollStyle = {height: 'calc(100vh - 250px)'};

    this.subscriptions.add(this.route.queryParams.pipe(filter(params => params.search)).subscribe(params => {
      this.inputData.searchFilter = params['search'] || '';
    }));
    this.inputData.onSearchFilterChange = (newValue) => {
      this.inputData.searchFilter = newValue;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {search: this.inputData.searchFilter},
        queryParamsHandling: 'merge'
      });
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleDetailPageRequest(installEquipmentUT: string) {
    this.router.navigate([
      '/dashboard',
      {
        outlets: {
          left: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_CONTRACT_NOT_SCHEDULED],
          right: ['equipment', installEquipmentUT, 'contract', 'terminate']
        }
      }
    ], {queryParams: {search: this.inputData.searchFilter}, queryParamsHandling: 'merge'});
  }
}
