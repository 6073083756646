import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subscription, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PropertyCore } from '../../core/odata/odata.coreapi';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../core/odata/odataclient';



@Component({
  selector: 'app-building-room-list',
  templateUrl: './building-room-list.component.html',
  styleUrls: ['./building-room-list.component.scss']
})
export class BuildingRoomListComponent implements OnInit, OnDestroy {

  building: PropertyCore.Building;

  rooms: PropertyCore.Room[];
  floors: PropertyCore.Floor[];

  searchFilter: string = '';
  floorFilter: string = '';

  newRoomName: string;
  newRoomFloor: PropertyCore.Floor;
  buildingIdentity: string;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.route.root.firstChild.paramMap.pipe(switchMap((params: ParamMap) => {
      this.buildingIdentity = params.get('id');
      return combineLatest(
        from(this.odataCoreService.Building.Get()
        .Key(Guid.parse(this.buildingIdentity))
        .Expand(x => {
          x.Expand("UsageTypes");
          x.Expand("Statistics");
        })
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
        ,
        from(this.odataCoreService.Room.Query()
          .Expand(x => {
            x.Expand("Floor");
            x.Expand("Statistics");
          })
          .Filter(x => x
            .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.buildingIdentity)))
          .OrderBy("Name", "asc")
          .Exec().then(x => x.value ))
          .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
        ,
        from(this.odataCoreService.Building.Get()
          .Key(Guid.parse(this.buildingIdentity))
          .Expand(x => {
            x.Expand("Floors");
          })
          .Exec().then(x => x.value))
          .pipe(map(res => res[0].Floors.map(f => Utils.mapAllJSONDatesToDates(f)).sort((a, b) => a.Order - b.Order))));
      }))
      .subscribe(([building, rooms, floors]) => {
        this.building = building;
        this.rooms = rooms;
        this.floors = floors;

        // navigate to first room
        if (this.rooms && this.rooms.length && (!this.route.snapshot.parent || !this.route.snapshot.parent.children || this.route.snapshot.parent.children.length < 2 || !this.route.snapshot.parent.children[1].url || !this.route.snapshot.parent.children[1].url.length || this.route.snapshot.parent.children[1].url[0].path !== 'room')) {
          this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['room-list'], right: ['room', this.rooms[0].Identity] } }]);
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async openNewRoomPopup(content): Promise<void> {
    this.modalService.open(content).result.then(async result => {
      // on new room save click
      let res = await this.odataCoreService.Room.Post()
        .ValueType(this.odataCoreService.ODataTypes().Room())
        .ValueProperty("Name", this.newRoomName)
        .ValuePropertyBinding("Floor", this.odataCoreService.Floor.Get().Key(this.newRoomFloor.Id).Bind())
        .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.building.Identity)).Bind())
        .Exec();
      let res2 = await this.odataCoreService.Room.Query()
        .Expand(x => {
          x.Expand("Floor");
          x.Expand("Statistics");
        })
        .Filter(x => x
          .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.buildingIdentity)))
        .OrderBy("Name", "asc")
        .Exec().then(x => x.value);
             this.rooms = res2.map(i => Utils.mapAllJSONDatesToDates(i))
            // select new room
            const newRoomUT = this.rooms.filter(r => r.Id === res);
            if (newRoomUT && newRoomUT.length) {
              this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['room-list'], right: ['room', newRoomUT[0].Identity] } }]);
            }
          this.newRoomName = null;
          this.newRoomFloor = null;
    }).catch(() => {
      // do nothing, just stay on page
    });
  }
}
