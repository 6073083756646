import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BuildingWizardNavigationPaths } from './building-wizard-navigation-paths';
import { BuildingWizardService } from './building-wizard.service';
import { Utils } from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { map } from 'rxjs/internal/operators/map';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';

@Component({
  selector: 'app-building-wizard',
  templateUrl: './building-wizard.component.html',
  styleUrls: ['./building-wizard.component.scss']
})
export class BuildingWizardComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  currentPage: BuildingWizardNavigationPaths;

  editMode: boolean = false;

  modelReady: boolean;

  PAGE_DATA = BuildingWizardNavigationPaths.DATA;
  PAGE_LOCATION = BuildingWizardNavigationPaths.LOCATION;
  PAGE_USAGE = BuildingWizardNavigationPaths.USAGE;
  PAGE_EQUIPMENT = BuildingWizardNavigationPaths.EQUIPMENT;

  constructor(
    private route: ActivatedRoute,
    private service: BuildingWizardService,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.modelReady = false;
    this.editMode = this.route.snapshot.data.editMode;
    this.currentPage = this.PAGE_DATA;

    // preload building data
    if (this.editMode) {
      this.subscriptions.add(this.route.paramMap.pipe(switchMap((params: ParamMap) => {
        return from(this.odataCoreService.Building.Get()
          .Key(Guid.parse(params.get('id')))
          .Expand(x => {
            x.Expand("UsageTypes");
            x.Expand("Statistics");
          })
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      }))
      .subscribe(building => {
        this.service.startWizard(building);
        this.modelReady = true;
      }));
    } else {
      this.service.startWizard();
      this.modelReady = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  processPageChangeRequest(pageChangeEvent: BuildingWizardNavigationPaths): void {

    switch (pageChangeEvent) {

      case BuildingWizardNavigationPaths.START: {
        this.currentPage = this.PAGE_DATA;
        break;
      }
      case BuildingWizardNavigationPaths.EXIT: {
        // 1. prio: if we have created a new building, we directly navigate to it
        if (this.service.getCurrentBuilding() && this.service.getCurrentBuilding().Identity) {
          this.router.navigate(['/building', this.service.getCurrentBuilding().Identity, { outlets: { left: ['list'], right: ['building'] } }]);
        }
        // 2. prio: if we haven't created a new building, navigate back to building list
        else {
          this.router.navigate(['/building']);
        }
        break;
      }
      case BuildingWizardNavigationPaths.NEXT: {
        if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_LOCATION;
        } else if (this.currentPage === this.PAGE_LOCATION) {
          this.currentPage = this.PAGE_USAGE;
        } else if (this.currentPage === this.PAGE_USAGE) {
          this.currentPage = this.PAGE_EQUIPMENT;
        }
        break;
      }
      case BuildingWizardNavigationPaths.PREVIOUS: {
        if (this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_USAGE;
        } else if (this.currentPage === this.PAGE_USAGE) {
          this.currentPage = this.PAGE_LOCATION;
        } else if (this.currentPage === this.PAGE_LOCATION) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case BuildingWizardNavigationPaths.DATA: {
        if (this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_LOCATION || this.currentPage === this.PAGE_USAGE || this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case BuildingWizardNavigationPaths.LOCATION: {
        if (this.currentPage === this.PAGE_LOCATION || this.currentPage === this.PAGE_USAGE || this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_LOCATION;
        }
        break;
      }
      case BuildingWizardNavigationPaths.USAGE: {
        if (this.currentPage === this.PAGE_USAGE || this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_USAGE;
        }
        break;
      }
      case BuildingWizardNavigationPaths.EQUIPMENT: {
        if (this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_EQUIPMENT;
        }
        break;
      }
    }
  }
}



