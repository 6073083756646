import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { PropertyCore } from '../../core/odata/odata.coreapi';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { Utils } from '../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-building-document-overview',
  templateUrl: './building-document-overview.component.html',
  styleUrls: ['./building-document-overview.component.scss']
})
export class BuildingDocumentOverviewComponent implements OnInit, OnDestroy {

  building: PropertyCore.Building;
  currentTab: string;

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {

    if (!this.route.firstChild) {
      // redirect to route with tabs outlet set to default value
      this.router.navigate([{ outlets: { tab: ['incident'] } }], { relativeTo: this.route });
    } else {
      // activate correct tab
      const path = this.route.firstChild.routeConfig.path;
      this.currentTab = path;
    }

    this.subscriptions.add(this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd), map(() => this.route))
      .subscribe((event) => {
        if (!this.route.firstChild) {
          // redirect to route with tabs outlet set to default value
          this.router.navigate([{ outlets: { tab: ['incident'] } }], { relativeTo: this.route });
        } else {
          // activate correct tab
          const path = this.route.firstChild.routeConfig.path;
          this.currentTab = path;
        }
      }));

    this.subscriptions.add(this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => {
      return from(this.odataCoreService.Building.Get()
        .Key(Guid.parse(params.get('id')))
        .Expand(x => {
          x.Expand("UsageTypes");
          x.Expand("Statistics");
        })
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
    })).subscribe(building => this.building = building));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.router.navigate([{ outlets: { tab: [changeEvent.nextId] } }], { relativeTo: this.route });
  }

}
