import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject, Subscription, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PropertyCore } from '../../../../core/odata/odata.coreapi';
import { EquipmentWizardNavigationPaths } from '../equipment-wizard-navigation-paths.enum';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { EquipmentWizardModel } from './../equipment-wizard-model';
import { EquipmentWizardService } from './../equipment-wizard.service';
import { ODataPath } from '../../../../core/odata/odataclient';
import { Utils } from '../../../../core/tools/utils';
import { map } from 'rxjs/internal/operators/map';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-equipment-wizard-data',
  templateUrl: './equipment-wizard-data.component.html',
  styleUrls: ['./equipment-wizard-data.component.scss']
})
export class EquipmentWizardDataComponent implements OnInit, OnDestroy {

  model: EquipmentWizardModel;

  floors: PropertyCore.Floor[];
  rooms: PropertyCore.Room[];

  newRoomName: string;

  qrCodeState: PropertyCore.QuickResponseCode;
  newQrCode = new Subject<string>();

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<EquipmentWizardNavigationPaths>();

  constructor(
    private service: EquipmentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.model = this.service.getCurrentEquipment();
    this.subscriptions.add(from(this.odataCoreService.Building.Get()
      .Key(Guid.parse(this.model.buildingId))
      .Expand(x => {
        x.Expand("Floors");
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res[0].Floors.map(f => Utils.mapAllJSONDatesToDates(f)).sort((a, b) => a.Order - b.Order))).subscribe(res => this.floors = res));
    if (this.model.floor) {
      this.subscriptions.add(from(this.odataCoreService.Room.Query()
          .Expand(x => {
            x.Expand("Floor");
            x.Expand("Statistics");
          })
          .Filter(x => x
            .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.buildingId)).And
            .Equals(ODataPath.For("Floor", "Id"), this.model.floor.Id))
          .OrderBy("Name", "asc")
          .Exec().then(x => x.value))
          .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
          .subscribe(res => this.rooms = res));
    }

    this.newQrCode
        .pipe(debounceTime(200), distinctUntilChanged(), switchMap(term => (term ? from(this.odataCoreService.QuickResponseCode.Query()
        .Filter(x => x
            .EqualsField("Id", +term))
            .Expand(x => {
            x.Expand("Equipments")})
        .Exec().then(x => x.value))
        .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
          .pipe(map(res => (res && res.length) ? res[0] : <PropertyCore.QuickResponseCode>{})) : of(<PropertyCore.QuickResponseCode>null))))
      .subscribe(res => (this.qrCodeState = res));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.NEXT);
  }

  previous(): void {
    this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.PREVIOUS);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('EquipmentWiz._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('EquipmentWiz._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('EquipmentWiz._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('EquipmentWiz._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentEquipment();
            this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.EXIT);
    }
  }

  onFloorChange(selectedFloor: PropertyCore.Floor): void {
    this.model.floor = selectedFloor;
    this.model.room = null;
    if (selectedFloor) {
      this.subscriptions.add(from(this.odataCoreService.Room.Query()
        .Expand(x => {
          x.Expand("Floor");
          x.Expand("Statistics");
        })
        .Filter(x => x
          .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.buildingId)).And
          .Equals(ODataPath.For("Floor", "Id"), this.model.floor.Id))
        .OrderBy("Name", "asc")
        .Exec().then(x => x.value))
        .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
        .subscribe(res => this.rooms = res));
    }
    }

    findRoom(rooms: PropertyCore.Room[], roomName: string): PropertyCore.Room {

        let room = rooms.find(x => x.Name == roomName);

        return room
    }

  async openNewRoomPopup(content): Promise<void> {
    this.modalService.open(content).result.then(async (result) => {
      // on save click
      let res = await this.odataCoreService.Room.Post()
        .ValueType(this.odataCoreService.ODataTypes().Room())
        .ValueProperty("Name", this.newRoomName)
        .ValuePropertyBinding("Floor", this.odataCoreService.Floor.Get().Key(this.model.floor.Id).Bind())
        .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.model.buildingId)).Bind())
        .Exec();
        this.model.room = <PropertyCore.Room>{ Id: res, Name: this.newRoomName };
        this.rooms.push(this.model.room);
      let res2 = await this.odataCoreService.Room.Query()
        .Expand(x => {
          x.Expand("Floor");
          x.Expand("Statistics");
        })
        .Filter(x => x
          .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.buildingId)).And
          .Equals(ODataPath.For("Floor", "Id"), this.model.floor.Id))
        .OrderBy("Name", "asc")
        .Exec().then(x => x.value);
            this.rooms = res2.map(i => Utils.mapAllJSONDatesToDates(i))
                  if (this.newRoomName) {
                      let newRoomNameSaved = this.newRoomName

                      this.model.room = this.findRoom(this.rooms, newRoomNameSaved)
                  }
                  this.newRoomName = null
    }).catch(() => {
      // do nothing, just stay on page
    });
    }


    getImgPath(qrCodeId: number): string {
        return this.odataCoreService.QuickResponseCode.Get().Key(qrCodeId).Raw().ToUrlString(false);
    }

  compareId(val1: {Id: number}, val2: {Id: number}): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  searchQrCode(newQrCode: string) {
    this.newQrCode.next(newQrCode.trim());
  }

  assignQrCode(qrCode: string): void {
    this.model.qrCodes = [<PropertyCore.QuickResponseCode>{ Id: +qrCode }];
    this.qrCodeState = null;
  }

  isAllowedToAssignQrCode(): boolean {
    return this.qrCodeState && !!this.qrCodeState.Id;
  }

    deleteQrCode(qrCode: PropertyCore.QuickResponseCode): void {
    const idx = this.model.qrCodes.indexOf(qrCode);
    if (idx > -1) {
      this.model.qrCodes.splice(idx, 1);
    }
  }

  openInfoDialog() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('EquipmentWizData._modal_title');
    modalRef.componentInstance.message = this.translateService.instant('EquipmentWizData._modal_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('EquipmentWizData._modal_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }

  openInfoDialog2() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('EquipmentDetailEdit._modal_qrcode_title');
    modalRef.componentInstance.message = this.translateService.instant('EquipmentDetailEdit._modal_qrcode_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('EquipmentDetailEdit._modal_qrcode_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }

  openInfoDialog3() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('EquipmentWizData._modal_customName_title');
    modalRef.componentInstance.message = this.translateService.instant('EquipmentWizData._modal_customName_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('EquipmentWizData._modal_customName_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }
}
