import { Injectable } from '@angular/core';

import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { IncidentWizardModel } from './incident-wizard-model';

@Injectable()
export class IncidentWizardService {

  model: IncidentWizardModel = new IncidentWizardModel;

  buildingId: string;
  buildingName: string;

  equipment: PropertyCore.Equipment;

  constructor() { }

  getCurrentIncident(): IncidentWizardModel {
    return this.model;
  }

  startWizard(buildingId: string, buildingName: string, equipment: PropertyCore.Equipment): void {
    this.buildingId = buildingId;
    this.buildingName = buildingName;
    this.equipment = equipment;
    this.resetCurrentIncident();
  }

  createIncident(): void {
    // service call
  }

  resetCurrentIncident(): void {
    this.model.reset();
    this.model.buildingUT = this.buildingId;
    this.model.buildingName = this.buildingName;
    this.model.equipment = this.equipment;
  }

}
