import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {Subscription, from} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {EquipmentEditInspectionDutiesInputData} from '../../../../core/components/equipment/equipment-edit-inspection-duties-input-data';
import {IntegrityBoxComponent} from '../../../dashboard-boxes/integrity-box/integrity-box.component';
import {EquipmentNotScheduledInspectionDutiesState} from '../equipment-not-scheduled-inspection-duties-state.service';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { Utils } from '../../../../core/tools/utils';
import { EditOperatorTask } from 'app/core/components/equipment/edit-inspection-duties/editOperatorTask';
import { ODataPath } from 'app/core/odata/odataclient';


@Component({
  selector: 'app-equipment-terminate-inspection-duties',
  templateUrl: './equipment-terminate-inspection-duties.component.html',
  styleUrls: ['./equipment-terminate-inspection-duties.component.scss']
})
export class EquipmentTerminateInspectionDutiesComponent implements OnInit, OnDestroy {
  inputData = new EquipmentEditInspectionDutiesInputData();

  installEquipmentUT: string;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private state: EquipmentNotScheduledInspectionDutiesState,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.inputData.installEquipment$ = this.route.paramMap.pipe(switchMap((params: ParamMap) => 
      from(this.odataCoreService.Equipment.Query()
        .Expand(x => {
          x.Expand("Building"),
          x.Expand("EquipmentType"),
          x.Expand("Room", y =>
            y.Expand("Floor"))
        })
        .Filter(x =>
          x.EqualsField("Identity", Guid.parse(params.get('id'))))
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
    ));

    this.inputData.equipmentOperatorTaskMarkers$ = this.route.paramMap.pipe(switchMap((params: ParamMap) => 
    from(this.odataCoreService.Equipment.Get()
    .Key(Guid.parse(params.get('id')))
    .Expand(x => {
      x.Expand("OperatorTaskMarkers", y => {
        y.Expand("OperatorTask", z => {
          z.Select("Id")
          z.Select("Identity")
          z.Select("Guidelines")
          z.Select("Subject")
          z.Select("Deadline")
          z.Select("Qualification")
          z.Select("Cycle")
          z.Select("CycleUnit")
          z.Select("IsRelevant")
        })
        y.Filter(z => z
          .Equals(ODataPath.For("OperatorTask", "IsRelevant"), true).Or
          .EqualsField("IsMissing", false))
      }),
        x.Expand("InspectionDuties", y => {
          y.Expand("Schedule", z => {
            z.Select("Identity")
            z.Select("Cycle")
            z.Select("CycleUnit")
            z.Select("CycleStart")
          }),
          y.Expand("OperatorTask", z=> {
            z.Select("Identity")
            z.Select("Id")
          })
          y.Filter(z => z
            .EqualsField("IsDisabled", false))
        })
    })
    .Select("Identity")
    .Exec()
    .then(x => x.value)))        
      , map(id => {
      const activatedInspectionDuties = id[0].InspectionDuties;
      const idg: EditOperatorTask[] = []
      id.forEach(x => {
        let a: number = 0;
        while (a < x.OperatorTaskMarkers.length) {

          let activatedOperatorTaskId =  x.OperatorTaskMarkers[a].OperatorTask.Id;

          let activatedInspectionDutyItem = 
            activatedInspectionDuties.filter(x => x.OperatorTask.Id == activatedOperatorTaskId).length != 0 ? 
            activatedInspectionDuties.filter(x => x.OperatorTask.Id == activatedOperatorTaskId)[0] : null;
        
          let idgItem;     
            idgItem = new EditOperatorTask();
            idgItem.guideline = x.OperatorTaskMarkers[a].OperatorTask.Guidelines;
            idgItem.deadline = x.OperatorTaskMarkers[a].OperatorTask.Deadline;
            idgItem.subject = x.OperatorTaskMarkers[a].OperatorTask.Subject;
            idgItem.qualification = x.OperatorTaskMarkers[a].OperatorTask.Qualification;
            idgItem.operatortaskId = x.OperatorTaskMarkers[a].OperatorTask.Id;
            idgItem.operatortaskIdentity = x.OperatorTaskMarkers[a].OperatorTask.Identity;
            idgItem.cycle = x.OperatorTaskMarkers[a].OperatorTask.Cycle;
            idgItem.cycleUnit = x.OperatorTaskMarkers[a].OperatorTask.CycleUnit;
            idgItem.isMissing = x.OperatorTaskMarkers[a].IsMissing;

            if (activatedInspectionDutyItem != null){
              idgItem.aCycle = activatedInspectionDutyItem.Schedule.Cycle;
              idgItem.aCycleUnit = activatedInspectionDutyItem.Schedule.CycleUnit;
              if(activatedInspectionDutyItem.Schedule.CycleStart == null){
                idgItem.aCycleStart = null;
                idgItem.aCycleStartIsNotEmpty = false;
              } else {
                idgItem.aCycleStart = new Date(activatedInspectionDutyItem.Schedule.CycleStart);
                idgItem.aCycleStartIsNotEmpty = true;
              }  
              idgItem.aInspectionDutyIdentity = activatedInspectionDutyItem.Identity;
            } else{
              idgItem.aCycle = null;
              idgItem.aCycleUnit = null;
              idgItem.aCycleStart = null;
              idgItem.aCycleStartIsNotEmpty = false;
              idgItem.aInspectionDutyIdentity = null;
            }

            idg.push(idgItem)         
          a++;
        }
      });
      idg.sort((a, b) => {
        const imNameCompare = a.imName ? a.imName.localeCompare(b.imName) : (b.imName ? -b.imName.localeCompare(a.imName) : 0);
        return imNameCompare ? imNameCompare : (a.guideline ? a.guideline.localeCompare(b.guideline) : (b.guideline ? -b.guideline.localeCompare(a.guideline) : 0));
      });
      return idg;
    }));

    this.inputData.showClose = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleCloseRequest(): void {
    this.router.navigate([
      '/dashboard',
      {
        outlets: {
          left: ['board'],
          right: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_NOT_SCHEDULED_INSPECTION_DUTIES]
        }
      }
    ], {queryParamsHandling: 'merge'});
  }

  handleSaveRequest(): void {
        this.state.notify(EquipmentNotScheduledInspectionDutiesState.ACTION_SCHEDULED_INSPECTION_DUTY);
        this.toasterService.pop('success', '', this.translateService.instant('DashboardIntegrityEquipmentsNotScheduledInspectionDuties._toastr_setInspectionDutySuccess'));
  }

  handleListEmpty(): void {
    this.router.navigate(['/dashboard']);
  }
}
