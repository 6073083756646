import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
import { FileService } from "./file.service";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  private EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  private EXCEL_EXTENSION = ".xlsx";

  public static NOT_ALLOWED_SHEET_NAME_CHARACTERS = [
    "\\",
    "/",
    "*",
    "?",
    ":",
    "[",
    "]",
  ];

  public static MAX_ALLOWED_SHEET_LENGTH_NAME = 31;

  constructor(private fileService: FileService) {}

  public getSaveSheetName(name: string): string {
    let result = name;
    ExcelService.NOT_ALLOWED_SHEET_NAME_CHARACTERS.forEach((char) => {
      result = result.replace(char, "");
    });

    result = result.slice(0, 31);

    return result;
  }

  createFile(name: string, sheets: SheetInfo[]): any {
    var wb = XLSX.utils.book_new();
    wb.Props = {
      Title: name,
      Author: "netinformRE",
    };

    sheets.forEach((sheet) => {
      let saveTitle = this.getSaveSheetName(sheet.title);
      wb.SheetNames.push(saveTitle);
      let ws = XLSX.utils.aoa_to_sheet(sheet.data);
      wb.Sheets[sheet.title] = ws;
    });

    let excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data: Blob = new Blob([excelBuffer], { type: this.EXCEL_TYPE });
    return data;
  }

  public saveFile(name: string, sheets: SheetInfo[]): any {
    let excelBuffer = this.createFile(name, sheets);
    this.fileService.saveAs(excelBuffer, name + this.EXCEL_EXTENSION);
  }
}

export interface SheetInfo {
  title: string;
  data: any[][];
}
