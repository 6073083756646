export enum DocumentWizardNavigationPaths {

  DATA = 'data',
  TYPE = 'type',
  EQUIPMENT = 'equipment',
  INCIDENT = 'incident',
  INCIDENT_DATA = 'incident-data',

  NEXT = 'next',
  PREVIOUS = 'previous',
  START = 'start',
  EXIT = 'exit'
}
