import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datepicker'
})

export class DatepickerPipe implements PipeTransform {

  transform(value: NgbDateStruct): Date {
    return value && new Date(value.year, value.month - 1, value.day);
  }
}
