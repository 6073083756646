import { Component, ViewEncapsulation } from '@angular/core';
import { CoreEquipmentListComponent } from '../list/core-equipment-list.component';


@Component({
  selector: 'app-core-equipment-list-accordion',
  templateUrl: './core-equipment-list-accordion.component.html',
  styleUrls: ['./core-equipment-list-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CoreEquipmentListAccordionComponent extends CoreEquipmentListComponent {
}
