import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "angular2-toaster";
import * as $ from "jquery";
import { PDFDocumentProxy } from "ng2-pdf-viewer";

@Component({
  selector: "app-pdf-modal",
  templateUrl: "./pdf.modal.html",
})
export class PdfModal {
  @Input()
  filename: string;

  @Input()
  pdfDocumentToken: string;

  @Input()
  onComplete: () => void;

  pageCount: number;

  constructor(
    public activeModal: NgbActiveModal,
    private toasterService: ToasterService
  ) {}

  onAfterLoadComplete(pdf: PDFDocumentProxy) {
    // temp save pdf page count for the on complete function
    this.pageCount = pdf.numPages;
  }

  onPageRendered(e: any) {
    $("#loadingoverlay").show(); // in case the overlay is cancelled by other function somewhere else
    // after all pages are rendered, call onComplete
    this.pageCount--;
    if (this.pageCount === 0 && this.onComplete) {
      this.onComplete();
    }
  }

  onError(error: any) {
    this.toasterService.pop(
      "error",
      "",
      "Fehler beim Laden des PDF Dokuments. " + error
    );
  }
}
