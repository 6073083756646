import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from "rxjs";
import { catchError, tap, map } from "rxjs/operators";
import { Mandator } from "../model/mandator";
import { Utils } from "../tools/utils";
import { ODataCoreService } from "../odata-services/odata.coreapi.service";
import { LoginService } from "../shared/login.service";
import { GlobalService } from "../shared/global.service";

@Injectable()
export class AuthService {
  public static readonly USER_MANDATOR_CONFIG = "OB3_Mandator";

  private oAuthService: OAuthService;
  private loginService: LoginService;

  private loginState: BehaviorSubject<boolean>;
  public loginStateInfo: Observable<boolean>;
  private mandatorState: BehaviorSubject<string>;
  public mandatorStateInfo: Observable<string>;
  private mandatorLoadState: Subject<boolean>;
  public mandatorLoadStateInfo: Observable<boolean>;
  private odataCoreService: ODataCoreService;
  private globalService: GlobalService;

  OAUTH_TOKEN_PREFIX = "Bearer ";

  constructor(private injector: Injector, private router: Router) {
    console.log("C:AuthService");
    this.loginState = new BehaviorSubject<boolean>(this.hasToken());
    this.loginStateInfo = this.loginState.asObservable();
    this.mandatorState = new BehaviorSubject<string>(null);
    this.mandatorStateInfo = this.mandatorState.asObservable();
    this.mandatorLoadState = new Subject();
    this.mandatorLoadStateInfo = this.mandatorLoadState.asObservable();
  }

  getOAuthService(): OAuthService {
    if (!this.oAuthService) {
      this.oAuthService = this.injector.get(OAuthService);
    }
    return this.oAuthService;
  }

  getAuthorizationHeader(): string {
    return this.OAUTH_TOKEN_PREFIX + this.getOAuthService().getAccessToken();
  }

  private hasToken(): boolean {
    return !!localStorage.getItem("token");
  }

  login(username: string, password: string): Observable<any> {
    var oauthService = this.getOAuthService();
    oauthService.customQueryParams = {
      application: "OB3",
    };
    const oAuthPromise: Promise<object> = oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(
      username,
      password
    );
    const authResult: Observable<any> = from(oAuthPromise)
      .pipe(
        tap((resp: any) => {
          localStorage.setItem("infoUserEmail", resp.info.info_user_email);
          localStorage.setItem("infoUserName", resp.info.info_user_name);
          localStorage.setItem("infoUserSurname", resp.info.info_user_surname);
          localStorage.setItem("infoUserUpn", resp.info.info_user_upn);
          localStorage.setItem("infoUserDays", resp.info.info_user_days);
          localStorage.setItem("infoUserIdentity", resp.info.info_user_identity);
          localStorage.setItem("token", "oauth2");
          localStorage.setItem("OB3_Info_shown", "false");
          localStorage.setItem("data_processing_agreement", "false");

          this.loadMandator();
        })
      )
      .pipe(
        catchError((err, caught) => {
          this.loginState.next(false);
          err.error = err.error ? err.error : { error_description: "Unbekannter Fehler" };
          console.log("login err.error.error", err.error.error);
          console.log("login err.error.error_description", err.error.error_description);
          this.mandatorLoadState.next(false); // we need the combined observable to be resolved on error too
          return of(err.error);
        })
      );

    return combineLatest(authResult, this.mandatorLoadStateInfo);
  }

  isLoggedIn(): Observable<boolean> {
    return this.loginStateInfo;
  }

  mandatorChanged(): Observable<string> {
    return this.mandatorStateInfo;
  }

  getAccessTokenExpiration(): number {
    return this.getOAuthService().getAccessTokenExpiration();
  }

  logout(): void {
    localStorage.removeItem("infoUserEmail");
    localStorage.removeItem("infoUserName");
    localStorage.removeItem("infoUserSurname");
    localStorage.removeItem("infoUserUpn");
    localStorage.removeItem("infoUserDays");
    localStorage.removeItem("infoUserIdentity");
    localStorage.removeItem("token");
    // localStorage.removeItem('access_token');
    // localStorage.removeItem('access_token_stored_at');
    // localStorage.removeItem('expires_at');
    // localStorage.removeItem('refresh_token');
    localStorage.removeItem("OB3_Info_shown");
    localStorage.removeItem("data_processing_agreement");
    this.loginState.next(false);

    this.getOAuthService().logOut();

    this.router.navigate(["logout"]);
  }

  refreshAccessToken(): Promise<object> {
    return this.getOAuthService()
      .refreshToken()
      .catch(() => {
        this.logout();
        return Promise.reject("refresh token catch reject");
      });
  }

  getUserEmail(): string {
    return localStorage.getItem("infoUserEmail");
  }

  getUserIdentity(): string {
    return localStorage.getItem("infoUserIdentity");
  }

  get advancedMode(): boolean {
    return localStorage.getItem("infoUserEmail") === localStorage.getItem("advanced_mode");
  }
  set advancedMode(value: boolean) {
    if (value) {
      localStorage.setItem("advanced_mode", localStorage.getItem("infoUserEmail"));
    } else {
      localStorage.removeItem("advanced_mode");
    }
  }

  get serviceContactMandator(): boolean {
    return localStorage.getItem("serviceContactMandator") === "true";
  }
  set serviceContactMandator(value: boolean) {
    localStorage.setItem("serviceContactMandator", String(value));
  }
  get userReadOnly(): boolean {
    return localStorage.getItem("subUser") === "true";
  }
  set userReadOnly(value: boolean) {
    localStorage.setItem("subUser", String(value));
  }
  getMandator(): string {
    return localStorage.getItem("mandator");
  }
  setMandator(mandatorIdentity: string, propagateChange: boolean = false): void {
    localStorage.setItem("mandator", mandatorIdentity);
    if (propagateChange) {
      this.mandatorState.next(mandatorIdentity);
    }
  }

  private loadMandator() {
    if (!this.globalService) {
      this.globalService = this.injector.get(GlobalService);
    }

    if (!this.odataCoreService) {
      this.odataCoreService = this.injector.get(ODataCoreService);
    }

    if (!this.loginService) {
      this.loginService = this.injector.get(LoginService);
    }

    this.odataCoreService.Account.Query()
      .OrderBy("Name")
      .Exec()
      .then((x) => {
        let mandators = x.value;

        if (mandators && mandators.length) {
          // read mandator from local storage if available
          const mandatorId = this.getMandator();
          if (mandatorId) {
            const findMandator = mandators.filter((m) => m.Identity === mandatorId);
            if (findMandator && findMandator.length > 0) {
              this.loginState.next(true);
              this.mandatorLoadState.next(true);
              return;
            }
          }

          // preselect mandator from user config
          this.loginService.readSettings(AuthService.USER_MANDATOR_CONFIG).subscribe((res) => {
            if (res) {
              const findMandator = mandators.filter((m) => m.Identity === res["value"]);
              if (findMandator && findMandator.length > 0) {
                this.setMandator(res["value"], true);
                this.loginState.next(true);
                this.mandatorLoadState.next(true);
                return;
              }
            }

            // preselect first mandator
            this.loginService.saveSettings(AuthService.USER_MANDATOR_CONFIG, mandators[0].Identity).subscribe((res) => {
              this.setMandator(mandators[0].Identity, true);
              this.loginState.next(true);
              this.mandatorLoadState.next(true);
              return;
            });
          });
        }
        this.loginState.next(true);
        this.mandatorLoadState.next(false);
      });
  }
}
