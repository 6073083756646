import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class EquipmentNoInspectionDutiesState {

  public static readonly ACTION_SET_INSPECTION_DUTY = 'action_set_inspection_duty';

  private subject: Subject<string> = new Subject();

  constructor() { }

  notify(notificationtext: string) {
    this.subject.next(notificationtext);
  }

  registerForNotification(): Observable<string> {
    return this.subject.asObservable();
  }

}
