import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, from } from "rxjs";

import { AttributeDTO } from "../../../../core/model/attributeDTO";
import { ConfirmationModal } from "../../../../core/popup/confirmation.modal";
import { Utils } from "../../../../core/tools/utils";
import { ModuleWizardModel } from "../module-wizard-model";
import { ModuleWizardNavigationPaths } from "../module-wizard-navigation-paths.enum";
import { ModuleWizardService } from "../module-wizard.service";
import { HttpErrorResponse } from "@angular/common/http";
import { PropertyCore, ObjektBrief } from "../../../../core/odata/odata.coreapi";
import Module = PropertyCore.Module;
import { ToasterService } from "angular2-toaster";
import { ODataCoreService } from "../../../../core/odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";
import { map } from "rxjs/operators";

@Component({
  selector: "app-module-wizard-technical",
  templateUrl: "./module-wizard-technical.component.html",
  styleUrls: ["./module-wizard-technical.component.scss"],
})
export class ModuleWizardTechnicalComponent implements OnInit, OnDestroy {
  model: ModuleWizardModel;

  attributeFilter: string;
  categoryFilter: string = "";
  attributesCategories?: any[] = [];
  attributesCategoryNames?: any[] = [];
  requiredAttributes: any[] = [];
  categoryArray: any[] = [];
  years: string[];
  AttributeType: any;

  subscriptions: Subscription = new Subscription();

  @Output() pageChangeRequest = new EventEmitter<ModuleWizardNavigationPaths>();

  constructor(
    private service: ModuleWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.AttributeType = ObjektBrief.AttributeValueTypes;
    this.model = this.service.getCurrentModule();

    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .Select("AttributeTypeSets")
          .Expand((x) => {
            x.Expand("AttributeTypeSets", (y) => {
              y.Expand("AttributeTypes", (z) => {
                z.Expand("MeasurementUnit");
                z.OrderBy("Order", "asc");
              });
            });
          })
          .Filter((x) => x.EqualsField("Identity", Guid.parse(this.model.equipment.Building.Identity)))
          .Exec()
          .then((x) => x.value)
      )
        .pipe(map((res) => res[0]))
        .subscribe((res1) => {
          this.subscriptions.add(
            from(
              this.odataCoreService.ModuleType.Query()
                .Expand((x) =>
                  x.Expand("AttributeTypes", (y) => {
                    y.Expand("Set");
                    y.Expand("MeasurementUnit");
                  })
                )
                .Filter((x) => x.EqualsField("Id", this.model.installModuleType.Id))
                .Exec()
                .then((x) => x.value)
            )
              .pipe(map((res) => res[0]))
              .subscribe((res) => {
                let a = 0;

                while (res1.AttributeTypeSets.length > a) {
                  let b = 0;

                  while (res1.AttributeTypeSets[a].AttributeTypes.length > b) {
                    let requiredAttributeItem = res.AttributeTypes.find(
                      (x) => x.Id == res1.AttributeTypeSets[a].AttributeTypes[b].Id
                    );

                    if (requiredAttributeItem) {
                      this.requiredAttributes.push(requiredAttributeItem);
                    }

                    b++;
                  }
                  a++;
                }

                this.model.attributesCategories = this.requiredAttributes;

                let x = 0;

                while (this.model.attributesCategories.length > x) {
                  this.attributesCategories.push(this.model.attributesCategories[x]);
                  this.attributesCategoryNames.push(this.model.attributesCategories[x].Set.Name);
                  x++;
                }

                this.categoryArray = this.attributesCategoryNames.filter((element, index, input) => {
                  if (input.indexOf(element) === index) {
                    return element;
                  }
                });

                this.preselectCategory();
              })
          );
        })
    );

    this.years = Utils.yearsTillToday();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private preselectCategory() {
    // pre-select ob standard category
    if (this.attributesCategories.length != 0) {
      var defaultCategory = this.attributesCategories.find((x) => x.Set.IsDefault == true);
      let obStandardCategory = "";

      if (defaultCategory != undefined) {
        obStandardCategory = this.attributesCategoryNames.find((Name) => Name == defaultCategory.Set.Name);
      }

      if (obStandardCategory) {
        this.categoryFilter = obStandardCategory;
      }
    }
  }

  previous(): void {
    this.pageChangeRequest.emit(ModuleWizardNavigationPaths.PREVIOUS);
  }

  async save(andNew: boolean = false): Promise<void> {
    if (this.model.room != null) {
      await this.odataCoreService.Module.Post()
        .ValueType(this.odataCoreService.ODataTypes().Module())
        .ValueProperty("Equipment", this.model.equipment)
        .ValueProperty("ModuleType", this.model.installModuleType)
        .ValueProperty("Room", this.model.room)
        .ValueProperty("CustomId", this.model.customid)
        .ValueProperty("Quantity", this.model.quantity)
        .ValueProperty("Comment", this.model.comment)
        .ValuePropertyBinding(
          "Equipment",
          this.odataCoreService.Equipment.Get().Key(Guid.parse(this.model.equipment.Identity)).Bind()
        )
        .ValuePropertyBinding("Room", this.odataCoreService.Room.Get().Key(Guid.parse(this.model.room.Identity)).Bind())
        .ValuePropertyBinding(
          "ModuleType",
          this.odataCoreService.ModuleType.Get().Key(this.model.installModuleType.Code).Bind()
        )
        .Exec()
        .then(
          (result: Module) => {
            this.saveAttributes(result.Identity, andNew);
            this.toasterService.pop(
              "success",
              "",
              this.translateService.instant("ModuleWizardTechnical._create_success")
            );
          },
          (error: HttpErrorResponse) => {
            this.toasterService.pop("error", "", "ModuleWizardTechnical._create_error");
          }
        );
    } else {
      await this.odataCoreService.Module.Post()
        .ValueType(this.odataCoreService.ODataTypes().Module())
        .ValueProperty("Equipment", this.model.equipment)
        .ValueProperty("ModuleType", this.model.installModuleType)
        .ValueProperty("Room", this.model.room)
        .ValueProperty("CustomId", this.model.customid)
        .ValueProperty("Quantity", this.model.quantity)
        .ValueProperty("Comment", this.model.comment)
        .ValuePropertyBinding(
          "Equipment",
          this.odataCoreService.Equipment.Get().Key(Guid.parse(this.model.equipment.Identity)).Bind()
        )
        .ValuePropertyBinding(
          "ModuleType",
          this.odataCoreService.ModuleType.Get().Key(this.model.installModuleType.Code).Bind()
        )
        .Exec()
        .then(
          (result: Module) => {
            this.saveAttributes(result.Identity, andNew);
            this.toasterService.pop(
              "success",
              "",
              this.translateService.instant("ModuleWizardTechnical._create_success")
            );
          },
          (error: HttpErrorResponse) => {
            this.toasterService.pop("error", "", "ModuleWizardTechnical._create_error");
          }
        );
    }
  }

  private saveAttributes(identity: string, andNew: boolean): void {
    // save attributes
    const attributes: AttributeDTO[] = this.model.attributesCategories
      .filter((attribute) => attribute.Value !== undefined && !!attribute.Value)
      .map((attribute) => {
        const attributeDto = new AttributeDTO();
        attributeDto.Id = attribute.Id;
        attributeDto.Value = attribute.ValueType === "Date" ? Utils.dateToJson(attribute.Value) : attribute.Value;
        attributeDto.TypToken = attribute.ValueType;
        return attributeDto;
      });
    if (attributes.length) {
      let a = 0;
      while (attributes.length > a) {
        if (attributes[a].Value == "") {
        } else {
          this.subscriptions.add(
            from(
              this.odataCoreService.Module.Get()
                .Key(Guid.parse(identity))
                .NavigateToArray<PropertyCore.AttributeValue>("AttributeValues")
                .Patch()
                .Key(attributes[a].Id)
                .ValueType(this.odataCoreService.ODataTypes().AttributeValue())
                .ValueProperty("Value", attributes[a].Value.toString())
                .Exec()
            ).subscribe((res2) => {
              if (attributes.length == a) {
                if (andNew) {
                  this.service.resetCurrentModule();
                  this.pageChangeRequest.emit(ModuleWizardNavigationPaths.START);
                } else {
                  this.pageChangeRequest.emit(ModuleWizardNavigationPaths.EXIT);
                }
              }
            })
          );
        }
        a++;
      }
    } else if (andNew) {
      this.service.resetCurrentModule();
      this.pageChangeRequest.emit(ModuleWizardNavigationPaths.START);
    } else {
      this.pageChangeRequest.emit(ModuleWizardNavigationPaths.EXIT);
    }
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant("ModuleWizard._modal_title");
      modalRef.componentInstance.message = this.translateService.instant("ModuleWizard._modal_message");
      modalRef.componentInstance.yesButton = this.translateService.instant("ModuleWizard._modal_yes");
      modalRef.componentInstance.cancelButton = this.translateService.instant("ModuleWizard._modal_cancel");

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentModule();
            this.pageChangeRequest.emit(ModuleWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(ModuleWizardNavigationPaths.EXIT);
    }
  }
}
