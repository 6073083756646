import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, from } from 'rxjs';
import { Common, PropertyCore } from '../../../../core/odata/odata.coreapi';
import { ConfirmationModal } from '../../../../core/popup/confirmation.modal';
import { IncidentWizardModel } from '../../incident/incident-wizard-model';
import { DefectWizardNavigationPaths } from '../defect-wizard-navigation-paths.enum';
import { DefectWizardService } from '../defect-wizard.service';
import { Utils } from '../../../../core/tools/utils';
import { map } from 'rxjs/operators';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../../core/odata/odataclient';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-defect-wizard-data',
  templateUrl: './defect-wizard-data.component.html',
  styleUrls: ['./defect-wizard-data.component.scss']
})
export class DefectWizardDataComponent implements OnInit, OnDestroy {

  model: IncidentWizardModel;

  incidentTypes: string[] = Object.keys(PropertyCore.EventTypes).filter(x => x !== PropertyCore.EventTypes.Undefined && x !== PropertyCore.EventTypes.FollowUpInspection);
  EventTypes = PropertyCore.EventTypes;
  cycleUnits: string[] = Object.keys(Common.IntervalUnits);

  serviceProviders: PropertyCore.ServiceProvider[] = [];

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<DefectWizardNavigationPaths>();

  constructor(
    private service: DefectWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentDefect();

    this.subscriptions.add(from(this.odataCoreService.ServiceProvider.Query()
      .Filter(x =>
        x.Equals(ODataPath.For("Account", "Identity"), Guid.parse(localStorage.getItem('mandator'))))
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
      .subscribe(res => {
        this.serviceProviders = res;
      }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(DefectWizardNavigationPaths.NEXT);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('DefectWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('DefectWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('DefectWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('DefectWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentDefect();
            this.pageChangeRequest.emit(DefectWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      this.pageChangeRequest.emit(DefectWizardNavigationPaths.EXIT);
    }
  }

  onTypeChange(): void {
    if (this.model.type !== PropertyCore.EventTypes.Inspection && this.model.type !== PropertyCore.EventTypes.Maintenance && this.model.type !== PropertyCore.EventTypes.Custom) {
      this.model.cycle = 1;
      this.model.cycleUnit = Common.IntervalUnits.Undefined;
    }
  }

  compareId(val1: { Id: number }, val2: { Id: number }): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  serviceContactWizard(): void {
    this.pageChangeRequest.emit(DefectWizardNavigationPaths.ADD_SERVICE_CONTACT);
  }

  compareProviders(p1: PropertyCore.ServiceProvider, p2: PropertyCore.ServiceProvider) {
    return p1 && p2 && p1.Identity == p2.Identity;
  }
}
