import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Library } from '../model/odata';


export function regionValidatorFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    const regionIdName: Library.Region = c.value;
    return regionIdName && regionIdName.Id === 0 ? { validateRegion: { valid: false } } : null;
  };
}

@Directive({
  selector: '[validateRegion][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: RegionValidator, multi: true }
  ]
})
export class RegionValidator implements Validator {

  validator: ValidatorFn;

  constructor() {
    this.validator = regionValidatorFactory();
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.validator(control);
  }
}
