import { Observable } from 'rxjs';

import { BuildingDocumentHierarchy } from './../../model/document';
import { PropertyCore } from '../../odata/odata.coreapi';

export class EquipmentDocumentsInputData {
  buildingEquipmentDocument$: Observable<PropertyCore.Building[]>;
  searchFilter$: Observable<string>;
  showBuildingData: boolean = false;
  showEquipmentData: boolean = false;
  currentEquipmentUT$: Observable<string>;
  currentDocumentTypeId$: Observable<number>;
  lastBuildingIdx: number = -1;
  lastEquipmentIdx: number = -1;

  showsAsCallToAction: boolean = true;
  navigateToFirstListItem: boolean = false;
}

export class EquipmentDocumentWizardRequest {
  buildingUT: string;
  ieUT: string;
  documentType: number;
}
