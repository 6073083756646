import { FileItem } from 'ng2-file-upload';

export class ImageUploadModel {
    file: FileItem[] = [];
    entityType: string;

    reset(): void {
        this.file = [];
        this.entityType = null;
    }
}
