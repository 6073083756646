import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { PropertyCore } from '../../../core/odata/odata.coreapi';

@Component({
  selector: 'app-search-install-equipment',
  templateUrl: './search-install-equipment.component.html',
  styleUrls: ['./search-install-equipment.component.scss']
})
export class SearchInstallEquipmentComponent implements OnInit, OnDestroy {

  @Input()
  title: string;

  buildingInstallEquipments: any[]; // array with InstallEquipment[] per building

  @Output()
  navRequest = new EventEmitter<PropertyCore.Equipment>();

  private subscriptions: Subscription = new Subscription();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @Input()
  set installEquipments(installEquipments: PropertyCore.Equipment[]) {
    this.buildingInstallEquipments = installEquipments.reduce((prev, next) => {
      const b = prev.find(x => x.buildingUT === next.Building.Identity);
      if (!b) {
        prev.push({ buildingUT: next.Building.Identity, buildingName: next.Building.Name,   dataList: [next] });
      } else {
        b.dataList.push(next);
      }
      return prev;
    }, []);
  }

  nav(installEquipment: PropertyCore.Equipment) {
    this.navRequest.emit(installEquipment);
  }

}
