import { Injectable } from '@angular/core';
import ConsoleAppender from 'log4ts/build/appenders/ConsoleAppender';
import { IAppender } from 'log4ts/build/IAppender';
import { ILayout } from 'log4ts/build/ILayout';
import BasicLayout from 'log4ts/build/layouts/BasicLayout';
import Logger from 'log4ts/build/Logger';
import LoggerConfig from 'log4ts/build/LoggerConfig';
import { LogLevel } from 'log4ts/build/LogLevel';

@Injectable()
export class LoggerService {
  public log: Logger;

  private appender: IAppender;
  private layout: ILayout;
  private config: LoggerConfig;

  constructor() {
    this.layout = new BasicLayout();
    this.appender = new ConsoleAppender();
    this.appender.setLayout(this.layout);
    this.config = new LoggerConfig(this.appender);
    this.config.setLevel(LogLevel.ALL);
    Logger.setConfig(this.config);
  }

  getLogger(forClass?: string): ILogger {
    return new Logger(forClass);
  }
}

export interface ILogger {
  info(message: string, ...params: any[]);
  fatal(message: string, ...params: any[]);
  error(message: string, ...params: any[]);
  debug(message: string, ...params: any[]);
  warn(message: string, ...params: any[]);
  trace(message: string, ...params: any[]);
}
