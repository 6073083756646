import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, ParamMap, Router } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { PropertyCore } from '../../core/odata/odata.coreapi';
import { AuthService } from './../../core/auth/auth.service';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { Utils } from '../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-building-overview',
  templateUrl: './building-overview.component.html',
  styleUrls: ['./building-overview.component.scss']
})
export class BuildingOverviewComponent implements OnInit, OnDestroy {

  showMenu: boolean;
  building: PropertyCore.Building;
  currentTab: string;
  isDefect: boolean;
  isIncident: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {

    if (!this.route.firstChild) {
      // redirect to route with tabs outlet set to default value
      this.router.navigate([{ outlets: { tab: ['details'] } }], { relativeTo: this.route });
    } else {
      // activate correct tab
      const path = this.route.firstChild.routeConfig.path;
      this.currentTab = path;
    }

    this.subscriptions.add(this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd), map(() => this.route))
      .subscribe((event) => {
        if (!this.route.firstChild) {
          // redirect to route with tabs outlet set to default value
          this.router.navigate([{ outlets: { tab: ['details'] } }], { relativeTo: this.route });
        } else {
          // activate correct tab
          const path = this.route.firstChild.routeConfig.path;
          this.currentTab = path;
        }
      }));

    this.subscriptions.add(this.route.root.firstChild.paramMap.pipe(switchMap((params: ParamMap) => {
      return from(this.odataCoreService.Building.Get()
        .Key(Guid.parse(params.get('id')))
        .Expand(x => {
          x.Expand("UsageTypes");
          x.Expand("Statistics");
        })
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
    })).subscribe(building => this.building = building));
    // Menu is only viewable when ist on the right side
    if (this.route.parent.outlet === 'left') {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }

    // 'Defects of' BuildingName or 'eqipments of' BuildingName
    if (this.route.snapshot.parent.firstChild.firstChild) {
      if (this.route.snapshot.parent.firstChild.firstChild.url[0].path === 'defect') {
        this.isDefect = true;
        this.isIncident = false;
      } else if (this.route.snapshot.parent.firstChild.firstChild.url[0].path === 'incident') {
        this.isIncident = true;
        this.isDefect = false;
      }
    } else {
      this.isDefect = false;
      this.isIncident = false
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  getShowMenu() {
    if (this.showMenu) {
      return 'block';
    } else {
      return 'none';
    }
  }
  showDocumentsIcons(): boolean {
    return this.route.parent.outlet === 'right';
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    const navigationExtras: NavigationExtras = { relativeTo: this.route };
    this.currentTab = changeEvent.nextId;

    if (this.route.parent.outlet === 'left') {
      this.router.navigate(['/building', this.building.Identity, {
        outlets: {
          left: ['list'],
          right: ['building', 'view', { outlets: { tab: [changeEvent.nextId] } }]
        }
      }], navigationExtras);
    } else {
      this.router.navigate([{ outlets: { tab: [changeEvent.nextId] } }], navigationExtras);
    }
  }
}
