import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../bootstrap/app.config';
import { Utils } from '../tools/utils';

@Injectable()
export class LoginService {

  // User Settings
  private saveSettingsPath = 'settings/${saveSettings}';
  private readSettingsPath = 'settings/${readSettings}';

  constructor(
    private httpClient: HttpClient, 
    private config: AppConfig) {
  }

  saveSettings(readSettings: string, value: string): Observable<any> {
    return this.httpClient.post(this.config.getLoginHostURL() + Utils.buildUrl(this.saveSettingsPath, { 'saveSettings': readSettings}), {value}, { responseType: 'text' });
  }

  readSettings(readSettings: string): Observable<string> {
    return this.httpClient.get<string>(this.config.getLoginHostURL() + Utils.buildUrl(this.readSettingsPath, { 'readSettings': readSettings }));
  }
}
