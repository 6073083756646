import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {PropertyCore} from '../../core/odata/odata.coreapi';
import {AuthService} from '../../core/auth/auth.service';
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { map } from 'rxjs/operators';
import { ODataPath } from '../../core/odata/odataclient';

@Component({
  selector: 'app-service-contact-list',
  templateUrl: './service-contact-list.component.html',
  styleUrls: ['./service-contact-list.component.scss']
})
export class ServiceContactListComponent implements OnInit, OnDestroy {

  serviceContacts: PropertyCore.ServiceProvider[];
  serviceContactId: string;

  searchFilter: string = '';

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
      this.subscriptions.add(from(this.odataCoreService.ServiceProvider.Query()
        .OrderBy('Name')
      .Filter(x =>
        x.Equals(ODataPath.For("Account", "Identity"), Guid.parse(localStorage.getItem('mandator'))))
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => {
      this.serviceContacts = res;
    }));
    this.subscriptions.add(this.route.parent.paramMap.subscribe(params => this.serviceContactId = params.get('id')));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  serviceContactWizard(): void {
    if (!this.serviceContactId) {
      if (this.serviceContacts && this.serviceContacts.length > 0) {
        this.serviceContactId = this.serviceContacts[0].Identity;
      } else {
        this.serviceContactId = '';
      }
    }
    this.router.navigate(['/service-contact', this.serviceContactId, {
      outlets: {
        left: ['list'],
        right: ['service-contact-wizard']
      }
    }]);
  }

}
