import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { from, Observable, of, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

import { AppConfig } from './../bootstrap/app.config';
import { AuthService } from './auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  ongoingRefreshToken = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private authService: AuthService, private router: Router
  ) {
    console.log('C:AuthInterceptor: AuthService, Router');
  }

  refreshToken(req: HttpRequest<any>): Observable<any> {
    if (this.ongoingRefreshToken) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          return observer.complete();
        });
      });
    } else {
      this.ongoingRefreshToken = true;

      return from(this.authService.refreshAccessToken()).pipe(map((obj) => {
        this.ongoingRefreshToken = false;
        this.tokenRefreshedSource.next(obj);
      }));
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // If application configuration has not been loaded yet, we just process the request.
    // Among other things that actually happens with the request that is - loading the settings.
    if (!AppConfig.settings) {
      return next.handle(req);
    }

    // If the user is not logged in, there is nothing to add. As such, we just do nothing.
    // Primitive workaround. We directly access this because authService.isLoggedIn() is an observable.
    let loggedin = localStorage.getItem("refresh_token");
    if (!loggedin) {
      return next.handle(req);
    }

    // Ignore .json access files. Only 2 types of them around: Environment and translation files.
    if (req.url.endsWith(".json")) {
      return next.handle(req);
    }

    const now = new Date();
    const exp = new Date(this.authService.getAccessTokenExpiration());

    req = this.setAuthorizationHeader(req);

    let preHandleAutoRefresh: Observable<any> = next.handle(req);

    // ignore token refresh calls
    if (!req.url.endsWith(AppConfig.TOKEN_POSTFIX)) {

      // only if it's a API call with 'expired' date

      // TODO: find a more secure solution to properly address the API base url.
      // we can't directly access the AppConfig, as it gets loaded itself by this interceptor...
      if (req.url.startsWith('http://') && (exp.getTime() <= now.getTime() + 120000)) {
        // For now we do not handle .json files at all. Those never are part of our api.
        preHandleAutoRefresh = this.refreshToken(req).pipe(flatMap(() => {
          req = this.setAuthorizationHeader(req)
          return next.handle(req);
        }));
      }
    }
    return preHandleAutoRefresh
      .pipe(catchError(error => {
        if (error.status === 401) {
          console.log('caught httpError : ', error);
          return this.refreshToken(req)
            .pipe(flatMap(() => {
              req = this.setAuthorizationHeader(req);
              return next.handle(req);
            }))
            .pipe(catchError(() => {
              console.log('Unexpected error occured at refreshing token');
              this.authService.logout();
              return of();
            }));
        }
        return observableThrowError(error);
      }));
  }

  private setAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (!req.url.endsWith(AppConfig.TOKEN_POSTFIX)) {
      return req.clone({
        setHeaders: {
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Authorization: this.authService.getAuthorizationHeader(),
          'Account': this.authService.getMandator()
            // We also handle old style Mandator here.
            || '', 'MandatorIdentity': this.authService.getMandator() || ''
        }
      });
    }
    return req;
  }
}
