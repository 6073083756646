import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription, from } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { BuildingListInputData } from './../../../core/components/building/building-list-input-data';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../core/odata/odataclient';


@Component({
  selector: 'app-buildings-no-equipment',
  templateUrl: './buildings-no-equipment.component.html',
  styleUrls: ['./buildings-no-equipment.component.scss']
})
export class BuildingsNoEquipmentComponent implements OnInit, OnDestroy {

  inputData: BuildingListInputData = new BuildingListInputData();

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.inputData.translatedListTitle = 'DashboardIntegrityBuildingsNoEquipment._h1_buildingOverview';
    this.inputData.currentBuildingUT$ = of(<string>null);
    this.inputData.showGroupingWizardRow = false;
    this.inputData.showMap = false;
    this.inputData.showItemLinkButton = true;

    this.inputData.buildings$ = 
      from(this.odataCoreService.Building.Query()
        .Filter(x => x
          .Equals(ODataPath.For("KpiMarker", "HasEquipment"), false))
        .Exec()
        .then(x => {
          return x.value
        }));

    this.subscriptions.add(this.route.queryParams.pipe(filter(params => params.search)).subscribe(params => { this.inputData.searchFilter = params['search'] || ''; }));
    this.inputData.onSearchFilterChange = (newValue) => {
      this.inputData.searchFilter = newValue;
      this.router.navigate([], { relativeTo: this.route, queryParams: { search: this.inputData.searchFilter }, queryParamsHandling: 'merge' });
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleDetailPageRequest(building: PropertyCore.Building): void {
    this.router.navigate(['/building', building.Identity, { outlets: { left: ['list'], right: ['building'] } }],
      { queryParams: { search: this.inputData.searchFilter }, queryParamsHandling: 'merge' });
  }

}
