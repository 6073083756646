import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { NewsService } from './news.service';
import { LoginService } from 'app/core/shared/login.service';
import { DatePipe } from '@angular/common';
import { ODataPath } from 'app/core/odata/odataclient';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from 'app/core/odata-services/odata.coreapi.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {
  currentTab: string;
  notificationCount: number = 0;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private newsService: NewsService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    if (!this.route.firstChild) {
      // redirect to route with tabs outlet set to default value
      this.router.navigate([{ outlets: { tab: ['notifications'] } }], { relativeTo: this.route });
      this.currentTab = 'notifications';
    } else {
      // activate correct tab
      const path = this.route.firstChild.routeConfig.path;
      this.currentTab = path;
    }

    this.subscriptions.add(this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd), map(() => this.route))
      .subscribe((event) => {
        if (!this.route.firstChild) {
          // redirect to route with tabs outlet set to default value
          this.router.navigate([{ outlets: { tab: ['notifications'] } }], { relativeTo: this.route });
        } else {
          // activate correct tab
          const path = this.route.firstChild.routeConfig.path;
          this.currentTab = path;
        }
      }));
    this.subscriptions.add(this.newsService.registerForChange().pipe(switchMap(x =>
      this.loginService.readSettings('Ob2Webclient_LastNotificationDate_' + localStorage.getItem('mandator'))
        .pipe(switchMap(date => {
          let cutoff = new Date();
          cutoff.setDate(cutoff.getDate() - 30);
          if (date) {
            const cutoff2 = new Date(date);
            if (cutoff.getTime() < cutoff2.getTime()) {
              cutoff = cutoff2;
            }
          }

          return this.odataCoreService.ActionLog.Query()
            .Filter(x => x
              .GreaterThanOrEquals("Created", cutoff))
            .Count()
            .then(x => x);
        }))
    ))
      .subscribe(res2 => this.notificationCount = +res2));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.router.navigate([{ outlets: { tab: [changeEvent.nextId] } }], { relativeTo: this.route });
  }
}
