import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {map} from 'rxjs/operators';

import {PropertyCore} from '../../core/odata/odata.coreapi';
import {ContractListInputdata} from '../../core/components/contract/contract-list-input-data';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { from } from 'rxjs';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {

  inputData: ContractListInputdata = new ContractListInputdata();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.inputData.contracts$ = from(this.odataCoreService.MaintenanceContract.Query()
      .Expand(x => {
        x.Expand("ServiceProvider")
      })
      .OrderBy("Title", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))));

    this.inputData.contractUT$ = this.route.parent.paramMap.pipe(map((params: ParamMap) => params.get('id')));
    this.inputData.scrollStyle = {'height': 'calc(100vh - 245px)'};
  }


  handleWizardRequest(): void {
    this.router.navigate(['contract', 'wizard']);
  }

  handleDetailPageRequest(contract: PropertyCore.MaintenanceContract) {
    this.router.navigate(['/contract', contract.Identity, {outlets: {left: ['list'], right: ['contract']}}]);
  }


}
