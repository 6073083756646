import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {Subscription, from} from 'rxjs';

import {PropertyCore} from '../../core/odata/odata.coreapi';
import {ExtendedMaintenanceContractMapping} from '../../core/model/extendedMaintenanceContractMapping';
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { map } from 'rxjs/operators';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../core/odata/odataclient';


@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent implements OnInit, OnDestroy {

  contractId: string;
  buildingInstallEquipments: any[];

  searchFilter: string = '';
  equipments: PropertyCore.EquipmentType[];
  equipmentFilter: any = '';

  subscriptions: Subscription = new Subscription();

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.parent.paramMap.subscribe((params: ParamMap) => {
      this.contractId = params.get('id');
      this.subscriptions.add(from(this.odataCoreService.MaintenanceJob.Query()
        .Filter(xb => xb.Equals(ODataPath.For("Contract", "Identity"), Guid.parse(this.contractId)))
        .Expand(xb => {
          xb.Expand("Contract"),
            xb.Expand("Equipment", y => {
              y.Expand("Building"),
              y.Expand("EquipmentType", z => {
                z.Expand("CostGroup")
              })
              y.Expand("Room", v => {
                v.Expand("Floor")
              })
            })
          xb.Expand("Schedule")
        })
        .Exec().then(x => x.value))
        .pipe(map(res => res.map(i => {
          Utils.mapAllJSONDatesToDates(i);
          return Object.assign(new ExtendedMaintenanceContractMapping(), i);
        })))
      .subscribe(res => {
        this.buildingInstallEquipments = res.reduce((prev, next) => {
          const b = prev.find(x => x.buildingUT === next.Equipment.Building.Identity);
          if (!b) {
            prev.push({
              buildingUT: next.Equipment.Building.Identity,
              buildingName: next.Equipment.Building.Name,
              dataList: [next]
            });
          } else {
            b.dataList.push(next);
          }
          return prev;
        }, []);
      }));
    }));

    this.subscriptions.add(from(this.odataCoreService.EquipmentType.Query().Exec().then(x => x.value)).subscribe(res => this.equipments = res));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  } //installEquipment.Equipment.Identity

  navToIE(installEquipment: ExtendedMaintenanceContractMapping) {
    this.subscriptions.add(from(this.odataCoreService.Equipment.Query()
      .Expand(x => {
        x.Expand("Building"),
          x.Expand("EquipmentType"),
          x.Expand("Room", y =>
            y.Expand("Floor"))
      })
      .Filter(x =>
        x.EqualsField("Identity", Guid.parse(installEquipment.Equipment.Identity)))
      .Exec().then(x => x.value))
      .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      .subscribe(res => {
      this.router.navigate(['/building', installEquipment.Equipment.Building.Identity, {
        outlets: {
          left: ['building', 'view', {outlets: {tab: ['equipment']}}],
          right: ['equipment', installEquipment.Equipment.Identity, 'view', {outlets: {tab: ['details']}}]
        }
      }]);
    }, error => {
      this.toasterService.pop('warning', '', this.translateService.instant('ContractEquipmentList._navToIE_error'));
    }));
  }
}
