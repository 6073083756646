import {Injectable} from '@angular/core';

import {PropertyCore} from '../../../core/odata/odata.coreapi';
import {ExtendedMaintenanceContractMapping} from '../../../core/model/extendedMaintenanceContractMapping';
import {ContractWizardModel} from './contract-wizard-model';

@Injectable()
export class ContractWizardService {
  private model: ContractWizardModel = new ContractWizardModel;

  constructor() {
  }

  getCurrentContract(): ContractWizardModel {
    return this.model;
  }

  startWizard(contract: PropertyCore.MaintenanceContract = null, installEquipments: ExtendedMaintenanceContractMapping[] = null): void {
    this.model.reset();

    if (contract) {
      this.model.contract = contract;
    }

    if (installEquipments) {
      this.model.installEquipments = installEquipments;
    }
  }

  resetCurrentContract(): void {
    this.model.reset();
  }
}
