import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { PropertyCore } from '../../core/odata/odata.coreapi';
import { BuildingListInputData } from './../../core/components/building/building-list-input-data';
import { from } from 'rxjs';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { Utils } from '../../core/tools/utils';

@Component({
  selector: 'app-building-list',
  templateUrl: './building-list.component.html',
  styleUrls: ['./building-list.component.scss']
})
export class BuildingListComponent implements OnInit {

  inputData: BuildingListInputData = new BuildingListInputData();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.inputData.translatedListTitle = 'BuildingList._h1_buildingOverview';
    this.inputData.currentBuildingUT$ = this.route.parent.paramMap.pipe(map( (params: ParamMap) => params.get('id')));
    this.inputData.buildings$ = from(this.odataCoreService.Building.Query()
      .OrderBy("Name", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        return Utils.mapAllJSONDatesToDates(i);
      })))
    this.inputData.scrollStyle = { 'max-height': 'calc(100vh - 500px)' };
  }

  handleDetailPageRequest(building: PropertyCore.Building) {
    this.router.navigate(['/building', building.Identity, { outlets: { left: ['list'], right: ['building']}}]);
  }

  handleBuildingWizardRequest() {
    this.router.navigate(['building', 'wizard']);
  }

  handleMapMarkerRequest(buildingUT: string) {
    this.router.navigate(['/building', buildingUT, { outlets: { left: ['list'], right: ['building'] } }]);
  }

}
