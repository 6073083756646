import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { InspectiondutyWizardComponent } from "../inspectionduty-wizard.component";
import { InspectiondutyWizardModel } from "../inspectionduty-wizard-model";
import { Equipment } from "app/core/model/equipmentOperatorTask";
import { InspectiondutyWizardNavigationPaths } from "../inspectionduty-wizard-navigation-paths";
import { ConfirmationModal } from "app/core/popup/confirmation.modal";
import { InspectiondutyWizardService } from "../inspectionduty-wizard-service";
import {
  NgbModal,
  NgbModalModule,
  NgbPanelChangeEvent,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ODataCoreService } from "app/core/odata-services/odata.coreapi.service";
import {
  Common,
  PropertyCore,
  Operations,
  ObjektBrief,
} from "app/core/odata/odata.coreapi";
import { EditInspectionDutyGroup } from "app/core/components/equipment/edit-inspection-duties/editInspectionDutyGroup";
import { from, Subscription } from "rxjs";
import { Guid } from "guid-typescript";
import { EditOperatorTask } from "app/core/components/equipment/edit-inspection-duties/editOperatorTask";
import { EquipmentEditInspectionDutiesSaveOutputData } from "app/core/components/equipment/equipment-edit-inspection-duties-save-output-data";
import { Router } from "@angular/router";
import { IntegrityBoxComponent } from "app/dashboard/dashboard-boxes/integrity-box/integrity-box.component";
import { Utils } from "app/core/tools/utils";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-inspectionduty-wizard-data",
  templateUrl: "./inspectionduty-wizard-data.component.html",
  styleUrls: ["./inspectionduty-wizard-data.component.scss"],
})
export class InspectiondutyWizardDataComponent implements OnInit {
  isAttributePanelOpen: boolean;
  subscriptions: Subscription;
  model: InspectiondutyWizardModel;
  formSubmitted: boolean = false;
  showClose: boolean;
  showHeader: boolean;
  hasInspectionDutiesActivatedChanged: boolean;
  inspectionDutyGroups: EditInspectionDutyGroup[];
  //equipmentOperatorTaskMarkers: EditOperatorTask[];

  cycleUnits: String[];
  CycleUnits = Common.IntervalUnits;

  comment: string;

  originalIsDisabled: boolean;
  isChangedSeriesStart: boolean;
  isChangedActive: boolean;
  bulkInspectionDutyChange: PropertyCore.InspectionDutyChanges;
  //bulkInspectionDutyChange: PropertyCore.InspectionDutyChanges;
  storeInspectionDutyChange: any[] = [];
  equipmentPatchFlag = 0;

  //attributeType: PropertyCore.AttributeType[];

  @Output()
  pageChangeRequest = new EventEmitter<InspectiondutyWizardNavigationPaths>();

  @Output()
  saveRequest = new EventEmitter<EquipmentEditInspectionDutiesSaveOutputData>();
  constructor(
    private service: InspectiondutyWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService,
    private router: Router,
    protected authService: AuthService
  ) {}

  ngOnInit() {
    this.isAttributePanelOpen = false;

    this.model = this.service.getCurrentEquipmentModel();
  }
  onPanelChange($event: NgbPanelChangeEvent) {
    this.isAttributePanelOpen = $event.nextState;
  }
  changeInspectionDutyActive(
    equipmentOperatorTaskMarkers: EditOperatorTask
  ): void {
    equipmentOperatorTaskMarkers.originalIsDisabled =
      equipmentOperatorTaskMarkers.originalIsDisabled === undefined ||
      equipmentOperatorTaskMarkers.originalIsDisabled === null
        ? equipmentOperatorTaskMarkers.isMissing
        : equipmentOperatorTaskMarkers.originalIsDisabled;

    equipmentOperatorTaskMarkers.isMissing = !equipmentOperatorTaskMarkers.isMissing;

    equipmentOperatorTaskMarkers.isChangedActive =
      equipmentOperatorTaskMarkers.originalIsDisabled !==
      equipmentOperatorTaskMarkers.isMissing;

    this.storeInspectionDuties();
  }

  changeSeriesStart(equipmentOperatorTaskMarkers: EditOperatorTask): void {
    equipmentOperatorTaskMarkers.isChangedSeriesStart = true;
  }
  close(): void {
    this.router.navigate(
      [
        "/dashboard",
        {
          outlets: {
            left: ["board"],
            right: [
              IntegrityBoxComponent.PATH_PREFIX,
              IntegrityBoxComponent.PATH_EQUIPMENTS_NO_INSPECTION_DUTIES,
            ],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  save(validForm: boolean): void {
    let validInspections = true;
    this.model.equipmentOperatorTasks.forEach((eot) => {
      if (
        eot.originalIsDisabled &&
        (!eot.cycle ||
          !eot.cycleUnit ||
          eot.cycleUnit === this.CycleUnits.Undefined)
      ) {
        validInspections = false;
      }
    });
    if (!validForm || !validInspections) {
      this.formSubmitted = true;
      return;
    }

    // check if checkbox is active, show popup
    if (this.model.equipment.HasInspectionDutiesActivated) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant(
        "EquipmentDetailEditInspection._modal_title2"
      );
      modalRef.componentInstance.message = this.translateService.instant(
        "EquipmentDetailEditInspection._modal_message2"
      );
      modalRef.componentInstance.yesButton = this.translateService.instant(
        "EquipmentDetailEditInspection._modal_yes2"
      );
      modalRef.componentInstance.cancelButton = this.translateService.instant(
        "EquipmentDetailEditInspection._modal_cancel2"
      );

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.saveInstallEquipment();
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.saveInstallEquipment();
    }
  }

  private saveInstallEquipment(): void {
    // update hasInspectionDutiesActivated flag
    if (this.hasInspectionDutiesActivatedChanged) {
      if (this.model.equipment.Room == null) {
        this.odataCoreService.Equipment.Patch()
          .ValueType(this.odataCoreService.ODataTypes().Equipment())
          .Key(Guid.parse(this.model.equipment.Identity))
          .ValueProperty("TuevNo", this.model.equipment.TuevNo)
          .ValueProperty("Comment", this.model.equipment.Comment)
          .ValueProperty(
            "IsRenterResponsibility",
            this.model.equipment.IsRenterResponsibility
          )
          .ValueProperty(
            "IsNoMaintenanceDesired",
            this.model.equipment.IsNoMaintenanceDesired
          )
          .ValueProperty(
            "HasInspectionDutiesActivated",
            this.model.equipment.HasInspectionDutiesActivated
          )
          .ValueProperty("CustomId", this.model.equipment.CustomId)
          .ValuePropertyBinding(
            "EquipmentType",
            this.odataCoreService.EquipmentType.Get()
              .Key(this.model.equipment.EquipmentType.Code)
              .Bind()
          )
          .ValuePropertyBinding(
            "Building",
            this.odataCoreService.Building.Get()
              .Key(Guid.parse(this.model.equipment.Building.Identity))
              .Bind()
          )
          .Exec()
          .then((res) => {
            this.equipmentPatchFlag = 1;
            this.saveInspectionDuties();
          });
      } else {
        this.odataCoreService.Equipment.Patch()
          .ValueType(this.odataCoreService.ODataTypes().Equipment())
          .Key(Guid.parse(this.model.equipment.Identity))
          .ValueProperty("TuevNo", this.model.equipment.TuevNo)
          .ValueProperty("Comment", this.model.equipment.Comment)
          .ValueProperty(
            "IsRenterResponsibility",
            this.model.equipment.IsRenterResponsibility
          )
          .ValueProperty(
            "IsNoMaintenanceDesired",
            this.model.equipment.IsNoMaintenanceDesired
          )
          .ValueProperty(
            "HasInspectionDutiesActivated",
            this.model.equipment.HasInspectionDutiesActivated
          )
          .ValueProperty("CustomId", this.model.equipment.CustomId)
          .ValuePropertyBinding(
            "Room",
            this.odataCoreService.Room.Get()
              .Key(Guid.parse(this.model.equipment.Room.Identity))
              .Bind()
          )
          .ValuePropertyBinding(
            "EquipmentType",
            this.odataCoreService.EquipmentType.Get()
              .Key(this.model.equipment.EquipmentType.Code)
              .Bind()
          )
          .ValuePropertyBinding(
            "Building",
            this.odataCoreService.Building.Get()
              .Key(Guid.parse(this.model.equipment.Building.Identity))
              .Bind()
          )
          .Exec()
          .then((res) => {
            this.equipmentPatchFlag = 1;
            this.saveInspectionDuties();
          });
      }
    } else {
      this.saveInspectionDuties();
    }
  }
  private saveInspectionDuties(): void {
    let inspectionDutyChangeFlag = 0;
    this.model.equipmentOperatorTasks.forEach((y) => {
      if (y.isChangedActive || y.cycleStart) {
        inspectionDutyChangeFlag++;
      }
    });

    this.model.equipmentOperatorTasks.forEach(async (x) => {
      if (x.isChangedActive || x.cycleStart) {
        // Operation: 0 - Undefined, 1 - Create, 2 - Update, 3 - Delete
        let operation;
        if (x.isChangedActive) {
          if (x.isMissing) {
            operation = Operations.Operations.Delete;
          } else {
            operation = Operations.Operations.Create; // activation and possible deadline change only here
          }
        } else if (x.isChangedSeriesStart) {
          operation = Operations.Operations.Update; // only when series start changed
        }

        let inspectionDutyChange: any;
        const guid = Utils.guid();
        switch (operation) {
          case Operations.Operations.Create:
            inspectionDutyChange = {
              Operation: operation,
              Identity: guid,
              Interval: {
                Step: x.cycle,
                Size: this.mapCycleUnitToEnum(x.cycleUnit),
              },
              EquipmentRef: this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.model.equipment.Identity))
                .Bind()
                .toString(),
              OperatorTaskRef: this.odataCoreService.OperatorTask.Get()
                .Key(Guid.parse(x.operatortaskIdentity))
                .Bind()
                .toString(),
            };

            break;

          case Operations.Operations.Delete:
            inspectionDutyChange = {
              Operation: operation,
              Identity: x.aInspectionDutyIdentity,
              EquipmentRef: this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.model.equipment.Identity))
                .Bind().toString,
              OperatorTaskRef: this.odataCoreService.OperatorTask.Get()
                .Key(Guid.parse(x.operatortaskIdentity))
                .Bind().toString,
            };
            break;

          case Operations.Operations.Update:
            inspectionDutyChange = {
              Identity: x.aInspectionDutyIdentity,
              Operation: operation,
              SeriesStart: x.cycleStart,
              Interval: {
                Step: x.aCycle,
                Size: this.mapCycleUnitToEnum(x.aCycleUnit),
              },
            };
            break;
        }

        this.storeInspectionDutyChange.push(inspectionDutyChange);

        if (
          this.storeInspectionDutyChange.length == inspectionDutyChangeFlag &&
          inspectionDutyChangeFlag != 1
        ) {
          this.bulkInspectionDutyChange = {
            Comment: this.comment,
            Changes: this.storeInspectionDutyChange,
          };

          await this.odataCoreService.InspectionDuty.Actions()
            .BulkChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet()
            .Parameters(this.bulkInspectionDutyChange)
            .Execute()
            .then((res) => {
              this.storeInspectionDutyChange = [];
              if (this.equipmentPatchFlag == 0) {
                this.saveRequest.emit();
                this.ngOnInit();
              }
            });
        } else if (inspectionDutyChangeFlag == 1) {
          await this.odataCoreService.InspectionDuty.Actions()
            .SingleChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet()
            .Parameters(this.comment, inspectionDutyChange)
            .Execute()
            .then((res) => {
              this.storeInspectionDutyChange = [];
              if (this.equipmentPatchFlag == 0) {
                this.saveRequest.emit();
                this.ngOnInit();
              }
            });
        }
      }
    });

    if (this.equipmentPatchFlag == 1) {
      this.saveRequest.emit();
    }
  }
  openInfoDialog() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant(
      "EquipmentDetailEditInspection._modal_title"
    );
    modalRef.componentInstance.message = this.translateService.instant(
      "EquipmentDetailEditInspection._modal_message"
    );
    modalRef.componentInstance.yesButton = this.translateService.instant(
      "EquipmentDetailEditInspection._modal_yes"
    );
    modalRef.componentInstance.hideCancelButton = true;
  }

  private mapCycleUnitToEnum(unit: string): Common.IntervalUnits {
    let cycleUnitEnum;
    switch (unit) {
      case Common.IntervalUnits.Undefined:
        cycleUnitEnum = Common.IntervalUnits.Undefined;
        break;
      case Common.IntervalUnits.Year:
        cycleUnitEnum = Common.IntervalUnits.Year;
        break;
      case Common.IntervalUnits.Month:
        cycleUnitEnum = Common.IntervalUnits.Month;
        break;
      case Common.IntervalUnits.Week:
        cycleUnitEnum = Common.IntervalUnits.Week;
        break;
      case Common.IntervalUnits.Day:
        cycleUnitEnum = Common.IntervalUnits.Day;
        break;
    }
    return cycleUnitEnum;
  }

  hasInspectionDutiesActivatedChange() {
    this.hasInspectionDutiesActivatedChanged = true;
  }

  next(validForm): void {
    let validInspections = true;
    this.model.equipmentOperatorTasks.forEach((eot) => {
      if (eot.isChangedActive && !eot.isMissing) {
        //Changed and currently it is active

        if (eot.originalIsDisabled) {
          if (
            !eot.cycle ||
            !eot.cycleUnit ||
            eot.cycleUnit === this.CycleUnits.Undefined
          ) {
            validInspections = false;
          }
        } else {
          if (
            !eot.aCycle ||
            !eot.aCycleUnit ||
            eot.aCycleUnit === this.CycleUnits.Undefined
          ) {
            validInspections = false;
          }
        }
      }
    });
    if (!validForm || !validInspections) {
      this.formSubmitted = true;
      return;
    }

    //return;
    this.formSubmitted = true;

    this.model.comment = this.comment;
    this.storeInspectionDuties();
    this.pageChangeRequest.emit(
      InspectiondutyWizardNavigationPaths.OTHEREQUIPMENTS
    );
  }

  storeInspectionDuties() {
    this.model.storeInspectionDutyChange = [];
    this.model.changedIDs = [];
    let inspectionDutyChangeFlag = 0;
    this.model.equipmentOperatorTasks.forEach((y) => {
      if (y.isChangedActive || y.cycleStart) {
        inspectionDutyChangeFlag++;
      }
    });

    this.model.equipmentOperatorTasks.forEach(async (x) => {
      if (x.isChangedActive || x.cycleStart) {
        // Operation: 0 - Undefined, 1 - Create, 2 - Update, 3 - Delete
        let operation;
        if (x.isChangedActive) {
          if (x.isMissing) {
            operation = Operations.Operations.Delete;
          } else {
            operation = Operations.Operations.Create; // activation and possible deadline change only here
          }
        } else if (x.isChangedSeriesStart) {
          operation = Operations.Operations.Update; // only when series start changed
        }

        let inspectionDutyChange: any;
        switch (operation) {
          case Operations.Operations.Create:
            inspectionDutyChange = {
              Operation: operation,
              SeriesStart: x.cycleStart,
              Interval: {
                Step: x.cycle,
                Size: this.mapCycleUnitToEnum(x.cycleUnit),
              },
              EquipmentRef: this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.model.equipment.Identity))
                .Bind()
                .toString(),
              OperatorTaskRef: this.odataCoreService.OperatorTask.Get()
                .Key(Guid.parse(x.operatortaskIdentity))
                .Bind()
                .toString(),
            };
            break;

          case Operations.Operations.Delete:
            inspectionDutyChange = {
              Operation: operation,
              Identity: x.aInspectionDutyIdentity,
              EquipmentRef: this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.model.equipment.Identity))
                .Bind()
                .toString(),
              OperatorTaskRef: this.odataCoreService.OperatorTask.Get()
                .Key(Guid.parse(x.operatortaskIdentity))
                .Bind()
                .toString(),
            };
            break;

          case Operations.Operations.Update:
            inspectionDutyChange = {
              Identity: x.aInspectionDutyIdentity,
              Operation: operation,
              SeriesStart: x.cycleStart,
              Interval: {
                Step: x.aCycle,
                Size: this.mapCycleUnitToEnum(x.aCycleUnit),
              },
            };
            break;
        }
        if (this.model.changedIDs.indexOf(x.operatortaskId) === -1) {
          this.model.changedIDs.push(x.operatortaskId);
        }
        this.model.storeInspectionDutyChange.push(inspectionDutyChange);
      }
    });
  }
}
