import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {PropertyCore} from '../../../core/odata/odata.coreapi';
import {DefectWizardNavigationPaths} from './defect-wizard-navigation-paths.enum';
import {DefectWizardService} from './defect-wizard.service';
import { Incident } from '../../../core/model/incident';
import { Utils } from '../../../core/tools/utils';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';

@Component({
  selector: 'app-defect-wizard',
  templateUrl: './defect-wizard.component.html',
  styleUrls: ['./defect-wizard.component.scss']
})
export class DefectWizardComponent implements OnInit, OnDestroy {

  navigateBack: string;

  currentPage: DefectWizardNavigationPaths;

  modelReady: boolean;

  PAGE_DATA = DefectWizardNavigationPaths.DATA;
  PAGE_ADD_SERVICE_CONTACT = DefectWizardNavigationPaths.ADD_SERVICE_CONTACT;
  PAGE_EQUIPMENT = DefectWizardNavigationPaths.EQUIPMENT;

  subscriptions: Subscription = new Subscription();

  constructor(
    private service: DefectWizardService,
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.navigateBack = this.route.snapshot.data.navigateBack;
    this.currentPage = this.PAGE_DATA;
    this.service.startWizard();
    this.subscriptions.add(this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      return from(this.odataCoreService.Event.Query()
        .Expand(x => {
          x.Expand("Response"),
            x.Expand("Schedule", xa => {
              xa.Expand("InspectionDuty", xaa => {
                xaa.Select("Id"),
                  xaa.Expand("OperatorTask", xaaa => {
                    xaaa.Select("Guidelines"),
                      xaaa.Select("Qualification")
                  })
              }),
                xa.Expand("MaintenanceContract", xab => {
                  xab.Select("StartDate"),
                    xab.Expand("ServiceProvider", xabb => {
                      xabb.Select("Name")
                    })
                })
            }),
            x.Expand("Building", xb => {
              xb.Select("Name"),
                xb.Select("Id"),
                xb.Select("Identity")
            }),
            x.Expand("Equipment", xc => {
              xc.Select("Name"),
                xc.Select("Id"),
                xc.Select("Identity"),
                xc.Select("CustomId"),
                xc.Expand("Room", xcc => {
                  xcc.Select("Name"),
                    xcc.Select("Id"),
                    xcc.Select("Identity")
                  xcc.Expand("Floor", xccc => {
                    xccc.Select("Id"),
                      xccc.Select("Name")
                  })
                })
            }),
            x.Expand("ServiceProvider", xd => {
              xd.Select("Name"),
                xd.Select("Id"),
                xd.Select("Identity")
            }),
            x.Expand("Module", xe => {
              xe.Select("Name"),
                xe.Select("Id"),
                xe.Select("Identity"),
                xe.Expand("ModuleType", xee => {
                  xee.Select("Code"),
                    xee.Select("Id"),
                    xee.Select("Name")
                })
            })
          x.Expand("KpiMarker")
        })
        .Filter(x => x
          .EqualsField("Id", (+params.get('id'))))
        .Exec().then(x => x.value))
        .pipe(map(res => {
          Utils.mapAllJSONDatesToDates(res[0]);
          return Object.assign(new Incident(), res[0])
        }))
    }))
      .subscribe(res => {
        this.service.fillWizard(res);
        this.modelReady = true;
      }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  processPageChangeRequest(pageChangeEvent: DefectWizardNavigationPaths): void {
    switch (pageChangeEvent) {

      case DefectWizardNavigationPaths.START: {
        this.currentPage = this.PAGE_DATA;
        break;
      }
      case DefectWizardNavigationPaths.EXIT: {
        const buildingUT = this.service.getParentIncident().Building.Identity;
        const parentIncidentId = this.service.getParentIncident().Id;
        const newIncidentId = 0; //this.service.getCurrentDefect().id; // we just want to navigate to parent incident
        if (this.navigateBack && this.navigateBack === 'building' && buildingUT && (newIncidentId || parentIncidentId)) {
          this.router.navigate(['/building', buildingUT, {
            outlets: {
              left: ['building', 'view', {outlets: {tab: ['defect']}}],
              right: ['incident', (newIncidentId || parentIncidentId)]
            }
          }]);
        } else if (this.navigateBack && this.navigateBack === 'dashboard-conformity-defect') {
          this.router.navigate(['/dashboard', {
            outlets: {
              left: ['board'],
              right: ['conformity', 'list', {tab: 'defect'}]
            }
          }]);
        } else {
          console.error('Could not find a navigation route for defect wizard!');
        }
        break;
      }
      case DefectWizardNavigationPaths.NEXT: {
        if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_EQUIPMENT;
        }
        break;
      }
      case DefectWizardNavigationPaths.PREVIOUS: {
        if (this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case DefectWizardNavigationPaths.DATA: {
        if (this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_ADD_SERVICE_CONTACT || this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case DefectWizardNavigationPaths.ADD_SERVICE_CONTACT: {
        if (this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_ADD_SERVICE_CONTACT || this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_ADD_SERVICE_CONTACT;
        }
        break;
      }
      case DefectWizardNavigationPaths.EQUIPMENT: {
        if (this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_EQUIPMENT;
        }
        break;
      }
    }
  }

  saveNewServiceContact(serviceContact: PropertyCore.ServiceProvider) {
    this.service.getCurrentDefect().contact = serviceContact;
    this.processPageChangeRequest(DefectWizardNavigationPaths.DATA);
  }
}
