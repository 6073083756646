import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

import {ConfirmationModal} from './../../../../core/popup/confirmation.modal';
import {MandatorWizardModel} from './../mandator-wizard-model';
import {MandatorWizardNavigationPaths} from './../mandator-wizard-navigation-paths.enum';
import {MandatorWizardService} from './../mandator-wizard.service';

@Component({
  selector: 'app-mandator-wizard-progress',
  templateUrl: './mandator-wizard-progress.component.html',
  styleUrls: ['./mandator-wizard-progress.component.scss']
})
export class MandatorWizardProgressComponent implements OnInit {

  PAGE_DATA = MandatorWizardNavigationPaths.DATA;
  PAGE_AGREEMENT = MandatorWizardNavigationPaths.AGREEMENT;
  PAGE_CONFIRM = MandatorWizardNavigationPaths.CONFIRM;
  PAGE_DONE = MandatorWizardNavigationPaths.DONE;

  model: MandatorWizardModel;

  @Output()
  pageChangeRequest = new EventEmitter<MandatorWizardNavigationPaths>();

  @Input() currentPage: MandatorWizardNavigationPaths;

  constructor(
    private service: MandatorWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentMandator();
  }

  openInfoDialog() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('CreateMandator._infoModal_title');
    modalRef.componentInstance.message = this.translateService.instant('ManageMandators._p_hint');
    modalRef.componentInstance.yesButton = this.translateService.instant('CreateMandator._infoModal_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }

  navigate(pageChangeEvent: MandatorWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }

}
