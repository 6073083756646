import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ODataCoreService } from "app/core/odata-services/odata.coreapi.service";
import { PropertyCore } from "app/core/odata/odata.coreapi";
import { BuildingService } from "app/core/shared/services/building.service";
import { Guid } from "guid-typescript";

@Component({
  selector: "app-export-calculation-inspection-duties",
  templateUrl: "./export-calculation-inspection-duties.component.html",
  styleUrls: ["./export-calculation-inspection-duties.component.scss"],
})
export class ExportCalculationInspectionDutiesComponent implements OnInit {
  private _buildings: PropertyCore.Building[];
  @Input() public set buildings(value: PropertyCore.Building[]) {
    if (value != this._buildings) {
      this._buildings = value;
      this.loadInspectionDuties(this._buildings);
    }
  }
  @Output() operatorTasksSelected: EventEmitter<
    PropertyCore.OperatorTask[]
  > = new EventEmitter();

  public get buildings(): PropertyCore.Building[] {
    return this._buildings;
  }

  public operatorTasks: PropertyCore.OperatorTask[] = [];

  constructor(private buildingService: BuildingService) {}

  ngOnInit() {}

  public onOperatorTasksSelected(
    selectedOperatorTasks: PropertyCore.OperatorTask[]
  ): void {
    this.operatorTasksSelected.emit(selectedOperatorTasks);
  }

  private loadInspectionDuties(buildings: PropertyCore.Building[]): void {
    if (!buildings || !buildings.length) return;

    this.buildingService
      .getBuildingUniqueInspectionDuties(buildings.map((b) => b.Identity))
      .subscribe(
        (operatorTasks) =>
          (this.operatorTasks = operatorTasks.sort((a, b) => {
            if (a.Guidelines < b.Guidelines) {
              return -1;
            }
            if (a.Guidelines > b.Guidelines) {
              return 1;
            }
            return 0;
          }))
      );
  }
}
