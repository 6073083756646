import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./../core/auth/auth-guard.service";
import { CoreModule } from "./../core/core.module";
import { GlobalService } from "./../core/shared/global.service";
import { BuildingDetailEditFloorsComponent } from "./building-detail/building-detail-edit-floors/building-detail-edit-floors.component";
import { BuildingDetailEditUsagesComponent } from "./building-detail/building-detail-edit-usages/building-detail-edit-usages.component";
import { BuildingDetailEditComponent } from "./building-detail/building-detail-edit/building-detail-edit.component";
import { BuildingDetailComponent } from "./building-detail/building-detail.component";
import { BuildingUsageComponent } from "./building-detail/building-usage.component";
import { BuildingDocumentOverviewComponent } from "./building-document-overview/building-document-overview.component";
import { BuildingIncidentDetailComponent } from "./building-incident-detail/building-incident-detail.component";
import { BuildingIncidentListComponent } from "./building-incident-list/building-incident-list.component";
import { IncidentState } from "./building-incident/incident-state.service";
import { BuildingListComponent } from "./building-list/building-list.component";
import { BuildingOverviewComponent } from "./building-overview/building-overview.component";
import { BuildingRoomDetailComponent } from "./building-room-detail/building-room-detail.component";
import { BuildingRoomListComponent } from "./building-room-list/building-room-list.component";
import { BuildingComponent } from "./building/building.component";
import { DefectListComponent } from "./defect-list/defect-list.component";
import { DocumentBuildingEquipmentComponent } from "./document-building-equipment/document-building-equipment.component";
import { DocumentIncidentComponent } from "./document-incident/document-incident.component";
import { EquipmentDetailEditInspectionDutiesComponent } from "./equipment-detail/equipment-detail-edit-inspection-duties/equipment-detail-edit-inspection-duties.component";
import { EquipmentDetailEditComponent } from "./equipment-detail/equipment-detail-edit/equipment-detail-edit.component";
import { EquipmentDetailComponent } from "./equipment-detail/equipment-detail.component";
import { EquipmentFeatureEditComponent } from "./equipment-feature/equipment-feature-edit/equipment-feature-edit.component";
import { EquipmentFeatureComponent } from "./equipment-feature/equipment-feature.component";
import { EquipmentHistoryComponent } from "./equipment-history/equipment-history.component";
import { EquipmentIncidentDetailComponent } from "./equipment-incident-detail/equipment-incident-detail.component";
import { EquipmentIncidentListComponent } from "./equipment-incident-list/equipment-incident-list.component";
import { EquipmentListComponent } from "./equipment-list/equipment-list.component";
import { EquipmentModuleComponent } from "./equipment-module/equipment-module.component";
import { EquipmentOverviewComponent } from "./equipment-overview/equipment-overview.component";
import { IncidentCalendarComponent } from "./incident-calendar/incident-calendar.component";
import { IncidentOverviewComponent } from "./incident-overview/incident-overview.component";
import { InstallEquipmentService } from "./installEquipment.service";
import { ModuleDetailEditComponent } from "./module-detail/module-detail-edit/module-detail-edit.component";
import { ModuleDetailComponent } from "./module-detail/module-detail.component";
import { ModuleFeatureEditComponent } from "./module-feature/module-feature-edit/module-feature-edit.component";
import { ModuleFeatureComponent } from "./module-feature/module-feature.component";
import { ModuleOverviewComponent } from "./module-overview/module-overview.component";
import { RouterThroughputComponent } from "./router-throughput/router.throughput.component";
import { StartComponent } from "./start/start.component";
import { BuildingWizardDataComponent } from "./wizard/building/building-wizard-data/building-wizard-data.component";
import { BuildingWizardEquipmentComponent } from "./wizard/building/building-wizard-equipment/building-wizard-equipment.component";
import { BuildingWizardLocationComponent } from "./wizard/building/building-wizard-location/building-wizard-location.component";
import { BuildingWizardUsageComponent } from "./wizard/building/building-wizard-usage/building-wizard-usage.component";
import { BuildingWizardProgressComponent } from "./wizard/building/building-wizard-progress/building-wizard-progress.component";
import { BuildingWizardComponent } from "./wizard/building/building-wizard.component";
import { BuildingWizardService } from "./wizard/building/building-wizard.service";
import { DefectWizardDataComponent } from "./wizard/defect/defect-wizard-data/defect-wizard-data.component";
import { DefectWizardDocumentComponent } from "./wizard/defect/defect-wizard-document/defect-wizard-document.component";
import { DefectWizardEquipmentComponent } from "./wizard/defect/defect-wizard-equipment/defect-wizard-equipment.component";
import { DefectWizardProgressComponent } from "./wizard/defect/defect-wizard-progress/defect-wizard-progress.component";
import { DefectWizardComponent } from "./wizard/defect/defect-wizard.component";
import { DefectWizardService } from "./wizard/defect/defect-wizard.service";
import { DocumentWizardDataComponent } from "./wizard/document/document-wizard-data/document-wizard-data.component";
import { DocumentWizardEquipmentComponent } from "./wizard/document/document-wizard-equipment/document-wizard-equipment.component";
import { DocumentWizardIncidentDataComponent } from "./wizard/document/document-wizard-incident-data/document-wizard-incident-data.component";
import { DocumentWizardIncidentComponent } from "./wizard/document/document-wizard-incident/document-wizard-incident.component";
import { DocumentWizardProgressComponent } from "./wizard/document/document-wizard-progress/document-wizard-progress.component";
import { DocumentWizardTypeComponent } from "./wizard/document/document-wizard-type/document-wizard-type.component";
import { DocumentWizardComponent } from "./wizard/document/document-wizard.component";
import { DocumentWizardService } from "./wizard/document/document-wizard.service";
import { EquipmentWizardDataComponent } from "./wizard/equipment/equipment-wizard-data/equipment-wizard-data.component";
import { EquipmentWizardProgressComponent } from "./wizard/equipment/equipment-wizard-progress/equipment-wizard-progress.component";
import { EquipmentWizardTechnicalComponent } from "./wizard/equipment/equipment-wizard-technical/equipment-wizard-technical.component";
import { EquipmentWizardTypeComponent } from "./wizard/equipment/equipment-wizard-type/equipment-wizard-type.component";
import { EquipmentWizardComponent } from "./wizard/equipment/equipment-wizard.component";
import { EquipmentWizardService } from "./wizard/equipment/equipment-wizard.service";
import { IncidentWizardContactComponent } from "./wizard/incident/incident-wizard-contact/incident-wizard-contact.component";
import { IncidentWizardDataComponent } from "./wizard/incident/incident-wizard-data/incident-wizard-data.component";
import { IncidentWizardEquipmentComponent } from "./wizard/incident/incident-wizard-equipment/incident-wizard-equipment.component";
import { IncidentWizardProgressComponent } from "./wizard/incident/incident-wizard-progress/incident-wizard-progress.component";
import { IncidentWizardComponent } from "./wizard/incident/incident-wizard.component";
import { IncidentWizardService } from "./wizard/incident/incident-wizard.service";
import { ModuleWizardDataComponent } from "./wizard/module/module-wizard-data/module-wizard-data.component";
import { ModuleWizardProgressComponent } from "./wizard/module/module-wizard-progress/module-wizard-progress.component";
import { ModuleWizardTechnicalComponent } from "./wizard/module/module-wizard-technical/module-wizard-technical.component";
import { ModuleWizardTypeComponent } from "./wizard/module/module-wizard-type/module-wizard-type.component";
import { ModuleWizardComponent } from "./wizard/module/module-wizard.component";
import { ModuleWizardService } from "./wizard/module/module-wizard.service";
import { ImageUploadService } from "../core/components/upload/image-upload-service";
import { LoginService } from "app/core/shared/login.service";
import { InspectiondutyWizardComponent } from "app/building/wizard/inspectionduty/inspectionduty-wizard.component";
import { InspectiondutyWizardProgressComponent } from "./wizard/inspectionduty/inspectionduty-wizard-progress/inspectionduty-wizard-progress.component";
import { InspectiondutyWizardDataComponent } from "./wizard/inspectionduty/inspectionduty-wizard-data/inspectionduty-wizard-data.component";
import { InspectiondutyWizardService } from "./wizard/inspectionduty/inspectionduty-wizard-service";
import { InspectiondutyWizardOtherEquipmentsComponent } from "./wizard/inspectionduty/inspectionduty-wizard-other-equipments/inspectionduty-wizard-other-equipments.component";

const routes: Routes = [
  // browsing navigation routes
  {
    path: "building",
    component: StartComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        component: BuildingListComponent,
      },
    ],
  },
  // *************************
  // wizard navigation routes
  // *************************
  {
    path: "building",
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "wizard",
        children: [
          {
            path: ":id",
            component: BuildingWizardComponent,
            data: { editMode: true },
          },
          {
            path: "",
            pathMatch: "full",
            component: BuildingWizardComponent,
          },
          {
            path: ":id/equipment",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: EquipmentWizardComponent,
                data: { navigateBack: "building" },
              },
              {
                path: ":id",
                component: EquipmentWizardComponent,
                data: { navigateBack: "equipment" },
              },
              {
                path: ":id/inspectionduty",
                component: InspectiondutyWizardComponent,
                data: { navigateBack: "equipment" },
              },
            ],
          },
          {
            path: ":id/module",
            children: [
              {
                path: ":id",
                component: ModuleWizardComponent,
                data: { navigateBack: "equipment" },
              },
            ],
          },
          {
            path: ":id/incident",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: IncidentWizardComponent,
                data: { navigateBack: "building" },
              },
              {
                path: ":id",
                component: IncidentWizardComponent,
                data: { navigateBack: "building" },
              },
            ],
          },
          {
            path: ":id/defect",
            children: [
              {
                path: ":id",
                component: DefectWizardComponent,
                data: { navigateBack: "building" },
              },
              {
                path: ":id/dashboard",
                component: DefectWizardComponent,
                data: { navigateBack: "dashboard-conformity-defect" },
              },
            ],
          },
          {
            path: ":id/document",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: DocumentWizardComponent,
              },
              {
                path: "dashboard",
                component: DocumentWizardComponent,
                data: { navigateBack: "dashboard" },
              },
              {
                path: "building",
                component: DocumentWizardComponent,
                data: { navigateBack: "building" },
              },
            ],
          },
          {
            path: ":id/inspectionduty",
            children: [
              {
                path: "",
                pathMatch: "full",
                component: InspectiondutyWizardComponent,
              },
              {
                path: "dashboard",
                component: InspectiondutyWizardComponent,
                data: { navigateBack: "dashboard" },
              },
              {
                path: "building",
                component: InspectiondutyWizardComponent,
                data: { navigateBack: "building" },
              },
              {
                path: "equipment",
                component: InspectiondutyWizardComponent,
                data: { navigateBack: "equipment" },
              },
            ],
          },
          {
            path: "equipment",
            children: [
              {
                path: ":id/inspectionduty",
                children: [
                  {
                    path: "",
                    pathMatch: "full",
                    component: InspectiondutyWizardComponent,
                  },
                  {
                    path: "dashboard",
                    component: InspectiondutyWizardComponent,
                    data: { navigateBack: "dashboard" },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  // *************************
  // detail navigation routes
  // *************************
  {
    path: "building/:id",
    component: BuildingComponent,
    canActivateChild: [AuthGuard],
    children: [
      // 1 level page setup
      {
        outlet: "left",
        path: "list",
        component: BuildingListComponent,
        data: {
          animation: "1-building-list",
        },
      },
      // documents handling
      {
        outlet: "right",
        path: "document",
        component: BuildingDocumentOverviewComponent,
        data: {
          animation: "1-building",
        },
        children: [
          {
            path: "incident",
            component: DocumentIncidentComponent,
            outlet: "tab",
          },
          {
            path: "building-equipment",
            component: DocumentBuildingEquipmentComponent,
            outlet: "tab",
          },
        ],
      },
      //building handling
      {
        outlet: "right",
        path: "building",
        component: RouterThroughputComponent,
        data: {
          animation: "1-building",
        },
        children: [
          // edit views
          {
            path: "edit/floors",
            component: BuildingDetailEditFloorsComponent,
          },
          {
            path: "edit/usages/:regionId",
            component: BuildingDetailEditUsagesComponent,
          },
          {
            path: "edit",
            component: BuildingDetailEditComponent,
          },
          // tabbed equipment views
          { path: "", redirectTo: "view", pathMatch: "full" },
          //{ path: 'overdue', redirectTo: '/building/:id/(left:list//right:building/view/(tab:incident/(incidentstyle:list;tab=overdue)))', pathMatch: 'full'},
          // { path: '', redirectTo: '/home/(catoutlet:cats//dogoutlet:dogs)', pathMatch: 'full' },
          {
            path: "view",
            component: BuildingOverviewComponent,
            children: [
              {
                path: "details",
                component: BuildingDetailComponent,
                outlet: "tab",
              },
              {
                path: "equipment",
                component: EquipmentListComponent,
                outlet: "tab",
              },
              {
                path: "incident",
                component: IncidentOverviewComponent,
                outlet: "tab",
                children: [
                  {
                    outlet: "incidentstyle",
                    path: "calendar",
                    component: IncidentCalendarComponent,
                    data: {
                      location: "building",
                    },
                  },
                  {
                    outlet: "incidentstyle",
                    path: "list",
                    component: BuildingIncidentListComponent,
                  },
                ],
              },
              {
                path: "defect",
                component: DefectListComponent,
                outlet: "tab",
              },
            ],
          },
        ],
      },

      // 2 level page setup

      {
        outlet: "left",
        path: "building",
        component: RouterThroughputComponent,
        data: {
          animation: "2-building",
        },
        children: [
          // edit views
          {
            path: "edit/floors",
            component: BuildingDetailEditFloorsComponent,
          },
          {
            path: "edit/usages/:regionId",
            component: BuildingDetailEditUsagesComponent,
          },
          {
            path: "edit",
            component: BuildingDetailEditComponent,
          },
          // tabbed equipment views
          { path: "", redirectTo: "view", pathMatch: "full" },
          {
            path: "view",
            component: BuildingOverviewComponent,
            children: [
              {
                path: "details",
                component: BuildingDetailComponent,
                outlet: "tab",
              },
              {
                path: "equipment",
                component: EquipmentListComponent,
                outlet: "tab",
              },
              {
                path: "incident",
                component: IncidentOverviewComponent,
                outlet: "tab",
                children: [
                  {
                    outlet: "incidentstyle",
                    path: "calendar",
                    component: IncidentCalendarComponent,
                    data: {
                      location: "building",
                    },
                  },
                  {
                    outlet: "incidentstyle",
                    path: "list",
                    component: BuildingIncidentListComponent,
                  },
                ],
              },
              {
                path: "defect",
                component: DefectListComponent,
                outlet: "tab",
              },
            ],
          },
        ],
      },

      {
        outlet: "left",
        path: "room-list",
        component: BuildingRoomListComponent,
      },
      {
        outlet: "right",
        path: "room/:id",
        component: BuildingRoomDetailComponent,
      },

      {
        outlet: "right",
        path: "equipment/:id",
        component: RouterThroughputComponent,
        data: {
          animation: "2-equipment",
          close: true,
        },
        children: [
          // full frame edit components
          {
            path: "edit/inspection-duties",
            component: EquipmentDetailEditInspectionDutiesComponent,
            data: {
              close: true,
            },
          },
          {
            path: "edit",
            component: EquipmentDetailEditComponent,
            data: {
              close: true,
            },
          },
          {
            path: "edit/feature",
            component: EquipmentFeatureEditComponent,
            data: {
              close: true,
            },
          },

          // tabbed equipment views
          { path: "", redirectTo: "view", pathMatch: "full" },
          {
            path: "view",
            component: EquipmentOverviewComponent,
            data: {
              close: true,
            },
            children: [
              {
                path: "details",
                component: EquipmentDetailComponent,
                outlet: "tab",
              },
              {
                path: "modules",
                component: EquipmentModuleComponent,
                outlet: "tab",
              },
              {
                path: "features",
                component: EquipmentFeatureComponent,
                outlet: "tab",
              },
              {
                path: "incident",
                component: IncidentOverviewComponent,
                outlet: "tab",
                children: [
                  {
                    outlet: "incidentstyle",
                    path: "calendar",
                    component: IncidentCalendarComponent,
                    data: {
                      location: "equipment",
                    },
                  },
                  {
                    outlet: "incidentstyle",
                    path: "list",
                    component: EquipmentIncidentListComponent,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        outlet: "right",
        path: "incident/:id",
        component: BuildingIncidentDetailComponent,
        data: {
          animation: "2-incident",
          close: true,
        },
      },

      // 3 level page setup

      {
        outlet: "left",
        path: "equipment/:id",
        component: RouterThroughputComponent,
        data: {
          animation: "3-equipment",
        },
        children: [
          // full frame edit components
          {
            path: "edit/inspection-duties",
            component: EquipmentDetailEditInspectionDutiesComponent,
          },
          {
            path: "edit",
            component: EquipmentDetailEditComponent,
          },
          {
            path: "edit/feature",
            component: EquipmentFeatureEditComponent,
          },

          // tabbed equipment views
          { path: "", redirectTo: "view", pathMatch: "full" },
          {
            path: "view",
            component: EquipmentOverviewComponent,
            children: [
              {
                path: "details",
                component: EquipmentDetailComponent,
                outlet: "tab",
              },
              {
                path: "modules",
                component: EquipmentModuleComponent,
                outlet: "tab",
              },
              {
                path: "features",
                component: EquipmentFeatureComponent,
                outlet: "tab",
              },
              {
                path: "incident",
                component: IncidentOverviewComponent,
                outlet: "tab",
                children: [
                  {
                    outlet: "incidentstyle",
                    path: "calendar",
                    component: IncidentCalendarComponent,
                    data: {
                      location: "equipment",
                    },
                  },
                  {
                    outlet: "incidentstyle",
                    path: "list",
                    component: EquipmentIncidentListComponent,
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        outlet: "right",
        path: "equipment/incident/:id",
        component: EquipmentIncidentDetailComponent,
        data: {
          animation: "3-incident",
          close: true,
        },
      },

      {
        outlet: "right",
        path: "equipment/module/edit/:id",
        component: ModuleDetailEditComponent,
      },
      {
        outlet: "right",
        path: "equipment/module/:id",
        component: ModuleOverviewComponent,
        data: {
          animation: "3-module",
          close: true,
        },
        children: [
          {
            path: "details",
            component: ModuleDetailComponent,
            outlet: "tab",
          },
          {
            path: "features",
            component: ModuleFeatureComponent,
            outlet: "tab",
          },
        ],
      },
      {
        outlet: "right",
        path: "equipment/module/edit/feature/:id",
        component: ModuleFeatureEditComponent,
        data: {
          animation: "3-module",
          close: true,
        },
      },
    ],
  },
];

@NgModule({
  imports: [FormsModule, CoreModule, RouterModule.forChild(routes)],
  declarations: [
    BuildingListComponent,
    BuildingRoomListComponent,
    BuildingRoomDetailComponent,
    EquipmentListComponent,
    EquipmentDetailComponent,
    EquipmentModuleComponent,
    EquipmentFeatureComponent,
    EquipmentHistoryComponent,
    EquipmentIncidentListComponent,
    BuildingOverviewComponent,
    BuildingDetailComponent,
    BuildingIncidentListComponent,
    BuildingComponent,
    StartComponent,
    BuildingUsageComponent,
    EquipmentDetailEditInspectionDutiesComponent,
    EquipmentFeatureEditComponent,
    BuildingIncidentDetailComponent,
    EquipmentOverviewComponent,
    EquipmentWizardProgressComponent,
    EquipmentWizardDataComponent,
    EquipmentWizardTechnicalComponent,
    EquipmentWizardComponent,
    EquipmentWizardTypeComponent,
    BuildingWizardComponent,
    BuildingWizardDataComponent,
    BuildingWizardLocationComponent,
    BuildingWizardUsageComponent,
    BuildingWizardEquipmentComponent,
    BuildingWizardProgressComponent,
    ModuleDetailComponent,
    ModuleWizardComponent,
    ModuleWizardDataComponent,
    ModuleWizardTechnicalComponent,
    ModuleWizardProgressComponent,
    ModuleWizardTypeComponent,
    BuildingDetailEditComponent,
    BuildingDetailEditUsagesComponent,
    BuildingDetailEditFloorsComponent,
    IncidentOverviewComponent,
    IncidentCalendarComponent,
    IncidentWizardComponent,
    IncidentWizardProgressComponent,
    IncidentWizardDataComponent,
    IncidentWizardEquipmentComponent,
    IncidentWizardContactComponent,
    DefectListComponent,
    DefectWizardComponent,
    DefectWizardProgressComponent,
    DefectWizardDocumentComponent,
    DefectWizardDataComponent,
    DefectWizardEquipmentComponent,
    ModuleOverviewComponent,
    ModuleFeatureComponent,
    ModuleFeatureEditComponent,
    ModuleDetailEditComponent,
    EquipmentDetailEditComponent,
    BuildingDocumentOverviewComponent,
    DocumentIncidentComponent,
    DocumentBuildingEquipmentComponent,
    RouterThroughputComponent,
    DocumentWizardComponent,
    DocumentWizardProgressComponent,
    DocumentWizardDataComponent,
    DocumentWizardTypeComponent,
    DocumentWizardEquipmentComponent,
    DocumentWizardIncidentComponent,
    DocumentWizardIncidentDataComponent,
    EquipmentIncidentDetailComponent,
    InspectiondutyWizardComponent,
    InspectiondutyWizardProgressComponent,
    InspectiondutyWizardDataComponent,
    InspectiondutyWizardOtherEquipmentsComponent,
  ],
  exports: [InspectiondutyWizardComponent, RouterModule],
  providers: [
    GlobalService,
    LoginService,
    InstallEquipmentService,
    EquipmentWizardService,
    BuildingWizardService,
    ModuleWizardService,
    IncidentWizardService,
    DefectWizardService,
    DocumentWizardService,
    ImageUploadService,
    InspectiondutyWizardService,
    IncidentState,
  ],
})
export class BuildingModule {}
