import { Component, Input } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-office-preview-modal",
  templateUrl: "./office-preview.modal.html",
})
export class OfficePreviewModal {
  @Input()
  filename: string;

  _officeDownloadToken: string;
  officeSafeUrl: SafeUrl;

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) {}

  @Input()
  set officeDownloadToken(officeDownloadToken: string) {
    this._officeDownloadToken = officeDownloadToken;

    officeDownloadToken =
      "https://view.officeapps.live.com/op/view.aspx?src=" +
      officeDownloadToken;
    this.officeSafeUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(officeDownloadToken);
  }

  get officeDownloadToken() {
    return this._officeDownloadToken;
  }
}
