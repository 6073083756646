import { Injectable } from '@angular/core';

import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { ModuleWizardModel } from './module-wizard-model';

@Injectable()
export class ModuleWizardService {

  model: ModuleWizardModel = new ModuleWizardModel;

  equipment: PropertyCore.Equipment;

  constructor() { }

  getCurrentModule(): ModuleWizardModel {
    return this.model;
  }

  startWizard(equipment: PropertyCore.Equipment): void {
    this.equipment = equipment;
    this.resetCurrentModule();
  }

  createModule(): void {
    // service call
  }

  resetCurrentModule(): void {
    this.model.reset();
    this.model.equipment = this.equipment;
  }

}
