import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PropertyCore } from '../../core/odata/odata.coreapi';

@Component({
  selector: 'app-building-usage',
  templateUrl: './building-usage.component.html',
  styleUrls: ['./building-usage.component.scss']
})
export class BuildingUsageComponent implements OnInit {

  public isEdit: boolean;

  @Input()
  building: PropertyCore.Building;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  editBuildingUsage(): void {
    this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['list'], right: ['building', 'edit', 'usages', this.building.Address.RegionInfo.Id] } }]);
  }

}


