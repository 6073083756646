import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Notification } from './../../core/model/notification';
import { NewsService } from './../news.service';
import { LoginService } from 'app/core/shared/login.service';
import { ODataCoreService } from 'app/core/odata-services/odata.coreapi.service';
import { PropertyCore, ActionLogEntityGetOperation } from 'app/core/odata/odata.coreapi';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-news-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  searchFilter: string = '';

  notifications: PropertyCore.ActionLog[];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private newsService: NewsService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private odataCoreService: ODataCoreService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.loginService.readSettings('MarkAsRead')
    .subscribe(res => {}));

    this.odataCoreService.ActionLog.Query()
    .Expand(x => {
      x.Expand("Building")
      x.Expand("Equipment")
      x.Expand("Module")
      x.Expand("Event")
      x.Expand("Document")
      x.Expand("MaintenanceContract")
      x.Expand("ServiceProvider")
    })
    .OrderBy("Created", "desc")
    .Exec()
    .then(res => {
        this.notifications = res.value

        this.notifications.forEach(notification => {
          switch(notification.Operation){
            case PropertyCore.ActionLogOperations.Undefined:
              notification['Text'] = 'Operation NONE';
              break;
            case PropertyCore.ActionLogOperations.Create:
              notification['Text'] = 'Erstellt';

              switch(notification.Element){
                case PropertyCore.ActionLogElements.Undefined:
                  if(notification.ModuleId != null) {
                    notification['Text'] =  "Neue Baugruppe wurde angelegt: " + notification.Module?.Name;
                  } else if (notification.EquipmentId != null) {
                    notification['Text'] = "Neue Anlage wurde angelegt: " + notification.Equipment?.Name;
                  } else if (notification.BuildingId != null) {
                    notification['Text'] =  "Neues Gebäude wurde angelegt: " + notification.Building?.Name;
                  } else if (notification.ServiceProviderId != null) {
                    notification['Text'] =  "Neuer Dienstleister wurde angelegt: " + notification.ServiceProvider?.Name
                  } else if (notification.MaintenanceContractId != null) {
                    notification['Text'] = "Neuer Wartungsvertrag wurde angelegt: " + notification.MaintenanceContract?.Title;
                  }
                  break;

                case PropertyCore.ActionLogElements.Document:
                  notification['Text'] = "Erstellt Dokument";
                  if (notification.EquipmentId != null) {
                    if(notification.Document?.IsVirtual == true){
                      notification['Text'] = "Für Anlagendokumentation an " + notification.Equipment?.Name + " wurde Ablageort bestimmt: " + notification.Document?.Name;
                    } else {
                      notification['Text'] = "Neue Anlagendokumentation wurde an " + notification.Equipment?.Name + " hinterlegt: " + notification.Document?.Name;
                    }
                  } else if (notification.BuildingId != null){
                    if (notification.Document?.IsVirtual == true) {
                      notification['Text'] = "Für Gebäudedokumentation an " + notification.Building?.Name + " wurde Ablageort bestimmt: " + notification.Document?.Name;
                  }
                  else {
                    notification['Text'] = "Neue Gebäudedokumentation wurde an " + notification.Building?.Name + " hinterlegt: " + notification.Document?.Name;
                  }
                  }
                  break;

                case PropertyCore.ActionLogElements.Image:
                  notification['Text'] = "Erstellt Bild";
                  if(notification.ModuleId != null){
                    notification['Text'] = "Neues Bild wurde an " + notification.Module?.Name + " hinterlegt";
                  } else if (notification.EquipmentId != null){
                    notification['Text'] = "Neues Bild wurde an " + notification.Equipment?.Name + " hinterlegt";
                  } else if (notification.BuildingId != null){
                    notification['Text'] = "Neues Bild wurde an " + notification.Building?.Name + " hinterlegt";
                  }
                  break;

                case PropertyCore.ActionLogElements.Attribute:
                  notification['Text'] = "Erstellt Merkmal";
                  break;
                
                case PropertyCore.ActionLogElements.Link:
                  notification['Text'] = "Neue Verknüpfung der Anlage " + notification.Equipment?.Name + " mit dem Wartungsvertrag \"" + notification.MaintenanceContract?.Title + "\" erzeugt";
                  break;
      
                case PropertyCore.ActionLogElements.Event:
                  if(notification.ModuleId != null){
                    notification['Text'] = "Neue Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '') + " an " + notification.Equipment?.Name + " wurde erstellt";
                  } else if (notification.EquipmentId != null){
                    notification['Text'] = "Neue Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '')  + " an " + notification.Equipment?.Name + " wurde erstellt";
                  } else {
                    notification['Text'] = "Neue Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '')  + " an " + notification.Building?.Name + " wurde erstellt";
                  }
                  break;

                case PropertyCore.ActionLogElements.Application:
                    notification['Text'] = "Erstellt Anwendung";
                  break;

                case PropertyCore.ActionLogElements.InspectionDuty:
                    notification['Text'] = "Erstellt Inspektionspflicht";
                    if(notification.ModuleId != null){
                      notification['Text'] = "Neue Prüfpflicht an " + notification.Module?.Name + " aktiviert";;
                    } else if (notification.EquipmentId != null){
                      notification['Text'] = "Neue Prüfpflicht an " + notification.Equipment?.Name + " aktiviert";;
                    }
                  break;

                case PropertyCore.ActionLogElements.AndroidApp:
                  notification['Text'] =  "Erstellt Android-App";
                  break;
              }
              break;

            case PropertyCore.ActionLogOperations.Update:
              notification['Text'] = "Bearbeitet";
              switch(notification.Element) {
                case PropertyCore.ActionLogElements.Undefined:                
                    if (notification.ModuleId != null){
                        notification['Text'] = "Stammdaten einer Baugruppe wurden bearbeitet: " + notification.Module?.Name;
                    } else if (notification.EquipmentId != null){
                        notification['Text'] = "Stammdaten einer Anlage wurden bearbeitet: " + notification.Equipment?.Name;
                    } else if (notification.BuildingId != null){
                        notification['Text'] = "Stammdaten eines Gebäudes wurden bearbeitet: " + notification.Building?.Name;
                    } else if (notification.ServiceProviderId != null){
                        notification['Text'] = "Stammdaten eines Dienstleisters wurden bearbeitet: " + notification.ServiceProvider?.Name;
                    } else if (notification.MaintenanceContractId != null){
                        notification['Text'] = "Stammdaten eines Wartungsvertrags wurden bearbeitet: " + notification.MaintenanceContract?.Title;
                    }
                  break;

                case PropertyCore.ActionLogElements.Document:
                    notification['Text'] = "Bearbeitet Dokument";
                  break;

                case PropertyCore.ActionLogElements.Image:
                    notification['Text'] = "Bearbeitet Bild";
                  break;

                case PropertyCore.ActionLogElements.Attribute:
                    notification['Text'] = "Bearbeitet Merkmal";
                    if (notification.ModuleId != null){
                      notification['Text'] = "Merkmale an " + notification.Module?.Name + " wurden bearbeitet";
                    } else if (notification.EquipmentId != null){
                      notification['Text'] = "Merkmale an " + notification.Equipment?.Name + " wurden bearbeitet";
                    }
                  break;

                case PropertyCore.ActionLogElements.Link:
                    notification['Text'] = "Bearbeitet Verknüpfung";
                  break;

                case PropertyCore.ActionLogElements.Event:
                    if (notification.ModuleId != null){
                      notification['Text'] = "Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '')+ " an " + notification.Equipment?.Name + " wurde bearbeitet";
                    } else if (notification.EquipmentId != null){
                      notification['Text'] = "Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '')  + " an " + notification.Equipment?.Name + " wurde bearbeitet";
                    } else {
                      notification['Text'] = "Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '')   + " an " + notification.Building?.Name + " wurde bearbeitet";
                    }
                  break;

                case PropertyCore.ActionLogElements.Application:
                    notification['Text'] = "Bearbeitet Anwendung";
                  break;

                case PropertyCore.ActionLogElements.InspectionDuty:
                    notification['Text'] = "Bearbeitet Inspektionspflicht";
                    if (notification.ModuleId != null){
                      notification['Text'] = "Prüfpflicht an " + notification.Module?.Name + " wurde bearbeitet";
                    } else if (notification.EquipmentId != null){
                      notification['Text'] = "Prüfpflicht an " + notification.Equipment?.Name + " wurde bearbeitet";
                    }
                  break;

                case PropertyCore.ActionLogElements.AndroidApp:
                    notification['Text'] = "Bearbeitet Android-App";
                  break;
              }
              break;

            case PropertyCore.ActionLogOperations.Delete:
              notification['Text'] = "Gelöscht";
              switch (notification.Element) {
                case PropertyCore.ActionLogElements.Undefined:
                  if (notification.ModuleId != null){
                    notification['Text'] = "Baugruppe wurde gelöscht: " + notification.Module?.Name;
                  } else if (notification.EquipmentId != null){
                    notification['Text'] = "Anlage wurde gelöscht: " + notification.Equipment?.Name;
                  } else if (notification.BuildingId != null){
                    notification['Text'] = "Gebäude wurde gelöscht: " + notification.Building?.Name;
                  }
                  break;

                case PropertyCore.ActionLogElements.Document:
                  notification['Text'] = "Gelöscht Dokument";
                  if (notification.ModuleId != null){
                    notification['Text'] = "Für Anlagendokumentation an " + notification.Equipment?.Name + " wurde Ablageort entfernt: " + notification.Document?.Name;
                  } else if (notification.EquipmentId != null){
                    if(notification.Document?.IsVirtual == true){
                      notification['Text'] =  "Für Anlagendokumentation an " + notification.Equipment?.Name + " wurde Ablageort entfernt: " + notification.Document?.Name;
                    } else {
                      notification['Text'] = "Anlagendokumentation wurde an " + notification.Equipment?.Name + " gelöscht: " + notification.Document?.Name;
                    }
                  } else if (notification.BuildingId != null){
                    if(notification.Document?.IsVirtual == true){
                      notification['Text'] = "Für Gebäudedokumentation an " + notification.Building?.Name + " wurde Ablageort entfernt: " + notification.Document?.Name;
                    } else {
                      notification['Text'] = "Gebäudedokumentation wurde an " + notification.Building?.Name +" gelöscht: " + notification.Document?.Name;
                    }
                  }
                  break;

                case PropertyCore.ActionLogElements.Image:
                  notification['Text'] = "Gelöscht Bild";
                  if (notification.ModuleId != null){
                    notification['Text'] = "Bild an " + notification.Module?.Name + " wurde gelöscht";
                  } else if (notification.EquipmentId != null){
                    notification['Text'] =  "Bild an " + notification.Equipment?.Name + " wurde gelöscht";
                  } else if (notification.BuildingId != null){
                    notification['Text'] =  "Bild an " + notification.Building?.Name + " wurde gelöscht";
                  }
                  break;
                
                case PropertyCore.ActionLogElements.Attribute:
                  notification['Text'] = "Gelöscht Merkmal";
                  break;

                case PropertyCore.ActionLogElements.Link:
                  notification['Text'] = "Verknüpfung der Anlage " + notification.Equipment?.Name + " mit dem Wartungsvertrag \"" + notification.MaintenanceContract?.Title + "\" wurde entfernt";
                  break;
                  
                case PropertyCore.ActionLogElements.Event:
                  if (notification.ModuleId != null) {
                    notification['Text'] = "Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '') + " an " + notification.Equipment?.Name + " wurde gelöscht";
                  }
                  else if (notification.EquipmentId != null) {
                    notification['Text'] = "Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '') + " an " + notification.Equipment?.Name + " wurde gelöscht";
                  }
                  else {
                    notification['Text'] = "Tätigkeit " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '') + " an " + notification.Building?.Name + " wurde gelöscht";
                  }
                  break;

                case PropertyCore.ActionLogElements.Application:
                    notification['Text']  = "Gelöscht Anwendung";
                    break;

                case PropertyCore.ActionLogElements.InspectionDuty:
                    notification['Text']  = "Gelöscht Inspektionspflicht";
                    if (notification.ModuleId != null) {
                      notification['Text'] = "Prüfpflicht an " + notification.Module?.Name + " deaktiviert";
                    }
                    else if (notification.EquipmentId != null) {
                      notification['Text'] = "Prüfpflicht an " + notification.Equipment?.Name + " deaktiviert";
                    }
                    break;

                case PropertyCore.ActionLogElements.AndroidApp:
                    notification['Text']  = "Gelöscht Android-App";
                    break;
              }
              break;
            
          case PropertyCore.ActionLogOperations.Feedback:
              notification['Text'] = "Feedback";
              if (notification.ModuleId != null) {
                notification['Text'] = "Neue Rückmeldung bei " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '')  + " an " + notification.Module?.Name  + " wurde durchgeführt";
              }
              else if (notification.EquipmentId != null) {
                notification['Text'] = "Neue Rückmeldung bei " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '')  + " an " + notification.Equipment?.Name + " wurde durchgeführt";
              }
              break;

          case PropertyCore.ActionLogOperations.Qualified:
              notification['Text'] = "Qualifiziert";
              if (notification.ModuleId != null) {
                notification['Text'] = "Neues Dokument \"" + notification.Document?.Name + "\" wurde bei der " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '') + " an " + notification.Module?.Name + " hinterlegt";
              }
              else if (notification.EquipmentId != null) {
                notification['Text'] = "Neues Dokument \"" + notification.Document?.Name + "\" wurde bei der " + (notification.Event?.Type != null ? this.translateService.instant('EventType.' + notification.Event?.Type) : '') + " an " + notification.Equipment?.Name + " hinterlegt";
              }
              break;

          case PropertyCore.ActionLogOperations.Download:
              notification['Text'] = "Autom. Download";
              break;

            default:
              notification['Text'] = '';
              break;
          }      
        })
      }
    )

    // this.subscriptions.add(this.newsService.registerForChange().pipe(switchMap(x => this.newsService.getNotifications()))
    //   .subscribe(res => {
    //     this.notifications = res;
    //   }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  navigate(notification: PropertyCore.ActionLog) {
    if (notification.Module) {
      if (notification['Text'].startsWith('Merkmale')) {
        // changed install module features
        this.router.navigate([
          '/building',
          notification.Building?.Identity,
          { outlets: { left: ['equipment', notification.Equipment?.Identity, 'view', { outlets: { tab: ['modules']}}], right: ['equipment', 'module', notification.Module?.Identity, { outlets: { tab: ['features']}}]}}]);
      } else {
        this.router.navigate([
          '/building',
          notification.Building?.Identity,
          { outlets: { left: ['equipment', notification.Equipment?.Identity, 'view', { outlets: { tab: ['modules']}}], right: ['equipment', 'module', notification.Module?.Identity, { outlets: { tab: ['details']}}]}}]);
      }
    } else if (notification.Equipment?.Identity) {
      if (notification?.Document) {
        // added document to install equipment
        this.router.navigate(['/building', notification.Building?.Identity, { outlets: { left: ['list'], right: ['document', { outlets: { tab: ['building-equipment'] } }]}}]);
      } else if (notification['Text'].startsWith('Merkmale')) {
        // changed install equipment features
        this.router.navigate([
          '/building',
          notification.Building?.Identity,
          { outlets: { left: ['building', 'view', { outlets: { tab: ['equipment'] } }], right: ['equipment', notification.Equipment?.Identity, 'view', { outlets: { tab: ['features'] } }] } }
        ]);
      } else if (notification.Module) {
        // deleted install module from install equipment
        this.router.navigate([
          '/building',
          notification.Building?.Identity,
          { outlets: { left: ['building', 'view', { outlets: { tab: ['equipment'] } }], right: ['equipment', notification.Equipment?.Identity, 'view', { outlets: { tab: ['modules'] } }] } }
        ]);
      } else if (notification.MaintenanceContract) {
        // install equipment add/remove maintenance contract
        this.router.navigate([
          '/contract',
          notification.MaintenanceContract?.Identity,
          { outlets: { left: ['list'], right: ['contract', { outlets: { tab: ['equipment'] } }] } }
        ]);
      } else if (notification.Event || notification['Text'].startsWith('Neue Tätigkeit')) {
        this.router.navigate([
          '/building',
          notification.Building?.Identity,
          { outlets: { left: ['building', 'view', { outlets: { tab: ['equipment'] } }], right: ['equipment', notification.Equipment?.Identity, 'view', { outlets: { tab: ['incident'] } }] } }
        ]);
      } else {
        this.router.navigate([
          '/building',
          notification.Building?.Identity,
          { outlets: { left: ['building', 'view', { outlets: { tab: ['equipment'] } }], right: ['equipment', notification.Equipment?.Identity, 'view', { outlets: { tab: ['details'] } }] } }
        ]);
      }
    } else if (notification.Building) {
      if (notification.Document?.Identity) {
        // added document to building
        this.router.navigate(['/building', notification.Building?.Identity, { outlets: { left: ['list'], right: ['document', { outlets: { tab: ['building-equipment'] } }]}}]);
      } else if (notification.Equipment?.Identity) {
        // deleted install equipment from building
        this.router.navigate(['/building', notification.Building?.Identity, { outlets: { left: ['list'], right: ['building', 'view', { outlets: { tab: ['equipment'] } }]}}]);
      } else if (notification.Event || notification['Text'].startsWith('Neue Tätigkeit')) {
        this.router.navigate(['/building', notification.Building?.Identity, { outlets: { left: ['list'], right: ['building', 'view', { outlets: { tab: ['incident'] } }]}}]);
      } else {
        this.router.navigate(['/building', notification.Building?.Identity, { outlets: { left: ['list'], right: ['building']}}]);
      }
    } else if (notification.MaintenanceContract) {
      this.router.navigate(['/contract', notification.MaintenanceContract?.Identity, { outlets: { left: ['list'], right: ['contract']}}]);
    } else if (notification.ServiceProvider) {
      this.router.navigate(['/service-contact', notification.ServiceProvider?.Identity, { outlets: { left: ['list'], right: ['service-contact']}}]);
    }
  }
}
