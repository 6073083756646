import { Injectable } from '@angular/core';

import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { DocumentWizardModel } from './document-wizard-model';

@Injectable()
export class DocumentWizardService {

  model: DocumentWizardModel = new DocumentWizardModel;

  building: PropertyCore.Building;

  constructor() { }

  getCurrentDocument(): DocumentWizardModel {
    return this.model;
  }

  startWizard(building: PropertyCore.Building): void {
    this.building = building;
    this.resetCurrentDocument();
  }

  resetCurrentDocument(): void {
    this.model.reset();
    this.model.building = this.building;
  }
}
