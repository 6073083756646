import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Utils } from './../tools/utils';

@Pipe({
  name: 'filterIncidentsBy'
})
@Injectable()
export class FilterIncidentsByPipe implements PipeTransform {

  /**
   * @items = object from array
   * @term = term's search
   */
  transform(items: any, terms: any, fields: any, translations: any): any {
    if (terms === undefined || terms === null || terms === '') {
      return items;
    }

    return items && items.filter(function (item) {
      if (!(terms instanceof Array)) {
        terms = [terms];
      }
      if (fields === undefined || fields === null || fields === '') {
        for (const property in item) {
          if (item[property] === null || item[property] === undefined) {
            continue;
          }
          let value: string = item[property].toString().toLowerCase();
          if (item[property] instanceof Date) {
            value = Utils.performPipeOperation('dateFormat', item[property]);
            value = value || '';
          }
          for (const term of terms) {
            if (term === undefined || term === null || term === '' || value.includes(term.toString().toLowerCase())) {
              return true;
            }
          }
        }
      } else {
        if (!(fields instanceof Array)) {
          fields = [fields];
        }

        for (const field of fields) {
          if (field) {
            let itemValue = item;
            const f = field.split('.');

            if (f.length === 1) {
              itemValue = itemValue[f[0]];
            } else if (f.length === 2) {
              if (!itemValue[f[0]]) {
                return false;
              }

              itemValue = itemValue[f[0]][f[1]];
            } else {
              if (!itemValue[f[0]]) {
                return false;
              }

              itemValue = itemValue[f[0]][f[1]][f[2]];
            }

            if (itemValue !== null && itemValue !== undefined) {
              let value: string = itemValue.toString().toLowerCase();

              if (itemValue instanceof Date) {
                value = Utils.performPipeOperation('dateFormat', itemValue);
                value = value || '';
              }
              
              for (const term of terms) {
                if (field === 'Type' || field === 'Schedule.CycleUnit') {
                  value = translations[itemValue].toLowerCase();
                }
                
                if (term === undefined || term === null || term === '' || value.includes(term.toString().toLowerCase())) {
                  return true;
                }
              }
            }
          }
        }
      }

      return false;
    });
  }
}
