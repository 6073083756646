import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Subscription, from } from 'rxjs';
import { Common, PropertyCore } from '../../../odata/odata.coreapi';
import { ConfirmationModal } from '../../../popup/confirmation.modal';
import { ILogger } from '../../../shared/logger.service';
import { ODataCoreService } from '../../../odata-services/odata.coreapi.service'
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-add-service-contact',
  templateUrl: './add-service-contact.component.html',
  styleUrls: ['./add-service-contact.component.scss']
})
export class AddServiceContactComponent implements OnInit, OnDestroy {

  model: PropertyCore.ServiceProvider;

  logger: ILogger;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  cancelRequest = new EventEmitter<any>();

  @Output()
  saveRequest = new EventEmitter<PropertyCore.ServiceProvider>();

  constructor(
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.model = <PropertyCore.ServiceProvider>{};
    this.model.Address = <Common.Address>{};
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.message = this.translateService.instant('ServiceContactWizzard._modal_message2');
      modalRef.componentInstance.title = this.translateService.instant('ServiceContactWizzard._modal_title');
      modalRef.componentInstance.yesButton = this.translateService.instant('ServiceContactWizzard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ServiceContactWizzard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.cancelRequest.emit();
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      this.cancelRequest.emit();
    }
  }

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    this.subscriptions.add(
      from(this.odataCoreService.ServiceProvider.Query()
        .Filter(xa =>
          xa.EqualsField("Name", this.model.Name))
        .Count().then(x => x > 0))
        .subscribe(async res => {

          if (res) {
            this.toasterService.pop('warning', '', this.translateService.instant('ServiceContactWizzard._longName_exists_error'));
          } else {
            if (this.model.Account != null && this.model.User == null) {

              await this.odataCoreService.ServiceProvider.Post()
                .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())
                .ValueProperty("Code", this.model.Name)
                .ValueProperty("Name", this.model.Name)
                .ValueProperty("IsMaintenance", this.model.IsMaintenance)
                .ValueProperty("IsInspection", this.model.IsInspection)
                .ValueProperty("Department", this.model.Department)
                .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
                .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
                .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
                .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
                .ValueProperty("ContactPerson", this.model.ContactPerson)
                .ValueProperty("ContactPhone1", this.model.ContactPhone1)
                .ValueProperty("ContactPhone2", this.model.ContactPhone2)
                .ValueProperty("Address", this.mapAddress(this.model.Address))
                .ValuePropertyBinding("Account", this.odataCoreService.Account.Get().Key(Guid.parse(this.model.Account.Identity)).Bind())
                .Exec()
                .then(res2 => {
                  this.model = res2;
                  this.saveRequest.emit(this.model);
                });


            } else if (this.model.User != null && this.model.Account == null) {


              await this.odataCoreService.ServiceProvider.Post()
                .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())    
                .ValueProperty("Code", this.model.Name)
                .ValueProperty("Name", this.model.Name)
                .ValueProperty("IsMaintenance", this.model.IsMaintenance)
                .ValueProperty("IsInspection", this.model.IsInspection)
                .ValueProperty("Department", this.model.Department)
                .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
                .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
                .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
                .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
                .ValueProperty("ContactPerson", this.model.ContactPerson)
                .ValueProperty("ContactPhone1", this.model.ContactPhone1)
                .ValueProperty("ContactPhone2", this.model.ContactPhone2)
                .ValueProperty("Address", this.mapAddress(this.model.Address))
                .ValuePropertyBinding("User", this.odataCoreService.User.Get().Key(Guid.parse(this.model.User.Identity)).Bind())
                .Exec()
                .then(res2 => {
                  this.model = res2;
                  this.saveRequest.emit(this.model);
                });

            } else if (this.model.User == null && this.model.Account == null) {
              await this.odataCoreService.ServiceProvider.Post()
                .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())
                .ValueProperty("Code", this.model.Name)
                .ValueProperty("Name", this.model.Name)
                .ValueProperty("IsMaintenance", this.model.IsMaintenance)
                .ValueProperty("IsInspection", this.model.IsInspection)
                .ValueProperty("Department", this.model.Department)
                .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
                .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
                .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
                .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
                .ValueProperty("ContactPerson", this.model.ContactPerson)
                .ValueProperty("ContactPhone1", this.model.ContactPhone1)
                .ValueProperty("ContactPhone2", this.model.ContactPhone2)
                .ValueProperty("Address", this.mapAddress(this.model.Address))
                .Exec()
                .then(res2 => {
                  this.model = res2;
                  this.saveRequest.emit(this.model);
                });
            } else {

              await this.odataCoreService.ServiceProvider.Post()
                .ValueType(this.odataCoreService.ODataTypes().ServiceProvider())
                .ValueProperty("Code", this.model.Name)
                .ValueProperty("Name", this.model.Name)
                .ValueProperty("IsMaintenance", this.model.IsMaintenance)
                .ValueProperty("IsInspection", this.model.IsInspection)
                .ValueProperty("Department", this.model.Department)
                .ValueProperty("ContractContactPhone", this.model.ContractContactPhone)
                .ValueProperty("ContractContactPerson", this.model.ContractContactPerson)
                .ValueProperty("ContractContactEmailAdd", this.model.ContractContactEmailAdd)
                .ValueProperty("ContactEmailAdd", this.model.ContactEmailAdd)
                .ValueProperty("ContactPerson", this.model.ContactPerson)
                .ValueProperty("ContactPhone1", this.model.ContactPhone1)
                .ValueProperty("ContactPhone2", this.model.ContactPhone2)
                .ValueProperty("Address", this.mapAddress(this.model.Address))
                .ValuePropertyBinding("Account", this.odataCoreService.Account.Get().Key(Guid.parse(this.model.Account.Identity)).Bind())
                .ValuePropertyBinding("User", this.odataCoreService.User.Get().Key(Guid.parse(this.model.User.Identity)).Bind())
                .Exec()
                .then(res2 => {
                  this.model = res2;
                  this.saveRequest.emit(this.model);
                });
            }
             
          }
        }));
  }

  private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};
    if (address.RegionInfo != null && address.RegionInfo.hasOwnProperty('Code')){
      entity.Region = address.RegionInfo['Code'];
    } 
    if (address.CountryInfo != null && address.CountryInfo.hasOwnProperty('Code')) {
      entity.Country = address.CountryInfo['Code'];
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }

}
