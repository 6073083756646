import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal, NgbPanelChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subscription, from } from "rxjs";

import {
  Common,
  PropertyCore,
  Operations,
  ObjektBrief,
} from "../../../odata/odata.coreapi";
import { AuthService } from "./../../../auth/auth.service";
import { ConfirmationModal } from "./../../../popup/confirmation.modal";
import { EquipmentEditInspectionDutiesInputData } from "./../equipment-edit-inspection-duties-input-data";
import { EquipmentEditInspectionDutiesSaveOutputData } from "./../equipment-edit-inspection-duties-save-output-data";
import { EditInspectionDutyGroup } from "./editInspectionDutyGroup";
import { ODataCoreService } from "../../../odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";
import { EditOperatorTask } from "./editOperatorTask";
import { map } from "rxjs/operators";
import { Utils } from "app/core/tools/utils";

@Component({
  selector: "app-core-edit-inspection-duties",
  templateUrl: "./core-edit-inspection-duties.component.html",
  styleUrls: ["./core-edit-inspection-duties.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CoreEditInspectionDutiesComponent implements OnInit, OnDestroy {
  @Input() inputData: EquipmentEditInspectionDutiesInputData;

  @Output() closeRequest = new EventEmitter<void>();

  @Output() saveRequest = new EventEmitter<
    EquipmentEditInspectionDutiesSaveOutputData
  >();

  equipment: PropertyCore.Equipment;
  hasInspectionDutiesActivatedChanged: boolean;

  showClose: boolean;
  showHeader: boolean;

  inspectionDutyGroups: EditInspectionDutyGroup[];
  equipmentOperatorTaskMarkers: EditOperatorTask[];

  cycleUnits: string[];
  CycleUnits = Common.IntervalUnits;

  comment: string;

  attributesCategories?: any[] = [];
  attributesCategoryNames?: any[] = [];
  categoryArray: any[] = [];

  isAttributePanelOpen: boolean;

  formSubmitted: boolean;
  originalIsDisabled: boolean;
  isChangedSeriesStart: boolean;
  isChangedActive: boolean;

  bulkInspectionDutyChange: PropertyCore.InspectionDutyChanges;
  storeInspectionDutyChange: any[] = [];
  equipmentPatchFlag = 0;

  attributeType: PropertyCore.AttributeType[];

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.equipmentPatchFlag = 0;
    this.cycleUnits = this.inputData.cycleUnits;

    this.subscriptions.add(
      combineLatest(
        this.inputData.equipmentOperatorTaskMarkers$,
        this.inputData.installEquipment$
      ).subscribe(([idg, ie]) => {
        this.equipmentOperatorTaskMarkers = idg;
        this.equipment = ie;

        // reset input data
        this.comment = null;
        this.formSubmitted = false;
        this.categoryArray.length = 0;
        this.attributesCategories.length = 0;
        this.attributesCategoryNames.length = 0;
        this.isAttributePanelOpen = false;

        // load ie attributes
        this.subscriptions.add(
          from(
            this.odataCoreService.Equipment.Get()
              .Key(Guid.parse(this.equipment.Identity))
              .Select("Identity", "Name", "AttributeValues")
              .Expand((x) => x.Expand("AttributeValues"))
              .Exec()
              .then((x) => x.value)
          )
            .pipe(map((res) => res[0]))
            .subscribe((res) => {
              this.odataCoreService.AttributeType.Query()
                .Expand((x) => {
                  x.Expand("Set");
                  x.Expand("MeasurementUnit");
                })
                .OrderBy("Order", "asc")
                .Exec()
                .then((res1) => {
                  let result1 = res1.value;
                  let a: number = 0;

                  while (result1.length > a) {
                    let attributeItem = res.AttributeValues.find(
                      (x) => x.Id == result1[a].Id
                    );
                    if (attributeItem) {
                      attributeItem["Description"] = result1[a].Description;
                      attributeItem["Name"] = result1[a].Name;
                      attributeItem["MeasurementUnit"] =
                        result1[a].MeasurementUnit;
                      attributeItem["IsOptional"] = result1[a].IsOptional;
                      attributeItem["AttributeCategory"] = result1[a].Set;
                      attributeItem["ValueType"] = result1[a].ValueType;

                      this.attributesCategories.push(attributeItem);
                      if (attributeItem.Value != null || "") {
                        this.attributesCategoryNames.push(
                          attributeItem["AttributeCategory"].Name
                        );
                      }
                    }
                    a++;
                  }

                  this.categoryArray = this.attributesCategoryNames.filter(
                    (element, index, input) => {
                      if (input.indexOf(element) === index) {
                        return element;
                      }
                    }
                  );
                });
            })
        );
      })
    );
    this.showClose = this.inputData.showClose;
    this.showHeader = this.inputData.showHeader;
    console.log(this.attributesCategories);
    console.log(this.attributesCategoryNames);
    console.log(this.categoryArray);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(): void {
    this.closeRequest.emit();
  }

  changeInspectionDutyActive(
    equipmentOperatorTaskMarkers: EditOperatorTask
  ): void {
    equipmentOperatorTaskMarkers.originalIsDisabled =
      equipmentOperatorTaskMarkers.originalIsDisabled === undefined ||
      equipmentOperatorTaskMarkers.originalIsDisabled === null
        ? equipmentOperatorTaskMarkers.isMissing
        : equipmentOperatorTaskMarkers.originalIsDisabled;

    equipmentOperatorTaskMarkers.isMissing = !equipmentOperatorTaskMarkers.isMissing;

    equipmentOperatorTaskMarkers.isChangedActive =
      equipmentOperatorTaskMarkers.originalIsDisabled !==
      equipmentOperatorTaskMarkers.isMissing;
  }

  changeSeriesStart(equipmentOperatorTaskMarkers: EditOperatorTask): void {
    equipmentOperatorTaskMarkers.isChangedSeriesStart = true;
  }

  save(validForm: boolean): void {
    let validInspections = true;
    this.equipmentOperatorTaskMarkers.forEach((eot) => {
      if (eot.isChangedActive && !eot.isMissing) {
        //Changed and currently it is active

        if (eot.originalIsDisabled) {
          if (
            !eot.cycle ||
            !eot.cycleUnit ||
            eot.cycleUnit === this.CycleUnits.Undefined
          ) {
            validInspections = false;
          }
        } else {
          if (
            !eot.aCycle ||
            !eot.aCycleUnit ||
            eot.aCycleUnit === this.CycleUnits.Undefined
          ) {
            validInspections = false;
          }
        }
      }
    });
    if (!validForm || !validInspections) {
      this.formSubmitted = true;
      return;
    }

    // check if checkbox is active, show popup
    if (this.equipment.HasInspectionDutiesActivated) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant(
        "EquipmentDetailEditInspection._modal_title2"
      );
      modalRef.componentInstance.message = this.translateService.instant(
        "EquipmentDetailEditInspection._modal_message2"
      );
      modalRef.componentInstance.yesButton = this.translateService.instant(
        "EquipmentDetailEditInspection._modal_yes2"
      );
      modalRef.componentInstance.cancelButton = this.translateService.instant(
        "EquipmentDetailEditInspection._modal_cancel2"
      );

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.saveInstallEquipment();
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.saveInstallEquipment();
    }
  }

  private saveInstallEquipment(): void {
    // update hasInspectionDutiesActivated flag
    if (this.hasInspectionDutiesActivatedChanged) {
      if (this.equipment.Room == null) {
        this.odataCoreService.Equipment.Patch()
          .ValueType(this.odataCoreService.ODataTypes().Equipment())
          .Key(Guid.parse(this.equipment.Identity))
          .ValueProperty("TuevNo", this.equipment.TuevNo)
          .ValueProperty("Comment", this.equipment.Comment)
          .ValueProperty(
            "IsRenterResponsibility",
            this.equipment.IsRenterResponsibility
          )
          .ValueProperty(
            "IsNoMaintenanceDesired",
            this.equipment.IsNoMaintenanceDesired
          )
          .ValueProperty(
            "HasInspectionDutiesActivated",
            this.equipment.HasInspectionDutiesActivated
          )
          .ValueProperty("CustomId", this.equipment.CustomId)
          .ValuePropertyBinding(
            "EquipmentType",
            this.odataCoreService.EquipmentType.Get()
              .Key(this.equipment.EquipmentType.Code)
              .Bind()
          )
          .ValuePropertyBinding(
            "Building",
            this.odataCoreService.Building.Get()
              .Key(Guid.parse(this.equipment.Building.Identity))
              .Bind()
          )
          .Exec()
          .then((res) => {
            this.equipmentPatchFlag = 1;
            this.saveInspectionDuties();
          });
      } else {
        this.odataCoreService.Equipment.Patch()
          .ValueType(this.odataCoreService.ODataTypes().Equipment())
          .Key(Guid.parse(this.equipment.Identity))
          .ValueProperty("TuevNo", this.equipment.TuevNo)
          .ValueProperty("Comment", this.equipment.Comment)
          .ValueProperty(
            "IsRenterResponsibility",
            this.equipment.IsRenterResponsibility
          )
          .ValueProperty(
            "IsNoMaintenanceDesired",
            this.equipment.IsNoMaintenanceDesired
          )
          .ValueProperty(
            "HasInspectionDutiesActivated",
            this.equipment.HasInspectionDutiesActivated
          )
          .ValueProperty("CustomId", this.equipment.CustomId)
          .ValuePropertyBinding(
            "Room",
            this.odataCoreService.Room.Get()
              .Key(Guid.parse(this.equipment.Room.Identity))
              .Bind()
          )
          .ValuePropertyBinding(
            "EquipmentType",
            this.odataCoreService.EquipmentType.Get()
              .Key(this.equipment.EquipmentType.Code)
              .Bind()
          )
          .ValuePropertyBinding(
            "Building",
            this.odataCoreService.Building.Get()
              .Key(Guid.parse(this.equipment.Building.Identity))
              .Bind()
          )
          .Exec()
          .then((res) => {
            this.equipmentPatchFlag = 1;
            this.saveInspectionDuties();
          });
      }
    } else {
      this.saveInspectionDuties();
    }
  }

  private saveInspectionDuties(): void {
    let inspectionDutyChangeFlag = 0;
    this.equipmentOperatorTaskMarkers.forEach((y) => {
      if (y.isChangedActive || y.cycleStart) {
        inspectionDutyChangeFlag++;
      }
    });

    this.equipmentOperatorTaskMarkers.forEach(async (x) => {
      if (x.isChangedActive || x.cycleStart) {
        // Operation: 0 - Undefined, 1 - Create, 2 - Update, 3 - Delete
        let operation;
        if (x.isChangedActive) {
          if (x.isMissing) {
            operation = Operations.Operations.Delete;
          } else {
            operation = Operations.Operations.Create; // activation and possible deadline change only here
          }
        } else if (x.isChangedSeriesStart) {
          operation = Operations.Operations.Update; // only when series start changed
        }

        let inspectionDutyChange: any;
        const guid = Utils.guid();
        switch (operation) {
          case Operations.Operations.Create:
            inspectionDutyChange = {
              Operation: operation,
              SeriesStart: x.cycleStart,
              Identity: guid,
              Interval: {
                Step: x.cycle,
                Size: this.mapCycleUnitToEnum(x.cycleUnit),
              },
              EquipmentRef: this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.equipment.Identity))
                .Bind()
                .toString(),
              OperatorTaskRef: this.odataCoreService.OperatorTask.Get()
                .Key(Guid.parse(x.operatortaskIdentity))
                .Bind()
                .toString(),
            };
            console.log(this.bulkInspectionDutyChange);
            break;

          case Operations.Operations.Delete:
            inspectionDutyChange = {
              Operation: operation,
              Identity: x.aInspectionDutyIdentity,
              EquipmentRef: this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.equipment.Identity))
                .Bind()
                .toString(),
              OperatorTaskRef: this.odataCoreService.OperatorTask.Get()
                .Key(Guid.parse(x.operatortaskIdentity))
                .Bind()
                .toString(),
            };
            break;

          case Operations.Operations.Update:
            inspectionDutyChange = {
              Identity: x.aInspectionDutyIdentity,
              Operation: operation,
              SeriesStart: x.cycleStart,
              Interval: {
                Step: x.aCycle,
                Size: this.mapCycleUnitToEnum(x.aCycleUnit),
              },
            };
            break;
        }

        this.storeInspectionDutyChange.push(inspectionDutyChange);

        if (
          this.storeInspectionDutyChange.length == inspectionDutyChangeFlag &&
          inspectionDutyChangeFlag != 1
        ) {
          this.bulkInspectionDutyChange = {
            Comment: this.comment,
            Changes: this.storeInspectionDutyChange,
          };

          await this.odataCoreService.InspectionDuty.Actions()
            .BulkChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet()
            .Parameters(this.bulkInspectionDutyChange)
            .Execute()
            .then((res) => {
              this.storeInspectionDutyChange = [];
              if (this.equipmentPatchFlag == 0) {
                this.saveRequest.emit();
                this.ngOnInit();
              }
            });
        } else if (inspectionDutyChangeFlag == 1) {
          await this.odataCoreService.InspectionDuty.Actions()
            .SingleChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet()
            .Parameters(this.comment, inspectionDutyChange)
            .Execute()
            .then((res) => {
              this.storeInspectionDutyChange = [];
              if (this.equipmentPatchFlag == 0) {
                this.saveRequest.emit();
                this.ngOnInit();
              }
            });
        }
      }
    });

    if (this.equipmentPatchFlag == 1) {
      this.saveRequest.emit();
    }
  }

  openInfoDialog() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant(
      "EquipmentDetailEditInspection._modal_title"
    );
    modalRef.componentInstance.message = this.translateService.instant(
      "EquipmentDetailEditInspection._modal_message"
    );
    modalRef.componentInstance.yesButton = this.translateService.instant(
      "EquipmentDetailEditInspection._modal_yes"
    );
    modalRef.componentInstance.hideCancelButton = true;
  }

  private mapCycleUnitToEnum(unit: string): Common.IntervalUnits {
    let cycleUnitEnum;
    switch (unit) {
      case Common.IntervalUnits.Undefined:
        cycleUnitEnum = Common.IntervalUnits.Undefined;
        break;
      case Common.IntervalUnits.Year:
        cycleUnitEnum = Common.IntervalUnits.Year;
        break;
      case Common.IntervalUnits.Month:
        cycleUnitEnum = Common.IntervalUnits.Month;
        break;
      case Common.IntervalUnits.Week:
        cycleUnitEnum = Common.IntervalUnits.Week;
        break;
      case Common.IntervalUnits.Day:
        cycleUnitEnum = Common.IntervalUnits.Day;
        break;
    }
    return cycleUnitEnum;
  }

  hasInspectionDutiesActivatedChange() {
    this.hasInspectionDutiesActivatedChanged = true;
  }

  onPanelChange($event: NgbPanelChangeEvent) {
    this.isAttributePanelOpen = $event.nextState;
  }
}
