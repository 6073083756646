import { Component, OnInit, Input,EventEmitter,Output } from '@angular/core';
import {InspectiondutyWizardNavigationPaths} from './../inspectionduty-wizard-navigation-paths';
import { InspectiondutyWizardService } from '../inspectionduty-wizard-service';
import { InspectiondutyWizardModel } from '../inspectionduty-wizard-model';
import { Common } from 'app/core/odata/odata.coreapi';

@Component({
  selector: 'app-inspectionduty-wizard-progress',
  templateUrl: './inspectionduty-wizard-progress.component.html',
  styleUrls: ['./inspectionduty-wizard-progress.component.scss']
})
export class InspectiondutyWizardProgressComponent implements OnInit {

  CycleUnits = Common.IntervalUnits;

  PAGE_DATA = InspectiondutyWizardNavigationPaths.DATA;
  PAGE_OTHEREQUIPMENTS = InspectiondutyWizardNavigationPaths.OTHEREQUIPMENTS;
  
  @Input()
  currentPage: InspectiondutyWizardNavigationPaths;

  @Output()
  pageChangeRequest = new EventEmitter<InspectiondutyWizardNavigationPaths>();

  constructor(private service: InspectiondutyWizardService) { }
  model: InspectiondutyWizardModel;



  ngOnInit() {
   this.model = this.service.getCurrentEquipmentModel();
  }
  navigate(pageChangeEvent: InspectiondutyWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }
}
