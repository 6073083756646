import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { PropertyCore } from '../../core/odata/odata.coreapi';
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-module-overview',
  templateUrl: './module-overview.component.html',
  styleUrls: ['./module-overview.component.scss']
})
export class ModuleOverviewComponent implements OnInit, OnDestroy {
  currentTab: string;
  equipmentUT: string;
  buildingUT: string;
  installModule: PropertyCore.Module;
  showClose: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.showClose = this.route.snapshot.data.close;

    this.subscriptions.add(
      this.route.paramMap
        .pipe(switchMap((params: ParamMap) => {
          return from(this.odataCoreService.Module.Query()
            .Expand(x => {
              x.Expand("ModuleType"),
                x.Expand("Equipment"),
                x.Expand("Room", y =>
                  y.Expand("Floor"))
            })
            .Filter(xa =>
              xa.EqualsField("Identity", Guid.parse(params.get('id'))))
            .Exec().then(x => x.value))
            .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])));
        }))
        .subscribe(installModule => this.installModule = installModule)
    );

    this.subscriptions.add(this.route.parent.firstChild.paramMap.subscribe(params => (this.equipmentUT = params.get('id'))));

    this.subscriptions.add(this.route.parent.paramMap.subscribe(params => (this.buildingUT = params.get('id'))));

    if (!this.route.firstChild) {
      // redirect to route with tabs outlet set to default value
      this.router.navigate([{ outlets: { tab: ['details'] } }], { relativeTo: this.route });
      this.currentTab = 'details';
    } else {
      // activate correct tab
      const path = this.route.firstChild.routeConfig.path;
      this.currentTab = path;
    }

    this.subscriptions.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd), map(() => this.route))
        .subscribe(event => {
          if (!this.route.firstChild) {
            // redirect to route with tabs outlet set to default value
            this.router.navigate([{ outlets: { tab: ['details'] } }], { relativeTo: this.route });
          } else {
            // activate correct tab
            const path = this.route.firstChild.routeConfig.path;
            this.currentTab = path;
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.router.navigate([{ outlets: { tab: [changeEvent.nextId] } }], { relativeTo: this.route });
  }

  private close(): void {
    this.router.navigate(['/building', this.buildingUT, {
      outlets: {
        left: ['building', 'view', { outlets: { tab: ['equipment'] } }],
        right: ['equipment', this.equipmentUT, 'view', { outlets: { tab: ['modules'] } }]
      }
    }]);
  }

}
