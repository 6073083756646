import {Observable} from 'rxjs';

import {Incident} from '../../model/incident';
import {IncidentType} from './incident-type';
import {IncidentView} from './incident-view';
import { PropertyCore } from '../../odata/odata.coreapi';

export class IncidentListInputData {
  incidents$: Observable<PropertyCore.Event[]>;
  allIncidents$: Observable<Incident[]>;
  currentView$: Observable<IncidentView>;
  currentType$: Observable<IncidentType>;
  allowedViews: IncidentView[];
  currentIncidentId$: Observable<number>;

  // little bit of a work around to set the correct scroll height for the list. currently 431 for buildilng and 277 for dashboard list usage
  variableScrollHeight: string = '277';

  // TODO extract to wrapper component (dashboard also displays building name)
  showBuildingData: boolean;
  showEquipmentData: boolean = true;

  navigateToFirstListItem: boolean = false;
}


