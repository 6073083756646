import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import {
  Common,
  Library,
  PropertyCore,
} from "../../../core/odata/odata.coreapi";
import { Utils } from "./../../../core/tools/utils";
import { BuildingWizardModel } from "./building-wizard-model";
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";

@Injectable()
export class BuildingWizardService {
  model: BuildingWizardModel;

  constructor(private odataCoreService: ODataCoreService) {}

  getCurrentBuilding(): BuildingWizardModel {
    return this.model;
  }

  startWizard(building: PropertyCore.Building = null): void {
    this.resetCurrentBuilding();

    if (building) {
      this.model.Identity = building.Identity;
      this.model.id = building.Id;
      this.model.name = building.Name;
      this.model.constructionYear = building.YearOfConstruction;
      this.model.squareMeters = building.Size;
      this.model.address = building.Address;
      this.model.latitude = building.Location.Latitude;
      this.model.longitude = building.Location.Longitude;
      this.model.usageTypes = building.UsageTypes;
    }
  }

  editBuilding(): Observable<any> {
    return from(
      this.odataCoreService.Building.Patch()
        .ValueType(this.odataCoreService.ODataTypes().Building())
        .Key(Guid.parse(this.model.Identity))
        .ValueProperty("Name", this.model.name)
        .ValueProperty("Address", this.mapAddress(this.model.address))
        .ValueProperty("Location", {
          Longitude: this.model.longitude,
          Latitude: this.model.latitude,
        })
        .ValueProperty("Size", this.model.squareMeters)
        .ValueProperty("YearOfConstruction", this.model.constructionYear)
        .Exec()
    );
  }

  resetCurrentBuilding(): void {
    this.model = new BuildingWizardModel();
    this.model.address = <Common.Address>{};
    this.model.address.CountryInfo = <Library.Country>{
      Id: 1,
      Name: "Deutschland",
    };
    this.model.location = <Common.Location>{};
  }

  private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};
    if (
      address.RegionInfo != null &&
      address.RegionInfo.hasOwnProperty("Code")
    ) {
      entity.Region = address.RegionInfo["Code"];
    }
    if (
      address.CountryInfo != null &&
      address.CountryInfo.hasOwnProperty("Code")
    ) {
      entity.Country = address.CountryInfo["Code"];
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }
}
