import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthGuard } from './../core/auth/auth-guard.service';
import { CoreModule } from './../core/core.module';
import { NewsComponent } from './news.component';
import { NewsService } from './news.service';
import { NotificationComponent } from './notification/notification.component';

const routes: Routes = [
  {
    path: 'news',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'view', pathMatch: 'full' },
      {
        path: 'view',
        component: NewsComponent,
        children: [
          {
            path: 'notifications',
            component: NotificationComponent,
            outlet: 'tab'
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule.forChild(routes),
    CoreModule
  ],
  declarations: [
    NewsComponent,
    NotificationComponent
  ],
  exports: [RouterModule],
  providers: [NewsService]
})
export class NewsModule {}
