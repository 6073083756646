import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Utils } from './../tools/utils';

@Pipe({
  name: 'dateCompare'
})
@Injectable()
export class DateComparePipe implements PipeTransform {

  /**
   * @items = object from array
   * @terms = term's search
   */
  transform(items: any, terms: any, fields: any, action: any): any {
    if (terms === undefined || terms === null) {
      return items;
    }

    return items && items.filter(function (item) {
      if (!action) {
        action = 'eq';
      }
      if (!(terms instanceof Array)) {
        terms = [terms];
      }
      if (fields === undefined || fields === null) {
        for (const property in item) {
          if (item[property] === null || item[property] === undefined) {
            continue;
          }
          const itemValue = (item[property] instanceof Date) ? item[property] : Utils.datepickerToDate(item[property]);
          if (!itemValue) {
            continue;
          }
          for (const term of terms) {
            const termValue = (term instanceof Date) ? term : Utils.datepickerToDate(term);
            if (!termValue) {
              continue;
            }
            switch (action) {
              case 'eq':
                return itemValue === termValue;
              case 'lt':
                return itemValue < termValue;
              case 'gt':
                return itemValue > termValue;
              case 'lte':
                return itemValue <= termValue;
              case 'gte':
                return itemValue >= termValue;
            }
          }
        }
      } else {
        if (!(fields instanceof Array)) {
          fields = [fields];
        }
        for (const field of fields) {
          let itemValue = item;
          field.split('.').forEach(val => {
            itemValue = itemValue[val];
          });

          if (itemValue !== null && itemValue !== undefined) {
            itemValue = (itemValue instanceof Date) ? itemValue : Utils.datepickerToDate(itemValue);
            if (!itemValue) {
              continue;
            }
            for (const term of terms) {
              const termValue = !(term instanceof Date) ? Utils.datepickerToDate(term) : term;
              if (!termValue) {
                continue;
              }
              switch (action) {
                case 'eq':
                  return itemValue === termValue;
                case 'lt':
                  return itemValue < termValue;
                case 'gt':
                  return itemValue > termValue;
                case 'lte':
                  return itemValue <= termValue;
                case 'gte':
                  return itemValue >= termValue;
              }
            }
          }
        }
      }

      return false;
    });
  }
}
