import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment',
  template: '<router-outlet></router-outlet>',
})
export class RouterThroughputComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
