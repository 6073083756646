import { Injectable } from "@angular/core";
import { ODataCoreService } from "app/core/odata-services/odata.coreapi.service";
import { PropertyCore } from "app/core/odata/odata.coreapi";
import { Utils } from "app/core/tools/utils";
import { Guid } from "guid-typescript";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BuildingService {
  constructor(private odataCoreService: ODataCoreService) {}

  public getBuildings(): Observable<PropertyCore.Building[]> {
    return from(
      this.odataCoreService.Building.Query()
        .OrderBy("Name", "asc")
        .Expand((b) => {
          b.Expand("Documents", (y) => {
            y.Expand("DocumentType");
          });
        })
        .Exec()
        .then((x) => x.value)
    ).pipe(
      map((res) =>
        res.map((i) => {
          return Utils.mapAllJSONDatesToDates(i);
        })
      )
    );
  }

  public getBuildingsWithInspectionDuties(
    buildingIds: string[]
  ): Observable<PropertyCore.Building[]> {
    if (!buildingIds || !buildingIds.length) return;

    return from(
      this.odataCoreService.Building.Query()
        .Select("Identity", "Name", "Address", "Id")
        .Expand((b) => {
          b.Expand("Equipments", (e) => {
            e.Select("Id");
            e.Select("Identity");
            e.Select("CustomId");
            e.Select("Name");
            e.Select("Room");
            e.Select("HasInspectionDutiesActivated");
            e.Select("AttributeValues");
            //e.Select("QuickResponseCodes");
            e.Select("EquipmentType");
            e.Select("Comment");
            e.Select("TuevNo");
            e.Expand("Room", (r) => {
              r.Select("Name");
              r.Select("Floor");
              r.Expand("Floor");
            });
            e.Expand("EquipmentType");
            e.Expand("AttributeValues");
            e.Expand("QuickResponseCodes", (qrCode) => {
              qrCode.Select("Value");
            });
            e.Expand("OperatorTaskMarkers", (z) => {
              z.Expand("OperatorTask");
            });
            e.Expand("InspectionDuties", (z) => {
              z.Select("IsDisabled");
              z.Expand("Module", (a) => {
                a.Select("Id");
                a.Select("CustomId");
                a.Select("Name");
              });
              z.Expand("OperatorTask", (a) => {
                a.Select("Id");
                a.Select("Identity");
                a.Select("Guidelines");
                a.Select("Subject");
                a.Select("Qualification");
              });
              z.Expand("Schedule", (a) => {
                a.Select("Cycle");
                a.Select("CycleUnit");
                a.Select("CycleStart");
              });
            });
          });
        })
        .Filter((x) => {
          var filterConnection = x.EqualsField(
            "Identity",
            Guid.parse(buildingIds[0])
          );
          buildingIds.splice(1).forEach((id) => {
            filterConnection.Or.EqualsField("Identity", Guid.parse(id));
          });
        })
        .Exec()
        .then((x) => {
          return x.value;
        })
    ).pipe(map((res) => res.map((b) => Utils.mapAllJSONDatesToDates(b))));
  }

  public getBuildingUniqueInspectionDuties(
    buildingIds: string[]
  ): Observable<PropertyCore.OperatorTask[]> {
    return this.getBuildingsWithInspectionDuties(buildingIds).pipe(
      map((buildings) =>
        this.getUniqueOperatorTasksWithInspectionDuties(buildings)
      )
    );
  }

  private getUniqueOperatorTasksWithInspectionDuties(
    buildings: PropertyCore.Building[]
  ): OperatorTaskExtendedInspectionDuties[] {
    var uniqueInspectionDuties: PropertyCore.InspectionDuty[] = [];

    var operatorTasksDict: {
      [key: string]: OperatorTaskWithInspectionDutyDictionaryItem;
    } = {};

    buildings.forEach((b) => {
      b.Equipments.forEach((e) => {
        e.InspectionDuties.forEach((i) => {
          if (!operatorTasksDict[i.OperatorTask.Identity]) {
            operatorTasksDict[i.OperatorTask.Identity] = <
              OperatorTaskWithInspectionDutyDictionaryItem
            >{
              operatorTask: i.OperatorTask,
              inspectionDuties: [],
            };
          }
          operatorTasksDict[i.OperatorTask.Identity].inspectionDuties.push({
            ...i,
            OperatorTask: null,
            Building: b,
            Equipment: e,
          });
        });
      });
    });

    var result: OperatorTaskExtendedInspectionDuties[] = [];
    for (var key of Object.keys(operatorTasksDict)) {
      let operatorTask = {
        ...operatorTasksDict[key].operatorTask,
        InspectionDuties: operatorTasksDict[key].inspectionDuties,
      };

      result.push(operatorTask);
    }

    return result;
  }
}

class OperatorTaskWithInspectionDutyDictionaryItem {
  operatorTask: OperatorTaskExtendedInspectionDuties;
  inspectionDuties: InspectionDutyExtendedWithBuildingAndEquipment[];
}

export interface OperatorTaskExtendedInspectionDuties
  extends PropertyCore.OperatorTask {
  InspectionDuties?: InspectionDutyExtendedWithBuildingAndEquipment[];
}

export interface InspectionDutyExtendedWithBuildingAndEquipment
  extends PropertyCore.InspectionDuty {
  Building: PropertyCore.Building;
  Equipment: PropertyCore.Equipment;
}
