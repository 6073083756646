export enum BuildingKpiFilter {
  Undefined = 0,
  NoEquipment = 1,
  NoUsageType = 2,
  MissingDocumentation = 3,
}

export enum InstallEquipmentKpiFilter {
  Undefined = 0,
  NoMaintenanceContract = 1,
  MaintenanceNotTerminated = 2,
  NoInspectionDutyTerminated = 3,
  NoInspectionDutySelected = 4,
  MissingDocumentation = 5,
  HasMaintenanceContract = 6,
  HasInspectionDutyTerminated = 7,
  HasInspectionDutyActive = 8,
  NoMaintenanceDesired = 9,
  RenterResponsibility = 10,
}
