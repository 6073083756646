import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {AuthService} from './../../core/auth/auth.service';
import {ExportNavigationPaths} from './export-navigation-paths.enum';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit, OnDestroy {

  currentPage: ExportNavigationPaths;

  ExportNavigationPaths = ExportNavigationPaths;

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
