import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { combineLatest, Subscription } from "rxjs";
import { AuthService } from "./../../../auth/auth.service";
import { FilterByPipe } from "./../../../pipes/filter.pipe";
import { IncidentDetailLinkOutputData } from "./../incident-detail-link-output-data";
import { IncidentListInputData } from "./../incident-list-input-data";
import { IncidentType } from "./../incident-type";
import { IncidentView } from "./../incident-view";
import { IncidentTabComponent } from "./incident-tab/incident-tab.component";
import { PropertyCore } from "app/core/odata/odata.coreapi";
import { Incident } from "app/core/model/incident";
import { NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-incident-list-view",
  templateUrl: "./incident-list-view.component.html",
  styleUrls: ["./incident-list-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class IncidentListViewComponent implements OnInit, OnDestroy {
  @ViewChild("tabTodo") tabTodo: IncidentTabComponent;
  @ViewChild("tabTodoFuture") tabTodoFuture: IncidentTabComponent;
  @ViewChild("tabOverdue") tabOverdue: IncidentTabComponent;
  @ViewChild("tabDone") tabDone: IncidentTabComponent;
  @ViewChild("tabDefect") tabDefect: IncidentTabComponent;
  @ViewChild("tabMissingDocument") tabMissingDocument: IncidentTabComponent;

  @Input() inputData: IncidentListInputData;

  @Output() viewTabChangeRequest = new EventEmitter<IncidentView>();
  @Output() typeTabChangeRequest = new EventEmitter<IncidentType>();
  @Output() detailPageRequest = new EventEmitter<IncidentDetailLinkOutputData>();

  searchFilter: string;
  serviceProviderSearchFilter: string = "";

  // prefilter incidents
  todoIncidents: PropertyCore.Event[] = [];
  todoFutureIncidents: PropertyCore.Event[] = [];
  overdueIncidents: PropertyCore.Event[] = [];
  doneIncidents: PropertyCore.Event[] = [];
  defectIncidents: PropertyCore.Event[] = [];
  missingDocumentIncidents: PropertyCore.Event[] = [];

  incidents: Incident[];
  currentViewTab: IncidentView;
  currentTypeTab: IncidentType;
  currentIncidentId: number = 0;

  subscriptions: Subscription = new Subscription();

  constructor(public authService: AuthService, private filterByPipe: FilterByPipe) {}

  ngOnInit() {
    this.subscriptions.add(
      combineLatest(
        this.inputData.allIncidents$,
        this.inputData.currentView$,
        this.inputData.currentIncidentId$,
        this.inputData.currentType$
      ).subscribe(([incidents, currentViewTab, currentIncidentId, currentTypeTab]) => {
        this.incidents = incidents;
        this.filterIncidents(this.incidents);
        this.currentViewTab = currentViewTab;
        this.currentIncidentId = currentIncidentId;
        this.currentTypeTab = currentTypeTab;

        if (this.inputData.navigateToFirstListItem) {
          let currentViewTabIncidents = this.incidents;
          if (this.currentViewTab && this.currentTypeTab) {
            this.inputData.navigateToFirstListItem = false;
            currentViewTabIncidents = this[this.currentViewTab + "Incidents"];
            // we have incidents in same tab
            if (currentViewTabIncidents && currentViewTabIncidents.length) {
              switch (this.currentTypeTab) {
                case "inspection":
                  currentViewTabIncidents = currentViewTabIncidents.filter(
                    (i) =>
                      i.Type === PropertyCore.EventTypes.Inspection ||
                      i.Type === PropertyCore.EventTypes.FollowUpInspection
                  );
                  break;
                case "maintenance":
                  currentViewTabIncidents = currentViewTabIncidents.filter(
                    (i) => i.Type === PropertyCore.EventTypes.Maintenance
                  );
                  break;
                case "repair":
                  currentViewTabIncidents = currentViewTabIncidents.filter(
                    (i) => i.Type === PropertyCore.EventTypes.Repair
                  );
                  break;
                case "individual":
                  currentViewTabIncidents = currentViewTabIncidents.filter(
                    (i) => i.Type === PropertyCore.EventTypes.Custom
                  );
                  break;
              }
              // we have incidents in same accordion
            }
          }
          if (currentViewTabIncidents && currentViewTabIncidents.length) {
            this.currentIncidentId = currentViewTabIncidents[0].Id;
            this.linkToIncidentDetail(this.currentIncidentId, IncidentType.getIncidentType(this.currentTypeTab));
          }
        }

        // scroll to current incident
        if (this.currentIncidentId) {
          const incident = this.incidents.find((x) => x.Id === this.currentIncidentId);
          if (incident) {
            switch (this.currentViewTab) {
              case IncidentView.ALL:
                // Do nothing - filter is in html with directive
                // this.virtualScrollAll.items = this.filterByPipe.transform(this.incidents, this.searchFilter, ['TypeShort', 'CycleShort', 'GuidelinesShort', 'DueDate', 'Building.Name', 'Equipment.Name', 'Equipment.CustomId']);
                // this.virtualScrollAll.scrollInto(incident);
                break;
              case IncidentView.TODO:
                //this.tabTodo.scrollTo(this.currentIncidentId);
                break;
              case IncidentView.TODOFUTURE:
                //this.tabTodoFuture.scrollTo(this.currentIncidentId);
                break;
              case IncidentView.OVERDUE:
                //this.tabOverdue.scrollTo(this.currentIncidentId);
                break;
              case IncidentView.DONE:
                //this.tabDone.scrollTo(this.currentIncidentId);
                break;
              case IncidentView.DEFECT:
                //this.tabDefect.scrollTo(this.currentIncidentId);
                break;
              case IncidentView.MISSINGDOCUMENT:
                //this.tabMissingDocument.scrollTo(this.currentIncidentId);
                break;
            }
          }
        }
      })
    );

    if (!this.currentViewTab) {
      this.currentViewTab = IncidentView.TODOFUTURE;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.viewTabChangeRequest.emit(IncidentView.getIncidentView(changeEvent.nextId));
  }

  filterIncidents(incidents: PropertyCore.Event[]) {
    const future = new Date();
    future.setMonth(future.getMonth() + 3);
    future.setDate(future.getDate() + 1);
    future.setHours(0, 0, 0, 0);

    this.todoIncidents = this.incidents
      .filter((x) => x.KpiMarker.IsPlanned && x.DueDate < future && x.Type != PropertyCore.EventTypes.Undefined)
      .sort(function (a, b) {
        return (a.DueDate ? a.DueDate.getTime() : 0) - (b.DueDate ? b.DueDate.getTime() : 0);
      });

    this.todoFutureIncidents = incidents
      .filter(
        (x) =>
          x.KpiMarker.IsPlanned &&
          x.KpiMarker.IsActive &&
          x.KpiMarker.IsInFocus &&
          x.DueDate < future &&
          x.Type != PropertyCore.EventTypes.Undefined
      )
      .sort(function (a, b) {
        return (a.DueDate ? a.DueDate.getTime() : 0) - (b.DueDate ? b.DueDate.getTime() : 0);
      });

    this.overdueIncidents = this.incidents
      .filter(
        (x) =>
          x.KpiMarker.IsOverdue &&
          x.KpiMarker.IsActive &&
          x.KpiMarker.IsInFocus &&
          x.Type != PropertyCore.EventTypes.Undefined
      )
      .sort(function (a, b) {
        return (b.DueDate ? b.DueDate.getTime() : 0) - (a.DueDate ? a.DueDate.getTime() : 0);
      });

    this.doneIncidents = incidents
      .filter((x) => (x.KpiMarker.IsDone || !x.KpiMarker.IsActive) && x.Type != PropertyCore.EventTypes.Undefined)
      .sort(function (a, b) {
        return (b.DueDate ? b.DueDate.getTime() : 0) - (a.DueDate ? a.DueDate.getTime() : 0);
      });

    this.missingDocumentIncidents = this.incidents
      .filter(
        (x) =>
          x.KpiMarker.IsMissingDocuments &&
          x.KpiMarker.IsActive &&
          x.KpiMarker.IsInFocus &&
          x.Type != PropertyCore.EventTypes.Undefined
      )
      .sort(function (a, b) {
        return (b.DueDate ? b.DueDate.getTime() : 0) - (a.DueDate ? a.DueDate.getTime() : 0);
      });

    this.defectIncidents = this.incidents
      .filter(
        (x) =>
          x.KpiMarker.IsDefective &&
          x.KpiMarker.IsActive &&
          x.KpiMarker.IsInFocus &&
          x.Type != PropertyCore.EventTypes.Undefined
      )
      .sort(function (a, b) {
        return (b.DueDate ? b.DueDate.getTime() : 0) - (a.DueDate ? a.DueDate.getTime() : 0);
      });
  }

  serviceProviderNames(incidents: Incident[]): string[] {
    if (!incidents) {
      return [];
    }
    return [...new Set(incidents.filter((value) => value.ServiceProvider).map((value) => value.ServiceProvider.Name))];
  }

  handleDetailPageRequest($event: IncidentDetailLinkOutputData) {
    this.detailPageRequest.emit($event);
  }

  handleTypeTabChangeRequest($event: IncidentType) {
    this.typeTabChangeRequest.emit($event);
  }

  isShown(tabId: string): boolean {
    return this.inputData.allowedViews.indexOf(IncidentView.getIncidentView(tabId)) !== -1;
  }

  linkToIncidentDetail(id: number, type: IncidentType = IncidentType.NONE): void {
    const requestData = new IncidentDetailLinkOutputData();
    requestData.id = id;
    requestData.type = type;
    this.handleDetailPageRequest(requestData);
  }

  isSelected(id: number) {
    return this.currentIncidentId === id;
  }
}
