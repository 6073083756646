import { animate, AnimationMetadata, group, query, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';

const leftContentMoveToLeft = [
  query(':leave .outlet', style({ transform: 'translate3d(0%,0,0)' }), { optional: true }),
  query(':enter .outlet', style({ 'z-index': 2, transform: 'translate3d(100%,0,0)' }), { optional: true }), // entering panel must be over the leaving from right side in order to routerLinkActive to work
  query(':leave .outlet *[name="tabs"] + *', style({ opacity: 1 }), { optional: true }),

  group([
    query(':leave .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(-100%,0,0)' })), { optional: true }),
    query(':enter .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(0%,0,0)' })), { optional: true }),
    query(':leave .outlet *[name="tabs"] + *',
      animate('0ms 600ms', style({ opacity: 0 })), { optional: true }) //'tabs' router outlet should be visible until the end of animation
  ])
];

const leftContentMoveToRight = [
  query(':leave .outlet', style({ transform: 'translate3d(0%,0,0)' }), { optional: true }),
  query(':enter .outlet', style({ transform: 'translate3d(-100%,0,0)' }), { optional: true }),
  group([
    query(':leave .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(100%,0,0)' }))),
    query(':enter .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(0%,0,0)' })), { optional: true })
  ])
];

const rightContentMoveToLeft = [
  query(':leave .outlet', style({ transform: 'translate3d(0%,0,0)' }), { optional: true }),
  query(':enter .outlet', style({ transform: 'translate3d(100%,0,0)' }), { optional: true }),
  query(':leave .outlet *[name="tabs"] + *', style({ opacity: 1 }), { optional: true }),

  group([
    query(':leave .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(-100%,0,0)' })), { optional: true }),
    query(':enter .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(0%,0,0)' })), { optional: true }),
    query(':leave .outlet *[name="tabs"] + *',
      animate('0ms 600ms', style({ opacity: 0 })), { optional: true }) //'tabs' router outlet should be visible until the end of animation
  ])
];

const rightContentMoveToRight = [
  query(':leave .outlet', style({ transform: 'translate3d(0%,0,0)' }), { optional: true }),
  query(':enter .outlet', style({ transform: 'translate3d(-100%,0,0)' }), { optional: true }),

  group([
    query(':leave .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(100%,0,0)' })), { optional: true }),
    query(':enter .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(0%,0,0)' })), { optional: true })
  ])
];

export const leftPanelSideAnimations: AnimationMetadata = trigger('leftAnimations', [
  transition('init => *', []), //denies any animation from 'init' state
  transition('void => *', []), //denies animation from 'null' state
  transition('2-building => 1-building-list', leftContentMoveToRight),
  transition('3-equipment => 2-building', leftContentMoveToRight), //pressed close on 3-incident
  transition('3-module => 2-building', leftContentMoveToRight),
  transition('* => 2-building', leftContentMoveToLeft),
  transition('* => 3-equipment', leftContentMoveToLeft),
  transition('* => 3-module', leftContentMoveToLeft)
]);


/**
 * xyz => * : when moving forward to the right side: use rightContentMoveToLeft
 * abc => xyz : when moving backwards (close button) from abc to xyz: use rightContentMoveToRight
 */
export const rightPanelSideAnimations: AnimationMetadata = trigger('rightAnimations', [
  transition('init => *', []), //denies any animation from 'init' state
  transition('* => init', []), //denies any animation to 'init' state
  transition('void => *', []), //denies animation from 'null' state
  transition('1-building => *', rightContentMoveToLeft),
  transition('2-equipment => 1-building', rightContentMoveToRight), // pressed close on 2-equipment
  transition('2-incident => 1-building', rightContentMoveToRight), // pressed close on 2-incident
  transition('2-equipment => *', rightContentMoveToLeft),
  transition('2-incident => 2-equipment', rightContentMoveToRight),
  transition('3-module => 2-equipment', rightContentMoveToRight),
  transition('3-incident => 2-equipment', rightContentMoveToRight)
  //transition('2-equipment => 2-incident', []),
  //transition('2-incident => 2-equipment', []),
  //transition('* => 2-equipment', rightContentMoveFromRight),
  //transition('* => 3-module', rightContentMoveToRight)
]);


@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss'],
  animations: [leftPanelSideAnimations, rightPanelSideAnimations]
})
export class BuildingComponent implements OnInit, OnDestroy {

  isWizard: boolean = false;

  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute
  ) { }

  public prepareRouteTransition(outlet: RouterOutlet) {
    const result = outlet.activatedRouteData['animation'] || 'init';
    return result;
  }

  ngOnInit() {
    this.subscriptions.add(this.route.url.subscribe(url => {
      this.isWizard = (url.length > 2) && (url[2].path.toLowerCase() === 'wizard');
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
