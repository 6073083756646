export enum IncidentWizardNavigationPaths {

  DATA = 'data',
  EQUIPMENT = 'equipment',
  CONTACT = 'contact',
  ADD_SERVICE_CONTACT = 'add-service-contact',

  NEXT = 'next',
  PREVIOUS = 'previous',
  START = 'start',
  EXIT = 'exit'
}
