import { CommonModule } from "@angular/common";
import {
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    NgbDateParserFormatter,
    NgbDatepickerI18n,
    NgbModule,
    NgbDateAdapter,
    NgbDateNativeAdapter
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FileUploadModule } from "ng2-file-upload";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { UiSwitchModule } from "ngx-ui-switch";

import { AddressComponent } from "./components/address/address.component";
import { CoreBuildingDocumentsComponent } from "./components/building/documents/core-building-documents.component";
import { CoreBuildingDocumentsOnlyComponent } from "./components/building/documents-missing-only/core-building-documents-missing-only.component";
import { CoreBuildingEditUsagesComponent } from "./components/building/edit-usages/core-building-edit-usages.component";
import { CoreBuildingListComponent } from "./components/building/list/core-building-list.component";
import { CoreContractListComponent } from "./components/contract/list/core-contract-list.component";
import { CoreContractSetMaintenanceComponent } from "./components/contract/set-maintenance/core-contract-set-maintenance.component";
import { CoreEquipmentDocumentsComponent } from "./components/equipment/documents/core-equipment-documents.component";
import { CoreEditInspectionDutiesComponent } from "./components/equipment/edit-inspection-duties/core-edit-inspection-duties.component";
import { CoreEquipmentListComponent } from "./components/equipment/list/core-equipment-list.component";
import { CoreEquipmentListAccordionComponent } from "./components/equipment/list-accordion/core-equipment-list-accordion.component";
import { IncidentDetailComponent } from "./components/incident/incident-detail/incident-detail.component";
import { IncidentListViewComponent } from "./components/incident/incident-list-view/incident-list-view.component";
import { IncidentRowComponent } from "./components/incident/incident-list-view/incident-row/incident-row.component";
import { IncidentTabComponent } from "./components/incident/incident-list-view/incident-tab/incident-tab.component";
import { IncidentFilterComponent } from "./components/incident/incident-data-grid/incident-filter/incident-filter.component";
import { InfographicComponent } from "./components/infographic/infographic.component";
import { AddServiceContactComponent } from "./components/service-contact/add-service-contact/add-service-contact.component";
import { DocumentUploadComponent } from "./components/upload/document-upload.component";
import { ImageUploadComponent } from "./components/upload/image-upload.component";
import { ClickOutsideDirective } from "./datepicker/clickOutside.directive";
import { CustomDateParserFormatter } from "./datepicker/customDateParserFormatter";
import { CustomDatepickerI18n } from "./datepicker/datepicker-i18n";
import { DatepickerPipe } from "./datepicker/datepicker.pipe";
import { ProvideParentFormDirective } from "./form/provide-parent-form";
import { ProvideParentFormGroupDirective } from "./form/provide-parent-form-group";
import { ScrollToListItemDirective } from "./gui/scrollToListItem.directive";
import { TextTruncateTitleDirective } from "./gui/text-truncate-title.directive";
import { DateComparePipe } from "./pipes/dateCompare.pipe";
import { DateFormatPipe } from "./pipes/dateFormat.pipe";
import { DateTimeFormatPipe } from "./pipes/dateTimeFormat.pipe";
import { FileSizePipe } from "./pipes/fileSize.pipe";
import { FilterByPipe } from "./pipes/filter.pipe";
import { OrderPipe } from "./pipes/order.pipe";
import { SecureDownloadTokenPipe } from "./pipes/secureDownloadToken.pipe";
import { FilterIncidentsByPipe } from "./pipes/filter2.pipe";
import { ConfirmationModal } from "./popup/confirmation.modal";
import { ImagePreviewModal } from "./popup/image-preview.modal";
import { OfficePreviewModal } from "./popup/office-preview.modal";
import { PdfModal } from "./popup/pdf.modal";
import { PopupService } from "./popup/popup.service";
import { LoggerService } from "./shared/logger.service";
import { NavigatorService } from "./shared/navigator.service";
import { CountryValidator } from "./validators/country.validator";
import { OptionalEmailValidator } from "./validators/optionalEmail.validator";
import { RegionValidator } from "./validators/region.validator";
import {
    DevExtremeModule,
    DxDataGridModule,
    DxDropDownBoxModule,
} from "devextreme-angular";
import { LoginService } from "./shared/login.service";
import { GlobalService } from "./shared/global.service";
import { MandatorService } from "./shared/services/mandator.service";
import { BuildingService } from "./shared/services/building.service";
import { SelectableItemsComponent } from "./components/selectable-items/selectable-items.component";
import { ExcelService } from "./shared/services/shared/excel.service";
import { AttributeValuesService } from "./shared/services/attribute-values.service";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        GoogleMapsModule,
        MatFormFieldModule,
        MatSelectModule,
        CommonModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        UiSwitchModule,
        NgbModule,
        FileUploadModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        PdfViewerModule,
        DevExtremeModule,
        DxDataGridModule,
        DxDropDownBoxModule
    ],
    exports: [
        CommonModule,
        GoogleMapsModule,
        MatFormFieldModule,
        MatSelectModule,
        // AgmCoreModule,
        FilterByPipe,
        OrderPipe,
        FilterIncidentsByPipe,
        DatepickerPipe,
        DateFormatPipe,
        DateTimeFormatPipe,
        DateComparePipe,
        FileSizePipe,
        SecureDownloadTokenPipe,
        ProvideParentFormDirective,
        ProvideParentFormGroupDirective,
        CalendarModule,
        UiSwitchModule,
        NgbModule,
        ClickOutsideDirective,
        FileUploadModule,
        ImageUploadComponent,
        DocumentUploadComponent,
        InfographicComponent,
        TranslateModule,
        AddressComponent,
        IncidentListViewComponent,
        IncidentTabComponent,
        IncidentRowComponent,
        IncidentDetailComponent,
        PdfViewerModule,
        OptionalEmailValidator,
        CoreBuildingListComponent,
        CoreEquipmentListComponent,
        CoreEquipmentListAccordionComponent,
        CoreBuildingEditUsagesComponent,
        CoreEditInspectionDutiesComponent,
        CoreContractListComponent,
        CoreBuildingDocumentsComponent,
        CoreBuildingDocumentsOnlyComponent,
        CoreEquipmentDocumentsComponent,
        ScrollToListItemDirective,
        TextTruncateTitleDirective,
        AddServiceContactComponent,
        SelectableItemsComponent,
    ],
    declarations: [
        ConfirmationModal,
        PdfModal,
        ImagePreviewModal,
        OfficePreviewModal,
        FilterByPipe,
        OrderPipe,
        FilterIncidentsByPipe,
        DatepickerPipe,
        DateFormatPipe,
        DateTimeFormatPipe,
        DateComparePipe,
        FileSizePipe,
        SecureDownloadTokenPipe,
        ProvideParentFormGroupDirective,
        ProvideParentFormDirective,
        ClickOutsideDirective,
        ImageUploadComponent,
        DocumentUploadComponent,
        InfographicComponent,
        AddressComponent,
        IncidentListViewComponent,
        IncidentTabComponent,
        IncidentRowComponent,
        IncidentFilterComponent,
        IncidentDetailComponent,
        CountryValidator,
        RegionValidator,
        OptionalEmailValidator,
        CoreBuildingListComponent,
        CoreEquipmentListComponent,
        CoreEquipmentListAccordionComponent,
        CoreBuildingEditUsagesComponent,
        CoreEditInspectionDutiesComponent,
        CoreContractListComponent,
        CoreContractSetMaintenanceComponent,
        CoreBuildingDocumentsComponent,
        CoreBuildingDocumentsOnlyComponent,
        CoreEquipmentDocumentsComponent,
        ScrollToListItemDirective,
        TextTruncateTitleDirective,
        AddServiceContactComponent,
        SelectableItemsComponent,
    ],
    providers: [
        GlobalService,
        LoginService,
        LoggerService,
        PopupService,
        NavigatorService,
        FilterByPipe,
        OrderPipe,
        FilterIncidentsByPipe,
        DatepickerPipe,
        DateFormatPipe,
        DateTimeFormatPipe,
        DateComparePipe,
        FileSizePipe,
        SecureDownloadTokenPipe,
        {
            provide: NgbDateParserFormatter,
            useClass: CustomDateParserFormatter,
        },
        {
            provide: NgbDateAdapter,
            useClass: NgbDateNativeAdapter,
        },
        {
            provide: NgbDatepickerI18n,
            useClass: CustomDatepickerI18n,
        },
        MandatorService,
        BuildingService,
        ExcelService,
        AttributeValuesService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CoreModule { }
