import { DatePipe, formatNumber } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatPipe } from './../pipes/dateFormat.pipe';


export class Utils {

  public static buildUrl(url: string, params?: any): string {


    // TODO
    /*
    private doLog(level: LogLevel, message: string, ...params: any[]) {
      if (level >= Logger.config.getLevel() && Logger.config.hasTag(this.tag)) {
          if (params != null && params.length > 0) {
    message = message.replace(/{(\d+)}/g, (match, number) => params[number]);
    */

    if (params) {
      Object.keys(params).map(key => url = url.replace('${' + key + '}', params[key] == null ? '' : encodeURIComponent(params[key])));
    }
    return url;
  }

  public static retrieveBuildingId(href: string): string {
    var index = href.indexOf("building/");
    var substr = href.substr(index + 9);
    var index2 = substr.indexOf("/");
    var buil = substr.substr(0, index2);
    // console.log(buil);

    return buil;
  }

  public static retrieveFacilityId(href: string): string {
    var index3 = href.indexOf("equipment/");
    var substr2 = href.substr(index3 + 10);
    var index4 = substr2.indexOf("/");
    var eq = substr2.substr(0, index4);
    // console.log(eq);

    return eq;
  }

  public static isEmpty(text: string) {
    return (!text || 0 === text.length);
  }

  public static guid(): string {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  public static toApiCent(jsAmount: any): number {
    if (typeof jsAmount == 'undefined') {
      return null;
    }

    if (jsAmount === 0) {
      return 0;
    }

    if (jsAmount === null) {
      return null;
    }

    if (jsAmount === '') {
      return null;
    }

    const res = jsAmount.toString().replace(',', '.');
    return Math.round(res * 100);
  }

  public static centToJsAmount(cent: number): number {
    if (cent == null) {
      return null;
    }
    return cent / 100;
  }

  public static datepickerToApiDate(date: NgbDateStruct): string {
    // IE bug for date format yyyy-MM-dd HH:mm, worksfine without the time format
    return this.transformDateToJson(this.datepickerToDate(date));
  }

  public static dateToJson(date: Date) {
    if (!date) {
      return null;
    }
    return this.transformDateToJson(date);
  }

  private static transformDateToJson(date: Date): string {
    return new DatePipe('de').transform(date, 'yyyy-MM-dd') + ' 00:00';
  }

  public static dateToDatepicker(date: Date): NgbDateStruct {
    if (date) {
      return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }
    return null;
  }

  public static datepickerToDate(date: NgbDateStruct): Date {
    if (!date || !date.year || !date.month || !date.day) {
      return null;
    }
    return new Date(date.year, date.month - 1, date.day);
  }

  public static jsonToDate(date: any): Date {
    let res: Date = null;
    if (date) {
      res = new Date(date);
      // 0001-01-01T00:00:00Z
      if (res.getFullYear() === 1) {
        res = null;
      }
    }
    return res;
  }

  public static jsonToDatepicker(date: string): NgbDateStruct {
    return this.dateToDatepicker(this.jsonToDate(date));
  }

  public static yearsTillToday(startFrom: number = 1900): string[] {
    const years: string[] = [];
    for (let i = new Date().getFullYear(); i >= startFrom; i--) {
      years.push(String(i));
    }
    return years;
  }

  public static performPipeOperation(pipe: string, value: any): string {
    switch (pipe) {
      case 'dateFormat':
        return new DateFormatPipe('de').transform(value);
      case 'number':
        return formatNumber(value, 'de', '.2-2');
      default:
        return '';
    }
  }

  public static mapAllJSONDatesToDates<T>(obj: T): T {
    if (!obj) {
      return obj;
    }

    // [prop] syntax should be used as of typescript 3.9 as
    // Type Parameters That Extend any No Longer Act as any
    if (obj["Created"]) {
      obj["Created"] = this.jsonToDate(obj["Created"]);
    }
    if (obj["DueDate"]) {
      obj["DueDate"] = this.jsonToDate(obj["DueDate"]);
    }
    if (obj["RefDate"]) {
      obj["RefDate"] = this.jsonToDate(obj["RefDate"]);
    }
    if (obj["ClosedDate"]) {
      obj["ClosedDate"] = this.jsonToDate(obj["ClosedDate"]);
    }
    if (obj["CycleStart"]) {
      obj["CycleStart"] = this.jsonToDate(obj["CycleStart"]);
    }
    if (obj["CycleNext"]) {
      obj["CycleNext"] = this.jsonToDate(obj["CycleNext"]);
    }
    if (obj["CycleStop"]) {
      obj["CycleStop"] = this.jsonToDate(obj["CycleStop"]);
    }
    if (obj["StartDate"]) {
      obj["StartDate"] = this.jsonToDate(obj["StartDate"]);
    }
    if (obj["EndDate"]) {
      obj["EndDate"] = this.jsonToDate(obj["EndDate"]);
    }
    if (obj["TimeStamp"]) {
      obj["TimeStamp"] = this.jsonToDate(obj["TimeStamp"]);
    }
    if (obj["ResponseDate"]) {
      obj["ResponseDate"] = this.jsonToDate(obj["ResponseDate"]);
    }
    if (obj["UpdateDate"]) {
      obj["UpdateDate"] = this.jsonToDate(obj["UpdateDate"]);
    }
    Object.keys(obj).forEach(o => {
      if (typeof obj[o] === 'object') {
        this.mapAllJSONDatesToDates(obj[o]);
      }
    });
    return obj;
  }
}
