import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject, timer } from 'rxjs';
import { startWith} from 'rxjs/operators';



@Injectable()
export class NewsService {

  private _notificationChangedSubject: Subject<boolean> = new Subject();

  constructor() {
  }

  registerForChange(): Observable<[boolean, number]> {
    // every 60 seconds or when mark as read was called
    return combineLatest(this._notificationChangedSubject.asObservable().pipe(startWith(true)), timer(0, 60000));
  }

}
