import { PropertyCore, Common } from "../../../odata/odata.coreapi";

export class EditOperatorTask {
  identity: string;
  id: string;
  imName: string;
  guideline: string;
  deadline: string;
  subject: string;
  qualification: string;
  operatortaskId: number;
  operatortaskIdentity: string;
  cycle: number;
  cycleUnit: string;
  cycleStart: Date;
  isMissing: boolean;
  operatorTaskMarkers: PropertyCore.OperatorTaskMarker[] = [];

  //activated inspection duty
  aCycle: number;
  aCycleUnit: string;
  aCycleStart: Date;
  aCycleStartIsNotEmpty: boolean;
  aInspectionDutyIdentity: string;

  // additional properties for gui use
  originalIsDisabled: boolean;
  isChangedSeriesStart: boolean;
  isChangedActive: boolean;
}
