import { Observable } from "rxjs";
import { PropertyCore } from "../../odata/odata.coreapi";

export class EquipmentListInputData {
  searchFilter: string = "";
  onSearchFilterChange(newValue: string) {
    this.searchFilter = newValue;
  }

  installEquipments$: Observable<PropertyCore.Equipment[]>;
  equipments$: Observable<PropertyCore.EquipmentType[]>;
  currentInstallEquipmentUT$: Observable<string>;
  lastIdx: number = -1;
  showGroupingWizardRow: boolean = true;
  showBuilding: boolean = false;
  showEquipmentTypeData: boolean = false;
  scrollStyle = { height: "calc(100vh - 383px)" };
  presetFilter: number = null;
  navigateToFirstListItem: boolean = false;
}
