import { DocumentStatus, Incident } from "./../../../core/model/incident";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { FileItem } from "ng2-file-upload";
import { Subscription, from, Observable } from "rxjs";
import { PropertyCore } from "../../../core/odata/odata.coreapi";
import { IncidentState } from "./../../../building/building-incident/incident-state.service";
import { ConfirmationModal } from "./../../../core/popup/confirmation.modal";
import { IntegrityBoxComponent } from "./../../dashboard-boxes/integrity-box/integrity-box.component";
import { MissingDocumentState } from "./missing-document-state.service";
import { Guid } from "guid-typescript";
import { Utils } from "../../../core/tools/utils";
import { map } from "rxjs/internal/operators/map";
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-missing-document-upload",
  templateUrl: "./missing-document-upload.component.html",
  styleUrls: ["./missing-document-upload.component.scss"],
})
export class MissingDocumentUploadComponent implements OnInit, OnDestroy {
  type: string;
  UT: string;
  id: number;
  documentTypeId: number;
  virtual: boolean;
  file: FileItem;
  name: string;

  documentTypes: PropertyCore.DocumentType[];
  requiredDocumentTypeId: number;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private missingDocumentState: MissingDocumentState,
    private incidentState: IncidentState,
    private odataCoreService: ODataCoreService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.type = this.route.snapshot.url[0].path;
    this.subscriptions.add(
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.resetForm();

        this.UT = params.get("id") || params.get("incidentId");
        this.documentTypeId = +params.get("documentTypeId");

        // get id from detail data by UT
        switch (this.type) {
          case "building":
            this.subscriptions.add(
              from(
                this.odataCoreService.Building.Get()
                  .Key(Guid.parse(this.UT))
                  .Expand((x) => {
                    x.Expand("UsageTypes");
                    x.Expand("Statistics");
                  })
                  .Exec()
                  .then((x) => x.value)
              )
                .pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])))
                .subscribe((res) => (this.id = res.Id))
            );
            break;
          case "equipment":
            this.subscriptions.add(
              from(
                this.odataCoreService.Equipment.Query()
                  .Expand((x) => {
                    x.Expand("Building"),
                      x.Expand("EquipmentType"),
                      x.Expand("Room", (y) => y.Expand("Floor"));
                  })
                  .Filter((x) => x.EqualsField("Identity", Guid.parse(this.UT)))
                  .Exec()
                  .then((x) => x.value)
              )
                .pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])))
                .subscribe((res) => (this.id = res.Id))
            );
            break;
          case "incident":
            this.subscriptions.add(
              from(
                this.odataCoreService.Event.Query()
                  .Expand((x) => {
                    x.Expand("Response"),
                      x.Expand("Schedule", (xa) => {
                        xa.Expand("InspectionDuty", (xaa) => {
                          xaa.Select("Id"),
                            xaa.Expand("OperatorTask", (xaaa) => {
                              xaaa.Select("Guidelines"),
                                xaaa.Select("Qualification");
                            });
                        }),
                          xa.Expand("MaintenanceContract", (xab) => {
                            xab.Select("StartDate"),
                              xab.Expand("ServiceProvider", (xabb) => {
                                xabb.Select("Name");
                              });
                          });
                      }),
                      x.Expand("Building", (xb) => {
                        xb.Select("Name"),
                          xb.Select("Id"),
                          xb.Select("Identity");
                      }),
                      x.Expand("Equipment", (xc) => {
                        xc.Select("Name"),
                          xc.Select("Id"),
                          xc.Select("Identity"),
                          xc.Select("CustomId"),
                          xc.Expand("Room", (xcc) => {
                            xcc.Select("Name"),
                              xcc.Select("Id"),
                              xcc.Select("Identity");
                            xcc.Expand("Floor", (xccc) => {
                              xccc.Select("Id"), xccc.Select("Name");
                            });
                          });
                      }),
                      x.Expand("ServiceProvider", (xd) => {
                        xd.Select("Name"),
                          xd.Select("Id"),
                          xd.Select("Identity");
                      }),
                      x.Expand("Module", (xe) => {
                        xe.Select("Name"),
                          xe.Select("Id"),
                          xe.Select("Identity"),
                          xe.Expand("ModuleType", (xee) => {
                            xee.Select("Code"),
                              xee.Select("Id"),
                              xee.Select("Name");
                          });
                      }),
                      x.Expand("Documents", (y) => {
                        y.Select("DocumentType"),
                          y.Expand("DocumentType", (z) => {
                            z.Select("Id"), z.Select("Name");
                          });
                      }),
                      x.Expand("KpiMarker");
                  })
                  .Filter((x) => x.EqualsField("Id", +this.UT))
                  .Exec()
                  .then((x) => x.value)
              )
                .pipe(
                  map((res) => {
                    Utils.mapAllJSONDatesToDates(res[0]);
                    return Object.assign(new Incident(), res[0]);
                  })
                )
                .subscribe((res) => {
                  this.UT = res.Identity;
                  this.id = res.Id;

                  if (
                    res.documentOfferStatus === DocumentStatus.MissingRequired
                  ) {
                    this.requiredDocumentTypeId = 1; // Angebot
                    this.documentTypeId = 1;
                  } else if (
                    res.documentCommissionStatus ===
                    DocumentStatus.MissingRequired
                  ) {
                    this.requiredDocumentTypeId = 2; // Beauftragung
                    this.documentTypeId = 2;
                  } else if (
                    res.documentReportStatus === DocumentStatus.MissingRequired
                  ) {
                    this.requiredDocumentTypeId = 4; // Regiebericht
                    this.documentTypeId = 4;
                  } else if (
                    res.documentProtocolStatus ===
                    DocumentStatus.MissingRequired
                  ) {
                    this.requiredDocumentTypeId = 3; // Protokoll
                    this.documentTypeId = 3;
                  } else if (
                    res.documentInvoiceStatus === DocumentStatus.MissingRequired
                  ) {
                    this.requiredDocumentTypeId = 5; // Rechnung
                    this.documentTypeId = 5;
                  }

                  this.subscriptions.add(
                    from(
                      this.odataCoreService.DocumentTypeGroup.Query()
                        .Filter((x) => x.EqualsField("Id", 1))
                        .Expand((x) => {
                          x.Expand("DocumentTypes");
                        })
                        .Exec()
                        .then((x) => x.value)
                    )
                      .pipe(
                        map((res) =>
                          res[0].DocumentTypes.map((i) =>
                            Utils.mapAllJSONDatesToDates(i)
                          )
                        )
                      )
                      .subscribe((res2) => {
                        this.documentTypes = res2;
                      })
                  );
                })
            );
            break;
        }
      })
    );
  }

  get documentTypeIdString() {
    return this.documentTypeId.toString();
  }

  onDocumentTypeChange(typeId: string): void {
    this.documentTypeId = +typeId;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onVirtualChange(virtual: boolean): void {
    this.virtual = virtual;

    // reset data which depends on virtual
    this.file = null;
    this.name = null;

    this.formSubmitted = false;
  }

  onAddFile(file: FileItem): void {
    this.file = file;
  }

  onRemoveFile(): void {
    this.file = null;
  }

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    if (this.virtual) {
      let saveDocument$;

      this.subscriptions.add(
        from(
          this.odataCoreService.Document.Post()
            .ValueType(this.odataCoreService.ODataTypes().Document())
            .ValuePropertyBinding(
              "DocumentType",
              this.odataCoreService.DocumentType.Get()
                .Key(this.documentTypeId)
                .Bind()
            )
            .ValueProperty("Description", this.name)
            .Exec()
        ).subscribe((res) => {
          switch (this.type) {
            case "building":
              saveDocument$ = from(
                this.odataCoreService.Building.Link()
                  .Key(Guid.parse(this.UT))
                  .Value(
                    "Documents",
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(res.Identity))
                      .Bind()
                  )
                  .Post()
              );
              break;

            case "equipment":
              saveDocument$ = from(
                this.odataCoreService.Equipment.Link()
                  .Key(Guid.parse(this.UT))
                  .Value(
                    "Documents",
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(res.Identity))
                      .Bind()
                  )
                  .Post()
              );
              break;

            case "incident":
              saveDocument$ = from(
                this.odataCoreService.Event.Link()
                  .Key(Guid.parse(this.UT))
                  .Value(
                    "Documents",
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(res.Identity))
                      .Bind()
                  )
                  .Post()
              );
              break;
          }
          if (saveDocument$) {
            this.subscriptions.add(
              saveDocument$.subscribe((res2) => {
                this.toasterService.pop(
                  "info",
                  "",
                  this.translateService.instant(
                    "DashboardIntegrityMissingDocumentUpload._upload2_success"
                  )
                );
                this.missingDocumentState.notify(
                  MissingDocumentState.ACTION_UPLOAD_DOCUMENT
                );
                this.incidentState.notify(
                  IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT
                );

                this.resetForm();
              })
            );
          } else {
            this.exit();
          }
        })
      );
    } else {
      this.subscriptions.add(
        from(
          this.odataCoreService.Document.Post()
            .ValueType(this.odataCoreService.ODataTypes().Document())
            .ValuePropertyBinding(
              "DocumentType",
              this.odataCoreService.DocumentType.Get()
                .Key(this.documentTypeId)
                .Bind()
            )
            .ValueProperty("Name", this.file.file.name)
            .ValueProperty("Description", this.name)
            .Exec()
        ).subscribe((res) => {
          if (this.file.file.type != "") {
            var odataDocumentUrl = this.odataCoreService.Document.Get()
              .Raw()
              .Key(Guid.parse(res.Identity))
              .ToUrlString(false);

            this.subscriptions.add(
              from(
                this.uploadODataDocument(this.file, odataDocumentUrl)
              ).subscribe((res1) => {
                let saveDocument$;
                switch (this.type) {
                  case "building":
                    saveDocument$ = from(
                      this.odataCoreService.Building.Link()
                        .Key(Guid.parse(this.UT))
                        .Value(
                          "Documents",
                          this.odataCoreService.Document.Get()
                            .Key(Guid.parse(res.Identity))
                            .Bind()
                        )
                        .Post()
                    );

                    break;
                  case "equipment":
                    saveDocument$ = from(
                      this.odataCoreService.Equipment.Link()
                        .Key(Guid.parse(this.UT))
                        .Value(
                          "Documents",
                          this.odataCoreService.Document.Get()
                            .Key(Guid.parse(res.Identity))
                            .Bind()
                        )
                        .Post()
                    );
                    break;
                  case "incident":
                    saveDocument$ = from(
                      this.odataCoreService.Event.Link()
                        .Key(Guid.parse(this.UT))
                        .Value(
                          "Documents",
                          this.odataCoreService.Document.Get()
                            .Key(Guid.parse(res.Identity))
                            .Bind()
                        )
                        .Post()
                    );
                    break;
                }
                if (saveDocument$) {
                  this.subscriptions.add(
                    saveDocument$.subscribe((res2) => {
                      this.toasterService.pop(
                        "info",
                        "",
                        this.translateService.instant(
                          "DashboardIntegrityMissingDocumentUpload._upload_success"
                        )
                      );
                      this.missingDocumentState.notify(
                        MissingDocumentState.ACTION_UPLOAD_DOCUMENT
                      );
                      this.incidentState.notify(
                        IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT
                      );

                      this.resetForm();
                    })
                  );
                } else {
                  this.exit();
                }
              })
            );
          } else {
            this.uploadDocumentNoContentType(res.Identity);
          }
        })
      );
    }
  }

  uploadODataDocument(file: FileItem, url: string): Observable<any> {
    return this.httpClient.put(url, file._file);
  }

  uploadODataDocumentNoCT(file: Blob, url: string): Observable<any> {
    return this.httpClient.put(url, file);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant(
        "DashboardIntegrityMissingDocumentUpload._modal_title"
      );
      modalRef.componentInstance.message = this.translateService.instant(
        "DashboardIntegrityMissingDocumentUpload._modal_message"
      );
      modalRef.componentInstance.yesButton = this.translateService.instant(
        "DashboardIntegrityMissingDocumentUpload._modal_yes"
      );
      modalRef.componentInstance.cancelButton = this.translateService.instant(
        "DashboardIntegrityMissingDocumentUpload._modal_cancel"
      );

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.exit();
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.exit();
    }
  }

  private exit() {
    switch (this.type) {
      case "building":
        this.router.navigate(
          [
            "/dashboard",
            {
              outlets: {
                left: ["board"],
                right: [
                  IntegrityBoxComponent.PATH_PREFIX,
                  IntegrityBoxComponent.PATH_BUILDINGS_MISSING_DOCUMENTS,
                ],
              },
            },
          ],
          { queryParamsHandling: "merge" }
        );
        break;
      case "equipment":
        this.router.navigate(
          [
            "/dashboard",
            {
              outlets: {
                left: ["board"],
                right: [
                  IntegrityBoxComponent.PATH_PREFIX,
                  IntegrityBoxComponent.PATH_EQUIPMENTS_MISSING_DOCUMENTS,
                ],
              },
            },
          ],
          { queryParamsHandling: "merge" }
        );
        break;
      case "incident":
        this.router.navigate(
          [
            "/dashboard",
            {
              outlets: {
                left: ["board"],
                right: ["conformity", "list", { tab: "missingDocument" }],
              },
            },
          ],
          { queryParamsHandling: "merge" }
        );
        break;
      default:
        this.router.navigate(["/dashboard"]);
        break;
    }
  }

  uploadDocumentNoContentType(documentIdentity: string): void {
    let fileExtensionIndex = this.file.file.name.lastIndexOf(".");
    let fileExtension = this.file.file.name.substring(fileExtensionIndex);
    let blobFile;

    switch (fileExtension) {
      case ".pdf":
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "application/pdf"
        );
        break;

      case ".png":
        blobFile = this.file._file.slice(0, this.file.file.size, "image/png");
        break;

      case ".jpg":
      case ".jpeg":
        blobFile = this.file._file.slice(0, this.file.file.size, "image/jpeg");
        break;

      case ".tif":
      case ".tiff":
        blobFile = this.file._file.slice(0, this.file.file.size, "image/tiff");
        break;

      case ".doc":
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "application/msword"
        );
        break;

      case ".docx":
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
        break;

      case ".xls":
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "application/vnd.ms-excel"
        );
        break;

      case ".xlsx":
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        break;

      case ".ppt":
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "application/vnd.ms-powerpoint"
        );
        break;

      case ".pptx":
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        );
        break;

      case ".dwg":
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "image/vnd.dwg"
        );
        break;

      default:
        blobFile = this.file._file.slice(
          0,
          this.file.file.size,
          "application/octet-stream"
        );
        break;
    }

    var odataDocumentUrl = this.odataCoreService.Document.Get()
      .Raw()
      .Key(Guid.parse(documentIdentity))
      .ToUrlString(false);

    this.subscriptions.add(
      from(this.uploadODataDocumentNoCT(blobFile, odataDocumentUrl)).subscribe(
        (res1) => {
          let saveDocument$;
          switch (this.type) {
            case "building":
              saveDocument$ = from(
                this.odataCoreService.Building.Link()
                  .Key(Guid.parse(this.UT))
                  .Value(
                    "Documents",
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(documentIdentity))
                      .Bind()
                  )
                  .Post()
              );

              break;
            case "equipment":
              saveDocument$ = from(
                this.odataCoreService.Equipment.Link()
                  .Key(Guid.parse(this.UT))
                  .Value(
                    "Documents",
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(documentIdentity))
                      .Bind()
                  )
                  .Post()
              );
              break;
            case "incident":
              saveDocument$ = from(
                this.odataCoreService.Event.Link()
                  .Key(Guid.parse(this.UT))
                  .Value(
                    "Documents",
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(documentIdentity))
                      .Bind()
                  )
                  .Post()
              );
              break;
          }
          if (saveDocument$) {
            this.subscriptions.add(
              saveDocument$.subscribe((res2) => {
                this.toasterService.pop(
                  "info",
                  "",
                  this.translateService.instant(
                    "DashboardIntegrityMissingDocumentUpload._upload_success"
                  )
                );
                this.missingDocumentState.notify(
                  MissingDocumentState.ACTION_UPLOAD_DOCUMENT
                );
                this.incidentState.notify(
                  IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT
                );
              })
            );
          } else {
            this.exit();
          }
        }
      )
    );
  }

  private resetForm(): void {
    this.virtual = false;
    this.file = null;
    this.name = null;
    this.formSubmitted = false;
  }
}
