import {Injectable, Pipe, PipeTransform} from '@angular/core';

import {Utils} from './../tools/utils';

@Pipe({
  name: 'filterBy'
})
@Injectable()
export class FilterByPipe implements PipeTransform {

  /**
   * @items = object from array
   * @term = term's search
   */
  transform(items: any, terms: any, fields: any): any {
    if (terms === undefined || terms === null || terms === '') {
      return items;
    }

    return items && items.filter(function (item) {
      if (!(terms instanceof Array)) {
        terms = [terms];
      }
      if (fields === undefined || fields === null || fields === '') {
        for (const property in item) {
          if (item == null || item == undefined || property == undefined || !item.hasOwnProperty(property)) {
            continue;
          }
          let value: string = item[property].toString().toLowerCase();
          if (item[property] instanceof Date) {
            value = Utils.performPipeOperation('dateFormat', item[property]);
            value = value || '';
          }
          for (const term of terms) {
            if (term === undefined || term === null || term === ''
              || value.includes(term.toString().toLowerCase())) {
              return true;
            }
          }
        }
      } else {
        if (!(fields instanceof Array)) {
          fields = [fields];
        }
        for (const field of fields) {
          if (field) {
            let itemValue = item;
            field.split('.').forEach(val => {
              if (itemValue != null && itemValue != undefined && itemValue.hasOwnProperty(val)) {
                itemValue = itemValue[val];
              }
            });

            if (itemValue !== null && itemValue !== undefined) {
              let value: string = itemValue.toString().toLowerCase();
              if (itemValue instanceof Date) {
                value = Utils.performPipeOperation('dateFormat', itemValue);
                value = value || '';
              }
              for (const term of terms) {
                if (term === undefined || term === null || term === ''
                  || value.includes(term.toString().toLowerCase())) {
                  return true;
                }
              }
            }
          }
        }
      }

      return false;
    });
  }
}
