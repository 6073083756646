import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Observable, Subscription, from} from 'rxjs';
import {flatMap, switchMap, tap, map} from 'rxjs/operators';
import {DocumentType} from '../../../building/wizard/document/document-wizard-model';
import {NavigatorService} from '../../../core/shared/navigator.service';
import {IncidentState} from '../../../building/building-incident/incident-state.service';
import {IncidentCreateOuputData} from '../../../core/components/incident/incident-create-output-data';
import {Incident} from '../../../core/model/incident';
import { Guid } from 'guid-typescript';
import { Utils } from '../../../core/tools/utils';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../core/odata/odataclient';


@Component({
  selector: 'app-conformity-detail',
  templateUrl: './conformity-detail.component.html',
  styleUrls: ['./conformity-detail.component.scss']
})
export class ConformityDetailComponent implements OnInit, OnDestroy {

  incident$: Observable<Incident>;
  defects$: Observable<Incident[]>;
  incidentUT: string;


  subscriptions: Subscription = new Subscription();

  constructor(
    private state: IncidentState,
    private route: ActivatedRoute,
    private router: Router,
    private navigator: NavigatorService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.incident$ = this.route.paramMap.pipe(
      tap(params => this.incidentUT = params.get('incidentId')),
      switchMap((params: ParamMap) => {
        return from(this.odataCoreService.Event.Query()
          .Expand(x => {
            x.Expand("Response"),
              x.Expand("Schedule", xa => {
                xa.Expand("InspectionDuty", xaa => {
                  xaa.Select("Id"),
                    xaa.Expand("OperatorTask", xaaa => {
                      xaaa.Select("Guidelines"),
                        xaaa.Select("Qualification")
                    })
                }),
                  xa.Expand("MaintenanceContract", xab => {
                    xab.Select("StartDate"),
                      xab.Expand("ServiceProvider", xabb => {
                        xabb.Select("Name")
                      })
                  })
              }),
              x.Expand("Building", xb => {
                xb.Select("Name"),
                  xb.Select("Id"),
                  xb.Select("Identity")
              }),
              x.Expand("Equipment", xc => {
                xc.Select("Name"),
                  xc.Select("Id"),
                  xc.Select("Identity"),
                  xc.Select("CustomId"),
                  xc.Expand("Room", xcc => {
                    xcc.Select("Name"),
                      xcc.Select("Id"),
                      xcc.Select("Identity")
                    xcc.Expand("Floor", xccc => {
                      xccc.Select("Id"),
                        xccc.Select("Name")
                    })
                  })
              }),
              x.Expand("ServiceProvider", xd => {
                xd.Select("Name"),
                  xd.Select("Id"),
                  xd.Select("Identity")
              }),
              x.Expand("Module", xe => {
                xe.Select("Name"),
                  xe.Select("Id"),
                  xe.Select("Identity"),
                  xe.Expand("ModuleType", xee => {
                    xee.Select("Code"),
                      xee.Select("Id"),
                      xee.Select("Name")
                  })
              })
            x.Expand("KpiMarker")
          })
          .Filter(x => x
            .EqualsField("Id", (+params.get('incidentId'))))
          .Exec().then(x => x.value))
          .pipe(map(res => {
            Utils.mapAllJSONDatesToDates(res[0]);
            return Object.assign(new Incident(), res[0])
          }))
      }));
    this.defects$ = this.incident$.pipe(flatMap(inc => from(this.odataCoreService.Event.Query()
      .Filter(x => x
        .Equals(ODataPath.For("Parent", "Identity"), Guid.parse(inc.Identity)))
      .Expand(x => {
        x.Expand("Parent", y => {
          y.Select("Identity")
        }),
        x.Expand("Schedule"),
        x.Expand("Equipment", y => {
          y.Select("Name"),
          y.Select("Id"),
          y.Select("Identity"),
          y.Select("CustomId")
        }),
        x.Expand("Building", y => {
          y.Select("Name"),
          y.Select("Id"),
          y.Select("Identity")
        })
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        Utils.mapAllJSONDatesToDates(i);
        return Object.assign(new Incident(), i);
      })))
    ));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleCloseRequest(): void {
    const viewTab = this.route.parent.firstChild.snapshot.paramMap.get('tab');
    const typeTab = this.route.parent.firstChild.snapshot.paramMap.get('subtab');

    if (!typeTab) {
      this.router.navigate(['/dashboard', {outlets: {left: ['board'], right: ['conformity', 'list', {tab: viewTab}]}}]);
    } else {
      this.router.navigate(['/dashboard', {
        outlets: {
          left: ['board'],
          right: ['conformity', 'list', {tab: viewTab, subtab: typeTab}]
        }
      }]);
    }
  }

  handleCreateRequest(data: IncidentCreateOuputData): void {
    this.subscriptions.add(this.incident$.subscribe(res => this.router.navigate(['building', 'wizard', res.Building.Identity, 'defect', res.Id, 'dashboard'])));
  }

  handleSetProcessedRequest(): void {
    this.state.notify(IncidentState.ACTION_CHANGE_INCIDENT);
  }

  handleRefreshRequest(): void {
    this.state.notify(IncidentState.ACTION_CHANGE_INCIDENT);
  }

  handlePostSimpleSaveRequest(buildingUT: string): void {
    this.state.notify(IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT);
  }

  handlePostSaveRequest(buildingUT: string): void {
    this.navigator.provideBackUrl(this.router.routerState.snapshot.url, IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT);
    this.router.navigate(['building', 'wizard', buildingUT, 'document', 'dashboard'], {
      queryParams: {
        type: DocumentType.INCIDENT,
        incidentId: this.incidentUT,
        returnUrl: true
      }, queryParamsHandling: 'merge'
    });
  }

}
