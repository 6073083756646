import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize} from 'rxjs/operators';


// The InfoInterceptor handles the 2 info items (number errors, number active requests) needed for unittests
// and the loading overlay if a call is in progress
@Injectable()
export class InfoInterceptor implements HttpInterceptor {

  constructor(
  ) {
    console.log('C:InfoInterceptor');

    (<any>window).ajaxInProgessCount = 0;
    (<any>window).ajaxErrorCount = 0;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    (<any>window).ajaxInProgessCount++;

    // if loading overlay is hidden, show after 50 ms (short requests don't show loader)
    if ((<any>window).ajaxInProgessCount === 1) {
      (<any>window).lastTimeoutId = setTimeout(function () { $('#loadingoverlay').show(); }, 50);
    }

    let preHandleAutoRefresh: Observable<any> = next.handle(req);

    return preHandleAutoRefresh
      .pipe(catchError(error => {
        (<any>window).ajaxErrorCount++;
        return observableThrowError(error);
      }))
      .pipe(finalize(() => {
        (<any>window).ajaxInProgessCount--;

        // if last ajax call, hide loading overlay
        if (!(<any>window).ajaxInProgessCount) {
          // get last timeout id
          if ((<any>window).lastTimeoutId) {
            clearTimeout((<any>window).lastTimeoutId);
          }
          // hide overlay
          $('#loadingoverlay').hide();
        }
      }));
  }

}
