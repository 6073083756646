import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { PropertyCore } from '../../core/odata/odata.coreapi';
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-equipment-overview',
  templateUrl: './equipment-overview.component.html',
  styleUrls: ['./equipment-overview.component.scss']
})
export class EquipmentOverviewComponent implements OnInit, OnDestroy {
  currentTab: string;
  buildingId: string;
  installEquipment: PropertyCore.Equipment;
  showClose: boolean;
  showMenu: boolean;
  isIncident: boolean;
  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.showClose = this.route.snapshot.data.close;

    this.subscriptions.add(this.route.parent.parent.paramMap.subscribe((params: ParamMap) => (this.buildingId = params.get('id'))));
    this.subscriptions.add(
      this.route.parent.paramMap
        .pipe(switchMap((params: ParamMap) => {
          return from(this.odataCoreService.Equipment.Query()
            .Expand(x => {
              x.Expand("Building"),
                x.Expand("EquipmentType"),
                x.Expand("Room", y =>
                  y.Expand("Floor"))
            })
            .Filter(x =>
              x.EqualsField("Identity", Guid.parse(params.get('id'))))
            .Exec().then(x => x.value))
            .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
        }))
        .subscribe(res => {
          this.installEquipment = res
        }));


    if (!this.route.firstChild) {
      // redirect to route with tabs outlet set to default value
      this.router.navigate([{ outlets: { tab: ['details'] } }], { relativeTo: this.route, queryParamsHandling: 'merge' });
      this.currentTab = 'details';
    } else {
      // activate correct tab
      const path = this.route.firstChild.routeConfig.path;
      this.currentTab = path;
    }

    this.subscriptions.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd), map(() => this.route))
        .subscribe(event => {
          if (!this.route.firstChild) {
            // redirect to route with tabs outlet set to default value
            this.router.navigate([{ outlets: { tab: ['details'] } }], {
              relativeTo: this.route,
              queryParamsHandling: 'merge'
            });
          } else {
            // activate correct tab
            const path = this.route.firstChild.routeConfig.path;
            this.currentTab = path;
          }
        })
    );

    if (this.route.parent.outlet === 'left') {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
    if (this.route.snapshot.parent.firstChild.firstChild) {
      if (this.route.snapshot.parent.firstChild.firstChild.url[0].path === 'incident') {
        this.isIncident = true;
      } else {
        this.isIncident = false;
      }
    }

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (this.route.outlet === 'left') {
      this.router.navigate([
        '/building',
        this.buildingId,
        {
          outlets: {
            left: ['building', 'view', { outlets: { tab: ['equipment'] } }],
            right: ['equipment', this.installEquipment.Identity, 'view', { outlets: { tab: [changeEvent.nextId] } }]
          }
        }
      ], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([{ outlets: { tab: [changeEvent.nextId] } }], { relativeTo: this.route, queryParamsHandling: 'merge' });
    }
  }

  private close(): void {
    this.router.navigate([
      '/building',
      this.buildingId,
      { outlets: { left: ['list'], right: ['building', 'view', { outlets: { tab: ['equipment'] } }] } }
    ], { queryParamsHandling: 'merge' });
  }

}
