import { Observable } from 'rxjs';

import { PropertyCore } from '../../odata/odata.coreapi';

export class BuildingEditUsagesInputData {

  buildingUsageTypes$: Observable<PropertyCore.UsageType[]>;

  building$: Observable<PropertyCore.Building>;
}
