import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {of, Subscription, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';

import { PropertyCore, ObjektBrief} from '../../core/odata/odata.coreapi';
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss']
})

export class ContractDetailComponent implements OnInit, OnDestroy {

  contract: PropertyCore.MaintenanceContract;
  CancellationPeriod = ObjektBrief.MaintenanceContractCancellationPeriods;
  CancellationUnit = ObjektBrief.MaintenanceContractCancellationUnits;
  CancellationCalendar = ObjektBrief.MaintenanceContractCancellationCalendars;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.parent.parent.paramMap.pipe(switchMap((params: ParamMap) => of(params.get('id')))).subscribe(contractId => {
        this.subscriptions.add(from(this.odataCoreService.MaintenanceContract.Query()
          .Expand(x => {
            x.Expand("ServiceProvider")
          })
          .Filter(xa =>
            xa.EqualsField("Identity", Guid.parse(contractId)))
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0]))).subscribe(contract => {
          this.contract = contract;
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
