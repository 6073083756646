import { Observable } from 'rxjs';
import { Common, PropertyCore } from '../../odata/odata.coreapi';
import { EditInspectionDutyGroup } from './edit-inspection-duties/editInspectionDutyGroup';
import { EditOperatorTask } from './edit-inspection-duties/editOperatorTask';


export class EquipmentEditInspectionDutiesInputData {

  installEquipment$: Observable<PropertyCore.Equipment>;
  equipmentOperatorTaskMarkers$: Observable<EditOperatorTask[]>;
  cycleUnits: string[] = Object.keys(Common.IntervalUnits);
  inspectionDutyGroups$: Observable<EditInspectionDutyGroup[]>;
  showClose: boolean = false;
  showHeader: boolean = true;

}
