export enum MandatorWizardNavigationPaths {

  DATA = 'data',
  AGREEMENT = 'agreement',
  ABO = 'abo',
  CONFIRM = 'confirm',
  DONE = 'done',

  NEXT = 'next',
  PREVIOUS = 'previous',
  START = 'start',
  EXIT = 'exit'
}
