import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from './../core/auth/auth.service';
import { AppConfig } from './../core/bootstrap/app.config';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private conf: AppConfig
  ) { }

  ngOnInit() {
    window.location.replace(this.conf.getLogoutUrl());
  }

}
