import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription, from } from "rxjs";
import { map } from "rxjs/operators";
import { PropertyCore } from "../../core/odata/odata.coreapi";
import { EquipmentListInputData } from "../../core/components/equipment/equipment-list-input-data";
import { InstallEquipmentKpiFilter } from "../kpiFilter.enum";
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { GlobalService } from "app/core/shared/global.service";
import { ODataPath } from "../../core/odata/odataclient";
import { Guid } from "guid-typescript";
import { Utils } from "../../core/tools/utils";

@Component({
  selector: "app-equipment-list",
  templateUrl: "./equipment-list.component.html",
  styleUrls: ["./equipment-list.component.scss"],
})
export class EquipmentListComponent implements OnInit, OnDestroy {
  inputData: EquipmentListInputData = new EquipmentListInputData();
  subject: Subject<PropertyCore.Equipment[]> = new Subject();

  buildingUT: string;

  selectedInstallEquipmentUT: string = null;

  private subscriptions: Subscription = new Subscription();
  private ieSubscription: Subscription = new Subscription();

  private presetFilter: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.inputData.showEquipmentTypeData = true;
    this.inputData.equipments$ = from(
      this.odataCoreService.EquipmentType.Query()
        .OrderBy("Name")
        .Exec()
        .then((x) => x.value)
    );
    this.inputData.installEquipments$ = this.subject.asObservable();

    this.subscriptions.add(this.route.queryParamMap.subscribe((params) => (this.presetFilter = +params.get("filter"))));
    if (this.presetFilter) {
      this.inputData.presetFilter = this.presetFilter;
    } else {
      this.inputData.presetFilter = InstallEquipmentKpiFilter.Undefined;
    }

    this.subscriptions.add(
      this.route.root.firstChild.paramMap.subscribe((params) => {
        this.buildingUT = params.get("id");
        this.handleKpiFilterChange(this.inputData.presetFilter);
      })
    );

    this.inputData.currentInstallEquipmentUT$ = this.route.root.firstChild.children[1].paramMap.pipe(
      map((params) => params.get("id"))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.ieSubscription.unsubscribe();
  }

  handleEquipmentWizardRequest() {
    this.router.navigate(["/building", "wizard", this.buildingUT, "equipment"]);
  }

  handleDetailPageRequest(installEquipmentUT: string) {
    this.router.navigate(
      [
        "/building",
        this.buildingUT,
        {
          outlets: {
            left: ["building", "view", { outlets: { tab: ["equipment"] } }],
            right: ["equipment", installEquipmentUT, "view", { outlets: { tab: ["details"] } }],
          },
        },
      ],
      { queryParams: { filter: this.presetFilter }, queryParamsHandling: "merge" }
    );
  }

  handleKpiFilterChange(kpiFilter: InstallEquipmentKpiFilter) {
    if (kpiFilter !== this.presetFilter || kpiFilter !== this.inputData.presetFilter) {
      this.presetFilter = kpiFilter;
      this.inputData.presetFilter = kpiFilter;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { filter: this.presetFilter },
        queryParamsHandling: "merge",
      });
    }

    this.ieSubscription.unsubscribe();

    this.ieSubscription = from(
      this.odataCoreService.Equipment.Query()
        .OrderBy("EquipmentType/Name,CustomId,Name", "asc")
        .Filter((x) => x.Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.buildingUT)))
        .Expand((x) => {
          x.Expand("Building"),
            x.Expand("EquipmentType"),
            x.Expand("Room", (y) => {
              y.Expand("Floor");
            });
        })
        .Exec()
        .then((x) => x.value)
    )
      .pipe(map((res) => res.map((i) => Utils.mapAllJSONDatesToDates(i))))
      .subscribe((x) => {
        this.subject.next(x);
      });
  }
}
