import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {combineLatest, of, Subscription, Observable, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {AuthService} from './../../../core/auth/auth.service';
import {Mandator} from './../../../core/model/mandator';
import {MandatorWizardModel} from './mandator-wizard-model';
import {MandatorWizardNavigationPaths} from './mandator-wizard-navigation-paths.enum';
import {MandatorWizardService} from './mandator-wizard.service';
import { Utils } from '../../../core/tools/utils';
import { ODataCoreService} from '../../../core/odata-services/odata.coreapi.service'
import { Guid } from 'guid-typescript';
import { Common, Operations, ObjektBrief } from '../../../core/odata/odata.coreapi';
import { LoginService } from 'app/core/shared/login.service';
import { GlobalService } from 'app/core/shared/global.service';


@Component({
  selector: 'app-mandator-wizard',
  templateUrl: './mandator-wizard.component.html',
  styleUrls: ['./mandator-wizard.component.scss']
})
export class MandatorWizardComponent implements OnInit, OnDestroy {

  currentPage: MandatorWizardNavigationPaths;

  modelReady: boolean;

  PAGE_DATA = MandatorWizardNavigationPaths.DATA;
  PAGE_AGREEMENT = MandatorWizardNavigationPaths.AGREEMENT;
  PAGE_CONFIRM = MandatorWizardNavigationPaths.CONFIRM;
  PAGE_DONE = MandatorWizardNavigationPaths.DONE;

  subscriptions: Subscription = new Subscription();

  constructor(
    private service: MandatorWizardService,
    private authService: AuthService,
    private loginService: LoginService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService,
    private globalService: GlobalService
  ) {
  }

  ngOnInit() {
    // new mandator
    this.currentPage = this.PAGE_DATA;
    this.service.startWizard();
    this.modelReady = true;

    this.subscriptions.add(
      this.route.paramMap
        .pipe(switchMap((params: ParamMap) => params.get('id') ? from(this.odataCoreService.Account.Query()
          .Filter(x =>
            x.EqualsField("Identity", Guid.parse(params.get('id'))))
          .Expand(x => {
            x.Expand("User"),
              x.Expand("Statistics"),
              x.Expand("Profile"),
              x.Expand("AccountDataProcessingAgreement", z => {
                z.Expand("Country")
              })
          })
          .Exec().then(x => x.value))
          .pipe(map(res => {
            Utils.mapAllJSONDatesToDates(res[0]);
            return Object.assign(new Mandator(), res[0]);
          })) : of(<Mandator>null)))
        .subscribe(mandator => {
          // edit mandator
          if (mandator) {
            // we need to load countries for country data
            this.subscriptions.add(from(this.odataCoreService.Country.Query().OrderBy('Id')
              .Exec().then(x => x.value))
              .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
              .subscribe(countries => {
              this.service.editWizard(mandator, countries.find(c => c.Id === mandator.Profile.Address.CountryInfo.Id));
            }));
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  save(model: MandatorWizardModel): void {

  
      this.odataCoreService.Account.Post()
        .ValueType(this.odataCoreService.ODataTypes().Account())
        .ValueProperty("Identity", model.identity)
        .ValueProperty("Name", model.name)
        .ValueProperty("AccountType", Operations.AccountTypes.FacilityManagement)
        .Exec()  
        .then(res => {
          this.odataCoreService.Account.Get()
          .Key(Guid.parse(res.Identity))
          .NavigateToArray<ObjektBrief.AccountProfile>("Profile")
          .Patch()
          .ValueType(this.odataCoreService.ODataTypes().AccountProfile())
          .ValueProperty("Name", model.mandatorName)
          .ValueProperty("Contact", model.contact)
          .ValueProperty("ContactPhone", model.contactPhone)
          .ValueProperty("ContactMail", model.contactMail)
          .ValueProperty("Address", this.mapAddress(model.address))
          .Exec()
          .then(x => {
        this.toasterService.pop('info', '', this.translateService.instant('CreateMandator._save_success'));

        // change mandator to this new mandator
        this.authService.setMandator(model.identity, true);
        let doCall = this.loginService.saveSettings(AuthService.USER_MANDATOR_CONFIG, model.identity);
        if (model.agreed) {
          // set local storage so that the popup doesn't show up
          localStorage.setItem('data_processing_agreement', 'true');
          // add sign agreement to the call
          doCall = combineLatest(doCall, this.odataCoreService.Account.Get().Key(Guid.parse(model.identity)).Actions().SignDataProcessingAgreementInOperationsOnAccountInOperations().Execute());
        }
        this.subscriptions.add(doCall.subscribe(any => {
          this.processPageChangeRequest(MandatorWizardNavigationPaths.NEXT);
        }));
      })
      });
    
  }

  processPageChangeRequest(pageChangeEvent: MandatorWizardNavigationPaths): void {
    switch (pageChangeEvent) {
      case MandatorWizardNavigationPaths.START: {
        this.currentPage = this.PAGE_DATA;
        break;
      }
      case MandatorWizardNavigationPaths.EXIT: {
        this.router.navigate(['/global', 'mandator']);
        break;
      }
      case MandatorWizardNavigationPaths.NEXT: {
        if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_AGREEMENT;
        } else if (this.currentPage === this.PAGE_AGREEMENT) {
          this.currentPage = this.PAGE_CONFIRM;
        } else if (this.currentPage === this.PAGE_CONFIRM) {
          this.currentPage = this.PAGE_DONE;
        } else if (this.currentPage === this.PAGE_DONE) {
          this.router.navigate(['/building', 'wizard']);
        }
        break;
      }
      case MandatorWizardNavigationPaths.PREVIOUS: {
        if (this.currentPage === this.PAGE_CONFIRM) {
          this.currentPage = this.PAGE_AGREEMENT;
        } else if (this.currentPage === this.PAGE_AGREEMENT) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case MandatorWizardNavigationPaths.DATA: {
        if (this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_AGREEMENT ||  this.currentPage === this.PAGE_CONFIRM) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case MandatorWizardNavigationPaths.AGREEMENT: {
        if (this.currentPage === this.PAGE_AGREEMENT ||  this.currentPage === this.PAGE_CONFIRM) {
          this.currentPage = this.PAGE_AGREEMENT;
        }
        break;
      }
      case MandatorWizardNavigationPaths.CONFIRM: {
        if (this.currentPage === this.PAGE_CONFIRM) {
          this.currentPage = this.PAGE_CONFIRM;
        }
        break;
      }
      case MandatorWizardNavigationPaths.DONE: {
        if (this.currentPage === this.PAGE_DONE) {
          this.currentPage = this.PAGE_DONE;
        }
        break;
      }
    }
  }

  private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};
     if (address.RegionInfo != null && address.RegionInfo.hasOwnProperty('Code')){
      entity.Region = address.RegionInfo['Code'];
    } 
    if (address.CountryInfo != null && address.CountryInfo.hasOwnProperty('Code')) {
      entity.Country = address.CountryInfo['Code'];
    } else {
      entity.Country = 'DE';
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }
}

