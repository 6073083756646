import { Injector, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarEventTitleFormatter } from 'angular-calendar';
import { Incident } from './../../core/model/incident';
import { PropertyCore } from '../../core/odata/odata.coreapi';

@Injectable()
export class CustomCalendarEventTitleFormatter extends CalendarEventTitleFormatter {

  translateService: TranslateService;

  dienstleisterData: any = null;

  constructor(private injector: Injector) {
    super();

    this.translateService = this.injector.get(TranslateService);
  }

  month(event: CalendarEvent<Incident>): string {
    const equipmentData = `<div class="col text-truncate"
                            title="${event.meta.Equipment ? (event.meta.Equipment.CustomId ? event.meta.Equipment.CustomId : event.meta.Equipment.Name) : event.meta.Building.Name}">
                              ${event.meta.Equipment ? (event.meta.Equipment.CustomId ? event.meta.Equipment.CustomId : event.meta.Equipment.Name) : event.meta.Building.Name}
                          </div>`;
    if (event.meta.Schedule != null && event.meta.Schedule.MaintenanceContract != null && event.meta.Schedule.MaintenanceContract.ServiceProvider != null && event.meta.ServiceProvider != null) {
      this.dienstleisterData = `<div class="col text-truncate"
                            title="${event.meta.ServiceProvider ? ('Wartungsvertrag Fa. ' + event.meta.Schedule.MaintenanceContract.ServiceProvider.Name + ' vom ' + event.meta.Schedule.MaintenanceContract.StartDate.toLocaleDateString()) : event.meta.Equipment.Name}">
                              ${event.meta.ServiceProvider ? ('Wartungsvertrag Fa. ' + event.meta.Schedule.MaintenanceContract.ServiceProvider.Name + ' vom ' + event.meta.Schedule.MaintenanceContract.StartDate.toLocaleDateString()) : event.meta.Equipment.Name}
                          </div>`;
    } else {
      this.dienstleisterData = `<div class="col text-truncate" title="${(event.meta.Equipment ? event.meta.Equipment.Name : event.meta.Building.Name)}"> ${(event.meta.Equipment ? event.meta.Equipment.Name : event.meta.Building.Name)} </div>`;
    }
    return `<div class="custom-calendar-event-title">
              <div class="row">
                <div class="col-auto" title="` + this.translateService.instant('EventType.' + event.meta.Type) + `">${this.TypeShort(event)}</div>`
      + (event.cssClass === 'building' ? equipmentData : (event.cssClass === 'equipment' ? (this.dienstleisterData == null ? (event.meta.Equipment ? event.meta.Equipment.Name : event.meta.Building.Name) : this.dienstleisterData) : '')) +
      (`<div class="col text-truncate" title="${event.meta.Guidelines}">${event.meta.Guidelines}</div>`) +
      (event.meta.Schedule ? (`<div class="col-auto" title="${event.meta.Schedule.Cycle}` + ' ' + this.translateService.instant('CycleUnit2.' + event.meta.Schedule.CycleUnit) + `">${event.meta.Schedule.Cycle}` + this.translateService.instant('CycleUnitShort.' + event.meta.Schedule.CycleUnit) + `</div>`) :
        (`<div class="col-auto" title="` + this.translateService.instant('CycleUnit2.NotSpecified') + `">1` + this.translateService.instant('CycleUnitShort.NotSpecified') + `</div>`)) +
      `<div class="col-auto" title="` + this.translateService.instant('IncidentStatus.' + this.Status(event)) + `">` + this.translateService.instant('IncidentStatus.' + this.Status(event)) + `</div>
              </div>
            </div>`;

  }


   TypeShort(event: CalendarEvent<Incident>): string {
    switch (event.meta.Type) {
      case PropertyCore.EventTypes.Inspection:
      case PropertyCore.EventTypes.FollowUpInspection:
        return 'P';
      case PropertyCore.EventTypes.Maintenance:
        return 'W';
      case PropertyCore.EventTypes.Repair:
        return 'I';
      case PropertyCore.EventTypes.Custom:
        return 'S';
    }
    return '';
  }

   Status(event: CalendarEvent<Incident>) {
     if (event.meta.IsSkipped) {
      return IncidentStatus.IsSkipped;
    }
     else if (event.meta.ClosedDate) {
      return IncidentStatus.Closed;
    }
     else if (event.meta.KpiMarker != null && event.meta.KpiMarker.IsDone) {
      return IncidentStatus.Executed;
    }
     else if (event.meta.KpiMarker != null && event.meta.KpiMarker.IsOverdue) {
      return IncidentStatus.Due;
    }
    return IncidentStatus.Open;
  }



}
enum IncidentStatus {
  IsSkipped = 'IsSkipped',
  Closed = 'Closed',
  Executed = 'Executed',
  Due = 'Due',
  Open = 'Open'
}
