import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, from } from 'rxjs';
import { PropertyCore } from '../../../../core/odata/odata.coreapi';
import { EquipmentWizardNavigationPaths } from '../equipment-wizard-navigation-paths.enum';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { EquipmentWizardModel } from './../equipment-wizard-model';
import { EquipmentWizardService } from './../equipment-wizard.service';
import { Utils } from '../../../../core/tools/utils';
import { map } from 'rxjs/operators';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';


@Component({
  selector: 'app-equipment-wizard-type',
  templateUrl: './equipment-wizard-type.component.html',
  styleUrls: ['./equipment-wizard-type.component.scss']
})
export class EquipmentWizardTypeComponent implements OnInit, OnDestroy {

  model: EquipmentWizardModel;

  costGroups: PropertyCore.CostGroup[];

  equipmentFilter: any = '';
  costGroupFilter: string = '';

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<EquipmentWizardNavigationPaths>();

  constructor(
    private service: EquipmentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.model = this.service.getCurrentEquipment();
    this.subscriptions.add(from(this.odataCoreService.CostGroup.Query()
      .OrderBy("No")
      .Filter(x => x
        .NotEquals("Id", 30))
      .Expand(x => {
        x.Expand("EquipmentTypes")
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.costGroups = res));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(equipment: PropertyCore.EquipmentType): void {
    if (!equipment) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.NEXT);
  }

  selectEquipment(equipment: PropertyCore.EquipmentType): void {
    if (!this.model.equipment || (equipment.Id !== this.model.equipment.Id)) {
      this.model.equipment = equipment;
      this.model.attributesCategories = null;
    }
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('EquipmentWiz._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('EquipmentWiz._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('EquipmentWiz._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('EquipmentWiz._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentEquipment();
            this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.EXIT);
    }
  }

}
