import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {forkJoin, of, Subscription, from} from 'rxjs';
import {PropertyCore} from '../../../core/odata/odata.coreapi';
import {IncidentWizardNavigationPaths} from './incident-wizard-navigation-paths.enum';
import {IncidentWizardService} from './incident-wizard.service';
import { Utils } from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { map } from 'rxjs/operators';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service"


@Component({
  selector: 'app-incident-wizard',
  templateUrl: './incident-wizard.component.html',
  styleUrls: ['./incident-wizard.component.scss']
})
export class IncidentWizardComponent implements OnInit, OnDestroy {

  equipmentId: string;
  buildingId: string;
  navigateBack: string;

  currentPage: IncidentWizardNavigationPaths;

  modelReady: boolean;

  PAGE_DATA = IncidentWizardNavigationPaths.DATA;
  PAGE_EQUIPMENT = IncidentWizardNavigationPaths.EQUIPMENT;
  PAGE_CONTACT = IncidentWizardNavigationPaths.CONTACT;
  PAGE_ADD_SERVICE_CONTACT = IncidentWizardNavigationPaths.ADD_SERVICE_CONTACT;

  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private service: IncidentWizardService,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.paramMap.subscribe((params: ParamMap) => this.buildingId = params.get('id')));
    this.subscriptions.add(this.route.paramMap.subscribe((params: ParamMap) => {
      this.equipmentId = params.get('id');
      let observable = of(<PropertyCore.Equipment>null);
      if (this.equipmentId) {
        observable =  from(this.odataCoreService.Equipment.Query()
          .Expand(x => {
            x.Expand("Building"),
              x.Expand("EquipmentType"),
              x.Expand("Room", y =>
                y.Expand("Floor"))
          })
          .Filter(x =>
            x.EqualsField("Identity", Guid.parse(this.equipmentId)))
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      }
      this.subscriptions.add(forkJoin(from(this.odataCoreService.Building.Get()
        .Key(Guid.parse(this.buildingId))
        .Expand(x => {
          x.Expand("UsageTypes");
          x.Expand("Statistics");
        })
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0]))), observable).subscribe(([b, ie]) => {
        this.service.startWizard(b.Identity, b.Name, ie);
        this.modelReady = true;
      }));
    }));
    this.navigateBack = this.route.snapshot.data.navigateBack;
    this.currentPage = this.PAGE_DATA;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  processPageChangeRequest(pageChangeEvent: IncidentWizardNavigationPaths): void {

    switch (pageChangeEvent) {

      case IncidentWizardNavigationPaths.START: {
        this.currentPage = this.PAGE_DATA;
        break;
      }
      case IncidentWizardNavigationPaths.EXIT: {
        if (this.navigateBack && this.navigateBack === 'building' && this.buildingId) {
          const model = this.service.getCurrentIncident();
          if (model.id) {
            if (model.equipment) {
              this.router.navigate(['/building', this.buildingId, {
                outlets: {
                  left: ['equipment', model.equipment.Identity, 'view', {outlets: {tab: ['incident']}}],
                  right: ['equipment', 'incident', model.id]
                }
              }]);
            } else {
              this.router.navigate(['/building', this.buildingId, {
                outlets: {
                  left: ['building', 'view', {outlets: {tab: ['incident']}}],
                  right: ['incident', model.id]
                }
              }]);
            }
          } else {
            if (this.equipmentId) {
              this.router.navigate(['/building', this.buildingId, {
                outlets: {
                  left: ['building', 'view', {outlets: {tab: ['equipment']}}],
                  right: ['equipment', this.equipmentId, 'view', {outlets: {tab: ['incident']}}]
                }
              }]);
            } else {
              this.router.navigate(['/building', this.buildingId, {
                outlets: {
                  left: ['list'],
                  right: ['building', 'view', {outlets: {tab: ['incident']}}]
                }
              }]);
            }
          }
        } else {
          console.error('Could not find a navigation route for incident wizard!');
        }
        break;
      }
      case IncidentWizardNavigationPaths.NEXT: {
        if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_EQUIPMENT;
        } else if (this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_CONTACT;
        }
        break;
      }
      case IncidentWizardNavigationPaths.PREVIOUS: {
        if (this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_DATA;
        } else if (this.currentPage === this.PAGE_CONTACT) {
          this.currentPage = this.PAGE_EQUIPMENT;
        }
        break;
      }
      case IncidentWizardNavigationPaths.DATA: {
        if (this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_EQUIPMENT || this.currentPage === this.PAGE_CONTACT || this.currentPage === this.PAGE_ADD_SERVICE_CONTACT) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case IncidentWizardNavigationPaths.EQUIPMENT: {
        if (this.currentPage === this.PAGE_EQUIPMENT || this.currentPage === this.PAGE_CONTACT || this.currentPage === this.PAGE_ADD_SERVICE_CONTACT) {
          this.currentPage = this.PAGE_EQUIPMENT;
        }
        break;
      }
      case IncidentWizardNavigationPaths.CONTACT: {
        if (this.currentPage === this.PAGE_CONTACT || this.currentPage === this.PAGE_ADD_SERVICE_CONTACT) {
          this.currentPage = this.PAGE_CONTACT;
        }
        break;
      }
      case IncidentWizardNavigationPaths.ADD_SERVICE_CONTACT: {
        if (this.currentPage === this.PAGE_CONTACT || this.currentPage === this.PAGE_ADD_SERVICE_CONTACT) {
          this.currentPage = this.PAGE_ADD_SERVICE_CONTACT;
        }
        break;
      }
    }
  }

  saveNewServiceContact(serviceContact: PropertyCore.ServiceProvider) {
    this.service.getCurrentIncident().contact = serviceContact;
    this.processPageChangeRequest(IncidentWizardNavigationPaths.CONTACT);
  }

}
