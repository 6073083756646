import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: any): string | null {
    value = typeof value === 'string' ? new Date(value) : value;
    if (value && value instanceof Date && !isNaN(value.getTime())) {
      return super.transform(value, 'dd.MM.yyyy HH:mm');
    }
    return null;
  }
}
