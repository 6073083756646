import { AfterViewChecked, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[scrollToListItem]'
})
export class ScrollToListItemDirective implements AfterViewChecked {

  private done = false;

  constructor(private _elementRef: ElementRef) {}

  ngAfterViewChecked() {
    if (!this.done) {
      const activeListItems = this._elementRef.nativeElement.getElementsByClassName('active');
      if (activeListItems.length && activeListItems.item(0).tagName === 'LI' && activeListItems.item(0).clientHeight) {
        const activeListItem = activeListItems.item(0);
        this.done = true;
        activeListItem.scrollIntoView();
        // we are not at the end of the list, so center vertically
        if (this._elementRef.nativeElement.scrollTop > (activeListItem.offsetTop - this._elementRef.nativeElement.clientHeight)) {
          this._elementRef.nativeElement.scrollTop = this._elementRef.nativeElement.scrollTop - (this._elementRef.nativeElement.clientHeight / 2) + (activeListItem.clientHeight / 2);
        }
      }
    }
  }
}
