import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { combineLatest, Subscription, from } from "rxjs";
import { PropertyCore } from "../../../odata/odata.coreapi";
import { AuthService } from "./../../../auth/auth.service";
import { FilterByPipe } from "./../../../pipes/filter.pipe";
import { BuildingListInputData } from "./../building-list-input-data";
import { Guid } from "guid-typescript";
import { Utils } from "../../../tools/utils";
import { Mandator } from "../../../model/mandator";
import { map } from "rxjs/operators";
import { ODataCoreService } from "../../../odata-services/odata.coreapi.service";
import * as L from "leaflet";

@Component({
  selector: "app-core-building-list",
  templateUrl: "./core-building-list.component.html",
  styleUrls: ["./core-building-list.component.scss"],
})
export class CoreBuildingListComponent implements OnInit, OnDestroy {
  @Input() inputData: BuildingListInputData;

  @Output() detailPageRequest = new EventEmitter<PropertyCore.Building>();
  @Output() buildingWizardRequest = new EventEmitter<void>();
  @Output() mapMarkerRequest = new EventEmitter<string>();
  @Output() detailPageButtonRequest = new EventEmitter<PropertyCore.Building>();
  @Output() listEmptyRequest = new EventEmitter<void>();

  buildings: PropertyCore.Building[];
  filteredBuildings: PropertyCore.Building[];
  testItems = ["aaaaa", "bbbbbb", "cccccc", "dddddd", "eeeeee"];
  currentBuildingUT: string;

  lat: number = 48.1357;
  lng: number = 11.53002;
  initialZoom = 8;
  selectedZoom = 12;

  leafletmap: L.Map;
  markerGroup: L.FeatureGroup;
  selectedMarkerGroup: L.FeatureGroup;
  mapSelectedIcon = L.icon({
    iconUrl: "assets/marker_selected.png",
    popupAnchor: [12, 0],
  });

  mapIcon = L.icon({
    iconUrl: "assets/marker.png",
  });

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private filterByPipe: FilterByPipe,
    private odataCoreService: ODataCoreService
  ) {}

  private loadMap(): void {
    try {
      this.leafletmap = L.map("leafletmap", { attributionControl: false }).setView([this.lat, this.lng], this.initialZoom);
      this.markerGroup = L.featureGroup();
      this.selectedMarkerGroup = L.featureGroup();

      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
      }).addTo(this.leafletmap);
    } catch (e) {
      // ignore: leaflet element not visible
    }
  }

  private addMapMarker(buildings: PropertyCore.Building[]): void {
    if (!this.leafletmap || this.leafletmap.hasLayer(this.markerGroup)) {
      return;
    }

    buildings.forEach((building) => {
      const marker = L.marker([building.Location.Latitude, building.Location.Longitude], {
        icon: this.mapIcon,
      });

      marker.on("click", () => {
        this.mapMarkerRequest.emit(building.Identity);
      });

      marker.addTo(this.markerGroup);
    });

    this.markerGroup.addTo(this.leafletmap);
    this.markerGroup.bringToBack();
  }

  private addSelectedMapMarker(building: PropertyCore.Building): void {
    if (!this.leafletmap) {
      return;
    }

    if (this.leafletmap.hasLayer(this.selectedMarkerGroup)) {
      this.selectedMarkerGroup.clearLayers();
    }

    const marker = L.marker([building.Location.Latitude, building.Location.Longitude], {
      icon: this.mapSelectedIcon,
    });

    marker.addTo(this.selectedMarkerGroup);

    this.leafletmap.flyTo([building.Location.Latitude, building.Location.Longitude], this.selectedZoom);

    this.selectedMarkerGroup.addTo(this.leafletmap);
    this.selectedMarkerGroup.bringToFront();
  }

  ngOnInit() {
    this.subscriptions.add(
      combineLatest(this.inputData.buildings$, this.inputData.currentBuildingUT$).subscribe(([b, bUT]) => {
        this.buildings = b;
        this.filteredBuildings = this.buildings;
        this.currentBuildingUT = bUT;

        if (!this.leafletmap) {
          this.loadMap();
        }

        if (!this.buildings || this.buildings.length === 0) {
          this.listEmptyRequest.emit();
          return;
        }

        this.addMapMarker(this.buildings);

        if (this.currentBuildingUT) {
          this.inputData.lastIdx = this.filteredBuildings.findIndex((b) => b.Identity === this.currentBuildingUT);
        }

        if (this.inputData.navigateToFirstListItem && !this.currentBuildingUT) {
          const idx =
            this.inputData.lastIdx === -1
              ? 0
              : this.inputData.lastIdx >= this.filteredBuildings.length
              ? this.filteredBuildings.length - 1
              : this.inputData.lastIdx;
          this.onListItemClick(this.filteredBuildings[idx]);
        }

        const building = this.filteredBuildings.find((x) => x.Identity === this.currentBuildingUT);

        // selected building
        if (building) {
          this.filteredBuildings = this.filterByPipe.transform(this.buildings, this.inputData.searchFilter, [
            "Name",
            "Address.Street",
            "Address.PostCode",
            "Address.City",
          ]);

          if (this.inputData.showMap) {
            // center selected building
            if (this.leafletmap && building.Location.Latitude && building.Location.Longitude) {
              this.addSelectedMapMarker(building);
            }
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    // this.leafletmap.remove();
  }

  buildingWizard(): void {
    this.subscriptions.add(
      from(
        this.odataCoreService.Account.Query()
          .Expand((x) => {
            x.Expand("User"), x.Expand("Statistics"), x.Expand("Profile");
          })
          .Filter((x) => x.EqualsField("Identity", Guid.parse(localStorage.getItem("mandator"))))
          .Exec()
          .then((x) => x.value)
      )
        .pipe(
          map((res) => {
            Utils.mapAllJSONDatesToDates(res[0]);
            return Object.assign(new Mandator(), res[0]);
          })
        )
        .subscribe((mandator) => {
          this.buildingWizardRequest.emit();
        })
    );
  }

  onListItemClick(building: PropertyCore.Building): void {
    this.currentBuildingUT = building.Identity;
    if (this.currentBuildingUT) {
      this.inputData.lastIdx = this.filteredBuildings.findIndex((b) => b.Identity === this.currentBuildingUT);
    }
    this.detailPageRequest.emit(building);
  }

  onListItemButtonClick(building: PropertyCore.Building): void {
    this.detailPageButtonRequest.emit(building);
  }

  onMarkerClick(building: PropertyCore.Building): void {
    this.mapMarkerRequest.emit(building.Identity);
  }

  isSelected(UT: string) {
    return this.currentBuildingUT === UT;
  }
}
