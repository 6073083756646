import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AuthGuard } from './auth-guard.service';
import { AuthInterceptor } from './auth.interceptor';
import { InfoInterceptor } from './auth.infointerceptor';
import { AuthService } from './auth.service';
import { GlobalErrorHandler } from './global-error.handler';

@NgModule({
  imports: [
    CommonModule,
    OAuthModule.forRoot(
      {
        resourceServer: {
          //allowedUrls: [
          //  //''
          //],
          sendAccessToken: false
        }
      }
    ),
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InfoInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    AuthGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ]
})
export class AuthModule {}
