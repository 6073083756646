import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {IntegrityBoxComponent} from '../../../dashboard-boxes/integrity-box/integrity-box.component';
import {ContractHandlingInputData} from '../../shared/equipments-contract-handling/contract-handling-input-data';
import {EquipmentsNoContractState} from '../equipments-no-contract-state.service';
import { Utils } from '../../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';


@Component({
  selector: 'app-equipment-set-contract',
  templateUrl: './equipment-set-contract.component.html',
  styleUrls: ['./equipment-set-contract.component.scss']
})
export class EquipmentSetContractComponent implements OnInit, OnDestroy {
  inputData = new ContractHandlingInputData();

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private state: EquipmentsNoContractState,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.inputData.contracts$ = from(this.odataCoreService.MaintenanceContract.Query()
      .Expand(x => {
        x.Expand("ServiceProvider")
      })
      .OrderBy("Title", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))));

    // this.inputData.installEquipment$ = this.route.parent.firstChild.paramMap.switchMap((params: ParamMap) =>
    this.inputData.installEquipment$ = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      from(this.odataCoreService.Equipment.Query()
      .Expand(x => {
        x.Expand("Building"),
          x.Expand("EquipmentType"),
          x.Expand("Room", y =>
            y.Expand("Floor"))
      })
      .Filter(x =>
        x.EqualsField("Identity", Guid.parse(params.get('id'))))
      .Exec().then(x => x.value))
      .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
    ));

    this.inputData.navigateBackUrlTree = this.router.createUrlTree([
      '/dashboard',
      {
        outlets: {
          left: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_NO_CONTRACT],
          right: ['equipment', this.route.snapshot.paramMap.get('id'), 'contract', 'choose']
        }
      }
    ], {queryParamsHandling: 'merge'});

    this.inputData.contractTerminationMandatory = true;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleSaveRequest() {
    this.state.notify(EquipmentsNoContractState.ACTION_SET_CONTRACT);
  }
}
