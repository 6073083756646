import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbAccordion, NgbPanelChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Subscription, combineLatest } from "rxjs";
import { PropertyCore } from "../../../../model/odata";
import { Incident } from "./../../../../model/incident";
import { FilterByPipe } from "./../../../../pipes/filter.pipe";
import { IncidentDetailLinkOutputData } from "./../../incident-detail-link-output-data";
import { IncidentType } from "./../../incident-type";

@Component({
  selector: "app-incident-tab",
  templateUrl: "./incident-tab.component.html",
  styleUrls: ["./incident-tab.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class IncidentTabComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(NgbAccordion)
  acc: NgbAccordion;

  @Input()
  incidents: Incident[];

  @Input()
  selectedId: number;

  @Input()
  currentTab: IncidentType;

  @Input()
  showBuildingData: boolean;

  @Input()
  showEquipmentData: boolean;

  @Input()
  variableScrollHeight: string;

  @Output()
  detailPageRequest = new EventEmitter<IncidentDetailLinkOutputData>();

  @Output()
  typeTabChangeRequest = new EventEmitter<IncidentType>();

  searchFilter: string;

  serviceProviderSearchFilter: string = "";

  EventTypes = PropertyCore.EventTypes;

  subscriptions: Subscription = new Subscription();

  constructor(private filterByPipe: FilterByPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["currentTab"] &&
      changes["currentTab"].currentValue != undefined &&
      changes["currentTab"].currentValue != ""
    ) {
      this.acc?.expand(changes["currentTab"].currentValue);
    }
  }

  ngOnInit() {
    if (!this.currentTab) {
      this.currentTab = IncidentType.NONE;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  serviceProviderNames(incidents: Incident[]): string[] {
    if (!incidents) {
      return [];
    }
    return [...new Set(incidents.filter((value) => value.ServiceProvider).map((value) => value.ServiceProvider.Name))];
  }

  linkToIncidentDetail(id: number): void {
    const requestData = new IncidentDetailLinkOutputData();
    this.selectedId = id;
    requestData.id = id;
    requestData.type = this.currentTab;
    this.detailPageRequest.emit(requestData);
  }

  onPanelChange($event: NgbPanelChangeEvent) {
    this.currentTab = IncidentType.getIncidentType($event.panelId);
    this.typeTabChangeRequest.emit(this.currentTab);
  }

  isSelected(id: number) {
    return this.selectedId === id;
  }
}
