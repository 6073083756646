import {DocumentStatus, Incident} from '../../../core/model/incident';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {PropertyCore} from '../../../core/odata/odata.coreapi';
import {NavigatorService} from '../../../core/shared/navigator.service';
import {DocumentType, DocumentWizardModel} from './document-wizard-model';
import {DocumentWizardNavigationPaths} from './document-wizard-navigation-paths.enum';
import {DocumentWizardService} from './document-wizard.service';
import { Utils } from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service"


@Component({
  selector: 'app-document-wizard',
  templateUrl: './document-wizard.component.html',
  styleUrls: ['./document-wizard.component.scss']
})
export class DocumentWizardComponent implements OnInit, OnDestroy {

  model: DocumentWizardModel;

  navigateBack: string;
  returnUrl: string;

  currentPage: DocumentWizardNavigationPaths;

  modelReady: boolean;

  PAGE_DATA = DocumentWizardNavigationPaths.DATA;
  PAGE_TYPE = DocumentWizardNavigationPaths.TYPE;
  PAGE_EQUIPMENT = DocumentWizardNavigationPaths.EQUIPMENT;
  PAGE_INCIDENT = DocumentWizardNavigationPaths.INCIDENT;
  PAGE_INCIDENT_DATA = DocumentWizardNavigationPaths.INCIDENT_DATA;

  subscriptions: Subscription = new Subscription();

  constructor(
    private service: DocumentWizardService,
    private route: ActivatedRoute,
    private router: Router,
    private navigator: NavigatorService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => {
      return from(this.odataCoreService.Building.Get()
        .Key(Guid.parse(params.get('id')))
        .Expand(x => {
          x.Expand("UsageTypes");
          x.Expand("Statistics");
        })
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      }))
      .subscribe(building => {
        this.service.startWizard(building);
        this.modelReady = true;

        this.model = this.service.getCurrentDocument();

        this.subscriptions.add(this.route.queryParams.subscribe(params => {

          // set type if it exists
          const type = +params['type'] || null;
          if (type) {
            // check if such enum exists
            for (const dt in DocumentType) {
              if (!isNaN(+dt) && +dt === type) {
                this.model.type = +dt;
                this.model.typeFromUrl = true;
              }
            }
          }

          // set document type if it exists
          const documentType = +params['documentType'];
          if (this.model.type && documentType) {
            switch (this.model.type) {
              case DocumentType.BUILDING:
                this.subscriptions.add(from(this.odataCoreService.DocumentTypeGroup.Query()
                  .Filter(x =>
                    x.EqualsField("Id", 3))
                  .Expand(x => {
                    x.Expand("DocumentTypes")
                  })
                  .Exec().then(x => x.value))
                  .pipe(map(res => res[0].DocumentTypes.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.setDocumentType(res, documentType)));
                break;
              case DocumentType.EQUIPMENT:
                this.subscriptions.add(from(this.odataCoreService.DocumentTypeGroup.Query()
                  .Filter(x =>
                    x.EqualsField("Id", 2))
                  .Expand(x => {
                    x.Expand("DocumentTypes")
                  })
                  .Exec().then(x => x.value))
                  .pipe(map(res => res[0].DocumentTypes.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.setDocumentType(res, documentType)));
                break;
              case DocumentType.INCIDENT:
                this.subscriptions.add(from(this.odataCoreService.DocumentTypeGroup.Query()
                  .Filter(x =>
                    x.EqualsField("Id", 1))
                  .Expand(x => {
                    x.Expand("DocumentTypes")
                  })
                  .Exec().then(x => x.value))
                  .pipe(map(res => res[0].DocumentTypes.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.setDocumentType(res, documentType)));
                break;
            }
          }

          // set anlage if it exists
          const ieUT = params['ieUT'];
          if (ieUT) {
            this.subscriptions.add(from(this.odataCoreService.Equipment.Query()
              .Expand(x => {
                x.Expand("Building"),
                  x.Expand("EquipmentType"),
                  x.Expand("Room", y =>
                    y.Expand("Floor"))
              })
              .Filter(x =>
                x.EqualsField("Identity", Guid.parse(ieUT)))
              .Exec().then(x => x.value))
              .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
              .subscribe(res => {
              if (res.Building.Identity === this.model.building.Identity) {
                this.model.equipments = [res];
              }
            }));
          }

          // set incident if it exists
          const incidentId = params['incidentId'];
          if (incidentId) {
            this.subscriptions.add(from(this.odataCoreService.Event.Query()
              .Expand(x => {
                x.Expand("Response"),
                  x.Expand("Schedule", xa => {
                    xa.Expand("InspectionDuty", xaa => {
                      xaa.Select("Id"),
                        xaa.Expand("OperatorTask", xaaa => {
                          xaaa.Select("Guidelines"),
                            xaaa.Select("Qualification")
                        })
                    }),
                      xa.Expand("MaintenanceContract", xab => {
                        xab.Select("StartDate"),
                          xab.Expand("ServiceProvider", xabb => {
                            xabb.Select("Name")
                          })
                      })
                  }),
                  x.Expand("Building", xb => {
                    xb.Select("Name"),
                      xb.Select("Id"),
                      xb.Select("Identity")
                  }),
                  x.Expand("Equipment", xc => {
                    xc.Select("Name"),
                      xc.Select("Id"),
                      xc.Select("Identity"),
                      xc.Select("CustomId"),
                      xc.Expand("Room", xcc => {
                        xcc.Select("Name"),
                          xcc.Select("Id"),
                          xcc.Select("Identity")
                        xcc.Expand("Floor", xccc => {
                          xccc.Select("Id"),
                            xccc.Select("Name")
                        })
                      })
                  }),
                  x.Expand("ServiceProvider", xd => {
                    xd.Select("Name"),
                      xd.Select("Id"),
                      xd.Select("Identity")
                  }),
                  x.Expand("Module", xe => {
                    xe.Select("Name"),
                      xe.Select("Id"),
                      xe.Select("Identity"),
                      xe.Expand("ModuleType", xee => {
                        xee.Select("Code"),
                          xee.Select("Id"),
                          xee.Select("Name")
                      })
                  }),
                  x.Expand("Documents", xe => {
                    xe.Select("DocumentType"),
                      xe.Expand("DocumentType", xea => {
                        xea.Select("Id"),
                          xea.Select("Name")
                      })
                  }),
                x.Expand("KpiMarker")
              })
              .Filter(x => x
                .EqualsField("Id", (+incidentId)))
              .Exec().then(x => x.value))
              .pipe(map(res => {
                Utils.mapAllJSONDatesToDates(res[0]);
                return Object.assign(new Incident(), res[0])
              })).subscribe(res => {
              if (res.Building.Identity === this.model.building.Identity) {
                this.model.incidents = [res];
                // get required document type
                this.subscriptions.add(from(this.odataCoreService.DocumentTypeGroup.Query()
                  .Filter(x =>
                    x.EqualsField("Id", 1))
                  .Expand(x => {
                    x.Expand("DocumentTypes")
                  })
                  .Exec().then(x => x.value))
                  .pipe(map(res => res[0].DocumentTypes.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res2 => {
                  if (res.documentOfferStatus === DocumentStatus.MissingRequired) {
                    this.model.requiredDocumentTypeId = 1; // Angebot
                  } else if (res.documentCommissionStatus === DocumentStatus.MissingRequired) {
                    this.model.requiredDocumentTypeId = 2; // Beauftragung
                  } else if (res.documentProtocolStatus === DocumentStatus.MissingRequired) {
                    this.model.requiredDocumentTypeId = 3; // Protokoll
                  } else if (res.documentReportStatus === DocumentStatus.MissingRequired) {
                    this.model.requiredDocumentTypeId = 4; // Regiebericht
                  } else if (res.documentInvoiceStatus === DocumentStatus.MissingRequired) {
                    this.model.requiredDocumentTypeId = 5; // Rechnung
                  }
                  if (this.model.requiredDocumentTypeId) {
                    this.model.DocumentType = res2.find(x => x.Id === this.model.requiredDocumentTypeId);
                  }
                }));
              }
            }));
          }

          // set returnUrl if it exists
          const returnUrl = params['returnUrl'];
          if (returnUrl) {
            this.returnUrl = this.navigator.getBackUrl();
          }
        }));
      }));

    this.navigateBack = this.route.snapshot.data.navigateBack;
    this.currentPage = this.PAGE_DATA;
  }

  private setDocumentType(types: PropertyCore.DocumentType[], typeId: number): void {
    this.model.DocumentType = types.find(x => x.Id === typeId);
    this.model.documentTypeFromUrl = true;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  processPageChangeRequest(pageChangeEvent: DocumentWizardNavigationPaths): void {
    switch (pageChangeEvent) {

      case DocumentWizardNavigationPaths.START: {
        this.currentPage = this.PAGE_DATA;
        break;
      }
      case DocumentWizardNavigationPaths.EXIT: {
        if (this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl);
        } else {
          switch (this.navigateBack) {
            case 'dashboard':
              this.router.navigate(['dashboard']);
              break;
            case 'building':
              this.router.navigate(['/building', this.model.building.Identity, {
                outlets: {
                  left: ['list'],
                  right: ['building', 'view', {outlets: {'tab': ['incident']}}]
                }
              }]);
              break;
            default:
              if (this.model.type === DocumentType.BUILDING || this.model.type === DocumentType.EQUIPMENT) {
                this.router.navigate(['/building', this.model.building.Identity, {
                  outlets: {
                    left: ['list'],
                    right: ['document', {outlets: {'tab': ['building-equipment']}}]
                  }
                }]);
              } else {
                this.router.navigate(['/building', this.model.building.Identity, {
                  outlets: {
                    left: ['list'],
                    right: ['document', {outlets: {'tab': ['incident']}}]
                  }
                }]);
              }
              break;
          }
        }
        break;
      }
      case DocumentWizardNavigationPaths.NEXT: {
        if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_TYPE;
        } else if (this.currentPage === this.PAGE_TYPE) {
          if (this.model.type === DocumentType.EQUIPMENT) {
            this.currentPage = this.PAGE_EQUIPMENT;
          } else if (this.model.type === DocumentType.INCIDENT) {
            this.currentPage = this.PAGE_INCIDENT;
          }
        } else if (this.currentPage === this.PAGE_INCIDENT) {
          this.currentPage = this.PAGE_INCIDENT_DATA;
        }
        break;
      }
      case DocumentWizardNavigationPaths.PREVIOUS: {
        if (this.currentPage === this.PAGE_INCIDENT_DATA) {
          this.currentPage = this.PAGE_INCIDENT;
        } else if (this.currentPage === this.PAGE_INCIDENT || this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_TYPE;
        } else if (this.currentPage === this.PAGE_TYPE) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case DocumentWizardNavigationPaths.DATA: {
        if (this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_TYPE || this.currentPage === this.PAGE_EQUIPMENT || this.currentPage === this.PAGE_INCIDENT || this.currentPage === this.PAGE_INCIDENT_DATA) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case DocumentWizardNavigationPaths.TYPE: {
        if (this.currentPage === this.PAGE_TYPE || this.currentPage === this.PAGE_EQUIPMENT || this.currentPage === this.PAGE_INCIDENT || this.currentPage === this.PAGE_INCIDENT_DATA) {
          this.currentPage = this.PAGE_TYPE;
        }
        break;
      }
      case DocumentWizardNavigationPaths.EQUIPMENT: {
        if (this.currentPage === this.PAGE_EQUIPMENT) {
          this.currentPage = this.PAGE_EQUIPMENT;
        }
        break;
      }
      case DocumentWizardNavigationPaths.INCIDENT: {
        if (this.currentPage === this.PAGE_INCIDENT || this.currentPage === this.PAGE_INCIDENT_DATA) {
          this.currentPage = this.PAGE_INCIDENT;
        }
        break;
      }
      case DocumentWizardNavigationPaths.INCIDENT_DATA: {
        if (this.currentPage === this.PAGE_INCIDENT_DATA) {
          this.currentPage = this.PAGE_INCIDENT_DATA;
        }
        break;
      }
    }
  }
}
