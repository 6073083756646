import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {IntegrityBoxComponent} from '../../../dashboard-boxes/integrity-box/integrity-box.component';
import {ContractHandlingInputData} from '../../shared/equipments-contract-handling/contract-handling-input-data';
import { Utils } from '../../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../../core/odata/odataclient';


@Component({
  selector: 'app-equipments-contracts-set-termination',
  templateUrl: './equipments-contracts-set-termination.component.html',
  styleUrls: ['./equipments-contracts-set-termination.component.scss']
})
export class EquipmentsContractsSetTerminationComponent implements OnInit, OnDestroy {

  inputData = new ContractHandlingInputData();

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {

    this.inputData.contracts$ = this.route.paramMap
        .pipe(switchMap((params: ParamMap) => {
            return from(this.odataCoreService.MaintenanceJob.Query()
          .Filter(x => x.Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(params.get('id'))))
          .Expand(x => {
            x.Expand("Contract", y => {
              y.Expand("ServiceProvider")
            })
          })
                .Exec().then(x => x.value))
                .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i.Contract))));
      }),
        map(cs => {
          return cs.filter(c => !c.StartDate);
        }));

    this.inputData.installEquipment$ = this.route.paramMap
      .pipe(switchMap((params: ParamMap) =>
        from(this.odataCoreService.Equipment.Query()
        .Expand(x => {
          x.Expand("Building"),
            x.Expand("EquipmentType"),
            x.Expand("Room", y =>
              y.Expand("Floor"))
        })
        .Filter(xa =>
          xa.EqualsField("Identity", Guid.parse(params.get('id'))))
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      ));

    this.inputData.navigateBackUrlTree = this.router.createUrlTree([
      '/dashboard',
      {
        outlets: {
          left: ['board'],
          right: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_CONTRACT_NOT_SCHEDULED]
        }
      }
    ], {queryParamsHandling: 'merge'});

    this.inputData.contractTerminationMandatory = true;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
