import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { InspectiondutyWizardComponent } from "../inspectionduty-wizard.component";
import { InspectiondutyWizardModel } from "../inspectionduty-wizard-model";
import { Equipment } from "app/core/model/equipmentOperatorTask";
import { InspectiondutyWizardNavigationPaths } from "../inspectionduty-wizard-navigation-paths";
import { ConfirmationModal } from "app/core/popup/confirmation.modal";
import { InspectiondutyWizardService } from "../inspectionduty-wizard-service";
import {
  NgbModal,
  NgbModalModule,
  NgbPanelChangeEvent,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ODataCoreService } from "app/core/odata-services/odata.coreapi.service";
import {
  Common,
  PropertyCore,
  Operations,
  ObjektBrief,
} from "app/core/odata/odata.coreapi";
import { EditInspectionDutyGroup } from "app/core/components/equipment/edit-inspection-duties/editInspectionDutyGroup";
import { from, Subscription, zip } from "rxjs";
import { Guid } from "guid-typescript";
import { EquipmentNoInspectionDutiesState } from "app/dashboard/integrity/equipments-no-inspection-duties/equipment-no-inspection-duties-state.service";
import { EditOperatorTask } from "app/core/components/equipment/edit-inspection-duties/editOperatorTask";
import { EquipmentEditInspectionDutiesSaveOutputData } from "app/core/components/equipment/equipment-edit-inspection-duties-save-output-data";
import { Router } from "@angular/router";
import { IntegrityBoxComponent } from "app/dashboard/dashboard-boxes/integrity-box/integrity-box.component";
import { Utils } from "app/core/tools/utils";
import { ToasterService } from "angular2-toaster";

@Component({
  selector: "app-inspectionduty-wizard-other-equipments",
  templateUrl: "./inspectionduty-wizard-other-equipments.component.html",
  styleUrls: ["./inspectionduty-wizard-other-equipments.component.scss"],
})
export class InspectiondutyWizardOtherEquipmentsComponent implements OnInit {
  model: InspectiondutyWizardModel;
  numberOfOtherEquipments: number = 0;
  formSubmitted: boolean;

  cycleUnits: String[];
  CycleUnits = Common.IntervalUnits;
  bulkInspectionDutyChange: PropertyCore.InspectionDutyChanges;

  equipmentPatchFlag = 0;
  problematicEquipments: string[] = [];
  changes: any = [];

  saveInspectiondutiesOutputData: any;

  @Output()
  saveRequest = new EventEmitter<EquipmentEditInspectionDutiesSaveOutputData>();

  @Output()
  pageChangeRequest = new EventEmitter<InspectiondutyWizardNavigationPaths>();

  constructor(
    private toaster: ToasterService,
    private state: EquipmentNoInspectionDutiesState,
    private router: Router,
    private service: InspectiondutyWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.model = this.service.getCurrentEquipmentModel();

    this.numberOfOtherEquipments = 0;
    this.model.equipment.Building.Equipments.forEach((a) => {
      if (
        a.EquipmentType.Name === this.model.equipment.EquipmentType.Name &&
        a.Identity !== this.model.equipment.Identity
      ) {
        this.numberOfOtherEquipments = this.numberOfOtherEquipments + 1;
      }
    });
  }

  selectEquipment(eqId: String, event) {
    if (this.model.selectedEquipments.length === 0) {
      this.model.selectedEquipments.push(eqId);
    }
    var index = this.model.selectedEquipments.indexOf(eqId);
    if (event.target.checked) {
      this.model.selectedEquipments.push(eqId);
    } else {
      if (index !== -1) {
        this.model.selectedEquipments.splice(index, 1);
      }
    }
  }

  selectAll(event): void {
    this.model.selectedEquipments = [];
    this.model.selectedEquipments.push(this.model.equipment.Identity);

    if (event.target.checked) {
      this.model.equipment.Building.Equipments.forEach((element) => {
        if (
          element.EquipmentType.Name ===
            this.model.equipment.EquipmentType.Name &&
          element.Identity !== this.model.equipment.Identity
        ) {
          this.model.selectedEquipments.push(element.Identity);
        }
      });
    }
  }

  private checkOtherEquipments() {
    //Alle Equipments
    this.problematicEquipments = [];
    this.model.equipment.Building.Equipments.forEach((x) => {
      // Alle ausgewählten Equipments
      if (this.model.selectedEquipments.indexOf(x.Identity) !== -1) {
        //Alle OperatorTaskMarkers
        x.OperatorTaskMarkers.forEach((s) => {
          if (
            x.Identity !== this.model.equipment.Identity &&
            this.model.changedIDs.indexOf(s.OperatorTask.Id) !== -1 &&
            s.IsMissing === false &&
            this.problematicEquipments.indexOf(x.Identity) === -1
          ) {
            this.problematicEquipments.push(x.Identity);
          }
        });
      }
    });
  }

  private collectAllSaves(): void {
    this.problematicEquipments = [];
    this.changes = [];
    this.model.equipmentOperatorTasks.forEach((s) => {
      let operation;
      let change: any;
      let guid = Utils.guid();

      if (s.isChangedActive) {
        if (s.isMissing) {
          operation = Operations.Operations.Delete;
        } else {
          operation = Operations.Operations.Create; // activation and possible deadline change only here
        }
        change = {
          Operation: operation,
          Identity: guid,
          EquipmentRef:
            "Equipment(" + this.model.equipment.Identity.toString() + ")",
          Interval: '{"Step":' + s.cycle + ',"Size":"' + s.cycleUnit + '"}',
          OperatorTaskRef:
            "OperatorTask(" + s.operatortaskIdentity.toString() + ")",
        };
        this.changes.push(change);
      }
    });
    this.model.equipment.Building.Equipments.forEach((y) => {
      if (
        this.model.selectedEquipments.indexOf(y.Identity) !== -1 &&
        y.Identity !== this.model.equipment.Identity
      ) {
        this.model.equipmentOperatorTasks.forEach(async (x) => {
          y.OperatorTaskMarkers.forEach((z) => {
            let operation;
            let change: any;
            let secondChange: any;
            let guid = Utils.guid();
            if (z.OperatorTask.Id === x.operatortaskId) {
              /// CREATE

              if (z.IsMissing === true && x.isMissing === false) {
                operation = Operations.Operations.Create;

                change = {
                  Operation: operation,
                  Identity: guid,
                  EquipmentRef: "Equipment(" + y.Identity.toString() + ")",
                  Interval:
                    '{"Step":"' + x.cycle + '","Size":"' + x.cycleUnit + '"}',
                  OperatorTaskRef:
                    "OperatorTask(" + x.operatortaskIdentity.toString() + ")",
                };

                this.changes.push(change);
              }

              if (z.IsMissing === true && x.isMissing === true) {
                /// overwrite
              }
              if (z.IsMissing === false && x.isMissing === false) {
                let guid1 = Utils.guid();
                operation = Operations.Operations.Delete;
                change = {
                  Operation: operation,
                  Identity: guid1,
                  EquipmentRef: "Equipment(" + y.Identity.toString() + ")",
                  OperatorTaskRef:
                    "OperatorTask(" + x.operatortaskIdentity.toString() + ")",
                };

                this.changes.push(change);

                operation = Operations.Operations.Create;

                let guid = Utils.guid();

                secondChange = {
                  Operation: operation,
                  Identity: guid,
                  Interval:
                    '{"Step":"' + x.cycle + '","Size":"' + x.cycleUnit + '"}',
                  EquipmentRef: "Equipment(" + y.Identity.toString() + ")",
                  OperatorTaskRef:
                    "OperatorTask(" + x.operatortaskIdentity.toString() + ")",
                };

                this.changes.push(secondChange);

                if (this.problematicEquipments.indexOf(y.Identity) === -1) {
                  this.problematicEquipments.push(y.Identity);
                }
              }

              /// DELETE

              if (z.IsMissing === false && x.isMissing === true) {
                operation = "Delete";
                change = {
                  Operation: operation,
                  Identity: guid,
                  EquipmentRef: "Equipment(" + y.Identity.toString() + ")",
                  OperatorTaskRef:
                    "OperatorTask(" + x.operatortaskIdentity.toString() + ")",
                };

                this.changes.push(change);

                if (this.problematicEquipments.indexOf(y.Identity) === -1) {
                  this.problematicEquipments.push(y.Identity);
                }
              }
            }
          });
        });
      }
    });

    if (
      this.changes.length === 0 &&
      this.model.equipment.HasInspectionDutiesActivated
    ) {
      // Die Prüfpflichten wurden gar nicht geändert, aber das Abschliessen Flag ist aktiv (das checkbox) =>
      //Müssen die Ids der selektierten Anlagen mit Operation UpdateActivateInspectionDutiesOnly gespeichert werden
      this.model.selectedEquipments.forEach((eqId) => {
        let change = {
          Operation: Operations.Operations.UpdateActivateInspectionDutiesOnly,
          //Identity: Utils.guid(),
          EquipmentRef: "Equipment(" + eqId + ")",
        };

        this.changes.push(change);
      });
    }

    let jsonChanges = "[";

    this.changes.forEach((change) => {
      if (jsonChanges !== "[") {
        jsonChanges = jsonChanges + ",";
      }
      jsonChanges =
        jsonChanges +
        "{" +
        '"Operation":' +
        '"' +
        change.Operation +
        '",' +
        '"EquipmentRef":' +
        '"' +
        change.EquipmentRef +
        '"';

      if (change.Identity) {
        jsonChanges += "," + '"Identity":' + '"' + change.Identity + '"';
      }
      if (change.Interval) {
        jsonChanges += "," + '"Interval":' + change.Interval;
      }

      if (change.OperatorTaskRef) {
        jsonChanges +=
          "," + '"OperatorTaskRef":' + '"' + change.OperatorTaskRef + '"';
      }

      jsonChanges += "}";
    });

    jsonChanges = jsonChanges + "]";

    this.saveInspectiondutiesOutputData = {
      Comment: this.model.comment,
      ActivateInspectionDuties:
        this.model.equipment.HasInspectionDutiesActivated.toString(),
      Changes: jsonChanges,
    };
  }

  SaveAll(validForm: boolean): void {
    this.collectAllSaves();

    if (this.changes.length > 0) {
      if (this.problematicEquipments.length > 0) {
        const modalRef = this.modalService.open(ConfirmationModal);

        let names: string[] = [];
        let message: string = "";
        this.model.equipment.Building.Equipments.forEach((x) => {
          if (this.problematicEquipments.indexOf(x.Identity) > -1) {
            names.push(x.Name);
          }
        });

        modalRef.componentInstance.title =
          "Überschreiben von aktivierten Prüfpflichten";
        message =
          "Bei folgenden Anlagen werden aktivierte Prüfpflichten überschrieben:<br>";

        names.forEach((y) => {
          message = message + " -  " + y + "  <br>";
        });
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.yesButton = "Speichern";
        modalRef.componentInstance.cancelButton = "Abbrechen";

        modalRef.result
          .then((val) => {
            if (val === ConfirmationModal.YES_VALUE) {
              this.odataCoreService.InspectionDuty.Actions()
                .BatchChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet()
                .Parameters(
                  this.saveInspectiondutiesOutputData.Comment,
                  this.saveInspectiondutiesOutputData.ActivateInspectionDuties,
                  this.saveInspectiondutiesOutputData.Changes
                )
                .Execute()
                .then((res) => {
                  this.model = null;
                  this.changes = [];
                  this.problematicEquipments = [];
                  this.goToInspectionDutiesNotActivated();
                });
            }
          })
          .catch(() => {
            this.changes = [];
            this.problematicEquipments = [];
          });
      } else {
        this.odataCoreService.InspectionDuty.Actions()
          .BatchChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet()
          .Parameters(
            this.saveInspectiondutiesOutputData.Comment,
            this.saveInspectiondutiesOutputData.ActivateInspectionDuties,
            this.saveInspectiondutiesOutputData.Changes
          )
          .Execute()
          .then((res) => {
            this.changes = [];
            this.problematicEquipments = [];
            this.goToInspectionDutiesNotActivated();
          });
      }
    }
  }

  private mapCycleUnitToEnum(unit: string): Common.IntervalUnits {
    let cycleUnitEnum;
    switch (unit) {
      case Common.IntervalUnits.Undefined:
        cycleUnitEnum = Common.IntervalUnits.Undefined;
        break;
      case Common.IntervalUnits.Year:
        cycleUnitEnum = Common.IntervalUnits.Year;
        break;
      case Common.IntervalUnits.Month:
        cycleUnitEnum = Common.IntervalUnits.Month;
        break;
      case Common.IntervalUnits.Week:
        cycleUnitEnum = Common.IntervalUnits.Week;
        break;
      case Common.IntervalUnits.Day:
        cycleUnitEnum = Common.IntervalUnits.Day;
        break;
    }
    return cycleUnitEnum;
  }

  private goToInspectionDutiesNotActivated(): void {
    this.router.navigate(
      [
        "/dashboard",
        {
          outlets: {
            left: ["board"],
            right: [
              IntegrityBoxComponent.PATH_PREFIX,
              IntegrityBoxComponent.PATH_EQUIPMENTS_NO_INSPECTION_DUTIES,
            ],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  close(): void {
    this.router.navigate(
      [
        "/dashboard",
        {
          outlets: {
            left: ["board"],
            right: [
              IntegrityBoxComponent.PATH_PREFIX,
              IntegrityBoxComponent.PATH_EQUIPMENTS_NO_INSPECTION_DUTIES,
            ],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  public previous() {
    this.pageChangeRequest.emit(InspectiondutyWizardNavigationPaths.PREVIOUS);
  }
}
