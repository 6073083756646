import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IncidentWizardModel } from './../../incident/incident-wizard-model';
import { DefectWizardNavigationPaths } from './../defect-wizard-navigation-paths.enum';
import { DefectWizardService } from './../defect-wizard.service';

@Component({
  selector: 'app-defect-wizard-progress',
  templateUrl: './defect-wizard-progress.component.html',
  styleUrls: ['./defect-wizard-progress.component.scss']
})
export class DefectWizardProgressComponent implements OnInit {

  PAGE_DATA = DefectWizardNavigationPaths.DATA;
  PAGE_ADD_SERVICE_CONTACT = DefectWizardNavigationPaths.ADD_SERVICE_CONTACT;
  PAGE_EQUIPMENT = DefectWizardNavigationPaths.EQUIPMENT;

  model: IncidentWizardModel;

  @Output()
  pageChangeRequest = new EventEmitter<DefectWizardNavigationPaths>();

  @Input()
  currentPage: DefectWizardNavigationPaths;

  constructor(private service: DefectWizardService) { }

  ngOnInit() {
    this.model = this.service.getCurrentDefect();
  }

  navigate(pageChangeEvent: DefectWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }

}
