import {Component, Input} from '@angular/core';

import {MandatorWizardModel} from './../../mandator/wizard/mandator-wizard-model';

@Component({
  selector: 'app-contract-data-processing-article',
  templateUrl: './contract-data-processing-article.component.html',
  styleUrls: ['./contract-data-processing-article.component.scss']
})
export class ContractDataProcessingArticleComponent {

  @Input()
  model: MandatorWizardModel;

  constructor() {
  }

}
