import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NgbCarouselConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { Subscription, from } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { PropertyCore, Operations } from "../../core/odata/odata.coreapi";
import { AuthService } from "../../core/auth/auth.service";
import { EditInspectionDutyGroup } from "../../core/components/equipment/edit-inspection-duties/editInspectionDutyGroup";
import { Kpi } from "./../../core/model/kpi";
import { MissingDataKpi } from "../../core/model/missingDataKpi";
import { ConfirmationModal } from "../../core/popup/confirmation.modal";
import { PopupService } from "../../core/popup/popup.service";
import { Guid } from "guid-typescript";
import { Utils } from "../../core/tools/utils";
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { ODataPath } from "../../core/odata/odataclient";
import { InspectionDuty } from "../../core/model/inspectionDuty";
import { ImageUploadService } from "../../core/components/upload/image-upload-service";
import { ImageUploadModel } from "../../core/components/upload/image-upload-model";
import { FileItem } from "ng2-file-upload";
import { GlobalService } from "app/core/shared/global.service";
import { Equipment } from "app/core/model/equipmentOperatorTask";

@Component({
  selector: "app-equipment-detail",
  templateUrl: "./equipment-detail.component.html",
  styleUrls: ["./equipment-detail.component.scss"],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
  encapsulation: ViewEncapsulation.None,
})
export class EquipmentDetailComponent implements OnInit, OnDestroy {
  installEquipmentId: string;
  buildingId: string;
  installEquipment: PropertyCore.Equipment;
  images: PropertyCore.Image[];
  imgIdx = 0;
  inspectionDutyGroups: EditInspectionDutyGroup[];
  contracts: PropertyCore.MaintenanceContract[];

  missingData: MissingDataKpi;
  kpi: Kpi;

  overDueIncidentsLength = 0;
  nextThreeMonthsIncidentsLength = 0;
  defectIncidentsLength = 0;
  equipmentNoDocumentation = 0;
  equipmentNoInspectionDutyAssigned = 0;
  equipmentInspectionDutyUnterminated = 0;
  equipmentNoMaintenanceContract = 0;
  equipmentNoMaintenanceDesired = false;
  building: PropertyCore.Building;
  imageUploadModel: ImageUploadModel;
  private response: string;

  private subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private config: NgbCarouselConfig,
    private popupService: PopupService,
    private odataCoreService: ODataCoreService,
    private imageUploadService: ImageUploadService,
    private globalService: GlobalService
  ) {
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
  }

  ngOnInit() {
    this.imageUploadModel = this.imageUploadService.getCurrentImageUploadModel();
    this.imageUploadModel.entityType = "Equipment";
    this.subscriptions.add(
      this.route.root.firstChild.paramMap.subscribe((params: ParamMap) => (this.buildingId = params.get("id")))
    );

    this.subscriptions.add(
      this.route.root.firstChild.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            return from(
              this.odataCoreService.Building.Get()
                .Key(Guid.parse(params.get("id")))
                .Expand((x) => {
                  x.Expand("UsageTypes");
                  x.Expand("Statistics");
                })
                .Exec()
                .then((x) => x.value)
            ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])));
          })
        )
        .subscribe((building) => {
          this.building = building;
        })
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            this.installEquipmentId = params.get("id");
            return from(
              this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(this.installEquipmentId))
                .Expand((x) => {
                  x.Expand("Building"), x.Expand("EquipmentType"), x.Expand("Room", (y) => y.Expand("Floor"));
                })
                .Exec()
                .then((x) => x.value)
            ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])));
          })
        )
        .subscribe((installEquipment) => {
          this.installEquipment = installEquipment;

          this.odataCoreService.Event.Query()
            .Filter((x) =>
              x
                .Equals(ODataPath.For("KpiMarker", "IsPlanned"), true)
                .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
                .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                .And.Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(this.installEquipmentId))
                .And.NotEquals("Type", "Undefined")
            )
            .Count()
            .then((result) => (this.nextThreeMonthsIncidentsLength = result));

          this.odataCoreService.Event.Query()
            .Filter((x) =>
              x
                .Equals(ODataPath.For("KpiMarker", "IsOverdue"), true)
                .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
                .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                .And.Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(this.installEquipmentId))
                .And.NotEquals("Type", "Undefined")
            )
            .Count()
            .then((result) => (this.overDueIncidentsLength = result));

          this.odataCoreService.Event.Query()
            .Filter((x) =>
              x
                .Equals(ODataPath.For("KpiMarker", "IsDefective"), true)
                .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
                .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                .And.Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(this.installEquipmentId))
                .And.NotEquals("Type", "Undefined")
            )
            .Count()
            .then((result) => (this.defectIncidentsLength = result));
        })
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(params.get("id")))
                .Expand((x) => {
                  x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
                })
                .Select("Images")
                .Exec()
                .then((x) => x.value)
            ).pipe(
              map((res) =>
                res[0].Images.map((img) => {
                  Utils.mapAllJSONDatesToDates(img);
                  img.DownloadToken = this.odataCoreService.Image.Get()
                    .Key(Guid.parse(img.Identity))
                    .Raw()
                    .ToUrlString(false);
                  return img;
                })
              )
            )
          )
        )
        .subscribe((res) => {
          this.images = res;
          this.imgIdx = 0;
        })
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.InspectionDuty.Query()
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(params.get("id")))
                    .Or.Equals(ODataPath.For("Module", "Equipment", "Identity"), Guid.parse(params.get("id")))
                )
                .Expand((x) => {
                  x.Expand("Module"), x.Expand("Equipment"), x.Expand("Schedule"), x.Expand("OperatorTask");
                })
                .Exec()
                .then((x) => x.value)
            ).pipe(
              map((res) =>
                res.map((i) => {
                  Utils.mapAllJSONDatesToDates(i);
                  return Object.assign(new InspectionDuty(), i);
                })
              )
            )
          ),
          map((id) => {
            const idg: EditInspectionDutyGroup[] = [];
            id.filter((i) => !i.IsDisabled).forEach((x) => {
              let idgItem = idg.find(
                (val) => x.Module && val.imName === x.Module.Name && val.guideline === x.OperatorTask.Guidelines
              );
              if (!idgItem) {
                idgItem = new EditInspectionDutyGroup();
                idgItem.imName = x.Module ? x.Module.Name : null;
                idgItem.guideline = x.OperatorTask.Guidelines;
                idg.push(idgItem);
              }
              idgItem.inspectionDuties.push(x);
            });
            return idg;
          })
        )
        .subscribe((res) => (this.inspectionDutyGroups = res))
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            return from(
              this.odataCoreService.MaintenanceJob.Query()
                .Filter((x) => x.Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(params.get("id"))))
                .Expand((x) => {
                  x.Expand("Contract", (y) => {
                    y.Expand("ServiceProvider");
                  });
                })
                .Exec()
                .then((x) => x.value)
            ).pipe(map((res) => res.map((i) => Utils.mapAllJSONDatesToDates(i.Contract))));
          })
        )
        .subscribe((res) => (this.contracts = res))
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            this.odataCoreService.Equipment.Query()
              .Filter((x) =>
                x
                  .Equals(ODataPath.For("KpiMarker", "IsDocumented"), false)
                  .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                  .And.EqualsField("Identity", Guid.parse(params.get("id")))
                  .Or.Equals(ODataPath.For("KpiMarker", "IsDocumented"), false)
                  .And.Equals(ODataPath.For("KpiMarker", "IsActive"), false)
                  .And.EqualsField("IsRenterResponsibility", false)
                  .And.EqualsField("IsNoMaintenanceDesired", true)
                  .And.EqualsField("Identity", Guid.parse(params.get("id")))
              )
              .Count()
              .then((result) => (this.equipmentNoDocumentation = result))
          )
        )
        .subscribe((result) => (this.equipmentNoDocumentation = result))
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            this.odataCoreService.Equipment.Query()
              .Filter((x) =>
                x
                  .Equals(ODataPath.For("KpiMarker", "IsMaintained"), false)
                  .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                  .And.EqualsField("Identity", Guid.parse(params.get("id")))
              )
              .Count()
              .then((result) => (this.equipmentNoMaintenanceContract = result))
          )
        )
        .subscribe((result) => (this.equipmentNoMaintenanceContract = result))
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            this.odataCoreService.Equipment.Query()
              .Filter((x) =>
                x
                  .EqualsField("IsNoMaintenanceDesired", true)
                  .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                  .And.EqualsField("Identity", Guid.parse(params.get("id")))
              )
              .Count()
              .then((result) => result > 0)
          )
        )
        .subscribe((result) => (this.equipmentNoMaintenanceDesired = result))
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            this.odataCoreService.Equipment.Query()
              .Filter((x) =>
                x
                  .Equals(ODataPath.For("KpiMarker", "IsInspectionDutiesAssigned"), false)
                  .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                  .And.EqualsField("Identity", Guid.parse(params.get("id")))
              )
              .Count()
              .then((result) => (this.equipmentNoInspectionDutyAssigned = result))
          )
        )
        .subscribe((result) => (this.equipmentNoInspectionDutyAssigned = result))
    );

    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            this.odataCoreService.Equipment.Query()
              .Filter((x) =>
                x
                  .Equals(ODataPath.For("KpiMarker", "HasInspectionDutyUnterminated"), true)
                  .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                  .And.EqualsField("Identity", Guid.parse(params.get("id")))
              )
              .Count()
              .then((result) => (this.equipmentInspectionDutyUnterminated = result))
          )
        )
        .subscribe((result) => (this.equipmentInspectionDutyUnterminated = result))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  edit(): void {
    this.router.navigate(
      [
        "/building",
        this.buildingId,
        {
          outlets: {
            left: ["building", "view", { outlets: { tab: ["equipment"] } }],
            right: ["equipment", this.installEquipmentId, "edit"],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  async delete(): Promise<void> {
    const nameof = <T>(name: keyof T) => name;

    let res = await this.odataCoreService.Equipment.Get()
      .Key(Guid.parse(this.installEquipmentId))
      .Actions()
      .CanOperateInOperationsOnEquipmentInPropertyCore()
      .Parameters(Operations.Operations.Delete)
      .Execute();
    if (res.Blocker.length == 0) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant("EquipmentDetail._delete_modal_title");
      modalRef.componentInstance.message = this.translateService.instant("EquipmentDetail._delete_modal_message");
      modalRef.componentInstance.yesButton = this.translateService.instant("EquipmentDetail._delete_modal_yes");
      modalRef.componentInstance.cancelButton = this.translateService.instant("EquipmentDetail._delete_modal_cancel");

      modalRef.result
        .then(async (val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            await this.odataCoreService.Equipment.Delete().Key(Guid.parse(this.installEquipmentId)).Exec();
            this.toasterService.pop("info", "", this.translateService.instant("EquipmentDetail._delete_success"));
            this.router.navigate(
              [
                "/building",
                this.buildingId,
                {
                  outlets: {
                    left: ["list"],
                    right: ["building", "view", { outlets: { tab: ["equipment"] } }],
                  },
                },
              ],
              { queryParamsHandling: "merge" }
            );
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      let x = 0;

      var deleteText = "<br/><br/>";
      while (res.Blocker.length > x) {
        switch (res.Blocker[x].Property) {
          case nameof<PropertyCore.Equipment>("Documents"):
            deleteText +=
              this.translateService.instant("EquipmentDetail._delete_documentCount") +
              ": " +
              res.Blocker[x].CurrentValue +
              "<br/>";
            break;
          case nameof<PropertyCore.Equipment>("Modules"):
            deleteText +=
              this.translateService.instant("EquipmentDetail._delete_imCount") +
              ": " +
              res.Blocker[x].CurrentValue +
              "<br/>";
            break;
          case nameof<PropertyCore.Equipment>("Images"):
            deleteText +=
              this.translateService.instant("EquipmentDetail._delete_imageCount") +
              ": " +
              res.Blocker[x].CurrentValue +
              "<br/>";
            break;
          case nameof<PropertyCore.Equipment>("MaintenanceContractMappings"):
            deleteText +=
              this.translateService.instant("EquipmentDetail._delete_maintenanceContractMappingsCount") +
              ": " +
              res.Blocker[x].CurrentValue +
              "<br/>";
            break;
          case nameof<PropertyCore.Equipment>("InspectionDuties"):
            deleteText +=
              this.translateService.instant("EquipmentDetail._delete_inspectionDutiesCount") +
              ": " +
              res.Blocker[x].CurrentValue +
              "<br/>";
            break;
          case nameof<PropertyCore.Equipment>("Events"):
            deleteText +=
              this.translateService.instant("EquipmentDetail._delete_incidentsCount") +
              ": " +
              res.Blocker[x].CurrentValue +
              "<br/>";
            break;
          default:
            break;
        }
        x++;
      }

      this.toasterService.pop(
        "warning",
        "",
        this.translateService.instant("EquipmentDetail._delete_error", { deleteInfo: deleteText })
      );
    }
  }

  editInspectionDuties(): void {
    this.router.navigate(
      [
        "/building",
        this.buildingId,
        {
          outlets: {
            left: ["building", "view", { outlets: { tab: ["equipment"] } }],
            right: ["equipment", this.installEquipmentId, "edit", "inspection-duties"],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  editIncidents(): void {
    this.router.navigate([
      "/building",
      this.buildingId,
      { outlets: { left: ["list"], right: ["building", "view", { outlets: { tab: ["incident"] } }] } },
    ]);
  }

  createIncidents(): void {
    this.router.navigate(["building", "wizard", this.buildingId, "incident"]);
  }

  private close(): void {
    this.router.navigate(
      [
        "/building",
        this.installEquipment.Identity,
        { outlets: { left: ["list"], right: ["building", "view", { outlets: { tab: ["equipment"] } }] } },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  goToContracts(): void {
    this.router.navigate(["/contract"]);
  }

  goToTodoInspections(): void {
    const url = this.router
      .createUrlTree(
        [
          "/building",
          this.buildingId,
          {
            outlets: {
              left: ["building", "view", { outlets: { tab: ["equipment"] } }],
              right: [
                "equipment",
                this.installEquipmentId,
                "view",
                { outlets: { tab: ["incident", { outlets: { incidentstyle: ["list", { tab: ["todoFuture"] }] } }] } },
              ],
            },
          },
        ],
        { queryParamsHandling: "merge" }
      )
      .toString();
    this.router.navigateByUrl("/skipThisPage", { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
  }

  goToOverdueInspections(): void {
    const url = this.router
      .createUrlTree(
        [
          "/building",
          this.buildingId,
          {
            outlets: {
              left: ["building", "view", { outlets: { tab: ["equipment"] } }],
              right: [
                "equipment",
                this.installEquipmentId,
                "view",
                { outlets: { tab: ["incident", { outlets: { incidentstyle: ["list", { tab: ["overdue"] }] } }] } },
              ],
            },
          },
        ],
        { queryParamsHandling: "merge" }
      )
      .toString();
    this.router.navigateByUrl("/skipThisPage", { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
  }

  goToDefectInspections(): void {
    this.router.navigate([
      "/building",
      this.buildingId,
      {
        outlets: {
          left: ["list"],
          right: ["building", "view", { outlets: { tab: ["defect"] } }],
        },
      },
    ]);
  }

  goToDocuments(): void {
    this.router.navigate([
      "/building",
      this.buildingId,
      {
        outlets: {
          left: ["list"],
          right: ["document", { outlets: { tab: ["building-equipment"] } }],
        },
      },
    ]);
  }

  onImageUpload(event): void {
    this.subscriptions.add(
      from(
        this.odataCoreService.Equipment.Get()
          .Key(Guid.parse(this.installEquipmentId))
          .Expand((x) => {
            x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
          })
          .Select("Images")
          .Exec()
          .then((x) => x.value)
      )
        .pipe(
          map((res) =>
            res[0].Images.map((img) => {
              Utils.mapAllJSONDatesToDates(img);
              img.DownloadToken = this.odataCoreService.Image.Get()
                .Key(Guid.parse(img.Identity))
                .Raw()
                .ToUrlString(false);
              return img;
            })
          )
        )
        .subscribe((res) => {
          this.images = res;
          this.imgIdx = this.images.length - 1;
          this.imageUploadService.resetCurrentImageUploadModel();
          this.imageUploadModel = this.imageUploadService.getCurrentImageUploadModel();
          this.imageUploadModel.entityType = "Equipment";
        })
    );
  }

  onAddFile(file: FileItem): void {
    this.imageUploadModel.file.push(file);
  }

  nextImg(): void {
    this.imgIdx++;
    if (this.imgIdx === this.images.length) {
      this.imgIdx = 0;
    }
  }

  prevImg(): void {
    this.imgIdx--;
    if (this.imgIdx === -1) {
      this.imgIdx = this.images.length - 1;
    }
  }

  async deleteImg(): Promise<void> {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant("EquipmentDetail._deleteImage_modal_title");
    modalRef.componentInstance.message = this.translateService.instant("EquipmentDetail._deleteImage_modal_message");
    modalRef.componentInstance.yesButton = this.translateService.instant("EquipmentDetail._deleteImage_modal_yes");
    modalRef.componentInstance.cancelButton = this.translateService.instant(
      "EquipmentDetail._deleteImage_modal_cancel"
    );

    modalRef.result
      .then(async (val) => {
        if (val === ConfirmationModal.YES_VALUE) {
          await this.odataCoreService.Image.Delete().Key(Guid.parse(this.images[this.imgIdx].Identity)).Exec();
          this.toasterService.pop("info", "", this.translateService.instant("EquipmentDetail._deleteImage_success"));
          // reload images after delete
          let res = await this.odataCoreService.Equipment.Get()
            .Key(Guid.parse(this.installEquipmentId))
            .Expand((x) => {
              x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
            })
            .Select("Images")
            .Exec()
            .then((x) => x.value);
          let res2 = res[0].Images.map((img) => {
            Utils.mapAllJSONDatesToDates(img);
            img.DownloadToken = this.odataCoreService.Image.Get()
              .Key(Guid.parse(img.Identity))
              .Raw()
              .ToUrlString(false);
            return img;
          });
          this.images = res2;
          // nav to next image after the deleted one
          this.prevImg();
          this.nextImg();
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  async sortImg(): Promise<void> {
    if (this.images.length == 2) {
      await this.odataCoreService.Image.Get()
        .Key(Guid.parse(this.images[this.imgIdx].Identity))
        .Actions()
        .SortInOperationsOnImageInPropertyCore()
        .Parameters(Guid.createEmpty().toString())
        .Execute();
      await this.odataCoreService.Image.Get()
        .Key(Guid.parse(this.images[this.imgIdx].Identity))
        .Actions()
        .SortInOperationsOnImageInPropertyCore()
        .Parameters(Guid.createEmpty().toString())
        .Execute();
      this.toasterService.pop("info", "", this.translateService.instant("EquipmentDetail._sortImage_success"));
      // reload images after sort
      let res = await this.odataCoreService.Equipment.Get()
        .Key(Guid.parse(this.installEquipmentId))
        .Expand((x) => {
          x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
        })
        .Select("Images")
        .Exec()
        .then((x) => x.value);
      let res2 = res[0].Images.map((img) => {
        Utils.mapAllJSONDatesToDates(img);
        img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
        return img;
      });
      this.images = res2;
      // nav to first image
      this.imgIdx = 0;
    } else {
      await this.odataCoreService.Image.Get()
        .Key(Guid.parse(this.images[this.imgIdx].Identity))
        .Actions()
        .SortInOperationsOnImageInPropertyCore()
        .Parameters(Guid.createEmpty().toString())
        .Execute();
      this.toasterService.pop("info", "", this.translateService.instant("EquipmentDetail._sortImage_success"));
      // reload images after sort
      let res = await this.odataCoreService.Equipment.Get()
        .Key(Guid.parse(this.installEquipmentId))
        .Expand((x) => {
          x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
        })
        .Select("Images")
        .Exec()
        .then((x) => x.value);
      let res2 = res[0].Images.map((img) => {
        Utils.mapAllJSONDatesToDates(img);
        img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
        return img;
      });
      this.images = res2;
      // nav to first image
      this.imgIdx = 0;
    }
  }

  showImg(): void {
    this.popupService.openImagePreviewModal(this.images[this.imgIdx].DownloadToken);
  }
}
