import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent, CalendarEventTitleFormatter } from 'angular-calendar';
import { format, isSameDay, isSameMonth, parse, startOfDay } from 'date-fns';
import { Subject, Subscription, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CustomCalendarEventTitleFormatter } from './custom-calendar-event-title-formatter';
import { Common, PropertyCore } from '../../core/odata/odata.coreapi';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../core/tools/utils';
import { ODataPath } from '../../core/odata/odataclient';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#fae3e3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#d1e8ff'
  },
  green: {
    primary: '#9de308',
    secondary: '#e8fdba'
  }
};


@Component({
  selector: 'app-incident-calendar',
  templateUrl: './incident-calendar.component.html',
  styleUrls: ['./incident-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomCalendarEventTitleFormatter
    }
  ]
})
export class IncidentCalendarComponent implements OnInit, OnDestroy {

  location: string;

  buildingId: string;
  equipmentId: string;

  viewDate: Date = new Date();

  view: string = 'month';

  events: CalendarEvent[] = [];

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  lang: string = 'de';

  incidents: PropertyCore.Event[];

  subscriptions: Subscription = new Subscription();

  dienstleisterData: any = null;

  typeshortvalue: string;
  statusvalue: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.location = this.route.snapshot.data.location;

    if (this.location === 'building') {
      this.subscriptions.add(this.route.root.firstChild.paramMap
        .pipe(tap(params => this.buildingId = params.get('id')), switchMap((params: ParamMap) =>
          from(this.odataCoreService.Event
            .Query()
            .Filter(x => x
              .Equals(ODataPath.For("KpiMarker", "IsActive"), true).And
              .Equals(ODataPath.For("KpiMarker", "IsInFocus"), true).And
              .Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get('id'))))
            .Select("Identity", "Id", "Type", "Guidelines", "DueDate")
            .Expand(x => {
              x.Expand("Schedule", y => {
                y.Select("CycleUnit");
                y.Select("Cycle");
                y.Expand("InspectionDuty", y => {
                  y.Select("OperatorTask");
                  y.Expand("OperatorTask", z => {
                    z.Select("Guidelines");
                    z.Select("Qualification");
                  });
                });
                y.Expand("MaintenanceContract", z => {
                  z.Select("StartDate");
                  z.Expand("ServiceProvider", za => {
                    za.Select("Name");
                  })
                });
              });
              x.Expand("Building", y => {
                y.Select("Name");
                y.Select("Id");
                y.Select("Identity");
              });
              x.Expand("Equipment", y => {
                y.Select("Name");
                y.Select("Id");
                y.Select("Identity");
                y.Select("CustomId");
              });
              x.Expand("ServiceProvider", y => {
                y.Select("Name");
                y.Select("Id");
                y.Select("Identity");
              });
              x.Expand("KpiMarker");
            })
            .OrderBy("DueDate", "asc")
            .Exec()
            .then(x =>
              x.value
            ))
            .pipe(map(res => res.map(i => {
              return Utils.mapAllJSONDatesToDates(i);
            })))
            .pipe(tap(inc => {
              inc.sort((a, b) => {
                return (b.DueDate ? b.DueDate.getTime() : 0) - (a.DueDate ? a.DueDate.getTime() : 0);
              });
            }))))
        .subscribe(res => this.createCalendarEventsFromIncidents(res)));
    } else if (this.location === 'equipment') {
      this.subscriptions.add(this.route.root.firstChild.paramMap.subscribe((params: ParamMap) => this.buildingId = params.get('id')));
      this.subscriptions.add(this.route.parent.parent.parent.paramMap
        .pipe(tap(params => this.equipmentId = params.get('id')), switchMap((params: ParamMap) => from(this.odataCoreService.Event
          .Query()
          .Filter(x => x
            .Equals(ODataPath.For("KpiMarker", "IsActive"), true).And
            .Equals(ODataPath.For("KpiMarker", "IsInFocus"), true).And
            .Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(params.get('id'))))
          .Select("Identity", "Id", "Type", "Guidelines", "DueDate")
          .Expand(x => {
            x.Expand("Schedule", y => {
              y.Select("CycleUnit");
              y.Select("Cycle");
              y.Expand("InspectionDuty", y => {
                y.Select("OperatorTask");
                y.Expand("OperatorTask", z => {
                  z.Select("Guidelines");
                  z.Select("Qualification");
                });
              });
              y.Expand("MaintenanceContract", z => {
                z.Select("StartDate");
                z.Expand("ServiceProvider", za => {
                  za.Select("Name");
                })
              });
            });
            x.Expand("Building", y => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
            });
            x.Expand("Equipment", y => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
              y.Select("CustomId");
            });
            x.Expand("ServiceProvider", y => {
              y.Select("Name");
              y.Select("Id");
              y.Select("Identity");
            });
            x.Expand("KpiMarker");
          })
          .OrderBy("DueDate", "asc")
          .Exec()
          .then(x =>
            x.value
          ))
          .pipe(map(res => res.map(i => {
            return Utils.mapAllJSONDatesToDates(i);
          })))))
        .subscribe(res => this.createCalendarEventsFromIncidents(res)));
    } else {
      console.error('unknown incident calendar location!', this.location);
    }


    if (this.route.snapshot.params.date) {
      const date = parse(this.route.snapshot.params.date, 'yyyy-MM-dd', new Date());
      if (!isNaN(date.getTime())) {
        this.viewDate = date;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (this.location === 'building') {
      this.router.navigate(['/building', this.buildingId, {
        outlets: {
          left: ['building', 'view', { outlets: { tab: ['incident', { outlets: { 'incidentstyle': ['calendar', { date: format(this.viewDate, 'yyyy-MM-dd') }] } }] } }],
          right: ['incident', event.meta.Id]
        }
      }]);
    } else if (this.location === 'equipment') {
      this.router.navigate(['/building', this.buildingId, {
        outlets: {
          left: ['equipment', this.equipmentId, 'view', { outlets: { tab: ['incident', { outlets: { 'incidentstyle': ['calendar', { date: format(this.viewDate, 'yyyy-MM-dd') }] } }] } }],
          right: ['equipment', 'incident', event.meta.Id]
        }
      }]);
    } else {
      console.error('unknown incident calendar location!', this.location);
    }
  }

  createCalendarEventsFromIncidents(incidents: PropertyCore.Event[]): void {
    this.incidents = incidents;

    incidents.forEach(inc => {
      const equipmentData = (inc.Equipment ? (inc.Equipment.CustomId ? inc.Equipment.CustomId : inc.Equipment.Name) : inc.Building.Name) + ', ';
      if (inc.Schedule != null && inc.Schedule.MaintenanceContract != null && inc.Schedule.MaintenanceContract.ServiceProvider != null && inc.ServiceProvider != null && inc.Equipment != null) {
        this.dienstleisterData = inc.Schedule.MaintenanceContract.ServiceProvider ? ('Wartungsvertrag Fa. ' + inc.Schedule.MaintenanceContract.ServiceProvider.Name + ' vom ' + inc.Schedule.MaintenanceContract.StartDate.toLocaleDateString()) : inc.Equipment.Name;
      } else {
        this.dienstleisterData = (inc.Equipment ? inc.Equipment.Name : inc.Building.Name);
      }

      const event: CalendarEvent<PropertyCore.Event> = {
        start: startOfDay(inc.DueDate),
        title: this.TypeShort(inc.Type) + ', ' + (this.location === 'building' ? equipmentData : (this.location === 'equipment' ? (this.dienstleisterData == null ? (inc.Equipment ? inc.Equipment.Name : inc.Building.Name) : this.dienstleisterData) : '')) + inc.Guidelines + ', ' + (inc.Schedule ? (inc.Schedule.Cycle + this.translateService.instant('CycleUnitShort.' + inc.Schedule.CycleUnit)) : ('1' + this.translateService.instant('CycleUnitShort.' + Common.IntervalUnits.Undefined))) + ', ' + this.translateService.instant('IncidentStatus.' + this.Status(inc)),
        cssClass: this.location, // we need to somehow send the data to the formatter if to show equipment data or not
        color: (this.Status(inc) === IncidentStatus.Executed || this.Status(inc) === IncidentStatus.Closed || this.Status(inc) === IncidentStatus.IsSkipped) ? colors.green : (this.Status(inc) === IncidentStatus.Due ? colors.red : colors.blue),
        meta: inc
      };

      this.events.push(event);
    });
    this.refresh.next({});
  }



  onViewDateChange(): void {
    this.activeDayIsOpen = false;
    this.router.navigate([{ date: format(this.viewDate, 'yyyy-MM-dd') }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
  }

  TypeShort(Type): string {
    switch (Type) {
      case Type.Inspection:
      case Type.FollowUpInspection:
        return 'P';
      case Type.Maintenance:
        return 'W';
      case Type.Repair:
        return 'I';
      case Type.Custom:
        return 'S';
    }
    return '';
  };

  Status(incidents) {
    if (incidents.IsSkipped) {
      return IncidentStatus.IsSkipped;
    }
    else if (incidents.ClosedDate) {
      return IncidentStatus.Closed;
    }
    else if (incidents.KpiMarker != null && incidents.KpiMarker.IsDone) {
      return IncidentStatus.Executed;
    }
    else if (incidents.KpiMarker != null && incidents.KpiMarker.IsOverdue) {
      return IncidentStatus.Due;
    }
    return IncidentStatus.Open;
  }

}


enum IncidentStatus {
  IsSkipped = 'IsSkipped',
  Closed = 'Closed',
  Executed = 'Executed',
  Due = 'Due',
  Open = 'Open'
}
