import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Subscription, from } from 'rxjs';

import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { AppConfig } from './../../../core/bootstrap/app.config';
import { Selectable } from './../../../core/model/common';
import { GaebExport, GaebExportLog } from './../../../core/model/gaeb';
import { IdName } from './../../../core/model/idName';
import { GlobalService } from './../../../core/shared/global.service';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { Utils } from '../../../core/tools/utils';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-export-gaeb',
  templateUrl: './export-gaeb.component.html',
  styleUrls: ['./export-gaeb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportGaebComponent implements OnInit, OnDestroy {

  @ViewChild('negativeReportPopupContent', { static: true })
  negativeReportPopupContent: any;

  @ViewChild('exportLogPopupContent', { static: true })
  exportLogPopupContent: any;

  @Input()
  title: string;

  buildings: Selectable<PropertyCore.Building>[];
  tenderObjectGroups: IdName[];

  selectedTenderObjectGroupId: number;
  zeroPosition: boolean;

  lastGaebExport: GaebExport;
  gaebExportLogs: GaebExportLog[];

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private config: AppConfig,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  get selectedCount(): number {
    return this.buildings ? this.buildings.filter(x => x.selected).length : 0;
  }

  ngOnInit() {
    this.subscriptions.add(from(this.odataCoreService.Building.Query()
      .OrderBy("Name", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        return Utils.mapAllJSONDatesToDates(i);
      })))
      .subscribe(res => this.buildings = res));

    this.subscriptions.add(this.globalService.getTenderObjectGroups().subscribe(res => {
      this.tenderObjectGroups = res.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectAll(selectAll: boolean): void {
    this.buildings.forEach(building => building.selected = selectAll);
  }

  export(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    const ids = this.buildings.filter(x => x.selected).map(x => x.Identity);
    this.subscriptions.add(this.globalService.gaebExport(ids, this.selectedTenderObjectGroupId, this.zeroPosition).subscribe(gaebExport => {
      this.toasterService.pop('info', '', this.translateService.instant('Export._export_success'));

      this.lastGaebExport = gaebExport;

      this.showLastGaebExport();

      // download
      this.downloadGaebExport(gaebExport.fileKey);
    }));
  }

  showLastGaebExport(): void {
    this.modalService.open(this.negativeReportPopupContent, { windowClass: 'negativeReport-modal' });
  }

  showGaebLogFile(): void {
    this.subscriptions.add(this.globalService.gaebExportLog().subscribe(gaebExportLogs => {
      this.gaebExportLogs = gaebExportLogs;

      this.modalService.open(this.exportLogPopupContent, { windowClass: 'negativeReport-modal' });
    }));
  }

  downloadGaebExport(UT: string): void {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', this.config.getBaseUrl() + 'WebClient/GaebService/GetFile/' + encodeURIComponent(UT));
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }
}
