import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription, from } from "rxjs";
import { Common, Library } from "../../odata/odata.coreapi";
import { map } from "rxjs/operators";
import { Utils } from "../../tools/utils";
import { ODataCoreService } from "../../odata-services/odata.coreapi.service";
import { ODataPath } from "../../odata/odataclient";

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
})
export class AddressComponent implements OnInit, OnDestroy {
  @Input()
  model: Common.Address;
  @Input()
  model2: Common.Location;
  @Input()
  model3: Common.AddressGeoCoded = <Common.AddressGeoCoded>{};

  @Input()
  required: {
    street: boolean;
    number: boolean;
    zip: boolean;
    country: boolean;
    city: boolean;
    region: boolean;
  };

  @Input()
  hidden: {
    street: boolean;
    number: boolean;
    zip: boolean;
    country: boolean;
    city: boolean;
    region: boolean;
  };

  @Output()
  addressChangeRequest = new EventEmitter<void>();

  countries: Library.CountryInfo[];
  regions: Library.RegionInfo[];

  subscriptions: Subscription = new Subscription();

  constructor(private odataCoreService: ODataCoreService) {}

  ngOnInit() {
    this.subscriptions.add(
      from(
        this.odataCoreService.Country.Query()
          .OrderBy("Id")
          .Exec()
          .then((x) => x.value)
      )
        .pipe(map((res) => res.map((i) => Utils.mapAllJSONDatesToDates(i))))
        .subscribe((res) => (this.countries = res))
    );
    if (this.model.CountryInfo) {
      this.onCountryChange(
        this.model.CountryInfo,
        this.model.RegionInfo ? this.model.RegionInfo.Name : null
      );
    }
  }

  onChange(
    changedStreet: boolean = false,
    changedNumber: boolean = false,
    changedZip: boolean = false,
    changedCity: boolean = false,
    changedRegion: boolean = false
  ) {
    this.model3.Street = this.model.Street;
    this.model3.No = this.model.No;
    this.model3.PostCode = this.model.PostCode;
    this.model3.City = this.model.City;
    const currCountryFullData = this.countries.filter(
      (c) => c.Name == this.model.CountryInfo.Name
    )[0];
    this.model3.Country = currCountryFullData["Code"];
    this.model3.Region = this.model.Region;

    this.subscriptions.add(
      from(
        this.odataCoreService
          .Actions()
          .GeoCodeInTools()
          .Parameters(this.model3)
          .Execute()
      ).subscribe(
        (result) => {
          if (result) {
            if (!this.model.Street && result["street"]) {
              this.model.Street = result["street"];
            }
            if (!this.model.No && result["no"]) {
              this.model.No = result["no"];
            }
            if ((!this.model.PostCode || changedCity) && result["postCode"]) {
              this.model.PostCode = result["postCode"];
            }
            if ((!this.model.City || changedZip) && result["city"]) {
              this.model.City = result["city"];
            }
            if (
              (!this.model.RegionInfo || changedZip || changedCity) &&
              result["region"] &&
              this.regions
            ) {
              const regionId = this.regions.find(
                (r) => r["Code"] === result["region"]
              );
              if (regionId) {
                this.model.RegionInfo = regionId;
              }
            }
            if (
              (!this.model.CountryInfo || !this.model.CountryInfo.Id) &&
              result["country"]
            ) {
              const countryId = this.countries.find(
                (c) => c["Code"] === result["country"]
              );
              if (countryId) {
                this.model.CountryInfo = countryId;
                this.onCountryChange(countryId, result["region"]);
              }
            }
            if (
              this.model2 != undefined &&
              result["location"] &&
              result["location"]["latitude"] &&
              result["location"]["longitude"]
            ) {
              this.model2.Latitude = result["location"]["latitude"];
              this.model2.Longitude = result["location"]["longitude"];
            }

            this.addressChangeRequest.emit();
          }
        },
        (err) => {
          this.addressChangeRequest.emit();
        }
      )
    );
  }
  onStreetChange() {
    this.onChange(true);
  }
  onNumberChange() {
    this.onChange(false, true);
  }
  onZipChange() {
    this.onChange(false, false, true);
  }
  onCityChange() {
    this.onChange(false, false, false, true);
  }
  onRegionChange() {
    this.onChange(false, false, false, false, true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCountryChange(
    selectedCountry: Library.CountryInfo,
    regionName?: string
  ): void {
    this.model.CountryInfo = selectedCountry;
    this.subscriptions.add(
      from(
        this.odataCoreService.Region.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("Country", "Id"), selectedCountry.Id)
              .Or.EqualsField("Id", 0)
          )
          .Exec()
          .then((x) => x.value)
      )
        .pipe(map((res) => res.map((i) => Utils.mapAllJSONDatesToDates(i))))
        .subscribe((res) => {
          this.regions = res;

          // pre-select region
          if (regionName) {
            const regionId = this.regions.find((r) => r["Code"] === regionName);
            if (regionId) {
              this.model.RegionInfo = regionId;
            }
          }
        })
    );
  }

  compareId(val1: { Id: number }, val2: { Id: number }): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }
}
