export class GaebExport {
  fileKey: string;

  negativeReportIEs: NegativeReport[];
  validIECount: number;
  negativeReportAllIECount: number;

  negativeReportIMs: NegativeReport[];
  validIMCount: number;
  negativeReportAllIMCount: number;
}

export class NegativeReport {
  id: number;
  UT: string;
  buildingName: string;
  shortName: string;
  longName: string;
  customId: string;
  roomName: string;
}

export class GaebExportLog {
  id: number;
  UT: string;
  createDate: Date;
  lastDownload: Date;
  is0PositionExport: boolean;
  sizeInKB: number;
}
