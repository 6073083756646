import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { PropertyCore } from '../../../core/odata/odata.coreapi';

@Component({
  selector: 'app-search-building',
  templateUrl: './search-building.component.html',
  styleUrls: ['./search-building.component.scss']
})
export class SearchBuildingComponent implements OnInit, OnDestroy {

  @Input()
  title: string;

  @Input()
  buildings: PropertyCore.Building[];

  @Output()
  navRequest = new EventEmitter<PropertyCore.Building>();

  private subscriptions: Subscription = new Subscription();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  nav(building: PropertyCore.Building) {
    this.navRequest.emit(building);
  }

}
