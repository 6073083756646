import { Common, PropertyCore } from '../../../core/odata/odata.coreapi';


export class IncidentWizardModel {

  Identity?: string;
  id?: number; // will be filled upon save

  parentId?: string;
  buildingUT?: string;
  buildingName?: string;

  type?: PropertyCore.EventTypes;
  guidelines?: string = '';
  qualification?: string = '';
  dueDate?: Date = new Date();
  cycle?: number = 1;
  cycleUnit?: Common.IntervalUnits = Common.IntervalUnits.Undefined;

  equipment?: PropertyCore.Equipment;
  contact?: PropertyCore.ServiceProvider;

  isDefectHandler?: boolean;

  refDate?: Date;
  isDefectExists?: boolean;
  defectComment?: string;

  isSkipped?: boolean;

  reset(): void {
    this.Identity = null;
    this.id = null;
    this.parentId = null;
    this.buildingUT = null;
    this.buildingName = null;
    this.type = null;
    this.guidelines = '';
    this.qualification = '';
    this.dueDate = new Date();
    this.cycle = 1;
    this.cycleUnit = Common.IntervalUnits.Undefined;
    this.equipment = null;
    this.contact = null;
    this.isDefectHandler = false;
    this.refDate = null;
    this.isDefectExists = false;
    this.defectComment = null;
    this.isSkipped = false;
  }
}
