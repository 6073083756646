import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, from, Observable } from 'rxjs';
import { PropertyCore } from '../../../../core/odata/odata.coreapi';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { DocumentType, DocumentWizardModel } from './../document-wizard-model';
import { DocumentWizardNavigationPaths } from './../document-wizard-navigation-paths.enum';
import { DocumentWizardService } from './../document-wizard.service';
import { map } from 'rxjs/operators';
import { Utils } from '../../../../core/tools/utils';
import { ODataCoreService } from './../../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';
import { HttpClient} from '@angular/common/http';
import { FileItem } from 'ng2-file-upload';


@Component({
  selector: 'app-document-wizard-type',
  templateUrl: './document-wizard-type.component.html',
  styleUrls: ['./document-wizard-type.component.scss']
})
export class DocumentWizardTypeComponent implements OnInit, OnDestroy {

  model: DocumentWizardModel;

  documentTypes: PropertyCore.DocumentType[];

  formSubmitted: boolean;

  DOCUMENT_TYPE_BUILDING = DocumentType.BUILDING;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<DocumentWizardNavigationPaths>();

  constructor(
    private service: DocumentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.model = this.service.getCurrentDocument();

    switch (this.model.type) {
      case DocumentType.BUILDING:
        this.subscriptions.add(from(this.odataCoreService.DocumentTypeGroup.Query()
          .Filter(x =>
            x.EqualsField("Id", 3))
          .Expand(x => {
            x.Expand("DocumentTypes")
          })
          .Exec().then(x => x.value))
          .pipe(map(res => res[0].DocumentTypes.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.documentTypes = res));
        break;
      case DocumentType.EQUIPMENT:
        this.subscriptions.add(from(this.odataCoreService.DocumentTypeGroup.Query()
          .Filter(x =>
            x.EqualsField("Id", 2))
          .Expand(x => {
            x.Expand("DocumentTypes")
          })
          .Exec().then(x => x.value))
          .pipe(map(res => res[0].DocumentTypes.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.documentTypes = res));
        break;
      case DocumentType.INCIDENT:
        this.subscriptions.add(from(this.odataCoreService.DocumentTypeGroup.Query()
          .Filter(x =>
            x.EqualsField("Id", 1))
          .Expand(x => {
            x.Expand("DocumentTypes")
          })
          .Exec().then(x => x.value))
          .pipe(map(res => res[0].DocumentTypes.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.documentTypes = res));
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  previous(): void {
    this.pageChangeRequest.emit(DocumentWizardNavigationPaths.PREVIOUS);
  }

  async next(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    if (this.model.type === this.DOCUMENT_TYPE_BUILDING) {
        if (this.model.virtual) {

        await this.odataCoreService.Document.Post()
                .ValueType(this.odataCoreService.ODataTypes().Document())
                .ValuePropertyBinding("DocumentType", this.odataCoreService.DocumentType.Get().Key(this.model.DocumentType.Id).Bind())
                .ValueProperty("Description", this.model.name)
                .Exec()
                .then(async res => {
                    await this.odataCoreService.Building.Link()
                        .Key(Guid.parse(this.model.building.Identity))
                        .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(res.Identity)).Bind())
                        .Post()
                        .then(res1 => {
                            this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                        })
                })
      } else {
            await this.odataCoreService.Document.Post()
              .ValueType(this.odataCoreService.ODataTypes().Document())
              .ValuePropertyBinding("DocumentType", this.odataCoreService.DocumentType.Get().Key(this.model.DocumentType.Id).Bind())
              .ValueProperty("Name", this.model.file.file.name)
              .ValueProperty("Description", this.model.name)
              .Exec()
              .then(async res => {
              await this.odataCoreService.Building.Link()
                    .Key(Guid.parse(this.model.building.Identity))
                    .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(res.Identity)).Bind())
                    .Post()
                    .then(async res1 => {
                      if(this.model.file.file.type != "") {

                        var odataDocumentUrl = this.odataCoreService.Document.Get().Raw()
                            .Key(Guid.parse(res.Identity))
                            .ToUrlString(false);

                        await this.uploadODataDocument(this.model.file, odataDocumentUrl)
                            .toPromise().then(res2 => {
                                  this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                            })
                            
                      } else {
                        await this.uploadDocumentNoContentType(res.Identity);
                      }
                    })
              })
      }
    } else {
      this.pageChangeRequest.emit(DocumentWizardNavigationPaths.NEXT);
    }
}

  uploadODataDocument(file: FileItem, url: string): Observable<any> {
      return this.httpClient.put(url, file._file);
    }

  uploadODataDocumentNoCT(file: Blob, url: string): Observable<any> {
    return this.httpClient.put(url, file);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('DocumentWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('DocumentWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('DocumentWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('DocumentWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentDocument();
            this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
    }
  }

  onDocumentTypeChange(typeId: string): void {
    this.model.DocumentType = this.documentTypes.find(x => x.Id === +typeId);
    }

  async uploadDocumentNoContentType(documentIdentity : string): Promise<void>{
    let fileExtensionIndex = this.model.file.file.name.lastIndexOf(".");
    let fileExtension = this.model.file.file.name.substring(fileExtensionIndex);
    let blobFile;

    switch (fileExtension) {

      case '.pdf':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/pdf'); 
      break;

      case '.png':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/png');
        break;

      case '.jpg':
      case '.jpeg':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/jpeg');
        break;

      case '.tif':
      case '.tiff':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/tiff');
        break;

      case '.doc':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/msword');
        break;

      case '.docx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        break;

      case '.xls':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.ms-excel');
        break;

      case '.xlsx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        break;

      case '.ppt':        
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.ms-powerpoint');
        break;

      case '.pptx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
        break;

      case '.dwg':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/vnd.dwg');
        break;

      default: 
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/octet-stream');
        break;
        
      }
    
      var odataDocumentUrl = this.odataCoreService.Document.Get().Raw()
      .Key(Guid.parse(documentIdentity))
      .ToUrlString(false);

      await this.uploadODataDocumentNoCT(blobFile, odataDocumentUrl)
        .toPromise().then(res => {
          this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
        })
  }
}
