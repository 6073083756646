import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { ConfirmationModal } from './../../../core/popup/confirmation.modal';
import { ILogger } from './../../../core/shared/logger.service';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { Common } from '../../../core/model/odata';


@Component({
  selector: 'app-building-detail-edit',
  templateUrl: './building-detail-edit.component.html',
  styleUrls: ['./building-detail-edit.component.scss']
})
export class BuildingDetailEditComponent implements OnInit, OnDestroy {

  building: PropertyCore.Building;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  logger: ILogger;

  buildingIdentity: string;

  constructor(
    private translateService: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.route.root.firstChild.paramMap.pipe(switchMap((params: ParamMap) => {
      this.buildingIdentity = params.get('id');
      return from(this.odataCoreService.Building.Get()
          .Key(Guid.parse(this.buildingIdentity))
          .Expand(x => {
            x.Expand("UsageTypes");
            x.Expand("Statistics");
          })        
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      }))
      .subscribe(res => {
        this.building = res;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('BuildingDetailEdit._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('BuildingDetailEdit._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('BuildingDetailEdit._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('BuildingDetailEdit._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['list'], right: ['building'] } }]);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['list'], right: ['building'] } }]);
    }
  }

  async save(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    await this.odataCoreService.Building.Patch()
      .ValueType(this.odataCoreService.ODataTypes().Building())
      .Key(Guid.parse(this.building.Identity))
      .ValueProperty("Name", this.building.Name)
      .ValueProperty("Address", this.mapAddress(this.building.Address))
      .ValueProperty("Location", {
        Longitude: this.building.Location.Longitude,
        Latitude: this.building.Location.Latitude
      })
      .ValueProperty("Size", this.building.Size)
      .ValueProperty("YearOfConstruction", this.building.YearOfConstruction)
      .Exec();
        this.router.navigate(['/building', this.building.Identity, { outlets: { left: ['list'], right: ['building'] } }]);
    };

  private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};

    if (address.RegionInfo != null && address.RegionInfo.hasOwnProperty('Code')) {
      entity.Region = address.RegionInfo['Code'];
    }
    if (address.CountryInfo != null && address.CountryInfo.hasOwnProperty('Code')) {
      entity.Country = address.CountryInfo['Code'];
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }
}
