import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { startWith, switchMap, map } from 'rxjs/operators';

import { Incident } from '../../core/model/incident';
import { IncidentState } from '../building-incident/incident-state.service';
import { Utils } from '../../core/tools/utils';
import { ODataPath } from '../../core/odata/odataclient';
import { Guid } from 'guid-typescript';
import { PropertyCore } from '../../core/odata/odata.coreapi'
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';

@Component({
  selector: 'app-defect-list',
  templateUrl: './defect-list.component.html',
  styleUrls: ['./defect-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefectListComponent implements OnInit, OnDestroy {
  buildingId: string;
  defects: PropertyCore.Event[];

  searchFilter: string = '';

  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private state: IncidentState,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {

    this.subscriptions.add(this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(v => {
        return this.route.root.firstChild.paramMap.pipe(switchMap((params: ParamMap) =>
          from(this.odataCoreService.Event
            .Query()
            .Filter(x => x
              .Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get('id'))).And
              .NotEqualsPath(ODataPath.For("Response", "DefectRating"), "Undefined"))
            .Expand(x => {
              x.Expand("Response", y => {
                y.Expand("User")
              });
              x.Expand("Schedule", y => {
                y.Expand("MaintenanceContract"),
                  y.Expand("InspectionDuty", y => {
                    y.Select("OperatorTask"),
                      y.Expand("OperatorTask")
                  });
              });
              x.Expand("Building", y => {
                y.Select("Name"),
                  y.Select("Id"),
                  y.Select("Identity")
              });
              x.Expand("Equipment", y => {
                y.Select("Name"),
                  y.Select("Id"),
                  y.Select("Identity"),
                  y.Select("CustomId")
              });
            })
            .Exec()
            .then(x =>
              x.value
            ))
            .pipe(map(res => res.map(i => {
              Utils.mapAllJSONDatesToDates(i);
              return Object.assign(new Incident(), i);
            })))
        ));
      })).subscribe(res => this.defects = res));

    this.subscriptions.add(this.route.root.firstChild.paramMap.subscribe(params => this.buildingId = params.get('id')));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
