import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation.modal.html'
})

export class ConfirmationModal {


  public static YES_VALUE: string = 'Yes';
  public static CANCEL_VALUE: string = 'Cancel';

  public yesValue = ConfirmationModal.YES_VALUE;
  public cancelValue = ConfirmationModal.CANCEL_VALUE;

  @Input() message;
  @Input() cancelButton;
  @Input() yesButton;
  @Input() title;

  @Input() hideYesButton = false;
  @Input() hideCancelButton = false;

  constructor(public activeModal: NgbActiveModal) { }
}
