import {Common, PropertyCore} from '../../../core/odata/odata.coreapi';

export class MandatorWizardModel {

  identity: string;
  name: string;
  mandatorName: string;
  address?: Common.Address;
  contact: string;
  contactPhone: string;
  contactMail: string;
  oldAgreed: boolean; // on mandator edit
  agreed: boolean;

  editMode: boolean;

}
