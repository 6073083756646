export enum ContractWizardNavigationPaths {

  GENERAL_DATA = 'general-data',
  SERVICE_CONTACT = 'service-contact',
  ADD_SERVICE_CONTACT = 'add-service-contact',
  INSTALL_EQUIPMENTS = 'install-equipments',
  MAINTENANCE_DATA = 'maintenance-data',

  NEXT = 'next',
  PREVIOUS = 'previous',
  START = 'start',
  EXIT = 'exit'
}
