import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { Subscription, from } from "rxjs";
import { switchMap } from "rxjs/operators";

import { PropertyCore } from "../../core/odata/odata.coreapi";
import { Document } from "./../../core/model/document";
import { ConfirmationModal } from "./../../core/popup/confirmation.modal";
import { PopupService } from "./../../core/popup/popup.service";
import { DocumentType } from "./../wizard/document/document-wizard-model";
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";
import { map } from "rxjs/internal/operators/map";
import { Utils } from "../../core/tools/utils";
import { GlobalService } from "app/core/shared/global.service";
import { Building } from "app/core/model/building";
import { FilterByPipe } from "app/core/pipes/filter.pipe";

@Component({
  selector: "app-document-incident",
  templateUrl: "./document-incident.component.html",
  styleUrls: ["./document-incident.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentIncidentComponent implements OnInit, OnDestroy {
  building: PropertyCore.Building;
  buildingDocumentHierarchy: PropertyCore.Building;

  searchFilter: string;

  editDocument: Document;

  eventDocumentCount = 0;

  subscriptions: Subscription = new Subscription();

  constructor(
    private popupService: PopupService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService,
    private globalService: GlobalService,
    private filterByPipe: FilterByPipe
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            return from(
              this.odataCoreService.Building.Get()
                .Key(Guid.parse(params.get("id")))
                .Expand((x) => {
                  x.Expand("UsageTypes");
                  x.Expand("Statistics");
                })
                .Exec()
                .then((x) => x.value)
            ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])));
          })
        )
        .subscribe((building) => (this.building = building))
    );

    this.loadDocuments();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private async loadDocuments() {
    this.subscriptions.add(
      this.route.parent.parent.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            return from(
              this.odataCoreService.Building.Get()
                .Key(Guid.parse(params.get("id")))
                .Expand((x) => {
                  x.Expand("Events", (y) => {
                    y.Expand("Documents", (z) => {
                      z.Expand("DocumentType");
                    });
                  });
                  x.Expand("Equipments", (y) => {
                    y.Expand("Events", (z) => {
                      z.Expand("Documents", (a) => {
                        a.Expand("DocumentType");
                      });
                      z.Expand("Schedule", (a) => {
                        a.Select("CycleUnit");
                        a.Select("Cycle");
                        a.Select("InspectionDuty");
                        a.Expand("InspectionDuty", (b) => {
                          b.Select("OperatorTask");
                          b.Expand("OperatorTask", (c) => {
                            c.Select("Guidelines");
                          });
                        });
                      });
                    });
                  });
                })
                .Exec()
                .then((x) => x.value)
            ).pipe(
              map((res) =>
                res.map((i) => {
                  return Object.assign(
                    new Building(),
                    Utils.mapAllJSONDatesToDates(i)
                  );
                })
              )
            );
          })
        )
        .subscribe((res) => {
          res.forEach(
            (building) =>
            (building.Events = building.Events.filter(
              (events) => !!events.Documents.length
            ))
          );
          res.forEach(
            (building) =>
            (building.Equipments = building.Equipments.filter((equipments) =>
              equipments.Events.find((events) => !!events.Documents.length)
            ))
          );

          let a = 0;
          if (res[0].Events.length !== 0) {
            while (res[0].Events.length > a) {
              this.eventDocumentCount =
                res[0].Events[a].Documents.length + this.eventDocumentCount;
              a++;
            }
          }

          this.buildingDocumentHierarchy = res.length === 1 ? res[0] : null;
        })
    );
  }

  documentWizard() {
    this.router.navigate(
      ["building", "wizard", this.building.Identity, "document"],
      {
        queryParams: { type: DocumentType.INCIDENT },
        queryParamsHandling: "merge",
      }
    );
  }

  showDocument(doc: Document) {
    if (!doc.IsVirtual) {
      if (doc.Name.endsWith(".pdf")) {
        this.popupService.openPdfModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false),
          doc.Name
        );
      } else if (
        doc.Name.endsWith(".jpg") ||
        doc.Name.endsWith(".jpeg") ||
        doc.Name.endsWith(".png")
      ) {
        this.popupService.openImagePreviewModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false)
        );
      } else if (
        doc.Name.endsWith(".doc") ||
        doc.Name.endsWith(".docx") ||
        doc.Name.endsWith(".xls") ||
        doc.Name.endsWith(".xlsx") ||
        doc.Name.endsWith(".ppt") ||
        doc.Name.endsWith(".pptx")
      ) {
        this.popupService.openOfficePreviewModal(
          this.odataCoreService.Document.Get()
            .Key(Guid.parse(doc.Identity))
            .Raw()
            .ToUrlString(false),
          doc.Name
        );
      } else {
        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = this.translateService.instant(
          "DocumentList._preview_title"
        );
        modalRef.componentInstance.message = this.translateService.instant(
          "DocumentList._preview_message"
        );
        modalRef.componentInstance.yesButton = this.translateService.instant(
          "DocumentList._preview_download"
        );
        modalRef.componentInstance.cancelButton = this.translateService.instant(
          "DocumentList._preview_cancel"
        );

        modalRef.result
          .then((val) => {
            if (val === ConfirmationModal.YES_VALUE) {
              this.subscriptions.add(
                this.globalService
                  .downloadDocument(
                    this.odataCoreService.Document.Get()
                      .Key(Guid.parse(doc.Identity))
                      .Raw()
                      .ToUrlString(false)
                  )
                  .subscribe((data) => {
                    const blob = new Blob([data], {
                      type: "application/octet-stream",
                    });
                    // if (navigator.msSaveBlob) {
                    //   // IE 10+
                    //   navigator.msSaveBlob(blob, doc.Name);
                    // } else {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = doc.Name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    //}
                  })
              );
            }
          })
          .catch(() => {
            // do nothing, just stay on page
          });
      }
    }
  }

  edit(event: MouseEvent, document: Document, content: any) {
    event.stopPropagation();
    this.editDocument = Object.assign({}, document);
    this.modalService.open(content);
  }

  delete(closeModal: () => void) {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant(
      "DocumentList._modal_title"
    );
    modalRef.componentInstance.message = this.translateService.instant(
      "DocumentList._modal_message"
    );
    modalRef.componentInstance.yesButton = this.translateService.instant(
      "DocumentList._modal_yes"
    );
    modalRef.componentInstance.cancelButton = this.translateService.instant(
      "DocumentList._modal_cancel"
    );

    modalRef.result
      .then(async (val) => {
        if (val === ConfirmationModal.YES_VALUE) {
          await this.odataCoreService.Document.Delete()
            .Key(Guid.parse(this.editDocument.Identity))
            .Exec();
          closeModal();
          this.toasterService.pop(
            "info",
            "",
            this.translateService.instant(
              "DocumentList._deleteDocument_success"
            )
          );
          this.eventDocumentCount = 0;
          this.loadDocuments();
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  async save(closeModal: () => Promise<void>) {
    await this.odataCoreService.Document.Patch()
      .ValueType(this.odataCoreService.ODataTypes().Document())
      .Key(Guid.parse(this.editDocument.Identity))
      .ValueProperty("Description", this.editDocument.Description)
      .Exec();
    closeModal();
    this.toasterService.pop(
      "info",
      "",
      this.translateService.instant("DocumentList._saveDocument_success")
    );
    this.eventDocumentCount = 0;
    this.loadDocuments();
  }

  countDocuments(events: PropertyCore.Event[]) {
    return events.map((i) => i.Documents.length).reduce((a, b) => a + b, 0);
  }

  isEquipmentHidden(equipment: PropertyCore.Equipment): boolean {
    let docsCount = 0;
    equipment.Events.forEach(ev => {
      const matchDocs = this.filterByPipe.transform(ev.Documents, this.searchFilter, ['Type', 'Description', 'Name', 'Created', 'incidentGuidelines', 'incidentDueDate'])
      docsCount += matchDocs.length
    });
    return docsCount === 0;
  }
}
