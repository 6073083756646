import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as $ from "jquery";

import { ImagePreviewModal } from "./image-preview.modal";
import { OfficePreviewModal } from "./office-preview.modal";
import { PdfModal } from "./pdf.modal";

@Injectable()
export class PopupService {
  constructor(private modalService: NgbModal) {}

  openPdfModal(downloadToken: string, filename: string) {
    $("#loadingoverlay").show();
    const modalRef = this.modalService.open(PdfModal, {
      windowClass: "pdf-modal",
    });
    modalRef.componentInstance.filename = filename;
    modalRef.componentInstance.pdfDocumentToken = downloadToken;
    modalRef.componentInstance.onComplete = (): void => {
      $("#loadingoverlay").hide();
    };
  }

  openImagePreviewModal(downloadToken: string) {
    const modalRef = this.modalService.open(ImagePreviewModal, {
      windowClass: "pdf-modal",
    });
    modalRef.componentInstance.imageDownloadToken = downloadToken;
  }

  openOfficePreviewModal(downloadToken: string, filename: string) {
    const modalRef = this.modalService.open(OfficePreviewModal, {
      windowClass: "pdf-modal",
    });
    modalRef.componentInstance.filename = filename;
    modalRef.componentInstance.officeDownloadToken = downloadToken;
  }
}
