import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {of, Subscription, Observable, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {Mandator} from './../../core/model/mandator';
import {MandatorWizardModel} from './../mandator/wizard/mandator-wizard-model';
import {MandatorWizardService} from './../mandator/wizard/mandator-wizard.service';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-contract-data-processing',
  templateUrl: './contract-data-processing.component.html',
  styleUrls: ['./contract-data-processing.component.scss']
})
export class ContractDataProcessingComponent implements OnInit, OnDestroy {

  model: MandatorWizardModel;

  subscriptions: Subscription = new Subscription();

  constructor(
    private mandatorWizardService: MandatorWizardService,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    const history = this.route.snapshot.data.history;
    if (history) {
      this.subscriptions.add(this.route.paramMap.pipe(switchMap((params: ParamMap) => params.get('id') ? from(this.odataCoreService.Account.Query()
        .Filter(x =>
          x.EqualsField("Identity", Guid.parse(params.get('id'))))
        .Expand(x => {
          x.Expand("User"),
            x.Expand("Statistics"),
            x.Expand("Profile"),
            x.Expand("AccountDataProcessingAgreement", z => {
              z.Expand("Country")
            })
        })
        .Exec().then(x => x.value))
        .pipe(map(res => {
          Utils.mapAllJSONDatesToDates(res[0]);
          return Object.assign(new Mandator(), res[0]);
        })) : of(<Mandator>null)))
        .subscribe(mandator => {
          if (mandator) {
            this.mandatorWizardService.startWizard();
            this.model = this.mandatorWizardService.getCurrentMandator();
            // we need to load countries for country data
            this.subscriptions.add(from(this.odataCoreService.Country.Query().OrderBy('Id')
              .Exec().then(x => x.value))
              .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(countries => {
                this.mandatorWizardService.editWizard(mandator, countries.find(c => c.Id === ((history && mandator?.AccountDataProcessingAgreement) ? mandator?.AccountDataProcessingAgreement?.Country.Id : mandator.Profile.Address.CountryInfo.Id)), history);
              }));
          }
        }));
    } else {

      this.subscriptions.add(this.route.paramMap.pipe(switchMap((params: ParamMap) => params.get('id') ? from(this.odataCoreService.Account.Query()
        .Filter(x =>
          x.EqualsField("Identity", Guid.parse(params.get('id'))))
        .Expand(x => {
          x.Expand("User"),
            x.Expand("Statistics"),
            x.Expand("Profile")        
        })
        .Exec().then(x => x.value))
        .pipe(map(res => {
          Utils.mapAllJSONDatesToDates(res[0]);
          return Object.assign(new Mandator(), res[0]);
        })) : of(<Mandator>null)))
        .subscribe(mandator => {
          if (mandator) {
            this.mandatorWizardService.startWizard();
            this.model = this.mandatorWizardService.getCurrentMandator();
            // we need to load countries for country data
            this.subscriptions.add(from(this.odataCoreService.Country.Query().OrderBy('Id')
              .Exec().then(x => x.value))
              .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(countries => {
                this.mandatorWizardService.editWizard(mandator, countries.find(c => c.Id === ((history && mandator?.AccountDataProcessingAgreement) ? mandator?.AccountDataProcessingAgreement?.Country.Id : mandator.Profile.Address.CountryInfo.Id)), history);
              }));
          }
        }));
    }
   
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
