import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Mandator } from '../core/model/mandator';
import { ODataCoreService } from '../core/odata-services/odata.coreapi.service';
import { Utils } from '../core/tools/utils';


@Component({
  selector: 'app-login-landing',
  templateUrl: './login-landing.component.html',
})
export class LoginLandingComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
      this.odataCoreService.Building.Query()
          .Count()
          .then(x => {
              let buildingCount = x;
              if (buildingCount == 0) {
                  this.router.navigate(['building/wizard']);
                  return;
              }
              this.router.navigate(['dashboard']);
          })
          .catch(x => {
              this.router.navigate(['logout']);
          });
  }

  ngOnDestroy() {
  }

}
