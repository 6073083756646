import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {map, startWith, switchMap, tap} from 'rxjs/operators';
import {IncidentDetailLinkOutputData} from '../../core/components/incident/incident-detail-link-output-data';
import {IncidentListInputData} from '../../core/components/incident/incident-list-input-data';
import {IncidentType} from '../../core/components/incident/incident-type';
import {IncidentView} from '../../core/components/incident/incident-view';
import {NavigatorService} from '../../core/shared/navigator.service';
import {IncidentState} from '../building-incident/incident-state.service';
import { Utils } from '../../core/tools/utils';
import { ODataPath } from '../../core/odata/odataclient';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';
import { Incident } from '../../core/model/incident';


@Component({
  selector: 'app-equipment-incident-list',
  templateUrl: './equipment-incident-list.component.html',
  styleUrls: ['./equipment-incident-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EquipmentIncidentListComponent implements OnInit, OnDestroy {

  currentViewTab: IncidentView;

  currentTypeTab: IncidentType = IncidentType.NONE;

  inputData: IncidentListInputData = new IncidentListInputData();
  buildingId: string;
  equipmentId: string;

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: IncidentState,
    private navigator: NavigatorService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.inputData.allowedViews = [IncidentView.ALL, IncidentView.TODOFUTURE, IncidentView.DONE, IncidentView.OVERDUE];

    this.inputData.variableScrollHeight = '431';
    this.subscriptions.add(this.route.root.firstChild.paramMap.subscribe((params: ParamMap) => this.buildingId = params.get('id')));
    this.inputData.allIncidents$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(v => {
        return this.route.parent.parent.parent.paramMap
          .pipe(
            tap(params => (this.equipmentId = params.get('id'))),
            switchMap((params: ParamMap) => {
              return from(this.odataCoreService.Event.Query()
                .Filter(x => x
                  .Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(params.get('id')))
                )
                .Select("Identity", "Id", "Type", "Guidelines", "DueDate")
                .Expand(x => {
                  x.Expand("Schedule", xa => {
                    xa.Select("CycleUnit"),
                      xa.Select("Cycle"),
                      xa.Expand("InspectionDuty", xaa => {
                        xaa.Select("OperatorTask"),
                          xaa.Expand("OperatorTask", xaaa => {
                            xaaa.Select("Guidelines"),
                              xaaa.Select("Qualification")
                          })
                      }),
                      xa.Expand("MaintenanceContract", xab => {
                        xab.Select("StartDate"),
                          xab.Expand("ServiceProvider", xabb => {
                            xabb.Select("Name")
                          })
                      })
                  }),
                    x.Expand("Building", xb => {
                      xb.Select("Name"),
                        xb.Select("Id"),
                        xb.Select("Identity")
                    }),
                    x.Expand("Equipment", xc => {
                      xc.Select("Name"),
                        xc.Select("Id"),
                        xc.Select("Identity"),
                        xc.Select("CustomId")
                    }),
                    x.Expand("ServiceProvider", xd => {
                      xd.Select("Name"),
                        xd.Select("Id"),
                        xd.Select("Identity")
                    }),
                    x.Expand("Documents", xe => {
                      xe.Select("DocumentType"),
                        xe.Expand("DocumentType", xea => {
                          xea.Select("Id"),
                            xea.Select("Name")
                        })
                    }),
                    x.Expand("KpiMarker")
                })
                .Exec().then(x => x.value))
                .pipe(map(res => res.map(i => {
                  Utils.mapAllJSONDatesToDates(i);
                  return Object.assign(new Incident(), i)
                })))
            }),
            tap(inc => {
              inc.sort((a, b) => {
                return (b.DueDate ? b.DueDate.getTime() : 0) - (a.DueDate ? a.DueDate.getTime() : 0);
              });
            }));
      }));

    this.inputData.currentView$ = this.route.paramMap
      .pipe(map(p => IncidentView.getIncidentView(p.get('tab'))), tap(val => (this.currentViewTab = val)));

    this.inputData.currentType$ = this.route.paramMap
      .pipe(map(p => IncidentType.getIncidentType(p.get('subtab'))), tap(val => (this.currentTypeTab = val)));

    this.inputData.currentIncidentId$ = this.route.parent.parent.parent.parent.children[1].paramMap
      .pipe(map(params => +params.get('id')));

    this.inputData.showEquipmentData = false;

    if (this.navigator.getNavigationAction() === IncidentState.NAVIGATION_ACTION_NEXT_INCIDENT) {
      this.inputData.navigateToFirstListItem = true;
    }
    this.navigator.finishNavigation();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleViewTabChangeRequest($event: string) {
    this.currentViewTab = IncidentView.getIncidentView($event);
    this.router.navigate([{tab: [this.currentViewTab]}], {relativeTo: this.route, queryParamsHandling: 'merge'});
  }

  handleTypeTabChangeRequest($event: string) {
    this.currentTypeTab = IncidentType.getIncidentType($event);
    this.router.navigate([{tab: [this.currentViewTab], subtab: [this.currentTypeTab]}], {
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
  }

  handleDetailPageRequest($event: IncidentDetailLinkOutputData) {
    const id = $event.id;
    const subTab = $event.type;

    if (subTab) {
      this.router.navigate([
        'building',
        this.buildingId,
        {
          outlets: {
            left: [
              'equipment', this.equipmentId, 'view',
              {
                outlets: {
                  tab: ['incident', {
                    outlets: {
                      'incidentstyle': ['list', {
                        tab: [this.currentViewTab],
                        subtab: [subTab]
                      }]
                    }
                  }]
                }
              }
            ],
            right: ['equipment', 'incident', id]
          }
        }
      ]);
    } else {
      this.router.navigate([
        'building',
        this.buildingId,
        {
          outlets: {
            left: ['equipment', this.equipmentId, 'view', {outlets: {tab: ['incident', {outlets: {'incidentstyle': ['list', {tab: ['all']}]}}]}}],
            right: ['equipment', 'incident', id]
          }
        }
      ]);
    }
  }
}
