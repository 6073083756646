import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EquipmentWizardModel } from './../equipment-wizard-model';
import { EquipmentWizardNavigationPaths } from './../equipment-wizard-navigation-paths.enum';
import { EquipmentWizardService } from './../equipment-wizard.service';
import { ObjektBrief } from 'app/core/odata/odata.coreapi';


@Component({
  selector: 'app-equipment-wizard-progress',
  templateUrl: './equipment-wizard-progress.component.html',
  styleUrls: ['./equipment-wizard-progress.component.scss']
})
export class EquipmentWizardProgressComponent implements OnInit {

  PAGE_DATA = EquipmentWizardNavigationPaths.DATA;
  PAGE_TYPE = EquipmentWizardNavigationPaths.TYPE;
  PAGE_TECHNICAL = EquipmentWizardNavigationPaths.TECHNICAL;

  model: EquipmentWizardModel;
  AttributeType: any;

  @Output()
  pageChangeRequest = new EventEmitter<EquipmentWizardNavigationPaths>();

  @Input()
  currentPage: EquipmentWizardNavigationPaths;

  constructor(private service: EquipmentWizardService) { }

  ngOnInit() {
    this.model = this.service.getCurrentEquipment();
    this.AttributeType = ObjektBrief.AttributeValueTypes;
  }

  navigate(pageChangeEvent: EquipmentWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }

}
