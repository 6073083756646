import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthGuard } from './../core/auth/auth-guard.service';
import { CoreModule } from './../core/core.module';
import { SearchBuildingComponent } from './search/search-building/search-building.component';
import { SearchInstallEquipmentComponent } from './search/search-install-equipment/search-install-equipment.component';
import { SearchInstallModuleComponent } from './search/search-install-module/search-install-module.component';
import { SearchComponent } from './search/search.component';

const routes: Routes = [
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: SearchComponent
      }
    ]
  },
  {
    path: 'search/:id',
    component: SearchComponent,
    canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule.forChild(routes),
    CoreModule],
  declarations: [
    SearchComponent,
    SearchBuildingComponent,
    SearchInstallEquipmentComponent,
    SearchInstallModuleComponent
  ],
  exports: [RouterModule]
})
export class SearchModule {}
