import { Injectable } from "@angular/core";
import { PropertyCore } from "app/core/odata/odata.coreapi";
import { Observable, from } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { switchMap, map } from "rxjs/operators";
import { ODataPath } from "app/core/odata/odataclient";
import { Guid } from "guid-typescript";
import { Utils } from "app/core/tools/utils";
import { ODataCoreService } from "app/core/odata-services/odata.coreapi.service";
@Injectable()
export class InspectiondutyWizardApiService {
  constructor(private odataCoreService: ODataCoreService) {}

  getEquipmentAllData(id: Guid): Observable<PropertyCore.Equipment> {
    return from(
      this.odataCoreService.Equipment.Query()
        .Expand((x) => {
          x.Expand("Building"),
            x.Expand("EquipmentType"),
            x.Expand("Room", (xa) => xa.Expand("Floor"));
        })
        .Filter((x) => x.EqualsField("Identity", id))
        .Exec()
        .then((x) => x.value)
    )
      .pipe(map((res) => res[0]))
      .pipe(
        switchMap((simpleEquipment) => {
          return from(
            this.odataCoreService.Building.Query()
              .Expand((b) => {
                b.Expand("Equipments", (xaa) => {
                  xaa.Expand("EquipmentType"),
                    xaa.Expand("OperatorTaskMarkers", (xaaa) => {
                      xaaa.Expand("OperatorTask", (xaaaa) => {
                        xaaaa.Select("Id");
                        xaaaa.Select("Identity");
                        xaaaa.Select("Guidelines");
                        xaaaa.Select("Subject");
                        xaaaa.Select("Deadline");
                        xaaaa.Select("Qualification");
                        xaaaa.Select("Cycle");
                        xaaaa.Select("CycleUnit");
                        xaaaa.Select("IsRelevant");
                      });
                      xaaa.Filter((z) =>
                        z
                          .Equals(
                            ODataPath.For("OperatorTask", "IsRelevant"),
                            true
                          )
                          .Or.EqualsField("IsMissing", false)
                      );
                    });
                  xaa.Expand("InspectionDuties", (y) => {
                    y.Expand("Schedule", (z) => {
                      z.Select("Identity");
                      z.Select("Cycle");
                      z.Select("CycleUnit");
                      z.Select("CycleStart");
                    }),
                      y.Expand("OperatorTask", (z) => {
                        z.Select("Identity");
                        z.Select("Id");
                      });
                    y.Filter((z) => z.EqualsField("IsDisabled", false));
                  }),
                    xaa.Expand("Room", (room) => room.Expand("Floor"));
                });
              })
              .Filter((b) =>
                b.EqualsField(
                  "Identity",
                  Guid.parse(simpleEquipment.Building.Identity)
                )
              )
              .Exec()
              .then((x) => x.value)
          )
            .pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])))
            .pipe(
              map((b) => {
                simpleEquipment.Building = b;
                return simpleEquipment;
              })
            )
            .pipe(map((res) => Utils.mapAllJSONDatesToDates(res)));
        })
      );

    //TODO: Delete nach test
    // return from(this.odataCoreService.Equipment.Query()
    //     .Expand(x => {
    //         x.Expand("Building", xa => {
    //             xa.Expand("Equipments", xaa => {
    //                 xaa.Expand("EquipmentType"),
    //                     xaa.Expand("OperatorTaskMarkers", xaaa => {
    //                         xaaa.Expand("OperatorTask", xaaaa => {
    //                             xaaaa.Select("Id")
    //                             xaaaa.Select("Identity")
    //                             xaaaa.Select("Guidelines")
    //                             xaaaa.Select("Subject")
    //                             xaaaa.Select("Deadline")
    //                             xaaaa.Select("Qualification")
    //                             xaaaa.Select("Cycle")
    //                             xaaaa.Select("CycleUnit")
    //                             xaaaa.Select("IsRelevant")
    //                         })
    //                         xaaa.Filter(z => z
    //                             .Equals(ODataPath.For("OperatorTask", "IsRelevant"), true).Or
    //                             .EqualsField("IsMissing", false))

    //                     })
    //                 xaa.Expand("InspectionDuties", y => {
    //                     y.Expand("Schedule", z => {
    //                         z.Select("Identity")
    //                         z.Select("Cycle")
    //                         z.Select("CycleUnit")
    //                         z.Select("CycleStart")
    //                     }),
    //                         y.Expand("OperatorTask", z => {
    //                             z.Select("Identity")
    //                             z.Select("Id")
    //                         })
    //                     y.Filter(z => z
    //                         .EqualsField("IsDisabled", false))
    //                 })
    //             })
    //         }),

    //             x.Expand("EquipmentType"),
    //             x.Expand("Room", xa =>
    //                 xa.Expand("Floor"))
    //     })
    //     .Filter(x =>
    //         x.EqualsField("Identity", id))
    //     .Exec().then(x => x.value))
    //     .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])));
  }
}
