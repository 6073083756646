import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { combineLatest, Subscription } from "rxjs";
import { PropertyCore } from "../../../odata/odata.coreapi";
import { InstallEquipmentKpiFilter } from "./../../../../building/kpiFilter.enum";
import { AuthService } from "./../../../auth/auth.service";
import { EquipmentListInputData } from "./../equipment-list-input-data";

@Component({
  selector: "app-core-equipment-list",
  templateUrl: "./core-equipment-list.component.html",
  styleUrls: ["./core-equipment-list.component.scss"],
})
export class CoreEquipmentListComponent implements OnInit, OnDestroy {
  @Input() inputData: EquipmentListInputData;

  @Output() detailPageRequest = new EventEmitter<string>();
  @Output() equipmentWizardRequest = new EventEmitter<any>();
  @Output() kpiFilterChange = new EventEmitter<InstallEquipmentKpiFilter>();
  @Output() listEmptyRequest = new EventEmitter<void>();

  installEquipments: PropertyCore.Equipment[];
  existIE: boolean;
  buildings: PropertyCore.Building[] = [];
  selectedInstallEquipmentUT: string;
  activeIds: string[] = [];
  equipments: PropertyCore.EquipmentType[];
  equipmentFilter: any = "";
  _kpiFilter: InstallEquipmentKpiFilter = InstallEquipmentKpiFilter.Undefined;

  INSTALL_EQUIPMENT_KPI_FILTER_UNDEFINED = InstallEquipmentKpiFilter.Undefined;
  INSTALL_EQUIPMENT_KPI_FILTER_NO_MAINTENANCE_CONTRACT = InstallEquipmentKpiFilter.NoMaintenanceContract;
  INSTALL_EQUIPMENT_KPI_FILTER_NO_INSPECTION_DUTY_SELECTED = InstallEquipmentKpiFilter.NoInspectionDutySelected;
  INSTALL_EQUIPMENT_KPI_FILTER_HAS_INSPECTION_DUTY_ACTIVE = InstallEquipmentKpiFilter.HasInspectionDutyActive;
  INSTALL_EQUIPMENT_KPI_FILTER_NO_INSPECTION_DUTY_TERMINATED = InstallEquipmentKpiFilter.NoInspectionDutyTerminated;
  INSTALL_EQUIPMENT_KPI_FILTER_HAS_MAINTENANCE_CONTRACT = InstallEquipmentKpiFilter.HasMaintenanceContract;
  INSTALL_EQUIPMENT_KPI_FILTER_NO_MAINTENANCE_DESIRED = InstallEquipmentKpiFilter.NoMaintenanceDesired;
  INSTALL_EQUIPMENT_KPI_FILTER_RENTER_RESPONSIBILITY = InstallEquipmentKpiFilter.RenterResponsibility;

  subscriptions: Subscription = new Subscription();

  constructor(protected authService: AuthService) {}

  ngOnInit() {
    this._kpiFilter = this.inputData.presetFilter;
    let buildingsDict: { [name: string]: PropertyCore.Building } = {};

    this.subscriptions.add(
      combineLatest(
        this.inputData.installEquipments$,
        this.inputData.equipments$,
        this.inputData.currentInstallEquipmentUT$
      ).subscribe(([ie, e, sie]) => {
        this.installEquipments = ie;
        this.installEquipments.forEach((y) => {
          if (!buildingsDict[y.Building.Name]) {
            buildingsDict[y.Building.Name] = y.Building;
          }
        });

        this.buildings = Object.values(buildingsDict);
        this.activeIds = this.buildings.map((b) => b.Name);

        console.log(this.installEquipments);
        // only equipment types from existing install equipments
        this.equipments = e.filter((x) => ie.find((y) => y.EquipmentType.Id === x.Id));
        this.selectedInstallEquipmentUT = sie;

        this.existIE = !!(this.installEquipments.length || this.inputData.presetFilter);

        if (!this.installEquipments || this.installEquipments.length === 0) {
          this.listEmptyRequest.emit();
          return;
        }

        if (this.selectedInstallEquipmentUT) {
          this.inputData.lastIdx = this.installEquipments.findIndex(
            (ie) => ie.Identity === this.selectedInstallEquipmentUT
          );
        }

        if (this.inputData.navigateToFirstListItem && !this.selectedInstallEquipmentUT) {
          const idx =
            this.inputData.lastIdx === -1
              ? 0
              : this.inputData.lastIdx >= this.installEquipments.length
              ? this.installEquipments.length - 1
              : this.inputData.lastIdx;
          this.onListItemClick(this.installEquipments[idx]);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  installEquipmentWizard(): void {
    this.equipmentWizardRequest.emit();
  }

  onListItemClick(installEquipment: PropertyCore.Equipment) {
    this.selectedInstallEquipmentUT = installEquipment.Identity;
    if (this.selectedInstallEquipmentUT) {
      this.inputData.lastIdx = this.installEquipments.findIndex(
        (ie) => ie.Identity === this.selectedInstallEquipmentUT
      );
    }
    this.detailPageRequest.emit(installEquipment.Identity);
  }

  isSelected(UT: string) {
    return this.selectedInstallEquipmentUT === UT;
  }

  changeActiveStatus(activeId: string): void {
    if (this.activeIds.indexOf(activeId) !== -1) {
      this.activeIds = this.activeIds.filter((name) => name !== activeId);
    } else {
      this.activeIds.push(activeId);
    }
  }

  set kpiFilter(kpiFilter: InstallEquipmentKpiFilter) {
    this._kpiFilter = kpiFilter;
    this.kpiFilterChange.emit(kpiFilter);
  }
  get kpiFilter() {
    return this._kpiFilter;
  }
}
