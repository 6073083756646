import { Injectable } from "@angular/core";
import { AppConfig } from "app/core/bootstrap/app.config";
import { environment } from "../../../../environments/environment";

declare const window: any;

@Injectable()
export class GoogleAnalyticsService {
  constructor(private config: AppConfig) {
    // if (environment.ga) {
    //   this.loadGoogleAnalytics();
    // }
  }

  public loadGoogleAnalytics(): void {
    if (!this.config.getGaId()) {
      return;
    }
    window.dataLayer = window.dataLayer || [];

    this.add({
      ua_propertyID: this.config.getGaId(),
      environment_name: this.config.getGaEnvName(),
      site_section: this.config.getGaSection(),
      user_login_state: "logged-in", //static, because the platforms are only used logged in
      user_login_type: "customer_group_x", //see section “Login and Customer Group Tracking”
      event: "common_data_ready",
    });

    this.addEvent("data_ready");
  }

  public add(obj: any): void {
    window.dataLayer.push(obj);
  }

  public addEvent(name: string): void {
    window.dataLayer.push({ event: name });
  }
}
