import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  @Output()
  clickOutside = new EventEmitter<MouseEvent>();

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event', '$event.target'])
  @HostListener('document:touchstart', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    if (!this._elementRef.nativeElement.contains(targetElement)) {
      this.clickOutside.emit(event);
    }
  }

  // @HostListener('document:click', ['$event.path'])
  // @HostListener('document:touchstart', ['$event.path'])
  // public onGlobalClick(targetElementPath: Array<any>) {
  //   const elementRefInPath = targetElementPath.find(e => e === this._elementRef.nativeElement);
  //   if (!elementRefInPath) {
  //     this.clickOutside.emit(null);
  //   }
  // }
}
