import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { combineLatest, Observable, Subscription, timer, from } from "rxjs";
import { take } from "rxjs/operators";
import { Common, PropertyCore, Operations } from "../../../odata/odata.coreapi";
import { AuthService } from "../../../auth/auth.service";
import { Incident } from "../../../model/incident";
import { ConfirmationModal } from "../../../popup/confirmation.modal";
import { Utils } from "../../../tools/utils";
import { IncidentCreateOuputData } from "../incident-create-output-data";
import { IncidentDetailNavigationPaths } from "./incident-detail-navigation-paths.enum";
import { LegacyIncident } from "../../../model/legacyIncident";
import { ODataCoreService } from "../../../odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";
import { map } from "rxjs/internal/operators/map";
import { ODataPath } from "../../../odata/odataclient";

@Component({
  selector: "app-incident-detail",
  templateUrl: "./incident-detail.component.html",
  styleUrls: ["./incident-detail.component.scss"],
})
export class IncidentDetailComponent implements OnInit, OnDestroy {
  @Input()
  incident$: Observable<Incident>;

  @Input()
  defects$: Observable<LegacyIncident[]>;

  @Input()
  isIncidentView: boolean;

  @Input()
  showClose: boolean;

  @Output()
  closeRequest = new EventEmitter<any>();

  @Output()
  createRequest = new EventEmitter<IncidentCreateOuputData>();

  @Output()
  postSimpleSaveRequest = new EventEmitter<any>();

  @Output()
  postSaveRequest = new EventEmitter<any>();

  @Output()
  setProcessedRequest = new EventEmitter<any>();

  @Output()
  refreshListRequest = new EventEmitter<string>();

  currentPage: IncidentDetailNavigationPaths;

  PAGE_INCIDENT_DETAIL = IncidentDetailNavigationPaths.INCIDENT_DETAIL;
  PAGE_ADD_SERVICE_CONTACT = IncidentDetailNavigationPaths.ADD_SERVICE_CONTACT;

  incident: Incident;
  defects: LegacyIncident[];

  // not used
  now: Date = new Date();
  nowNgbDateStruct = Utils.dateToDatepicker(this.now);

  serviceProviders: PropertyCore.ServiceProvider[] = [];

  editMode: boolean;
  editIncident: Incident;
  editHeaderDataMode: boolean;
  formSubmitted: boolean;

  canEdit: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.incident$.subscribe((res) => {
        this.incident = res;
        this.cancelEdit();

        this.canEdit = false;

        this.odataCoreService.Event.Get()
          .Key(Guid.parse(this.incident.Identity))
          .Actions()
          .CanOperateInOperationsOnEventInPropertyCore()
          .Parameters(Operations.Operations.Update)
          .Execute()
          .then((canEdit) => {
            if (canEdit.CanExecute == true) {
              this.canEdit = true;
            } else {
              this.canEdit = false;
            }
          });
      })
    );
    this.subscriptions.add(this.defects$.subscribe((res) => (this.defects = res)));

    this.currentPage = this.PAGE_INCIDENT_DETAIL;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private close(): void {
    this.closeRequest.emit();
  }

  edit(editHeaderData: boolean = false): void {
    this.editMode = !editHeaderData;
    this.editHeaderDataMode = editHeaderData;
    this.editIncident = Object.assign({}, this.incident);

    // create subobjects if they do not exist
    if (!this.editIncident.Response) {
      this.editIncident.Response = <PropertyCore.EventResponse>{};
    }

    if (!this.serviceProviders.length) {
      this.subscriptions.add(
        from(
          this.odataCoreService.ServiceProvider.Query()
            .Filter((x) => x.Equals(ODataPath.For("Account", "Identity"), Guid.parse(localStorage.getItem("mandator"))))
            .Exec()
            .then((x) => x.value)
        )
          .pipe(map((res) => res.map((i) => Utils.mapAllJSONDatesToDates(i))))
          .subscribe((res) => {
            this.serviceProviders = res;
          })
      );
    }

    if (!editHeaderData) {
      // scroll to bottom after 1 ms so that DOM is already in edit mode
      const scrollContent = document.getElementsByClassName("scroll-content incident-detail");
      timer(1)
        .pipe(take(1))
        .subscribe(() => (scrollContent[0].scrollTop = scrollContent[0].scrollHeight));
    }
  }

  processPageChangeRequest(pageChangeEvent: IncidentDetailNavigationPaths): void {
    switch (pageChangeEvent) {
      case IncidentDetailNavigationPaths.INCIDENT_DETAIL: {
        if (this.currentPage === this.PAGE_ADD_SERVICE_CONTACT) {
          this.currentPage = this.PAGE_INCIDENT_DETAIL;
        }
        break;
      }
      case IncidentDetailNavigationPaths.ADD_SERVICE_CONTACT: {
        if (this.currentPage === this.PAGE_INCIDENT_DETAIL) {
          this.currentPage = this.PAGE_ADD_SERVICE_CONTACT;
        }
        break;
      }
    }
  }

  saveNewServiceContact(serviceContact: PropertyCore.ServiceProvider) {
    this.subscriptions.add(
      from(
        this.odataCoreService.ServiceProvider.Query()
          .Filter((x) => x.Equals(ODataPath.For("Account", "Identity"), Guid.parse(localStorage.getItem("mandator"))))
          .Exec()
          .then((x) => x.value)
      )
        .pipe(map((res) => res.map((i) => Utils.mapAllJSONDatesToDates(i))))
        .subscribe((res) => {
          this.serviceProviders = res;
        })
    );
    this.processPageChangeRequest(IncidentDetailNavigationPaths.INCIDENT_DETAIL);
  }

  cancelEdit(): void {
    this.editMode = false;
    this.editHeaderDataMode = false;
    this.formSubmitted = false;
    this.editIncident = null;
  }

  validRefDate(): boolean {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let tomorrowNgbDateStruct = Utils.dateToDatepicker(tomorrow);

    return !!(
      this.editIncident.IsSkipped ||
      (this.editIncident.RefDate && this.editIncident.RefDate <= Utils.datepickerToDate(tomorrowNgbDateStruct))
    );
  }

  save(validForm: boolean, navToDocumentWizard: boolean = false): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.subscriptions.add(
      from(
        this.odataCoreService.Event.Patch()
          .Key(Guid.parse(this.editIncident.Identity))
          .ValueType(this.odataCoreService.ODataTypes().Event())
          .ValueProperty("DueDate", this.editIncident.DueDate)
          .ValueProperty("IsSkipped", this.editIncident.IsSkipped)
          .Exec()
          .then((x) => x)
      ).subscribe((res1) => {
        this.subscriptions.add(
          from(
            this.odataCoreService.Event.Query()
              .Select("Identity")
              .Filter((x) => {
                x.EqualsField("Identity", Guid.parse(this.editIncident.Identity));
              })
              .Expand((x) => {
                x.Expand("Response");
                x.Expand("ServiceProvider");
              })
              .Exec()
              .then((x) => x.value)
          )
            .pipe(map((res2) => res2[0]))
            .subscribe((res2) => {
              if (res2.ServiceProvider == null && this.editIncident.ServiceProvider != null) {
                this.subscriptions.add(
                  from(
                    this.odataCoreService.Event.Link()
                      .Key(Guid.parse(this.editIncident.Identity))
                      .Value(
                        "ServiceProvider",
                        this.odataCoreService.ServiceProvider.Get()
                          .Key(Guid.parse(this.editIncident.ServiceProvider.Identity))
                          .Bind()
                      )
                      .Post()
                  ).subscribe((res3) => {
                    this.eventResponse(res2.Response, navToDocumentWizard);
                  })
                );
              } else if (
                res2.ServiceProvider &&
                this.editIncident.ServiceProvider &&
                this.editIncident.ServiceProvider.Identity &&
                res2.ServiceProvider.Identity != this.editIncident.ServiceProvider.Identity
              ) {
                this.odataCoreService.Event.Link()
                  .Key(Guid.parse(this.editIncident.Identity))
                  .Value(
                    "ServiceProvider",
                    this.odataCoreService.ServiceProvider.Get().Key(Guid.parse(res2.ServiceProvider.Identity)).Bind()
                  )
                  .Delete();
                this.subscriptions.add(
                  from(
                    this.odataCoreService.Event.Link()
                      .Key(Guid.parse(this.editIncident.Identity))
                      .Value(
                        "ServiceProvider",
                        this.odataCoreService.ServiceProvider.Get()
                          .Key(Guid.parse(this.editIncident.ServiceProvider.Identity))
                          .Bind()
                      )
                      .Post()
                  ).subscribe((res3) => {
                    this.eventResponse(res2.Response, navToDocumentWizard);
                  })
                );
              } else if (
                res2.ServiceProvider &&
                (!this.editIncident.ServiceProvider || !this.editIncident.ServiceProvider.Identity)
              ) {
                this.subscriptions.add(
                  from(
                    this.odataCoreService.Event.Link()
                      .Key(Guid.parse(this.editIncident.Identity))
                      .Value(
                        "ServiceProvider",
                        this.odataCoreService.ServiceProvider.Get()
                          .Key(Guid.parse(res2.ServiceProvider.Identity))
                          .Bind()
                      )
                      .Delete()
                  ).subscribe((res3) => {
                    this.eventResponse(res2.Response, navToDocumentWizard);
                  })
                );
              } else {
                this.eventResponse(res2.Response, navToDocumentWizard);
              }
            })
        );
      })
    );
  }

  eventResponse(eventResponse: any, navToDocumentWizard: boolean = false): void {
    //if (eventResponse != null && this.editIncident.RefDate != null) {
    if (eventResponse != null) {
      this.odataCoreService.Event.Get()
        .Key(Guid.parse(this.editIncident.Identity))
        .NavigateToArray<PropertyCore.EventResponse>("Response")
        .Patch()
        .ValueType(this.odataCoreService.ODataTypes().EventResponse())
        .ValueProperty("Comment", this.editIncident.Response.Comment ? this.editIncident.Response.Comment : "")
        .ValueProperty(
          "DefectRating",
          this.editIncident.IsSkipped
            ? PropertyCore.DefectRatings.Undefined
            : this.editIncident.IsDefectHandler
            ? PropertyCore.DefectRatings.Unrated
            : PropertyCore.DefectRatings.Undefined
        ) // skipped incident does not have defect))
        .ValuePropertyNullable("ReferenceDate", this.editIncident.IsSkipped ? null : this.editIncident.RefDate) // skipped incident does not have refdate
        .Exec()
        .then((res) => {
          this.toasterService.pop("info", "", this.translateService.instant("IncidentDetail.save_success"));
          if (navToDocumentWizard) {
            this.postSaveRequest.emit(this.editIncident.Building.Identity);
          } else {
            this.postSimpleSaveRequest.emit(this.editIncident.Building.Identity);
            this.cancelEdit();
            // reload current incident data
            this.subscriptions.add(this.reloadData());
          }
        });
      //} else if (eventResponse == null && this.editIncident.RefDate != null) {
    } else if (eventResponse == null) {
      this.subscriptions.add(
        from(
          this.odataCoreService.Event.Get()
            .Key(Guid.parse(this.editIncident.Identity))
            .NavigateToArray<PropertyCore.EventResponse>("Response")
            .Post()
            .ValueType(this.odataCoreService.ODataTypes().EventResponse())
            .ValueProperty("Comment", this.editIncident.Response.Comment ? this.editIncident.Response.Comment : "")
            .ValueProperty(
              "DefectRating",
              this.editIncident.IsSkipped
                ? PropertyCore.DefectRatings.Undefined
                : this.editIncident.IsDefectHandler
                ? PropertyCore.DefectRatings.Unrated
                : PropertyCore.DefectRatings.Undefined
            ) // skipped incident does not have defect))
            .ValuePropertyNullable("ReferenceDate", this.editIncident.IsSkipped ? null : this.editIncident.RefDate) // skipped incident does not have refdate
            .Exec()
            .then((x) => x.value)
        ).subscribe((res) => {
          this.toasterService.pop("info", "", this.translateService.instant("IncidentDetail.save_success"));
          if (navToDocumentWizard) {
            this.postSaveRequest.emit(this.editIncident.Building.Identity);
          } else {
            this.postSimpleSaveRequest.emit(this.editIncident.Building.Identity);
            this.cancelEdit();
            // reload current incident data
            this.subscriptions.add(this.reloadData());
          }
        })
      );
    } else {
      this.toasterService.pop("info", "", this.translateService.instant("IncidentDetail.save_success"));
      if (navToDocumentWizard) {
        this.postSaveRequest.emit(this.editIncident.Building.Identity);
      } else {
        this.postSimpleSaveRequest.emit(this.editIncident.Building.Identity);
        this.cancelEdit();
        // reload current incident data
        this.subscriptions.add(this.reloadData());
      }
    }
  }
  create(): void {
    const data: IncidentCreateOuputData = new IncidentCreateOuputData();
    data.buildingUT = this.incident.Building.Identity;
    data.incidentId = this.incident.Id;
    this.createRequest.emit(data);
  }

  setProcessed(): void {
    this.setProcessedRequest.emit();

    this.odataCoreService.Event.Get()
      .Key(Guid.parse(this.incident.Identity))
      .NavigateToArray<PropertyCore.EventResponse>("Response")
      .Patch()
      .ValueType(this.odataCoreService.ODataTypes().EventResponse())
      .ValueProperty("IsDefectProcessed", true)
      .Exec()
      .then((res) => {
        this.toasterService.pop("info", "", this.translateService.instant("IncidentDetail.setProcessed_success"));

        // reload current incident data
        this.subscriptions.add(this.reloadData());
      });
  }

  private reloadData(): Subscription {
    this.refreshListRequest.emit();
    return combineLatest(this.incident$, this.defects$).subscribe(([i, d]) => {
      this.incident = i;
      this.defects = d;
    });
  }

  canDelete(): void {
    this.subscriptions.add(
      from(
        this.odataCoreService.Event.Get()
          .Key(Guid.parse(this.incident.Identity))
          .Actions()
          .CanOperateInOperationsOnEventInPropertyCore()
          .Parameters(Operations.Operations.Delete)
          .Execute()
      ).subscribe((res) => {
        if (res.CanExecute == true) {
          let deleteAll: boolean = false;
          // Wartung or Individual repeating (not one time) without maintenance contract mapping
          if (
            (this.incident.getTypeShort === "P" ||
              this.incident.getTypeShort === "W" ||
              this.incident.getTypeShort === "S" ||
              this.incident.getTypeShort === "I") &&
            (!this.incident.Schedule || this.incident.Schedule.CycleUnit !== Common.IntervalUnits.Undefined) &&
            (!this.incident.Schedule || !this.incident.Schedule.MaintenanceContract)
          ) {
            deleteAll = true;
          }

          const modalRef = this.modalService.open(ConfirmationModal);
          modalRef.componentInstance.title = this.translateService.instant("IncidentDetail._modal_title");
          modalRef.componentInstance.message = deleteAll
            ? this.translateService.instant("IncidentDetail._modal_message")
            : this.translateService.instant("IncidentDetail._modal_message_all");
          modalRef.componentInstance.yesButton = this.translateService.instant("IncidentDetail._modal_yes");
          modalRef.componentInstance.cancelButton = this.translateService.instant("IncidentDetail._modal_cancel");

          modalRef.result
            .then((val) => {
              if (val === ConfirmationModal.YES_VALUE) {
                this.delete();
              }
            })
            .catch(() => {
              // do nothing, just stay on page
            });
        } else if (res.CanExecute == false) {
          let deleteAll: boolean = false;
          if (res.Blocker.length == 1 && res.Blocker[0].Property == "Schedule") {
            if (this.incident.Schedule.CycleUnit == Common.IntervalUnits.Undefined && this.incident.Response == null) {
              deleteAll = true;
            } else if (this.incident.Response != null) {
              deleteAll = null;
            } else {
              deleteAll = false;
            }

            if (deleteAll != null) {
              const modalRef = this.modalService.open(ConfirmationModal);
              modalRef.componentInstance.title = this.translateService.instant("IncidentDetail._modal_title");
              modalRef.componentInstance.message = deleteAll
                ? this.translateService.instant("IncidentDetail._modal_message")
                : this.translateService.instant("IncidentDetail._modal_message_all");
              modalRef.componentInstance.yesButton = this.translateService.instant("IncidentDetail._modal_yes");
              modalRef.componentInstance.cancelButton = this.translateService.instant("IncidentDetail._modal_cancel");

              modalRef.result
                .then((val) => {
                  if (val === ConfirmationModal.YES_VALUE) {
                    this.subscriptions.add(
                      from(
                        this.odataCoreService.Schedule.Delete().Key(Guid.parse(this.incident.Schedule.Identity)).Exec()
                      ).subscribe(
                        (res) => {
                          this.toasterService.pop(
                            "info",
                            "",
                            this.translateService.instant("IncidentDetail.delete_success")
                          );
                          this.cancelEdit();
                          this.router.navigate([
                            "/building",
                            this.incident.Building.Identity,
                            {
                              outlets: {
                                left: ["list"],
                                right: ["building", "view", { outlets: { tab: ["incident"] } }],
                              },
                            },
                          ]);
                        },
                        (err) => {
                          this.toasterService.pop("error", "Fehler", err);
                        }
                      )
                    );
                  }
                })
                .catch(() => {
                  // do nothing, just stay on page
                });
            } else {
              const modalRef = this.modalService.open(ConfirmationModal);
              modalRef.componentInstance.title = this.translateService.instant("IncidentDetail._modal_title");
              modalRef.componentInstance.message = this.translateService.instant(
                "IncidentDetail._modal_message_event_response"
              );
              modalRef.componentInstance.hideYesButton = true;
              modalRef.componentInstance.cancelButton = this.translateService.instant("IncidentDetail._modal_cancel");

              modalRef.result.catch(() => {
                // do nothing, just stay on page
              });
            }
          } else {
            let deleteMessage: boolean = false;

            if (this.incident.getTypeShort === "W") {
              deleteMessage = true;
            }

            const modalRef = this.modalService.open(ConfirmationModal);
            modalRef.componentInstance.title = this.translateService.instant("IncidentDetail._modal_title");
            modalRef.componentInstance.message = deleteMessage
              ? this.translateService.instant("IncidentDetail._modal_message_wartungsvertrag")
              : this.translateService.instant("IncidentDetail._modal_message_prüfung");
            modalRef.componentInstance.hideYesButton = true;
            modalRef.componentInstance.cancelButton = this.translateService.instant("IncidentDetail._modal_cancel");

            modalRef.result.catch(() => {
              // do nothing, just stay on page
            });
          }
        }
      })
    );
  }

  private delete(): void {
    this.subscriptions.add(
      from(this.odataCoreService.Event.Delete().Key(Guid.parse(this.incident.Identity)).Exec()).subscribe(
        (res) => {
          this.toasterService.pop("info", "", this.translateService.instant("IncidentDetail.delete_success"));
          this.cancelEdit();
          this.router.navigate([
            "/building",
            this.incident.Building.Identity,
            {
              outlets: {
                left: ["list"],
                right: ["building", "view", { outlets: { tab: ["incident"] } }],
              },
            },
          ]);
        },
        (err) => {
          this.toasterService.pop("error", "Fehler", err);
        }
      )
    );
  }

  goToDefects() {
    // right side is same, so we need hard reload
    const url = this.router
      .createUrlTree([
        "/building",
        this.incident.Building.Identity,
        {
          outlets: {
            left: ["building", "view", { outlets: { tab: ["defect"] } }],
            right: ["incident", this.incident.Id, {}],
          },
        },
      ])
      .toString();
    this.router.navigateByUrl("/skipThisPage", { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
  }

  sendMail() {
    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Get()
          .Key(Guid.parse(this.incident.Building.Identity))
          .Expand((x) => {
            x.Expand("UsageTypes");
            x.Expand("Statistics");
          })
          .Exec()
          .then((x) => x.value)
      )
        .pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])))
        .subscribe((building) => {
          location.href =
            "mailto:netinform.re@tuvsud.com?Subject=" +
            encodeURIComponent("Anfrage für Prüfung") +
            "&Body=" +
            encodeURIComponent(
              "Sehr geehrter Herr Maier,\n\n" +
                "bitte senden Sie uns ein Angebot für die Prüfung folgender Anlage:\n" +
                this.incident.Guidelines +
                ", " +
                Utils.performPipeOperation("dateFormat", this.incident.DueDate) +
                "\n" +
                this.incident.Building.Name +
                ", " +
                building.Address.Street +
                " " +
                building.Address.No +
                ", " +
                building.Address.PostCode +
                ", " +
                building.Address.City +
                "\n" +
                (this.incident.Equipment.CustomId
                  ? this.incident.Equipment.Name + ", " + this.incident.Equipment.CustomId
                  : this.incident.Equipment.Name) +
                ", " +
                this.incident.Equipment.Room.Floor.Name +
                ", " +
                this.incident.Equipment.Room.Name +
                "\n" +
                document.location.origin +
                "/building/" +
                this.incident.Building.Identity +
                "/(left:building/view/(tab:incident/(incidentstyle:list;tab=all))//right:incident/" +
                this.incident.Id +
                ")" +
                "\n\n" +
                "Mit freundlichen Grüßen\n" +
                "" +
                localStorage.getItem("infoUserName") +
                " " +
                localStorage.getItem("infoUserSurname") +
                ", " +
                localStorage.getItem("infoUserEmail") +
                "\n"
            );
        })
    );
  }

  navigateToIncidents(incident: Incident): void {
    const url = this.router
      .createUrlTree([
        "/building",
        incident.Building.Identity,
        {
          outlets: {
            left: ["building", "view", { outlets: { tab: ["incident"] } }],
            right: ["incident", incident.Id, {}],
          },
        },
      ])
      .toString();
    this.router.navigateByUrl("/skipThisPage", { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
  }

  onIsSkippedChange(): void {
    this.editIncident.RefDate = null;
    if (!this.editIncident.Response) {
      this.editIncident.Response = <PropertyCore.EventResponse>{};
    }
    this.editIncident.IsDefectHandler = false;
  }

  compareProviders(p1: PropertyCore.ServiceProvider, p2: PropertyCore.ServiceProvider) {
    return p1 && p2 && p1.Identity == p2.Identity;
  }
}
