import { Injectable } from "@angular/core";
import { ODataCoreService } from "app/core/odata-services/odata.coreapi.service";
import { Utils } from "app/core/tools/utils";
import { Mandator } from "app/core/model/mandator";
import { pipe, from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Guid } from "guid-typescript";

@Injectable({
  providedIn: "root",
})
export class MandatorService {
  constructor(private odataCoreService: ODataCoreService) {}

  public getCurrentMandatorWithRights$(): Observable<Mandator> {
    return from(
      this.odataCoreService.Account.Query()
        .Filter((a) =>
          a.EqualsField(
            "Identity",
            Guid.parse(localStorage.getItem("mandator"))
          )
        )
        .Expand((a) => {
          a.Expand("Profile");
          a.Expand("Rights", (y) => {
            y.Filter((z) =>
              z.EqualsField(
                "Identity",
                Guid.parse(localStorage.getItem("infoUserIdentity"))
              )
            );
          });
          a.Expand("User"),
            a.Expand("Roles", (y) => {
              y.Expand("User");
            });
        })
        .Exec()
        .then((x) => x.value)
    ).pipe(
      map((res) => {
        Utils.mapAllJSONDatesToDates(res[0]);
        return Object.assign(new Mandator(), res[0]);
      })
    );
  }
}
