import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class EquipmentsNoContractState {

  public static readonly ACTION_SET_NO_CONTRACT = 'action_set_no_contract';
  public static readonly ACTION_SET_CONTRACT = 'action_set_contract';

  private subject: Subject<string> = new Subject();

  constructor() { }

  notify(notificationtext: string) {
    this.subject.next(notificationtext);
  }

  registerForNotification(): Observable<string> {
    return this.subject.asObservable();
  }

}
