import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {Subscription, from, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {EditInspectionDutyGroup} from '../../../core/components/equipment/edit-inspection-duties/editInspectionDutyGroup';
import {EquipmentEditInspectionDutiesInputData} from '../../../core/components/equipment/equipment-edit-inspection-duties-input-data';
import {EquipmentEditInspectionDutiesSaveOutputData} from '../../../core/components/equipment/equipment-edit-inspection-duties-save-output-data';
import {InstallEquipmentService} from '../../installEquipment.service';
import { Utils } from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { Common } from '../../../core/odata/odata.coreapi';
import { ODataPath } from '../../../core/odata/odataclient';
import { EditOperatorTask } from '../../../core/components/equipment/edit-inspection-duties/editOperatorTask';


@Component({
  selector: 'app-equipment-detail-edit-inspection-duties',
  templateUrl: './equipment-detail-edit-inspection-duties.component.html',
  styleUrls: ['./equipment-detail-edit-inspection-duties.component.scss']
})
export class EquipmentDetailEditInspectionDutiesComponent implements OnInit, OnDestroy {
  inputData = new EquipmentEditInspectionDutiesInputData();

  buildingUT: string;
  installEquipmentUT: string;
  showClose: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private installEquipmentService: InstallEquipmentService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.inputData.installEquipment$ = this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => {
      this.installEquipmentUT = params.get('id');
      return from(this.odataCoreService.Equipment.Query()
        .Expand(x => {
          x.Expand("Building"),
            x.Expand("EquipmentType"),
            x.Expand("Room", y =>
              y.Expand("Floor"))
        })
        .Filter(x =>
          x.EqualsField("Identity", Guid.parse(params.get('id'))))
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
    })); 
      
    this.inputData.equipmentOperatorTaskMarkers$ = this.route.parent.paramMap.pipe(switchMap((params: ParamMap) =>
         from(this.odataCoreService.Equipment.Get()
          .Key(Guid.parse(params.get('id')))
          .Expand(x => {
            x.Expand("OperatorTaskMarkers", y => {
              y.Expand("OperatorTask", z => {
                z.Select("Id")
                z.Select("Identity")
                z.Select("Guidelines")
                z.Select("Subject")
                z.Select("Deadline")
                z.Select("Qualification")
                z.Select("Cycle")
                z.Select("CycleUnit")
                z.Select("IsRelevant")
                z.Expand("ModuleTypes")
              })
              y.Filter(z => z
                .Equals(ODataPath.For("OperatorTask", "IsRelevant"), true).Or
                .EqualsField("IsMissing", false))
            }),
              x.Expand("InspectionDuties", y => {
                y.Expand("Schedule", z => {
                  z.Select("Identity")
                  z.Select("Cycle")
                  z.Select("CycleUnit")
                  z.Select("CycleStart")
                }),
                y.Expand("OperatorTask", z=> {
                  z.Select("Identity")
                  z.Select("Id")
                })
                y.Filter(z => z
                  .EqualsField("IsDisabled", false))
              })
          })
          .Select("Identity")
          .Exec()
          .then(x => x.value)))        
        , map(id => {
        const activatedInspectionDuties = id[0].InspectionDuties;
        const idg: EditOperatorTask[] = []
        id.forEach(x => {
          let a: number = 0;
          while (a < x.OperatorTaskMarkers.length) {

            let activatedOperatorTaskId =  x.OperatorTaskMarkers[a].OperatorTask.Id;

            let activatedInspectionDutyItem = 
              activatedInspectionDuties.filter(x => x.OperatorTask.Id == activatedOperatorTaskId).length != 0 ? 
              activatedInspectionDuties.filter(x => x.OperatorTask.Id == activatedOperatorTaskId)[0] : null;
          
            let idgItem;     
              idgItem = new EditOperatorTask();
              if(x.OperatorTaskMarkers[a].OperatorTask.ModuleTypes) {
                idgItem.imName = x.OperatorTaskMarkers[a].OperatorTask.ModuleTypes.map(m => m.Name).join(", ");
              }
              idgItem.guideline = x.OperatorTaskMarkers[a].OperatorTask.Guidelines;
              idgItem.deadline = x.OperatorTaskMarkers[a].OperatorTask.Deadline;
              idgItem.subject = x.OperatorTaskMarkers[a].OperatorTask.Subject;
              idgItem.qualification = x.OperatorTaskMarkers[a].OperatorTask.Qualification;
              idgItem.operatortaskId = x.OperatorTaskMarkers[a].OperatorTask.Id;
              idgItem.operatortaskIdentity = x.OperatorTaskMarkers[a].OperatorTask.Identity;
              idgItem.cycle = x.OperatorTaskMarkers[a].OperatorTask.Cycle;
              idgItem.cycleUnit = x.OperatorTaskMarkers[a].OperatorTask.CycleUnit;
              idgItem.isMissing = x.OperatorTaskMarkers[a].IsMissing;

              if (activatedInspectionDutyItem != null){
                idgItem.aCycle = activatedInspectionDutyItem.Schedule.Cycle;
                idgItem.aCycleUnit = activatedInspectionDutyItem.Schedule.CycleUnit;
                if(activatedInspectionDutyItem.Schedule.CycleStart == null){
                  idgItem.aCycleStart = null;
                  idgItem.aCycleStartIsNotEmpty = false;
                } else {
                  idgItem.aCycleStart = new Date(activatedInspectionDutyItem.Schedule.CycleStart);
                  idgItem.aCycleStartIsNotEmpty = true;
                }  
                idgItem.aInspectionDutyIdentity = activatedInspectionDutyItem.Identity;
              } else{
                idgItem.aCycle = null;
                idgItem.aCycleUnit = null;
                idgItem.aCycleStart = null;
                idgItem.aCycleStartIsNotEmpty = false;
                idgItem.aInspectionDutyIdentity = null;
              }

              idg.push(idgItem)         
            a++;
          }
        });
        idg.sort((a, b) => {
          const imNameCompare = a.imName ? a.imName.localeCompare(b.imName) : (b.imName ? -b.imName.localeCompare(a.imName) : 0);
          return imNameCompare ? imNameCompare : (a.guideline ? a.guideline.localeCompare(b.guideline) : (b.guideline ? -b.guideline.localeCompare(a.guideline) : 0));
        });
        return idg;
      }));

    this.subscriptions.add(this.route.root.firstChild.paramMap.subscribe(params => (this.buildingUT = params.get('id'))));
  
    this.inputData.showHeader = false;
    
    // this.inputData.showClose = this.route.snapshot.data.close;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleCloseRequest(): void {
    this.router.navigate([
      '/building',
      this.buildingUT,
      {
        outlets: {
          left: ['building', 'view', {outlets: {tab: ['equipment']}}],
          right: ['equipment', this.installEquipmentUT]
        }
      }
    ], {queryParamsHandling: 'merge'});
  }

  handleSaveRequest(): void {
        this.toasterService.pop('success', '', this.translateService.instant('EquipmentDetailEditInspection._toastr_success'));
        this.handleCloseRequest();
  }

}
