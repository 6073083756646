import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { combineLatest, Subscription, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PropertyCore } from '../../core/odata/odata.coreapi';
import { ConfirmationModal } from './../../core/popup/confirmation.modal';
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';



@Component({
  selector: 'app-building-room-detail',
  templateUrl: './building-room-detail.component.html',
  styleUrls: ['./building-room-detail.component.scss']
})
export class BuildingRoomDetailComponent implements OnInit, OnDestroy {

  buildingUT: string;

  room: PropertyCore.Room;

  editMode: boolean;
  editRoom: PropertyCore.Room;
  floors: PropertyCore.Floor[];
  formSubmitted: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(combineLatest(
      this.route.root.firstChild.paramMap.pipe(map(params => params.get('id'))),
      this.route.paramMap.pipe(map(params => params.get('id'))))
      .pipe(switchMap(([buildingUT, buildingRoomUT]) => {
        this.buildingUT = buildingUT;
        return from(this.odataCoreService.Room.Query()
          .Expand(x => {
            x.Expand("Floor");
            x.Expand("Statistics");
          })
          .Filter(x => x
            .EqualsField("Identity", Guid.parse(buildingRoomUT)))
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])));
      })).subscribe(room => {
        this.room = room;
        this.editMode = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async edit(): Promise<void> {
    this.editMode = true;
    this.editRoom = Object.assign({}, this.room);

    if (!this.floors) {
      let res = await this.odataCoreService.Building.Get()
        .Key(Guid.parse(this.buildingUT))
        .Expand(x => {
          x.Expand("Floors");
        })
        .Exec().then(x => x.value)
      this.floors = res[0].Floors.map(f => Utils.mapAllJSONDatesToDates(f)).sort((a, b) => a.Order - b.Order)
    }
  }

  close(isDirty: boolean = true):void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.message = this.translateService.instant('BuildingRoomDetail._modal_message');
      modalRef.componentInstance.title = this.translateService.instant('BuildingRoomDetail._modal_title');
      modalRef.componentInstance.yesButton = this.translateService.instant('BuildingRoomDetail._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('BuildingRoomDetail._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            if (this.editMode) {
              this.editMode = false;
            } else {
              this.router.navigate(['/building', this.buildingUT, { outlets: { left: ['list'], right: ['building'] } }]);
            }
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      if (this.editMode) {
        this.editMode = false;
      } else {
        this.router.navigate(['/building', this.buildingUT, { outlets: { left: ['list'], right: ['building'] } }]);
      }
    }
  }

  async save(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
      await this.odataCoreService.Room.Patch()
              .ValueType(this.odataCoreService.ODataTypes().Room())
              .Key(Guid.parse(this.editRoom.Identity))
              .ValueProperty("Name", this.editRoom.Name)
              .Exec()
      await this.odataCoreService.Room.Link()
              .Key(Guid.parse(this.editRoom.Identity))
              .Value("Floor", this.odataCoreService.Floor.Get().Key(this.editRoom.Floor.Id).Bind())
              .Post()
      this.room = Object.assign({}, this.editRoom);
      this.editMode = false;

      // hard reload of both sides
      const url = this.router.createUrlTree(['/building', this.buildingUT, { outlets: { left: ['room-list'], right: ['room', this.editRoom.Identity] } }]).toString();
      this.router.navigateByUrl('/skipThisPage', { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
                          
                          
  }

  async delete(): Promise<void> {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.message = this.translateService.instant('BuildingRoomDetail._modal_message_delete');
    modalRef.componentInstance.title = this.translateService.instant('BuildingRoomDetail._modal_title');
    modalRef.componentInstance.yesButton = this.translateService.instant('BuildingRoomDetail._button_delete');
    modalRef.componentInstance.cancelButton = this.translateService.instant('BuildingRoomDetail._button_cancel');

    modalRef.result
      .then((val) => {
        if (val === ConfirmationModal.YES_VALUE) {
          if (this.editRoom.Statistics.EquipmentCount > 0 || this.editRoom.Statistics.ModuleCount > 0) {
            this.toasterService.pop('warning', '', this.translateService.instant('BuildingRoomDetail._delete_error_info', { ieCount: this.editRoom.Statistics.EquipmentCount, imCount: this.editRoom.Statistics.ModuleCount }));
          } else {
            this.odataCoreService.Room.Delete()
              .Key(Guid.parse(this.editRoom.Identity))
              .Exec();
              // hard reload of left side and loading another room
              const url = this.router.createUrlTree(['/building', this.buildingUT, { outlets: { left: ['room-list'], right: null } }]).toString();
              this.router.navigateByUrl('/skipThisPage', { skipLocationChange: true }).then(() => this.router.navigateByUrl(url));
          }
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  compareId(val1: {Id: number}, val2: {Id: number}): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }
}
