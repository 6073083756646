import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ObjektBrief } from '../../../../core/odata/odata.coreapi';
import { ACTION } from '../../../../core/model/extendedMaintenanceContractMapping';
import { ContractWizardModel } from './../contract-wizard-model';
import { ContractWizardNavigationPaths } from './../contract-wizard-navigation-paths.enum';
import { ContractWizardService } from './../contract-wizard.service';

@Component({
  selector: 'app-contract-wizard-progress',
  templateUrl: './contract-wizard-progress.component.html',
  styleUrls: ['./contract-wizard-progress.component.scss']
})
export class ContractWizardProgressComponent implements OnInit {
  PAGE_GENERAL_DATA = ContractWizardNavigationPaths.GENERAL_DATA;
  PAGE_ADD_SERVICE_CONTACT = ContractWizardNavigationPaths.ADD_SERVICE_CONTACT;
  PAGE_SERVICE_CONTACT = ContractWizardNavigationPaths.SERVICE_CONTACT;
  PAGE_INSTALL_EQUIPMENTS = ContractWizardNavigationPaths.INSTALL_EQUIPMENTS;
  PAGE_MAINTENANCE_DATA = ContractWizardNavigationPaths.MAINTENANCE_DATA;

  model: ContractWizardModel;

  CancellationPeriod = ObjektBrief.MaintenanceContractCancellationPeriods;
  CancellationUnit = ObjektBrief.MaintenanceContractCancellationUnits;
  CancellationCalendar = ObjektBrief.MaintenanceContractCancellationCalendars;

  
  @Output()
  pageChangeRequest = new EventEmitter<ContractWizardNavigationPaths>();

  @Input()
  editMode: boolean;

  @Input() currentPage: ContractWizardNavigationPaths;

  constructor(private service: ContractWizardService) {}

  ngOnInit() {
    this.model = this.service.getCurrentContract();
  }

  navigate(pageChangeEvent: ContractWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }

  get selectedIECount(): number {
    return new Set(this.model.installEquipments.filter(x => x.action === ACTION.NONE || x.action === ACTION.ADD).map(x => x.Equipment.Identity)).size;
  }
  get selectedIEDataCount(): number {
    return this.model.installEquipments.filter(x => x.action === ACTION.NONE || x.action === ACTION.ADD).length;
  }
}
