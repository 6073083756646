import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {PropertyCore} from '../../core/odata/odata.coreapi';
import {AuthService} from '../../core/auth/auth.service';
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../core/odata/odataclient';


@Component({
  selector: 'app-equipment-module',
  templateUrl: './equipment-module.component.html',
  styleUrls: ['./equipment-module.component.scss']
})
export class EquipmentModuleComponent implements OnInit, OnDestroy {

  installEquipmentId: string;
  installEquipment: PropertyCore.Equipment;
  selectedModuleUT: string = null;

  installModules: PropertyCore.Module[];
  canHaveModules: boolean;

  searchFilter: string = '';

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.parent.paramMap
      .pipe(switchMap((params: ParamMap) => {
        this.installEquipmentId = params.get('id');
          return from(this.odataCoreService.Module.Query()
            .Filter(x => x.Equals(ODataPath.For("Equipment", "Identity"), Guid.parse(params.get('id'))))
            .Expand(x => {
              x.Expand("ModuleType"),
              x.Expand("Equipment"),
              x.Expand("Room", y => {
                y.Expand("Floor")
              })
            })
            .Exec().then(x => x.value))
            .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))));
      }), switchMap(res => {
        this.installModules = res;
        return from(this.odataCoreService.Equipment.Query()
          .Expand(x => {
            x.Expand("Building"),
              x.Expand("EquipmentType"),
              x.Expand("Room", y =>
                y.Expand("Floor"))
          })
          .Filter(xa =>
            xa.EqualsField("Identity", Guid.parse(this.installEquipmentId)))
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      }))
      .subscribe(res => {
        this.installEquipment = res;

        // empty modules, check if this equipment can have any?
        if (!this.installModules || !this.installModules.length) {
          this.subscriptions.add(
            from(this.odataCoreService.ModuleType.Query()
              .Filter(x => x.Equals(ODataPath.For("EquipmentType", "Id"), this.installEquipment.EquipmentType.Id))
              .Count().then(x => x))
            .subscribe(moduleTypeCount => {
              if(moduleTypeCount == 0){
                this.canHaveModules = false;
              } else {
                this.canHaveModules = true;
              }
            }));
        }
      }));

    this.subscriptions.add(this.route.root.firstChild.children[1].paramMap.subscribe(params => this.selectedModuleUT = params.get('id')));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goToDetail(installModuleUT: string) {
    this.router.navigate(['/building', this.installEquipment.Building.Identity, {
      outlets: {
        left: ['equipment', this.installEquipment.Identity, 'view', {outlets: {tab: ['modules']}}],
        right: ['equipment', 'module', installModuleUT, {outlets: {tab: ['details']}}]
      }
    }]);
  }

  installModulesWizard(): void {
    this.router.navigate(['building', 'wizard', this.installEquipment.Building.Identity, 'module', this.installEquipment.Identity]);
  }

}
