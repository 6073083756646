import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Subscription, from } from "rxjs";

import { EquipmentWizardNavigationPaths } from "../equipment-wizard-navigation-paths.enum";
import { AttributeDTO } from "./../../../../core/model/attributeDTO";
import { ConfirmationModal } from "./../../../../core/popup/confirmation.modal";
import { Utils } from "./../../../../core/tools/utils";
import { InstallEquipmentService } from "./../../../installEquipment.service";
import { EquipmentWizardModel } from "./../equipment-wizard-model";
import { EquipmentWizardService } from "./../equipment-wizard.service";
import { ODataCoreService } from "./../../../../core/odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";
import { ODataPath } from "app/core/odata/odataclient";
import { map } from "rxjs/operators";
import { PropertyCore, AttributeTypeEntityFunctions, ObjektBrief } from "app/core/odata/odata.coreapi";

@Component({
  selector: "app-equipment-wizard-technical",
  templateUrl: "./equipment-wizard-technical.component.html",
  styleUrls: ["./equipment-wizard-technical.component.scss"],
})
export class EquipmentWizardTechnicalComponent implements OnInit, OnDestroy {
  model: EquipmentWizardModel;

  attributeFilter: string;
  categoryFilter: string = "";
  attributesCategories?: any[] = [];
  attributesCategoryNames?: any[] = [];
  requiredAttributes: any[] = [];
  categoryArray: any[] = [];
  years: string[];
  AttributeType: any;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<EquipmentWizardNavigationPaths>();

  constructor(
    private service: EquipmentWizardService,
    private installEquipmentService: InstallEquipmentService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.AttributeType = ObjektBrief.AttributeValueTypes;
    this.model = this.service.getCurrentEquipment();

    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .Select("AttributeTypeSets")
          .Expand((x) => {
            x.Expand("AttributeTypeSets", (y) => {
              y.Expand("AttributeTypes", (z) => {
                z.Expand("MeasurementUnit");
                z.OrderBy("Order", "asc");
              });
            });
          })
          .Filter((x) => x.EqualsField("Identity", Guid.parse(this.model.buildingId)))
          .Exec()
          .then((x) => x.value)
      )
        .pipe(map((res) => res[0]))
        .subscribe((res1) => {
          this.subscriptions.add(
            from(
              this.odataCoreService.EquipmentType.Query()
                .Expand((x) =>
                  x.Expand("AttributeTypes", (y) => {
                    y.Expand("Set");
                    y.Expand("MeasurementUnit");
                  })
                )
                .Filter((x) => x.EqualsField("Id", this.model.equipment.Id))
                .Exec()
                .then((x) => x.value)
            )
              .pipe(map((res) => res[0]))
              .subscribe((res) => {
                let a = 0;

                while (res1.AttributeTypeSets.length > a) {
                  let b = 0;

                  while (res1.AttributeTypeSets[a].AttributeTypes.length > b) {
                    let requiredAttributeItem = res.AttributeTypes.find(
                      (x) => x.Id == res1.AttributeTypeSets[a].AttributeTypes[b].Id
                    );

                    if (requiredAttributeItem) {
                      this.requiredAttributes.push(requiredAttributeItem);
                    }

                    b++;
                  }
                  a++;
                }

                this.model.attributesCategories = this.requiredAttributes;

                let x = 0;

                while (this.model.attributesCategories.length > x) {
                  this.attributesCategories.push(this.model.attributesCategories[x]);
                  this.attributesCategoryNames.push(this.model.attributesCategories[x].Set.Name);
                  x++;
                }

                this.categoryArray = this.attributesCategoryNames.filter((element, index, input) => {
                  if (input.indexOf(element) === index) {
                    return element;
                  }
                });

                this.preselectCategory();
              })
          );
        })
    );

    this.years = Utils.yearsTillToday();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private preselectCategory() {
    // pre-select ob standard category
    if (this.attributesCategories.length != 0) {
      var defaultCategory = this.attributesCategories.find((x) => x.Set.IsDefault == true);
      let obStandardCategory = "";

      if (defaultCategory != undefined) {
        obStandardCategory = this.attributesCategoryNames.find((Name) => Name == defaultCategory.Set.Name);
      }

      if (obStandardCategory) {
        this.categoryFilter = obStandardCategory;
      }
    }
  }

  previous(): void {
    this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.PREVIOUS);
  }

  save(andNew: boolean = false): void {
    const id = Utils.guid();

    if (this.model.room == null) {
      this.subscriptions.add(
        from(
          this.odataCoreService.Equipment.Post()
            .ValueType(this.odataCoreService.ODataTypes().Equipment())
            .ValueProperty("Identity", id)
            .ValueProperty("TuevNo", this.model.tuevNo)
            .ValueProperty("Comment", this.model.comment)
            .ValueProperty("IsRenterResponsibility", this.model.isRenterResponsibility)
            .ValueProperty("IsNoMaintenanceDesired", this.model.isNoMaintenanceDesired)
            .ValueProperty("CustomId", this.model.name)
            .ValuePropertyBinding(
              "EquipmentType",
              this.odataCoreService.EquipmentType.Get().Key(this.model.equipment.Code).Bind()
            )
            .ValuePropertyBinding(
              "Building",
              this.odataCoreService.Building.Get().Key(Guid.parse(this.model.buildingId)).Bind()
            )
            .Exec()
        ).subscribe((res) => {
          // save qr code
          if (this.model.qrCodes && this.model.qrCodes.length) {
            this.subscriptions.add(
              forkJoin([
                ...this.model.qrCodes.map((qrCode) =>
                  from(
                    this.odataCoreService.Equipment.Link()
                      .Key(Guid.parse(id))
                      .Value("QuickResponseCodes", this.odataCoreService.QuickResponseCode.Get().Key(qrCode.Id).Bind())
                      .Post()
                  )
                ),
              ]).subscribe((res) => {
                this.save2(id, andNew);
              })
            );
          } else {
            this.save2(id, andNew);
          }
        })
      );
    } else {
      this.subscriptions.add(
        from(
          this.odataCoreService.Equipment.Post()
            .ValueType(this.odataCoreService.ODataTypes().Equipment())
            .ValueProperty("Identity", id)
            .ValueProperty("TuevNo", this.model.tuevNo)
            .ValueProperty("Comment", this.model.comment)
            .ValueProperty("IsRenterResponsibility", this.model.isRenterResponsibility)
            .ValueProperty("IsNoMaintenanceDesired", this.model.isNoMaintenanceDesired)
            .ValueProperty("CustomId", this.model.name)
            .ValuePropertyBinding(
              "Room",
              this.odataCoreService.Room.Get().Key(Guid.parse(this.model.room.Identity)).Bind()
            )
            .ValuePropertyBinding(
              "EquipmentType",
              this.odataCoreService.EquipmentType.Get().Key(this.model.equipment.Code).Bind()
            )
            .ValuePropertyBinding(
              "Building",
              this.odataCoreService.Building.Get().Key(Guid.parse(this.model.buildingId)).Bind()
            )
            .Exec()
        ).subscribe((res) => {
          // save qr code
          if (this.model.qrCodes && this.model.qrCodes.length) {
            this.subscriptions.add(
              forkJoin(
                ...this.model.qrCodes.map((qrCode) =>
                  from(
                    this.odataCoreService.Equipment.Link()
                      .Key(Guid.parse(id))
                      .Value("QuickResponseCodes", this.odataCoreService.QuickResponseCode.Get().Key(qrCode.Id).Bind())
                      .Post()
                  )
                )
              ).subscribe((res) => {
                this.save2(id, andNew);
              })
            );
          } else {
            this.save2(id, andNew);
          }
        })
      );
    }
  }

  private save2(id: string, andNew: boolean = false): void {
    // save attributes
    const attributes: AttributeDTO[] = this.model.attributesCategories
      .filter((attribute) => attribute.Value !== undefined && !!attribute.Value)
      .map((attribute) => {
        const attributeDto = new AttributeDTO();
        attributeDto.Id = attribute.Id;
        attributeDto.Value = attribute.ValueType === "Date" ? Utils.dateToJson(attribute.Value) : attribute.Value;
        attributeDto.TypToken = attribute.ValueType;
        return attributeDto;
      });
    if (attributes.length) {
      let a = 0;
      while (attributes.length > a) {
        if (attributes[a].Value == "") {
        } else {
          this.subscriptions.add(
            from(
              this.odataCoreService.Equipment.Get()
                .Key(Guid.parse(id))
                .NavigateToArray<PropertyCore.AttributeValue>("AttributeValues")
                .Patch()
                .Key(attributes[a].Id)
                .ValueType(this.odataCoreService.ODataTypes().AttributeValue())
                .ValueProperty("Value", attributes[a].Value.toString())
                .Exec()
            ).subscribe((res2) => {
              if (attributes.length == a) {
                if (andNew) {
                  this.service.resetCurrentEquipment();
                  this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.START);
                } else {
                  this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.EXIT);
                }
              }
            })
          );
        }
        a++;
      }
    } else if (andNew) {
      this.service.resetCurrentEquipment();
      this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.START);
    } else {
      this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.EXIT);
    }
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant("EquipmentWiz._modal_title");
      modalRef.componentInstance.message = this.translateService.instant("EquipmentWiz._modal_message");
      modalRef.componentInstance.yesButton = this.translateService.instant("EquipmentWiz._modal_yes");
      modalRef.componentInstance.cancelButton = this.translateService.instant("EquipmentWiz._modal_cancel");

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentEquipment();
            this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(EquipmentWizardNavigationPaths.EXIT);
    }
  }
}
