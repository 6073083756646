import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { Common } from "../../../../core/odata/odata.coreapi";
import { ContractWizardNavigationPaths } from "../contract-wizard-navigation-paths.enum";
import {
  ACTION,
  ExtendedMaintenanceContractMapping,
} from "../../../../core/model/extendedMaintenanceContractMapping";
import { PropertyCore } from "../../../../core/odata/odata.coreapi";
import { ConfirmationModal } from "../../../../core/popup/confirmation.modal";
import { ContractWizardModel } from "../contract-wizard-model";
import { ContractWizardService } from "../contract-wizard.service";

@Component({
  selector: "app-contract-wizard-maintenance-data",
  templateUrl: "./contract-wizard-maintenance-data.component.html",
  styleUrls: ["./contract-wizard-maintenance-data.component.scss"],
})
export class ContractWizardMaintenanceDataComponent
  implements OnInit, OnDestroy {
  editMode: boolean;

  model: ContractWizardModel;
  buildingInstallEquipments: any[]; // array with ContractInstallEquipment[] per building

  cycleUnits: string[] = Object.keys(Common.IntervalUnits).filter(
    (x) => x !== Common.IntervalUnits.Undefined
  );

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<ContractWizardNavigationPaths>();

  @Output()
  saveContractRequest = new EventEmitter<ContractWizardModel>();

  constructor(
    private service: ContractWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.editMode = this.route.snapshot.data.editMode;

    this.model = this.service.getCurrentContract();
    // group by building
    this.buildingInstallEquipments = this.model.installEquipments.reduce(
      (prev, next) => {
        const b = prev.find(
          (x) => x.buildingUT === next.Equipment.Building.Identity
        );
        if (!b) {
          prev.push({
            buildingUT: next.Equipment.Building.Identity,
            buildingName: next.Equipment.Building.Name,
            dataList: [next],
          });
        } else {
          b.dataList.push(next);
        }
        return prev;
      },
      []
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  compareId(val1: { Id: number }, val2: { Id: number }): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  previous(): void {
    this.pageChangeRequest.emit(ContractWizardNavigationPaths.PREVIOUS);
  }

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.saveContractRequest.emit(this.model);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant(
        "ContractWizzard._modal_title"
      );
      modalRef.componentInstance.message = this.translateService.instant(
        "ContractWizzard._modal_message"
      );
      modalRef.componentInstance.yesButton = this.translateService.instant(
        "ContractWizzard._modal_yes"
      );
      modalRef.componentInstance.cancelButton = this.translateService.instant(
        "ContractWizzard._modal_cancel"
      );

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentContract();
            this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
    }
  }

  addMaintenanceData(ieToAdd: ExtendedMaintenanceContractMapping): void {
    // copy data and reset input
    const newIe = Object.assign(
      new ExtendedMaintenanceContractMapping(),
      ieToAdd
    );
    newIe.action = ACTION.ADD;
    newIe.Schedule = <PropertyCore.Schedule>{
      Cycle: null,
      CycleUnit: null,
      CycleStart: null,
    };
    // add to gui list
    const buildingInstallEquipments: ExtendedMaintenanceContractMapping[] = this.buildingInstallEquipments.find(
      (x) => x.buildingUT === ieToAdd.Equipment.Building.Identity
    ).dataList;
    buildingInstallEquipments.splice(
      buildingInstallEquipments.indexOf(ieToAdd) + 1,
      0,
      newIe
    );
    // duplicate and replace main looped value so that gui is refreshed without problems
    this.buildingInstallEquipments = JSON.parse(
      JSON.stringify(this.buildingInstallEquipments)
    );

    // add to model
    this.model.installEquipments.push(newIe);

    this.editMode = this.route.snapshot.data.editMode;

    this.model = this.service.getCurrentContract();
    // group by building
    this.buildingInstallEquipments = this.model.installEquipments.reduce(
      (prev, next) => {
        const b = prev.find(
          (x) => x.buildingUT === next.Equipment.Building.Identity
        );
        if (!b) {
          prev.push({
            buildingUT: next.Equipment.Building.Identity,
            buildingName: next.Equipment.Building.Name,
            dataList: [next],
          });
        } else {
          b.dataList.push(next);
        }
        return prev;
      },
      []
    );
  }

  removeMaintenanceData(ieToRemove: ExtendedMaintenanceContractMapping): void {
    // remove from gui list
    const buildingInstallEquipments: ExtendedMaintenanceContractMapping[] = this.buildingInstallEquipments.find(
      (x) => x.buildingUT === ieToRemove.Equipment.Building.Identity
    ).dataList;
    buildingInstallEquipments.splice(
      buildingInstallEquipments.indexOf(ieToRemove),
      1
    );
    if (!buildingInstallEquipments.length) {
      this.buildingInstallEquipments = this.buildingInstallEquipments.filter(
        (x) => x.buildingUT !== ieToRemove.Equipment.Building.Identity
      );
    }

    const removed = this.model.installEquipments.splice(
      this.model.installEquipments.indexOf(ieToRemove),
      1
    );
    // add back to model if edit mode and when old action was not 'add'
    if (this.editMode && ieToRemove.action !== ACTION.ADD) {
      removed[0].action = ACTION.REMOVE;
      this.model.installEquipments.push(removed[0]);
    }
  }

  getDateLimit(type = "min") {
    const today = new Date();
    if (type === "min") {
      return {
        year: today.getFullYear() - 1,
        month: today.getMonth() + 1,
        day: today.getDate(),
      };
    } else {
      return {
        year: today.getFullYear() + 15,
        month: today.getMonth() + 1,
        day: today.getDate(),
      };
    }
  }
}
