import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { FileItem, FileUploader } from "ng2-file-upload";

import { AuthService } from "./../../auth/auth.service";
import { AppConfig } from "./../../bootstrap/app.config";

@Component({
  selector: "app-document-upload",
  templateUrl: "./document-upload.component.html",
  styleUrls: ["./document-upload.component.scss"],
})
export class DocumentUploadComponent {
  @Output()
  addFile: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  removeFile: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("fileInput", { static: true })
  fileInput: any;

  uploader: FileUploader;

  hasDropZoneOver = false;

  constructor(
    private config: AppConfig,
    private authService: AuthService,
    private toasterService: ToasterService
  ) {
    this.uploader = new FileUploader({
      isHTML5: true,
      // manually check for mime type or extensions as it depends if the user has a program installed
      allowedMimeType: [
        "application/pdf",
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/tif",
        "image/tiff",
        // .doc, .docx
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        // .xls, .xlsx
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // .ppt, .pptx
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        // .dwg
        "application/acad",
        "", // application/acad, application/x-acad, application/autocad_dwg, image/x-dwg, application/dwg, application/x-dwg, application/x-autocad, image/vnd.dwg, drawing/dwg
      ],
      maxFileSize: 150 * 1024 * 1024, // 150 MB
      queueLimit: 1,
      method: "POST",
      headers: [{ name: "Accept", value: "text/json" }],
      removeAfterUpload: true,
      authToken: authService.getAuthorizationHeader(),
    });

    this.uploader.onBuildItemForm = (item, form) => {
      // console.log('onBuildItemForm', item, form);
    };
    this.uploader.onAfterAddingAll = (fileItems) => {
      // console.log('onAfterAddingAll', fileItems);
    };
    this.uploader.onAfterAddingFile = (fileItem) => {
      // console.log('onAfterAddingFile', fileItem);
      this.addFile.emit(fileItem);
    };
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      // console.log('onWhenAddingFileFailed', item, filter, options);
      switch (filter.name) {
        // case 'mimeType':
        //   this.toasterService.pop('warning', '', 'Datei \'' + item.name + '\' konnte nicht hinzugefügt werden (.pdf, .jpg, .tiff, .xls, .doc, .dwg)');
        //   break;
        case "fileSize":
          this.toasterService.pop(
            "warning",
            "",
            "Datei '" +
              item.name +
              "' konnte nicht hinzugefügt werden (Größe über 150MB)"
          );
          break;
        case "queueLimit":
          this.toasterService.pop(
            "warning",
            "",
            "Datei '" +
              item.name +
              "' konnte nicht hinzugefügt werden (nur 1 Dokument möglich)"
          );
          break;
        default:
          this.toasterService.pop(
            "warning",
            "",
            "Datei '" + item.name + "' konnte nicht hinzugefügt werden"
          );
          break;
      }
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      // console.log('onBeforeUploadItem', fileItem);
    };
    this.uploader.onProgressItem = (fileItem, progress) => {
      // console.log('onProgressItem', fileItem, progress);
    };
    this.uploader.onProgressAll = (progress) => {
      // console.log('onProgressAll', progress);
    };
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      // console.log('onSuccessItem', item, response, status, headers);
      this.toasterService.pop(
        "info",
        "",
        "Datei '" + item._file.name + "' wurde erfolgreich hochgeladen"
      );
    };
    this.uploader.onErrorItem = (item, response, status, headers) => {
      // console.log('onErrorItem', item, response, status, headers);
      this.toasterService.pop(
        "error",
        "",
        "Fehler beim Hochladen der Datei '" + item._file.name + "'"
      );
    };
    this.uploader.onCancelItem = (item, response, status, headers) => {
      // console.log('onCancelItem', item, response, status, headers);
      this.toasterService.pop(
        "warning",
        "",
        "Datei '" + item._file.name + "' wurde abgebrochen"
      );
    };
    this.uploader.onCompleteItem = (item, response, status, headers) => {
      // console.log('onCompleteItem', item, response, status, headers);
    };
    this.uploader.onCompleteAll = () => {
      // console.log('onCompleteAll');
    };
  }

  public fileOver(e: any): void {
    this.hasDropZoneOver = e;
  }

  public onFileRemove() {
    this.uploader.clearQueue();
    this.fileInput.nativeElement.value = "";
    this.removeFile.emit();
  }

  @Input()
  set file(file: FileItem) {
    if (file) {
      // manually check for mime type and extension
      let ok = false;
      switch (file.file.type) {
        // .pdf
        case "application/pdf":
        // .png
        case "image/png":
        // .jpg, .jpeg
        case "image/jpg":
        case "image/jpeg":
        // .tif, .tiff
        case "image/tif":
        case "image/tiff":
        // .doc
        case "application/msword":
        // .docx
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        // .xls
        case "application/vnd.ms-excel":
        // .xlsx
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        // .ppt
        case "application/vnd.ms-powerpoint":
        // .pptx
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        // .dwg
        case "application/acad":
        case "application/x-acad":
        case "application/autocad_dwg":
        case "image/x-dwg":
        case "application/dwg":
        case "application/x-dwg":
        case "application/x-autocad":
        case "image/vnd.dwg":
        case "drawing/dwg":
          ok = true;
          break;
        case "":
          if (
            file.file.name.endsWith(".pdf") ||
            file.file.name.endsWith(".png") ||
            file.file.name.endsWith(".jpg") ||
            file.file.name.endsWith(".jpeg") ||
            file.file.name.endsWith(".tif") ||
            file.file.name.endsWith(".tiff") ||
            file.file.name.endsWith(".doc") ||
            file.file.name.endsWith(".docx") ||
            file.file.name.endsWith(".xls") ||
            file.file.name.endsWith(".xlsx") ||
            file.file.name.endsWith(".ppt") ||
            file.file.name.endsWith(".pptx") ||
            file.file.name.endsWith(".dwg")
          ) {
            ok = true;
          }
          break;
      }

      if (ok) {
        // do nothing
      } else {
        this.toasterService.pop(
          "warning",
          "",
          "Datei '" +
            file.file.name +
            "' konnte nicht hinzugefügt werden (erlaubt: .pdf, .jpg, .tiff, .xls, .doc, .ppt, .dwg)"
        );
      }
    } else {
      this.uploader.clearQueue();
      this.fileInput.nativeElement.value = "";
    }
  }
}
