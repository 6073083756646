import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { Utils } from './../tools/utils';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private translateService: TranslateService) {
    super();
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.getWeekdayShortName(weekday);
  }
  getWeekdayShortName(weekday: number): string {
    return this.translateService.instant('WEEKDAYS_SHORT')[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return this.translateService.instant('MONTHS_SHORT')[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.translateService.instant('MONTHS_FULL')[month - 1];
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return Utils.performPipeOperation('dateFormat', Utils.datepickerToDate(date));
  }
}
