import { FileItem } from 'ng2-file-upload';
import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { Selectable } from './../../../core/model/common';
import { Incident } from './../../../core/model/incident';


export class DocumentWizardModel {

  building: PropertyCore.Building;

  virtual: boolean;
  file: FileItem;
  name: string;
  type: DocumentType;
  DocumentType: PropertyCore.DocumentType;
  equipments: Selectable<PropertyCore.Equipment>[] = [];
  _incidents: Incident[] = [];
  missingDataIncidents: Incident[] = [];

  typeFromUrl: boolean;
  documentTypeFromUrl: boolean;
  requiredDocumentTypeId: number;

  reset(): void {
    this.building = null;
    this.virtual = false;
    this.file = null;
    this.name = null;
    this.type = null;
    this.DocumentType = null;
    this.equipments = [];
    this._incidents = [];
    this.missingDataIncidents = [];
    this.typeFromUrl = false;
    this.documentTypeFromUrl = false;
    this.requiredDocumentTypeId = null;
  }

  get typeString(): string {
    return this.type ? this.type.toString() : null;
  }

  set typeString(type: string) {
    this.type = +type;
  }

  get documentTypeString(): string {
    return this.DocumentType ? this.DocumentType.Id.toString() : null;
  }

  get incidents(): Incident[] {
    return this._incidents.concat(this.missingDataIncidents);
  }

  set incidents(incidents: Incident[]) {
    this._incidents = incidents.filter(i => i.RefDate);
    this.missingDataIncidents = incidents.filter(i => !i.RefDate);
  }
}

export enum DocumentType {
  BUILDING = 3, EQUIPMENT = 2, INCIDENT = 1
}
