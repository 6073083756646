import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {Subscription, from} from 'rxjs';
import {PropertyCore} from '../../../../core/odata/odata.coreapi';
import {DefectWizardNavigationPaths} from '../defect-wizard-navigation-paths.enum';
import {ConfirmationModal} from '../../../../core/popup/confirmation.modal';
import {Utils} from '../../../../core/tools/utils';
import {IncidentWizardModel} from '../../incident/incident-wizard-model';
import {DefectWizardService} from '../defect-wizard.service';
import { map } from 'rxjs/operators';
import { ODataCoreService } from "../../../../core/odata-services/odata.coreapi.service";
import { ODataPath } from '../../../../core/odata/odataclient';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-defect-wizard-equipment',
  templateUrl: './defect-wizard-equipment.component.html',
  styleUrls: ['./defect-wizard-equipment.component.scss']
})
export class DefectWizardEquipmentComponent implements OnInit, OnDestroy {

  model: IncidentWizardModel;

  installEquipments: PropertyCore.Equipment[];

  searchFilter: string = '';
  equipments: PropertyCore.EquipmentType[];
  equipmentFilter: any = '';

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<DefectWizardNavigationPaths>();

  constructor(
    private service: DefectWizardService,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentDefect();
    this.subscriptions.add(from(this.odataCoreService.Equipment.Query()
      .OrderBy("Name")
      .Filter(x =>
      x.Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.buildingUT)))
      .Expand(x => {
        x.Expand("Building"),
          x.Expand("EquipmentType"),
          x.Expand("Room", y => {
            y.Expand("Floor")
          })
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.installEquipments = res));
    this.subscriptions.add(from(this.odataCoreService.EquipmentType.Query().Exec().then(x => x.value)).subscribe(res => this.equipments = res));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  previous(): void {
    this.pageChangeRequest.emit(DefectWizardNavigationPaths.PREVIOUS);
  }

  selectEquipment(equipment: PropertyCore.Equipment): void {
    if (!this.model.equipment || this.model.equipment.Identity !== equipment.Identity) {
      this.model.equipment = equipment;
    } else {
      // deselect
      this.model.equipment = null;
    }
  }

  async save(next: boolean = false): Promise<void> {
    this.model.Identity = Utils.guid();

    var equipmentBind;
    if (this.model.equipment) {
      equipmentBind = this.odataCoreService.Equipment.Get().Key(Guid.parse(this.model.equipment.Identity)).Bind();
    } else {
      equipmentBind = "";
    }

    let res = await this.odataCoreService.Event.Post()
      .ValueType(this.odataCoreService.ODataTypes().Event())
      .ValueProperty("Identity", this.model.Identity)
      .ValueProperty("Type", this.model.type)
      .ValueProperty("DueDate", this.model.dueDate)
      .ValueProperty("IsDefectHandler", this.model.isDefectHandler)
      .ValueProperty("Guidelines", this.model.guidelines)
      .ValueProperty("Qualification", this.model.qualification)
      .ValuePropertyBinding("Equipment", equipmentBind)
      .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.model.buildingUT)).Bind())
      .ValuePropertyBinding("Parent", this.odataCoreService.Event.Get().Key(Guid.parse(this.model.parentId)).Bind())
      .Exec();
      this.model.id = res.Id;
    let res2 = await this.odataCoreService.Event.Get()
      .Key(Guid.parse(this.model.Identity))
      .Actions()
      .MakeSeriesInOperationsOnEventInPropertyCore()
      .Execute();

      await this.odataCoreService.Schedule.Patch()
      .Key(Guid.parse(res2.Identity))
      .ValueType(this.odataCoreService.ODataTypes().Schedule())
      .ValueProperty("Cycle", this.model.cycle)
      .ValueProperty("CycleUnit", this.model.cycleUnit)
      .ValueProperty("CycleStart", this.model.dueDate)
      .Exec();
                if (this.model.contact) {
                  await this.odataCoreService.Event.Link()
                    .Key(Guid.parse(this.model.Identity))
                    .Value("ServiceProvider", this.odataCoreService.ServiceProvider.Get().Key(Guid.parse(this.model.contact.Identity)).Bind())
                    .Post()
                        if (next) {
                          this.toasterService.pop('info', '', this.translateService.instant('DefectWizardEquipment.create_success'));
                          this.service.resetCurrentDefect();
                          this.pageChangeRequest.emit(DefectWizardNavigationPaths.START);
                        } else {
                          await this.odataCoreService.Event.Get()
                            .Key(Guid.parse(this.model.parentId))
                            .NavigateToArray<PropertyCore.EventResponse>("Response")
                            .Patch()
                            .ValueType(this.odataCoreService.ODataTypes().EventResponse())
                            .ValueProperty("IsDefectProcessed", true)
                            .Exec();
                            this.pageChangeRequest.emit(DefectWizardNavigationPaths.EXIT);   
                        }

              } else {
                  if (next) {
                    this.toasterService.pop('info', '', this.translateService.instant('DefectWizardEquipment.create_success'));
                    this.service.resetCurrentDefect();
                    this.pageChangeRequest.emit(DefectWizardNavigationPaths.START);
                  } else {
                    await this.odataCoreService.Event.Get()
                      .Key(Guid.parse(this.model.parentId))
                      .NavigateToArray<PropertyCore.EventResponse>("Response")
                      .Patch()
                      .ValueType(this.odataCoreService.ODataTypes().EventResponse())
                      .ValueProperty("IsDefectProcessed", true)
                      .Exec();
                      this.pageChangeRequest.emit(DefectWizardNavigationPaths.EXIT);     
                  }
    }

  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('DefectWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('DefectWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('DefectWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('DefectWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentDefect();
            this.pageChangeRequest.emit(DefectWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
            // do nothing, just stay on page
          }
        );
    } else {
      this.pageChangeRequest.emit(DefectWizardNavigationPaths.EXIT);
    }
  }

}
