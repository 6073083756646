import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { NgbCarousel, NgbCarouselConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription, Observable, from } from "rxjs";
import { MissingDataKpi } from "./../../../core/model/missingDataKpi";
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { ODataPath } from "../../../core/odata/odataclient";
import { LoginService } from "app/core/shared/login.service";

@Component({
  selector: "app-dashboard-integrity-box",
  templateUrl: "./integrity-box.component.html",
  styleUrls: ["./integrity-box.component.scss"],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
  encapsulation: ViewEncapsulation.None,
})
export class IntegrityBoxComponent implements OnInit, OnDestroy {
  static readonly PATH_PREFIX = "integrity";

  static readonly PATH_BUILDINGS_NO_EQUIPMENT = "buildings-no-equipment";
  static readonly PATH_BUILDINGS_NO_USAGE = "buildings-no-usage";
  static readonly PATH_EQUIPMENTS_NO_CONTRACT = "equipments-no-contract";
  static readonly PATH_EQUIPMENTS_CONTRACT_NOT_SCHEDULED = "equipments-contract-not-scheduled";
  static readonly PATH_EQUIPMENTS_NO_INSPECTION_DUTIES = "equipments-no-inspection-duties";
  static readonly PATH_EQUIPMENTS_NOT_SCHEDULED_INSPECTION_DUTIES = "equipments-not-scheduled-inspection-duties";
  static readonly PATH_BUILDINGS_MISSING_DOCUMENTS = "buildings-missing-documents";
  static readonly PATH_BUILDINGS_MISSING_DOCUMENTS_ONLY = "buildings-missing-documents-only";
  static readonly PATH_EQUIPMENTS_MISSING_DOCUMENTS = "equipments-missing-documents";

  USER_INFO_CONFIG = "OB3_Info";

  @ViewChild("infoPopupContent", { static: true })
  infoPopupContent: any;

  hideInfo: boolean;
  showHideInfo: boolean;

  private subscriptions: Subscription = new Subscription();

  missingData: MissingDataKpi;
  buildingsNoEquipment = 0;
  buildingsNoUsageType = 0;
  buildingsNoDocumentation = 0;
  equipmentsNoMaintenanceContract = 0;
  equipmentsNoDocumentation = 0;
  equipmentsNoInspectionDutyAssigned = 0;
  equipmentsInspectionDutyUnterminated = 0;

  // TODO use child to pause when kpi is clicked
  @ViewChild(NgbCarousel)
  carousel: NgbCarousel;

  constructor(
    private loginService: LoginService,
    private config: NgbCarouselConfig,
    private modalService: NgbModal,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
  }

  ngOnInit() {
    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .Filter((x) => x.Equals(ODataPath.For("KpiMarker", "HasEquipment"), false))
          .Count()
          .then((result) => (this.buildingsNoEquipment = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .Filter((x) => x.Equals(ODataPath.For("KpiMarker", "HasUsageTypes"), false))
          .Count()
          .then((result) => (this.buildingsNoUsageType = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .Filter((x) => x.Equals(ODataPath.For("KpiMarker", "HasDocumentation"), false))
          .Count()
          .then((result) => (this.buildingsNoDocumentation = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Equipment.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "IsMaintained"), false)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
          )
          .Count()
          .then((result) => (this.equipmentsNoMaintenanceContract = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Equipment.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "IsDocumented"), false)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
              .And.EqualsField("IsRenterResponsibility", false)
              .Or.Equals(ODataPath.For("KpiMarker", "IsDocumented"), false)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), false)
              .And.EqualsField("IsRenterResponsibility", false)
              .And.EqualsField("IsNoMaintenanceDesired", true)
          )
          .Count()
          .then((result) => (this.equipmentsNoDocumentation = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Equipment.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "IsInspectionDutiesAssigned"), false)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
          )
          .Count()
          .then((result) => (this.equipmentsNoInspectionDutyAssigned = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Equipment.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "HasInspectionDutyUnterminated"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
          )
          .Count()
          .then((result) => (this.equipmentsInspectionDutyUnterminated = result))
      ).subscribe()
    );

    this.subscriptions.add(
      this.loginService.readSettings(this.USER_INFO_CONFIG).subscribe((res) => {
        if (
          (!res || res["value"] !== "true") &&
          (!localStorage.getItem("OB3_Info_shown") || localStorage.getItem("OB3_Info_shown") === "false")
        ) {
          // show modal
          this.openInfoDialog();
          this.showHideInfo = true;
          localStorage.setItem("OB3_Info_shown", "true");
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  openInfoDialog() {
    this.showHideInfo = false;
    this.hideInfo = false;
    this.modalService
      .open(this.infoPopupContent, { windowClass: "info-modal" })
      .result.then((result) => {
        // if checkbox true then save, else do nothing, just stay on page
        if (this.hideInfo) {
          this.subscriptions.add(
            this.loginService.saveSettings(this.USER_INFO_CONFIG, "true").subscribe((res) => {
              this.showHideInfo = false;
            })
          );
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  navigateTo(path: string) {
    this.router.navigate([
      "/dashboard",
      { outlets: { left: ["board"], right: [IntegrityBoxComponent.PATH_PREFIX, path] } },
    ]);
  }

  get PATH_BUILDINGS_NO_EQUIPMENT(): string {
    return IntegrityBoxComponent.PATH_BUILDINGS_NO_EQUIPMENT;
  }
  get PATH_BUILDINGS_NO_USAGE(): string {
    return IntegrityBoxComponent.PATH_BUILDINGS_NO_USAGE;
  }
  get PATH_EQUIPMENTS_NO_CONTRACT(): string {
    return IntegrityBoxComponent.PATH_EQUIPMENTS_NO_CONTRACT;
  }
  get PATH_EQUIPMENTS_CONTRACT_NOT_SCHEDULED(): string {
    return IntegrityBoxComponent.PATH_EQUIPMENTS_CONTRACT_NOT_SCHEDULED;
  }
  get PATH_EQUIPMENTS_NO_INSPECTION_DUTIES(): string {
    return IntegrityBoxComponent.PATH_EQUIPMENTS_NO_INSPECTION_DUTIES;
  }
  get PATH_EQUIPMENTS_NOT_SCHEDULED_INSPECTION_DUTIES(): string {
    return IntegrityBoxComponent.PATH_EQUIPMENTS_NOT_SCHEDULED_INSPECTION_DUTIES;
  }
  get PATH_BUILDINGS_MISSING_DOCUMENTS(): string {
    return IntegrityBoxComponent.PATH_BUILDINGS_MISSING_DOCUMENTS;
  }
  get PATH_BUILDINGS_MISSING_DOCUMENTS_ONLY(): string {
    return IntegrityBoxComponent.PATH_BUILDINGS_MISSING_DOCUMENTS_ONLY;
  }
  get PATH_EQUIPMENTS_MISSING_DOCUMENTS(): string {
    return IntegrityBoxComponent.PATH_EQUIPMENTS_MISSING_DOCUMENTS;
  }
}
