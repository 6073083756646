import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthGuard } from "./../core/auth/auth-guard.service";
import { CoreModule } from "./../core/core.module";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ContractDataProcessingArticleComponent } from "./contract-data-processing/article/contract-data-processing-article.component";
import { ContractDataProcessingComponent } from "./contract-data-processing/contract-data-processing.component";
import { DataProtectionComponent } from "./data-protection/data-protection.component";
import { ExportComponent } from "./export/export.component";
import { ExportGaebComponent } from "./export/gaeb/export-gaeb.component";
import { ExportIncidentsComponent } from "./export/incidents/export-incidents.component";
import { ExportInspectionDutyComponent } from "./export/inspection-duty/export-inspection-duty.component";
import { ExportMaintenanceContractComponent } from "./export/maintenance-contract/export-maintenance-contract.component";
import { ExportPortfolioComponent } from "./export/portfolio/export-portfolio.component";
import { ManageUsersComponent } from "./manage-users/manage-users.component";
import { MandatorDetailComponent } from "./mandator/mandator-detail/mandator-detail.component";
import { MandatorListComponent } from "./mandator/mandator-list/mandator-list.component";
import { MandatorWizardAgreementComponent } from "./mandator/wizard/mandator-wizard-agreement/mandator-wizard-agreement.component";
import { MandatorWizardConfirmComponent } from "./mandator/wizard/mandator-wizard-confirm/mandator-wizard-confirm.component";
import { MandatorWizardDataComponent } from "./mandator/wizard/mandator-wizard-data/mandator-wizard-data.component";
import { MandatorWizardDoneComponent } from "./mandator/wizard/mandator-wizard-done/mandator-wizard-done.component";
import { MandatorWizardProgressComponent } from "./mandator/wizard/mandator-wizard-progress/mandator-wizard-progress.component";
import { MandatorWizardComponent } from "./mandator/wizard/mandator-wizard.component";
import { MandatorWizardService } from "./mandator/wizard/mandator-wizard.service";
import { AdvancedComponent } from "./profile/advanced/advanced.component";
import { ProfileComponent } from "./profile/profile.component";
import { ServiceProviderComponent } from "./service-provider/service-provider.component";
import { SpecificationOfServicesComponent } from "./specification-of-services/specification-of-services.component";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { ExportCalculationComponent } from './export/export-calculation/export-calculation.component';
import { ExportCalculationBuildingsComponent } from './export/export-calculation/export-calculation-buildings/export-calculation-buildings.component';
import { ExportCalculationInspectionDutiesComponent } from './export/export-calculation/export-calculation-inspection-duties/export-calculation-inspection-duties.component';

const routes: Routes = [
  {
    path: "global",
    children: [
      {
        path: "profile",
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: ProfileComponent,
          },
          {
            path: "advanced",
            component: AdvancedComponent,
            children: [
              {
                path: "",
                component: AdvancedComponent,
              },
              {
                path: "off",
                component: AdvancedComponent,
              },
            ],
          },
        ],
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
      },
      {
        path: "mandator",
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: MandatorListComponent,
          },
          {
            path: "wizard",
            children: [
              {
                path: ":id",
                component: MandatorWizardComponent,
              },
              {
                path: "",
                pathMatch: "full",
                component: MandatorWizardComponent,
              },
            ],
          },
          {
            path: ":id",
            pathMatch: "full",
            component: MandatorDetailComponent,
          },
        ],
      },
      {
        path: "service-provider",
        component: ServiceProviderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "manage-users",
        component: ManageUsersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "export",
        component: ExportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "terms-of-use",
        component: TermsOfUseComponent,
      },
      {
        path: "contract-data-processing",
        children: [
          {
            path: "",
            component: ContractDataProcessingComponent,
          },
          {
            path: ":id",
            component: ContractDataProcessingComponent,
          },
          {
            path: ":id/history",
            component: ContractDataProcessingComponent,
            data: {
              history: true,
            },
          },
        ],
      },
      {
        path: "specification-of-services",
        component: SpecificationOfServicesComponent,
      },
      {
        path: "data-protection",
        component: DataProtectionComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule.forChild(routes),
    CoreModule,
  ],
  declarations: [
    ProfileComponent,
    AdvancedComponent,
    ChangePasswordComponent,
    MandatorListComponent,
    MandatorDetailComponent,
    MandatorWizardComponent,
    MandatorWizardProgressComponent,
    MandatorWizardDataComponent,
    MandatorWizardAgreementComponent,
    MandatorWizardConfirmComponent,
    MandatorWizardDoneComponent,
    ServiceProviderComponent,
    ManageUsersComponent,
    ExportComponent,
    ExportPortfolioComponent,
    ExportIncidentsComponent,
    ExportGaebComponent,
    ExportInspectionDutyComponent,
    ExportMaintenanceContractComponent,
    TermsOfUseComponent,
    DataProtectionComponent,
    ContractDataProcessingComponent,
    ContractDataProcessingArticleComponent,
    SpecificationOfServicesComponent,
    ExportCalculationComponent,
    ExportCalculationBuildingsComponent,
    ExportCalculationInspectionDutiesComponent,
  ],
  exports: [RouterModule, ContractDataProcessingArticleComponent],
  providers: [MandatorWizardService],
})
export class GlobalModule {}
