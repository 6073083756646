import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Subscription, from } from 'rxjs';

import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { InstallEquipmentService } from './../../../installEquipment.service';
import { BuildingWizardModel } from './../building-wizard-model';
import { BuildingWizardNavigationPaths } from './../building-wizard-navigation-paths';
import { BuildingWizardService } from './../building-wizard.service';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';
import { Utils } from '../../../../core/tools/utils';
import { AuthService } from '../../../../core/auth/auth.service';
import { Common } from '../../../../core/model/odata';

@Component({
  selector: 'app-building-wizard-equipment',
  templateUrl: './building-wizard-equipment.component.html',
  styleUrls: ['./building-wizard-equipment.component.scss']
})
export class BuildingWizardEquipmentComponent implements OnInit, OnDestroy {

  model: BuildingWizardModel;

  equipments = [
    { LongName: 'Wärmeerzeugungsanlage', Code: 'HZE', Id: 118, Selected: false },
    { LongName: 'Lüftungsanlage', Code: 'RLT', Id: 126, Selected: false },
    { LongName: 'RWA-Anlagen natürlich', Code: 'RWA', Id: 133, Selected: false },
    { LongName: 'Kälteanlage - Kälteerzeugung', Code: 'KLE', Id: 134, Selected: false },
    { LongName: 'Sicherheitsbeleuchtungsanlage', Code: 'SBA', Id: 144, Selected: false },
    { LongName: 'Niederspannungsschaltanlage', Code: 'ENS', Id: 142, Selected: false },
    { LongName: 'Brandmeldeanlage', Code: 'BMA', Id: 150, Selected: false },
    { LongName: 'Aufzugsanlage', Code: 'AUF', Id: 157, Selected: false },
    { LongName: 'Hydrant Wand', Code: 'WHY', Id: 166, Selected: false },
    { LongName: 'Feuerlöscher', Code: 'FLH', Id: 169, Selected: false },
    { LongName: 'Türen, handbetätigt', Code: 'TUH', Id: 97, Selected: false },
    { LongName: 'Türen, kraftbetätigt', Code: 'TUK', Id: 95, Selected: false },
  ];

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<BuildingWizardNavigationPaths>();

  constructor(
    private service: BuildingWizardService,
    private installEquipmentService: InstallEquipmentService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private odataCoreService: ODataCoreService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.model = this.service.getCurrentBuilding();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('BuildingWiz._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('BuildingWiz._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('BuildingWiz._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('BuildingWiz._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentBuilding();
            this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
    }
  }

  previous(): void {
    this.pageChangeRequest.emit(BuildingWizardNavigationPaths.PREVIOUS);
  }

  async save(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.model.Identity = Utils.guid();
    // save building and then save install equipments and usage types if any
    await this.odataCoreService.Building.Post()
        .ValueType(this.odataCoreService.ODataTypes().Building())
        .ValueProperty("Identity", this.model.Identity)
        .ValueProperty("Name", this.model.name)
        .ValueProperty("Address", this.mapAddress(this.model.address))
        .ValueProperty("Location", {
          Longitude: this.model.longitude,
          Latitude: this.model.latitude
        })
        .ValueProperty("Size", this.model.squareMeters)
        .ValueProperty("YearOfConstruction", this.model.constructionYear)
        .ValuePropertyBinding("Account", this.odataCoreService.Account.Get().Key(Guid.parse(this.authService.getMandator())).Bind())
        .Exec().then(async id => {

          let i: number = 0;
          if (this.model.usageTypes) {
            while (i < this.model.usageTypes.length) {
              await this.odataCoreService.Building.Link()
                  .Key(Guid.parse(this.model.Identity))
                  .Value("UsageTypes", this.odataCoreService.UsageType.Get().Key(this.getUsageTypesIds(this.model.usageTypes)[i]).Bind())
                  .Post()
              i++;
            }
          }

          const eIds = this.equipments.filter(e => e.Selected).map(e => e.Code);

          let x: number = 0;
          if (eIds) {
            while (x < eIds.length) {
              await this.odataCoreService.Equipment.Post()
                .ValueType(this.odataCoreService.ODataTypes().Equipment())
                .ValueProperty("Identity", Utils.guid())
                .ValuePropertyBinding("EquipmentType", this.odataCoreService.EquipmentType.Get().Key(eIds[x]).Bind())
                .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.model.Identity)).Bind())
                .Exec()
              x++;
            }
            this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
          }
          else {
            this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
          }

        }, err => {
          this.toasterService.pop('error', 'Fehler!', 'Ein unerwarteter Fehler ist aufgetreten und das Gebäude konnte nicht angelegt werden!');
        })
  
  }

  getUsageTypesIds(usageTypes): number[] {
    let typeIds: number[] = [];
    usageTypes.forEach(ut => {
      typeIds.push(ut.Id);
    });
    return typeIds;
  }

  private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};
    if (address.RegionInfo != null && address.RegionInfo.hasOwnProperty('Code')) {
      entity.Region = address.RegionInfo['Code'];
    }
    if (address.CountryInfo != null && address.CountryInfo.hasOwnProperty('Code')) {
      entity.Country = address.CountryInfo['Code'];
    } else {
      entity.Country = 'DE';
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.No == null) {
      entity.No = ''
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }
}
