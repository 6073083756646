import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, from} from 'rxjs';

import {PropertyCore} from '../../../../core/odata/odata.coreapi';
import {ConfirmationModal} from '../../../../core/popup/confirmation.modal';
import {ILogger} from '../../../../core/shared/logger.service';
import {ModuleWizardModel} from '../module-wizard-model';
import {ModuleWizardNavigationPaths} from '../module-wizard-navigation-paths.enum';
import {ModuleWizardService} from '../module-wizard.service';
import { map } from 'rxjs/operators';
import { Utils } from '../../../../core/tools/utils';
import { ODataPath } from '../../../../core/odata/odataclient';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';

@Component({
  selector: 'app-module-wizard-type',
  templateUrl: './module-wizard-type.component.html',
  styleUrls: ['./module-wizard-type.component.scss']
})
export class ModuleWizardTypeComponent implements OnInit, OnDestroy {
  model: ModuleWizardModel;

  installModuleTypes: PropertyCore.ModuleType[];

  installModuleTypeFilter: string;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  logger: ILogger;

  @Output() pageChangeRequest = new EventEmitter<ModuleWizardNavigationPaths>();

  constructor(
    private service: ModuleWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentModule();
    // TODO filter and show only those who are not set to a equipment yet, or always show every type?
    this.subscriptions.add(
         from(this.odataCoreService.ModuleType.Query()
         .Filter(x => x.Equals(ODataPath.For("EquipmentType", "Id"), this.service.equipment.EquipmentType.Id))
         .Exec().then(x => x.value))
         .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
        .subscribe(moduleTypes => (this.installModuleTypes = moduleTypes))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(installModuleType: PropertyCore.ModuleType): void {
    if (!installModuleType) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(ModuleWizardNavigationPaths.NEXT);
  }

  selectInstallModuleType(moduleType: PropertyCore.ModuleType): void {
    if (!this.model.installModuleType || (moduleType.Id !== this.model.installModuleType.Id)) {
      this.model.installModuleType = moduleType;
      this.model.attributesCategories = null;
    }
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('ModuleWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('ModuleWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('ModuleWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ModuleWizard._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentModule();
            this.pageChangeRequest.emit(ModuleWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(ModuleWizardNavigationPaths.EXIT);
    }
  }
}
