export enum BuildingWizardNavigationPaths {
    DATA = 'data',
    LOCATION = 'location',
    USAGE = 'usage',
    EQUIPMENT = 'equipment',

    NEXT = 'next',
    PREVIOUS = 'previous',
    START = 'start',
    EXIT = 'exit',
  }
