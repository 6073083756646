import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-incident-filter',
  templateUrl: './incident-filter.component.html',
  styleUrls: ['./incident-filter.component.scss'],
  providers: [TranslatePipe]
})
export class IncidentFilterComponent implements OnInit {
  @Input('filterModel') filterModel: any = {};
  @Input('statuses') statuses: any[];
  @Input('types') types: any[];
  // Labels / Parents
  @Input('EquipmentTypes') EquipmentTypes: any[];
  @Input('individualTerms') individualTerms: any[];
  @Input('serviceAgents') serviceAgents: any[];
  @Input('guideLines') guideLines: any[];
  @Input('intervals') intervals: any[];
  @Input('cities') cities: any[];
  @Input('buildingNames') buildingNames: any[];
  @Output('filterKey') filterKey: EventEmitter<any> = new EventEmitter();

  dropdownOptions = {
    minWidth: '200px'
  };

  searchInputOptions = {
    placeholder: this.translate.transform('IncidentListFilter._dropdown.searchPlaceholder')
  };
  
  constructor(private translate: TranslatePipe) { }

  ngOnInit() {
    this.EquipmentTypes = this.EquipmentTypes.filter((data) => data !== '');
    this.individualTerms = this.individualTerms.filter((data) => data !== '');
   /* this.serviceAgents = this.serviceAgents.filter((data) => data !== ''); */
    this.guideLines = this.guideLines.filter((data) => data !== '');
    this.intervals = this.intervals.filter((data) => data !== '' && data !== ' IncidentListFilter._dropdown.undefined');
    this.cities = this.cities.filter((data) => data !== '');
    this.buildingNames = this.buildingNames.filter((data) => data !== '');
  }

  applyFilter() {
    this.filterKey.emit(this.filterModel);
  }

  setDateLimit(date: Date) {
    return date ? {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()} : {};
  }
}
