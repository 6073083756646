import { Injectable } from "@angular/core";
import { AttributeValueData } from "app/core/model/attributeValueData";
import { ODataCoreService } from "app/core/odata-services/odata.coreapi.service";
import { PropertyCore } from "app/core/odata/odata.coreapi";
import { from, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AttributeValuesService {
  constructor(private odataCoreService: ODataCoreService) {}

  public getAttributeTypes$(): Observable<PropertyCore.AttributeType[]> {
    return from(
      this.odataCoreService.AttributeType.Query()
        .Expand((x) => {
          x.Expand("Set");
          x.Expand("MeasurementUnit");
        })
        .OrderBy("Order", "asc")
        .Exec()
        .then((x) => x.value)
    );
  }

  public getEquipmentAttributesData(
    equipment: PropertyCore.Equipment,
    allAttributeTypes: PropertyCore.AttributeType[]
  ): AttributeValueData[] {
    let result: AttributeValueData[] = [];

    equipment.AttributeValues.forEach((attr) => {
      let attributeType = allAttributeTypes.find((t) => t.Id == attr.Id);

      let attributeValueWithData: AttributeValueData = {
        AttributeTypeId: attributeType.Id,
        Name: attributeType.Name,
        Description: attributeType.Description,
        MeasurementUnit: attributeType.MeasurementUnit,
        IsOptional: attributeType.IsOptional,
        AttributeCategory: attributeType.Set,
        ValueType: attributeType.ValueType,
        Value: attr.Value,
      };

      result.push(attributeValueWithData);
    });

    return result;
  }
}
