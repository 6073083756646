import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Subscription, from } from 'rxjs';
import { flatMap, switchMap } from 'rxjs/operators';

import { BuildingEditUsagesInputData } from './../../../../core/components/building/building-edit-usages-input-data';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { IntegrityBoxComponent } from './../../../dashboard-boxes/integrity-box/integrity-box.component';
import { BuildingNoUsageState } from './../building-no-usage-state.service';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../../../core/tools/utils';
import { PropertyCore } from '../../../../core/odata/odata.coreapi';

@Component({
  selector: 'app-building-edit-usages',
  templateUrl: './building-edit-usages.component.html',
  styleUrls: ['./building-edit-usages.component.scss']
})
export class BuildingEditUsagesComponent implements OnInit, OnDestroy {
  inputData: BuildingEditUsagesInputData = new BuildingEditUsagesInputData();

  buildingUT: string;
  subscriptions: Subscription = new Subscription();
  building: PropertyCore.Building;
  constructor(
    private translateService: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private state: BuildingNoUsageState,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {

    this.inputData.building$ = this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      this.buildingUT = params.get('id');

      this.subscriptions.add(from(this.odataCoreService.Building.Get()
        .Key(Guid.parse(params.get('id')))
        .Expand(x => {
          x.Expand("UsageTypes");
          x.Expand("Statistics");
        })
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0]))).subscribe(res => {
          this.building = res;
        }));

      return from(this.odataCoreService.Building.Get()
        .Key(Guid.parse(params.get('id')))
        .Expand(x => {
          x.Expand("UsageTypes");
          x.Expand("Statistics");
        })
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
    }));

    this.inputData.buildingUsageTypes$ = this.inputData.building$
      .pipe(flatMap(b =>
        from(this.odataCoreService.UsageType.Query()
          .Filter(x =>
            x.Any("Regions", "r: r/Id eq " + b.Address.RegionInfo.Id + ""))
          .Exec().then(x => x.value))
          .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
      ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleCloseRequest(isDirty: boolean): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('BuildingDetailEditUsage._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('BuildingDetailEditUsage._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('BuildingDetailEditUsage._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('BuildingDetailEditUsage._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate(['/dashboard', { outlets: { left: ['board'], right: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_BUILDINGS_NO_USAGE] } }],
              { queryParamsHandling: 'merge' });
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate(['/dashboard', { outlets: { left: ['board'], right: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_BUILDINGS_NO_USAGE] } }],
        { queryParamsHandling: 'merge' });
    }
  }

  async handleSaveRequest(btIds: number[]): Promise<void> {
      let x: number = 0;
 
      while (btIds.length > x) {
        var existingUsageType = this.building.UsageTypes.filter(function(usageTypeId){
              return usageTypeId.Id == btIds[x];
        });
        
        // Add UsageType that is newly selected by the user, and ignore the current select usagetype
        if(existingUsageType.length == 0){
          await this.odataCoreService.Building.Link().Key(Guid.parse(this.buildingUT))
              .Value("UsageTypes", this.odataCoreService.UsageType.Get().Key(btIds[x]).Bind())
              .Post()
        }
        x++;
      }

      let y: number = 0;
      while (this.building.UsageTypes.length > y) {
        var buildingUsageTypes = this.building.UsageTypes;
        var selectedUsageType = btIds.filter(function(usageTypeId){
              return usageTypeId == buildingUsageTypes[y].Id;
        });

        if(selectedUsageType.length == 0){
          await this.odataCoreService.Building.Link().Key(Guid.parse(this.buildingUT))
              .Value("UsageTypes", this.odataCoreService.UsageType.Get().Key(buildingUsageTypes[y].Id).Bind())
              .Delete()
        }
        y++;
    }

    this.state.notify(BuildingNoUsageState.ACTION_SET_USAGE);
    this.toasterService.pop('success', '', this.translateService.instant('DashboardIntegrityBuildingsNoUsage._toastr_setUsageSuccess'));


  }

}
