import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppConfig} from '../core/bootstrap/app.config';
import {InstallEquipmentDeleteInfo} from '../core/model/installEquipment';
import {Utils} from '../core/tools/utils';



@Injectable()
export class InstallEquipmentService {


  private canDeletePath = 'WebClient/InstallEquipment/IsDeletable/${equipmentUT}';

  constructor(private httpClient: HttpClient,
              private config: AppConfig,
              private translateService: TranslateService) {
 }


  canDelete(equipmentUT: string): Observable<InstallEquipmentDeleteInfo> {
    return this.httpClient.get<any>(this.config.getBaseUrl() + Utils.buildUrl(this.canDeletePath, {equipmentUT: equipmentUT}))
      .pipe(map(deleteInfo => mapDeleteInfoAPIintoDeleteInfo(deleteInfo)));
  }

}

function mapDeleteInfoAPIintoDeleteInfo(data: any): InstallEquipmentDeleteInfo {
  const i: InstallEquipmentDeleteInfo = new InstallEquipmentDeleteInfo();

  i.isDeletable = data.IsDeletable;
  i.attributeValueCount = data.AttributeValueCount;
  i.childCount = data.ChildCount;
  i.imCount = data.IMCount;
  i.imageCount = data.ImageCount;
  i.incidentsCount = data.IncidentsCount;
  i.inspectionDutiesCount = data.InspectionDutiesCount;
  i.maintenanceContractMappingsCount = data.MaintenanceContractMappingsCount;
  i.qrCodeCount = data.QRCodeCount;

  return i;
}
