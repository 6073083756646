import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BuildingWizardModel } from './../building-wizard-model';
import { BuildingWizardNavigationPaths } from './../building-wizard-navigation-paths';
import { BuildingWizardService } from './../building-wizard.service';

@Component({
  selector: 'app-building-wizard-progress',
  templateUrl: './building-wizard-progress.component.html',
  styleUrls: ['./building-wizard-progress.component.scss']
})
export class BuildingWizardProgressComponent implements OnInit {

  PAGE_DATA = BuildingWizardNavigationPaths.DATA;
  PAGE_LOCATION = BuildingWizardNavigationPaths.LOCATION;
  PAGE_USAGE = BuildingWizardNavigationPaths.USAGE;
  PAGE_EQUIPMENT = BuildingWizardNavigationPaths.EQUIPMENT;

  model: BuildingWizardModel;

  @Output()
  pageChangeRequest = new EventEmitter<BuildingWizardNavigationPaths>();

  @Input()
  editMode: boolean;

  @Input()
  currentPage: BuildingWizardNavigationPaths;

  constructor(private service: BuildingWizardService) { }

  ngOnInit() {
    this.model = this.service.getCurrentBuilding();
  }

  navigate(pageChangeEvent: BuildingWizardNavigationPaths): void {
    this.pageChangeRequest.emit(pageChangeEvent);
  }

}
