import { Injectable } from '@angular/core';
import { ImageUploadModel } from './image-upload-model';

@Injectable()
export class ImageUploadService {

    imageUploadModel: ImageUploadModel = new ImageUploadModel;

    constructor() { }

    getCurrentImageUploadModel(): ImageUploadModel {
        return this.imageUploadModel;
    }

    resetCurrentImageUploadModel(): void {
        this.imageUploadModel.reset();
    }
}

