import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { Subscription, timer, from } from "rxjs";
import { take, map } from "rxjs/operators";

import { AuthService } from "./../../core/auth/auth.service";
import { ConfirmationModal } from "./../../core/popup/confirmation.modal";
import { GlobalService } from "./../../core/shared/global.service";
import { Utils } from "../../core/tools/utils";
import { ODataCoreService } from "./../../core/odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  isFirstLogin: boolean;
  agreeChecked: boolean;
  isCompany: boolean;
  agree2Checked: boolean;

  email: string;
  password: string;
  encrypted: boolean = true;
  newPassword: string;
  newPasswordRepeat: string;

  accessCode: string;
  invitation: string;

  errors: string[];

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private toasterService: ToasterService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private router: Router,
    private odataCoreService: ODataCoreService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParamMap.subscribe((params) => {
        this.email = params.get("username");
        this.password = params.get("password");
        this.encrypted = params.get("encrypted") === "true";
        this.accessCode = params.get("accessCode");
        this.invitation = params.get("invitation");

        this.subscriptions.add(
          this.authService.isLoggedIn().subscribe((isLoggedIn) => {
            if (isLoggedIn && !this.isFirstLogin) {
              // when username and password in url, then initial password change - logout previous user and hard reload page
              if (this.email && this.password) {
                // use timer to not conflict with existing isLoggedIn value in appComponent
                timer(1)
                  .pipe(take(1))
                  .subscribe(() => {
                    this.authService.logout();
                    // hard reload
                    const url = this.router
                      .createUrlTree(["/global/change-password"], {
                        queryParams: {
                          username: this.email,
                          password: this.password,
                          encrypted: this.encrypted,
                          accessCode: this.accessCode,
                          invitation: this.invitation,
                        },
                      })
                      .toString();
                    this.router
                      .navigateByUrl("/skipThisPage", {
                        skipLocationChange: true,
                      })
                      .then(() => this.router.navigateByUrl(url));
                  });
              } else {
                this.subscriptions.add(
                  from(
                    this.odataCoreService.User.Query()
                      .Filter((x) => x.EqualsField("Identity", Guid.parse(localStorage.getItem("infoUserIdentity"))))
                      .Exec()
                      .then((x) => x.value)
                  )
                    .pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])))
                    .subscribe((profile) => {
                      this.email = profile.Email;

                      this.subscriptions.add(
                        this.globalService.isUserValid(this.email, false).subscribe((isPwUnchanged) => {
                          this.isFirstLogin = isPwUnchanged;
                        })
                      );
                    })
                );
              }
            } else {
              this.subscriptions.add(
                this.globalService.isUserValid(this.email, this.encrypted).subscribe((isPwUnchanged) => {
                  this.isFirstLogin = isPwUnchanged;
                })
              );
            }
          })
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onInputChange() {
    if (!this.password || this.password.length < 3 || !this.newPassword || this.newPassword.length < 3) {
      return;
    }
    this.subscriptions.add(
      this.globalService.checkPassword(this.email, this.newPassword, this.encrypted).subscribe((response) => {
        this.errors = response;
        if (this.newPassword !== this.newPasswordRepeat) {
          this.errors.push("identicalPassword");
        }
      })
    );
  }

  getPasswordDescription() {
    if (this.errors && this.errors.indexOf("passwordDescription") === -1) {
      switch (this.errors.length) {
        case 6:
          return this.translateService.instant("ChangePassword._h3_state_short");
        case 5:
        case 4:
        case 3:
          return this.translateService.instant("ChangePassword._h3_state_weak");
        case 2:
          return this.translateService.instant("ChangePassword._h3_state_good");
        case 1:
          return this.translateService.instant("ChangePassword._h3_state_strong");
        case 0:
          return this.translateService.instant("ChangePassword._h3_state_super");
      }
    }
    return this.translateService.instant("ChangePassword._h3_state_newPw");
  }

  save(validForm: boolean): void {
    if (
      !validForm ||
      (this.errors && this.errors.length) ||
      (this.isFirstLogin && (!this.agreeChecked || (!this.isCompany && !this.agree2Checked)))
    ) {
      this.formSubmitted = true;
      return;
    }
    this.subscriptions.add(
      this.globalService.changePassword(this.password, this.newPassword, this.email, this.encrypted).subscribe(
        (res) => {
          this.toasterService.pop("success", "", this.translateService.instant("ChangePassword.save_success"));

          // re-login
          this.subscriptions.add(
            this.authService.login(this.email, this.newPassword).subscribe((loggedIn) => {
              if (loggedIn && loggedIn.length && loggedIn[0]) {
                loggedIn = loggedIn[0];
                if (loggedIn.error) {
                  this.toasterService.pop("error", "", this.translateService.instant("ChangePassword.relogin_error"));
                } else {
                  if (this.isFirstLogin) {
                    this.odataCoreService.User.Get()
                      .Key(Guid.parse(localStorage.getItem("infoUserIdentity")))
                      .Actions()
                      .AcceptUsageTermsInOperationsOnUserInOperations()
                      .Parameters(this.agreeChecked, this.isCompany, this.agree2Checked)
                      .Execute()
                      .then((res) => {
                        this.router.navigate(["/global/profile"], {
                          queryParams: {
                            isNewUser: true,
                            accessCode: this.accessCode,
                            invitation: this.invitation,
                          },
                        });
                      });
                  } else {
                    timer(1)
                      .pipe(take(1))
                      .subscribe(() => {
                        this.authService.logout();
                      });
                  }
                }
              }
            })
          );
        },
        (err) => {
          this.toasterService.pop("error", "", this.translateService.instant("ChangePassword.save_error"));
        }
      )
    );
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant("ChangePassword._modal_title");
      modalRef.componentInstance.message = this.translateService.instant("ChangePassword._modal_message");
      modalRef.componentInstance.yesButton = this.translateService.instant("ChangePassword._modal_yes");
      modalRef.componentInstance.cancelButton = this.translateService.instant("ChangePassword._modal_cancel");

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate(["/dashboard"]);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate(["/dashboard"]);
    }
  }

  changeIsCompany(value: boolean) {
    this.isCompany = value;
    this.agree2Checked = false;
  }
}
