import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { of, Subject, Subscription, from } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { EquipmentDocumentsInputData } from './../../../core/components/equipment/equipment-documents-input-data';
import { IntegrityBoxComponent } from './../../dashboard-boxes/integrity-box/integrity-box.component';
import { MissingDocumentState } from './../missing-document-upload/missing-document-state.service';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../../core/odata/odataclient';

@Component({
  selector: 'app-equipments-missing-documents',
  templateUrl: './equipments-missing-documents.component.html',
  styleUrls: ['./equipments-missing-documents.component.scss']
})
export class EquipmentsMissingDocumentsComponent implements OnInit, OnDestroy {

  equipmentDocumentsInputData: EquipmentDocumentsInputData = new EquipmentDocumentsInputData();

  _searchFilter: string = '';
  searchFilterSubject = new Subject<string>();

  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: MissingDocumentState,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.route.queryParams.pipe(filter(params => params.search)).subscribe(params => { this.searchFilter = params['search'] || ''; }));

    this.equipmentDocumentsInputData.navigateToFirstListItem = false;
    this.equipmentDocumentsInputData.buildingEquipmentDocument$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(v => {
        return from(this.odataCoreService.Building.Query()
          .Expand(x => {
            x.Expand("Equipments",
              y => {
                y.Expand("DocumentTypeMarkers", z => {
                  z.Expand("DocumentType")
                  z.Filter(a => a
                      .EqualsField("IsRequired", true).And
                      .EqualsField("IsMissing", true))
                  y.Expand("Room", z => {
                    z.Expand("Floor", a => {
                      a.Select("Name")
                    })
                    z.Select("Name")
                  })
                })
                y.Expand("KpiMarker")
                y.Filter(z => z
                  .Equals(ODataPath.For("KpiMarker", "IsDocumented"), false).And
                  .Equals(ODataPath.For("KpiMarker", "IsActive"), true).And
                  .EqualsField("IsRenterResponsibility", false).Or
                  .Equals(ODataPath.For("KpiMarker", "IsDocumented"), false).And
                  .Equals(ODataPath.For("KpiMarker", "IsActive"), false).And
                  .EqualsField("IsRenterResponsibility", false).And
                  .EqualsField("IsNoMaintenanceDesired",true)
                  )
                y.Select("Id")
                y.Select("Identity")
                y.Select("Name")
                y.Select("CustomId")
                y.Select("IsNoMaintenanceDesired")
              })
          })
          .OrderBy("Name", "asc")
          .Exec().then(x => x.value));
      }));

    this.equipmentDocumentsInputData.searchFilter$ = this.searchFilterSubject.asObservable().pipe(startWith(this.searchFilter));
    this.equipmentDocumentsInputData.showBuildingData = true;
    this.equipmentDocumentsInputData.showEquipmentData = true;

    this.equipmentDocumentsInputData.currentEquipmentUT$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(action => {
        if (action === MissingDocumentState.ACTION_UPLOAD_DOCUMENT) {
          this.equipmentDocumentsInputData.navigateToFirstListItem = true;
          return of(<string>null);
        }
        return this.route.parent.children[1].paramMap.pipe(map( (params: ParamMap) => params.get('id')));
      }));
    this.equipmentDocumentsInputData.currentDocumentTypeId$ = this.state.registerForNotification()
      .pipe(startWith('init call'))
      .pipe(switchMap(action => {
        if (action === MissingDocumentState.ACTION_UPLOAD_DOCUMENT) {
          this.equipmentDocumentsInputData.navigateToFirstListItem = true;
          return of(<number>null);
        }
        return this.route.parent.children[1].paramMap.pipe(map( (params: ParamMap) => +params.get('documentTypeId')));
      }));

    this.equipmentDocumentsInputData.showsAsCallToAction = false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get searchFilter(): string {
    return this._searchFilter;
  }

  set searchFilter(searchFilter: string) {
    this._searchFilter = searchFilter;
    this.searchFilterSubject.next(searchFilter);

    this.router.navigate([], { relativeTo: this.route, queryParams: { search: this.searchFilter }, queryParamsHandling: 'merge' });
  }

  documentWizard(ieUT: string, documentType: number) {
    this.router.navigate([
      '/dashboard',
      {
        outlets: {
          left: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_MISSING_DOCUMENTS],
          right: ['equipment', ieUT, 'document-upload', documentType]
        }
      }
    ], { queryParams: { search: this.searchFilter }, queryParamsHandling: 'merge' });
  }

}
