import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { combineLatest, Subscription, from } from "rxjs";

import {
  ObjektBrief,
  Operations,
  PropertyCore,
} from "../../core/odata/odata.coreapi";
import { AuthService } from "../../core/auth/auth.service";
import { Selectable } from "../../core/model/common";
import {
  Mandator,
  RoleBuilding,
  UserRoleBuildings,
} from "../../core/model/mandator";
import { ConfirmationModal } from "../../core/popup/confirmation.modal";
import { GlobalService } from "../../core/shared/global.service";
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { Utils } from "../../core/tools/utils";
import { map } from "rxjs/internal/operators/map";
import { Guid } from "guid-typescript";
import { MandatorService } from "app/core/shared/services/mandator.service";

@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  mandator: Mandator;

  loginPermissions: UserRoleBuildings[];
  serviceProviderAccounts: PropertyCore.ServiceProvider[];

  searchFilter: string = "";

  editMode: boolean;
  userEmail: string;
  userType: UserType = UserType.MEMBER;
  UserType = UserType;
  serviceContacts: PropertyCore.ServiceProvider[];
  serviceContact: PropertyCore.ServiceProvider;
  buildings: Selectable<PropertyCore.Building>[];
  _addedBuildings: PropertyCore.Building[];
  _removedBuildings: PropertyCore.Building[];

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService,
    private mandatorService: MandatorService
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.mandatorService.getCurrentMandatorWithRights$().subscribe((res) => {
        this.mandator = res;
        if (this.mandator.Rights[0].CanEdit) {
          // buildings and users with roles
          this.subscriptions.add(
            from(
              this.odataCoreService.Account.Query()
                .Filter((x) =>
                  x.EqualsField(
                    "Identity",
                    Guid.parse(localStorage.getItem("mandator"))
                  )
                )
                .Expand((x) => {
                  x.Expand("Buildings", (x) => {
                    x.Expand("Roles", (xa) => {
                      xa.Expand("User");
                    });
                  });
                })
                .Exec()
                .then((x) => x.value)
            )
              .pipe(
                map((res) => {
                  Utils.mapAllJSONDatesToDates(res[0]);
                  return Object.assign(new Mandator(), res[0]);
                })
              )
              .subscribe((res2) => {
                this.mandator.Buildings = res2.Buildings;
                // create map of user email and UserBuildings[]
                const permissions = res2.Buildings.reduce(
                  (accumulator, building) => {
                    building.Roles.forEach((role) => {
                      accumulator[role.User.Email] =
                        accumulator[role.User.Email] ||
                        <UserRoleBuildings>{
                          User: role.User,
                          RoleBuildings: [],
                        };
                      accumulator[role.User.Email].RoleBuildings.push(<
                        RoleBuilding
                        >{ Role: role, Building: building });
                    });
                    return accumulator;
                  },
                  {}
                );
                this.loginPermissions = Object.keys(permissions)
                  .map((key) => {
                    return {
                      User: permissions[key].User,
                      RoleBuildings: permissions[
                        key
                      ].RoleBuildings.sort((a, b) =>
                        a.Building.Name.toLowerCase().localeCompare(
                          b.Building.Name.toLowerCase()
                        )
                      ),
                    };
                  })
                  .sort((a, b) =>
                    a.User.Email.toLowerCase().localeCompare(
                      b.User.Email.toLowerCase()
                    )
                  );
              })
          );

          // service providers with service account (To implemented in the future)
          /*this.subscriptions.add(this.serviceContactService.getServiceContacts(true).subscribe(res => {
          this.serviceProviderAccounts = res;
        }));*/
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  removeOwner(owner: Operations.User) {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant(
      "ManageUsers._modal_title"
    );
    modalRef.componentInstance.message = this.translateService.instant(
      "ManageUsers._modal_message",
      { email: owner.Email }
    );
    modalRef.componentInstance.yesButton = this.translateService.instant(
      "ManageUsers._modal_yes"
    );
    modalRef.componentInstance.cancelButton = this.translateService.instant(
      "ManageUsers._modal_cancel"
    );

    modalRef.result
      .then((val) => {
        if (val === ConfirmationModal.YES_VALUE) {
          this.odataCoreService.Account.Link()
            .Key(Guid.parse(this.mandator.Identity))
            .Value(
              "Users",
              this.odataCoreService.User.Get()
                .Key(Guid.parse(owner.Identity))
                .Bind()
            )
            .Delete()
            .then(async (res) => {
              this.toasterService.pop(
                "info",
                "",
                this.translateService.instant(
                  "ManageUsers._deleteOwner_success"
                )
              );
              this.ngOnInit();
            });
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  editOwner(content, owner: Operations.User) {
    this.editUser(content, owner);
  }

  removeUserPermissions(userPermissions: UserRoleBuildings) {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant(
      "ManageUsers._modal_title"
    );
    modalRef.componentInstance.message = this.translateService.instant(
      "ManageUsers._modal_message",
      { email: userPermissions.User.Email }
    );
    modalRef.componentInstance.yesButton = this.translateService.instant(
      "ManageUsers._modal_yes"
    );
    modalRef.componentInstance.cancelButton = this.translateService.instant(
      "ManageUsers._modal_cancel"
    );

    modalRef.result
      .then(async (val) => {
        if (val === ConfirmationModal.YES_VALUE) {
          await this.subscriptions.add(
            combineLatest(
              ...userPermissions.RoleBuildings.map((permission) =>
                this.odataCoreService.Building.Link()
                  .Key(Guid.parse(permission.Building.Identity))
                  .Value(
                    "Users",
                    this.odataCoreService.User.Get()
                      .Key(Guid.parse(permission.Role.User.Identity))
                      .Bind()
                  )
                  .Delete()
                  .then((res) => { })
              )
            ).subscribe((res) => {
              this.toasterService.pop(
                "info",
                "",
                this.translateService.instant("ManageUsers._deleteUser_success")
              );
              this.ngOnInit();
            })
          );
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  editUser(
    content,
    user: Operations.User = null,
    permissions: RoleBuilding[] = []
  ) {
    this.formSubmitted = false;
    this._addedBuildings = [];
    this._removedBuildings = [];

    if (user) {
      this.editMode = true;
      this.userEmail = user.Email;
      this.userType = !!this.mandator.Roles.find(
        (r) =>
          r.Role === ObjektBrief.AccountRoles.Administrator &&
          r.User.Email === this.userEmail
      )
        ? UserType.OWNER
        : UserType.MEMBER;
    } else {
      this.editMode = false;
      this.userEmail = undefined;
      this.userType = UserType.MEMBER;
    }

    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .Expand((x) => x.Expand("Users"))
          .OrderBy("Name", "asc")
          .Exec()
          .then((x) => x.value)
      )
        .pipe(
          map((res) =>
            res.map((i) => {
              return Utils.mapAllJSONDatesToDates(i);
            })
          )
        )
        .subscribe((res) => {
          this.buildings = res;
          // pre-select
          if (permissions.length == 0 && user != null) {
            this.buildings.forEach(
              (b) => (b.selected = !!b.Users.find((p) => p.Email == user.Email))
            );
          } else {
            this.buildings.forEach(
              (b) =>
              (b.selected = !!permissions.find(
                (p) => p.Building.Identity === b.Identity
              ))
            );
          }
        })
    );
    /*
    if (!this.serviceContacts) {
      this.subscriptions.add(this.serviceContactService.getServiceContacts(false, true).subscribe(res => this.serviceContacts = res));
    } */
    this.modalService
      .open(content, { windowClass: "user-modal" })
      .result.then(async (result) => {
        // invite user
        if (!this.editMode) {
          let buildingIds = [];
          switch (this.userType) {
            case UserType.OWNER:
              buildingIds = this.mandator.Buildings.map((b) => b.Identity);
              break;
            case UserType.MEMBER:
              buildingIds = this._addedBuildings.map((b) => b.Identity);
              break;
          }
          if (
            this.userType === UserType.OWNER ||
            this.userType === UserType.MEMBER
          ) {
            this.subscriptions.add(
              this.globalService
                .invite(this.userEmail, buildingIds)
                .subscribe(async (res) => {
                  var userIdentity = res;
                  if (this.userType === UserType.OWNER) {
                    this.odataCoreService.Account.Link()
                      .Key(Guid.parse(this.mandator.Identity))
                      .Value(
                        "Users",
                        this.odataCoreService.User.Get()
                          .Key(Guid.parse(res))
                          .Bind()
                      )
                      .Post()
                      .then(async (res) => {
                        this.toasterService.pop(
                          "info",
                          "",
                          this.translateService.instant(
                            "ManageUsers._inviteOwner_success"
                          )
                        );

                        // remove permissions for owner
                        if (buildingIds.length) {
                          await this.subscriptions.add(
                            combineLatest(
                              buildingIds.map((buildingId) =>
                                this.odataCoreService.Building.Link()
                                  .Key(Guid.parse(buildingId))
                                  .Value(
                                    "Users",
                                    this.odataCoreService.User.Get()
                                      .Key(Guid.parse(userIdentity))
                                      .Bind()
                                  )
                                  .Delete()
                                  .then((res) => { })
                              )
                            ).subscribe((res3) => {
                              this.ngOnInit();
                            })
                          );
                        }
                      });
                  } else {
                    if (buildingIds.length) {
                      await this.subscriptions.add(
                        combineLatest(
                          buildingIds.map((buildingId) =>
                            this.odataCoreService.Building.Link()
                              .Key(Guid.parse(buildingId))
                              .Value(
                                "Users",
                                this.odataCoreService.User.Get()
                                  .Key(Guid.parse(res))
                                  .Bind()
                              )
                              .Delete()
                              .then((res) => { })
                          )
                        ).subscribe(async (res3) => {
                          await this.subscriptions.add(
                            combineLatest(
                              buildingIds.map((buildingId) =>
                                this.odataCoreService.Building.Link()
                                  .Key(Guid.parse(buildingId))
                                  .Value(
                                    "Users",
                                    this.odataCoreService.User.Get()
                                      .Key(Guid.parse(res))
                                      .Bind()
                                  )
                                  .Post()
                                  .then((res) => { })
                              )
                            ).subscribe((res2) => {
                              this.toasterService.pop(
                                "info",
                                "",
                                this.translateService.instant(
                                  "ManageUsers._inviteUser_success"
                                )
                              );
                              this.ngOnInit();
                            })
                          );
                        })
                      );
                    }
                  }
                })
            );
          } else if (this.userType === UserType.SERVICE_CONTACT) {
            this.subscriptions.add(
              from(
                this.odataCoreService.Invitation.Post()
                  .ValueType(this.odataCoreService.ODataTypes().Invitation())
                  .ValueProperty("Email", this.userEmail)
                  .ValueProperty("ServiceProvider", this.serviceContact)
                  .Exec()
              ).subscribe((res) => {
                this.toasterService.pop(
                  "info",
                  "",
                  this.translateService.instant(
                    "ManageUsers._inviteUser_success"
                  )
                );
                this.ngOnInit();
              })
            );
          }
        } else {
          // check if isOwner changed
          const wasUserOwner = !!this.mandator.Roles.find(
            (r) =>
              r.Role === ObjektBrief.AccountRoles.Administrator &&
              r.User.Email === this.userEmail
          )
            ? UserType.OWNER
            : UserType.MEMBER;
          if (wasUserOwner !== this.userType) {
            // changed from member to owner
            if (
              this.mandator.Rights[0].CanEdit &&
              this.userType === UserType.OWNER
            ) {
              this.odataCoreService.Account.Link()
                .Key(Guid.parse(this.mandator.Identity))
                .Value(
                  "Users",
                  this.odataCoreService.User.Get()
                    .Key(Guid.parse(user.Identity))
                    .Bind()
                )
                .Post()
                .then(async (res) => {
                  // remove old permissions
                  if (permissions.length) {
                    await this.subscriptions.add(
                      combineLatest(
                        permissions.map((permission) =>
                          this.odataCoreService.Building.Link()
                            .Key(Guid.parse(permission.Building.Identity))
                            .Value(
                              "Users",
                              this.odataCoreService.User.Get()
                                .Key(Guid.parse(permission.Role.User.Identity))
                                .Bind()
                            )
                            .Delete()
                            .then((res) => { })
                        )
                      ).subscribe((res2) => {
                        this.toasterService.pop(
                          "info",
                          "",
                          this.translateService.instant(
                            "ManageUsers._editUser_success"
                          )
                        );
                        this.ngOnInit();
                      })
                    );
                  } else {
                    this.toasterService.pop(
                      "info",
                      "",
                      this.translateService.instant(
                        "ManageUsers._editUser_success"
                      )
                    );
                  }
                });
            } else if (this.userType === UserType.MEMBER) {
              // changed from owner to member
              this.odataCoreService.Account.Link()
                .Key(Guid.parse(this.mandator.Identity))
                .Value(
                  "Users",
                  this.odataCoreService.User.Get()
                    .Key(Guid.parse(user.Identity))
                    .Bind()
                )
                .Delete()
                .then(async (res) => {
                  if (this._addedBuildings.length) {
                    await this.subscriptions.add(
                      combineLatest(
                        this._addedBuildings.map((building) =>
                          this.odataCoreService.Building.Link()
                            .Key(Guid.parse(building.Identity))
                            .Value(
                              "Users",
                              this.odataCoreService.User.Get()
                                .Key(Guid.parse(user.Identity))
                                .Bind()
                            )
                            .Post()
                            .then((res) => { })
                        )
                      ).subscribe((res2) => {
                        this.toasterService.pop(
                          "info",
                          "",
                          this.translateService.instant(
                            "ManageUsers._editUser_success"
                          )
                        );
                        this.ngOnInit();
                      })
                    );
                  }

                  if (this._removedBuildings.length) {
                    await this.subscriptions.add(
                      combineLatest(
                        this._removedBuildings.map((building) =>
                          this.odataCoreService.Building.Link()
                            .Key(Guid.parse(building.Identity))
                            .Value(
                              "Users",
                              this.odataCoreService.User.Get()
                                .Key(Guid.parse(user.Identity))
                                .Bind()
                            )
                            .Delete()
                            .then((res) => { })
                        )
                      ).subscribe((res2) => {
                        this.toasterService.pop(
                          "info",
                          "",
                          this.translateService.instant(
                            "ManageUsers._editUser_success"
                          )
                        );
                        this.ngOnInit();
                      })
                    );
                  }
                });
            }
          } else if (this.userType === UserType.MEMBER) {
            // default Manager roleId = 2
            if (this._addedBuildings.length) {
              await this.subscriptions.add(
                combineLatest(
                  this._addedBuildings.map((building) =>
                    this.odataCoreService.Building.Link()
                      .Key(Guid.parse(building.Identity))
                      .Value(
                        "Users",
                        this.odataCoreService.User.Get()
                          .Key(Guid.parse(user.Identity))
                          .Bind()
                      )
                      .Post()
                      .then((res) => { })
                  )
                ).subscribe((res2) => {
                  this.toasterService.pop(
                    "info",
                    "",
                    this.translateService.instant(
                      "ManageUsers._editUser_success"
                    )
                  );
                  this.ngOnInit();
                })
              );
            }

            if (this._removedBuildings.length) {
              await this.subscriptions.add(
                combineLatest(
                  this._removedBuildings.map((building) =>
                    this.odataCoreService.Building.Link()
                      .Key(Guid.parse(building.Identity))
                      .Value(
                        "Users",
                        this.odataCoreService.User.Get()
                          .Key(Guid.parse(user.Identity))
                          .Bind()
                      )
                      .Delete()
                      .then((res) => { })
                  )
                ).subscribe((res2) => {
                  this.toasterService.pop(
                    "info",
                    "",
                    this.translateService.instant(
                      "ManageUsers._editUser_success"
                    )
                  );
                  this.ngOnInit();
                })
              );
            }
          }
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  saveUser(validForm: boolean, saveFct: any) {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    saveFct();
  }

  selectBuilding(building: PropertyCore.Building, selected: boolean) {
    if (selected) {
      this._addedBuildings.push(building);
      const i = this._removedBuildings.indexOf(building);
      if (i !== -1) {
        this._removedBuildings.splice(i, 1);
      }
    } else {
      this._removedBuildings.push(building);
      const i = this._addedBuildings.indexOf(building);
      if (i !== -1) {
        this._addedBuildings.splice(i, 1);
      }
    }
  }

  selectAll(all: boolean): void {
    this.buildings.forEach((b) => {
      b.selected = all;
      this.selectBuilding(b, all);
    });
  }

  onServiceContactChange(serviceContact: PropertyCore.ServiceProvider): void {
    this.serviceContact = serviceContact;
    this.userEmail = null;
    if (this.serviceContact) {
      this.userEmail = this.serviceContact.ContactEmailAdd;
    }
  }

  editServiceProvider(serviceProvider: PropertyCore.ServiceProvider) {
    console.log("TODO editServiceProvider");
    alert("TODO editServiceProvider");
  }

  removeServiceProvider(serviceProvider: PropertyCore.ServiceProvider) {
    console.log("TODO removeServiceProvider");
    alert("TODO removeServiceProvider");
  }
}

export enum UserType {
  OWNER,
  MEMBER,
  SERVICE_CONTACT,
}
