import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription, from } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { PropertyCore } from '../../core/odata/odata.coreapi';
import { Utils } from '../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";



@Component({
  selector: 'app-module-feature',
  templateUrl: './module-feature.component.html',
  styleUrls: ['./module-feature.component.scss']
})
export class ModuleFeatureComponent implements OnInit, OnDestroy {

  buildingUT: string;
  installEquipmentUT: string;
  installModule: PropertyCore.Module;
  installModuleId: string;
  attributesCategories?: any[] = [];
  attributesCategoryNames?: any[] = [];
  categoryArray: any[] = [];
  private subscriptions: Subscription = new Subscription();

    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private odataCoreService: ODataCoreService
    ) { }

    ngOnInit() {
      this.subscriptions.add(this.route.parent.parent.paramMap.subscribe((params: ParamMap) => this.buildingUT = params.get('id')));

      this.subscriptions.add(this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => {
        this.installModuleId = params.get('id');
        return from(this.odataCoreService.Module.Query()
          .Expand(x => {
            x.Expand("ModuleType"),
              x.Expand("Equipment"),
              x.Expand("Room", y =>
                y.Expand("Floor"))
          })
          .Filter(xa =>
            xa.EqualsField("Identity", Guid.parse(params.get('id'))))
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])));
      }))
        .subscribe(res => this.installModule = res));

        this.subscriptions.add(this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => 
        from(this.odataCoreService.Module.Get()
        .Key(Guid.parse(this.installModuleId))
        .Select("Identity","Name","AttributeValues")
        .Expand(x => 
          x.Expand("AttributeValues"))
        .Exec()
          .then(x => x.value))))
          .pipe(map(res => res[0]))
        .subscribe(res => {
          this.subscriptions.add(
            from(this.odataCoreService.AttributeType.Query()
            .Expand(x => {
              x.Expand("Set")
              x.Expand("MeasurementUnit")
              })
            .OrderBy("Order", "asc")
            .Exec()
              .then(x => x.value))
            .subscribe(res1 => {
              let a: number = 0;

              while(res1.length > a) {
                let attributeItem = res.AttributeValues.find(x => x.Id == res1[a].Id);
                if(attributeItem){          
                  attributeItem['Description'] = res1[a].Description;
                  attributeItem['Name'] = res1[a].Name;
                  attributeItem['MeasurementUnit'] = res1[a].MeasurementUnit;
                  attributeItem['IsOptional'] = res1[a].IsOptional;
                  attributeItem['AttributeCategory'] = res1[a].Set;
                  attributeItem['ValueType'] = res1[a].ValueType;
                  
                this.attributesCategories.push(attributeItem);
                if(attributeItem.Value != null || ""){
                  this.attributesCategoryNames.push(attributeItem['AttributeCategory'].Name);
                }  
                }
                a++;
              }
              this.categoryArray = this.attributesCategoryNames.filter((element, index, input) => {
                if (input.indexOf(element) === index) {      
                  return element
                }         
            })
            
          })

          );
        }));

        this.subscriptions.add(this.route.parent.parent.firstChild.paramMap.subscribe(params => (this.installEquipmentUT = params.get('id'))));
    }

    ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
    }

    edit(): void {
      this.router.navigate(['/building', this.buildingUT, { outlets: { left: ['equipment', this.installEquipmentUT, 'view', { outlets: { tab: ['modules'] } }], right: ['equipment', 'module','edit', 'feature', this.installModule.Identity] } }]);
    }


}
