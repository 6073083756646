export class InstallEquipmentDeleteInfo {

  isDeletable: boolean;
  attributeValueCount: number;
  childCount: number;
  imCount: number;
  imageCount: number;
  incidentsCount: number;
  inspectionDutiesCount: number;
  maintenanceContractMappingsCount: number;
  qrCodeCount: number;

}

