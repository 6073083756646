import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthGuard } from './../core/auth/auth-guard.service';
import { CoreModule } from './../core/core.module';
import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractOverviewComponent } from './contract-overview/contract-overview.component';
import { ContractComponent } from './contract/contract.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { StartComponent } from './start/start.component';
import {
    ContractWizardGeneralDataComponent,
} from './wizard/contract/contract-wizard-general-data/contract-wizard-general-data.component';
import {
    ContractWizardInstallEquipmentsComponent,
} from './wizard/contract/contract-wizard-install-equipments/contract-wizard-install-equipments.component';
import {
    ContractWizardMaintenanceDataComponent,
} from './wizard/contract/contract-wizard-maintenance-data/contract-wizard-maintenance-data.component';
import {
    ContractWizardProgressComponent,
} from './wizard/contract/contract-wizard-progress/contract-wizard-progress.component';
import {
    ContractWizardServiceContactComponent,
} from './wizard/contract/contract-wizard-service-contact/contract-wizard-service-contact.component';
import { ContractWizardComponent } from './wizard/contract/contract-wizard.component';
import { ContractWizardService } from './wizard/contract/contract-wizard.service';

const routes: Routes = [
  {
    path: 'contract',
    component: StartComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ContractListComponent
      }
    ]
  },
  // wizard navigation routes
  {
    path: 'contract',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'wizard',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ContractWizardComponent,
            data: { navigateBack: 'contract' }
          },
          {
            path: 'set-equipment/:installEquipmentUT',
            component: ContractWizardComponent,
            data: { navigateBack: 'dashboard-integrity-equipment-no-contract' }
          },
          {
            path: ':id',
            component: ContractWizardComponent,
            data: { navigateBack: 'contract', editMode: true }
          }
        ]
      }
    ]
  },
  // detail navigation routes
  {
    path: 'contract/:id',
    component: ContractComponent,
    canActivateChild: [AuthGuard],
    children: [
      // 1 level page setup
      {
        outlet: 'left',
        path: 'list',
        component: ContractListComponent,
        data: {
          animation: '1-contract-list'
        }
      },
      {
        outlet: 'right',
        path: 'contract',
        component: ContractOverviewComponent,
        data: {
          animation: '1-contract'
        },
        children: [
          {
            path: 'details',
            component: ContractDetailComponent,
            outlet: 'tab'
          },
          {
            path: 'equipment',
            component: EquipmentListComponent,
            outlet: 'tab'
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule.forChild(routes),
    CoreModule],
  declarations: [
    StartComponent,
    ContractComponent,
    ContractListComponent,
    ContractOverviewComponent,
    ContractDetailComponent,
    EquipmentListComponent,
    ContractWizardComponent,
    ContractWizardProgressComponent,
    ContractWizardGeneralDataComponent,
    ContractWizardServiceContactComponent,
    ContractWizardInstallEquipmentsComponent,
    ContractWizardMaintenanceDataComponent
  ],
  exports: [RouterModule],
  providers: [ContractWizardService]
})
export class ContractModule {}
