// This is generated code. Do not add properties. If bugs need fixing, inform the author of the
// code generator to fix the bugs also in the backend.

import { Injectable } from '@angular/core';
import { map, lastValueFrom } from 'rxjs';
import {
	ODataLiteral, ODataType,
	ODataContext, ODataSettings, ODataEntitySet,
	ODataOperationSet, ODataActionOperation, ODataFunctionOperation, ODataFunctionSetOperation, ODataGetOperation,
	ODataQueryResult
} from './odataclient';


//Namespace: ObjektBrief
export namespace ObjektBrief {

	//Enum: AccountRoles
	export enum AccountRoles {
		Undefined = "Undefined",
		Administrator = "Administrator"
	}

	//Enum: AttributeValueTypes
	export enum AttributeValueTypes {
		Undefined = "Undefined",
		Text = "Text",
		Integer = "Integer",
		Date = "Date",
		Year = "Year",
		Decimal = "Decimal",
		Selection = "Selection",
		Boolean = "Boolean"
	}

	//Enum: BuildingRoles
	export enum BuildingRoles {
		Undefined = "Undefined",
		Manager = "Manager"
	}

	//Enum: MaintenanceContractCancellationCalendars
	export enum MaintenanceContractCancellationCalendars {
		Undefined = "Undefined",
		CalendarYear = "CalendarYear",
		ContractYear = "ContractYear"
	}

	//Enum: MaintenanceContractCancellationPeriods
	export enum MaintenanceContractCancellationPeriods {
		Undefined = "Undefined",
		MonthEnd = "MonthEnd",
		QuarterEnd = "QuarterEnd",
		YearEnd = "YearEnd"
	}

	//Enum: MaintenanceContractCancellationUnits
	export enum MaintenanceContractCancellationUnits {
		Undefined = "Undefined",
		Day = "Day",
		Week = "Week",
		Month = "Month"
	}

	//Enum: OperationBLockReasons
	export enum OperationBLockReasons {
		Undefined = "Undefined",
		READ = "READ",
		PERM = "PERM",
		AGE = "AGE",
		DEPENDANT = "DEPENDANT",
		VALUE = "VALUE"
	}

}

//Namespace: Operations
export namespace Operations {

	//Enum: AccountTypes
	export enum AccountTypes {
		Unknown = "Unknown",
		FacilityManagement = "FacilityManagement",
		ServiceProvider = "ServiceProvider"
	}

	//Enum: Operations
	export enum Operations {
		Undefined = "Undefined",
		Create = "Create",
		Update = "Update",
		Delete = "Delete",
		UpdateActivateInspectionDutiesOnly = "UpdateActivateInspectionDutiesOnly"
	}

	//Enum: ServiceRequestTypes
	export enum ServiceRequestTypes {
		Training = "Training",
		InstallEquipmentDocumentation = "InstallEquipmentDocumentation",
		InspectionDutyAnalysis = "InspectionDutyAnalysis"
	}

}

//Namespace: PropertyCore
export namespace PropertyCore {

	//Enum: ActionLogElements
	export enum ActionLogElements {
		Undefined = "Undefined",
		Document = "Document",
		Image = "Image",
		Attribute = "Attribute",
		Link = "Link",
		Event = "Event",
		Application = "Application",
		InspectionDuty = "InspectionDuty",
		AndroidApp = "AndroidApp"
	}

	//Enum: ActionLogOperations
	export enum ActionLogOperations {
		Undefined = "Undefined",
		Create = "Create",
		Update = "Update",
		Delete = "Delete",
		Feedback = "Feedback",
		Qualified = "Qualified",
		Download = "Download"
	}

	//Enum: DefectRatings
	export enum DefectRatings {
		Undefined = "Undefined",
		Unrated = "Unrated",
		Minor = "Minor",
		Major = "Major",
		Dangerous = "Dangerous"
	}

	//Enum: EventTypes
	export enum EventTypes {
		Undefined = "Undefined",
		Inspection = "Inspection",
		FollowUpInspection = "FollowUpInspection",
		Maintenance = "Maintenance",
		Repair = "Repair",
		Custom = "Custom"
	}

}

//Namespace: Common
export namespace Common {

	//Enum: IntervalUnits
	export enum IntervalUnits {
		Undefined = "Undefined",
		Year = "Year",
		Month = "Month",
		Week = "Week",
		Day = "Day"
	}

}

//Namespace: Common
export namespace Common {

	//Complex Type: Address
	export interface Address  {
		Street: string;
		No: string;
		City: string;
		PostCode: string;
		Country: string;
		CountryInfo: Library.CountryInfo;
		Region: string;
		RegionInfo: Library.RegionInfo;
	}

	//Complex Type: AddressGeoCoded
	export interface AddressGeoCoded  extends Common.Address {
		Location: Location;
	}

	//Complex Type: Interval
	export interface Interval  {
		Step: number;
		Size: IntervalUnits;
	}

	//Complex Type: InvoiceAddress
	export interface InvoiceAddress  extends Common.Address {
	}

	//Complex Type: Location
	export interface Location  {
		Longitude: number;
		Latitude: number;
	}

}

//Namespace: Library
export namespace Library {

	//Complex Type: CountryInfo
	export interface CountryInfo  {
		Id: number;
		Name: string;
	}

	//Complex Type: RegionInfo
	export interface RegionInfo  {
		Id: number;
		Name: string;
	}

}

//Namespace: PropertyCore
export namespace PropertyCore {

	//Complex Type: InspectionDutyChange
	export interface InspectionDutyChange  {
		Operation: Operations.Operations;
		Identity: string;
		IsDisabled: boolean;
		SeriesStart: Date;
		Interval: Common.Interval;
		EquipmentRef: string;
		OperatorTaskRef: string;
	}

	//Complex Type: InspectionDutyChanges
	export interface InspectionDutyChanges  {
		Comment: string;
		Changes?: InspectionDutyChange[];
	}

}

//Namespace: ObjektBrief
export namespace ObjektBrief {

	//Complex Type: OperationsBlock
	export interface OperationsBlock  {
		Reason: OperationBLockReasons;
		Property: string;
		CurrentValue: string;
		RequiredValue: string;
		CanForce: boolean;
	}

}

//Namespace: Operations
export namespace Operations {

	//Complex Type: OperationsCheckResult
	export interface OperationsCheckResult  {
		CanExecute: boolean;
		MustForce: boolean;
		Blocker?: ObjektBrief.OperationsBlock[];
	}

}

//Namespace: Operations
export namespace Operations {

	//Entity Type: Account
	export interface Account {
		Identity: string;
		Id: number;
		Name: string;
		MaxBuildingCount: number;
		SapNo: string;
		StartDate: Date;
		EndDate: Date;
		AccountType: AccountTypes;
		Created: Date;
		Roles?: AccountRole[];
		Profile?: ObjektBrief.AccountProfile;
		User?: User;
		Statistics?: ObjektBrief.AccountStatistics;
		AccountDataProcessingAgreement?: AccountDataProcessingAgreement;
		ActionLogs?: PropertyCore.ActionLog[];
		Buildings?: PropertyCore.Building[];
		MaintenanceContracts?: PropertyCore.MaintenanceContract[];
		ServiceProviders?: PropertyCore.ServiceProvider[];
		Users?: User[];
		Rights?: AccountRights[];
	}

	//Entity Type: AccountDataProcessingAgreement
	export interface AccountDataProcessingAgreement {
		Identity: string;
		TimeStamp: Date;
		LoginName: string;
		Name: string;
		Street: string;
		HouseNo: string;
		AddressAddition: string;
		ZipCode: string;
		City: string;
		Country?: Library.Country;
	}

	//Entity Type: AccountRights
	export interface AccountRights {
		Identity: string;
		CanRead: boolean;
		CanEdit: boolean;
		CanDelete: boolean;
	}

	//Entity Type: AccountRole
	export interface AccountRole {
		Id: number;
		Role: ObjektBrief.AccountRoles;
		User?: User;
	}

	//Entity Type: AccountSubscriptionHistory
	export interface AccountSubscriptionHistory {
		Id: number;
		RefDate: Date;
	}

	//Entity Type: BuildingRole
	export interface BuildingRole {
		Id: number;
		Role: ObjektBrief.BuildingRoles;
		User?: User;
	}

	//Entity Type: Invitation
	export interface Invitation {
		Identity: string;
		Email: string;
		AccessCode: string;
		ServiceProvider?: PropertyCore.ServiceProvider;
	}

	//Entity Type: User
	export interface User {
		Identity: string;
		Id: number;
		Title: string;
		Position: string;
		FirstName: string;
		LastName: string;
		PhoneBusiness: string;
		PhoneMobile: string;
		Email: string;
		LoginSource: number;
		IsTuevEmployee: boolean;
		IsMale: boolean;
		IsReportSubscribed: boolean;
		Created: Date;
	}

}

//Namespace: ObjektBrief
export namespace ObjektBrief {

	//Entity Type: AccountProfile
	export interface AccountProfile {
		Identity: string;
		Contact: string;
		ContactPhone: string;
		ContactMail: string;
		Name: string;
		PartnerNo: string;
		VatCode: string;
		AddressAddition: string;
		Address: Common.Address;
		InvoiceName: string;
		InvoicePartnerNo: string;
		InvoiceCode: string;
		InvoiceAddressAddition: string;
		InvoiceAddress: Common.InvoiceAddress;
	}

	//Entity Type: AccountStatistics
	export interface AccountStatistics {
		Id: number;
		BuildingCount: number;
	}

	//Entity Type: EquipmentStatistics
	export interface EquipmentStatistics {
		Id: number;
		FabNo: string;
		AlternativeNo: string;
		ModuleCount: number;
		MaintenanceContractCount: number;
		ImageCount: number;
		QRCodeCount: number;
		OperatorTaskCount: number;
		InspectionDutyCount: number;
		DocumentsCountMissing: number;
	}

	//Entity Type: EventParent
	export interface EventParent extends PropertyCore.Event {
	}

	//Entity Type: ModuleStatistics
	export interface ModuleStatistics {
		Id: number;
		ImageCount: number;
	}

	//Entity Type: RoomStatistics
	export interface RoomStatistics {
		Id: number;
		EquipmentCount: number;
		ModuleCount: number;
	}

	//Entity Type: ServiceProviderStatistics
	export interface ServiceProviderStatistics {
		Id: number;
		MaintenanceContractCount: number;
		EquipmentEventCount: number;
		ModuleEventCount: number;
	}

}

//Namespace: PropertyCore
export namespace PropertyCore {

	//Entity Type: ActionLog
	export interface ActionLog {
		Id: number;
		IsAnynomous: boolean;
		Operation: ActionLogOperations;
		Element: ActionLogElements;
		UserId: number;
		UserEmail: string;
		BuildingId: number;
		EquipmentId: number;
		ModuleId: number;
		DocumentId: number;
		MaintenanceContractId: number;
		ServiceProviderId: number;
		EventId: number;
		Created: Date;
		Account?: Operations.Account;
		Building?: Building;
		Equipment?: Equipment;
		Module?: Module;
		Document?: Document;
		MaintenanceContract?: MaintenanceContract;
		ServiceProvider?: ServiceProvider;
		Event?: Event;
	}

	//Entity Type: AttributeType
	export interface AttributeType {
		Id: number;
		Name: string;
		Description: string;
		ValueType: ObjektBrief.AttributeValueTypes;
		AllowedValues: string[];
		IsOptional: boolean;
		Order: number;
		Set?: AttributeTypeSet;
		MeasurementUnit?: MeasurementUnit;
	}

	//Entity Type: AttributeTypeSet
	export interface AttributeTypeSet {
		Id: number;
		Name: string;
		Order: number;
		IsDefault: boolean;
		AttributeTypes?: AttributeType[];
	}

	//Entity Type: AttributeValue
	export interface AttributeValue {
		Id: number;
		Order: number;
		Value: string;
	}

	//Entity Type: Building
	export interface Building {
		Identity: string;
		Id: number;
		Name: string;
		Address: Common.Address;
		Location: Common.Location;
		Size: number;
		YearOfConstruction: number;
		Created: Date;
		DataTimestamp: Date;
		DocumentTypeMarkers?: DocumentTypeMarker[];
		Roles?: Operations.BuildingRole[];
		Account?: Operations.Account;
		User?: Operations.User;
		Equipments?: Equipment[];
		Images?: Image[];
		Events?: Event[];
		Rooms?: Room[];
		UsageTypes?: UsageType[];
		Floors?: Floor[];
		Statistics?: BuildingStatistics;
		Users?: Operations.User[];
		Documents?: Document[];
		KpiMarker?: BuildingKpiMarker;
		AttributeTypeSets?: AttributeTypeSet[];
	}

	//Entity Type: BuildingKpiMarker
	export interface BuildingKpiMarker {
		HasEquipment: boolean;
		HasDocumentation: boolean;
		HasUsageTypes: boolean;
	}

	//Entity Type: BuildingStatistics
	export interface BuildingStatistics {
		Id: number;
		EquipmentCount: number;
		ModuleCount: number;
		UsageTypeCount: number;
		MissingDocumentsCount: number;
		RoomCount: number;
		FloorCount: number;
	}

	//Entity Type: CostGroup
	export interface CostGroup {
		Id: number;
		Code: string;
		Name: string;
		No: number;
		IsDisabled: boolean;
		Created: Date;
		EquipmentTypes?: EquipmentType[];
	}

	//Entity Type: Document
	export interface Document {
		Identity: string;
		Id: number;
		Description: string;
		Name: string;
		Size: number;
		FileType: string;
		IsDeleted: boolean;
		IsVirtual: boolean;
		Created: Date;
		User?: Operations.User;
		DocumentType?: DocumentType;
		Buildings?: Building[];
		Equipments?: Equipment[];
		Events?: Event[];
	}

	//Entity Type: DocumentType
	export interface DocumentType {
		Id: number;
		Name: string;
		Mark: string;
		IsRequired: boolean;
		Created: Date;
		Documents?: Document[];
		OperatorTasks?: OperatorTask[];
	}

	//Entity Type: DocumentTypeGroup
	export interface DocumentTypeGroup {
		Id: number;
		Name: string;
		Created: Date;
		DocumentTypes?: DocumentType[];
	}

	//Entity Type: DocumentTypeMarker
	export interface DocumentTypeMarker {
		Id: number;
		IsRequired: boolean;
		IsMissing: boolean;
		DocumentType?: DocumentType;
	}

	//Entity Type: Equipment
	export interface Equipment {
		Identity: string;
		Id: number;
		Name: string;
		Comment: string;
		InstallNo: number;
		CustomId: string;
		TuevNo: string;
		IsRenterResponsibility: boolean;
		IsNoMaintenanceDesired: boolean;
		HasInspectionDutiesActivated: boolean;
		DataTimestamp: Date;
		OperatorTaskMarkers?: OperatorTaskMarker[];
		AttributeValues?: AttributeValue[];
		EquipmentType?: EquipmentType;
		Building?: Building;
		Room?: Room;
		MaintenanceContractMappings?: MaintenanceJob[];
		Events?: Event[];
		Modules?: Module[];
		Images?: Image[];
		InspectionDuties?: InspectionDuty[];
		QuickResponseCodes?: QuickResponseCode[];
		Statistics?: ObjektBrief.EquipmentStatistics;
		Documents?: Document[];
		KpiMarker?: EquipmentKpiMarker;
		DocumentTypeMarkers?: DocumentTypeMarker[];
	}

	//Entity Type: EquipmentKpiMarker
	export interface EquipmentKpiMarker {
		IsActive: boolean;
		IsDocumented: boolean;
		IsMaintained: boolean;
		IsInspectionDutiesAssigned: boolean;
		HasInspectionDutyUnterminated: boolean;
	}

	//Entity Type: EquipmentType
	export interface EquipmentType {
		Code: string;
		Id: number;
		Name: string;
		SubNo: number;
		Created: Date;
		CostGroup?: CostGroup;
		Equipments?: Equipment[];
		ModuleTypes?: ModuleType[];
		AttributeTypes?: AttributeType[];
	}

	//Entity Type: Event
	export interface Event {
		Identity: string;
		Id: number;
		Type: EventTypes;
		Guidelines: string;
		Qualification: string;
		DueDate: Date;
		RefDate: Date;
		IsSkipped: boolean;
		Created: Date;
		IsDefectHandler: boolean;
		Parent?: ObjektBrief.EventParent;
		Response?: EventResponse;
		Schedule?: Schedule;
		ServiceProvider?: ServiceProvider;
		Building?: Building;
		Documents?: Document[];
		Equipment?: Equipment;
		Module?: Module;
		KpiMarker?: EventKpiMarker;
		DocumentTypeMarkers?: DocumentTypeMarker[];
	}

	//Entity Type: EventKpiMarker
	export interface EventKpiMarker {
		IsActive: boolean;
		IsInFocus: boolean;
		IsPlanned: boolean;
		IsDone: boolean;
		IsOverdue: boolean;
		IsDefective: boolean;
		IsMissingDocuments: boolean;
	}

	//Entity Type: EventResponse
	export interface EventResponse {
		Identity: string;
		Id: number;
		ReferenceDate: Date;
		Comment: string;
		DefectRating: DefectRatings;
		IsServiceComplete: boolean;
		IsDefectProcessed: boolean;
		ResponseDate: Date;
		UpdateDate: Date;
		Created: Date;
		User?: Operations.User;
	}

	//Entity Type: Floor
	export interface Floor {
		Id: number;
		Name: string;
		Order: number;
		Created: Date;
		Rooms?: Room[];
		Buildings?: Building[];
	}

	//Entity Type: Image
	export interface Image {
		Identity: string;
		Id: number;
		Order: number;
		Comment: string;
		FileName: string;
		FileSize: number;
		FileType: string;
		DownloadToken: string;
		Created: Date;
		ThumbNailImageAsBase64String: string;
		Building?: Building;
		Equipment?: Equipment;
		Module?: Module;
		User?: Operations.User;
	}

	//Entity Type: InspectionDuty
	export interface InspectionDuty {
		Identity: string;
		Id: number;
		IsDisabled: boolean;
		Module?: Module;
		OperatorTask?: OperatorTask;
		Equipment?: Equipment;
		Schedule?: Schedule;
	}

	//Entity Type: InspectionDutyHistory
	export interface InspectionDutyHistory {
		Id: number;
		Duty?: InspectionDuty;
	}

	//Entity Type: InspectionDutyUpdate
	export interface InspectionDutyUpdate {
		Identity: string;
		Timestamp: Date;
		Comment: string;
		User?: Operations.User;
		Histories?: InspectionDutyHistory[];
	}

	//Entity Type: LegalArea
	export interface LegalArea {
		Identity: string;
		Id: number;
		Name: string;
		Description: string;
		Created: Date;
		OperatorTasks?: OperatorTask[];
	}

	//Entity Type: MaintenanceContract
	export interface MaintenanceContract {
		Identity: string;
		Id: number;
		Title: string;
		No: string;
		CostServiceHourNetAmount: number;
		CostApproachNetAmount: number;
		AmountNet: number;
		StartDate: Date;
		EndDate: Date;
		CancellationDigit: number;
		CancellationPeriod: ObjektBrief.MaintenanceContractCancellationPeriods;
		CancellationUnit: ObjektBrief.MaintenanceContractCancellationUnits;
		CancellationCalendar: ObjektBrief.MaintenanceContractCancellationCalendars;
		IsGeneralAgreement: boolean;
		Created: Date;
		User?: Operations.User;
		Account?: Operations.Account;
		ServiceProvider?: ServiceProvider;
		Jobs?: MaintenanceJob[];
	}

	//Entity Type: MaintenanceJob
	export interface MaintenanceJob {
		Identity: string;
		Id: number;
		Created: Date;
		IsDisabled: boolean;
		Equipment?: Equipment;
		Schedule?: Schedule;
		Contract?: MaintenanceContract;
	}

	//Entity Type: MeasurementUnit
	export interface MeasurementUnit {
		Code: string;
		Name: string;
	}

	//Entity Type: Module
	export interface Module {
		Identity: string;
		Id: number;
		Quantity: number;
		CustomId: string;
		Name: string;
		Comment: string;
		InstallNo: number;
		Created: Date;
		DataTimestamp: Date;
		AttributeValues?: AttributeValue[];
		Equipment?: Equipment;
		ModuleType?: ModuleType;
		Room?: Room;
		MaintenanceContract?: MaintenanceContract;
		Images?: Image[];
		InspectionDuties?: InspectionDuty[];
		Statistics?: ObjektBrief.ModuleStatistics;
	}

	//Entity Type: ModuleType
	export interface ModuleType {
		Code: string;
		Id: number;
		Name: string;
		Created: Date;
		EquipmentType?: EquipmentType;
		Modules?: Module[];
		AttributeTypes?: AttributeType[];
	}

	//Entity Type: OperatorTask
	export interface OperatorTask {
		Identity: string;
		Id: number;
		Guidelines: string;
		Subject: string;
		Deadline: string;
		Qualification: string;
		Type: string;
		BuildingType: string;
		IsRelevant: boolean;
		TextPassage: string;
		Cycle: number;
		CycleUnit: Common.IntervalUnits;
		Created: Date;
		User?: Operations.User;
		DocumentType?: DocumentType;
		LegalArea?: LegalArea;
		InspectionDuties?: InspectionDuty[];
		EquipmentTypes?: EquipmentType[];
		ModuleTypes?: ModuleType[];
		Regions?: Library.Region[];
	}

	//Entity Type: OperatorTaskMarker
	export interface OperatorTaskMarker {
		Identity: string;
		IsMissing: boolean;
		OperatorTask?: OperatorTask;
	}

	//Entity Type: QuickResponseCode
	export interface QuickResponseCode {
		Id: number;
		Value: string;
		Created: Date;
		User?: Operations.User;
		Equipments?: Equipment[];
	}

	//Entity Type: Room
	export interface Room {
		Identity: string;
		Id: number;
		Name: string;
		Created: Date;
		Statistics?: ObjektBrief.RoomStatistics;
		User?: Operations.User;
		Floor?: Floor;
		Building?: Building;
		Equipments?: Equipment[];
		Modules?: Module[];
	}

	//Entity Type: Schedule
	export interface Schedule {
		Identity: string;
		Id: number;
		Cycle: number;
		CycleUnit: Common.IntervalUnits;
		Interval: Common.Interval;
		CycleStart: Date;
		CycleNext: Date;
		CycleStop: Date;
		InspectionDuty?: InspectionDuty;
		MaintenanceContract?: MaintenanceContract;
		Events?: Event[];
	}

	//Entity Type: ServiceProvider
	export interface ServiceProvider {
		Identity: string;
		Id: number;
		Code: string;
		Name: string;
		ContactPerson: string;
		Department: string;
		ContactPhone1: string;
		ContactPhone2: string;
		ContactEmailAdd: string;
		ContractContactPerson: string;
		ContractContactEmailAdd: string;
		ContractContactPhone: string;
		IsInspection: boolean;
		IsMaintenance: boolean;
		Type: number;
		Address: Common.Address;
		Created: Date;
		User?: Operations.User;
		Account?: Operations.Account;
		Statistics?: ObjektBrief.ServiceProviderStatistics;
		Events?: Event[];
		MaintenanceContracts?: MaintenanceContract[];
	}

	//Entity Type: UsageType
	export interface UsageType {
		Id: number;
		Name: string;
		Created: Date;
		Regions?: Library.Region[];
	}

}

//Namespace: Library
export namespace Library {

	//Entity Type: Country
	export interface Country {
		Code: string;
		Id: number;
		IsoNo: number;
		Name: string;
		Created: Date;
		Buildings?: PropertyCore.Building[];
		Regions?: Region[];
		AccountDataProcessingAgreements?: Operations.AccountDataProcessingAgreement[];
	}

	//Entity Type: Region
	export interface Region {
		Code: string;
		Id: number;
		Name: string;
		Created: Date;
		Country?: Country;
		Buildings?: PropertyCore.Building[];
	}

}

export class AcceptInvitationInOperationsOnAccountInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.AcceptInvitation' : url.endsWith('/') ? url + 'Operations.AcceptInvitation' : url + '/' + 'Operations.AcceptInvitation');
	}

	request: object = {};

	public Parameters(Identity: string, AccessCode: string) {
		this.request['Identity'] = Identity;
		this.request['AccessCode'] = AccessCode;
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class AcceptUsageTermsInOperationsOnUserInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.AcceptUsageTerms' : url.endsWith('/') ? url + 'Operations.AcceptUsageTerms' : url + '/' + 'Operations.AcceptUsageTerms');
	}

	request: object = {};

	public Parameters(AcceptTerms: boolean, CertifyAsCompany: boolean, ForfeitCoolingOffPeriod: boolean) {
		this.request['AcceptTerms'] = AcceptTerms;
		this.request['CertifyAsCompany'] = CertifyAsCompany;
		this.request['ForfeitCoolingOffPeriod'] = ForfeitCoolingOffPeriod;
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class AddDefaultsInOperationsOnBuildingInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.AddDefaults' : url.endsWith('/') ? url + 'Operations.AddDefaults' : url + '/' + 'Operations.AddDefaults');
	}

	request: object = {};

	public Parameters() {
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class BatchChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.BatchChange' : url.endsWith('/') ? url + 'Operations.BatchChange' : url + '/' + 'Operations.BatchChange');
	}

	request: object = {};

	public Parameters(Comment: string, ActivateInspectionDuties: boolean, Changes: PropertyCore.InspectionDutyChange []) {
		this.request['Comment'] = Comment;
		this.request['ActivateInspectionDuties'] = ActivateInspectionDuties;
		this.request['Changes'] = Changes;
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class BulkChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.BulkChange' : url.endsWith('/') ? url + 'Operations.BulkChange' : url + '/' + 'Operations.BulkChange');
	}

	request: object = {};

	public Parameters(Updates: PropertyCore.InspectionDutyChanges) {
		this.request['Updates'] = Updates;
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class CanOperateInOperationsOnAccountInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CanOperate' : url.endsWith('/') ? url + 'Operations.CanOperate' : url + '/' + 'Operations.CanOperate');
	}

	request: object = {};

	public Parameters(Operation: Operations.Operations) {
		this.request['Operation'] = Operation;
		return this;
	}

	public async Execute()  : Promise<Operations.OperationsCheckResult>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Operations.OperationsCheckResult;
		}));
		return lastValueFrom(subscription);
	}

}

export class CanOperateInOperationsOnBuildingInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CanOperate' : url.endsWith('/') ? url + 'Operations.CanOperate' : url + '/' + 'Operations.CanOperate');
	}

	request: object = {};

	public Parameters(Operation: Operations.Operations) {
		this.request['Operation'] = Operation;
		return this;
	}

	public async Execute()  : Promise<Operations.OperationsCheckResult>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Operations.OperationsCheckResult;
		}));
		return lastValueFrom(subscription);
	}

}

export class CanOperateInOperationsOnEquipmentInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CanOperate' : url.endsWith('/') ? url + 'Operations.CanOperate' : url + '/' + 'Operations.CanOperate');
	}

	request: object = {};

	public Parameters(Operation: Operations.Operations) {
		this.request['Operation'] = Operation;
		return this;
	}

	public async Execute()  : Promise<Operations.OperationsCheckResult>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Operations.OperationsCheckResult;
		}));
		return lastValueFrom(subscription);
	}

}

export class CanOperateInOperationsOnEventInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CanOperate' : url.endsWith('/') ? url + 'Operations.CanOperate' : url + '/' + 'Operations.CanOperate');
	}

	request: object = {};

	public Parameters(Operation: Operations.Operations) {
		this.request['Operation'] = Operation;
		return this;
	}

	public async Execute()  : Promise<Operations.OperationsCheckResult>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Operations.OperationsCheckResult;
		}));
		return lastValueFrom(subscription);
	}

}

export class CanOperateInOperationsOnInspectionDutyInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CanOperate' : url.endsWith('/') ? url + 'Operations.CanOperate' : url + '/' + 'Operations.CanOperate');
	}

	request: object = {};

	public Parameters(Operation: Operations.Operations) {
		this.request['Operation'] = Operation;
		return this;
	}

	public async Execute()  : Promise<Operations.OperationsCheckResult>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Operations.OperationsCheckResult;
		}));
		return lastValueFrom(subscription);
	}

}

export class CanOperateInOperationsOnMaintenanceContractInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CanOperate' : url.endsWith('/') ? url + 'Operations.CanOperate' : url + '/' + 'Operations.CanOperate');
	}

	request: object = {};

	public Parameters(Operation: Operations.Operations) {
		this.request['Operation'] = Operation;
		return this;
	}

	public async Execute()  : Promise<Operations.OperationsCheckResult>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Operations.OperationsCheckResult;
		}));
		return lastValueFrom(subscription);
	}

}

export class CanOperateInOperationsOnMaintenanceJobInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CanOperate' : url.endsWith('/') ? url + 'Operations.CanOperate' : url + '/' + 'Operations.CanOperate');
	}

	request: object = {};

	public Parameters(Operation: Operations.Operations) {
		this.request['Operation'] = Operation;
		return this;
	}

	public async Execute()  : Promise<Operations.OperationsCheckResult>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Operations.OperationsCheckResult;
		}));
		return lastValueFrom(subscription);
	}

}

export class CanOperateInOperationsOnScheduleInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CanOperate' : url.endsWith('/') ? url + 'Operations.CanOperate' : url + '/' + 'Operations.CanOperate');
	}

	request: object = {};

	public Parameters(Operation: Operations.Operations) {
		this.request['Operation'] = Operation;
		return this;
	}

	public async Execute()  : Promise<Operations.OperationsCheckResult>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Operations.OperationsCheckResult;
		}));
		return lastValueFrom(subscription);
	}

}

export class CheckNameInOperationsOnAccountInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CheckName' : url.endsWith('/') ? url + 'Operations.CheckName' : url + '/' + 'Operations.CheckName');
	}

	request: object = {};

	public Parameters(CheckValue: string) {
		this.request['CheckValue'] = CheckValue;
		return this;
	}

	public async Execute()  : Promise<boolean>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as boolean;
		}));
		return lastValueFrom(subscription);
	}

}

export class ExpandInPropertyCoreOnScheduleInPropertyCoreOnEntitySet extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'PropertyCore.Expand' : url.endsWith('/') ? url + 'PropertyCore.Expand' : url + '/' + 'PropertyCore.Expand');
	}

	request: object = {};

	public Parameters() {
		return this;
	}

	public async Execute()  : Promise<number>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as number;
		}));
		return lastValueFrom(subscription);
	}

}

export class GeoCodeInTools extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Tools.GeoCode' : url.endsWith('/') ? url + 'Tools.GeoCode' : url + '/' + 'Tools.GeoCode');
	}

	request: object = {};

	public Parameters(Address: Common.AddressGeoCoded) {
		this.request['Address'] = Address;
		return this;
	}

	public async Execute()  : Promise<Common.AddressGeoCoded>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Common.AddressGeoCoded;
		}));
		return lastValueFrom(subscription);
	}

}

export class IsValidInUser extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'User.IsValid' : url.endsWith('/') ? url + 'User.IsValid' : url + '/' + 'User.IsValid');
	}

	request: object = {};

	public Parameters(EMail: string, IsDataEncrypted: boolean) {
		this.request['EMail'] = EMail;
		this.request['IsDataEncrypted'] = IsDataEncrypted;
		return this;
	}

	public async Execute()  : Promise<boolean>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as boolean;
		}));
		return lastValueFrom(subscription);
	}

}

export class MakeSeriesInOperationsOnEventInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.MakeSeries' : url.endsWith('/') ? url + 'Operations.MakeSeries' : url + '/' + 'Operations.MakeSeries');
	}

	request: object = {};

	public Parameters() {
		return this;
	}

	public async Execute()  : Promise<PropertyCore.Schedule>{
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as PropertyCore.Schedule;
		}));
		return lastValueFrom(subscription);
	}

}

export class SendContactRequestInOperationsOnAccountInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.SendContactRequest' : url.endsWith('/') ? url + 'Operations.SendContactRequest' : url + '/' + 'Operations.SendContactRequest');
	}

	request: object = {};

	public Parameters(Message: string) {
		this.request['Message'] = Message;
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class SendMobileApplicationRequestInOperationsOnAccountInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.SendMobileApplicationRequest' : url.endsWith('/') ? url + 'Operations.SendMobileApplicationRequest' : url + '/' + 'Operations.SendMobileApplicationRequest');
	}

	request: object = {};

	public Parameters() {
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class SendQuickResponseCodeRequestInOperationsOnAccountInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.SendQuickResponseCodeRequest' : url.endsWith('/') ? url + 'Operations.SendQuickResponseCodeRequest' : url + '/' + 'Operations.SendQuickResponseCodeRequest');
	}

	request: object = {};

	public Parameters(Amount: number) {
		this.request['Amount'] = Amount;
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class SendServiceRequestInOperationsOnAccountInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.SendServiceRequest' : url.endsWith('/') ? url + 'Operations.SendServiceRequest' : url + '/' + 'Operations.SendServiceRequest');
	}

	request: object = {};

	public Parameters(RequestType: Operations.ServiceRequestTypes, BuildingIdentities?: string []) {
		this.request['RequestType'] = RequestType;
		if (BuildingIdentities) {
			this.request['BuildingIdentities'] = BuildingIdentities;
		}
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class SignDataProcessingAgreementInOperationsOnAccountInOperations extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.SignDataProcessingAgreement' : url.endsWith('/') ? url + 'Operations.SignDataProcessingAgreement' : url + '/' + 'Operations.SignDataProcessingAgreement');
	}

	request: object = {};

	public Parameters() {
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class SingleChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.SingleChange' : url.endsWith('/') ? url + 'Operations.SingleChange' : url + '/' + 'Operations.SingleChange');
	}

	request: object = {};

	public Parameters(Comment: string, Change: PropertyCore.InspectionDutyChange) {
		this.request['Comment'] = Comment;
		this.request['Change'] = Change;
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class SortInOperationsOnImageInPropertyCore extends ODataActionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.Sort' : url.endsWith('/') ? url + 'Operations.Sort' : url + '/' + 'Operations.Sort');
	}

	request: object = {};

	public Parameters(targetIdentity?: string) {
		if (targetIdentity) {
			this.request['targetIdentity'] = targetIdentity;
		}
		return this;
	}

	public async Execute() {
		let subscription = this.settings.http.post(this.getBaseUrl(), JSON.stringify(this.request), {
			withCredentials: false,
			headers: this.settings.headers,
		});
		return lastValueFrom(subscription);
	}

}

export class CalculateDateInOperationsOnScheduleInPropertyCoreOnEntitySet extends ODataFunctionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.CalculateDate' : url + '/' + 'Operations.CalculateDate');
	}

	public Parameters(Start: Date, Step: number, Unit: number) {
		this.parameters += 'Start=' + ODataLiteral.For(Start).toUrlString();
		this.parameters += ','
		this.parameters += 'Step=' + ODataLiteral.For(Step).toUrlString();
		this.parameters += ','
		this.parameters += 'Unit=' + ODataLiteral.For(Unit).toUrlString();
		return this;
	}

	public async Execute(): Promise<Date> {
		var url = this.getBaseUrl();
		let subscription = this.settings.http.get(url, {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as Date;
		}));
		return lastValueFrom(subscription);
	}

}

export class EncodeValueInOperationsOnAttributeTypeInPropertyCoreOnEntitySet extends ODataFunctionOperation {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'Operations.EncodeValue' : url + '/' + 'Operations.EncodeValue');
	}

	public Parameters(Value: string) {
		this.parameters += 'Value=' + ODataLiteral.For(Value).toUrlString();
		return this;
	}

	public async Execute(): Promise<string> {
		var url = this.getBaseUrl();
		let subscription = this.settings.http.get(url, {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as string;
		}));
		return lastValueFrom(subscription);
	}

}

export class SearchInObjektBriefOnBuildingInPropertyCoreOnEntitySet extends ODataFunctionSetOperation<PropertyCore.Building> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'ObjektBrief.Search' : url + '/' + 'ObjektBrief.Search');
	}

	public Parameters(term: string) {
		this.parameters += 'term=' + ODataLiteral.For(term).toUrlString();
		return this;
	}

	public async Execute(): Promise<ODataQueryResult<PropertyCore.Building>> {
		var url = this.getBaseUrl();
		let subscription = this.settings.http.get(url, {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as ODataQueryResult<PropertyCore.Building>;
		}));
		return lastValueFrom(subscription);
	}

}

export class SearchInObjektBriefOnEquipmentInPropertyCoreOnEntitySet extends ODataFunctionSetOperation<PropertyCore.Equipment> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'ObjektBrief.Search' : url + '/' + 'ObjektBrief.Search');
	}

	public Parameters(term: string) {
		this.parameters += 'term=' + ODataLiteral.For(term).toUrlString();
		return this;
	}

	public async Execute(): Promise<ODataQueryResult<PropertyCore.Equipment>> {
		var url = this.getBaseUrl();
		let subscription = this.settings.http.get(url, {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as ODataQueryResult<PropertyCore.Equipment>;
		}));
		return lastValueFrom(subscription);
	}

}

export class SearchInObjektBriefOnModuleInPropertyCoreOnEntitySet extends ODataFunctionSetOperation<PropertyCore.Module> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url === '' ? 'ObjektBrief.Search' : url + '/' + 'ObjektBrief.Search');
	}

	public Parameters(term: string) {
		this.parameters += 'term=' + ODataLiteral.For(term).toUrlString();
		return this;
	}

	public async Execute(): Promise<ODataQueryResult<PropertyCore.Module>> {
		var url = this.getBaseUrl();
		let subscription = this.settings.http.get(url, {
			withCredentials: false,
			headers: this.settings.headers,
		}).pipe(map(a => {
			return a as ODataQueryResult<PropertyCore.Module>;
		}));
		return lastValueFrom(subscription);
	}

}

export class CountryEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class CountryEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class CountryEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class CountryEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class CountryEntityGetOperation extends ODataGetOperation<Library.Country> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new CountryEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new CountryEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class CountryEntitySet extends ODataEntitySet<Library.Country> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Country");
	}

	Get(): CountryEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new CountryEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new CountryEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new CountryEntitySetFunctions(settings, entitySetUrl);
	}

}

export class RegionEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class RegionEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class RegionEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class RegionEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class RegionEntityGetOperation extends ODataGetOperation<Library.Region> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new RegionEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new RegionEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class RegionEntitySet extends ODataEntitySet<Library.Region> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Region");
	}

	Get(): RegionEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new RegionEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new RegionEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new RegionEntitySetFunctions(settings, entitySetUrl);
	}

}

export class UserEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public AcceptUsageTermsInOperationsOnUserInOperations() {
		return new AcceptUsageTermsInOperationsOnUserInOperations(this.settings, this.baseUrl)
	}

}

export class UserEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class UserEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class UserEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class UserEntityGetOperation extends ODataGetOperation<Operations.User> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new UserEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new UserEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class UserEntitySet extends ODataEntitySet<Operations.User> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "User");
	}

	Get(): UserEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new UserEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new UserEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new UserEntitySetFunctions(settings, entitySetUrl);
	}

}

export class AccountEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public AcceptInvitationInOperationsOnAccountInOperations() {
		return new AcceptInvitationInOperationsOnAccountInOperations(this.settings, this.baseUrl)
	}

	public CheckNameInOperationsOnAccountInOperations() {
		return new CheckNameInOperationsOnAccountInOperations(this.settings, this.baseUrl)
	}

	public CanOperateInOperationsOnAccountInOperations() {
		return new CanOperateInOperationsOnAccountInOperations(this.settings, this.baseUrl)
	}

	public SendContactRequestInOperationsOnAccountInOperations() {
		return new SendContactRequestInOperationsOnAccountInOperations(this.settings, this.baseUrl)
	}

	public SendQuickResponseCodeRequestInOperationsOnAccountInOperations() {
		return new SendQuickResponseCodeRequestInOperationsOnAccountInOperations(this.settings, this.baseUrl)
	}

	public SendServiceRequestInOperationsOnAccountInOperations() {
		return new SendServiceRequestInOperationsOnAccountInOperations(this.settings, this.baseUrl)
	}

	public SignDataProcessingAgreementInOperationsOnAccountInOperations() {
		return new SignDataProcessingAgreementInOperationsOnAccountInOperations(this.settings, this.baseUrl)
	}

	public SendMobileApplicationRequestInOperationsOnAccountInOperations() {
		return new SendMobileApplicationRequestInOperationsOnAccountInOperations(this.settings, this.baseUrl)
	}

}

export class AccountEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountEntityGetOperation extends ODataGetOperation<Operations.Account> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new AccountEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new AccountEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class AccountEntitySet extends ODataEntitySet<Operations.Account> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Account");
	}

	Get(): AccountEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountEntitySetFunctions(settings, entitySetUrl);
	}

}

export class AccountRoleEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountRoleEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountRoleEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountRoleEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountRoleEntityGetOperation extends ODataGetOperation<Operations.AccountRole> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new AccountRoleEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new AccountRoleEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class AccountRoleEntitySet extends ODataEntitySet<Operations.AccountRole> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "AccountRole");
	}

	Get(): AccountRoleEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountRoleEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountRoleEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountRoleEntitySetFunctions(settings, entitySetUrl);
	}

}

export class AccountDataProcessingAgreementEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountDataProcessingAgreementEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountDataProcessingAgreementEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountDataProcessingAgreementEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountDataProcessingAgreementEntityGetOperation extends ODataGetOperation<Operations.AccountDataProcessingAgreement> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new AccountDataProcessingAgreementEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new AccountDataProcessingAgreementEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class AccountDataProcessingAgreementEntitySet extends ODataEntitySet<Operations.AccountDataProcessingAgreement> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "AccountDataProcessingAgreement");
	}

	Get(): AccountDataProcessingAgreementEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountDataProcessingAgreementEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountDataProcessingAgreementEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountDataProcessingAgreementEntitySetFunctions(settings, entitySetUrl);
	}

}

export class AccountRightsEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountRightsEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountRightsEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountRightsEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountRightsEntityGetOperation extends ODataGetOperation<Operations.AccountRights> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new AccountRightsEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new AccountRightsEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class AccountRightsEntitySet extends ODataEntitySet<Operations.AccountRights> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "AccountRights");
	}

	Get(): AccountRightsEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountRightsEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountRightsEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountRightsEntitySetFunctions(settings, entitySetUrl);
	}

}

export class AccountSubscriptionHistoryEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountSubscriptionHistoryEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountSubscriptionHistoryEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountSubscriptionHistoryEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AccountSubscriptionHistoryEntityGetOperation extends ODataGetOperation<Operations.AccountSubscriptionHistory> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new AccountSubscriptionHistoryEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new AccountSubscriptionHistoryEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class AccountSubscriptionHistoryEntitySet extends ODataEntitySet<Operations.AccountSubscriptionHistory> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "AccountSubscriptionHistory");
	}

	Get(): AccountSubscriptionHistoryEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountSubscriptionHistoryEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountSubscriptionHistoryEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AccountSubscriptionHistoryEntitySetFunctions(settings, entitySetUrl);
	}

}

export class BuildingRoleEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingRoleEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingRoleEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingRoleEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingRoleEntityGetOperation extends ODataGetOperation<Operations.BuildingRole> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new BuildingRoleEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new BuildingRoleEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class BuildingRoleEntitySet extends ODataEntitySet<Operations.BuildingRole> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "BuildingRole");
	}

	Get(): BuildingRoleEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingRoleEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingRoleEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingRoleEntitySetFunctions(settings, entitySetUrl);
	}

}

export class InvitationEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InvitationEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InvitationEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InvitationEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InvitationEntityGetOperation extends ODataGetOperation<Operations.Invitation> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new InvitationEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new InvitationEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class InvitationEntitySet extends ODataEntitySet<Operations.Invitation> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Invitation");
	}

	Get(): InvitationEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InvitationEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InvitationEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InvitationEntitySetFunctions(settings, entitySetUrl);
	}

}

export class BuildingEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public CanOperateInOperationsOnBuildingInPropertyCore() {
		return new CanOperateInOperationsOnBuildingInPropertyCore(this.settings, this.baseUrl)
	}

	public AddDefaultsInOperationsOnBuildingInPropertyCore() {
		return new AddDefaultsInOperationsOnBuildingInPropertyCore(this.settings, this.baseUrl)
	}

}

export class BuildingEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public SearchInObjektBriefOnBuildingInPropertyCoreOnEntitySet() {
		return new SearchInObjektBriefOnBuildingInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

}

export class BuildingEntityGetOperation extends ODataGetOperation<PropertyCore.Building> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new BuildingEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new BuildingEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class BuildingEntitySet extends ODataEntitySet<PropertyCore.Building> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Building");
	}

	Get(): BuildingEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingEntitySetFunctions(settings, entitySetUrl);
	}

}

export class EquipmentEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public CanOperateInOperationsOnEquipmentInPropertyCore() {
		return new CanOperateInOperationsOnEquipmentInPropertyCore(this.settings, this.baseUrl)
	}

}

export class EquipmentEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EquipmentEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EquipmentEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public SearchInObjektBriefOnEquipmentInPropertyCoreOnEntitySet() {
		return new SearchInObjektBriefOnEquipmentInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

}

export class EquipmentEntityGetOperation extends ODataGetOperation<PropertyCore.Equipment> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new EquipmentEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new EquipmentEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class EquipmentEntitySet extends ODataEntitySet<PropertyCore.Equipment> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Equipment");
	}

	Get(): EquipmentEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EquipmentEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EquipmentEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EquipmentEntitySetFunctions(settings, entitySetUrl);
	}

}

export class ModuleEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ModuleEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ModuleEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ModuleEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public SearchInObjektBriefOnModuleInPropertyCoreOnEntitySet() {
		return new SearchInObjektBriefOnModuleInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

}

export class ModuleEntityGetOperation extends ODataGetOperation<PropertyCore.Module> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new ModuleEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new ModuleEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class ModuleEntitySet extends ODataEntitySet<PropertyCore.Module> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Module");
	}

	Get(): ModuleEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ModuleEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ModuleEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ModuleEntitySetFunctions(settings, entitySetUrl);
	}

}

export class AttributeTypeEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AttributeTypeEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AttributeTypeEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AttributeTypeEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public EncodeValueInOperationsOnAttributeTypeInPropertyCoreOnEntitySet() {
		return new EncodeValueInOperationsOnAttributeTypeInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

}

export class AttributeTypeEntityGetOperation extends ODataGetOperation<PropertyCore.AttributeType> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new AttributeTypeEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new AttributeTypeEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class AttributeTypeEntitySet extends ODataEntitySet<PropertyCore.AttributeType> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "AttributeType");
	}

	Get(): AttributeTypeEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AttributeTypeEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AttributeTypeEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AttributeTypeEntitySetFunctions(settings, entitySetUrl);
	}

}

export class AttributeTypeSetEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AttributeTypeSetEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AttributeTypeSetEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AttributeTypeSetEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class AttributeTypeSetEntityGetOperation extends ODataGetOperation<PropertyCore.AttributeTypeSet> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new AttributeTypeSetEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new AttributeTypeSetEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class AttributeTypeSetEntitySet extends ODataEntitySet<PropertyCore.AttributeTypeSet> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "AttributeTypeSet");
	}

	Get(): AttributeTypeSetEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AttributeTypeSetEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AttributeTypeSetEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new AttributeTypeSetEntitySetFunctions(settings, entitySetUrl);
	}

}

export class BuildingStatisticsEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingStatisticsEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingStatisticsEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingStatisticsEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class BuildingStatisticsEntityGetOperation extends ODataGetOperation<PropertyCore.BuildingStatistics> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new BuildingStatisticsEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new BuildingStatisticsEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class BuildingStatisticsEntitySet extends ODataEntitySet<PropertyCore.BuildingStatistics> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "BuildingStatistics");
	}

	Get(): BuildingStatisticsEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingStatisticsEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingStatisticsEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new BuildingStatisticsEntitySetFunctions(settings, entitySetUrl);
	}

}

export class EquipmentTypeEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EquipmentTypeEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EquipmentTypeEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EquipmentTypeEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EquipmentTypeEntityGetOperation extends ODataGetOperation<PropertyCore.EquipmentType> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new EquipmentTypeEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new EquipmentTypeEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class EquipmentTypeEntitySet extends ODataEntitySet<PropertyCore.EquipmentType> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "EquipmentType");
	}

	Get(): EquipmentTypeEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EquipmentTypeEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EquipmentTypeEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EquipmentTypeEntitySetFunctions(settings, entitySetUrl);
	}

}

export class ModuleTypeEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ModuleTypeEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ModuleTypeEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ModuleTypeEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ModuleTypeEntityGetOperation extends ODataGetOperation<PropertyCore.ModuleType> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new ModuleTypeEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new ModuleTypeEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class ModuleTypeEntitySet extends ODataEntitySet<PropertyCore.ModuleType> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "ModuleType");
	}

	Get(): ModuleTypeEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ModuleTypeEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ModuleTypeEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ModuleTypeEntitySetFunctions(settings, entitySetUrl);
	}

}

export class ScheduleEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public CanOperateInOperationsOnScheduleInPropertyCore() {
		return new CanOperateInOperationsOnScheduleInPropertyCore(this.settings, this.baseUrl)
	}

}

export class ScheduleEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ScheduleEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public ExpandInPropertyCoreOnScheduleInPropertyCoreOnEntitySet() {
		return new ExpandInPropertyCoreOnScheduleInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

}

export class ScheduleEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public CalculateDateInOperationsOnScheduleInPropertyCoreOnEntitySet() {
		return new CalculateDateInOperationsOnScheduleInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

}

export class ScheduleEntityGetOperation extends ODataGetOperation<PropertyCore.Schedule> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new ScheduleEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new ScheduleEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class ScheduleEntitySet extends ODataEntitySet<PropertyCore.Schedule> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Schedule");
	}

	Get(): ScheduleEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ScheduleEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ScheduleEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ScheduleEntitySetFunctions(settings, entitySetUrl);
	}

}

export class EventEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public CanOperateInOperationsOnEventInPropertyCore() {
		return new CanOperateInOperationsOnEventInPropertyCore(this.settings, this.baseUrl)
	}

	public MakeSeriesInOperationsOnEventInPropertyCore() {
		return new MakeSeriesInOperationsOnEventInPropertyCore(this.settings, this.baseUrl)
	}

}

export class EventEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EventEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EventEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EventEntityGetOperation extends ODataGetOperation<PropertyCore.Event> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new EventEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new EventEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class EventEntitySet extends ODataEntitySet<PropertyCore.Event> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Event");
	}

	Get(): EventEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EventEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EventEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EventEntitySetFunctions(settings, entitySetUrl);
	}

}

export class EventResponseEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EventResponseEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EventResponseEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EventResponseEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class EventResponseEntityGetOperation extends ODataGetOperation<PropertyCore.EventResponse> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new EventResponseEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new EventResponseEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class EventResponseEntitySet extends ODataEntitySet<PropertyCore.EventResponse> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "EventResponse");
	}

	Get(): EventResponseEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EventResponseEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EventResponseEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new EventResponseEntitySetFunctions(settings, entitySetUrl);
	}

}

export class MaintenanceContractEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public CanOperateInOperationsOnMaintenanceContractInPropertyCore() {
		return new CanOperateInOperationsOnMaintenanceContractInPropertyCore(this.settings, this.baseUrl)
	}

}

export class MaintenanceContractEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MaintenanceContractEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MaintenanceContractEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MaintenanceContractEntityGetOperation extends ODataGetOperation<PropertyCore.MaintenanceContract> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new MaintenanceContractEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new MaintenanceContractEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class MaintenanceContractEntitySet extends ODataEntitySet<PropertyCore.MaintenanceContract> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "MaintenanceContract");
	}

	Get(): MaintenanceContractEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MaintenanceContractEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MaintenanceContractEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MaintenanceContractEntitySetFunctions(settings, entitySetUrl);
	}

}

export class MaintenanceJobEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public CanOperateInOperationsOnMaintenanceJobInPropertyCore() {
		return new CanOperateInOperationsOnMaintenanceJobInPropertyCore(this.settings, this.baseUrl)
	}

}

export class MaintenanceJobEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MaintenanceJobEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MaintenanceJobEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MaintenanceJobEntityGetOperation extends ODataGetOperation<PropertyCore.MaintenanceJob> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new MaintenanceJobEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new MaintenanceJobEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class MaintenanceJobEntitySet extends ODataEntitySet<PropertyCore.MaintenanceJob> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "MaintenanceJob");
	}

	Get(): MaintenanceJobEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MaintenanceJobEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MaintenanceJobEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MaintenanceJobEntitySetFunctions(settings, entitySetUrl);
	}

}

export class RoomEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class RoomEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class RoomEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class RoomEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class RoomEntityGetOperation extends ODataGetOperation<PropertyCore.Room> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new RoomEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new RoomEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class RoomEntitySet extends ODataEntitySet<PropertyCore.Room> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Room");
	}

	Get(): RoomEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new RoomEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new RoomEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new RoomEntitySetFunctions(settings, entitySetUrl);
	}

}

export class FloorEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class FloorEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class FloorEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class FloorEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class FloorEntityGetOperation extends ODataGetOperation<PropertyCore.Floor> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new FloorEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new FloorEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class FloorEntitySet extends ODataEntitySet<PropertyCore.Floor> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Floor");
	}

	Get(): FloorEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new FloorEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new FloorEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new FloorEntitySetFunctions(settings, entitySetUrl);
	}

}

export class MeasurementUnitEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MeasurementUnitEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MeasurementUnitEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MeasurementUnitEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class MeasurementUnitEntityGetOperation extends ODataGetOperation<PropertyCore.MeasurementUnit> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new MeasurementUnitEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new MeasurementUnitEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class MeasurementUnitEntitySet extends ODataEntitySet<PropertyCore.MeasurementUnit> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "MeasurementUnit");
	}

	Get(): MeasurementUnitEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MeasurementUnitEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MeasurementUnitEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new MeasurementUnitEntitySetFunctions(settings, entitySetUrl);
	}

}

export class UsageTypeEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class UsageTypeEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class UsageTypeEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class UsageTypeEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class UsageTypeEntityGetOperation extends ODataGetOperation<PropertyCore.UsageType> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new UsageTypeEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new UsageTypeEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class UsageTypeEntitySet extends ODataEntitySet<PropertyCore.UsageType> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "UsageType");
	}

	Get(): UsageTypeEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new UsageTypeEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new UsageTypeEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new UsageTypeEntitySetFunctions(settings, entitySetUrl);
	}

}

export class CostGroupEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class CostGroupEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class CostGroupEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class CostGroupEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class CostGroupEntityGetOperation extends ODataGetOperation<PropertyCore.CostGroup> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new CostGroupEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new CostGroupEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class CostGroupEntitySet extends ODataEntitySet<PropertyCore.CostGroup> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "CostGroup");
	}

	Get(): CostGroupEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new CostGroupEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new CostGroupEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new CostGroupEntitySetFunctions(settings, entitySetUrl);
	}

}

export class ActionLogEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ActionLogEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ActionLogEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ActionLogEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ActionLogEntityGetOperation extends ODataGetOperation<PropertyCore.ActionLog> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new ActionLogEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new ActionLogEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class ActionLogEntitySet extends ODataEntitySet<PropertyCore.ActionLog> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "ActionLog");
	}

	Get(): ActionLogEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ActionLogEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ActionLogEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ActionLogEntitySetFunctions(settings, entitySetUrl);
	}

}

export class ServiceProviderEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ServiceProviderEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ServiceProviderEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ServiceProviderEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ServiceProviderEntityGetOperation extends ODataGetOperation<PropertyCore.ServiceProvider> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new ServiceProviderEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new ServiceProviderEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class ServiceProviderEntitySet extends ODataEntitySet<PropertyCore.ServiceProvider> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "ServiceProvider");
	}

	Get(): ServiceProviderEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ServiceProviderEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ServiceProviderEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ServiceProviderEntitySetFunctions(settings, entitySetUrl);
	}

}

export class DocumentEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentEntityGetOperation extends ODataGetOperation<PropertyCore.Document> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new DocumentEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new DocumentEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class DocumentEntitySet extends ODataEntitySet<PropertyCore.Document> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Document");
	}

	Get(): DocumentEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentEntitySetFunctions(settings, entitySetUrl);
	}

}

export class DocumentTypeEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentTypeEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentTypeEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentTypeEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentTypeEntityGetOperation extends ODataGetOperation<PropertyCore.DocumentType> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new DocumentTypeEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new DocumentTypeEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class DocumentTypeEntitySet extends ODataEntitySet<PropertyCore.DocumentType> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "DocumentType");
	}

	Get(): DocumentTypeEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentTypeEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentTypeEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentTypeEntitySetFunctions(settings, entitySetUrl);
	}

}

export class DocumentTypeGroupEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentTypeGroupEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentTypeGroupEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentTypeGroupEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class DocumentTypeGroupEntityGetOperation extends ODataGetOperation<PropertyCore.DocumentTypeGroup> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new DocumentTypeGroupEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new DocumentTypeGroupEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class DocumentTypeGroupEntitySet extends ODataEntitySet<PropertyCore.DocumentTypeGroup> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "DocumentTypeGroup");
	}

	Get(): DocumentTypeGroupEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentTypeGroupEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentTypeGroupEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new DocumentTypeGroupEntitySetFunctions(settings, entitySetUrl);
	}

}

export class ImageEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public SortInOperationsOnImageInPropertyCore() {
		return new SortInOperationsOnImageInPropertyCore(this.settings, this.baseUrl)
	}

}

export class ImageEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ImageEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ImageEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class ImageEntityGetOperation extends ODataGetOperation<PropertyCore.Image> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new ImageEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new ImageEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class ImageEntitySet extends ODataEntitySet<PropertyCore.Image> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "Image");
	}

	Get(): ImageEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ImageEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ImageEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new ImageEntitySetFunctions(settings, entitySetUrl);
	}

}

export class LegalAreaEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class LegalAreaEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class LegalAreaEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class LegalAreaEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class LegalAreaEntityGetOperation extends ODataGetOperation<PropertyCore.LegalArea> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new LegalAreaEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new LegalAreaEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class LegalAreaEntitySet extends ODataEntitySet<PropertyCore.LegalArea> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "LegalArea");
	}

	Get(): LegalAreaEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new LegalAreaEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new LegalAreaEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new LegalAreaEntitySetFunctions(settings, entitySetUrl);
	}

}

export class OperatorTaskEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class OperatorTaskEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class OperatorTaskEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class OperatorTaskEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class OperatorTaskEntityGetOperation extends ODataGetOperation<PropertyCore.OperatorTask> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new OperatorTaskEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new OperatorTaskEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class OperatorTaskEntitySet extends ODataEntitySet<PropertyCore.OperatorTask> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "OperatorTask");
	}

	Get(): OperatorTaskEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new OperatorTaskEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new OperatorTaskEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new OperatorTaskEntitySetFunctions(settings, entitySetUrl);
	}

}

export class InspectionDutyHistoryEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyHistoryEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyHistoryEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyHistoryEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyHistoryEntityGetOperation extends ODataGetOperation<PropertyCore.InspectionDutyHistory> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new InspectionDutyHistoryEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new InspectionDutyHistoryEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class InspectionDutyHistoryEntitySet extends ODataEntitySet<PropertyCore.InspectionDutyHistory> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "InspectionDutyHistory");
	}

	Get(): InspectionDutyHistoryEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyHistoryEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyHistoryEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyHistoryEntitySetFunctions(settings, entitySetUrl);
	}

}

export class InspectionDutyUpdateEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyUpdateEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyUpdateEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyUpdateEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyUpdateEntityGetOperation extends ODataGetOperation<PropertyCore.InspectionDutyUpdate> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new InspectionDutyUpdateEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new InspectionDutyUpdateEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class InspectionDutyUpdateEntitySet extends ODataEntitySet<PropertyCore.InspectionDutyUpdate> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "InspectionDutyUpdate");
	}

	Get(): InspectionDutyUpdateEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyUpdateEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyUpdateEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyUpdateEntitySetFunctions(settings, entitySetUrl);
	}

}

export class InspectionDutyEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public CanOperateInOperationsOnInspectionDutyInPropertyCore() {
		return new CanOperateInOperationsOnInspectionDutyInPropertyCore(this.settings, this.baseUrl)
	}

}

export class InspectionDutyEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public SingleChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet() {
		return new SingleChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

	public BulkChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet() {
		return new BulkChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

	public BatchChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet() {
		return new BatchChangeInOperationsOnInspectionDutyInPropertyCoreOnEntitySet(this.settings, this.baseUrl)
	}

}

export class InspectionDutyEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class InspectionDutyEntityGetOperation extends ODataGetOperation<PropertyCore.InspectionDuty> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new InspectionDutyEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new InspectionDutyEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class InspectionDutyEntitySet extends ODataEntitySet<PropertyCore.InspectionDuty> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "InspectionDuty");
	}

	Get(): InspectionDutyEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new InspectionDutyEntitySetFunctions(settings, entitySetUrl);
	}

}

export class QuickResponseCodeEntityActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class QuickResponseCodeEntityFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class QuickResponseCodeEntitySetActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class QuickResponseCodeEntitySetFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

export class QuickResponseCodeEntityGetOperation extends ODataGetOperation<PropertyCore.QuickResponseCode> {

	constructor(settings: ODataSettings, url: string) {
		super(settings, url);
	}

	public Actions() {
		return new QuickResponseCodeEntityActions(this.settings, this.buildQueryUrl());
	}

	public Functions() {
		return new QuickResponseCodeEntityFunctions(this.settings, this.buildQueryUrl());
	}

}

export class QuickResponseCodeEntitySet extends ODataEntitySet<PropertyCore.QuickResponseCode> {

	constructor(odatasettings: ODataSettings) {
		super(odatasettings, "QuickResponseCode");
	}

	Get(): QuickResponseCodeEntityGetOperation {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new QuickResponseCodeEntityGetOperation(settings, entitySetUrl);
	}

	public Actions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new QuickResponseCodeEntitySetActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = this.definedname;
		let settings = this.settings;
		return new QuickResponseCodeEntitySetFunctions(settings, entitySetUrl);
	}

}

export class ODataCoreContextODataTypes {

	public Account() {
		return ODataType.For('Account','Operations')
	}

	public AccountDataProcessingAgreement() {
		return ODataType.For('AccountDataProcessingAgreement','Operations')
	}

	public AccountProfile() {
		return ODataType.For('AccountProfile','ObjektBrief')
	}

	public AccountRights() {
		return ODataType.For('AccountRights','Operations')
	}

	public AccountRole() {
		return ODataType.For('AccountRole','Operations')
	}

	public AccountStatistics() {
		return ODataType.For('AccountStatistics','ObjektBrief')
	}

	public AccountSubscriptionHistory() {
		return ODataType.For('AccountSubscriptionHistory','Operations')
	}

	public ActionLog() {
		return ODataType.For('ActionLog','PropertyCore')
	}

	public AttributeType() {
		return ODataType.For('AttributeType','PropertyCore')
	}

	public AttributeTypeSet() {
		return ODataType.For('AttributeTypeSet','PropertyCore')
	}

	public AttributeValue() {
		return ODataType.For('AttributeValue','PropertyCore')
	}

	public Building() {
		return ODataType.For('Building','PropertyCore')
	}

	public BuildingKpiMarker() {
		return ODataType.For('BuildingKpiMarker','PropertyCore')
	}

	public BuildingRole() {
		return ODataType.For('BuildingRole','Operations')
	}

	public BuildingStatistics() {
		return ODataType.For('BuildingStatistics','PropertyCore')
	}

	public CostGroup() {
		return ODataType.For('CostGroup','PropertyCore')
	}

	public Country() {
		return ODataType.For('Country','Library')
	}

	public Document() {
		return ODataType.For('Document','PropertyCore')
	}

	public DocumentType() {
		return ODataType.For('DocumentType','PropertyCore')
	}

	public DocumentTypeGroup() {
		return ODataType.For('DocumentTypeGroup','PropertyCore')
	}

	public DocumentTypeMarker() {
		return ODataType.For('DocumentTypeMarker','PropertyCore')
	}

	public Equipment() {
		return ODataType.For('Equipment','PropertyCore')
	}

	public EquipmentKpiMarker() {
		return ODataType.For('EquipmentKpiMarker','PropertyCore')
	}

	public EquipmentStatistics() {
		return ODataType.For('EquipmentStatistics','ObjektBrief')
	}

	public EquipmentType() {
		return ODataType.For('EquipmentType','PropertyCore')
	}

	public Event() {
		return ODataType.For('Event','PropertyCore')
	}

	public EventKpiMarker() {
		return ODataType.For('EventKpiMarker','PropertyCore')
	}

	public EventParent() {
		return ODataType.For('EventParent','ObjektBrief')
	}

	public EventResponse() {
		return ODataType.For('EventResponse','PropertyCore')
	}

	public Floor() {
		return ODataType.For('Floor','PropertyCore')
	}

	public Image() {
		return ODataType.For('Image','PropertyCore')
	}

	public InspectionDuty() {
		return ODataType.For('InspectionDuty','PropertyCore')
	}

	public InspectionDutyHistory() {
		return ODataType.For('InspectionDutyHistory','PropertyCore')
	}

	public InspectionDutyUpdate() {
		return ODataType.For('InspectionDutyUpdate','PropertyCore')
	}

	public Invitation() {
		return ODataType.For('Invitation','Operations')
	}

	public LegalArea() {
		return ODataType.For('LegalArea','PropertyCore')
	}

	public MaintenanceContract() {
		return ODataType.For('MaintenanceContract','PropertyCore')
	}

	public MaintenanceJob() {
		return ODataType.For('MaintenanceJob','PropertyCore')
	}

	public MeasurementUnit() {
		return ODataType.For('MeasurementUnit','PropertyCore')
	}

	public Module() {
		return ODataType.For('Module','PropertyCore')
	}

	public ModuleStatistics() {
		return ODataType.For('ModuleStatistics','ObjektBrief')
	}

	public ModuleType() {
		return ODataType.For('ModuleType','PropertyCore')
	}

	public OperatorTask() {
		return ODataType.For('OperatorTask','PropertyCore')
	}

	public OperatorTaskMarker() {
		return ODataType.For('OperatorTaskMarker','PropertyCore')
	}

	public QuickResponseCode() {
		return ODataType.For('QuickResponseCode','PropertyCore')
	}

	public Region() {
		return ODataType.For('Region','Library')
	}

	public Room() {
		return ODataType.For('Room','PropertyCore')
	}

	public RoomStatistics() {
		return ODataType.For('RoomStatistics','ObjektBrief')
	}

	public Schedule() {
		return ODataType.For('Schedule','PropertyCore')
	}

	public ServiceProvider() {
		return ODataType.For('ServiceProvider','PropertyCore')
	}

	public ServiceProviderStatistics() {
		return ODataType.For('ServiceProviderStatistics','ObjektBrief')
	}

	public UsageType() {
		return ODataType.For('UsageType','PropertyCore')
	}

	public User() {
		return ODataType.For('User','Operations')
	}

}

export class ODataCoreContextContainerActions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

	public GeoCodeInTools() {
		return new GeoCodeInTools(this.settings, this.baseUrl)
	}

	public IsValidInUser() {
		return new IsValidInUser(this.settings, this.baseUrl)
	}

}

export class ODataCoreContextContainerFunctions extends ODataOperationSet {

	constructor(settings: ODataSettings, baseUrl: string) {
		super(settings, baseUrl);
	}

}

@Injectable()
export class ODataCoreContext extends ODataContext {

	constructor() {
		super();

		this.Account = new AccountEntitySet(this.ODataSettings);
		this.AccountDataProcessingAgreement = new AccountDataProcessingAgreementEntitySet(this.ODataSettings);
		this.AccountRights = new AccountRightsEntitySet(this.ODataSettings);
		this.AccountRole = new AccountRoleEntitySet(this.ODataSettings);
		this.AccountSubscriptionHistory = new AccountSubscriptionHistoryEntitySet(this.ODataSettings);
		this.ActionLog = new ActionLogEntitySet(this.ODataSettings);
		this.AttributeType = new AttributeTypeEntitySet(this.ODataSettings);
		this.AttributeTypeSet = new AttributeTypeSetEntitySet(this.ODataSettings);
		this.Building = new BuildingEntitySet(this.ODataSettings);
		this.BuildingRole = new BuildingRoleEntitySet(this.ODataSettings);
		this.BuildingStatistics = new BuildingStatisticsEntitySet(this.ODataSettings);
		this.CostGroup = new CostGroupEntitySet(this.ODataSettings);
		this.Country = new CountryEntitySet(this.ODataSettings);
		this.Document = new DocumentEntitySet(this.ODataSettings);
		this.DocumentType = new DocumentTypeEntitySet(this.ODataSettings);
		this.DocumentTypeGroup = new DocumentTypeGroupEntitySet(this.ODataSettings);
		this.Equipment = new EquipmentEntitySet(this.ODataSettings);
		this.EquipmentType = new EquipmentTypeEntitySet(this.ODataSettings);
		this.Event = new EventEntitySet(this.ODataSettings);
		this.EventResponse = new EventResponseEntitySet(this.ODataSettings);
		this.Floor = new FloorEntitySet(this.ODataSettings);
		this.Image = new ImageEntitySet(this.ODataSettings);
		this.InspectionDuty = new InspectionDutyEntitySet(this.ODataSettings);
		this.InspectionDutyHistory = new InspectionDutyHistoryEntitySet(this.ODataSettings);
		this.InspectionDutyUpdate = new InspectionDutyUpdateEntitySet(this.ODataSettings);
		this.Invitation = new InvitationEntitySet(this.ODataSettings);
		this.LegalArea = new LegalAreaEntitySet(this.ODataSettings);
		this.MaintenanceContract = new MaintenanceContractEntitySet(this.ODataSettings);
		this.MaintenanceJob = new MaintenanceJobEntitySet(this.ODataSettings);
		this.MeasurementUnit = new MeasurementUnitEntitySet(this.ODataSettings);
		this.Module = new ModuleEntitySet(this.ODataSettings);
		this.ModuleType = new ModuleTypeEntitySet(this.ODataSettings);
		this.OperatorTask = new OperatorTaskEntitySet(this.ODataSettings);
		this.QuickResponseCode = new QuickResponseCodeEntitySet(this.ODataSettings);
		this.Region = new RegionEntitySet(this.ODataSettings);
		this.Room = new RoomEntitySet(this.ODataSettings);
		this.Schedule = new ScheduleEntitySet(this.ODataSettings);
		this.ServiceProvider = new ServiceProviderEntitySet(this.ODataSettings);
		this.UsageType = new UsageTypeEntitySet(this.ODataSettings);
		this.User = new UserEntitySet(this.ODataSettings);
	}

	public ODataTypes() {
		return new ODataCoreContextODataTypes();
	}

	public Actions() {
		let entitySetUrl = '';
		let settings = this.ODataSettings;
		return new ODataCoreContextContainerActions(settings, entitySetUrl);
	}

	public Functions() {
		let entitySetUrl = '';
		let settings = this.ODataSettings;
		return new ODataCoreContextContainerFunctions(settings, entitySetUrl);
	}

	// Entity Set Variables
	Account: AccountEntitySet;
	AccountDataProcessingAgreement: AccountDataProcessingAgreementEntitySet;
	AccountRights: AccountRightsEntitySet;
	AccountRole: AccountRoleEntitySet;
	AccountSubscriptionHistory: AccountSubscriptionHistoryEntitySet;
	ActionLog: ActionLogEntitySet;
	AttributeType: AttributeTypeEntitySet;
	AttributeTypeSet: AttributeTypeSetEntitySet;
	Building: BuildingEntitySet;
	BuildingRole: BuildingRoleEntitySet;
	BuildingStatistics: BuildingStatisticsEntitySet;
	CostGroup: CostGroupEntitySet;
	Country: CountryEntitySet;
	Document: DocumentEntitySet;
	DocumentType: DocumentTypeEntitySet;
	DocumentTypeGroup: DocumentTypeGroupEntitySet;
	Equipment: EquipmentEntitySet;
	EquipmentType: EquipmentTypeEntitySet;
	Event: EventEntitySet;
	EventResponse: EventResponseEntitySet;
	Floor: FloorEntitySet;
	Image: ImageEntitySet;
	InspectionDuty: InspectionDutyEntitySet;
	InspectionDutyHistory: InspectionDutyHistoryEntitySet;
	InspectionDutyUpdate: InspectionDutyUpdateEntitySet;
	Invitation: InvitationEntitySet;
	LegalArea: LegalAreaEntitySet;
	MaintenanceContract: MaintenanceContractEntitySet;
	MaintenanceJob: MaintenanceJobEntitySet;
	MeasurementUnit: MeasurementUnitEntitySet;
	Module: ModuleEntitySet;
	ModuleType: ModuleTypeEntitySet;
	OperatorTask: OperatorTaskEntitySet;
	QuickResponseCode: QuickResponseCodeEntitySet;
	Region: RegionEntitySet;
	Room: RoomEntitySet;
	Schedule: ScheduleEntitySet;
	ServiceProvider: ServiceProviderEntitySet;
	UsageType: UsageTypeEntitySet;
	User: UserEntitySet;

}
