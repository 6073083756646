import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { of, Subject, Subscription, from } from 'rxjs';
import { mergeMap, startWith } from 'rxjs/operators';

import { AuthService } from './../../core/auth/auth.service';
import { BuildingDocumentsInputData } from './../../core/components/building/building-documents-input-data';
import { EquipmentDocumentsInputData } from './../../core/components/equipment/equipment-documents-input-data';

import { DocumentType } from './../wizard/document/document-wizard-model';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';
import { ODataPath } from 'app/core/odata/odataclient';

@Component({
  selector: 'app-document-building-equipment',
  templateUrl: './document-building-equipment.component.html',
  styleUrls: ['./document-building-equipment.component.scss']
})
export class DocumentBuildingEquipmentComponent implements OnInit, OnDestroy {

  buildingDocumentsInputData: BuildingDocumentsInputData = new BuildingDocumentsInputData();
  buildingDocumentsSubject = new Subject<string>();
  equipmentDocumentsInputData: EquipmentDocumentsInputData = new EquipmentDocumentsInputData();
  equipmentDocumentsSubject = new Subject<string>();

  buildingUT: string;

  _searchFilter: string;
  searchFilterSubject = new Subject<string>();

  DOCUMENT_TYPE_BUILDING = DocumentType.BUILDING;
  DOCUMENT_TYPE_EQUIPMENT = DocumentType.EQUIPMENT;

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.parent.paramMap.subscribe((params: ParamMap) => {
      this.buildingUT = params.get('id');

      this.buildingDocumentsInputData.buildingDocument$ = this.buildingDocumentsSubject.asObservable().pipe(startWith(''), mergeMap(() =>
        from(this.odataCoreService.Building.Get()
          .Key(Guid.parse(this.buildingUT))
          .Expand(x => {
            x.Expand("DocumentTypeMarkers",
              y => {
                y.Expand("DocumentType")
                y.Filter(z => z
                  .EqualsField("IsRequired", true).And
                  .EqualsField("IsMissing", true))
              })
            x.Expand("Documents",
              y => {
                y.Expand("DocumentType")
              })  
          })
          .Exec().then(x => x.value))));

    this.buildingDocumentsInputData.searchFilter$ = this.searchFilterSubject.asObservable().pipe(startWith(''));
    this.buildingDocumentsInputData.currentBuildingUT$ = of(this.buildingUT);
    this.buildingDocumentsInputData.currentDocumentTypeId$ = of(<number>null);

      this.equipmentDocumentsInputData.buildingEquipmentDocument$ = this.equipmentDocumentsSubject.asObservable().pipe(startWith(''), mergeMap(() =>
      from(this.odataCoreService.Building.Get()
      .Key(Guid.parse(this.buildingUT))
      .Expand(x => {
        x.Expand("Equipments",
            y => {
              y.OrderBy('Name')
            y.Expand("Documents", z => z
              .Expand("DocumentType"))
            y.Expand("DocumentTypeMarkers", z => {
              z.Expand("DocumentType")
              .Filter(a => a
                .EqualsField("IsRequired", true).And
                .EqualsField("IsMissing", true))
              y.Expand("Room", z => {
                z.Expand("Floor")
              })
            })
            y.Expand("KpiMarker")
            y.Filter(z => z
              .Any("Documents","").Or
              .Equals(ODataPath.For("KpiMarker", "IsDocumented"), false).And
              .Equals(ODataPath.For("KpiMarker", "IsActive"), true).And
              .EqualsField("IsRenterResponsibility", false).Or
              .Equals(ODataPath.For("KpiMarker", "IsDocumented"), false).And
              .Equals(ODataPath.For("KpiMarker", "IsActive"), false).And
              .EqualsField("IsRenterResponsibility", false).And
              .EqualsField("IsNoMaintenanceDesired",true)
              )
            })
      })
      .Exec().then(x => x.value))));


    this.equipmentDocumentsInputData.searchFilter$ = this.searchFilterSubject.asObservable().pipe(startWith(''));
    this.equipmentDocumentsInputData.currentEquipmentUT$ = of(<string>null);
    this.equipmentDocumentsInputData.currentDocumentTypeId$ = of(<number>null);
  }));
}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  reloadBuildingDocuments() {
    this.buildingDocumentsSubject.next('');
  }

  reloadEquipmentDocuments() {
    this.equipmentDocumentsSubject.next('');
  }

  documentWizard(buildingUT: string, type: number = undefined, documentType: number = undefined, installEquipmentUT: string = undefined) {
    this.router.navigate(['building', 'wizard', buildingUT, 'document'], { queryParams: { type: type, documentType: documentType, ieUT: installEquipmentUT }, queryParamsHandling: 'merge' });
  }

  get searchFilter(): string {
    return this._searchFilter;
  }

  set searchFilter(searchFilter: string) {
    this._searchFilter = searchFilter;
    this.searchFilterSubject.next(searchFilter);
  }

}
