import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {ModuleWizardNavigationPaths} from './module-wizard-navigation-paths.enum';
import {ModuleWizardService} from './module-wizard.service';
import { map } from 'rxjs/operators';
import { Utils } from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";

@Component({
  selector: 'app-module-wizard',
  templateUrl: './module-wizard.component.html',
  styleUrls: ['./module-wizard.component.scss']
})
export class ModuleWizardComponent implements OnInit, OnDestroy {

  equipmentId: string;
  buildingId: string;
  navigateBack: string;

  currentPage: ModuleWizardNavigationPaths;

  modelReady: boolean;

  PAGE_DATA = ModuleWizardNavigationPaths.DATA;
  PAGE_TYPE = ModuleWizardNavigationPaths.TYPE;
  PAGE_TECHNICAL = ModuleWizardNavigationPaths.TECHNICAL;

  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private service: ModuleWizardService,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.paramMap.subscribe((params: ParamMap) => this.buildingId = params.get('id')));
    this.subscriptions.add(this.route.paramMap.subscribe((params: ParamMap) => this.equipmentId = params.get('id')));
    this.navigateBack = this.route.snapshot.data.navigateBack;
    this.currentPage = this.PAGE_TYPE;

    this.subscriptions.add(from(this.odataCoreService.Equipment.Query()
      .Expand(x => {
        x.Expand("Building"),
          x.Expand("EquipmentType"),
          x.Expand("Room", y =>
            y.Expand("Floor"))
      })
      .Filter(x =>
        x.EqualsField("Identity", Guid.parse(this.equipmentId)))
      .Exec().then(x => x.value))
      .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      .subscribe(ie => {
      this.service.startWizard(ie);
      this.modelReady = true;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  processPageChangeRequest(pageChangeEvent: ModuleWizardNavigationPaths): void {

    switch (pageChangeEvent) {

      case ModuleWizardNavigationPaths.START: {
        this.currentPage = this.PAGE_TYPE;
        break;
      }
      case ModuleWizardNavigationPaths.EXIT: {
        if (this.navigateBack && this.navigateBack === 'equipment' && this.equipmentId) {
          this.router.navigate(['/building', this.buildingId, {
            outlets: {
              left: ['building', 'view', {outlets: {tab: ['equipment']}}],
              right: ['equipment', this.equipmentId, 'view', {outlets: {tab: ['modules']}}]
            }
          }]);
        } else if (this.navigateBack && this.navigateBack === 'building' && this.buildingId) {
          this.router.navigate(['/building', this.buildingId, {outlets: {left: ['list'], right: ['building']}}]);
        } else {
          console.error('Could not find a navigation route for equipment wizard!');
        }
        break;
      }
      case ModuleWizardNavigationPaths.NEXT: {
        if (this.currentPage === this.PAGE_TYPE) {
          this.currentPage = this.PAGE_DATA;
        } else if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_TECHNICAL;
        }
        break;
      }
      case ModuleWizardNavigationPaths.PREVIOUS: {
        if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_TYPE;
        } else if (this.currentPage === this.PAGE_TECHNICAL) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case ModuleWizardNavigationPaths.TYPE: {
        if (this.currentPage === this.PAGE_TYPE || this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_TECHNICAL) {
          this.currentPage = this.PAGE_TYPE;
        }
        break;
      }
      case ModuleWizardNavigationPaths.DATA: {
        if (this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_TECHNICAL) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case ModuleWizardNavigationPaths.TECHNICAL: {
        if (this.currentPage === this.PAGE_TECHNICAL) {
          this.currentPage = this.PAGE_TECHNICAL;
        }
        break;
      }
    }
  }
}
