import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ContractWizardNavigationPaths } from '../contract-wizard-navigation-paths.enum';
import { ObjektBrief } from './../../../../core/odata/odata.coreapi';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { ContractWizardModel } from './../contract-wizard-model';
import { ContractWizardService } from './../contract-wizard.service';


@Component({
  selector: 'app-contract-wizard-general-data',
  templateUrl: './contract-wizard-general-data.component.html',
  styleUrls: ['./contract-wizard-general-data.component.scss']
})
export class ContractWizardGeneralDataComponent implements OnInit, OnDestroy {
  model: ContractWizardModel;

  mcCancellationUnits: string[] = Object.keys(ObjektBrief.MaintenanceContractCancellationUnits).filter(x => x !== ObjektBrief.MaintenanceContractCancellationUnits.Undefined);
  mcCancellationPeriods: string[] = Object.keys(ObjektBrief.MaintenanceContractCancellationPeriods).filter(x => x !== ObjektBrief.MaintenanceContractCancellationPeriods.Undefined);
  mcCancellationCalendars: string[] = Object.keys(ObjektBrief.MaintenanceContractCancellationCalendars).filter(x => x !== ObjektBrief.MaintenanceContractCancellationCalendars.Undefined);

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<ContractWizardNavigationPaths>();

  constructor(
    private service: ContractWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.model = this.service.getCurrentContract();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(validForm: boolean): void {
    const now = new Date();
    const fifteenYearsAgo = new Date(now.getFullYear()-15, now.getMonth(), now.getDate());

    if (!validForm) {
      if(this.model.contract.StartDate != null && fifteenYearsAgo.getTime() - this.model.contract.StartDate.getTime() > 0) {
        const modalRefUnvalidForm = this.modalService.open(ConfirmationModal);
        modalRefUnvalidForm.componentInstance.title = this.translateService.instant('ContractWizzard._modal_unvalidForm._title');
        modalRefUnvalidForm.componentInstance.message = this.translateService.instant('ContractWizzard._modal_unvalidForm._message');
        modalRefUnvalidForm.componentInstance.yesButton = this.translateService.instant('ContractWizzard._modal_unvalidForm._yes');
        modalRefUnvalidForm.componentInstance.hideCancelButton = true;
  
        modalRefUnvalidForm.result
          .then((val) => {
            if (val === ConfirmationModal.YES_VALUE) {
              modalRefUnvalidForm.close();
            }
          })
          .catch(() => {
            // do nothing, just stay on page
          }
        );
      }

      this.formSubmitted = true;
      return;
    }

    this.pageChangeRequest.emit(ContractWizardNavigationPaths.NEXT);
  }

  previous(): void {
    this.pageChangeRequest.emit(ContractWizardNavigationPaths.PREVIOUS);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('ContractWizzard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('ContractWizzard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('ContractWizzard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ContractWizzard._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentContract();
            this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
    }
  }

  compareId(val1: {Id: number}, val2: {Id: number}): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  getDateLimit(type = 'min') {
    const today = new Date();
    if (type === 'min') {
      return {year: today.getFullYear()-15, month: today.getMonth()+1, day: today.getDate()};
    } else {
      return {year: today.getFullYear(), month: today.getMonth()+1, day: today.getDate()};
    }
  }
}
