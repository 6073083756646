import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { AuthService } from './../../../../core/auth/auth.service';
import { Subscription, from } from 'rxjs';

import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { BuildingWizardModel } from './../building-wizard-model';
import { BuildingWizardNavigationPaths } from './../building-wizard-navigation-paths';
import { BuildingWizardService } from './../building-wizard.service';
import { BuildingEditUsagesInputData } from './../../../../core/components/building/building-edit-usages-input-data';

import { Utils } from '../../../../core/tools/utils';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-building-wizard-usage',
  templateUrl: './building-wizard-usage.component.html',
  styleUrls: ['./building-wizard-usage.component.scss']
})
export class BuildingWizardUsageComponent implements OnInit, OnDestroy {

  model: BuildingWizardModel;
  inputData: BuildingEditUsagesInputData = new BuildingEditUsagesInputData();
  buildingUsageTypes: any[];
  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<BuildingWizardNavigationPaths>();

  @Input()
  editMode: boolean;

  constructor(
    protected authService: AuthService,
    private modalService: NgbModal,
    private service: BuildingWizardService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.model = this.service.getCurrentBuilding();
    from(this.odataCoreService.UsageType.Query()
      .Filter(x =>
        x.Any("Regions", "r: r/Id eq " + this.model.address.RegionInfo.Id + ""))
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
      .subscribe((types) => {
      this.buildingUsageTypes = types;
      this.model.usageTypes = [];
    });
  }

  controlUsageTypes(usage) {
    const exists = this.model.usageTypes.filter(type => type.Id === usage.Id);

    if (exists.length > 0) {
      this.model.usageTypes = this.model.usageTypes.filter(u => u.Id !== usage.Id); 
    } else {
      this.model.usageTypes.push(usage);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onMarkerDragEnd(data: any): void {
    this.model.latitude = data.coords.lat;
    this.model.longitude = data.coords.lng;
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('BuildingWiz._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('BuildingWiz._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('BuildingWiz._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('BuildingWiz._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentBuilding();
            this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
    }
  }

  previous(): void {
    this.pageChangeRequest.emit(BuildingWizardNavigationPaths.PREVIOUS);
  }

  async next(): Promise<void> {
    if (this.editMode) {
      // save building and then save install equipments if any
      try {
      await this.service.editBuilding()
          this.pageChangeRequest.emit(BuildingWizardNavigationPaths.EXIT);
      }
      catch (err) {
          this.toasterService.pop('error', 'Fehler!', 'Ein unerwarteter Fehler ist aufgetreten und das Gebäude konnte nicht geändert werden!');
        }
    } else {
      this.pageChangeRequest.emit(BuildingWizardNavigationPaths.NEXT);
    }
  }
}
