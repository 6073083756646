import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, from} from 'rxjs';
import {PropertyCore} from '../../../../core/odata/odata.coreapi';
import {IncidentWizardNavigationPaths} from '../incident-wizard-navigation-paths.enum';
import {ConfirmationModal} from '../../../../core/popup/confirmation.modal';
import {InstallEquipmentService} from '../../../installEquipment.service';
import {InstallEquipmentKpiFilter} from '../../../kpiFilter.enum';
import {IncidentWizardModel} from '../incident-wizard-model';
import {IncidentWizardService} from '../incident-wizard.service';
import { ODataCoreService } from "../../../../core/odata-services/odata.coreapi.service";
import { Utils } from '../../../../core/tools/utils';
import { map } from 'rxjs/operators';
import { ODataPath } from '../../../../core/odata/odataclient';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-incident-wizard-equipment',
  templateUrl: './incident-wizard-equipment.component.html',
  styleUrls: ['./incident-wizard-equipment.component.scss']
})
export class IncidentWizardEquipmentComponent implements OnInit, OnDestroy {

  model: IncidentWizardModel;

  installEquipments: PropertyCore.Equipment[];

  searchFilter: string = '';
  equipments: PropertyCore.EquipmentType[];
  equipmentFilter: any = '';

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<IncidentWizardNavigationPaths>();

  constructor(
    private service: IncidentWizardService,
    private installEquipmentService: InstallEquipmentService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentIncident();
    this.subscriptions.add(from(this.odataCoreService.Equipment.Query()
      .OrderBy("Name")
      .Filter(x =>
        x.Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.buildingUT)))
      .Expand(x => {
        x.Expand("Building"),
          x.Expand("EquipmentType"),
          x.Expand("Room", y => {
            y.Expand("Floor")
          })
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => this.installEquipments = res));
    this.subscriptions.add(from(this.odataCoreService.EquipmentType.Query().Exec().then(x => x.value)).subscribe(res => this.equipments = res));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(): void {
    this.pageChangeRequest.emit(IncidentWizardNavigationPaths.NEXT);
  }

  previous(): void {
    this.pageChangeRequest.emit(IncidentWizardNavigationPaths.PREVIOUS);
  }

  selectEquipment(equipment: PropertyCore.Equipment): void {
    if (!this.model.equipment || this.model.equipment.Identity !== equipment.Identity) {
      this.model.equipment = equipment;
    } else {
      // deselect
      this.model.equipment = null;
    }
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('IncidentWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('IncidentWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('IncidentWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('IncidentWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentIncident();
            this.pageChangeRequest.emit(IncidentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
            // do nothing, just stay on page
          }
        );
    } else {
      this.pageChangeRequest.emit(IncidentWizardNavigationPaths.EXIT);
    }
  }

}
