import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from './../../core/auth/auth.service';

@Component({
  selector: 'app-incident-overview',
  templateUrl: './incident-overview.component.html',
  styleUrls: ['./incident-overview.component.scss']
})
export class IncidentOverviewComponent implements OnInit, OnDestroy {

  buildingId: string;
  installEquipmentId: string;

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.route.firstChild) {
      // redirect to route with tabs outlet set to default value
      this.router.navigate([{ outlets: { 'incidentstyle': ['list', { tab: ['todoFuture']}] } }], { relativeTo: this.route, queryParamsHandling: 'merge' });
    }

    this.subscriptions.add(this.route.root.firstChild.paramMap.subscribe(params => this.buildingId = params.get('id')));
    this.subscriptions.add(this.route.parent.parent.paramMap.subscribe(params => this.installEquipmentId = params.get('id')));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  incidentWizard(): void {
    this.router.navigate(['building', 'wizard', this.buildingId, 'incident', this.installEquipmentId ? this.installEquipmentId : '']);
  }

}
