export enum DefectWizardNavigationPaths {

  DATA = 'data',
  ADD_SERVICE_CONTACT = 'add-service-contact',
  EQUIPMENT = 'equipment',

  NEXT = 'next',
  PREVIOUS = 'previous',
  START = 'start',
  EXIT = 'exit'
}
