import { Injectable } from '@angular/core';
import { PropertyCore } from '../../../core/odata/odata.coreapi';
import { Incident } from './../../../core/model/incident';
import { IncidentWizardModel } from './../incident/incident-wizard-model';


@Injectable()
export class DefectWizardService {

  model: IncidentWizardModel = new IncidentWizardModel;

  parentIncident: Incident;

  constructor() { }

  getCurrentDefect(): IncidentWizardModel {
    return this.model;
  }

  getParentIncident(): Incident {
    return this.parentIncident;
  }

  startWizard(): void {
    this.resetCurrentDefect();
  }

  fillWizard(parentIncident: Incident): void {
    this.parentIncident = parentIncident;
    this.model.parentId = this.parentIncident.Identity;
    this.model.buildingUT = this.parentIncident.Building.Identity;
    this.model.buildingName = this.parentIncident.Building.Name;
    if (this.parentIncident.Equipment) {
      this.model.equipment = <PropertyCore.Equipment>{ Id: undefined, Identity: this.parentIncident.Equipment.Identity, Name: this.parentIncident.Equipment.Name, CustomId: this.parentIncident.Equipment.CustomId, Building: { Identity: this.parentIncident.Building.Identity, Name: this.parentIncident.Building.Name } };
    }
  }

  createDefect(): void {
    // service call
  }

  resetCurrentDefect(): void {
    this.model.reset();
    this.model.isDefectHandler = true;

    this.parentIncident && this.fillWizard(this.parentIncident);
  }

}
