import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { GlobalService } from '../shared/global.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  handleError(error) {
    const toasterService = this.injector.get(ToasterService);
    const message = error.error ? (error.error.Message ? error.error.Message : (error.message ? error.message : error.error.toString())) : (error.message ? error.message : error.toString());

    const translateService = this.injector.get(TranslateService);
    toasterService.pop('error', 'Fehler', translateService.instant('Common._error_occured') + ': ' + message);

    const globalService = this.injector.get(GlobalService);
    globalService.logException(message);

    super.handleError(error);
  }

}
