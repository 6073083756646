import { Common, PropertyCore } from '../../../core/odata/odata.coreapi';

export class BuildingWizardModel {

  Identity: string;
  id: number;
  name: string;
  constructionYear: number;
  squareMeters: number;

  address?: Common.Address;
  location?: Common.Location;

  latitude: number;
  longitude: number;

  // edit mode
  usageTypes?: PropertyCore.UsageType[];

}
