/**************************************************************************
 Created by odatatools: https://marketplace.visualstudio.com/items?itemName=apazureck.odatatools
 Use Command 'odata: xyUpdate to refresh data while this file is active in the editor.
 Creation Time: Wed Oct 24 2018 09:54:30 GMT+0200 (Central Europe Daylight Time)
 DO NOT DELETE THIS IN ORDER TO UPDATE YOUR SERVICE
 #ODATATOOLSOPTIONS
 {
	"modularity": "Ambient",
	"requestOptions": {},
	"source": "http://api.obb.tuev-daily.de/OData/$metadata",
	"useTemplate": "proxy.ot"
}
 #ODATATOOLSOPTIONSEND
 **************************************************************************/

// Base classes ##########################################################
// Leave this in order to use the base classes
namespace odatatools {
  enum Method {
    GET,
    POST,
    PUT,
    PATCH,
    DELETE
  }

  export class ProxyBase {
    constructor(
      public readonly Address: string,
      public readonly Name?: string,
      additonalHeaders?: odatajs.Header
    ) {
      this.Name = this.Name || "ProxyService";

      this.Headers = {
        "Content-Type": "application/json",
        Accept: "application/json"
      };

      for (var attrname in additonalHeaders) {
        this.Headers[attrname] = additonalHeaders[attrname];
      }
    }

    /**
     * All headers appended to each request.
     *
     * @type {odatajs.Header}
     * @memberOf EntitySet
     */
    readonly Headers: odatajs.Header;
  }

  export abstract class ODataQueryOptionBase {
    private query: string[] = [];

    protected resolveODataOptions(): string {
      if (this.query.length > 0) return "?" + this.query.join("&");
      else return "";
    }

    protected addToQuery(element: string) {
      this.query.push(element);
    }

    protected emptyQuery(): void {
      this.query = [];
    }
  }

  export abstract class ODataQueryOptionsGetSingle<T> extends ODataQueryOptionBase {
  }

  export abstract class ODataQueryFilterOptions<T> extends ODataQueryOptionsGetSingle<T> {
    abstract Get(): Promise<T[]>;
    abstract Get(id: string): Promise<T>;

    abstract Count(): Promise<number>;

    /**
     * Selects properties on the elements. Works on Get() and Get(id).
     *
     * @param {keyof T | (keyof T)[]} properties Use comma separated names without spaces
     * @returns {ODataQueryOptions<T>}
     *
     * @memberof ODataQueryOptions
     */
    Select(properties: keyof T | (keyof T)[]): ODataQueryFilterOptions<T> {
      if (typeof properties === "string")
        this.addToQuery("$select=" + properties);
      else this.addToQuery("$select=" + (<(keyof T)[]>properties).join(","));
      return this;
    }

    /**
     * Orders elements by the given property. Works only on Get()
     *
     * @param {string} property Property on dataset to order by
     * @param {Order} [order=asc] Order "asc" for ascending and "desc" for descending.
     * @returns {ODataQueryFilterOptions<T>}
     *
     * @memberof ODataQueryFilterOptions
     */
    OrderBy(property: keyof T, order?: Order): ODataQueryFilterOptions<T> {
      this.addToQuery("$orderby=" + property + order ? " " + order : "");
      return this;
    }

    /**
     * Top selects the given number of element. Works only on Get()
     *
     * @param {number} select number of elements to select
     * @returns {ODataQueryFilterOptions<T>}
     *
     * @memberof ODataQueryFilterOptions
     */
    Top(select: number): ODataQueryFilterOptions<T> {
      this.addToQuery("$top=" + select);
      return this;
    }

    /**
     * Skips the given number of elements and starts with element n + 1
     *
     * @param {number} select Number of elements to skip
     * @returns {ODataQueryFilterOptions<T>}
     *
     * @memberof ODataQueryFilterOptions
     */
    Skip(select: number): ODataQueryFilterOptions<T> {
      this.addToQuery("$skip=" + select);
      return this;
    }

    /**
     * Filters by given criteria. See odata $filter convention for information on syntax.
     *
     * @param {string} filter Filter syntax specified by odata V4 standard.
     * @returns {ODataQueryFilterOptions<T>}
     *
     * @memberof ODataQueryFilterOptions
     */
    Filter(filter: string): ODataQueryFilterOptions<T> {
      this.addToQuery("$filter=" + filter);
      return this;
    }

    /**
     * Expands given property or array of properties.
     *
     * @param {(keyof T | (keyof T)[])} properties Properties to expand on.
     * @returns {ODataQueryFilterOptions<T>}
     *
     * @memberof ODataQueryFilterOptions
     */
    Expand(properties: keyof T | (keyof T)[]): ODataQueryFilterOptions<T> {
      if (typeof properties === "string")
        this.addToQuery("$expand=" + properties);
      else this.addToQuery("$expand=" + (<(keyof T)[]>properties).join(","));
      return this;
    }

    /**
     * Searches for a value in the entity set as specified in OData protocol
     *
     * @param {string} searchExpression Search specified in OData protocol
     * @returns {ODataQueryFilterOptions<T>}
     *
     * @memberof ODataQueryFilterOptions
     */
    Search(searchExpression: string): ODataQueryFilterOptions<T> {
      this.addToQuery("$search=" + searchExpression);
      return this;
    }

    Custom(customData: string): ODataQueryFilterOptions<T> {
      this.addToQuery(customData);
      return this;
    }
  }

  export type Order = "asc" | "desc";

  export type Partial<T> = { [P in keyof T]?: T[P] };

  /**
   *
   * A generic entity set which represents the content of the entity container.
   *
   * @export
   * @class EntitySet
   * @template T
   */
  export class EntitySet<T> extends ODataQueryFilterOptions<T> {
    /**
     * Creates an instance of EntitySet.
     *
     * @param {string} name of the EntitySet (Will determine the address of the entityset, too -> address + "/" + name)
     * @param {string} address of the service
     * @param {string} key of the EntitySet
     * @param {odatajs.Header} [headers] additional headers: Per default there are "Content-Type" and "Accept".
     *
     * @memberOf EntitySet
     */
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super();
      this.Name = name;
      this.Address = address.replace(/\/$/, "") + "/" + name;
      this.Key = key;
      this.Headers = {
        "Content-Type": "application/json",
        Accept: "application/json"
      };

      for (var attrname in additionalHeaders) {
        this.Headers[attrname] = additionalHeaders[attrname];
      }
    }

    /**
     * Name of the Entity Set (which is appended to the URI)
     * @memberOf EntitySet
     */
    readonly Name: string;
    /**
     * Address of the OData Service
     * @memberOf EntitySet
     */
    readonly Address: string;

    /**
     * All headers appended to each request.
     *
     * @type {odatajs.Header}
     * @memberOf EntitySet
     */
    readonly Headers: odatajs.Header;

    /**
     * Key of the entity
     * @memberOf EntitySet
     */
    readonly Key: string;

    /**
     * Gets all entries of an entity set. Use method chaining (call.Skip(10).Top(10).Get() before you call this method to create a query.
     *
     * @returns {Promise<T[]>}
     *
     * @memberof EntitySet
     */
    Get(): Promise<T[]>;
    /**
     * Gets one entry of the entity set by id.
     *
     * @param {string} id
     * @returns {Promise<T>}
     *
     * @memberof EntitySet
     */
    Get(id: string): Promise<T>;
    Get(id?: string): Promise<T | T[]> {
      return new Promise((resolve, reject) => {
        let requri: string;
        if (id) {
          requri = this.Address + "(" + id + ")";
        } else {
          requri = this.Address;
        }
        requri += this.resolveODataOptions();
        this.emptyQuery();
        let request: odatajs.Request = {
          headers: this.Headers,
          method: Method[Method.GET],
          requestUri: requri
        };

        odatajs.oData.request(
          request,
          (data, response) => {
            if (id) {
              resolve(data);
            } else {
              resolve(data.value);
            }
          },
          error => {
            console.error(
              error.name +
              " " +
              error.message +
              " | " +
              (error.response | error.response.statusText) +
              ":\n" +
              (error.response | error.response.body)
            );
            reject(error);
          }
        );
      });
    }

    /**
     * Replaces an existing value in the entity collection.
     *
     * @param {T} value to replace
     * @returns {Promise<T>} for async Operation. Use `await` keyword to get value or `.then` callback.
     *
     * @memberOf EntitySet
     */
    Put(value: T): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        let request: odatajs.Request = {
          headers: this.Headers,
          method: Method[Method.PUT],
          requestUri: this.Address + "(" + value[this.Key] + ")",
          data: value
        };
        odatajs.oData.request(
          request,
          (data, response) => {
            resolve();
          },
          error => {
            console.error(
              error.name +
              " " +
              error.message +
              " | " +
              (error.response | error.response.statusText) +
              ":\n" +
              (error.response | error.response.body)
            );
            reject(error);
          }
        );
      });
    }

    /**
     * Adds a new entry to an EntitySet
     *
     * @param {T} value to ad to the EntitySet
     * @returns {Promise<T>} for async Operation. Use `await` keyword to get value or `.then` callback.
     *
     * @memberOf EntitySet
     */
    Post(value: T): Promise<T> {
      return new Promise<T>((resolve, reject) => {
        let request: odatajs.Request = {
          headers: this.Headers,
          method: Method[Method.POST],
          requestUri: this.Address,
          data: value
        };
        odatajs.oData.request(
          request,
          (data, response) => {
            resolve(data as T);
          },
          error => {
            console.error(
              error.name +
              " " +
              error.message +
              " | " +
              (error.response | error.response.statusText) +
              ":\n" +
              (error.response | error.response.body)
            );
            reject(error);
          }
        );
      });
    }

    Patch(delta: Partial<T> | T): Promise<void>;
    Patch(oldvalue: T, newValue: T): Promise<void>;
    Patch(oldvalordelta: T | Partial<T>, newval?: T): Promise<void> {
      if (newval) oldvalordelta = this.getDelta(oldvalordelta as T, newval);

      return new Promise<void>((resolve, reject) => {
        let request: odatajs.Request = {
          headers: this.Headers,
          method: Method[Method.PATCH],
          requestUri: this.Address,
          data: oldvalordelta
        };
        odatajs.oData.request(
          request,
          (data, response) => {
            resolve();
          },
          error => {
            console.error(
              error.name +
              " " +
              error.message +
              " | " +
              (error.response | error.response.statusText) +
              ":\n" +
              (error.response | error.response.body)
            );
            reject(error);
          }
        );
      });
    }

    private getDelta(oldval: T, newVal: T): Partial<T> {
      let ret: any = {};
      for (let prop in newVal)
        if (oldval[prop] != newVal[prop]) ret[prop] = newVal[prop];
      return ret;
    }

    /**
     * Deletes a value from the entity set.
     *
     * @param {T} value to delete
     * @returns {Promise<T>} for async Operation. Use `await` keyword to get value or `.then` callback.
     *
     * @memberOf EntitySet
     */
    Delete(value: T): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        let request: odatajs.Request = {
          headers: this.Headers,
          method: Method[Method.DELETE],
          requestUri: this.Address + "(" + value[this.Key] + ")"
        };
        odatajs.oData.request(
          request,
          (data, response) => {
            resolve();
          },
          error => {
            console.error(
              error.name +
              " " +
              error.message +
              " | " +
              (error.response | error.response.statusText) +
              ":\n" +
              (error.response | error.response.body)
            );
            reject(error);
          }
        );
      });
    }

    Count(): Promise<number> {
      return new Promise<number>((resolve, reject) => {
        const requri = this.Address + "/$count/" + this.resolveODataOptions();
        let request: odatajs.Request = {
          headers: this.Headers,
          method: Method[Method.GET],
          requestUri: requri
        };

        odatajs.oData.request(
          request,
          (data, response) => {
            resolve(data);
          },
          error => {
            console.error(
              error.name +
              " " +
              error.message +
              " | " +
              (error.response | error.response.statusText) +
              ":\n" +
              (error.response | error.response.body)
            );
            reject(error);
          }
        );
      });
    }
  }

  class EntityContainer {
    constructor(name: string, uri: string) {
      this.Name = name;
      this.Uri = uri;
    }

    readonly Name: string;
    readonly Uri: string;
  }
}

declare namespace odatajs {
  class oData {
    static request(
      request: Request,
      success?: (data: any, response: any) => void,
      error?: (error: any) => void,
      handler?,
      httpClient?,
      metadata?
    );
  }

  interface Request {
    requestUri: string;
    method: string;
    headers: Header | Header[];
    data?: any;
  }

  interface Header {
    [name: string]: string;
  }
}

type JSDate = Date;

declare namespace Edm {
  export type Duration = string;
  export type Binary = string;
  export type Boolean = boolean;
  export type Byte = number;
  export type Date = JSDate;
  export type DateTimeOffset = JSDate;
  export type Decimal = number;
  export type Double = number;
  export type Guid = string;
  export type Int16 = number;
  export type Int32 = number;
  export type Int64 = number;
  export type SByte = number;
  export type Single = number;
  export type String = string;
  export type TimeOfDay = string;
  export type Stream = string;
  export type GeographyPoint = any;
}

console.log("Loaded odataproxybase");

// ###################################### Implementation ################

export namespace Common {
  // Entity types

  // Complex types
  export interface Address {
    Street: Edm.String;
    No: Edm.String;
    City: Edm.String;
    PostCode: Edm.String;
    Country: Edm.String;
    CountryInfo: Library.CountryInfo;
    Region: Edm.String;
    RegionInfo: Library.RegionInfo;
  }

  export interface AddressGeoCoded extends Common.Address {
    Location: Common.Location;
  }

  export interface InvoiceAddress extends Common.Address {
  }

  export interface Location {
    Longitude: Edm.Double;
    Latitude: Edm.Double;
  }

  // Enum Types

  // Entity container
  //EntitySets
}

export namespace Library {
  // Entity types
  export interface Country {
    Code: Edm.String;
    Id: Edm.Int32;
    IsoNo: Edm.Int16;
    Name: Edm.String;
    Created: Edm.DateTimeOffset;
    Buildings?: PropertyCore.Building[];
    Regions?: Library.Region[];
    AccountDataProcessingAgreements?: Operations.AccountDataProcessingAgreement[];
  }

  export interface Region {
    Code: Edm.String;
    Id: Edm.Int32;
    Name: Edm.String;
    Created: Edm.DateTimeOffset;
    Country?: Library.Country;
    Buildings?: PropertyCore.Building[];
  }

  // Complex types
  export interface CountryInfo {
    Id: Edm.Int32;
    Name: Edm.String;
  }

  export interface RegionInfo {
    Id: Edm.Int32;
    Name: Edm.String;
  }

  // Enum Types

  // Entity container
  //EntitySets
}

export namespace Operations {
  // Entity types
  export interface Account {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Name: Edm.String;
    MaxBuildingCount: Edm.Int32;
    IsSubscriptionProcessed: Edm.Boolean;
    SapNo: Edm.String;
    StartDate: Edm.DateTimeOffset;
    EndDate: Edm.DateTimeOffset;
    AccountType: Operations.AccountTypes;
    Created: Edm.DateTimeOffset;
    AccountProfile?: Operations.AccountProfile;
    User?: Operations.User;
    Statistics?: Api.Odata.AccountStatistics;
    AccountDataProcessingAgreements?: Operations.AccountDataProcessingAgreement[];
    ActionLogs?: PropertyCore.ActionLog[];
    Buildings?: PropertyCore.Building[];
    MaintenanceContracts?: PropertyCore.MaintenanceContract[];
    ServiceProviders?: PropertyCore.ServiceProvider[];
    SubscriptionPlan?: PropertyCore.SubscriptionPlan;
    Roles?: Operations.AccountRole[];
    Rights?: Operations.AccountRights[];
  }

  export interface User {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Title: Edm.String;
    Position: Edm.String;
    FirstName: Edm.String;
    LastName: Edm.String;
    PhoneBusiness: Edm.String;
    PhoneMobile: Edm.String;
    Email: Edm.String;
    LoginSource: Edm.Int32;
    IsTuevEmployee: Edm.Boolean;
    IsMale: Edm.Boolean;
    IsReportSubscribed: Edm.Boolean;
    Created: Edm.DateTimeOffset;
    Accounts?: Operations.Account[];
    ServiceProviders?: PropertyCore.ServiceProvider[];
    Documents?: PropertyCore.Document[];
    Images?: PropertyCore.Image[];
    OperatorTasks?: PropertyCore.OperatorTask[];
    QRCodes?: PropertyCore.QRCode[];
    QRCodeAssigns?: PropertyCore.QRCodeAssign[];
    Rooms?: PropertyCore.Room[];
    MaintenanceContracts?: PropertyCore.MaintenanceContract[];
    Buildings?: PropertyCore.Building[];
    Responses?: PropertyCore.EventResponse[];
  }

  export interface AccountRole {
    Id: Edm.Int32;
    Role: Api.Odata.AccountRoles;
    User?: Operations.User;
  }

  export interface AccountProfile {
    Identity: Edm.Guid;
    Contact: Edm.String;
    ContactPhone: Edm.String;
    ContactMail: Edm.String;
    Name: Edm.String;
    PartnerNo: Edm.String;
    VatCode: Edm.String;
    AddressAddition: Edm.String;
    Address: Common.Address;
    InvoiceName: Edm.String;
    InvoicePartnerNo: Edm.String;
    InvoiceCode: Edm.String;
    InvoiceAddressAddition: Edm.String;
    InvoiceAddress: Common.InvoiceAddress;
  }

  export interface AccountDataProcessingAgreement {
    Identity: Edm.Guid;
    TimeStamp: Edm.DateTimeOffset;
    LoginName: Edm.String;
    Name: Edm.String;
    Street: Edm.String;
    HouseNo: Edm.String;
    AddressAddition: Edm.String;
    ZipCode: Edm.String;
    City: Edm.String;
    Country?: Library.Country;
  }

  export interface AccountRights {
    Identity: Edm.Guid;
    CanRead: Edm.Boolean;
    CanEdit: Edm.Boolean;
    CanDelete: Edm.Boolean;
  }

  export interface AccountSubscriptionHistory {
    Id: Edm.Int32;
    RefDate: Edm.DateTimeOffset;
  }

  export interface BuildingRole {
    Id: Edm.Int32;
    Role: Api.Odata.BuildingRoles;
    User?: Operations.User;
  }

  export interface Invitation {
    Identity: Edm.Guid;
    Email: Edm.String;
    AccessCode: Edm.Guid;
    ServiceProvider?: PropertyCore.ServiceProvider;
  }

  // Complex types

  // Enum Types
  // Enum Values: Unknown = 0, FacilityManagement = 1, ServiceProvider = 2
  export enum AccountTypes {
    Unknown = "Unknown",
    FacilityManagement = "FacilityManagement",
    ServiceProvider = "ServiceProvider"
  }

  // Entity container
  //EntitySets
}

export namespace PropertyCore {
  // Entity types
  export interface Building {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Name: Edm.String;
    Address: Common.Address;
    Location: Common.Location;
    Size: Edm.Int32;
    YearOfConstruction: Edm.Int32;
    Created: Edm.DateTimeOffset;
    Account?: Operations.Account;
    User?: Operations.User;
    Equipments?: PropertyCore.Equipment[];
    Images?: PropertyCore.Image[];
    Events?: PropertyCore.Event[];
    Rooms?: PropertyCore.Room[];
    UsageTypes?: PropertyCore.UsageType[];
    Floors?: PropertyCore.Floor[];
    Statistics?: PropertyCore.BuildingStatistics;
    Roles?: Operations.BuildingRole[];
    Documents?: PropertyCore.Document[];
  }

  export interface BuildingStatistics {
    Id: Edm.Int32;
    EquipmentCount: Edm.Int32;
    ModuleCount: Edm.Int32;
    UsageTypeCount: Edm.Int32;
    MissingDocumentsCount: Edm.Int32;
    RoomCount: Edm.Int32;
    FloorCount: Edm.Int32;
  }

  export interface Equipment {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Name: Edm.String;
    Comment: Edm.String;
    InstallNo: Edm.Int32;
    CustomId: Edm.String;
    TuevNo: Edm.String;
    IsRenterResponsibility: Edm.Boolean;
    IsNoMaintenanceDesired: Edm.Boolean;
    HasInspectionDutiesActivated: Edm.Boolean;
    EquipmentType?: PropertyCore.EquipmentType;
    Building?: PropertyCore.Building;
    Section?: PropertyCore.Section;
    Room?: PropertyCore.Room;
    Events?: PropertyCore.Event[];
    Modules?: PropertyCore.Module[];
    Images?: PropertyCore.Image[];
    InspectionDuties?: PropertyCore.InspectionDuty[];
    QRCodeAssigns?: PropertyCore.QRCodeAssign[];
    Statistics?: Api.Odata.EquipmentStatistics;
    Documents?: PropertyCore.Document[];
  }

  export interface EquipmentType {
    Code: Edm.String;
    Id: Edm.Int32;
    Name: Edm.String;
    SubNo: Edm.Int32;
    Created: Edm.DateTimeOffset;
    CostGroup?: PropertyCore.CostGroup;
    Equipments?: PropertyCore.Equipment[];
    ModuleTypes?: PropertyCore.ModuleType[];
  }

  export interface Module {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Quantity: Edm.Int32;
    CustomId: Edm.String;
    Name: Edm.String;
    Comment: Edm.String;
    Created: Edm.DateTimeOffset;
    Equipment?: PropertyCore.Equipment;
    InstallNo?: Edm.Int32;
    ModuleType?: PropertyCore.ModuleType;
    Room?: PropertyCore.Room;
    MaintenanceContract?: PropertyCore.MaintenanceContract;
    Section?: PropertyCore.Section;
    Images?: PropertyCore.Image[];
    InspectionDuties?: PropertyCore.InspectionDuty[];
    Statistics?: Api.Odata.ModuleStatistics;
  }

  export interface ModuleType {
    Code: Edm.String;
    Id: Edm.Int32;
    Name: Edm.String;
    Created: Edm.DateTimeOffset;
    EquipmentType?: PropertyCore.EquipmentType;
    Modules?: PropertyCore.Module[];
  }

  export interface Schedule {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Cycle: Edm.Int32;
    CycleUnit: Api.Odata.CycleUnits;
    CycleStart: Edm.DateTimeOffset;
    CycleNext: Edm.DateTimeOffset;
    CycleStop: Edm.DateTimeOffset;
    InspectionDuty?: PropertyCore.InspectionDuty;
    MaintenanceContract?: PropertyCore.MaintenanceContract;
    Events?: PropertyCore.Event[];
  }

  export interface Event {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Type: PropertyCore.EventTypes;
    Guidelines: Edm.String;
    Qualification: Edm.String;
    Name: Edm.String;
    DueDate: Edm.DateTimeOffset;
    RefDate: Edm.DateTimeOffset;
    ClosedDate: Edm.DateTimeOffset;
    IsSkipped: Edm.Boolean;
    Created: Edm.DateTimeOffset;
    IsDefectHandler: Edm.Boolean;
    Response?: PropertyCore.EventResponse;
    Parent?: PropertyCore.Event;
    Schedule?: PropertyCore.Schedule;
    ServiceProvider?: PropertyCore.ServiceProvider;
    Building?: PropertyCore.Building;
    Documents?: PropertyCore.Document[];
    Equipment?: PropertyCore.Equipment;
    Module?: PropertyCore.Module;
    KpiMarker?: PropertyCore.EventKpiMarker;
  }

  export interface EventKpiMarker {
    IsActive: Edm.Boolean;
    IsInFocus: Edm.Boolean;
    IsPlanned: Edm.Boolean;
    IsDone: Edm.Boolean;
    IsOverdue: Edm.Boolean;
    IsDefective: Edm.Boolean;
    IsMissingDocuments: Edm.Boolean;
  }

  export interface MaintenanceContract {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Title: Edm.String;
    No: Edm.String;
    CostServiceHourNetAmount: Edm.Int32;
    CostApproachNetAmount: Edm.Int32;
    AmountNet: Edm.Decimal;
    ApproachFeeType: Api.Odata.MaintenanceContractApproachFeeTypes;
    CancellationDigit: Edm.Int32;
    CancellationPeriod: Api.Odata.MaintenanceContractCancellationPeriods;
    CancellationUnit: Api.Odata.MaintenanceContractCancellationUnits;
    CancellationCalendar: Api.Odata.MaintenanceContractCancellationCalendars;
    StartDate: Edm.DateTimeOffset;
    EndDate: Edm.DateTimeOffset;
    IsGeneralAgreement: Edm.Boolean;
    Created: Edm.DateTimeOffset;
    User?: Operations.User;
    Account?: Operations.Account;
    ServiceProvider?: PropertyCore.ServiceProvider;
    Modules?: PropertyCore.Module[];
    MaintenanceContractMappings?: PropertyCore.MaintenanceContractMapping[];
  }

  export interface MaintenanceContractMapping {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    IsDisabled: Edm.Boolean;
    Created: Edm.DateTimeOffset;
    Equipment?: PropertyCore.Equipment;
    Schedule?: PropertyCore.Schedule;
    MaintenanceContract?: PropertyCore.MaintenanceContract;
  }

  export interface Room {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Name: Edm.String;
    FloorRef: System.Uri;
    BuildingRef: System.Uri;
    Created: Edm.DateTimeOffset;
    Statistics?: Api.Odata.RoomStatistics;
    User?: Operations.User;
    Floor?: PropertyCore.Floor;
    Building?: PropertyCore.Building;
    Equipments?: PropertyCore.Equipment[];
    Modules?: PropertyCore.Module[];
  }

  export interface Floor {
    Id: Edm.Int32;
    Name: Edm.String;
    Order: Edm.Int32;
    Created: Edm.DateTimeOffset;
    Rooms?: PropertyCore.Room[];
    Buildings?: PropertyCore.Building[];
  }

  export interface Section {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Name: Edm.String;
    Created: Edm.DateTimeOffset;
    Equipments?: PropertyCore.Equipment[];
    Modules?: PropertyCore.Module[];
  }

  export interface UsageType {
    Id: Edm.Int32;
    Name: Edm.String;
    Created: Edm.DateTimeOffset;
    Regions?: Library.Region[];
  }

  export interface CostGroup {
    Id: Edm.Int32;
    Code: Edm.String;
    Name: Edm.String;
    No: Edm.Int32;
    IsDisabled: Edm.Boolean;
    Created: Edm.DateTimeOffset;
    Branch?: PropertyCore.Branch;
    EquipmentTypes?: PropertyCore.EquipmentType[];
  }

  export interface ActionLog {
    Id: Edm.Int32;
    IsAnynomous: Edm.Boolean;
    Operation: PropertyCore.ActionLogOperations;
    Element: PropertyCore.ActionLogElements;
    Created: Edm.DateTimeOffset;
    Account?: Operations.Account;
    User?: Operations.User;
    Building?: PropertyCore.Building;
    Equipment?: PropertyCore.Equipment;
    Module?: PropertyCore.Module;
    Document?: PropertyCore.Document;
    MaintenanceContract?: PropertyCore.MaintenanceContract;
    ServiceProvider?: PropertyCore.ServiceProvider;
    Event?: PropertyCore.Event;
  }

  export interface ServiceProvider {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Code: Edm.String;
    Name: Edm.String;
    ContactPerson: Edm.String;
    Department: Edm.String;
    ContactPhone1: Edm.String;
    ContactPhone2: Edm.String;
    ContactEmailAdd: Edm.String;
    ContractContactPerson: Edm.String;
    ContractContactEmailAdd: Edm.String;
    ContractContactPhone: Edm.String;
    IsInspection: Edm.Boolean;
    IsMaintenance: Edm.Boolean;
    Type: Edm.Int32;
    Address: Common.Address;
    Created: Edm.DateTimeOffset;
    User?: Operations.User;
    Account?: Operations.Account;
    Invitation?: Operations.Invitation;
    Statistics?: Api.Odata.ServiceProviderStatistics;
    Events?: PropertyCore.Event[];
    MaintenanceContracts?: PropertyCore.MaintenanceContract[];
    ServiceAccount?: Operations.Account;
  }

  export interface Document {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Description: Edm.String;
    Name: Edm.String;
    Size: Edm.Int32;
    FileType: Edm.String;
    IsDuplicated: Edm.Boolean;
    IsDeleted: Edm.Boolean;
    IsVirtual: Edm.Boolean;
    Created: Edm.DateTimeOffset;
    User?: Operations.User;
    DocumentType?: PropertyCore.DocumentType;
    Buildings?: PropertyCore.Building[];
    Equipments?: PropertyCore.Equipment[];
    Events?: PropertyCore.Event[];
  }

  export interface DocumentType {
    Id: Edm.Int32;
    Name: Edm.String;
    Html: Edm.String;
    Mark: Edm.String;
    IsRequired: Edm.Boolean;
    Created: Edm.DateTimeOffset;
    Documents?: PropertyCore.Document[];
    OperatorTasks?: PropertyCore.OperatorTask[];
  }

  export interface DocumentTypeGroup {
    Id: Edm.Int32;
    Name: Edm.String;
    Created: Edm.DateTimeOffset;
    DocumentTypes?: PropertyCore.DocumentType[];
  }

  export interface Image {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Comment: Edm.String;
    FileName: Edm.String;
    FileSize: Edm.Int32;
    FileType: Edm.String;
    DownloadToken: Edm.String;
    Created: Edm.DateTimeOffset;
    User?: Operations.User;
    Module?: PropertyCore.Module;
    Equipment?: PropertyCore.Equipment;
    Building?: PropertyCore.Building;
  }

  export interface SubscriptionPlan {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    AccountType: Operations.AccountTypes;
    Code: Edm.String;
    IsActive: Edm.Boolean;
    LimitBuildings: Edm.Int32;
    PricePerYear: Edm.Decimal;
  }

  export interface LegalArea {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Name: Edm.String;
    Description: Edm.String;
    Created: Edm.DateTimeOffset;
    OperatorTasks?: PropertyCore.OperatorTask[];
  }

  export interface OperatorTask {
    Identity: Edm.String;
    Id: Edm.Int32;
    Guidelines: Edm.String;
    Subject: Edm.String;
    Deadline: Edm.String;
    Qualification: Edm.String;
    Type: Edm.String;
    BuildingType: Edm.String;
    Rating: Edm.Int32;
    IsRelevant: Edm.Boolean;
    TextPassage: Edm.String;
    Cycle: Edm.Int32;
    CycleUnit: Api.Odata.CycleUnits;
    RequiredDocument: Edm.Int32;
    Created: Edm.DateTimeOffset;
    User?: Operations.User;
    DocumentType?: PropertyCore.DocumentType;
    LegalArea?: PropertyCore.LegalArea;
    InspectionDuties?: PropertyCore.InspectionDuty[];
  }

  export interface InspectionDuty {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    IsDisabled: Edm.Boolean;
    Module?: PropertyCore.Module;
    OperatorTask?: PropertyCore.OperatorTask;
    Equipment?: PropertyCore.Equipment;
    Schedule?: PropertyCore.Schedule;
  }

  export interface QRCode {
    Identity: Edm.String;
    Id: Edm.Int32;
    Created: Edm.DateTimeOffset;
    QRCodeAssigns?: PropertyCore.QRCodeAssign[];
    User?: Operations.User;
  }

  export interface QRCodeAssign {
    Id: Edm.Int32;
    IsApp: Edm.Boolean;
    Created: Edm.DateTimeOffset;
    User?: Operations.User;
    QRCode?: PropertyCore.QRCode;
    Equipment?: PropertyCore.Equipment;
  }

  export interface Branch {
    Id: Edm.Int32;
    Code: Edm.String;
    Name: Edm.String;
    Created: Edm.DateTimeOffset;
    CostGroups?: PropertyCore.CostGroup[];
  }

  export interface EventResponse {
    Id: Edm.Int32;
    Comment: Edm.String;
    DefectRating: PropertyCore.DefectRatings;
    HasDefect: Edm.Boolean;
    IsServiceComplete: Edm.Boolean;
    IsDefectProcessed: Edm.Boolean;
    ResponseDate: Edm.DateTimeOffset;
    UpdateDate: Edm.DateTimeOffset;
    Created: Edm.DateTimeOffset;
    User?: Operations.User;
  }

  // Complex types

  // Enum Types
  // Enum Values: Undefined = 0, Create = 1, Update = 2, Delete = 3, Feedback = 4, Qualified = 5, Download = 6
  export enum ActionLogOperations {
    Undefined = "Undefined",
    Create = "Create",
    Update = "Update",
    Delete = "Delete",
    Feedback = "Feedback",
    Qualified = "Qualified",
    Download = "Download"
  }

  // Enum Values: Undefined = 0, Document = 1, Image = 2, Attribute = 3, Link = 4, Event = 5, Application = 6, InspectionDuty = 7, AndroidApp = 8
  export enum ActionLogElements {
    Undefined = "Undefined",
    Document = "Document",
    Image = "Image",
    Attribute = "Attribute",
    Link = "Link",
    Event = "Event",
    Application = "Application",
    InspectionDuty = "InspectionDuty",
    AndroidApp = "AndroidApp"
  }

  // Enum Values: Inspection = 1, FollowUpInspection = 2, Maintenance = 3, Repair = 4, Custom = 5
  export enum EventTypes {
    Undefined = "Undefined",
    Inspection = "Inspection",
    FollowUpInspection = "FollowUpInspection",
    Maintenance = "Maintenance",
    Repair = "Repair",
    Custom = "Custom"
  }

  // Enum Values: Undefined = 0, Unrated = 1, Minor = 2, Major = 3, Dangerous = 4
  export enum DefectRatings {
    Undefined = "Undefined",
    Unrated = "Unrated",
    Minor = "Minor",
    Major = "Major",
    Dangerous = "Dangerous"
  }

  // Entity container
  //EntitySets
}

export namespace ZZZ {
  // Entity types
  export interface ZZZAccount {
    Identity: Edm.Guid;
    Id: Edm.Int32;
    Name: Edm.String;
    User?: ZZZ.ZZZUser;
  }

  export interface ZZZUser {
    Identity: Edm.Guid;
    Id: Edm.Int32;
  }

  // Complex types

  // Enum Types

  // Entity container
  //EntitySets
}

export namespace System {
  // Entity types

  // Complex types
  export interface Uri {
    Segments: Edm.String[];
  }

  // Enum Types

  // Entity container
  //EntitySets
}

export namespace Api.Odata {
  // Entity types
  export interface ServiceProviderStatistics {
    Id: Edm.Int32;
    MaintenanceContractCount: Edm.Int32;
    EquipmentEventCount: Edm.Int32;
    ModuleEventCount: Edm.Int32;
  }

  export interface RoomStatistics {
    Id: Edm.Int32;
    EquipmentCount: Edm.Int32;
    ModuleCount: Edm.Int32;
  }

  export interface EquipmentStatistics {
    Id: Edm.Int32;
    FabNo: Edm.String;
    AlternativeNo: Edm.String;
    ModuleCount: Edm.Int32;
    MaintenanceContractCount: Edm.Int32;
    ImageCount: Edm.Int32;
    QRCodeCount: Edm.Int32;
    OperatorTaskCount: Edm.Int32;
    InspectionDutyCount: Edm.Int32;
  }

  export interface ModuleStatistics {
    Id: Edm.Int32;
    ImageCount: Edm.Int32;
  }

  export interface AccountStatistics {
    Id: Edm.Int32;
    BuildingCount: Edm.Int32;
  }

  // Complex types

  // Enum Types
  // Enum Values: NotSpecified = 0, Year = 1, Month = 2, Week = 3, Day = 4
  export enum CycleUnits {
    NotSpecified = "NotSpecified",
    Year = "Year",
    Month = "Month",
    Week = "Week",
    Day = "Day"
  }

  // Enum Values: Undefined = 0, Flat = 1, PerKilometer = 2
  export enum MaintenanceContractApproachFeeTypes {
    Undefined = "Undefined",
    Flat = "Flat",
    PerKilometer = "PerKilometer"
  }

  // Enum Values: Undefined = 0, MonthEnd = 1, QuarterEnd = 2, YearEnd = 3
  export enum MaintenanceContractCancellationPeriods {
    Undefined = "Undefined",
    MonthEnd = "MonthEnd",
    QuarterEnd = "QuarterEnd",
    YearEnd = "YearEnd"
  }

  // Enum Values: Undefined = 0, Day = 1, Week = 2, Month = 3
  export enum MaintenanceContractCancellationUnits {
    Undefined = "Undefined",
    Day = "Day",
    Week = "Week",
    Month = "Month"
  }

  // Enum Values: Undefined = 0, CalendarYear = 1, ContractYear = 2
  export enum MaintenanceContractCancellationCalendars {
    Undefined = "Undefined",
    CalendarYear = "CalendarYear",
    ContractYear = "ContractYear"
  }

  // Enum Values: Undefined = 0, Administrator = 1
  export enum AccountRoles {
    Undefined = "Undefined",
    Administrator = "Administrator"
  }

  // Enum Values: Undefined = 0, Manager = 2
  export enum BuildingRoles {
    Undefined = "Undefined",
    Manager = "Manager"
  }

  // Entity container
  //EntitySets
}

export namespace Tools {
  // Entity types
  // Complex types
  // Enum Types
  // Entity container
  //EntitySets
}

export namespace ObjektBrief {
  // Entity types

  // Complex types

  // Enum Types

  // Entity container
  export class Container extends odatatools.ProxyBase {
    constructor(
      address: string,
      name?: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(address, name, additionalHeaders);
      this.Country = new CountryEntitySet(
        "Country",
        address,
        "Code",
        additionalHeaders
      );
      this.Region = new RegionEntitySet(
        "Region",
        address,
        "Code",
        additionalHeaders
      );
      this.User = new UserEntitySet(
        "User",
        address,
        "Identity",
        additionalHeaders
      );
      this.Account = new AccountEntitySet(
        "Account",
        address,
        "Identity",
        additionalHeaders
      );
      this.AccountRole = new AccountRoleEntitySet(
        "AccountRole",
        address,
        "Id",
        additionalHeaders
      );
      this.AccountProfile = new AccountProfileEntitySet(
        "AccountProfile",
        address,
        "Identity",
        additionalHeaders
      );
      this.AccountDataProcessingAgreement = new AccountDataProcessingAgreementEntitySet(
        "AccountDataProcessingAgreement",
        address,
        "Identity",
        additionalHeaders
      );
      this.AccountRights = new AccountRightsEntitySet(
        "AccountRights",
        address,
        "Identity",
        additionalHeaders
      );
      this.AccountSubscriptionHistory = new AccountSubscriptionHistoryEntitySet(
        "AccountSubscriptionHistory",
        address,
        "Id",
        additionalHeaders
      );
      this.BuildingRole = new BuildingRoleEntitySet(
        "BuildingRole",
        address,
        "Id",
        additionalHeaders
      );
      this.Invitation = new InvitationEntitySet(
        "Invitation",
        address,
        "Identity",
        additionalHeaders
      );
      this.Building = new BuildingEntitySet(
        "Building",
        address,
        "Identity",
        additionalHeaders
      );
      this.BuildingStatistics = new BuildingStatisticsEntitySet(
        "BuildingStatistics",
        address,
        "Id",
        additionalHeaders
      );
      this.Equipment = new EquipmentEntitySet(
        "Equipment",
        address,
        "Identity",
        additionalHeaders
      );
      this.EquipmentType = new EquipmentTypeEntitySet(
        "EquipmentType",
        address,
        "Code",
        additionalHeaders
      );
      this.Module = new ModuleEntitySet(
        "Module",
        address,
        "Identity",
        additionalHeaders
      );
      this.ModuleType = new ModuleTypeEntitySet(
        "ModuleType",
        address,
        "Code",
        additionalHeaders
      );
      this.Schedule = new ScheduleEntitySet(
        "Schedule",
        address,
        "Identity",
        additionalHeaders
      );
      this.Event = new EventEntitySet(
        "Event",
        address,
        "Identity",
        additionalHeaders
      );
      this.MaintenanceContract = new MaintenanceContractEntitySet(
        "MaintenanceContract",
        address,
        "Identity",
        additionalHeaders
      );
      this.MaintenanceContractMapping = new MaintenanceContractMappingEntitySet(
        "MaintenanceContractMapping",
        address,
        "Identity",
        additionalHeaders
      );
      this.Room = new RoomEntitySet(
        "Room",
        address,
        "Identity",
        additionalHeaders
      );
      this.Floor = new FloorEntitySet(
        "Floor",
        address,
        "Id",
        additionalHeaders
      );
      this.Section = new SectionEntitySet(
        "Section",
        address,
        "Identity",
        additionalHeaders
      );
      this.UsageType = new UsageTypeEntitySet(
        "UsageType",
        address,
        "Id",
        additionalHeaders
      );
      this.CostGroup = new CostGroupEntitySet(
        "CostGroup",
        address,
        "Id",
        additionalHeaders
      );
      this.ActionLog = new ActionLogEntitySet(
        "ActionLog",
        address,
        "Id",
        additionalHeaders
      );
      this.ServiceProvider = new ServiceProviderEntitySet(
        "ServiceProvider",
        address,
        "Identity",
        additionalHeaders
      );
      this.Document = new DocumentEntitySet(
        "Document",
        address,
        "Identity",
        additionalHeaders
      );
      this.DocumentType = new DocumentTypeEntitySet(
        "DocumentType",
        address,
        "Id",
        additionalHeaders
      );
      this.DocumentTypeGroup = new DocumentTypeGroupEntitySet(
        "DocumentTypeGroup",
        address,
        "Id",
        additionalHeaders
      );
      this.Image = new ImageEntitySet(
        "Image",
        address,
        "Identity",
        additionalHeaders
      );
      this.SubscriptionPlan = new SubscriptionPlanEntitySet(
        "SubscriptionPlan",
        address,
        "Identity",
        additionalHeaders
      );
      this.LegalArea = new LegalAreaEntitySet(
        "LegalArea",
        address,
        "Identity",
        additionalHeaders
      );
      this.OperatorTask = new OperatorTaskEntitySet(
        "OperatorTask",
        address,
        "Identity",
        additionalHeaders
      );
      this.InspectionDuty = new InspectionDutyEntitySet(
        "InspectionDuty",
        address,
        "Identity",
        additionalHeaders
      );
      this.QRCode = new QRCodeEntitySet(
        "QRCode",
        address,
        "Identity",
        additionalHeaders
      );
      this.QRCodeAssign = new QRCodeAssignEntitySet(
        "QRCodeAssign",
        address,
        "Id",
        additionalHeaders
      );
      this.Branch = new BranchEntitySet(
        "Branch",
        address,
        "Id",
        additionalHeaders
      );
      this.ZZZAccount = new ZZZAccountEntitySet(
        "ZZZAccount",
        address,
        "Identity",
        additionalHeaders
      );
      this.ZZZUser = new ZZZUserEntitySet(
        "ZZZUser",
        address,
        "Identity",
        additionalHeaders
      );
    }

    Country: CountryEntitySet;
    Region: RegionEntitySet;
    User: UserEntitySet;
    Account: AccountEntitySet;
    AccountRole: AccountRoleEntitySet;
    AccountProfile: AccountProfileEntitySet;
    AccountDataProcessingAgreement: AccountDataProcessingAgreementEntitySet;
    AccountRights: AccountRightsEntitySet;
    AccountSubscriptionHistory: AccountSubscriptionHistoryEntitySet;
    BuildingRole: BuildingRoleEntitySet;
    Invitation: InvitationEntitySet;
    Building: BuildingEntitySet;
    BuildingStatistics: BuildingStatisticsEntitySet;
    Equipment: EquipmentEntitySet;
    EquipmentType: EquipmentTypeEntitySet;
    Module: ModuleEntitySet;
    ModuleType: ModuleTypeEntitySet;
    Schedule: ScheduleEntitySet;
    Event: EventEntitySet;
    MaintenanceContract: MaintenanceContractEntitySet;
    MaintenanceContractMapping: MaintenanceContractMappingEntitySet;
    Room: RoomEntitySet;
    Floor: FloorEntitySet;
    Section: SectionEntitySet;
    UsageType: UsageTypeEntitySet;
    CostGroup: CostGroupEntitySet;
    ActionLog: ActionLogEntitySet;
    ServiceProvider: ServiceProviderEntitySet;
    Document: DocumentEntitySet;
    DocumentType: DocumentTypeEntitySet;
    DocumentTypeGroup: DocumentTypeGroupEntitySet;
    Image: ImageEntitySet;
    SubscriptionPlan: SubscriptionPlanEntitySet;
    LegalArea: LegalAreaEntitySet;
    OperatorTask: OperatorTaskEntitySet;
    InspectionDuty: InspectionDutyEntitySet;
    QRCode: QRCodeEntitySet;
    QRCodeAssign: QRCodeAssignEntitySet;
    Branch: BranchEntitySet;
    ZZZAccount: ZZZAccountEntitySet;
    ZZZUser: ZZZUserEntitySet;

    // Unbound Functions

    //Unbound Actions
  }

  //EntitySets
  export class CountryEntitySet extends odatatools.EntitySet<Library.Country> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class RegionEntitySet extends odatatools.EntitySet<Library.Region> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class UserEntitySet extends odatatools.EntitySet<Operations.User> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class AccountEntitySet extends odatatools.EntitySet<Operations.Account> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }

    // Bound to entity Actions
    AcceptInvitation(
      key: Edm.Guid,
      Identity: Edm.Guid,
      AccessCode: Edm.Guid
    ): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        let request: odatajs.Request = {
          headers: this.Headers,
          method: "POST",
          requestUri:
            this.Address + "(" + key + ")/ObjektBrief.AcceptInvitation()",
          data: {
            Identity: Identity,
            AccessCode: AccessCode
          }
        };
        odatajs.oData.request(
          request,
          (data, response) => {
            resolve(data.value || data);
          },
          error => {
            console.error(
              error.name +
              " " +
              error.message +
              " | " +
              (error.response | error.response.statusText) +
              ":" +
              (error.response | error.response.body)
            );
            reject(error);
          }
        );
      });
    }
  }

  export class AccountRoleEntitySet extends odatatools.EntitySet<Operations.AccountRole> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class AccountProfileEntitySet extends odatatools.EntitySet<Operations.AccountProfile> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class AccountDataProcessingAgreementEntitySet extends odatatools.EntitySet<Operations.AccountDataProcessingAgreement> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class AccountRightsEntitySet extends odatatools.EntitySet<Operations.AccountRights> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class AccountSubscriptionHistoryEntitySet extends odatatools.EntitySet<Operations.AccountSubscriptionHistory> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class BuildingRoleEntitySet extends odatatools.EntitySet<Operations.BuildingRole> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class InvitationEntitySet extends odatatools.EntitySet<Operations.Invitation> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class BuildingEntitySet extends odatatools.EntitySet<PropertyCore.Building> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }

    // Bound to entity Actions
    AddDefaults(key: Edm.Guid): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        let request: odatajs.Request = {
          headers: this.Headers,
          method: "POST",
          requestUri: this.Address + "(" + key + ")/ObjektBrief.AddDefaults()",
          data: {}
        };
        odatajs.oData.request(
          request,
          (data, response) => {
            resolve(data.value || data);
          },
          error => {
            console.error(
              error.name +
              " " +
              error.message +
              " | " +
              (error.response | error.response.statusText) +
              ":" +
              (error.response | error.response.body)
            );
            reject(error);
          }
        );
      });
    }
  }

  export class BuildingStatisticsEntitySet extends odatatools.EntitySet<PropertyCore.BuildingStatistics> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class EquipmentEntitySet extends odatatools.EntitySet<PropertyCore.Equipment> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class EquipmentTypeEntitySet extends odatatools.EntitySet<PropertyCore.EquipmentType> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class ModuleEntitySet extends odatatools.EntitySet<PropertyCore.Module> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class ModuleTypeEntitySet extends odatatools.EntitySet<PropertyCore.ModuleType> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class ScheduleEntitySet extends odatatools.EntitySet<PropertyCore.Schedule> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class EventEntitySet extends odatatools.EntitySet<PropertyCore.Event> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class MaintenanceContractEntitySet extends odatatools.EntitySet<PropertyCore.MaintenanceContract> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class MaintenanceContractMappingEntitySet extends odatatools.EntitySet<PropertyCore.MaintenanceContractMapping> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class RoomEntitySet extends odatatools.EntitySet<PropertyCore.Room> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class FloorEntitySet extends odatatools.EntitySet<PropertyCore.Floor> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class SectionEntitySet extends odatatools.EntitySet<PropertyCore.Section> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class UsageTypeEntitySet extends odatatools.EntitySet<PropertyCore.UsageType> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class CostGroupEntitySet extends odatatools.EntitySet<PropertyCore.CostGroup> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class ActionLogEntitySet extends odatatools.EntitySet<PropertyCore.ActionLog> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class ServiceProviderEntitySet extends odatatools.EntitySet<PropertyCore.ServiceProvider> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class DocumentEntitySet extends odatatools.EntitySet<PropertyCore.Document> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class DocumentTypeEntitySet extends odatatools.EntitySet<PropertyCore.DocumentType> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class DocumentTypeGroupEntitySet extends odatatools.EntitySet<PropertyCore.DocumentTypeGroup> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class ImageEntitySet extends odatatools.EntitySet<PropertyCore.Image> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class SubscriptionPlanEntitySet extends odatatools.EntitySet<PropertyCore.SubscriptionPlan> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class LegalAreaEntitySet extends odatatools.EntitySet<PropertyCore.LegalArea> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class OperatorTaskEntitySet extends odatatools.EntitySet<PropertyCore.OperatorTask> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class InspectionDutyEntitySet extends odatatools.EntitySet<PropertyCore.InspectionDuty> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class QRCodeEntitySet extends odatatools.EntitySet<PropertyCore.QRCode> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class QRCodeAssignEntitySet extends odatatools.EntitySet<PropertyCore.QRCodeAssign> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class BranchEntitySet extends odatatools.EntitySet<PropertyCore.Branch> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class ZZZAccountEntitySet extends odatatools.EntitySet<ZZZ.ZZZAccount> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }

  export class ZZZUserEntitySet extends odatatools.EntitySet<ZZZ.ZZZUser> {
    constructor(
      name: string,
      address: string,
      key: string,
      additionalHeaders?: odatajs.Header
    ) {
      super(name, address, key, additionalHeaders);
    }
  }
}
