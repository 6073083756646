import { PropertyCore } from '../odata/odata.coreapi';

export class InspectionDuty implements PropertyCore.InspectionDuty {

  // from interface
  Identity: string;
  Id: number;
  IsDisabled: boolean;
  Module?: PropertyCore.Module;
  OperatorTask?: PropertyCore.OperatorTask;
  Equipment?: PropertyCore.Equipment;
  Schedule?: PropertyCore.Schedule;

  // additional properties for gui use
  originalIsDisabled: boolean;
  isChangedSeriesStart: boolean;
  isChangedActive: boolean;
}
