import { Injectable } from '@angular/core';
import {PropertyCore} from 'app/core/odata/odata.coreapi';
import { InspectiondutyWizardModel} from './inspectionduty-wizard-model';
//import { Equipment } from 'app/core/model/equipmentOperatorTask';
import { EditOperatorTask } from '../../../core/components/equipment/edit-inspection-duties/editOperatorTask';
@Injectable()
export class InspectiondutyWizardService {

    model: InspectiondutyWizardModel = new InspectiondutyWizardModel;
    equipment: PropertyCore.Equipment;
    equipmentOperatorTasks: EditOperatorTask[];
    attributesCategories?: any[] = [];
    attributesCategoryNames?: any[] = [];
    categoryArray?: any[] = [];   
    cycleUnits: string[];
    selectedEquipments: String[] = [];
    changedIDs: number[] =[];

    constructor() {
    }
    getCurrentEquipmentModel(): InspectiondutyWizardModel{
        return this.model;
    }
    startWizard(equipment: PropertyCore.Equipment,
        equipmentOperatorTasks: EditOperatorTask[],
         attributesCategories:any[],
          attributesCategoryNames:any[],
          categoryArray: any[],
          cycleUnits: String[]
          ):void{

            this.model.equipment = equipment;
            this.model.equipmentOperatorTasks = equipmentOperatorTasks;
            this.model.attributesCategories = attributesCategories;
            this.model.attributesCategoryNames = attributesCategoryNames;
            this.model.categoryArray = categoryArray;
            this.model.cycleUnits = cycleUnits;
            this.model.selectedEquipments = [];
            this.model.selectedEquipments.push(equipment.Identity);
            this.model.comment= "";
            this.changedIDs=[];
            
            

            
        }
    resettInspectionduty():void {
        
        
    }


   
}
