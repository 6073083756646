export enum EquipmentWizardNavigationPaths {

  TYPE = 'type',
  DATA = 'data',
  TECHNICAL = 'technical',

  NEXT = 'next',
  PREVIOUS = 'previous',
  START = 'start',
  EXIT = 'exit'
}
