import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class IncidentState {

  public static readonly NAVIGATION_ACTION_NEXT_INCIDENT = 'nav_action_next_incident';

  public static readonly ACTION_CHANGE_INCIDENT = 'action_change_incident';

  private subject: Subject<string> = new Subject();

  constructor() {}

  notify(notificationtext: string) {
    this.subject.next(notificationtext);
  }

  registerForNotification(): Observable<string> {
    return this.subject.asObservable();
  }

}
