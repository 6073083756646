import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {Subscription, from} from 'rxjs';
import {AuthService} from './../../core/auth/auth.service';
import {Common, Operations, ObjektBrief} from './../../core/odata/odata.coreapi';
import {Utils} from './../../core/tools/utils';
import { map } from 'rxjs/operators';
import { ODataCoreService } from './../../core/odata-services/odata.coreapi.service';
import { ODataPath } from '../../core/odata/odataclient';
import { Guid } from 'guid-typescript';
import { LoginService } from 'app/core/shared/login.service';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceProviderComponent implements OnInit, OnDestroy {

  mandators: Operations.Account[];

  searchFilter: string = '';

  formSubmitted: boolean;

  accessCode: string;
  invitation: string;

  @ViewChild('serviceContactPopupContent', { static: true })
  serviceContactPopupContent: any;
  mandatorName: string;
  mandatorAddress: Common.Address = <Common.Address>{CountryInfo: {Id: 1, Name: 'Deutschland'}};

  subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.route.queryParamMap.subscribe(params => {
      this.accessCode = params.get('accessCode');
      this.invitation = params.get('invitation');

      if (!this.accessCode || !this.invitation) {
        this.toasterService.pop('warning', '', this.translateService.instant('ServiceProvider.load_error'));
        this.router.navigate(['/dashboard']);
      } else {
        this.subscriptions.add(from(this.odataCoreService.Account.Query()
          .OrderBy("Name")
          .Filter(x => x
            .Equals(ODataPath.For("AccountType", Operations.AccountTypes.ServiceProvider), true))
          .Exec().then(x => x.value))
          .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(mandators => {
          this.mandators = mandators;
        }));
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  newServiceProvider() {
    this.modalService.open(this.serviceContactPopupContent, {windowClass: 'service-contact-modal'}).result.then(result => {
        // create mandator

          const guid = Utils.guid();
          this.odataCoreService.Account.Post()
          .ValueType(this.odataCoreService.ODataTypes().Account())
          .ValueProperty("Identity", Guid.parse(guid))
          .ValueProperty("Name", this.mandatorName)     
          .ValueProperty("AccountType",Operations.AccountTypes.ServiceProvider)
          .Exec()
          .then(res => {

            this.odataCoreService.Account.Get()
            .Key(Guid.parse(res.Identity))
            .NavigateToArray<ObjektBrief.AccountProfile>("Profile")
            .Patch()
            .ValueType(this.odataCoreService.ODataTypes().AccountProfile())
            .ValueProperty("Name", this.mandatorName)
            .ValueProperty("Contact", localStorage.getItem('infoUserName') + ' ' + localStorage.getItem('infoUserSurname'))
            .ValueProperty("ContactMail", this.authService.getUserEmail())
            .ValueProperty("Address", this.mapAddress(this.mandatorAddress))
            .Exec()
            .then(x => {
                    
            // reload service provider mandators
                    this.subscriptions.add(from(this.odataCoreService.Account.Query()
                    .OrderBy("Name")
                    .Filter(x => x
                      .Equals(ODataPath.For("AccountType", Operations.AccountTypes.ServiceProvider), true))
                    .Exec().then(x => x.value))
                    .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(mandators => {
                  this.mandators = mandators;
                }));

        });
      });
    })
    .catch(() => {
      // do nothing, just stay on page
    });
  }

  acceptServiceProvider(mandator: Operations.Account) {
    this.subscriptions.add(from(this.odataCoreService.Account.Get().Key(Guid.parse(mandator.Identity)).Actions().AcceptInvitationInOperationsOnAccountInOperations().Parameters(this.invitation, this.accessCode)
      .Execute()).subscribe(() => {
      this.toasterService.pop('info', '', this.translateService.instant('ServiceProvider.accept_success'));

      // change mandator to the selected mandator
      this.authService.setMandator(mandator.Identity, true);
      this.subscriptions.add(this.loginService.saveSettings(AuthService.USER_MANDATOR_CONFIG, mandator.Identity).subscribe(any => {
        this.router.navigate(['/dashboard']);
      }));
    }));
  }

  private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};
    if (address?.RegionInfo != null && address.RegionInfo.hasOwnProperty('Code')){
      entity.Region = address.RegionInfo['Code'];
    } 
    if (address?.CountryInfo != null && address.CountryInfo.hasOwnProperty('Code')) {
      entity.Country = address.CountryInfo['Code'];
    } else {
      entity.Country = 'DE';
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }
}
