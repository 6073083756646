import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, Observable, from } from "rxjs";

import { AuthService } from "../../../core/auth/auth.service";
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { ODataPath } from "../../../core/odata/odataclient";

@Component({
  selector: "app-dashboard-conformity-box",
  templateUrl: "./conformity-box.component.html",
  styleUrls: ["./conformity-box.component.scss"],
})
export class ConformityBoxComponent implements OnInit, OnDestroy {
  missingDocumentIncidentsLength = 0;
  overDueIncidentsLength = 0;
  nextThreeMonthsIncidentsLength = 0;
  defectIncidentsLength = 0;
  subscriptions: Subscription = new Subscription();

  constructor(public authService: AuthService, private router: Router, private odataCoreService: ODataCoreService) {}

  ngOnInit() {
    this.subscriptions.add(
      from(
        this.odataCoreService.Event.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "IsDefective"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
              .And.NotEquals("Type", "Undefined")
          )
          .Count()
          .then((result) => (this.defectIncidentsLength = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Event.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "IsOverdue"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
              .And.NotEquals("Type", "Undefined")
          )
          .Count()
          .then((result) => (this.overDueIncidentsLength = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Event.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "IsPlanned"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
              .And.NotEquals("Type", "Undefined")
          )
          .Count()
          .then((result) => (this.nextThreeMonthsIncidentsLength = result))
      ).subscribe()
    );

    this.subscriptions.add(
      from(
        this.odataCoreService.Event.Query()
          .Filter((x) =>
            x
              .Equals(ODataPath.For("KpiMarker", "IsMissingDocuments"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
              .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
              .And.NotEquals("Type", "Undefined")
          )
          .Count()
          .then((result) => (this.missingDocumentIncidentsLength = result))
      ).subscribe()
    );
  }

  openConformityList(type: string) {
    this.router.navigate([
      "/dashboard",
      { outlets: { left: ["board"], right: ["conformity", "list", { tab: [type] }] } },
    ]);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
