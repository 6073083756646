import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MissingDocumentState {

  public static readonly ACTION_UPLOAD_DOCUMENT = 'action_upload_document';

  private subject: Subject<string> = new Subject();

  constructor() { }

  notify(notificationtext: string) {
    this.subject.next(notificationtext);
  }

  registerForNotification(): Observable<string> {
    return this.subject.asObservable();
  }


}
