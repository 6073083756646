import { Injectable } from '@angular/core';

@Injectable()
export class NavigatorService {

  private url: string;
  private navigationAction: string;

  constructor() { }

  // TODO think about storing data also in localstorage (in case user reloads page)

  public provideBackUrl(backUrl: string, navAction?: string): void {
    this.url = backUrl;
    this.navigationAction = navAction;
  }

  public getBackUrl(): string {
    return this.url;
  }

  public getNavigationAction(): string {
    return this.navigationAction;
  }

  // TODO move this very stupid and simple cleanup to proper registration and finish behavior with observer patterns
  // example: all possible listeners (of these navigation+action) behaviors ( = detail and list pages) register this service with their specific ACTION implementation
  //          when a post-wizard-navigation action is required the creator (whatever action requires e.h. a wizarding page process) defines the wanted ACTION.
  //          this service counts all registered subscribers and after all subscribers do their post-process action, this data (url and action) will be deleted.
  public finishNavigation(): void {
    this.url = undefined;
    this.navigationAction = undefined;
  }
}
