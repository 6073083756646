import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Subscription, from } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { PropertyCore } from '../../core/odata/odata.coreapi';
import { AuthService } from '../../core/auth/auth.service';
import { ConfirmationModal } from '../../core/popup/confirmation.modal';
import { PopupService } from '../../core/popup/popup.service';
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import Module = PropertyCore.Module;
import { Utils } from '../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { AppConfig } from '../../core/bootstrap/app.config';
import { ImageUploadModel } from '../../core/components/upload/image-upload-model';
import { ImageUploadService } from '../../core/components/upload/image-upload-service';
import { FileItem } from 'ng2-file-upload';


@Component({
  selector: 'app-module-detail',
  templateUrl: './module-detail.component.html',
  styleUrls: ['./module-detail.component.scss']
})
export class ModuleDetailComponent implements OnInit, OnDestroy {
  installModule: PropertyCore.Module;
  buildingId: string;
  images: PropertyCore.Image[];
  imgIdx = 0;
  inspectionDuties: any[];
  imageUploadModel: ImageUploadModel;

  subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private popupService: PopupService,
    private odataCoreService: ODataCoreService,
    private config: AppConfig,
    private imageUploadService: ImageUploadService
  ) {
  }

  ngOnInit() {
    this.imageUploadModel = this.imageUploadService.getCurrentImageUploadModel();
    this.imageUploadModel.entityType = "Module";
    this.subscriptions.add(
      this.route.parent.paramMap
        .pipe(switchMap((params: ParamMap) => {
          return from(this.odataCoreService.Module.Get()
            .Key(Guid.parse(params.get('id')))
            .Expand(x => {
              x.Expand("ModuleType"),
                x.Expand("Equipment"),
                x.Expand("Room", y =>
                  y.Expand("Floor"))
            })
            // .Filter(xa =>
            //   xa.EqualsField("Identity", Guid.parse(params.get('id'))))
            .Exec().then(x => x.value))
            .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])));
        }))
        .subscribe(installModule => this.installModule = installModule)
    );

    this.subscriptions.add(this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => {
      return from(this.odataCoreService.Module.Get()
        .Key(Guid.parse(params.get('id')))
        .Expand(x => {
          x.Expand("Images")
        })
        // .Filter(xa =>
        //   xa.EqualsField("Identity", Guid.parse(params.get('id'))))
        .Exec().then(x => x.value))
        .pipe(map(res => res[0].Images.map(img => {
          Utils.mapAllJSONDatesToDates(img);
          img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
          return img;
        })))
    }))
      .subscribe(res => {
        this.images = res;
        this.imgIdx = 0;
      }));

    this.subscriptions.add(this.route.parent.parent.paramMap.subscribe((params: ParamMap) => this.buildingId = params.get('id')));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onImageUpload(event): void {
    this.subscriptions.add(from(this.odataCoreService.Module.Query()
      .Expand(x => {
        x.Expand("Images")
      })
      .Filter(xa =>
        xa.EqualsField("Identity", Guid.parse(this.installModule.Identity)))
      .Exec().then(x => x.value))
      .pipe(map(res => res[0].Images.map(img => {
        Utils.mapAllJSONDatesToDates(img);
        img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
        return img;
      }))).subscribe(res => {
        this.images = res;
        this.imgIdx = this.images.length - 1;
        this.imageUploadService.resetCurrentImageUploadModel();
        this.imageUploadModel = this.imageUploadService.getCurrentImageUploadModel();
        this.imageUploadModel.entityType = "Module";
      })
    );
  }

  onAddFile(file: FileItem): void {
    this.imageUploadModel.file.push(file);
  }

  nextImg(): void {
    this.imgIdx++;
    if (this.imgIdx === this.images.length) {
      this.imgIdx = 0;
    }
  }

  prevImg(): void {
    this.imgIdx--;
    if (this.imgIdx === -1) {
      this.imgIdx = this.images.length - 1;
    }
  }

  deleteImg(): void {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('ModuleDetail._deleteImage_modal_title');
    modalRef.componentInstance.message = this.translateService.instant('ModuleDetail._deleteImage_modal_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('ModuleDetail._deleteImage_modal_yes');
    modalRef.componentInstance.cancelButton = this.translateService.instant('ModuleDetail._deleteImage_modal_cancel');

    modalRef.result
      .then(async val => {
        if (val === ConfirmationModal.YES_VALUE) {
          await this.odataCoreService.Image.Delete()
            .Key(Guid.parse(this.images[this.imgIdx].Identity))
            .Exec();
          this.toasterService.pop('info', '', this.translateService.instant('ModuleDetail._deleteImage_success'));
          // reload images after delete
          let res = await this.odataCoreService.Module.Query()
            .Expand(x => {
              x.Expand("Images")
            })
            .Filter(xa =>
              xa.EqualsField("Identity", Guid.parse(this.installModule.Identity)))
            .Exec().then(x => x.value);
          this.images = res[0].Images.map(img => {
            Utils.mapAllJSONDatesToDates(img);
            img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
            return img;
          })
          // nav to next image after the deleted one
          this.prevImg();
          this.nextImg();
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  async sortImg(): Promise<void> {
    if (this.images.length == 2) {
      await this.odataCoreService.Image.Get().Key(Guid.parse(this.images[this.imgIdx].Identity)).Actions().SortInOperationsOnImageInPropertyCore().Parameters(Guid.createEmpty().toString()).Execute();
      await this.odataCoreService.Image.Get().Key(Guid.parse(this.images[this.imgIdx].Identity)).Actions().SortInOperationsOnImageInPropertyCore().Parameters(Guid.createEmpty().toString()).Execute();
      this.toasterService.pop('info', '', this.translateService.instant('ModuleDetail._sortImage_success'));
      // reload images after sort
      let res = await this.odataCoreService.Module.Query()
        .Expand(x => {
          x.Expand("Images")
        })
        .Filter(xa =>
          xa.EqualsField("Identity", Guid.parse(this.installModule.Identity)))
        .Exec().then(x => x.value);
      this.images = res[0].Images.map(img => {
        Utils.mapAllJSONDatesToDates(img);
        img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
        return img;
      })
      // nav to first image
      this.imgIdx = 0;
    } else {
      await this.odataCoreService.Image.Get().Key(Guid.parse(this.images[this.imgIdx].Identity)).Actions().SortInOperationsOnImageInPropertyCore().Parameters(Guid.createEmpty().toString()).Execute();
      this.toasterService.pop('info', '', this.translateService.instant('ModuleDetail._sortImage_success'));
      // reload images after sort
      let res = await this.odataCoreService.Module.Query()
        .Expand(x => {
          x.Expand("Images")
        })
        .Filter(xa =>
          xa.EqualsField("Identity", Guid.parse(this.installModule.Identity)))
        .Exec().then(x => x.value);
      this.images = res[0].Images.map(img => {
        Utils.mapAllJSONDatesToDates(img);
        img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
        return img;
      })
      // nav to first image
      this.imgIdx = 0;
    }
  }

  showImg(): void {
    this.popupService.openImagePreviewModal(this.images[this.imgIdx].DownloadToken);
  }

  edit(): void {
    const identity = this.router.url.split('/'); // Equipment does not come with odata service so we got it from the URL

    this.router.navigate(['/building', this.buildingId, {
      outlets: {
        left: ['equipment', identity[4], 'view', { outlets: { tab: ['modules'] } }],
        right: ['equipment', 'module', 'edit', this.installModule.Identity]
      }
    }]);
  }

  delete(): void {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('EquipmentDetailEditModule._modal_title');
    modalRef.componentInstance.message = this.translateService.instant('EquipmentDetailEditModule._modal_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('EquipmentDetailEditModule._modal_yes');
    modalRef.componentInstance.cancelButton = this.translateService.instant('EquipmentDetailEditModule._modal_cancel');

    modalRef.result
      .then(async val => {
        if (val === ConfirmationModal.YES_VALUE) {
          await this.odataCoreService.Module.Delete()
            .Key(Guid.parse(this.installModule.Identity))
            .Exec();
          this.toasterService.pop('info', '', this.translateService.instant('EquipmentDetailEditModule._delete_success'));
          this.router.navigate(['/building', this.buildingId, {
            outlets: {
              left: ['building', 'view', { outlets: { tab: ['equipment'] } }],
              right: ['equipment', this.installModule.Equipment.Identity, 'view', { outlets: { tab: ['modules'] } }]
            }
          }]);
          (error: HttpErrorResponse) => {
            this.toasterService.pop('error', '', 'EquipmentDetailEditModule._delete_error');
          }
        }
      })
  }
}
