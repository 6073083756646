import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Common, PropertyCore } from '../../../../core/odata/odata.coreapi';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { IncidentWizardModel } from './../incident-wizard-model';
import { IncidentWizardNavigationPaths } from './../incident-wizard-navigation-paths.enum';
import { IncidentWizardService } from './../incident-wizard.service';


@Component({
  selector: 'app-incident-wizard-data',
  templateUrl: './incident-wizard-data.component.html',
  styleUrls: ['./incident-wizard-data.component.scss']
})
export class IncidentWizardDataComponent implements OnInit, OnDestroy {

  model: IncidentWizardModel;

  incidentTypes: string[] = Object.keys(PropertyCore.EventTypes).filter(x => x !== PropertyCore.EventTypes.Undefined && x !== PropertyCore.EventTypes.FollowUpInspection);
  EventTypes = PropertyCore.EventTypes;
  cycleUnits: string[] = Object.keys(Common.IntervalUnits);

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<IncidentWizardNavigationPaths>();

  constructor(
    private service: IncidentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.model = this.service.getCurrentIncident();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(IncidentWizardNavigationPaths.NEXT);
  }

  previous(): void {
    this.pageChangeRequest.emit(IncidentWizardNavigationPaths.PREVIOUS);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('IncidentWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('IncidentWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('IncidentWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('IncidentWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentIncident();
            this.pageChangeRequest.emit(IncidentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      this.pageChangeRequest.emit(IncidentWizardNavigationPaths.EXIT);
    }
  }

  onTypeChange(): void {
    if (this.model.type !== PropertyCore.EventTypes.Inspection && this.model.type !== PropertyCore.EventTypes.Maintenance && this.model.type !== PropertyCore.EventTypes.Custom) {
      this.model.cycle = 1;
      this.model.cycleUnit = Common.IntervalUnits.Undefined;
    }
  }

  compareId(val1: {Id: number}, val2: {Id: number}): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  openInfoDialog() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('IncidentWizardData._span_guidelines');
    modalRef.componentInstance.message = this.translateService.instant('IncidentWizardData._modal_guidelines_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('IncidentWizardData._modal_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }

  openInfoDialog2() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('IncidentWizardData._span_type');
    modalRef.componentInstance.message = this.translateService.instant('IncidentWizardData._modal_type_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('IncidentWizardData._modal_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }

}
