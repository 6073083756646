import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription, from, Observable } from 'rxjs';
import { Common, PropertyCore, Operations, ObjektBrief } from 'app/core/odata/odata.coreapi';
import { AuthService } from 'app/core/auth/auth.service';
import { ConfirmationModal } from 'app/core/popup/confirmation.modal';
import { EquipmentEditInspectionDutiesInputData } from 'app/core/components/equipment/equipment-edit-inspection-duties-input-data';
import { EquipmentEditInspectionDutiesSaveOutputData } from 'app/core/components/equipment/equipment-edit-inspection-duties-save-output-data';
import { EditInspectionDutyGroup } from 'app/core/components/equipment/edit-inspection-duties/editInspectionDutyGroup';
import { InspectiondutyWizardNavigationPaths } from './inspectionduty-wizard-navigation-paths';
import { ODataCoreService } from 'app/core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';
import { ODataPath } from 'app/core/odata/odataclient';
import { EditOperatorTask } from 'app/core/components/equipment/edit-inspection-duties/editOperatorTask';
import { map, switchMap } from 'rxjs/operators';
import { Utils } from 'app/core/tools/utils';
import { InspectiondutyWizardService } from './inspectionduty-wizard-service';
import { InspectiondutyWizardModel } from './inspectionduty-wizard-model';
import { InspectiondutyWizardApiService } from './inspectionduty-wizard-api-service';

@Component({
  selector: 'app-inspectionduty-wizard',
  templateUrl: './inspectionduty-wizard.component.html',
  styleUrls: ['./inspectionduty-wizard.component.scss'],
  providers: [InspectiondutyWizardApiService],
  encapsulation: ViewEncapsulation.None
})
export class InspectiondutyWizardComponent implements OnInit, OnDestroy {
  //@Input() inputData: EquipmentEditInspectionDutiesInputData;

  //@Output() closeRequest = new EventEmitter<void>();

  //@Output() saveRequest = new EventEmitter<EquipmentEditInspectionDutiesSaveOutputData>();
  inputData = new EquipmentEditInspectionDutiesInputData();
  equipment: PropertyCore.Equipment;
  equipmentOperatorTaskMarkers$: Observable<EditOperatorTask[]>;
  hasInspectionDutiesActivatedChanged: boolean;

  modelReady: boolean;
  installEquipmentUT: String;
  currentPage: InspectiondutyWizardNavigationPaths;
  PAGE_DATA = InspectiondutyWizardNavigationPaths.DATA;
  PAGE_OTHEREQUIPMENTS = InspectiondutyWizardNavigationPaths.OTHEREQUIPMENTS;


  showClose: boolean;
  showHeader: boolean;

  inspectionDutyGroups: EditInspectionDutyGroup[];
  equipmentOperatorTaskMarkers: EditOperatorTask[];

  cycleUnits: string[];
  CycleUnits = Common.IntervalUnits;
  comment: string;

  attributesCategories?: any[] = [];
  attributesCategoryNames?: any[] = [];
  categoryArray?: any[] = [];

  isAttributePanelOpen: boolean;

  formSubmitted: boolean;
  originalIsDisabled: boolean;
  isChangedSeriesStart: boolean;
  isChangedActive: boolean;

  bulkInspectionDutyChange: PropertyCore.InspectionDutyChanges;
  storeInspectionDutyChange: any[] = [];
  equipmentPatchFlag = 0;

  attributeType: PropertyCore.AttributeType[];

  private subscriptions: Subscription = new Subscription();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected authService: AuthService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private odataCoreService: ODataCoreService,
    private service: InspectiondutyWizardService,
    private apiService: InspectiondutyWizardApiService
  ) { }

  ngOnInit() {
    this.inputData.installEquipment$ = this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => {
      this.installEquipmentUT = params.get('id');
      return this.apiService.getEquipmentAllData(Guid.parse(params.get('id')));
    }));
    this.currentPage = this.PAGE_DATA;

    this.inputData.equipmentOperatorTaskMarkers$ = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      from(this.odataCoreService.Equipment.Get()
        .Key(Guid.parse(params.get('id')))
        .Expand(x => {
          x.Expand("OperatorTaskMarkers", y => {
            y.Expand("OperatorTask", z => {
              z.Select("Id")
              z.Select("Identity")
              z.Select("Guidelines")
              z.Select("Subject")
              z.Select("Deadline")
              z.Select("Qualification")
              z.Select("Cycle")
              z.Select("CycleUnit")
              z.Select("IsRelevant")
              z.Expand("ModuleTypes")
            })
            y.Filter(z => z
              .Equals(ODataPath.For("OperatorTask", "IsRelevant"), true).Or
              .EqualsField("IsMissing", false))
          }),
            x.Expand("InspectionDuties", y => {
              y.Expand("Schedule", z => {
                z.Select("Identity")
                z.Select("Cycle")
                z.Select("CycleUnit")
                z.Select("CycleStart")
              }),
                y.Expand("OperatorTask", z => {
                  z.Select("Identity")
                  z.Select("Id")
                })
              y.Filter(z => z
                .EqualsField("IsDisabled", false))
            })
        })
        .Select("Identity")
        .Exec()
        .then(x => x.value)))
      , map(id => {
        const activatedInspectionDuties = id[0].InspectionDuties;
        const idg: EditOperatorTask[] = []
        id.forEach(x => {
          let a: number = 0;
          while (a < x.OperatorTaskMarkers.length) {

            let activatedOperatorTaskId = x.OperatorTaskMarkers[a].OperatorTask.Id;

            let activatedInspectionDutyItem =
              activatedInspectionDuties.filter(x => x.OperatorTask.Id == activatedOperatorTaskId).length != 0 ?
                activatedInspectionDuties.filter(x => x.OperatorTask.Id == activatedOperatorTaskId)[0] : null;

            let idgItem;
            idgItem = new EditOperatorTask();
            if(x.OperatorTaskMarkers[a].OperatorTask.ModuleTypes) {
              idgItem.imName = x.OperatorTaskMarkers[a].OperatorTask.ModuleTypes.map(m => m.Name).join(", ");
            }
            idgItem.guideline = x.OperatorTaskMarkers[a].OperatorTask.Guidelines;
            idgItem.deadline = x.OperatorTaskMarkers[a].OperatorTask.Deadline;
            idgItem.subject = x.OperatorTaskMarkers[a].OperatorTask.Subject;
            idgItem.qualification = x.OperatorTaskMarkers[a].OperatorTask.Qualification;
            idgItem.operatortaskId = x.OperatorTaskMarkers[a].OperatorTask.Id;
            idgItem.operatortaskIdentity = x.OperatorTaskMarkers[a].OperatorTask.Identity;
            idgItem.cycle = x.OperatorTaskMarkers[a].OperatorTask.Cycle;
            idgItem.cycleUnit = x.OperatorTaskMarkers[a].OperatorTask.CycleUnit;
            idgItem.isMissing = x.OperatorTaskMarkers[a].IsMissing;

            if (activatedInspectionDutyItem != null) {
              idgItem.aCycle = activatedInspectionDutyItem.Schedule.Cycle;
              idgItem.aCycleUnit = activatedInspectionDutyItem.Schedule.CycleUnit;
              if (activatedInspectionDutyItem.Schedule.CycleStart == null) {
                idgItem.aCycleStart = null;
                idgItem.aCycleStartIsNotEmpty = false;
              } else {
                idgItem.aCycleStart = new Date(activatedInspectionDutyItem.Schedule.CycleStart);
                idgItem.aCycleStartIsNotEmpty = true;
              }
              idgItem.aInspectionDutyIdentity = activatedInspectionDutyItem.Identity;
            } else {
              idgItem.aCycle = null;
              idgItem.aCycleUnit = null;
              idgItem.aCycleStart = null;
              idgItem.aCycleStartIsNotEmpty = false;
              idgItem.aInspectionDutyIdentity = null;
            }

            idg.push(idgItem)
            a++;
          }
        });
        idg.sort((a, b) => {
          const imNameCompare = a.imName ? a.imName.localeCompare(b.imName) : (b.imName ? -b.imName.localeCompare(a.imName) : 0);
          return imNameCompare ? imNameCompare : (a.guideline ? a.guideline.localeCompare(b.guideline) : (b.guideline ? -b.guideline.localeCompare(a.guideline) : 0));
        });
        return idg;
      }));
    this.equipmentPatchFlag = 0;
    this.cycleUnits = this.inputData.cycleUnits;

    this.subscriptions.add(
      combineLatest(
        this.inputData.equipmentOperatorTaskMarkers$,
        this.inputData.installEquipment$,
      ).subscribe(([idg, ie]) => {
        this.equipmentOperatorTaskMarkers = idg;
        this.equipment = ie;

        // reset input data
        this.comment = null;
        this.formSubmitted = false;
        this.categoryArray.length = 0;
        this.attributesCategories.length = 0;
        this.attributesCategoryNames.length = 0;
        this.isAttributePanelOpen = false;

        // load ie attributes
        this.subscriptions.add(from(this.odataCoreService.Equipment.Get()
          .Key(Guid.parse(this.equipment.Identity))
          .Select("Identity", "Name", "AttributeValues")
          .Expand(x =>
            x.Expand("AttributeValues"))
          .Exec()
          .then(x => x.value))
          .pipe(map(res => res[0]))
          .subscribe(res => {

            this.odataCoreService.AttributeType.Query()
              .Expand(x => {
                x.Expand("Set")
                x.Expand("MeasurementUnit")
              })
              .OrderBy("Order", "asc")
              .Exec()
              .then(res1 => {
                let result1 = res1.value;
                let a: number = 0;

                while (result1.length > a) {
                  let attributeItem = res.AttributeValues.find(x => x.Id == result1[a].Id);
                  if (attributeItem) {
                    attributeItem['Description'] = result1[a].Description;
                    attributeItem['Name'] = result1[a].Name;
                    attributeItem['MeasurementUnit'] = result1[a].MeasurementUnit;
                    attributeItem['IsOptional'] = result1[a].IsOptional;
                    attributeItem['AttributeCategory'] = result1[a].Set;
                    attributeItem['ValueType'] = result1[a].ValueType;

                    this.attributesCategories.push(attributeItem);
                    if (attributeItem.Value != null || "") {
                      this.attributesCategoryNames.push(attributeItem['AttributeCategory'].Name);
                    }
                  }
                  a++;
                }
                this.categoryArray = this.attributesCategoryNames.filter((element, index, input) => {
                  if (input.indexOf(element) === index) {
                    return element
                  }
                })


              });

          }));

        this.service.startWizard(this.equipment, this.equipmentOperatorTaskMarkers, this.attributesCategories, this.attributesCategoryNames, this.categoryArray, this.cycleUnits);
      })
    );


    this.showClose = this.inputData.showClose;
    this.showHeader = this.inputData.showHeader;
    this.modelReady = true;

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  processPageChangeRequest(pageChangeEvent: InspectiondutyWizardNavigationPaths): void {

    switch (pageChangeEvent) {

      case InspectiondutyWizardNavigationPaths.DATA: {
        this.currentPage = this.PAGE_DATA;
        break;
      }
      case InspectiondutyWizardNavigationPaths.EXIT: {

      }
      case InspectiondutyWizardNavigationPaths.NEXT: {
        this.currentPage = this.PAGE_OTHEREQUIPMENTS;
        break;
      }
      case InspectiondutyWizardNavigationPaths.PREVIOUS: {
        this.currentPage = this.PAGE_DATA;
        break;
      }
      case InspectiondutyWizardNavigationPaths.OTHEREQUIPMENTS: {
        this.currentPage = this.PAGE_OTHEREQUIPMENTS;
      }

    }
  }

  openInfoDialog() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('EquipmentDetailEditInspection._modal_title');
    modalRef.componentInstance.message = this.translateService.instant('EquipmentDetailEditInspection._modal_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('EquipmentDetailEditInspection._modal_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }

  private mapCycleUnitToEnum(unit: string): Common.IntervalUnits {
    let cycleUnitEnum;
    switch (unit) {
      case Common.IntervalUnits.Undefined:
        cycleUnitEnum = Common.IntervalUnits.Undefined;
        break;
      case Common.IntervalUnits.Year:
        cycleUnitEnum = Common.IntervalUnits.Year;
        break;
      case Common.IntervalUnits.Month:
        cycleUnitEnum = Common.IntervalUnits.Month;
        break;
      case Common.IntervalUnits.Week:
        cycleUnitEnum = Common.IntervalUnits.Week;
        break;
      case Common.IntervalUnits.Day:
        cycleUnitEnum = Common.IntervalUnits.Day;
        break;
    }
    return cycleUnitEnum;
  }

  hasInspectionDutiesActivatedChange() {
    this.hasInspectionDutiesActivatedChanged = true;
  }

  onPanelChange($event: NgbPanelChangeEvent) {
    this.isAttributePanelOpen = $event.nextState;
  }



}
