import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { combineLatest, Subscription } from "rxjs";

import { PropertyCore } from "../../../odata/odata.coreapi";
import { AuthService } from "./../../../auth/auth.service";
import { ContractListInputdata } from "./../contract-list-input-data";
import { MandatorService } from "app/core/shared/services/mandator.service";
import { Mandator } from "app/core/model/mandator";

@Component({
  selector: "app-core-contract-list",
  templateUrl: "./core-contract-list.component.html",
  styleUrls: ["./core-contract-list.component.scss"],
})
export class CoreContractListComponent implements OnInit, OnDestroy {
  @Input() inputData: ContractListInputdata;

  @Output() detailPageRequest = new EventEmitter<
    PropertyCore.MaintenanceContract
  >();
  @Output() wizardRequest = new EventEmitter<void>();

  contracts: PropertyCore.MaintenanceContract[];
  currentContractUT: string;
  public mandator: Mandator;

  searchFilter: string = "";

  private subscriptions: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private mandatorService: MandatorService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      combineLatest(
        this.inputData.contracts$,
        this.inputData.contractUT$,
        this.mandatorService.getCurrentMandatorWithRights$()
      ).subscribe(([c, cut, mandator]) => {
        this.contracts = c;
        this.currentContractUT = cut;
        this.mandator = mandator;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onListItemClick(contract: PropertyCore.MaintenanceContract) {
    this.currentContractUT = contract.Identity;
    this.detailPageRequest.emit(contract);
  }

  contractWizard(): void {
    this.wizardRequest.emit();
  }

  isSelected(UT: string) {
    return this.currentContractUT === UT;
  }
}
