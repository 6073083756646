import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig } from "./../bootstrap/app.config";
import { GaebExport, GaebExportLog, NegativeReport } from "./../model/gaeb";
import { IdName } from "./../model/idName";
import { Utils } from "./../tools/utils";

@Injectable()
export class GlobalService {
  // profile
  private checkPasswordPath = "Account/CheckPassword";
  private changePasswordPath = "Account/ChangePassword";
  private isUserValidPath = "UserInfo/IsValid";
  private registerPath = "Account/Register";

  // mandator
  private invitePath = "Account/Invite";

  // reports

  private tenderObjectGroupsPath = "WebClient/GaebService/ListTenderObjectGroups";
  private gaebExportPath = "WebClient/GaebService/Export";
  private gaebExportLogPath = "WebClient/GaebService/ListExports";

  private appInsights: ApplicationInsights;

  constructor(private httpClient: HttpClient, private config: AppConfig) {
    this.setupAppInsights();
  }

  logException(exception: Error) {
    this.appInsights?.setAuthenticatedUserContext(localStorage.getItem("infoUserUpn"));
    this.appInsights?.trackException({ exception, error: exception });
  }

  checkPassword(email: string, password: string, isDataEncrypted: boolean): Observable<string[]> {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    });
    let options = {
      headers: httpHeaders,
    };
    return this.httpClient.post<string[]>(
      this.config.getLoginHostURL() + this.checkPasswordPath,
      {
        email,
        password,
        isDataEncrypted,
      },

      options
    );
  }

  isUserValid(email: string, isDataEncrypted: boolean): Observable<any> {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    });
    let options = {
      headers: httpHeaders,
    };
    return this.httpClient.post(
      this.config.getLoginHostURL() + this.isUserValidPath,
      {
        email: email,
        isDataEncrypted: isDataEncrypted,
      },
      options
    );
  }

  changePassword(password: string, newPassword: string, email: string, isDataEncrypted: boolean): Observable<any> {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    });
    let options = {
      headers: httpHeaders,
    };
    return this.httpClient.post(
      this.config.getLoginHostURL() + this.changePasswordPath,
      {
        email: email,
        password: password,
        newPassword: newPassword,
        isDataEncrypted: isDataEncrypted,
      },
      options
    );
  }

  register(email: string): Observable<any> {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    });
    let options = {
      headers: httpHeaders,
    };
    return this.httpClient.post(this.config.getLoginHostURL() + this.registerPath, { email: email }, options);
  }

  invite(email: string, buildingIds: string[]): Observable<any> {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    });
    let options = {
      headers: httpHeaders,
    };
    return this.httpClient.post<any>(
      this.config.getLoginHostURL() + this.invitePath,
      { email: email, BuildingIds: buildingIds },
      options
    );
  }

  downloadDocument(url: string): Observable<Blob> {
    return this.httpClient.get(url, { responseType: "blob" });
  }

  getBuildingIdFromUrl() {
    var href = window.location.href;
    return Utils.retrieveBuildingId(href);
  }

  getFacilityIdFromUrl() {
    var href = window.location.href;
    return Utils.retrieveFacilityId(href);
  }

  getTenderObjectGroups(): Observable<IdName[]> {
    return this.httpClient.get<any[]>(this.config.getBaseUrl() + Utils.buildUrl(this.tenderObjectGroupsPath)).pipe(
      map((tenderObjectGroupArray) => {
        return tenderObjectGroupArray.map((tenderObjectGroup) => mapIdNameAPIintoIdName(tenderObjectGroup));
      })
    );
  }
  gaebExport(buildingIds: string[], tenderObjectGroupId: number, zeroPosition: boolean): Observable<GaebExport> {
    return this.httpClient
      .post<any>(this.config.getBaseUrl() + Utils.buildUrl(this.gaebExportPath), {
        UTs: buildingIds,
        TenderObjectGroupId: tenderObjectGroupId,
        Is0PositionExport: !!zeroPosition,
      })
      .pipe(map((gaebExport) => mapGaebExportAPIintoGaebExport(gaebExport)));
  }
  gaebExportLog(): Observable<GaebExportLog[]> {
    return this.httpClient.get<any[]>(this.config.getBaseUrl() + Utils.buildUrl(this.gaebExportLogPath)).pipe(
      map((gaebExportLogArray) => {
        return gaebExportLogArray.map((gaebExportLog) => mapGaebExportLogAPIintoGaebExportLog(gaebExportLog));
      })
    );
  }

  private setupAppInsights(): void {
    var appInsightsKey = this.config.getappInsightsKey();
    if (appInsightsKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appInsightsKey,
          enableCorsCorrelation: true,
        },
      });
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

      var telemetryInitializer = (envelope) => {
        envelope.tags["ai.cloud.role"] = "OB3";
        envelope.tags["ai.cloud.roleInstance"] = "OB3";
      };
      this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }
  }
}

function mapIdNameAPIintoIdName(data: any): IdName {
  const x: IdName = { Id: data.Id, Name: data.Name };
  return x;
}

function mapGaebExportAPIintoGaebExport(data: any): GaebExport {
  const g: GaebExport = new GaebExport();

  g.fileKey = data.FileKey;
  g.negativeReportIEs = data.NegativReportIE.map((nr) => mapNegativeReportAPIintoNegativeReport(nr));
  g.validIECount = data.ValideIECount;
  g.negativeReportAllIECount = data.NegativReportAllIECount;
  g.negativeReportIMs = data.NegativReportIM.map((nr) => mapNegativeReportAPIintoNegativeReport(nr));
  g.validIMCount = data.ValideIMCount;
  g.negativeReportAllIMCount = data.NegativReportAllIMCount;

  return g;
}

function mapNegativeReportAPIintoNegativeReport(data: any): NegativeReport {
  const nr: NegativeReport = new NegativeReport();

  nr.id = data.Id;
  nr.UT = data.UT;
  nr.buildingName = data.BuildingName;
  nr.shortName = data.ShortName;
  nr.longName = data.LongName;
  nr.customId = data.CustomId;
  nr.roomName = data.RoomName;

  return nr;
}

function mapGaebExportLogAPIintoGaebExportLog(data: any): GaebExportLog {
  const gei: GaebExportLog = new GaebExportLog();

  gei.id = data.Id;
  gei.UT = data.UT;
  gei.createDate = Utils.jsonToDate(data.CreateDate);
  gei.lastDownload = Utils.jsonToDate(data.LastDownload);
  gei.is0PositionExport = data.Is0PositionExport;
  gei.sizeInKB = data.SizeInKB;

  return gei;
}
