import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, timer} from 'rxjs';
import {take} from 'rxjs/operators';

import {AuthService} from './../../../core/auth/auth.service';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss']
})
export class AdvancedComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    timer(1).pipe(take(1)).subscribe(res => {
      // unsubscribe from advanced mode has an additional url segment
      const additionalUrl = this.route.snapshot.firstChild.url;
      this.authService.advancedMode = !(additionalUrl && additionalUrl.length);
      this.router.navigate(['/dashboard']);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
