import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, map, Observable, of } from 'rxjs';

import { AppConfig } from './../bootstrap/app.config';

@Injectable()
export class GoogleMapsConfig {
    apiLoaded: Observable<boolean>;
    _apiKey: string;

    constructor(private config: AppConfig, private httpClient: HttpClient) {
    }

    load(): Observable<boolean> {
        if (!this.apiLoaded) {
            this.apiLoaded = this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`, 'callback')
                .pipe(
                    map(() => {
                        return true;
                    }),
                    catchError(() => of(false)),
                );
        }

        return this.apiLoaded;
    }

    get apiKey(): string {
        if (!this._apiKey) {
            this._apiKey = this.config.getGoogleApiKey();
        }
        return this._apiKey;
    }
    set apiKey(apiKey: string) {
        this._apiKey = apiKey;
    }
}
