import {Component, OnInit} from '@angular/core';
import {Common} from '../../../odata/odata.coreapi';
import {ExtendedMaintenanceContractMapping} from '../../../model/extendedMaintenanceContractMapping';


@Component({
  selector: 'app-core-contract-set-maintenance',
  templateUrl: './core-contract-set-maintenance.component.html',
  styleUrls: ['./core-contract-set-maintenance.component.scss']
})
export class CoreContractSetMaintenanceComponent implements OnInit {

  cycleUnits: string[] = Object.keys(Common.IntervalUnits);

  formSubmitted: boolean;

  installEquipment: ExtendedMaintenanceContractMapping;

  constructor() {
  }

  ngOnInit() {
  }

  compareId(val1: { Id: number }, val2: { Id: number }): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    /*
    this.saveContractRequest.emit(this.model);
    this.pageChangeRequest.emit(ContractWizardNavigationPaths.EXIT);
    */
  }

  cancel(isDirty: boolean = true): void {
  }
}
