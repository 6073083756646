import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription, from } from 'rxjs';
import { PropertyCore, Operations } from '../../../odata/odata.coreapi';
import { AuthService } from './../../../auth/auth.service';
import { Selectable } from './../../../model/common';
import { ConfirmationModal } from './../../../popup/confirmation.modal';
import { GlobalService } from './../../../shared/global.service';
import { BuildingEditUsagesInputData } from './../building-edit-usages-input-data';
import { ODataCoreService } from '../../../odata-services/odata.coreapi.service';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from '../../../tools/utils';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-core-building-edit-usages',
  templateUrl: './core-building-edit-usages.component.html',
  styleUrls: ['./core-building-edit-usages.component.scss']
})
export class CoreBuildingEditUsagesComponent implements OnInit, OnDestroy {

  @Input() inputData: BuildingEditUsagesInputData;
  @Input() wizard = false;

  @Output() closeRequest: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() saveRequest: EventEmitter<number[]> = new EventEmitter<number[]>();

  buildingUsageTypes: Selectable<PropertyCore.UsageType>[];

  building: PropertyCore.Building;

  formSubmitted: boolean;

  @ViewChild('helpPopupContent', { static: true })
  helpPopupContent: any;
  buildings: Selectable<PropertyCore.Building>[];
  formSubmitted2: boolean;
  invalid: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    private globalService: GlobalService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      combineLatest(this.inputData.building$, this.inputData.buildingUsageTypes$).subscribe(([b, but]) => {
        this.building = b;
        this.buildingUsageTypes = but;
        this.buildingUsageTypes.forEach(utOfList =>
          b.UsageTypes.forEach(utOfBuilding => (utOfList.Id === utOfBuilding.Id ? (utOfList.selected = true) : null))
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(isDirty: boolean = true): void {
    this.closeRequest.emit(isDirty);
  }

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    const btIds = this.buildingUsageTypes.filter(bt => bt.selected).map(bt => bt.Id);
    this.saveRequest.emit(btIds);
  }

  help2() {
    if (!this.buildings) {
      this.subscriptions.add(from(this.odataCoreService.Building.Query()
        .OrderBy("Name", "asc")
        .Exec().then(x => x.value))
        .pipe(map(res => res.map(i => {
          return Utils.mapAllJSONDatesToDates(i);
        })))
        .subscribe(res => this.buildings = res));
    } else {
      this.selectAll(false);
    }

    this.modalService.open(this.helpPopupContent, { windowClass: 'help-modal' }).result
      .then(val => {
        const buildingIds = this.buildings.filter(b => b.selected).map(b => b.Identity);
        this.odataCoreService.Account.Get().Key(Guid.parse(localStorage.getItem('mandator'))).Actions().SendServiceRequestInOperationsOnAccountInOperations().Parameters(Operations.ServiceRequestTypes.InspectionDutyAnalysis, buildingIds).Execute()
          .then(res => {
          const modalRef = this.modalService.open(ConfirmationModal);
          modalRef.componentInstance.title = this.translateService.instant('RangeOfServices._modal2_title');
          modalRef.componentInstance.message = this.translateService.instant('RangeOfServices._modal2_message');
          modalRef.componentInstance.yesButton = this.translateService.instant('RangeOfServices._modal2_yes');
          modalRef.componentInstance.hideCancelButton = true;
        });
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  selectAll(all: boolean): void {
    this.buildings.forEach(b => {
      b.selected = all;
    });
  }

  validate(closeModal: () => void): void {
    this.invalid = !this.buildings.filter(b => b.selected).length;
    if (this.invalid) {
      this.formSubmitted2 = true;
      return;
    }
    closeModal();
  }

}
