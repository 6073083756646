import { PropertyCore } from '../../../core/odata/odata.coreapi';

export class EquipmentWizardModel {

  buildingId: string;
  buildingName: string;

  equipment?: PropertyCore.EquipmentType;
  name: string;
  comment: string;

  floor?: PropertyCore.Floor;
  room?: PropertyCore.Room;
  isRenterResponsibility: boolean;
  isNoMaintenanceDesired: boolean;
  tuevNo: string;
  qrCodes: PropertyCore.QuickResponseCode[];

  attributesCategories?: any = {};

  reset(): void {
    this.buildingId = null;
    this.buildingName = null;
    this.equipment = null;
    this.name = null;
    this.comment = null;
    this.floor = null;
    this.room = null;
    this.isRenterResponsibility = false;
    this.isNoMaintenanceDesired = false;
    this.tuevNo = null;
    this.qrCodes = [];
    this.attributesCategories = null;
  }
}
