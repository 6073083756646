import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription, from } from 'rxjs';

import { ObjektBrief, Common, PropertyCore } from '../../../core/odata/odata.coreapi';
import { Selectable } from '../../../core/model/common';
import { ExtendedMaintenanceContractMapping } from '../../../core/model/extendedMaintenanceContractMapping';
import { FilterByPipe } from '../../../core/pipes/filter.pipe';
import { Utils } from '../../../core/tools/utils';
import { Guid } from 'guid-typescript';
import { map } from 'rxjs/operators';
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { ODataPath } from '../../../core/odata/odataclient';


@Component({
  selector: 'app-export-maintenance-contract',
  templateUrl: './export-maintenance-contract.component.html',
  styleUrls: ['./export-maintenance-contract.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportMaintenanceContractComponent implements OnInit, OnDestroy {
  @Input()
  title: string;
  contracts: Selectable<PropertyCore.MaintenanceContract>[];
  filteredContracts: Selectable<PropertyCore.MaintenanceContract>[];
  searchFilter: string = '';
  formSubmitted: boolean;
  subscriptions: Subscription = new Subscription();
  private COL_DELIMITER = ';';
  private LINE_DELIMITER = '\r\n';

  constructor(
    private translateService: TranslateService,
    private filterByPipe: FilterByPipe,
    private odataCoreService: ODataCoreService
  ) {
  }

  get selectedCount(): number {
    return this.filteredContracts ? this.filteredContracts.filter(x => x.selected).length : 0;
  }

  ngOnInit() {
    this.subscriptions.add(from(this.odataCoreService.MaintenanceContract.Query()
      .Expand(x => {
        x.Expand("ServiceProvider")
      })
      .OrderBy("Title", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => {
        this.contracts = res;
        this.filteredContracts = this.contracts;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSearchFilterChange(newValue: string) {
    this.searchFilter = newValue;
    this.filteredContracts = this.filterByPipe.transform(this.contracts, this.searchFilter, ['title', 'serviceContact.longName', 'startDate', 'endDate']);
  }

  selectAll(selectAll: boolean): void {
    this.filterByPipe.transform(this.filteredContracts, this.searchFilter, ['title', 'serviceContact.longName', 'startDate', 'endDate']).forEach(contract => contract.selected = selectAll);
  }

  export(validForm: boolean) {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    this.subscriptions.add(forkJoin(...this.filteredContracts.filter(x => x.selected).map(x => forkJoin(
      from(this.odataCoreService.MaintenanceContract.Query()
        .Select("Identity", "Title", "StartDate", "EndDate", "CancellationDigit", "CancellationUnit", "CancellationPeriod", "CancellationCalendar", "AmountNet")
        .Expand(x => {
          x.Expand("ServiceProvider", y => {
            y.Select("Name")
          })
          x.Expand("Jobs", y => {
            y.Expand("Schedule", z => {
              z.Select("Cycle")
              z.Select("CycleUnit")
              z.Select("CycleStart")
            }),
              y.Expand("Equipment", z => {
                z.Select("Name")
                z.Select("CustomId")
                z.Expand("Building", a => {
                  a.Select("Name")
                })
              })
          })
        })
        .Filter(xa =>
          xa.EqualsField("Identity", Guid.parse(x.Identity)))
        .Exec().then(x => x.value))
        .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
    ))).subscribe((...res) => {
      let csv = this.generateCSVHeader();
      res[0].forEach(contractInstallEquipments => {
        csv += this.generateCSV(contractInstallEquipments[0]);
      });
      this.downloadCSV(csv);
    }));
  }

  private generateCSVHeader(): string {
    let result = '';

    // header row
    result += 'Name' + this.COL_DELIMITER;
    result += 'Vertragsbeginn' + this.COL_DELIMITER;
    result += 'Vertragsende' + this.COL_DELIMITER;
    result += 'Gesamtbetrag in €/Jahr (Netto)' + this.COL_DELIMITER;
    result += 'Dienstleister' + this.COL_DELIMITER;
    result += 'Gebäude' + this.COL_DELIMITER;
    result += 'Anlage - Name' + this.COL_DELIMITER;
    result += 'Anlage - Individuelle Bezeichnung' + this.COL_DELIMITER;
    result += 'Intervall' + this.COL_DELIMITER;
    result += 'Zeiteinheit' + this.COL_DELIMITER;
    result += 'Datum nächste Wartung';
    result += this.LINE_DELIMITER;

    return result;
  }

  private generateCSV(contract: PropertyCore.MaintenanceContract): string {

    let result = '';

    // data rows
    let x = 0;
    while (x < contract.Jobs.length) {
      contract.Jobs.forEach(i => {
        result += contract.Title + this.COL_DELIMITER; // Name
        result += (contract.StartDate ? Utils.performPipeOperation('dateFormat', contract.StartDate) : '') + this.COL_DELIMITER; // Vertragsbeginn
        result += (contract.EndDate ? Utils.performPipeOperation('dateFormat', contract.EndDate) : '') + this.COL_DELIMITER; // Vertragsende
        result += (contract.AmountNet ? Utils.performPipeOperation('number', contract.AmountNet) : '') + this.COL_DELIMITER; // Gesamtbetrag in €/Jahr (Netto)
        result += (contract.ServiceProvider ? contract.ServiceProvider.Name : '') + this.COL_DELIMITER; // Dienstleister
        result += i?.Equipment?.Building?.Name + this.COL_DELIMITER; // Gebäude
        result += i?.Equipment?.Name + this.COL_DELIMITER; // Anlage - Name
        result += (i?.Equipment?.CustomId ? i?.Equipment?.CustomId : '') + this.COL_DELIMITER; // Anlage - Individuelle Bezeichnung
        result += (i?.Schedule?.Cycle ? i?.Schedule?.Cycle : '') + this.COL_DELIMITER; // Intervall
        result += (i?.Schedule?.CycleUnit ? this.translateService.instant('CycleUnit.' + i?.Schedule?.CycleUnit) : '') + this.COL_DELIMITER; // Zeiteinheit
        result += (i?.Schedule?.CycleStart ? Utils.performPipeOperation('dateFormat', i?.Schedule?.CycleStart) : ''); // Datum nächste Wartung
        result += this.LINE_DELIMITER;
      });
      x++;
      return result;
    }
  }

  private downloadCSV(csv: string): void {
    const filename = 'Wartungsvertragsexport.csv';
    const blob = new Blob(['\uFEFF', csv], { type: "text/csv;charset=UTF-8;" });
    // if (navigator.msSaveBlob) { // IE 10+
    //   navigator.msSaveBlob(blob, filename);
    // } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //}
  }
}
