import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NgbCarouselConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "angular2-toaster";
import { Subscription, Observable, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { PropertyCore } from "../../core/odata/odata.coreapi";
import { AuthService } from "../../core/auth/auth.service";
import { ConfirmationModal } from "../../core/popup/confirmation.modal";
import { PopupService } from "../../core/popup/popup.service";
import { GlobalService } from "../../core/shared/global.service";
import { InstallEquipmentKpiFilter } from "../kpiFilter.enum";
import { ODataPath } from "../../core/odata/odataclient";
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";
import { Utils } from "../../core/tools/utils";
import { map } from "rxjs/internal/operators/map";
import { AppConfig } from "../../core/bootstrap/app.config";
import { FileItem } from "ng2-file-upload";
import { ImageUploadModel } from "../../core/components/upload/image-upload-model";
import { ImageUploadService } from "../../core/components/upload/image-upload-service";

@Component({
  selector: "app-building-detail",
  templateUrl: "./building-detail.component.html",
  styleUrls: ["./building-detail.component.scss"],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
  encapsulation: ViewEncapsulation.None,
})
export class BuildingDetailComponent implements OnInit, OnDestroy {
  private register: any;
  private response: string;
  imageUploadModel: ImageUploadModel;
  images: PropertyCore.Image[];
  imgIdx = 0;
  building: PropertyCore.Building;
  overDueIncidentsLength = 0;
  nextThreeMonthsIncidentsLength = 0;
  defectIncidentsLength = 0;
  equipmentsNoInspectionDutyAssigned = 0;
  equipmentsInspectionDutyUnterminated = 0;
  equipmentsNoMaintenanceContract = 0;
  equipmentNoMaintenanceDesired = false;
  equipmentsNoDocumentation = 0;
  buildingIdentity: string;

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private config: NgbCarouselConfig,
    private popupService: PopupService,
    private odataCoreService: ODataCoreService,
    private appConfig: AppConfig,
    private imageUploadService: ImageUploadService
  ) {
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
  }

  ngOnInit() {
    this.imageUploadModel = this.imageUploadService.getCurrentImageUploadModel();
    this.imageUploadModel.entityType = "Building";
    this.subscriptions.add(
      this.route.root.firstChild.paramMap
        .pipe(
          switchMap((params: ParamMap) => {
            this.buildingIdentity = params.get("id");
            return from(
              this.odataCoreService.Building.Get()
                .Key(Guid.parse(this.buildingIdentity))
                .Expand((x) => {
                  x.Expand("UsageTypes");
                  x.Expand("Statistics");
                })
                .Exec()
                .then((x) => x.value)
            ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])));
          })
        )
        .subscribe((building) => {
          this.building = building;

          this.subscriptions.add(
            from(
              this.odataCoreService.Event.Query()
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("KpiMarker", "IsPlanned"), true)
                    .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.building.Identity))
                    .And.NotEquals("Type", "Undefined")
                )
                .Count()
                .then((result) => (this.nextThreeMonthsIncidentsLength = result))
            ).subscribe()
          );

          this.subscriptions.add(
            from(
              this.odataCoreService.Event.Query()
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("KpiMarker", "IsOverdue"), true)
                    .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.building.Identity))
                    .And.NotEquals("Type", "Undefined")
                )
                .Count()
                .then((result) => (this.overDueIncidentsLength = result))
            ).subscribe()
          );

          this.subscriptions.add(
            from(
              this.odataCoreService.Event.Query()
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("KpiMarker", "IsDefective"), true)
                    .And.Equals(ODataPath.For("KpiMarker", "IsInFocus"), true)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.building.Identity))
                    .And.NotEquals("Type", "Undefined")
                )
                .Count()
                .then((result) => (this.defectIncidentsLength = result))
            ).subscribe()
          );
        })
    );

    this.subscriptions.add(
      this.route.root.firstChild.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.Building.Get()
                .Key(Guid.parse(params.get("id")))
                .Expand((x) => {
                  x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
                })
                .Exec()
                .then((x) => x.value)
            ).pipe(
              map((res) =>
                res[0].Images.map((img) => {
                  Utils.mapAllJSONDatesToDates(img);
                  img.DownloadToken = this.odataCoreService.Image.Get()
                    .Key(Guid.parse(img.Identity))
                    .Raw()
                    .ToUrlString(false);
                  return img;
                })
              )
            )
          )
        )
        .subscribe((images) => {
          this.images = images;
          this.imgIdx = 0;
        })
    );

    this.subscriptions.add(
      this.route.root.firstChild.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.Equipment.Query()
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("KpiMarker", "IsDocumented"), false)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get("id")))
                    .Or.Equals(ODataPath.For("KpiMarker", "IsDocumented"), false)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), false)
                    .And.EqualsField("IsRenterResponsibility", false)
                    .And.EqualsField("IsNoMaintenanceDesired", true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get("id")))
                )
                .Count()
                .then((result) => (this.equipmentsNoDocumentation = result))
            )
          )
        )
        .subscribe((result) => (this.equipmentsNoDocumentation = result))
    );

    this.subscriptions.add(
      this.route.root.firstChild.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.Equipment.Query()
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("KpiMarker", "IsMaintained"), false)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get("id")))
                )
                .Count()
                .then((result) => (this.equipmentsNoMaintenanceContract = result))
            )
          )
        )
        .subscribe((result) => (this.equipmentsNoMaintenanceContract = result))
    );

    this.subscriptions.add(
      this.route.root.firstChild.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.Equipment.Query()
                .Filter((x) =>
                  x
                    .EqualsField("IsNoMaintenanceDesired", true)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get("id")))
                )
                .Count()
                .then((result) => (this.equipmentsNoMaintenanceContract = result))
            )
          )
        )
        .subscribe((result) => (this.equipmentsNoMaintenanceContract = result))
    );

    this.subscriptions.add(
      this.route.root.firstChild.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.Equipment.Query()
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("KpiMarker", "IsInspectionDutiesAssigned"), false)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get("id")))
                )
                .Count()
                .then((result) => (this.equipmentsNoInspectionDutyAssigned = result))
            )
          )
        )
        .subscribe((result) => (this.equipmentsNoInspectionDutyAssigned = result))
    );

    this.subscriptions.add(
      this.route.root.firstChild.paramMap
        .pipe(
          switchMap((params: ParamMap) =>
            from(
              this.odataCoreService.Equipment.Query()
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("KpiMarker", "HasInspectionDutyUnterminated"), true)
                    .And.Equals(ODataPath.For("KpiMarker", "IsActive"), true)
                    .And.Equals(ODataPath.For("Building", "Identity"), Guid.parse(params.get("id")))
                )
                .Count()
                .then((result) => (this.equipmentsInspectionDutyUnterminated = result))
            )
          )
        )
        .subscribe((result) => (this.equipmentsInspectionDutyUnterminated = result))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onImageUpload(event): void {
    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Get()
          .Key(Guid.parse(this.building.Identity))
          .Expand((x) => {
            x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
          })
          .Exec()
          .then((x) => x.value)
      )
        .pipe(
          map((res) =>
            res[0].Images.map((img) => {
              Utils.mapAllJSONDatesToDates(img);
              img.DownloadToken = this.odataCoreService.Image.Get()
                .Key(Guid.parse(img.Identity))
                .Raw()
                .ToUrlString(false);
              return img;
            })
          )
        )
        .subscribe((res) => {
          this.images = res;
          this.imgIdx = this.images.length - 1;
          this.imageUploadService.resetCurrentImageUploadModel();
          this.imageUploadModel = this.imageUploadService.getCurrentImageUploadModel();
          this.imageUploadModel.entityType = "Building";
        })
    );
  }

  onAddFile(file: FileItem): void {
    this.imageUploadModel.file.push(file);
  }

  nextImg(): void {
    this.imgIdx++;
    if (this.imgIdx === this.images.length) {
      this.imgIdx = 0;
    }
  }

  prevImg(): void {
    this.imgIdx--;
    if (this.imgIdx === -1) {
      this.imgIdx = this.images.length - 1;
    }
  }

  async deleteImg(): Promise<void> {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant("BuildingDetail._deleteImage_modal_title");
    modalRef.componentInstance.message = this.translateService.instant("BuildingDetail._deleteImage_modal_message");
    modalRef.componentInstance.yesButton = this.translateService.instant("BuildingDetail._deleteImage_modal_yes");
    modalRef.componentInstance.cancelButton = this.translateService.instant("BuildingDetail._deleteImage_modal_cancel");

    modalRef.result
      .then(async (val) => {
        this.images[this.imgIdx].Identity;
        if (val === ConfirmationModal.YES_VALUE) {
          await this.odataCoreService.Image.Delete().Key(Guid.parse(this.images[this.imgIdx].Identity)).Exec();
          this.toasterService.pop("info", "", this.translateService.instant("BuildingDetail._deleteImage_success"));
          // reload images after delete
          let res = await this.odataCoreService.Building.Get()
            .Key(Guid.parse(this.building.Identity))
            .Expand((x) => {
              x.Expand("Images");
            })
            .Exec()
            .then((x) => x.value);
          let res2 = res[0].Images.map((img) => {
            Utils.mapAllJSONDatesToDates(img);
            img.DownloadToken = this.odataCoreService.Image.Get()
              .Key(Guid.parse(img.Identity))
              .Raw()
              .ToUrlString(false);
            return img;
          });
          this.images = res2;
          // nav to next image after the deleted one
          this.prevImg();
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  async sortImg(): Promise<void> {
    if (this.images.length == 2) {
      await this.odataCoreService.Image.Get()
        .Key(Guid.parse(this.images[this.imgIdx].Identity))
        .Actions()
        .SortInOperationsOnImageInPropertyCore()
        .Parameters(Guid.createEmpty().toString())
        .Execute();
      await this.odataCoreService.Image.Get()
        .Key(Guid.parse(this.images[this.imgIdx].Identity))
        .Actions()
        .SortInOperationsOnImageInPropertyCore()
        .Parameters(Guid.createEmpty().toString())
        .Execute();
      this.toasterService.pop("info", "", this.translateService.instant("BuildingDetail._sortImage_success"));
      // reload images after sort
      let res = await this.odataCoreService.Building.Get()
        .Key(Guid.parse(this.building.Identity))
        .Expand((x) => {
          x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
        })
        .Exec()
        .then((x) => x.value);
      let res2 = res[0].Images.map((img) => {
        Utils.mapAllJSONDatesToDates(img);
        img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
        return img;
      });
      this.images = res2;
      // nav to first image
      this.imgIdx = 0;
    } else {
      await this.odataCoreService.Image.Get()
        .Key(Guid.parse(this.images[this.imgIdx].Identity))
        .Actions()
        .SortInOperationsOnImageInPropertyCore()
        .Parameters(Guid.createEmpty().toString())
        .Execute();
      this.toasterService.pop("info", "", this.translateService.instant("BuildingDetail._sortImage_success"));
      // reload images after sort
      let res = await this.odataCoreService.Building.Get()
        .Key(Guid.parse(this.building.Identity))
        .Expand((x) => {
          x.Expand("Images", (x) => x.OrderBy("Order", "asc"));
        })
        .Exec()
        .then((x) => x.value);
      let res2 = res[0].Images.map((img) => {
        Utils.mapAllJSONDatesToDates(img);
        img.DownloadToken = this.odataCoreService.Image.Get().Key(Guid.parse(img.Identity)).Raw().ToUrlString(false);
        return img;
      });
      this.images = res2;
      // nav to first image
      this.imgIdx = 0;
    }
  }

  showImg(): void {
    this.popupService.openImagePreviewModal(this.images[this.imgIdx].DownloadToken);
  }

  equipmentWizard(): void {
    this.router.navigate(["building", "wizard", this.building.Identity, "equipment"]);
  }

  usageTypeWizard(): void {
    this.router.navigate([
      "/building",
      this.building.Identity,
      {
        outlets: {
          left: ["list"],
          right: ["building", "edit", "usages", this.building.Address.RegionInfo.Id],
        },
      },
    ]);
  }

  goToEquipmentNoMaintenance(): void {
    this.router.navigate(
      [
        "building",
        this.building.Identity,
        {
          outlets: {
            left: ["list"],
            right: ["building", "view", { outlets: { tab: ["equipment"] } }],
          },
        },
      ],
      {
        queryParams: {
          filter: InstallEquipmentKpiFilter.NoMaintenanceContract.toString(),
        },
        queryParamsHandling: "merge",
      }
    );
  }

  goToEquipmentNoInspectionDuties(): void {
    this.router.navigate(
      [
        "building",
        this.building.Identity,
        {
          outlets: {
            left: ["list"],
            right: ["building", "view", { outlets: { tab: ["equipment"] } }],
          },
        },
      ],
      {
        queryParams: {
          filter: InstallEquipmentKpiFilter.NoInspectionDutySelected.toString(),
        },
        queryParamsHandling: "merge",
      }
    );
  }

  goToEquipmentNotScheduledInspectionDuties(): void {
    this.router.navigate(
      [
        "building",
        this.building.Identity,
        {
          outlets: {
            left: ["list"],
            right: ["building", "view", { outlets: { tab: ["equipment"] } }],
          },
        },
      ],
      {
        queryParams: {
          filter: InstallEquipmentKpiFilter.NoInspectionDutyTerminated.toString(),
        },
        queryParamsHandling: "merge",
      }
    );
  }

  goToTodoInspections(): void {
    this.router.navigate([
      "building",
      this.building.Identity,
      {
        outlets: {
          left: ["list"],
          right: [
            "building",
            "view",
            {
              outlets: {
                tab: [
                  "incident",
                  {
                    outlets: {
                      incidentstyle: ["list", { tab: ["todoFuture"] }],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ]);
  }

  goToOverdueInspections(): void {
    this.router.navigate([
      "/building",
      this.building.Identity,
      {
        outlets: {
          left: ["list"],
          right: [
            "building",
            "view",
            {
              outlets: {
                tab: [
                  "incident",
                  {
                    outlets: { incidentstyle: ["list", { tab: ["overdue"] }] },
                  },
                ],
              },
            },
          ],
        },
      },
    ]);
  }

  goToDefectInspections(): void {
    this.router.navigate([
      "/building",
      this.building.Identity,
      {
        outlets: {
          left: ["list"],
          right: ["building", "view", { outlets: { tab: ["defect"] } }],
        },
      },
    ]);
  }

  goToDocuments(): void {
    this.router.navigate([
      "/building",
      this.building.Identity,
      {
        outlets: {
          left: ["list"],
          right: ["document", { outlets: { tab: ["building-equipment"] } }],
        },
      },
    ]);
  }

  edit(): void {
    this.router.navigate(["/building", "wizard", this.building.Identity]);
  }

  editBuildingUsage(): void {
    this.router.navigate([
      "/building",
      this.building.Identity,
      {
        outlets: {
          left: ["list"],
          right: ["building", "edit", "usages", this.building.Address.RegionInfo.Id],
        },
      },
    ]);
  }

  editRooms(): void {
    this.router.navigate(["/building", this.building.Identity, { outlets: { left: ["room-list"] } }]);
  }

  editFloors(): void {
    this.router.navigate([
      "/building",
      this.building.Identity,
      {
        outlets: {
          left: ["list"],
          right: ["building", "edit", "floors"],
        },
      },
    ]);
  }
}
