import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor() {}

  public saveAs(data: Blob, fileName: string): void {
    FileSaver.saveAs(data, fileName);
  }
}
