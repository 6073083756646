export {}; // this file needs to be a module

Array.prototype.sortBy = function (propGetter: (item: any) => any): [] {
  return this.sort((a, b) => {
    if (propGetter(a) < propGetter(b)) {
      return -1;
    }
    if (propGetter(a) > propGetter(b)) {
      return 1;
    }
    return 0;
  });
};

// Array.prototype.sortDescBy = function (propGetter: (item: any) => any): [] {
//   return this.sortBy(propGetter).reverse();
// };
