import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AuthGuard} from '../core/auth/auth-guard.service';
import {CoreModule} from '../core/core.module';
import {ServiceContactDetailComponent} from './service-contact-detail/service-contact-detail.component';
import {ServiceContactListComponent} from './service-contact-list/service-contact-list.component';
import {ServiceContactComponent} from './service-contact/service-contact.component';
import {StartComponent} from './start/start.component';
import {ServiceContactWizardComponent} from './wizard/service-contact/service-contact-wizard.component';


const routes: Routes = [
  {
    path: 'service-contact',
    component: StartComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ServiceContactListComponent
      }
    ]
  },
  {
    path: 'service-contact/:id',
    component: ServiceContactComponent,
    canActivateChild: [AuthGuard],
    children: [

      // 1 level page setup
      {
        outlet: 'left',
        path: 'list',
        component: ServiceContactListComponent,
        data: {
          animation: '1-service-contact-list'
        }
      },
      {
        outlet: 'right',
        path: 'service-contact',
        component: ServiceContactDetailComponent,
        data: {
          animation: '1-service-contact'
        }
      },
      {
        outlet: 'right',
        path: 'service-contact-wizard/edit',
        component: ServiceContactWizardComponent,
        data: {
          editMode: true,
          animation: '1-service-contact'
        }
      },
      {
        outlet: 'right',
        path: 'service-contact-wizard',
        component: ServiceContactWizardComponent,
        data: {
          animation: '1-service-contact'
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    NgbModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    StartComponent,
    ServiceContactComponent,
    ServiceContactListComponent,
    ServiceContactDetailComponent,
    ServiceContactWizardComponent
  ],
  exports: [
    RouterModule,
  ],
  providers: []
})
export class ServiceContactModule {
}
