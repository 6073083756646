import { Pipe, PipeTransform } from "@angular/core";

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
*/
@Pipe({ name: "fileSize" })
export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];

  transform(kbytes: number = 0, precision: number = 1): string {
    if (isNaN(parseFloat(String(kbytes))) || !isFinite(kbytes)) {
      return '-';
    }

    const bytes = kbytes * 1024;
    const number = Math.floor(Math.log(bytes) / Math.log(1024));

    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + this.units[number];
  }
}
