import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileItem } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { IdName } from './../../../../core/model/idName';
import { ConfirmationModal } from './../../../../core/popup/confirmation.modal';
import { DocumentType, DocumentWizardModel } from './../document-wizard-model';
import { DocumentWizardNavigationPaths } from './../document-wizard-navigation-paths.enum';
import { DocumentWizardService } from './../document-wizard.service';


@Component({
  selector: 'app-document-wizard-data',
  templateUrl: './document-wizard-data.component.html',
  styleUrls: ['./document-wizard-data.component.scss']
})
export class DocumentWizardDataComponent implements OnInit, OnDestroy {

  model: DocumentWizardModel;

  types: IdName[] = [];

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<DocumentWizardNavigationPaths>();

  constructor(
    private service: DocumentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.model = this.service.getCurrentDocument();

    for (const enumValue in DocumentType) {
      if (typeof DocumentType[enumValue] === 'number') {
        this.types.push({ Id: +DocumentType[enumValue], Name: enumValue });
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    // convert string value to number value
    this.model.type = +this.model.type;
    this.pageChangeRequest.emit(DocumentWizardNavigationPaths.NEXT);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('DocumentWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('DocumentWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('DocumentWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('DocumentWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentDocument();
            this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        }
        );
    } else {
      this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
    }
  }

  onVirtualChange(virtual: boolean): void {
    this.model.virtual = virtual;

    // reset data which depends on virtual
    this.model.file = null;
    this.model.name = null;

    this.formSubmitted = false;
  }

  onTypeChange(type: DocumentType): void {
    this.model.type = +type; // for sure convert string to number

    // reset data which depends on type
    this.model.DocumentType = null;
    this.model.equipments = [];
    this.model.incidents = [];
  }

  onAddFile(file: FileItem): void {
    this.model.file = file;
  }

  onRemoveFile(): void {
    this.model.file = null;
  }

}
