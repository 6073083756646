import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription, from} from 'rxjs';

import { Utils } from '../../core/tools/utils';
import { map } from 'rxjs/operators';
import { ODataCoreService } from "../../core/odata-services/odata.coreapi.service";

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  constructor(
    private odataCoreService: ODataCoreService,
    private router: Router) {
  }

  ngOnInit() {
    this.subscriptions.add(from(this.odataCoreService.MaintenanceContract.Query()
      .Expand(x => {
        x.Expand("ServiceProvider")
      })
      .OrderBy("Title", "asc")
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i)))).subscribe(res => {
      if (res.length >= 1) {
        this.router.navigate(['/contract', res[0].Identity, {outlets: {left: ['list'], right: ['contract']}}]);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
