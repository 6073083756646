import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Observable, of, Subject, Subscription, from } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";

import { PropertyCore } from "../../../core/odata/odata.coreapi";
import { ConfirmationModal } from "./../../../core/popup/confirmation.modal";
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { ODataPath } from "../../../core/odata/odataclient";
import { Utils } from "../../../core/tools/utils";
import { Guid } from "guid-typescript";

@Component({
  selector: "app-equipment-detail-edit",
  templateUrl: "./equipment-detail-edit.component.html",
  styleUrls: ["./equipment-detail-edit.component.scss"],
})
export class EquipmentDetailEditComponent implements OnInit, OnDestroy {
  origInstallEquipment: PropertyCore.Equipment;
  installEquipment: PropertyCore.Equipment;
  installEquipmentFloor: PropertyCore.Floor;
  equipment$: Observable<PropertyCore.Equipment>;

  floors: PropertyCore.Floor[];
  rooms: PropertyCore.Room[];

  newRoomName: string;

  buildingUT: string;

  qrCodes: PropertyCore.QuickResponseCode[];
  qrCodeState: PropertyCore.QuickResponseCode;
  newQrCode = new Subject<string>();

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.root.firstChild.paramMap.subscribe((params) => (this.buildingUT = params.get("id")))
    );

    const installEquipmentUT$: Observable<string> = this.route.parent.paramMap.pipe(map((params) => params.get("id")));

    const floors$: Observable<PropertyCore.Floor[]> = installEquipmentUT$.pipe(
      switchMap((val) =>
        from(
          this.odataCoreService.Equipment.Get()
            .Key(Guid.parse(val))
            .Expand((x) => {
              x.Expand("Building", (y) => {
                y.Expand("Floors");
              });
            })
            .Exec()
            .then((x) => x.value)
        ).pipe(map((res) => res[0].Building.Floors.map((f) => Utils.mapAllJSONDatesToDates(f))))
      )
    );

    const equipment$: Observable<PropertyCore.Equipment> = installEquipmentUT$.pipe(
      switchMap((ut) =>
        from(
          this.odataCoreService.Equipment.Query()
            .Expand((x) => {
              x.Expand("Building"),
                x.Expand("QuickResponseCodes"),
                x.Expand("EquipmentType"),
                x.Expand("Room", (y) => y.Expand("Floor"));
            })
            .Filter((x) => x.EqualsField("Identity", Guid.parse(ut)))
            .Exec()
            .then((x) => x.value)
        ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])))
      )
    );

    this.subscriptions.add(
      combineLatest([installEquipmentUT$, equipment$, floors$]).subscribe(([_equipmentUT, _equipment, _floors]) => {
        this.origInstallEquipment = Object.assign({}, _equipment);
        this.installEquipment = _equipment;
        this.installEquipmentFloor = this.installEquipment.Room.Floor;
        this.floors = _floors;
        if (this.installEquipmentFloor) {
          this.subscriptions.add(
            from(
              this.odataCoreService.Room.Query()
                .Expand((x) => {
                  x.Expand("Floor");
                  x.Expand("Statistics");
                })
                .Filter((x) =>
                  x
                    .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.installEquipment.Building.Identity))
                    .And.Equals(ODataPath.For("Floor", "Id"), this.installEquipmentFloor.Id)
                )
                .OrderBy("Name", "asc")
                .Exec()
                .then((x) => x.value)
            )
              .pipe(map((res) => res.map((i) => Utils.mapAllJSONDatesToDates(i))))
              .subscribe((res) => (this.rooms = res))
          );
        }

        this.getQrCodes();
      })
    );

    this.newQrCode
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) =>
          term
            ? from(
                this.odataCoreService.QuickResponseCode.Query()
                  .Filter((x) => x.EqualsField("Id", +term))
                  .Expand((x) => {
                    x.Expand("Equipments");
                  })
                  .Exec()
                  .then((x) => x.value)
              )
                .pipe(map((res) => res.map((i) => Utils.mapAllJSONDatesToDates(i))))
                .pipe(map((res) => (res && res.length ? res[0] : <PropertyCore.QuickResponseCode>{})))
            : of(<PropertyCore.QuickResponseCode>null)
        )
      )
      .subscribe((res) => (this.qrCodeState = res));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getQrCodes(): void {
    let i: number = 0;
    if (this.installEquipment.QuickResponseCodes) {
      while (i < this.installEquipment.QuickResponseCodes.length) {
        this.subscriptions.add(
          from(
            this.odataCoreService.QuickResponseCode.Query()
              .Filter((x) => x.EqualsField("Id", this.installEquipment.QuickResponseCodes[i].Id))
              .Exec()
              .then((x) => x.value)
          )
            .pipe(map((res) => res.map((res2) => Utils.mapAllJSONDatesToDates(res2))))
            .subscribe((res) => (this.qrCodes = res))
        );
        i++;
      }
    }
  }

  getImgPath(qrCodeId: number): string {
    return this.odataCoreService.QuickResponseCode.Get().Key(qrCodeId).Raw().ToUrlString(false);
  }

  close(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant("EquipmentDetailEdit._modal_title");
      modalRef.componentInstance.message = this.translateService.instant("EquipmentDetailEdit._modal_message");
      modalRef.componentInstance.yesButton = this.translateService.instant("EquipmentDetailEdit._modal_yes");
      modalRef.componentInstance.cancelButton = this.translateService.instant("EquipmentDetailEdit._modal_cancel");

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate(
              [
                "/building",
                this.buildingUT,
                {
                  outlets: {
                    left: ["building", "view", { outlets: { tab: ["equipment"] } }],
                    right: ["equipment", this.installEquipment.Identity],
                  },
                },
              ],
              { queryParamsHandling: "merge" }
            );
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate(
        [
          "/building",
          this.buildingUT,
          {
            outlets: {
              left: ["building", "view", { outlets: { tab: ["equipment"] } }],
              right: ["equipment", this.installEquipment.Identity],
            },
          },
        ],
        { queryParamsHandling: "merge" }
      );
    }
  }

  async save(validForm: boolean): Promise<void> {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    if (this.installEquipment.Room == null) {
      await this.odataCoreService.Equipment.Patch()
        .ValueType(this.odataCoreService.ODataTypes().Equipment())
        .Key(Guid.parse(this.installEquipment.Identity))
        .ValueProperty("TuevNo", this.installEquipment.TuevNo)
        .ValueProperty("Comment", this.installEquipment.Comment)
        .ValueProperty("IsRenterResponsibility", this.installEquipment.IsRenterResponsibility)
        .ValueProperty("IsNoMaintenanceDesired", this.installEquipment.IsNoMaintenanceDesired)
        .ValueProperty("CustomId", this.installEquipment.CustomId)
        .ValuePropertyBinding(
          "EquipmentType",
          this.odataCoreService.EquipmentType.Get().Key(this.installEquipment.EquipmentType.Code).Bind()
        )
        .ValuePropertyBinding(
          "Building",
          this.odataCoreService.Building.Get().Key(Guid.parse(this.installEquipment.Building.Identity)).Bind()
        )
        .Exec();
    } else {
      await this.odataCoreService.Equipment.Patch()
        .ValueType(this.odataCoreService.ODataTypes().Equipment())
        .Key(Guid.parse(this.installEquipment.Identity))
        .ValueProperty("TuevNo", this.installEquipment.TuevNo)
        .ValueProperty("Comment", this.installEquipment.Comment)
        .ValueProperty("IsRenterResponsibility", this.installEquipment.IsRenterResponsibility)
        .ValueProperty("IsNoMaintenanceDesired", this.installEquipment.IsNoMaintenanceDesired)
        .ValueProperty("CustomId", this.installEquipment.CustomId)
        .ValuePropertyBinding(
          "Room",
          this.odataCoreService.Room.Get().Key(Guid.parse(this.installEquipment.Room.Identity)).Bind()
        )
        .ValuePropertyBinding(
          "EquipmentType",
          this.odataCoreService.EquipmentType.Get().Key(this.installEquipment.EquipmentType.Code).Bind()
        )
        .ValuePropertyBinding(
          "Building",
          this.odataCoreService.Building.Get().Key(Guid.parse(this.installEquipment.Building.Identity)).Bind()
        )
        .Exec();
    }
    this.router.navigate(
      [
        "/building",
        this.buildingUT,
        {
          outlets: {
            left: ["building", "view", { outlets: { tab: ["equipment"] } }],
            right: ["equipment", this.installEquipment.Identity],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  async onFloorChange(selectedFloor: PropertyCore.Floor): Promise<void> {
    this.installEquipmentFloor = selectedFloor;
    this.installEquipment.Room = null;
    if (selectedFloor) {
      let res = await this.odataCoreService.Room.Query()
        .Expand((x) => {
          x.Expand("Floor");
          x.Expand("Statistics");
        })
        .Filter((x) =>
          x
            .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.installEquipment.Building.Identity))
            .And.Equals(ODataPath.For("Floor", "Id"), selectedFloor.Id)
        )
        .OrderBy("Name", "asc")
        .Exec()
        .then((x) => x.value);
      this.rooms = res.map((i) => Utils.mapAllJSONDatesToDates(i));
    }
  }

  findRoom(rooms: PropertyCore.Room[], roomName: string): PropertyCore.Room {
    let room = rooms.find((x) => x.Name == roomName);

    return room;
  }

  async openNewRoomPopup(content): Promise<void> {
    this.modalService
      .open(content)
      .result.then(async (result) => {
        // on save click
        let res = await this.odataCoreService.Room.Post()
          .ValueType(this.odataCoreService.ODataTypes().Room())
          .ValueProperty("Name", this.newRoomName)
          .ValuePropertyBinding("Floor", this.odataCoreService.Floor.Get().Key(this.installEquipmentFloor.Id).Bind())
          .ValuePropertyBinding(
            "Building",
            this.odataCoreService.Building.Get().Key(Guid.parse(this.installEquipment.Building.Identity)).Bind()
          )
          .Exec();
        this.installEquipment.Room = <PropertyCore.Room>{
          Id: res,
          Name: this.newRoomName,
          Floor: this.installEquipmentFloor,
        };
        this.rooms.push(this.installEquipment.Room);
        let res2 = await this.odataCoreService.Room.Query()
          .Expand((x) => {
            x.Expand("Floor");
            x.Expand("Statistics");
          })
          .Filter((x) =>
            x
              .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.installEquipment.Building.Identity))
              .And.Equals(ODataPath.For("Floor", "Id"), this.installEquipmentFloor.Id)
          )
          .OrderBy("Name", "asc")
          .Exec()
          .then((x) => x.value);
        this.rooms = res2.map((i) => Utils.mapAllJSONDatesToDates(i));
        if (this.newRoomName) {
          let newRoomNameSaved = this.newRoomName;

          this.installEquipment.Room = this.findRoom(this.rooms, newRoomNameSaved);
        }
        this.newRoomName = null;
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  compareId(val1: { Id: number }, val2: { Id: number }): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  searchQrCode(newQrCode: string) {
    this.newQrCode.next(newQrCode.trim());
  }

  async assignQrCode(qrCode: string): Promise<void> {
    await this.odataCoreService.Equipment.Link()
      .Key(Guid.parse(this.installEquipment.Identity))
      .Value("QuickResponseCodes", this.odataCoreService.QuickResponseCode.Get().Key(+qrCode).Bind())
      .Post();
    this.getQrCodes();
    this.qrCodeState = null;
  }

  isAllowedToAssignQrCode(): boolean {
    return this.qrCodeState && !!this.qrCodeState.Id;
  }

  deleteQrCode(qrCode: PropertyCore.QuickResponseCode): void {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant("EquipmentDetailEdit._delete_qrcode_title");
    modalRef.componentInstance.message = this.translateService.instant("EquipmentDetailEdit._delete_qrcode_message", {
      qrCode: "QR" + qrCode.Id,
    });
    modalRef.componentInstance.yesButton = this.translateService.instant("EquipmentDetailEdit._modal_delete");
    modalRef.componentInstance.cancelButton = this.translateService.instant("EquipmentDetailEdit._modal_cancel");

    modalRef.result
      .then(async (val) => {
        if (val === ConfirmationModal.YES_VALUE) {
          await this.odataCoreService.Equipment.Link()
            .Key(Guid.parse(this.installEquipment.Identity))
            .Value("QuickResponseCodes", this.odataCoreService.QuickResponseCode.Get().Key(qrCode.Id).Bind())
            .Delete();
          const idx = this.qrCodes.indexOf(qrCode);
          if (idx > -1) {
            this.qrCodes.splice(idx, 1);
          }
        }
      })
      .catch(() => {
        // do nothing, just stay on page
      });
  }

  openInfoDialog() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant("EquipmentWizData._modal_title");
    modalRef.componentInstance.message = this.translateService.instant("EquipmentWizData._modal_message");
    modalRef.componentInstance.yesButton = this.translateService.instant("EquipmentWizData._modal_yes");
    modalRef.componentInstance.hideCancelButton = true;
  }

  openInfoDialog2() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant("EquipmentDetailEdit._modal_qrcode_title");
    modalRef.componentInstance.message = this.translateService.instant("EquipmentDetailEdit._modal_qrcode_message");
    modalRef.componentInstance.yesButton = this.translateService.instant("EquipmentDetailEdit._modal_qrcode_yes");
    modalRef.componentInstance.hideCancelButton = true;
  }

  openInfoDialog3() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant("EquipmentWizData._modal_customName_title");
    modalRef.componentInstance.message = this.translateService.instant("EquipmentWizData._modal_customName_message");
    modalRef.componentInstance.yesButton = this.translateService.instant("EquipmentWizData._modal_customName_yes");
    modalRef.componentInstance.hideCancelButton = true;
  }
}
