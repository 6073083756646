import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subscription, from } from "rxjs";
import { PropertyCore } from "../../../core/odata/odata.coreapi";
import { Selectable } from "./../../../core/model/common";
import { InspectionDuty } from "./../../../core/model/inspectionDuty";
import { FilterByPipe } from "./../../../core/pipes/filter.pipe";
import { Utils } from "./../../../core/tools/utils";
import { ODataCoreService } from "../../../core/odata-services/odata.coreapi.service";
import { Guid } from "guid-typescript";
import { map } from "rxjs/internal/operators/map";

@Component({
  selector: "app-export-inspection-duty",
  templateUrl: "./export-inspection-duty.component.html",
  styleUrls: ["./export-inspection-duty.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ExportInspectionDutyComponent implements OnInit, OnDestroy {
  @Input()
  title: string;
  buildings: Selectable<PropertyCore.Building>[];
  filteredBuildings: Selectable<PropertyCore.Building>[];
  searchFilter: string = "";
  formSubmitted: boolean;
  subscriptions: Subscription = new Subscription();
  private COL_DELIMITER = ";";
  private LINE_DELIMITER = "\r\n";

  constructor(
    private translateService: TranslateService,
    private filterByPipe: FilterByPipe,
    private odataCoreService: ODataCoreService
  ) { }

  get selectedCount(): number {
    return this.filteredBuildings ? this.filteredBuildings.filter((x) => x.selected).length : 0;
  }

  ngOnInit() {
    this.subscriptions.add(
      from(
        this.odataCoreService.Building.Query()
          .OrderBy("Name", "asc")
          .Exec()
          .then((x) => x.value)
      )
        .pipe(
          map((res) =>
            res.map((i) => {
              return Utils.mapAllJSONDatesToDates(i);
            })
          )
        )
        .subscribe((res) => {
          this.buildings = res;
          this.filteredBuildings = this.buildings;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSearchFilterChange(newValue: string) {
    this.searchFilter = newValue;
    this.filteredBuildings = this.filterByPipe.transform(
      this.buildings,
      this.searchFilter,
      ["Name", "Address.Street", "Address.PostCode", "Address.City"]
    );
  }

  selectAll(selectAll: boolean): void {
    // this.filterByPipe
    //   .transform(this.filteredBuildings, this.searchFilter, [
    //     "Name",
    //     "Address.Street",
    //     "Address.PostCode",
    //     "Address.City",
    //   ])
    //   .forEach((building) => (building.selected = selectAll));
    this.filteredBuildings.forEach((building) => (building.selected = selectAll));
  }

  export(validForm: boolean) {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    const ids = this.filteredBuildings.filter((x) => x.selected).map((x) => x.Identity);
    const observables = [];
    ids.forEach((id) =>
      observables.push(
        combineLatest(
          from(
            this.odataCoreService.Building.Query()
              .Select("Identity", "Name", "Address")
              .Expand((x) => {
                x.Expand("Equipments", (y) => {
                  y.Select("Id");
                  y.Select("Identity");
                  y.Select("CustomId");
                  y.Select("Name");
                  y.Select("HasInspectionDutiesActivated");
                  y.Expand("OperatorTaskMarkers", (z) => {
                    z.Expand("OperatorTask");
                  });
                  y.Expand("InspectionDuties", (z) => {
                    z.Select("IsDisabled");
                    z.Expand("Module", (a) => {
                      a.Select("Id");
                      a.Select("CustomId");
                      a.Select("Name");
                    });
                    z.Expand("OperatorTask", (a) => {
                      a.Select("Guidelines");
                      a.Select("Subject");
                      a.Select("Qualification");
                    });
                    z.Expand("Schedule", (a) => {
                      a.Select("Cycle");
                      a.Select("CycleUnit");
                      a.Select("CycleStart");
                    });
                  });
                });
              })
              .Filter((x) => x.EqualsField("Identity", Guid.parse(id)))
              .Exec()
              .then((x) => x.value)
          ).pipe(map((res) => Utils.mapAllJSONDatesToDates(res[0])))
        )
      )
    );
    this.subscriptions.add(
      combineLatest(...observables).subscribe((...res) => {
        let csv = this.generateCSVHeader();
        res[0].forEach((buildingInspectionDuties) => {
          csv += this.generateCSV(buildingInspectionDuties[0]);
        });
        this.downloadCSV(csv);
      })
    );
  }

  private generateCSVHeader(): string {
    let result = "";

    // header row
    result += "Gebäude" + this.COL_DELIMITER;
    result += "Land" + this.COL_DELIMITER;
    result += "Bundesland" + this.COL_DELIMITER;
    result += "Zip" + this.COL_DELIMITER;
    result += "Stadt" + this.COL_DELIMITER;
    result += "Adresse" + this.COL_DELIMITER;
    result += "Anlage ID" + this.COL_DELIMITER;
    result += "Name" + this.COL_DELIMITER;
    //result += "Bauteil" + this.COL_DELIMITER;
    //result += 'Baugruppe ID' + this.COL_DELIMITER;
    //result += 'Baugruppe' + this.COL_DELIMITER;
    result += "Regelwerk" + this.COL_DELIMITER;
    result += "Prüfobjekt" + this.COL_DELIMITER;
    result += "Qualifikation" + this.COL_DELIMITER;
    result += "Intervall" + this.COL_DELIMITER;
    result += "Einheit" + this.COL_DELIMITER;
    result += "Pflicht aktiv" + this.COL_DELIMITER;
    result += "Prüfpflichtenaktivierung abgeschlossen" + this.COL_DELIMITER;
    result += "Serienstart";
    result += this.LINE_DELIMITER;

    return result;
  }

  private generateCSV(building: PropertyCore.Building): string {
    let result = "";

    // data rows
    let x = 0;
    while (x < building.Equipments.length) {
      building.Equipments[x].InspectionDuties.forEach((i) => {
        result += building.Name + this.COL_DELIMITER; // Gebäude
        result += building.Address.CountryInfo.Name + this.COL_DELIMITER; // Land
        result += building.Address.RegionInfo.Name + this.COL_DELIMITER; // Bundesland
        result += building.Address.PostCode + this.COL_DELIMITER; // Zip
        result += building.Address.City + this.COL_DELIMITER; // Stadt
        result +=
          building.Address.Street +
          " " +
          building.Address.No +
          this.COL_DELIMITER; // Adresse
        result +=
          (building?.Equipments[x] ? building?.Equipments[x]?.Id : "") +
          this.COL_DELIMITER; // Anlage ID
        result +=
          (building?.Equipments[x]
            ? building?.Equipments[x]?.CustomId
              ? building?.Equipments[x]?.CustomId
              : building?.Equipments[x]?.Name
            : "") + this.COL_DELIMITER; // Name
        // Momentan beim Speichern von Inspection Duties wird das Modul gar nicht gesetzt
        //result += (i?.Module ? "Baugruppe" : "Anlage") + this.COL_DELIMITER; // Bauteil
        //result += (i?.Module ? i?.Module?.Id : "") + this.COL_DELIMITER; // Baugruppe ID
        // result +=
        //   (i?.Module
        //     ? i?.Module?.CustomId
        //       ? i?.Module?.CustomId
        //       : i?.Module?.Name
        //     : "") + this.COL_DELIMITER; // Baugruppe
        result += i?.OperatorTask?.Guidelines + this.COL_DELIMITER; // Regelwerk
        result += i?.OperatorTask?.Subject + this.COL_DELIMITER; // Prüfobjekt
        result += i?.OperatorTask?.Qualification + this.COL_DELIMITER; // Qualifikation
        result +=
          (i?.Schedule?.Cycle ? i?.Schedule?.Cycle : 0) + this.COL_DELIMITER; // Intervall
        result +=
          (i?.Schedule?.CycleUnit
            ? this.translateService.instant(
              "CycleUnit." + i?.Schedule?.CycleUnit
            )
            : this.translateService.instant("CycleUnit.NotSpecified")) +
          this.COL_DELIMITER; // Einheit
        result += (i?.IsDisabled ? "Nein" : "Ja") + this.COL_DELIMITER; // Pflicht aktiv
        result +=
          (building?.Equipments[x] &&
            building?.Equipments[x]?.HasInspectionDutiesActivated
            ? "Ja"
            : "Nein") + this.COL_DELIMITER; // Prüfpflichtenaktivierung abgeschlossen
        result += i?.Schedule?.CycleStart
          ? Utils.performPipeOperation("dateFormat", i?.Schedule?.CycleStart)
          : ""; // Serienstart
        result += this.LINE_DELIMITER;
      });
      x++;
    }

    // data rows

    x = 0;
    while (x < building.Equipments.length) {
      let y = 0;
      while (y < building.Equipments[x].OperatorTaskMarkers.length) {
        building.Equipments[x].OperatorTaskMarkers = building.Equipments[
          x
        ].OperatorTaskMarkers.filter(
          (x) => x.IsMissing == true && x.OperatorTask.IsRelevant == true
        );

        if (building.Equipments[x].OperatorTaskMarkers.length != 0) {
          result += building.Name + this.COL_DELIMITER; // Gebäude
          result += building.Address.CountryInfo.Name + this.COL_DELIMITER; // Land
          result += building.Address.RegionInfo.Name + this.COL_DELIMITER; // Bundesland
          result += building.Address.PostCode + this.COL_DELIMITER; // Zip
          result += building.Address.City + this.COL_DELIMITER; // Stadt
          result +=
            building.Address.Street +
            " " +
            building.Address.No +
            this.COL_DELIMITER; // Adresse
          result +=
            (building?.Equipments[x] ? building?.Equipments[x]?.Id : "") +
            this.COL_DELIMITER; // Anlage ID
          result +=
            (building?.Equipments[x]
              ? building?.Equipments[x]?.CustomId
                ? building?.Equipments[x]?.CustomId
                : building?.Equipments[x]?.Name
              : "") + this.COL_DELIMITER; // Name
          // result += "Anlage" + this.COL_DELIMITER; // Bauteil
          // result += "" + this.COL_DELIMITER; // Baugruppe ID
          // result += "" + this.COL_DELIMITER; // Baugruppe
          result +=
            building.Equipments[x].OperatorTaskMarkers[y]?.OperatorTask
              ?.Guidelines + this.COL_DELIMITER; // Regelwerk
          result +=
            building?.Equipments[x].OperatorTaskMarkers[y]?.OperatorTask
              ?.Subject + this.COL_DELIMITER; // Prüfobjekt
          result +=
            building.Equipments[x].OperatorTaskMarkers[y]?.OperatorTask
              ?.Qualification + this.COL_DELIMITER; // Qualifikation
          result +=
            (building.Equipments[x].OperatorTaskMarkers[y]?.OperatorTask?.Cycle
              ? building.Equipments[x].OperatorTaskMarkers[y]?.OperatorTask
                ?.Cycle
              : 0) + this.COL_DELIMITER; // Intervall
          result +=
            (building.Equipments[x].OperatorTaskMarkers[y]?.OperatorTask
              ?.CycleUnit
              ? this.translateService.instant(
                "CycleUnit." +
                building.Equipments[x].OperatorTaskMarkers[y]?.OperatorTask
                  ?.CycleUnit
              )
              : this.translateService.instant("CycleUnit.NotSpecified")) +
            this.COL_DELIMITER; // Einheit
          result +=
            (building.Equipments[x].OperatorTaskMarkers[y]?.IsMissing
              ? "Nein"
              : "Ja") + this.COL_DELIMITER; // Pflicht aktiv
          result +=
            (building?.Equipments[x] &&
              building?.Equipments[x]?.HasInspectionDutiesActivated
              ? "Ja"
              : "Nein") + this.COL_DELIMITER; // Prüfpflichtenaktivierung abgeschlossen
          result += ""; // Serienstart
          result += this.LINE_DELIMITER;
        }

        y++;
      }
      x++;
    }

    return result;
  }

  private downloadCSV(csv: string): void {
    const filename = "Pflichtenexport.csv";
    const blob = new Blob(["\uFEFF", csv], { type: "text/csv;charset=UTF-8;" });
    // if (navigator.msSaveBlob) {
    //   // IE 10+
    //   navigator.msSaveBlob(blob, filename);
    // } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    //}
  }
}
