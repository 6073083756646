import { animate, AnimationMetadata, group, query, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import { ILogger, LoggerService } from './../../core/shared/logger.service';

const leftContentMoveToLeft = [
  query(':leave .outlet', style({ transform: 'translate3d(0%,0,0)' }), { optional: true }),
  query(':enter .outlet', style({ 'z-index': 2, transform: 'translate3d(100%,0,0)' }), { optional: true }), // entering panel must be over the leaving from right side in order to routerLinkActive to work
  query(':leave .outlet *[name="tabs"] + *', style({ opacity: 1 }), { optional: true }),

  group([
    query(':leave .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(-100%,0,0)' })), { optional: true }),
    query(':enter .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(0%,0,0)' })), { optional: true }),
    query(':leave .outlet *[name="tabs"] + *',
      animate('0ms 600ms', style({ opacity: 0 })), { optional: true }) //'tabs' router outlet should be visible until the end of animation
  ])
];

const leftContentMoveToRight = [
  query(':leave .outlet', style({ transform: 'translate3d(0%,0,0)' }), { optional: true }),
  query(':enter .outlet', style({ transform: 'translate3d(-100%,0,0)' }), { optional: true }),
  group([
    query(':leave .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(100%,0,0)' })), { optional: true }),
    query(':enter .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(0%,0,0)' })), { optional: true })
  ])
];

const rightContentMoveToLeft = [
  query(':leave .outlet', style({ transform: 'translate3d(0%,0,0)' }), { optional: true }),
  query(':enter .outlet', style({ transform: 'translate3d(100%,0,0)' }), { optional: true }),
  query(':leave .outlet *[name="tabs"] + *', style({ opacity: 1 }), { optional: true }),

  group([
    query(':leave .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(-100%,0,0)' })), { optional: true }),
    query(':enter .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(0%,0,0)' })), { optional: true }),
    query(':leave .outlet *[name="tabs"] + *',
      animate('0ms 600ms', style({ opacity: 0 })), { optional: true }) //'tabs' router outlet should be visible until the end of animation
  ])
];

const rightContentMoveToRight = [
  query(':leave .outlet', style({ transform: 'translate3d(0%,0,0)' }), { optional: true }),
  query(':enter .outlet', style({ transform: 'translate3d(-100%,0,0)' }), { optional: true }),

  group([
    query(':leave .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(100%,0,0)' })), { optional: true }),
    query(':enter .outlet',
      animate('600ms ease-in-out', style({ transform: 'translate3d(0%,0,0)' })), { optional: true })
  ])
];

export const leftPanelSideAnimations: AnimationMetadata = trigger('leftAnimations', [
  transition('init => *', []), //denies any animation from 'init' state
  transition('void => *', []), //denies animation from 'null' state
  transition('2-contract => 1-contract-list', leftContentMoveToRight)
]);


/**
 * xyz => * : when moving forward to the right side: use rightContentMoveToLeft
 * abc => xyz : when moving backwards (close button) from abc to xyz: use rightContentMoveToRight
 */
export const rightPanelSideAnimations: AnimationMetadata = trigger('rightAnimations', [
  transition('init => *', []), //denies any animation from 'init' state
  transition('* => init', []), //denies any animation to 'init' state
  transition('void => *', []), //denies animation from 'null' state
  transition('1-contract => *', rightContentMoveToLeft),
  transition('2-equipment => 1-contract', rightContentMoveToRight), // pressed close on 2-equipment
  transition('2-equipment => *', rightContentMoveToLeft)
]);


@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
  animations: [leftPanelSideAnimations, rightPanelSideAnimations]
})
export class ContractComponent implements OnInit {

  private logger: ILogger;

  constructor(private route: ActivatedRoute, private loggerService: LoggerService) {
    this.logger = loggerService.getLogger('ContractComponent');
  }

  public prepareRouteTransition(outlet: RouterOutlet) {
    const result = outlet.activatedRouteData['animation'] || 'init';
    return result;
  }

  ngOnInit() {
  }
}
