import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {Subscription, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';

import {PropertyCore} from '../../../../core/odata/odata.coreapi';
import {InstallEquipmentService} from '../../../../building/installEquipment.service';
import {IntegrityBoxComponent} from '../../../dashboard-boxes/integrity-box/integrity-box.component';
import {EquipmentsNoContractState} from '../equipments-no-contract-state.service';
import { Guid } from 'guid-typescript';
import { Utils } from '../../../../core/tools/utils';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';

@Component({
  selector: 'app-equipment-choose-contract-action',
  templateUrl: './equipment-choose-contract-action.component.html',
  styleUrls: ['./equipment-choose-contract-action.component.scss']
})
export class EquipmentChooseContractActionComponent implements OnInit, OnDestroy {


  installEquipment: PropertyCore.Equipment;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private installEquipmentService: InstallEquipmentService,
    private toasterService: ToasterService,
    private state: EquipmentsNoContractState,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.paramMap
        .pipe(switchMap(params => {
          return from(this.odataCoreService.Equipment.Query()
            .Expand(x => {
              x.Expand("Building"),
                x.Expand("EquipmentType"),
                x.Expand("Room", y =>
                  y.Expand("Floor"))
            })
            .Filter(x =>
              x.EqualsField("Identity", Guid.parse(params.get('id'))))
              .Exec().then(x => x.value))
            .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      }))
        .subscribe(res => {
          this.installEquipment = res;
        }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setNoContract(): void {

    if (this.installEquipment.Room == null) {
      this.installEquipment.IsNoMaintenanceDesired = true;
      this.subscriptions.add(from(this.odataCoreService.Equipment.Patch()
        .ValueType(this.odataCoreService.ODataTypes().Equipment())
        .Key(Guid.parse(this.installEquipment.Identity))
        .ValueProperty("TuevNo", this.installEquipment.TuevNo)
        .ValueProperty("Comment", this.installEquipment.Comment)
        .ValueProperty("IsRenterResponsibility", this.installEquipment.IsRenterResponsibility)
        .ValueProperty("IsNoMaintenanceDesired", this.installEquipment.IsNoMaintenanceDesired)
        .ValueProperty("CustomId", this.installEquipment.CustomId)
        .ValuePropertyBinding("EquipmentType", this.odataCoreService.EquipmentType.Get().Key(this.installEquipment.EquipmentType.Code).Bind())
        .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.installEquipment.Building.Identity)).Bind())
        .Exec()).subscribe(res => {
          this.state.notify(EquipmentsNoContractState.ACTION_SET_NO_CONTRACT);
          this.toasterService.pop('success', '', this.translateService.instant('DashboardIntegrityEquipmentsNoContract._toastr_setNoContractSuccess'));
        })
      );

    } else {
      this.installEquipment.IsNoMaintenanceDesired = true;
      this.subscriptions.add(from(this.odataCoreService.Equipment.Patch()
        .ValueType(this.odataCoreService.ODataTypes().Equipment())
        .Key(Guid.parse(this.installEquipment.Identity))
        .ValueProperty("TuevNo", this.installEquipment.TuevNo)
        .ValueProperty("Comment", this.installEquipment.Comment)
        .ValueProperty("IsRenterResponsibility", this.installEquipment.IsRenterResponsibility)
        .ValueProperty("IsNoMaintenanceDesired", this.installEquipment.IsNoMaintenanceDesired)
        .ValueProperty("CustomId", this.installEquipment.CustomId)
        .ValuePropertyBinding("Room", this.odataCoreService.Room.Get().Key(Guid.parse(this.installEquipment.Room.Identity)).Bind())
        .ValuePropertyBinding("EquipmentType", this.odataCoreService.EquipmentType.Get().Key(this.installEquipment.EquipmentType.Code).Bind())
        .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.installEquipment.Building.Identity)).Bind())
        .Exec()).subscribe(res => {
          this.state.notify(EquipmentsNoContractState.ACTION_SET_NO_CONTRACT);
          this.toasterService.pop('success', '', this.translateService.instant('DashboardIntegrityEquipmentsNoContract._toastr_setNoContractSuccess'));
        })
      );


    }
   
  }

  setToExistingContract(): void {
    this.router.navigate([
      '/dashboard',
      {
        outlets: {
          left: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_NO_CONTRACT],
          right: ['set-contract', this.installEquipment.Identity]
        }
      }
    ], {queryParamsHandling: 'merge'});
  }

  setNewContract(): void {
    this.router.navigate(['contract', 'wizard', 'set-equipment', this.installEquipment.Identity]);
  }

  close() {
    this.router.navigate(['/dashboard', {
        outlets: {
          left: ['board'],
          right: [IntegrityBoxComponent.PATH_PREFIX, IntegrityBoxComponent.PATH_EQUIPMENTS_NO_CONTRACT]
        }
      }],
      {queryParamsHandling: 'merge'});
  }
}

