import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription, from } from 'rxjs';

import { EquipmentWizardNavigationPaths } from './equipment-wizard-navigation-paths.enum';
import { EquipmentWizardService } from './equipment-wizard.service';
import { Guid } from 'guid-typescript';
import { Utils } from '../../../core/tools/utils';
import { map } from 'rxjs/internal/operators/map';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';

@Component({
  selector: 'app-equipment-wizard',
  templateUrl: './equipment-wizard.component.html',
  styleUrls: ['./equipment-wizard.component.scss']
})
export class EquipmentWizardComponent implements OnInit, OnDestroy {

  equipmentId: string;
  buildingId: string;
  navigateBack: string;

  currentPage: EquipmentWizardNavigationPaths;

  modelReady: boolean;

  PAGE_DATA = EquipmentWizardNavigationPaths.DATA;
  PAGE_TYPE = EquipmentWizardNavigationPaths.TYPE;
  PAGE_TECHNICAL = EquipmentWizardNavigationPaths.TECHNICAL;

  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private service: EquipmentWizardService,
    private router: Router,
    private odataCoreService: ODataCoreService
  ) { }

  ngOnInit() {
    this.subscriptions.add(this.route.parent.paramMap.subscribe((params: ParamMap) => this.buildingId = params.get('id')));
    this.subscriptions.add(this.route.paramMap.subscribe((params: ParamMap) => this.equipmentId = params.get('id')));
    this.navigateBack = this.route.snapshot.data.navigateBack;
    this.currentPage = this.PAGE_TYPE;

    this.subscriptions.add(from(this.odataCoreService.Building.Get()
      .Key(Guid.parse(this.buildingId))
      .Expand(x => {
        x.Expand("UsageTypes");
        x.Expand("Statistics");
      })
      .Exec().then(x => x.value))
      .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0])))
      .subscribe(b => {
      this.service.startWizard(b.Identity, b.Name);
      this.modelReady = true;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  processPageChangeRequest(pageChangeEvent: EquipmentWizardNavigationPaths): void {

    switch (pageChangeEvent) {

      case EquipmentWizardNavigationPaths.START: {
        this.currentPage = this.PAGE_TYPE;
        break;
      }
      case EquipmentWizardNavigationPaths.EXIT: {
        if (this.navigateBack && this.navigateBack === 'equipment' && this.equipmentId) {
          this.router.navigate(['/building', this.buildingId, { outlets: { left: ['building', 'view', { outlets: { tab: ['equipment'] } }], right: ['equipment', this.equipmentId] } }]);
        } else if (this.navigateBack && this.navigateBack === 'building' && this.buildingId) {
          this.router.navigate(['/building', this.buildingId, { outlets: { left: ['list'], right: ['building'] } }]);
        } else {
          console.error('Could not find a navigation route for equipment wizard!');
        }
        break;
      }
      case EquipmentWizardNavigationPaths.NEXT: {
        if (this.currentPage === this.PAGE_TYPE) {
          this.currentPage = this.PAGE_DATA;
        } else if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_TECHNICAL;
        }
        break;
      }
      case EquipmentWizardNavigationPaths.PREVIOUS: {
        if (this.currentPage === this.PAGE_DATA) {
          this.currentPage = this.PAGE_TYPE;
        } else if (this.currentPage === this.PAGE_TECHNICAL) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case EquipmentWizardNavigationPaths.TYPE: {
        if (this.currentPage === this.PAGE_TYPE || this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_TECHNICAL) {
          this.currentPage = this.PAGE_TYPE;
        }
        break;
      }
      case EquipmentWizardNavigationPaths.DATA: {
        if (this.currentPage === this.PAGE_DATA || this.currentPage === this.PAGE_TECHNICAL) {
          this.currentPage = this.PAGE_DATA;
        }
        break;
      }
      case EquipmentWizardNavigationPaths.TECHNICAL: {
        if (this.currentPage === this.PAGE_TECHNICAL) {
          this.currentPage = this.PAGE_TECHNICAL;
        }
        break;
      }
    }
  }
}
