import {Component} from '@angular/core';

@Component({
  selector: 'app-specification-of-services',
  templateUrl: './specification-of-services.component.html',
  styleUrls: ['./specification-of-services.component.scss']
})
export class SpecificationOfServicesComponent {

  constructor() {
  }

}
