import { Injectable } from '@angular/core';

import { EquipmentWizardModel } from './equipment-wizard-model';

@Injectable()
export class EquipmentWizardService {

  model: EquipmentWizardModel = new EquipmentWizardModel;

  buildingId: string;
  buildingName: string;

  constructor() { }

  getCurrentEquipment(): EquipmentWizardModel {
    return this.model;
  }

  startWizard(buildingId: string, buildingName: string): void {
    this.buildingId = buildingId;
    this.buildingName = buildingName;
    this.resetCurrentEquipment();
  }

  createEquipment(): void {
    // service call
  }

  resetCurrentEquipment(): void {
    this.model.reset();
    this.model.buildingId = this.buildingId;
    this.model.buildingName = this.buildingName;
  }

}
