import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {of, Subscription, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {PropertyCore} from '../../core/odata/odata.coreapi';
import {AuthService} from '../../core/auth/auth.service';
import {ILogger, LoggerService} from '../../core/shared/logger.service';
import { Guid } from 'guid-typescript';
import { Utils } from '../../core/tools/utils';
import { ODataCoreService } from '../../core/odata-services/odata.coreapi.service';


@Component({
  selector: 'app-service-contact-detail',
  templateUrl: './service-contact-detail.component.html',
  styleUrls: ['./service-contact-detail.component.scss']
})

export class ServiceContactDetailComponent implements OnInit, OnDestroy {

  serviceContact: PropertyCore.ServiceProvider;

  private subscriptions: Subscription = new Subscription();

  logger: ILogger;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private loggerService: LoggerService,
    private odataCoreService: ODataCoreService
  ) {
    this.logger = loggerService.getLogger('ServiceContactDetailComponent');
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.parent.paramMap.pipe(switchMap((params: ParamMap) => of(params.get('id')))).subscribe(serviceContactId => {
        this.subscriptions.add(from(this.odataCoreService.ServiceProvider.Query()
          .Expand(x => {
            x.Expand("Statistics")
          })
          .Filter(xa =>
            xa.EqualsField("Identity", Guid.parse(serviceContactId)))
          .Exec().then(x => x.value))
          .pipe(map(res => Utils.mapAllJSONDatesToDates(res[0]))).subscribe(serviceContact => {
          this.serviceContact = serviceContact;
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  edit(UT: string): void {
    this.router.navigate(['/service-contact', UT, {
      outlets: {
        left: ['list'],
        right: ['service-contact-wizard', 'edit']
      }
    }]);
  }
}
