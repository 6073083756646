import {Component, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {Subscription, Observable, from} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';

import {Library, ObjektBrief, Common} from '../../../core/odata/odata.coreapi';
import {Mandator} from './../../../core/model/mandator';
import {ConfirmationModal} from './../../../core/popup/confirmation.modal';
import {GlobalService} from './../../../core/shared/global.service';
import { Utils } from '../../../core/tools/utils';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-mandator-detail',
  templateUrl: './mandator-detail.component.html',
  styleUrls: ['./mandator-detail.component.scss']
})
export class MandatorDetailComponent implements OnInit, OnDestroy {

  mandator: Mandator;

  countries: Library.Country[];
  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  accountChangeRequest = new EventEmitter<void>();

  onChange(UpdatedValue: string): void {

    this.mandator.Profile.PartnerNo = UpdatedValue;
    this.odataCoreService.Account.Get()
      .Key(Guid.parse(this.mandator.Identity)).Actions().CheckNameInOperationsOnAccountInOperations()
      .Parameters(this.mandator.Name)
      .Execute()
      .then(x => {
        if(x['value'] == true){
          this.mandator.doesExists = false;
        } else {
          this.mandator.doesExists = true;
        }
      });
      this.accountChangeRequest.emit();
  }

  constructor(
    private globalService: GlobalService,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {

    this.subscriptions.add(
      this.route.paramMap
        .pipe(switchMap((params: ParamMap) => from(this.odataCoreService.Account.Query()
          .Filter(x =>
            x.EqualsField("Identity", Guid.parse(params.get('id'))))
          .Expand(x => {
            x.Expand("User"),
              x.Expand("Statistics"),
              x.Expand("Profile"),
              x.Expand("AccountDataProcessingAgreement", z => {
                z.Expand("Country")
              })
          })   
          .Exec().then(x => x.value))
          .pipe(map(res => {
            Utils.mapAllJSONDatesToDates(res[0]);
            return Object.assign(new Mandator(), res[0]);
          }))))
        .subscribe(res1 => {

          this.subscriptions.add(
            from(this.odataCoreService.Country.Query()
            .OrderBy('Id')
            .Exec().then(x => x.value))
            .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
            .subscribe(res2 => {

            this.mandator = res1;
            this.mandator.doesExists = false;
            this.countries = res2;

            if (this.mandator.Profile == null) {
              this.odataCoreService.Account.Get()
              .Key(Guid.parse(this.mandator.Identity))
              .NavigateToArray<ObjektBrief.AccountProfile>("Profile")
              .Patch()
              .ValueType(this.odataCoreService.ODataTypes().AccountProfile())
              .Exec()
              .then(x => {               
                this.ngOnInit();
              });
            } else
            {
          
              const countryId = this.countries.find(c => c.Name === this.mandator.Profile?.Address?.CountryInfo.Name);      
              this.mandator.Profile.Address.CountryInfo = countryId;
              const invoiceCountryId = this.countries.find(c => c.Name === this.mandator.Profile?.InvoiceAddress?.CountryInfo.Name);      
              this.mandator.Profile.InvoiceAddress.CountryInfo = invoiceCountryId;

            };
            

            }));

        })
    ); 
 
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    // this.subscriptions.add(this.globalService.updateMandator(this.mandator).subscribe(res => {
    //   this.toasterService.pop('info', '', this.translateService.instant('EditMandator._save_success'));
    //   this.router.navigate(['/global/mandator']);
    // }));

    this.odataCoreService.Account.Patch()
    .Key(Guid.parse(this.mandator.Identity))
    .ValueType(this.odataCoreService.ODataTypes().Account())
    .ValueProperty("Name", this.mandator.Name)
    .Exec()
    .then(x => {

      this.odataCoreService.Account.Get()
      .Key(Guid.parse(x.Identity))
      .NavigateToArray<ObjektBrief.AccountProfile>("Profile")
      .Patch()
      .ValueType(this.odataCoreService.ODataTypes().AccountProfile())
      .ValueProperty("Name", this.mandator.Profile.Name)
      .ValueProperty("PartnerNo", this.mandator.Profile.PartnerNo)
      .ValueProperty("VatCode", this.mandator.Profile.VatCode)
      .ValueProperty("AddressAddition", this.mandator.Profile.AddressAddition)
      .ValueProperty("Address", this.mapAddress(this.mandator.Profile.Address))
      .ValueProperty("Contact", this.mandator.Profile.Contact)
      .ValueProperty("ContactPhone", this.mandator.Profile.ContactPhone)
      .ValueProperty("ContactMail", this.mandator.Profile.ContactMail)
      .ValueProperty("InvoiceName", this.mandator.Profile.InvoiceName)
      .ValueProperty("InvoicePartnerNo", this.mandator.Profile.InvoicePartnerNo)
      .ValueProperty("InvoiceCode", this.mandator.Profile.InvoiceCode)
      .ValueProperty("InvoiceAddressAddition", this.mandator.Profile.InvoiceAddressAddition)
      .ValueProperty("InvoiceAddress", this.mapAddress(this.mandator.Profile.InvoiceAddress))
      .Exec()
      .then(y => {
        this.toasterService.pop('info', '', this.translateService.instant('EditMandator._save_success'));
        this.router.navigate(['/global/mandator']);
      })

    })

  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('EditMandator._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('EditMandator._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('EditMandator._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('EditMandator._modal_cancel');

      modalRef.result
        .then(val => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.router.navigate(['/global/mandator']);
          }
        })
        .catch(() => {
          // do nothing, just stay on page
        });
    } else {
      this.router.navigate(['/global/mandator']);
    }
  }

/*   editSubscriptionPlan() {
    this.router.navigate(['/global', 'mandator', 'wizard', this.mandator.Identity]);
  } */

 private mapAddress(address: Common.Address): Common.Address {
    let entity: Common.Address = <Common.Address>{};
     if (address.RegionInfo != null && address.RegionInfo.hasOwnProperty('Code')){
      entity.Region = address.RegionInfo['Code'];
    } 
    if (address.CountryInfo != null && address.CountryInfo.hasOwnProperty('Code')) {
      entity.Country = address.CountryInfo['Code'];
    } else {
      entity.Country = 'DE';
    }
    if (address.City != null) {
      entity.City = address.City;
    }
    if (address.No != null) {
      entity.No = address.No;
    }
    if (address.PostCode != null) {
      entity.PostCode = address.PostCode;
    }
    if (address.Street != null) {
      entity.Street = address.Street;
    }
    return entity;
  }
}
