import { Directive, forwardRef } from '@angular/core';
import { Validator } from '@angular/forms';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validators } from '@angular/forms';

@Directive({
  selector: "[optionalEmail][formControlName],[optionalEmail][formControl],[optionalEmail][ngModel]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => OptionalEmailValidator), multi: true }
  ]
})
export class OptionalEmailValidator implements Validator {

  validate(c: AbstractControl): ValidationErrors|null {
    return c.value ? Validators.email(c) : null;
  }

}
