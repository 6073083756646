

import { Common, PropertyCore} from '../../../core/odata/odata.coreapi';

import { EditInspectionDutyGroup } from '../../../core/components/equipment/edit-inspection-duties/editInspectionDutyGroup';

import { EditOperatorTask } from '../../../core/components/equipment/edit-inspection-duties/editOperatorTask';


export class InspectiondutyWizardModel {

  equipment: PropertyCore.Equipment;
  equipmentOperatorTasks: EditOperatorTask[];
  attributesCategories?: any[] = [];
  attributesCategoryNames?: any[] = [];
  categoryArray: any[] =[];
  cycleUnits: String[] = [];
  storeInspectionDutyChange?: any[] = [];
  selectedEquipments: String[];
  inspectiondutyChanges?:any[];
  comment: string="";
  changedIDs: number[] =[];
  reset(): void {
    this.equipment = null;
    this.equipmentOperatorTasks = [];
    this.attributesCategories= [];
    this.attributesCategoryNames= [];
    this.categoryArray = [];
    this.cycleUnits = [];
    this.storeInspectionDutyChange = [];
    this.selectedEquipments = [];
    this.inspectiondutyChanges = [];
    this.comment = "";
    this.changedIDs=[];
    
  }
}
