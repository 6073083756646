import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, from} from 'rxjs';
import {ObjektBrief} from '../../../core/odata/odata.coreapi';
import {AuthService} from './../../../core/auth/auth.service';
import {Mandator} from './../../../core/model/mandator';
import { Utils } from '../../../core/tools/utils';
import { ODataCoreService } from '../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-mandator-list',
  templateUrl: './mandator-list.component.html',
  styleUrls: ['./mandator-list.component.scss']
})
export class MandatorListComponent implements OnInit, OnDestroy {

  mandators: Mandator[];

  searchFilter: string = '';

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(from(this.odataCoreService.Account.Query()
      .OrderBy("Name")
      .Expand(x => {
        x.Expand("Rights", y => {
          y.Filter(z => z
           .EqualsField("Identity", Guid.parse(localStorage.getItem('infoUserIdentity'))))
        })
        x.Expand("User"),
        x.Expand("User"),
        x.Expand("Roles", y => {
          y.Expand("User")
        })
      })
      .Exec().then(x => x.value))
      .pipe(map(res => res.map(i => {
        Utils.mapAllJSONDatesToDates(i);
        return Object.assign(new Mandator(), i);
      }))).subscribe(mandators => {
      this.mandators = mandators;
      this.mandators.forEach(m => m.isOwnerOwner = m.User.Email === this.authService.getUserEmail());
      this.mandators.forEach(m => m.isOwner = !!m.Roles.find(r => r.Role === ObjektBrief.AccountRoles.Administrator && r.User.Email === this.authService.getUserEmail()));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  newMandator() {
    this.router.navigate(['wizard'], {relativeTo: this.route});
  }

  editMandator(mandator: Mandator) {
    this.router.navigate([mandator.Identity], {relativeTo: this.route});
  }

}
