import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, from, Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {PropertyCore} from '../../../../core/odata/odata.coreapi';
import {DocumentWizardNavigationPaths} from '../document-wizard-navigation-paths.enum';
import {ConfirmationModal} from './../../../../core/popup/confirmation.modal';
import {Utils} from './../../../../core/tools/utils';
import {DocumentWizardModel} from './../document-wizard-model';
import {DocumentWizardService} from './../document-wizard.service';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';
import { FileItem } from 'ng2-file-upload';


@Component({
  selector: 'app-document-wizard-incident-data',
  templateUrl: './document-wizard-incident-data.component.html',
  styleUrls: ['./document-wizard-incident-data.component.scss']
})
export class DocumentWizardIncidentDataComponent implements OnInit, OnDestroy {

  model: DocumentWizardModel;

  buildingIncidents: any[]; // array with Incident[] per building
  installEquipmentIncidents: any[]; // array with Incident[] per installEquipment

  nowNgbDateStruct = Utils.dateToDatepicker((new Date()));

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<DocumentWizardNavigationPaths>();

  constructor(
    private service: DocumentWizardService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService,
    private httpClient: HttpClient
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentDocument();

    this.buildingIncidents = this.model.missingDataIncidents.filter(x => !x.Equipment).reduce((prev, next) => {
      // if event does not have response yet, add it, so that data can be edited
      if (!next.Response) {
        next.Response = <PropertyCore.EventResponse>{DefectRating: PropertyCore.DefectRatings.Undefined};
        next.DefectChecker = DefectShort(next.Response.DefectRating);
      }
      const b = prev.find(x => x.buildingUT === next.Building.Identity);
      if (!b) {
        prev.push({buildingUT: next.Building.Identity, buildingName: next.Building.Name, dataList: [next]});
      } else {
        b.dataList.push(next);
      }
      return prev;
    }, []);
    this.installEquipmentIncidents = this.model.missingDataIncidents.filter(x => x.Equipment).reduce((prev, next) => {
      // if event does not have response yet, add it, so that data can be edited
      if (!next.Response) {
        next.Response = <PropertyCore.EventResponse>{DefectRating: PropertyCore.DefectRatings.Undefined};
        next.DefectChecker = DefectShort(next.Response.DefectRating);
      }
      const b = prev.find(x => x.installEquipmentId === next.Equipment.Identity);
      if (!b) {
        prev.push({
          installEquipmentId: next.Equipment.Identity,
          installEquipmentName: next.Equipment.Name,
          installEquipmentCustomId: next.Equipment.CustomId,
          dataList: [next]
        });
      } else {
        b.dataList.push(next);
      }
      return prev;
    }, []);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  previous(): void {
    this.pageChangeRequest.emit(DocumentWizardNavigationPaths.PREVIOUS);
  }

  save(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }

    // save incident data
    let a = 0;
    while(this.model.incidents.length > a) {
      this.odataCoreService.Event.Get()
      .Key(Guid.parse(this.model.incidents[a].Identity))
      .Select("Identity","Response")
      .Expand(x => {
        x.Expand("Response", y => {
          y.Select("Identity")
        })
      })       
      .Exec()
        .then(res4 => {
        var eventResponse = res4.value[0];
        var eventModel = this.model.missingDataIncidents.find(x => x.Identity == eventResponse.Identity);
        if(eventModel?.Identity != undefined){
          if (eventResponse.Response != null){
            this.odataCoreService.Event.Get()
            .Key(Guid.parse(eventModel.Identity))
            .NavigateToArray<PropertyCore.EventResponse>("Response")
            .Patch()
            .ValueType(this.odataCoreService.ODataTypes().EventResponse())
            .ValueProperty("Comment", eventModel.Response.Comment ? eventModel.Response.Comment : '')  
            .ValueProperty("DefectRating", eventModel.IsSkipped ? PropertyCore.DefectRatings.Undefined : (eventModel.DefectChecker ? PropertyCore.DefectRatings.Unrated : PropertyCore.DefectRatings.Undefined)) // skipped incident does not have defect))
            .ValueProperty("ReferenceDate", eventModel.IsSkipped ? null : eventModel.RefDate) // skipped incident does not have refdate 
            .Exec()
          } 
          else {
            this.odataCoreService.Event.Get()
            .Key(Guid.parse(eventModel.Identity))
            .NavigateToArray<PropertyCore.EventResponse>("Response")
            .Post()
            .ValueType(this.odataCoreService.ODataTypes().EventResponse())
            .ValueProperty("Comment", eventModel.Response.Comment ? eventModel.Response.Comment : '')  
            .ValueProperty("DefectRating", eventModel.IsSkipped ? PropertyCore.DefectRatings.Undefined : (eventModel.DefectChecker ? PropertyCore.DefectRatings.Unrated : PropertyCore.DefectRatings.Undefined)) // skipped incident does not have defect))
            .ValueProperty("ReferenceDate", eventModel.IsSkipped ? null : eventModel.RefDate) // skipped incident does not have refdate 
            .Exec()
          }
        } else {
          // do nothing
        } 
      })
      a++;
    }
    
    // save document data
      if (this.model.virtual) {
          this.uploadVirtualDocumentEvent();        
      } else {
              this.odataCoreService.Document.Post()
                .ValueType(this.odataCoreService.ODataTypes().Document())
                .ValuePropertyBinding("DocumentType", this.odataCoreService.DocumentType.Get().Key(this.model.DocumentType.Id).Bind())
                .ValueProperty("Name", this.model.file.file.name)
                .ValueProperty("Description", this.model.name)
                .Exec()
                .then(res2 => {
                  if(this.model.file.file.type != "") {
                    var odataDocumentUrl = this.odataCoreService.Document.Get().Raw()
                        .Key(Guid.parse(res2.Identity))
                        .ToUrlString(false);

                        from(this.uploadODataDocument(this.model.file, odataDocumentUrl))
                        .subscribe(res4 => {
                            var eventDetails = this.model.incidents;
                            let x = 0;
                            while (eventDetails.length > x) {
                                    this.odataCoreService.Event.Link()
                                    .Key(Guid.parse(eventDetails[x].Identity))
                                    .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(res2.Identity)).Bind())
                                    .Post()
                                    .then(res3 => {                                               
                                        if(eventDetails.length == x){
                                        this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                                        }
                                    })
                                x++;
                            }
                        }) 
                    } else {
                      this.uploadDocumentNoContentType(res2.Identity);
                    }
      });
    }
  }

    private async uploadVirtualDocumentEvent() {
        for (let x = 0; x < this.model.incidents.length; x++) {
          let res = await this.odataCoreService.Document.Post()
            .ValueType(this.odataCoreService.ODataTypes().Document())
            .ValuePropertyBinding("DocumentType", this.odataCoreService.DocumentType.Get().Key(this.model.DocumentType.Id).Bind())
            .ValueProperty("Description", this.model.name)
            .Exec();
                        this.odataCoreService.Event.Link()
                            .Key(Guid.parse(this.model.incidents[x].Identity))
                            .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(res.Identity)).Bind())
                            .Post()
                                if (this.model.incidents.length == x + 1) {
                                    this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                                }

        }
    }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('DocumentWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('DocumentWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('DocumentWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('DocumentWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentDocument();
            this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
            // do nothing, just stay on page
          }
        );
    } else {
      this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
    }
  }

  uploadODataDocument(file: FileItem, url: string): Observable<any> {
      return this.httpClient.put(url, file._file);
  }

  uploadODataDocumentNoCT(file: Blob, url: string): Observable<any> {
    return this.httpClient.put(url, file);
  }

  uploadDocumentNoContentType(documentIdentity : string): void{
    let fileExtensionIndex = this.model.file.file.name.lastIndexOf(".");
    let fileExtension = this.model.file.file.name.substring(fileExtensionIndex);
    let blobFile;

    switch (fileExtension) {

     case '.pdf':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/pdf'); 
      break;

     case '.png':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/png');
       break;

     case '.jpg':
     case '.jpeg':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/jpeg');
       break;

     case '.tif':
     case '.tiff':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/tiff');
       break;

     case '.doc':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/msword');
       break;

     case '.docx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
       break;

     case '.xls':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.ms-excel');
       break;

     case '.xlsx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
       break;

     case '.ppt':        
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.ms-powerpoint');
       break;

     case '.pptx':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
        break;

     case '.dwg':
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'image/vnd.dwg');
       break;

     default: 
      blobFile = this.model.file._file.slice(0, this.model.file.file.size, 'application/octet-stream');
       break;
       
     }

 var odataDocumentUrl = this.odataCoreService.Document.Get().Raw().Key(Guid.parse(documentIdentity)).ToUrlString(false);

     from(this.uploadODataDocumentNoCT(blobFile, odataDocumentUrl))
      .subscribe(res => {
         var eventIdentity = this.model.incidents.map(x => x.Identity);
         let x = 0;
         while (eventIdentity.length > x) {
              this.odataCoreService.Event.Link()
                     .Key(Guid.parse(eventIdentity[x]))
                     .Value("Documents", this.odataCoreService.Document.Get().Key(Guid.parse(documentIdentity)).Bind())
                     .Post()
                     .then(res => {
                        if(eventIdentity.length == x + 1){
                         this.pageChangeRequest.emit(DocumentWizardNavigationPaths.EXIT);
                        }
                     })
             x++;
         }
     })
  }
}

function DefectShort(DefectRating): boolean {
  switch (DefectRating) {
    case PropertyCore.DefectRatings.Unrated:
    case PropertyCore.DefectRatings.Minor:
    case PropertyCore.DefectRatings.Major:
    case PropertyCore.DefectRatings.Dangerous:
      return true;
    case PropertyCore.DefectRatings.Undefined:
      return false;
  }
  return false;
};
