export enum IncidentType {
  INSPECTION = 'inspection',
  MAINTENANCE = 'maintenance',
  REPAIR = 'repair',
  INDIVIDUAL = 'individual',
  NONE = ''
}
export namespace IncidentType {
  export function getIncidentType(value: string) {
    switch (value) {
      case IncidentType.INSPECTION: return IncidentType.INSPECTION;
      case IncidentType.MAINTENANCE: return IncidentType.MAINTENANCE;
      case IncidentType.REPAIR: return IncidentType.REPAIR;
      case IncidentType.INDIVIDUAL: return IncidentType.INDIVIDUAL;
      case IncidentType.NONE: return IncidentType.NONE;
      default: return IncidentType.NONE;
    }
  }
}
