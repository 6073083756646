import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToasterService } from "angular2-toaster";
import { FileUploader, FileItem } from "ng2-file-upload";

import { AuthService } from "./../../auth/auth.service";
import { AppConfig } from "./../../bootstrap/app.config";
import { ODataCoreService } from "../../odata-services/odata.coreapi.service";
import { Subscription, from, Observable, lastValueFrom } from "rxjs";

import { Guid } from "guid-typescript";
import { HttpClient } from "@angular/common/http";
import { ImageUploadService } from "./image-upload-service";
import { ImageUploadModel } from "./image-upload-model";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
})
export class ImageUploadComponent {
  @Output()
  addFile: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  Identity: string;

  @Input()
  EntityType: string;

  @Output()
  complete: EventEmitter<any> = new EventEmitter<any>();

  uploader: FileUploader;

  hasDropZoneOver = false;

  imageUploadModel: ImageUploadModel;

  subscriptions: Subscription = new Subscription();

  constructor(
    private config: AppConfig,
    private authService: AuthService,
    private toasterService: ToasterService,
    private imageUploadService: ImageUploadService,
    private odataCoreService: ODataCoreService,
    private httpClient: HttpClient
  ) {
    this.uploader = new FileUploader({
      isHTML5: true,
      allowedMimeType: ["image/png", "image/jpg", "image/jpeg"],
      maxFileSize: 10 * 1024 * 1024, // 10 MB
      method: "POST",
      headers: [{ name: "Accept", value: "text/json" }],
      removeAfterUpload: true,
      authToken: authService.getAuthorizationHeader(),
    });

    this.uploader.onBuildItemForm = (item, form) => {
      // console.log('onBuildItemForm', item, form);
    };
    this.uploader.onAfterAddingAll = (fileItems) => {
      // console.log('onAfterAddingAll', fileItems);
    };
    this.uploader.onAfterAddingFile = (fileItem) => {
      // console.log('onAfterAddingFile', fileItem);
      this.addFile.emit(fileItem);
    };
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      // console.log('onWhenAddingFileFailed', item, filter, options);
      switch (filter.name) {
        case "mimeType":
          this.toasterService.pop(
            "warning",
            "",
            "Datei '" + item.name + "' konnte nicht hinzugefügt werden (kein Bild)"
          );
          break;
        case "fileSize":
          this.toasterService.pop(
            "warning",
            "",
            "Datei '" + item.name + "' konnte nicht hinzugefügt werden (Größe über 10MB)"
          );
          break;
        default:
          this.toasterService.pop("warning", "", "Datei '" + item.name + "' konnte nicht hinzugefügt werden");
          break;
      }
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      // console.log('onBeforeUploadItem', fileItem);
    };
    this.uploader.onProgressItem = (fileItem, progress) => {
      // console.log('onProgressItem', fileItem, progress);
    };
    this.uploader.onProgressAll = (progress) => {
      // console.log('onProgressAll', progress);
    };
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      // console.log('onSuccessItem', item, response, status, headers);
      this.toasterService.pop("info", "", "Datei '" + item._file.name + "' wurde erfolgreich hochgeladen");
    };
    this.uploader.onErrorItem = (item, response, status, headers) => {
      // console.log('onErrorItem', item, response, status, headers);
      this.toasterService.pop("error", "", "Fehler beim Hochladen der Datei '" + item._file.name + "'");
    };
    this.uploader.onCancelItem = (item, response, status, headers) => {
      // console.log('onCancelItem', item, response, status, headers);
      this.toasterService.pop("warning", "", "Datei '" + item._file.name + "' wurde abgebrochen");
    };
    this.uploader.onCompleteItem = (item, response, status, headers) => {
      // console.log('onCompleteItem', item, response, status, headers);
    };
    this.uploader.onCompleteAll = () => {
      // console.log('onCompleteAll');
    };
  }

  public fileOver(e: any): void {
    this.hasDropZoneOver = e;
  }

  public async uploadAll() {
    this.imageUploadModel = this.imageUploadService.getCurrentImageUploadModel();
    let x = 0;
    switch (this.EntityType) {
      case "Building":
        for await (const imageUpload of this.imageUploadModel.file) {
          const imageResult = await this.odataCoreService.Image.Post()
            .ValueType(this.odataCoreService.ODataTypes().Image())
            .ValueProperty("FileName", imageUpload.file.name)
            .ValuePropertyBinding(
              "Building",
              this.odataCoreService.Building.Get().Key(Guid.parse(this.Identity)).Bind()
            )
            .Exec();

          this.uploadImage(imageResult.Identity, imageUpload).subscribe(() => {
            this.toasterService.pop("info", "", "Datei '" + imageUpload.file.name + "' wurde erfolgreich hochgeladen");
            if (this.imageUploadModel.file.length == x + 1) {
              this.complete.emit(null);
            }
            x++;
          });
        }
        break;

      case "Equipment":
        for await (const imageUpload of this.imageUploadModel.file) {
          const imageResult = await this.odataCoreService.Image.Post()
            .ValueType(this.odataCoreService.ODataTypes().Image())
            .ValueProperty("FileName", imageUpload.file.name)
            .ValuePropertyBinding(
              "Equipment",
              this.odataCoreService.Equipment.Get().Key(Guid.parse(this.Identity)).Bind()
            )
            .Exec();

          this.uploadImage(imageResult.Identity, imageUpload).subscribe(() => {
            this.toasterService.pop("info", "", "Datei '" + imageUpload.file.name + "' wurde erfolgreich hochgeladen");
            if (this.imageUploadModel.file.length == x + 1) {
              this.complete.emit(null);
            }
            x++;
          });
        }
        break;

      case "Module":
        for await (const imageUpload of this.imageUploadModel.file) {
          const imageResult = await this.odataCoreService.Image.Post()
            .ValueType(this.odataCoreService.ODataTypes().Image())
            .ValueProperty("FileName", imageUpload.file.name)
            .ValuePropertyBinding("Module", this.odataCoreService.Module.Get().Key(Guid.parse(this.Identity)).Bind())
            .Exec();

          this.uploadImage(imageResult.Identity, imageUpload).subscribe(() => {
            this.toasterService.pop("info", "", "Datei '" + imageUpload.file.name + "' wurde erfolgreich hochgeladen");
            if (this.imageUploadModel.file.length == x + 1) {
              this.complete.emit(null);
            }
            x++;
          });
        }
        break;
    }
  }

  private uploadImage(identity: string, imageUpload: FileItem): Observable<Object> {
    var odataImageUrl = this.odataCoreService.Image.Get().Raw().Key(Guid.parse(identity)).ToUrlString(false);

    return this.httpClient.put(odataImageUrl, imageUpload.file.rawFile);
  }

  private resetImageModel(): void {
    this.imageUploadService.resetCurrentImageUploadModel();
    this.imageUploadModel = this.imageUploadService.getCurrentImageUploadModel();
    this.imageUploadModel.entityType = this.EntityType;
  }

  private removeImageFromModel(imageIndex: number): void {
    this.imageUploadService.imageUploadModel.file.splice(imageIndex, 1);
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
