import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, Observable, from} from 'rxjs';
import {PropertyCore} from '../../../../core/odata/odata.coreapi';
import {ConfirmationModal} from './../../../../core/popup/confirmation.modal';
import {InstallEquipmentService} from './../../../installEquipment.service';
import {ModuleWizardModel} from './../module-wizard-model';
import {ModuleWizardNavigationPaths} from './../module-wizard-navigation-paths.enum';
import {ModuleWizardService} from './../module-wizard.service';
import {Utils} from "../../../../core/tools/utils";
import { ODataPath } from '../../../../core/odata/odataclient';
import { map } from 'rxjs/operators';
import { ODataCoreService } from '../../../../core/odata-services/odata.coreapi.service';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-module-wizard-data',
  templateUrl: './module-wizard-data.component.html',
  styleUrls: ['./module-wizard-data.component.scss']
})
export class ModuleWizardDataComponent implements OnInit, OnDestroy {


  model: ModuleWizardModel;

  floors: PropertyCore.Floor[];
  rooms: PropertyCore.Room[];

  newRoomName: string;

  formSubmitted: boolean;

  subscriptions: Subscription = new Subscription();

  @Output()
  pageChangeRequest = new EventEmitter<ModuleWizardNavigationPaths>();

  constructor(
    private service: ModuleWizardService,
    private equipmentService: InstallEquipmentService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private odataCoreService: ODataCoreService
  ) {
  }

  ngOnInit() {
    this.model = this.service.getCurrentModule();

      this.subscriptions.add(
        from(this.odataCoreService.Equipment.Get()
          .Key(Guid.parse(this.model.equipment.Identity))
          .Expand(x => {
            x.Expand("Building", y => {
              y.Expand("Floors")
            })
          })
          .Exec()
          .then(x => x.value))
          .pipe(map(res => res[0].Building.Floors.map(f => Utils.mapAllJSONDatesToDates(f))))
          .subscribe(res => this.floors = res));

      if (this.model.floor) {
        this.subscriptions.add(
          from(this.odataCoreService.Room.Query()
            .Filter(x => x
              .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.equipment.Building.Identity)).And
              .Equals(ODataPath.For("Floor", "Id"), this.model.floor.Id))
            .Expand(x => {
              x.Expand("Floor")
              x.Expand("Statistics")
            })
            .Exec()
            .then(x => x.value))
            .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
            .subscribe(res => this.rooms = res));

    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  next(validForm: boolean): void {
    if (!validForm) {
      this.formSubmitted = true;
      return;
    }
    this.pageChangeRequest.emit(ModuleWizardNavigationPaths.NEXT);
  }

  previous(): void {
    this.pageChangeRequest.emit(ModuleWizardNavigationPaths.PREVIOUS);
  }

  cancel(isDirty: boolean = true): void {
    if (isDirty) {
      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = this.translateService.instant('ModuleWizard._modal_title');
      modalRef.componentInstance.message = this.translateService.instant('ModuleWizard._modal_message');
      modalRef.componentInstance.yesButton = this.translateService.instant('ModuleWizard._modal_yes');
      modalRef.componentInstance.cancelButton = this.translateService.instant('ModuleWizard._modal_cancel');

      modalRef.result
        .then((val) => {
          if (val === ConfirmationModal.YES_VALUE) {
            this.service.resetCurrentModule();
            this.pageChangeRequest.emit(ModuleWizardNavigationPaths.EXIT);
          }
        })
        .catch(() => {
            // do nothing, just stay on page
          }
        );
    } else {
      this.pageChangeRequest.emit(ModuleWizardNavigationPaths.EXIT);
    }
  }

  onFloorChange(selectedFloor: PropertyCore.Floor): void {
    this.model.floor = selectedFloor;
    this.model.room = null;

    if (selectedFloor) {

      this.subscriptions.add(
        from(this.odataCoreService.Room.Query()
          .Filter(x => x
            .Equals(ODataPath.For("Building", "Identity"), Guid.parse(this.model.equipment.Building.Identity)).And
            .Equals(ODataPath.For("Floor", "Id"), this.model.floor.Id))
          .Expand(x => {
            x.Expand("Floor")
            x.Expand("Statistics")
          })
          .Exec()
          .then(x => x.value))
          .pipe(map(res => res.map(i => Utils.mapAllJSONDatesToDates(i))))
          .subscribe(res => this.rooms = res));

    }
  }
  
  openNewRoomPopup(content): void {
    //TODO chage to odata
    this.modalService.open(content).result.then(async result => {
      // on save click
      const guid = Utils.guid();
      let res = await this.odataCoreService.Room.Post()
        .ValueType(this.odataCoreService.ODataTypes().Room())
        .ValueProperty("Identity", guid)
        .ValueProperty("Name", this.newRoomName)
        .ValuePropertyBinding("Floor", this.odataCoreService.Floor.Get().Key(this.model.floor.Id).Bind())
        .ValuePropertyBinding("Building", this.odataCoreService.Building.Get().Key(Guid.parse(this.model.equipment.Building.Identity)).Bind())
        .Exec();
        this.model.room = <PropertyCore.Room>{Id: res, Name: this.newRoomName, Identity: guid};
        this.rooms.push(this.model.room);
        this.newRoomName = null;
    }).catch(() => {
      // do nothing, just stay on page
    });
  }

  compareId(val1: { Id: number }, val2: { Id: number }): boolean {
    return val1 === val2 || (val1 && val2 && val1.Id === val2.Id);
  }

  openInfoDialog3() {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = this.translateService.instant('ModuleWizardData._modal_customName_title');
    modalRef.componentInstance.message = this.translateService.instant('ModuleWizardData._modal_customName_message');
    modalRef.componentInstance.yesButton = this.translateService.instant('ModuleWizardData._modal_customName_yes');
    modalRef.componentInstance.hideCancelButton = true;
  }

}
